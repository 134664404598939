import { Box, Divider, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import AvatarImage from '../../../../common/components/AvatarImage';
import ButtonComponent from '../../../../common/components/ButtonComponent';
import InformationRow from '../../../../common/components/InformationRow';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { formatUserName } from '../../../../utils/formatters';

import {
  EditIcon,
  TrashIconOutlined,
} from '../../../../assets/icons';

import Conclusions from '../Conclusions';
import Tasks from '../Tasks';

const ActivityDetailsDrawer = ({
  activity,
  isLoadingResources,
  tasks,
  conclusions,
  handleEdit,
  handleDelete,
  responsable,
  setIsAddTaskModalOpen,
  handleToggleTask,
  setIsAddConclusionModalOpen,
  setPreview,
  setTaskDetailModalId,
  isSubmittingTask,
  isSubmittingConclusion,
}) => {
  const intl = useIntl();

  return (
    <>
      <Box mt={20} gap={20} display="flex" flexDirection="column">
        <InformationRow
          richText
          label={intl.formatMessage({ id: 'label.toValidateHypothesis' })}
          value={activity?.title}
        />

        <InformationRow
          richText
          label={intl.formatMessage({ id: 'label.toMeasureHypothesis' })}
          value={activity?.successCriteria}
        />

        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
              {intl.formatMessage({ id: 'label.personInCharge' })}
            </Typography>

            <Box display="flex" alignItems="center" gap={8}>
              {responsable?.profilePicture && (
                <AvatarImage
                  width="20px"
                  height="20px"
                  isRounded
                  src={responsable?.profilePicture}
                />
              )}
              <Typography variant="body1" color="primary" fontWeight={600}>
                {responsable ? formatUserName(responsable) : NOT_APPLICABLE}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <InformationRow
              label={intl.formatMessage({ id: 'label.totalCost' })}
              value={activity?.totalCost || 0}
            />
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" gap={10}>
          <ButtonComponent
            onClick={handleEdit}
            variant="outlined"
            color="secondary"
            icon={<EditIcon fill="currentColor" size="14px" />}
            label={intl.formatMessage({ id: 'button.edit' })}
          />
          <ButtonComponent
            onClick={handleDelete}
            icon={<TrashIconOutlined size="16px" />}
            color="error"
            label={intl.formatMessage({ id: 'button.delete' })}
          />
        </Box>
      </Box>

      <Divider sx={{ my: 30 }} />

      <Box>
        <Tasks
          tasks={tasks}
          setIsAddTaskModalOpen={setIsAddTaskModalOpen}
          handleToggleTask={handleToggleTask}
          setPreview={setPreview}
          setTaskDetailModalId={setTaskDetailModalId}
          isSubmitting={isSubmittingTask}
          isLoadingResources={isLoadingResources && !isSubmittingConclusion}
        />

        <Divider sx={{ my: 30 }} />

        <Conclusions
          setIsAddConclusionModalOpen={setIsAddConclusionModalOpen}
          conclusions={conclusions}
          setPreviewedConclusion={(entity) => setPreview(entity, 'CONCLUSION')}
          isLoading={(isLoadingResources || isSubmittingConclusion) && !isSubmittingTask}
        />
      </Box>
    </>
  );
};

export default ActivityDetailsDrawer;
