import { Box, useMediaQuery, useTheme } from '@mui/material';
import { get } from 'lodash-es';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Navbar from '../../common/components/_Navbar';
import Sidebar from '../../common/components/_Sidebar';
import DocumentTitle from '../../common/components/DocumentTitle';
import VideoModal from '../../common/components/VideoModal';
import {
  PageContainer,
  PageBody,
} from '../../common/styled';
import { PageMiddlePoint } from '../../modules/tour/styled/onboardingDashboardBricks';

import {
  closeModal,
  getModalOpenStateSelector,
  openModal,
  currentTeamIdSelector,
} from '../../common/store';
import matchRoute from '../../utils/services/matchRoute';

import { NAVBAR_HEIGHT } from '../../constants/general';

const Page = ({
  children,
  isLoading,
  isMenuOpen,
  location,
}) => {
  // const dispatch = useDispatch();
  // const user = useSelector(state => state.account?.user);

  // useEffect(() => {
  //   loadProfile();
  // }, []);

  // useEffect(() => {
  //   if (user?._id) {
  //     dispatch(loadUserTeams(user?._id));
  //   }
  // }, [user?._id]);

  // const isMatchingUrl = (urls) =>
  //   urls.some(
  //     (url) =>
  //       window.location.pathname === url.pathname &&
  //       window.location.search.includes(url.search)
  //   );
  //
  // const urlsToShowResourcesGuide = [
  //   { pathname: '/resources', search: '' },
  //   { pathname: '/admin/mrl', search: '?tab=chapters' },
  // ];
  //
  // const isResourcesGuideVisible = isMatchingUrl(urlsToShowResourcesGuide);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickMenu = () => setIsSidebarOpen(!isSidebarOpen);

  const handleCloseSidebar = () => setIsSidebarOpen(false);

  return (
    <PageContainer>
      <DocumentTitle>Launchpad</DocumentTitle>
      {(!isTablet || isSidebarOpen) && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          onCloseSidebar={handleCloseSidebar}
        />
      )}
      {!isLoading && (
        <PageBody
          open={isMenuOpen}
          isCanvasPage={matchRoute(location.pathname, '/canvas')}
        >
          <Navbar onClickMenu={handleClickMenu} />
          <Box height={`calc(100vh - ${NAVBAR_HEIGHT}px - 20px)`} position="relative">
            {children}
          </Box>
          <VideoModal />
        </PageBody>
      )}
      <PageMiddlePoint id="page-middle" />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.common.userTeams.isLoading,
  isHypothesisModalOpen: getModalOpenStateSelector(state.common, 'hypothesis'),
  isHypothesisModaDetaillOpen: getModalOpenStateSelector(
    state.common,
    'hypothesisDetail'
  ),
  role: get(state.account, 'user.role'),
  teamId: currentTeamIdSelector(state.common.userTeams),
  isMenuOpen: state.common.menu.isMenuOpen,
});

const mapDispatchToProps = {
  closeModal,
  openModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
