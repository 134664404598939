import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import GenericComments from '../../../commentsAndNotifications/components/GenericComments';
import TaskDetails from '../../components/TaskDetails';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { DRAWER_TABS } from '../../../../constants/drawerTabs';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';

const TaskDrawer = ({
  setPreview,
  handleCloseDrawer,
  previewedTask,
  handleDeleteTask,
  handleEditTask,
  setTaskDetailModalId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);

  const currentUserId = useSelector((state) => state.userProfile.userProfile.userId._id);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities]) || [];
  const hypothesis = useSelector((state) => state.hypotheses.hypothesis);

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities,
          entityId: hypothesis._id,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  const handleClose = () => {
    handleCloseDrawer();
    setPreview(null, 'TASK');
  };

  const handleBack = () => {
    setPreview(null, 'TASK');
  };

  return (
    <>
      <DrawerTitle
        title={intl.formatMessage({ id: 'label.visualizeTask' })}
        onClose={handleClose}
        onBack={handleBack}
      />

      <DrawerTabs
        sx={{ mt: 4 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />

      {activeDrawerTab === DRAWER_TABS.details
        ? (
          <TaskDetails
            previewedTask={previewedTask}
            handleDeleteTask={handleDeleteTask}
            handleEditTask={handleEditTask}
            setTaskDetailModalId={setTaskDetailModalId}
            setPreview={setPreview}
          />
        ) : (
          <GenericComments
            fetchComments={fetchComments}
            comments={comments}
            teamId={teamId}
            userId={currentUserId}
            type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities}
            entityId={hypothesis._id}
          />
        )}
    </>
  );
};

export default TaskDrawer;
