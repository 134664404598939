import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { get } from 'lodash-es';

export const SegmentCircle = styled('div')(({ theme, bgColor, size }) =>  `
  width: ${size}px;
  height: ${size}px;
  background-color: ${get(theme.palette, bgColor)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
`);

export const ValueWrapper = styled(Typography)(({ theme }) =>  `
   border: 1px solid ${theme.palette.general.borderAccent};
   padding: ${theme.spacing(10)};
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${theme.palette.common.white};
   min-width: 125px;
   height: 50px;
   border-radius: 10px;
   margin-top: 40px;
`);

export const Line = styled('div')(({ length, left, top, angle, theme }) =>  `
  width: 2px;
  height: ${length}px;
  background-color: ${theme.palette.primary.main};
  position: absolute;
  left: ${left || '50%'};
  top: ${top || '50%'};
  transform: translate(-50%, -50%) rotate(${angle}deg);
  transform-origin: top;
`);
