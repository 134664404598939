
const MuiInputLabel = {
  styleOverrides: {
    root: ({ theme: muiTheme }) => ({
      color: muiTheme.palette.text.secondary,

      '&.Mui-focused': {
        color: muiTheme.palette.text.secondary,
      },
    }),
  },
};

export default MuiInputLabel;
