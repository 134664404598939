import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DetailsDrawerWrapper from '../../../../common/components/DetailsDrawerWrapper';
import DialogAlert from '../../../../common/components/DialogAlert';
import { getAllSwotElementsByTeamId, deleteSwotById } from '../../../../store/contextAnalysis';
import { SwotCard } from '../../components/SwotCard';
import SwotDetails from '../../components/SwotDetails';
import { SwotModal } from '../../components/SwotModal';

import { currentTeamIdSelector } from '../../../../common/store';

import { CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';
import { SWOT_TYPES } from '../../constants';

import { SwotCardContainer } from './index.styled';

const SwotPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const teamSwots = useSelector((state) => state.contextAnalysis.teamSwots) || {};
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const [isSwotModalOpen, setIsSwotModalOpen] = useState(false);
  const [swotType, setSwotType] = useState('');
  const [selectedSwotElement, setSelectedSwotElement] = useState(null);
  const [isSwotDrawerOpen, setIsSwotDrawerOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const {
    strengths = [],
    weaknesses = [],
    opportunities = [],
    threats = [],
  } = teamSwots;

  useEffect(() => {
    if (teamId) {
      dispatch(getAllSwotElementsByTeamId(teamId));
    }
  }, [teamId]);

  const handleAddSwotElement = (swotType) => {
    setSwotType(swotType);
    setIsSwotModalOpen(true);
    setSelectedSwotElement(null);
  };

  const handleOpenDeleteSwot = (swotId) => {
    setIdToDelete(swotId);
  };

  const handleDeleteSwotElement = async () => {
    await dispatch(deleteSwotById(idToDelete, teamId));
    setIsSwotDrawerOpen(false);
    setSelectedSwotElement(null);
    setIdToDelete('');
  };

  const handleEditSwotElement = (swotElement) => {
    setIsSwotModalOpen(true);
    setSelectedSwotElement(swotElement);
    setIsSwotDrawerOpen(false);
  };

  const handleViewSwotElement = (swotElement) => {
    setIsSwotDrawerOpen(true);
    setSelectedSwotElement(swotElement);
  };

  const handleCloseDrawer = () => {
    setIsSwotDrawerOpen(false);
    setSelectedSwotElement(null);
  };

  return (
    <>
      <Grid
        container
        spacing={16}
        style={{ height: 'calc(100% + 16px)', alignItems: 'stretch' }}
      >
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.strengths}
              swotSegmentData={strengths}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleOpenDeleteSwot}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.weaknesses}
              swotSegmentData={weaknesses}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleOpenDeleteSwot}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.opportunities}
              swotSegmentData={opportunities}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleOpenDeleteSwot}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
        <Grid item xs={6} sx={{ height: '50%' }}>
          <SwotCardContainer className="scrollbar">
            <SwotCard
              swotSegmentDetails={SWOT_TYPES.threats}
              swotSegmentData={threats}
              handleAddSwotElement={handleAddSwotElement}
              handleDeleteSwotElement={handleOpenDeleteSwot}
              handleEditSwotElement={handleEditSwotElement}
              handleViewSwotElement={handleViewSwotElement}
            />
          </SwotCardContainer>
        </Grid>
      </Grid>

      <SwotModal
        isModalOpen={isSwotModalOpen}
        setIsModalOpen={setIsSwotModalOpen}
        swotType={swotType}
        selectedSwotElement={selectedSwotElement}
      />

      {/*TODO: send entity id once we clarify how comments should work*/}
      <DetailsDrawerWrapper
        isDrawerOpen={isSwotDrawerOpen}
        onCloseDrawer={handleCloseDrawer}
        onEdit={() => handleEditSwotElement(selectedSwotElement)}
        onDelete={() => handleOpenDeleteSwot(selectedSwotElement._id)}
        commentType={CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB.swot}
        drawerTitle={intl.formatMessage({ id: 'label.visualizeSwot' })}
      >
        <SwotDetails swot={selectedSwotElement} />
      </DetailsDrawerWrapper>

      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteSwot' })}
          hasActions
          onSubmit={handleDeleteSwotElement}
        />
      )}
    </>
  );
};

export default SwotPage;
