import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TeamBox = styled('div')(({ theme, hasMultiple }) => `
  padding: ${theme.spacing(8, 0)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(8)};
  cursor: ${hasMultiple ? 'pointer' : 'initial'};
`);

export const TeamOption = styled('div')(({ theme, isSelected }) => `
  padding: ${theme.spacing(8, 16)};
  cursor: pointer;
  background-color: ${isSelected ? alpha(theme.palette.primary.main, 0.1) : theme.palette.common.white};
  
  &:hover {
    background-color: ${alpha(theme.palette.primary.main, 0.1)};
  }
`);
