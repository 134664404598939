import { Avatar } from '@mui/material';

const UserAvatar = (props) => {
  const {
    src = '',
    userInitials,
    height = '30px',
    width = '30px',
    variant = 'circular',
    sx = {},
  } = props;

  return (
    <Avatar
      sx={{
        height, width, fontSize: '14px', ...sx,
      }}
      variant={variant}
      src={src}
    >
      {userInitials}
    </Avatar>
  );
};

export default UserAvatar;
