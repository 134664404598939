// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Joyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TourContext from '../../../context/tourContext';

import { useApi } from '../../../hooks/useApi';
import { updateOnboardingProgress } from '../../../requests/onboarding';
import { editProfile as doEditProfile } from '../../../requests/userProfile';
import { isAdminSelector } from '../../../store/auth/login';
import { userProfileSelector } from '../../../store/userProfile/selectors';

import { handleApiError } from '../../../utils/errorUtils';
import { joyRideStyleOverride, ONBOARDING_PATH_NAMES, ONBOARDING_STEPS_BY_ROLE } from '../constants';

const JoyRideTour = () => {
  const intl = useIntl();
  const {
    runTour, stopTour, startTour, tourName, currentStepIndex, setCurrentStep
  } = useContext(TourContext);
  const dispatch = useDispatch();
  const [, runUpdateTourProgress] = useApi(updateOnboardingProgress);
  const userProfile = useSelector(userProfileSelector);
  const isAdmin = useSelector((state) => isAdminSelector(state?.account));
  const { onCallback, steps } = useMemo(() => (
    ONBOARDING_STEPS_BY_ROLE?.[userProfile?.userId?.role]?.find(({ pathName }) => pathName === tourName) || {}
  ), [userProfile, tourName]);

  const [isTourActive, setIsTourActive] = useState(true);
  const { push } = useHistory();

  useEffect(() => {
    setIsTourActive(runTour);
  }, [runTour]);

  const handleCallback = (data) => {
    if (onCallback) {
      onCallback({
        data,
        setIsTourActive,
        stopTour,
        push,
        setCurrentStep,
        runUpdateTourProgress,
        dispatch,
      });
    }
  };

  useEffect(() => {
    if (!userProfile?.hasSeenAppTour&& userProfile?.hasCompletedQuestionnaire
      && userProfile?.onboardings && !isAdmin
    ) {
      startTour(ONBOARDING_PATH_NAMES.platformFundamentals);
      handleUpdateProfile();
    }
  }, [userProfile, isAdmin]);

  const handleUpdateProfile = async () => {
    try {
      const payload = {
        ...userProfile,
        hasSeenAppTour: true,
      };
      await doEditProfile(payload);
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <Joyride
      callback={handleCallback}
      continuous
      spotlightClicks
      disableOverlayClose
      hideBackButton
      stepIndex={currentStepIndex}
      steps={steps}
      run={isTourActive}
      locale={{
        next: `${intl.formatMessage({ id: 'button.next' })} ➔`,
        last: intl.formatMessage({ id: 'button.closeGuide' }),
      }}
      styles={joyRideStyleOverride}
    />
  );
};

export default JoyRideTour;
