import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import PageTitle from '../../common/components/PageTitle';
import {
  MrlGuideContainer,
  MrlGuideStep,
  CounterContainer,
  StepLabel,
} from '../styled/MrlStyled';

import { editMrlOrder, loadMrl } from '../../modules/mrl/store';

const MrlGuide = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const mrlData = useSelector((state) => state.mrl.mrls);

  const [columns, setColumns] = useState();

  useEffect(() => {
    if (mrlData) setColumns({ mrl: { items: mrlData } });
  }, [mrlData]);

  useEffect(() => {
    dispatch(loadMrl());
  }, []);

  const updateOrder = (list) => {
    list.forEach((item, index) => (item.order = index));
    setColumns((prev) => ({ ...prev, mrl: { items: list } }));
    dispatch(editMrlOrder(list));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceColumn = columns[source.droppableId];
    const copiedItems = [...sourceColumn.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    updateOrder(copiedItems);
  };

  if (!columns) return <div>Loading...</div>;

  const sortedItems = columns.mrl.items.sort((a, b) => a.order - b.order);

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'label.mrlGuide' })} />
      <MrlGuideContainer className="scrollbar">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="mrl">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sortedItems.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <MrlGuideStep
                          onClick={() => {
                            history.push(`/admin/stage/${item._id}`);
                          }}
                        >
                          <CounterContainer>{index + 1}</CounterContainer>
                          <StepLabel>{item.name}</StepLabel>
                        </MrlGuideStep>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </MrlGuideContainer>
    </>
  );
};

export default MrlGuide;
