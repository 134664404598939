/* eslint max-len: 0 */

export const FunnelPart1 = () => (
  <svg width="564" height="127" viewBox="0 0 564 127" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M217.301 2.66951C177.067 5.35303 39.1586 15.5541 7.28842 49.8321C-16.5383 75.4594 30.212 92.2005 44.7079 97.1326C156.211 135.072 374.386 135.153 494.216 104.512C513.713 99.526 541.975 91.1002 556.517 77.1948C580.97 53.8121 533.63 34.8217 519.219 29.9616C433.602 1.08426 309.463 -3.47817 217.301 2.66951Z" fill="#74A3FE" />
  </svg>
);

export const FunnelPart2 = () => (
  <svg width="584" height="163" viewBox="0 0 584 163" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M284.798 48.4425C215.993 48.1681 98.897 41.4703 30.372 15.9329C19.2112 11.7739 10.4725 6.3588 0.73291 1.8602C2.12035 5.22352 51.5514 80.312 61.3158 98.2399L84.3589 138.787C90.0966 150.038 90.8309 147.452 102.018 149.407C212.229 168.67 371.059 165.502 481.755 149.457C501.589 146.582 491.711 150.149 511.066 118.423L546.365 58.8843C550.62 52.0555 554.093 46.2419 558.436 39.4789C566.627 26.7246 575.8 13.7947 583.28 0.891602C551.129 16.1023 555.428 16.706 518.039 26.3405C449.306 44.0516 354.413 48.7951 284.798 48.4425Z" fill="#74A3FE" />
  </svg>
);

export const FunnelPart3 = () => (
  <svg width="392" height="121" viewBox="0 0 392 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M391.978 0.561212C360.58 1.93247 328.746 9.05289 296.551 10.3693C263.327 11.7275 230.66 13.535 197.008 13.5371C163.483 13.5391 130.568 11.7385 97.4986 10.4413C64.7257 9.15577 33.0652 3.41965 0.719727 0.484375L44.8538 90.0572C56.2403 114.754 52.7128 113.313 63.3432 114.354C106.922 118.62 151.256 120.14 195.982 120.143C240.237 120.146 285.303 119.334 328.799 114.156C336.318 113.261 337.345 115.332 340.848 107.313L376.72 31.1754C380.733 23.2161 389.945 7.85858 391.978 0.561212Z"
      fill="#FFB800" />
  </svg>
);

export const FunnelPart4 = () => (
  <svg width="274" height="116" viewBox="0 0 274 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.111 111.489C104.134 115.819 170.079 117.116 229.972 111.428L273.259 0.28125C182.649 10.8143 90.7179 6.90151 0.198242 1.13523C1.12498 6.14008 19.0612 46.9617 22.6469 56.0439L44.111 111.489Z"
      fill="#FB791C" />
  </svg>
);

export const FunnelBottomArrow = () => (
  <svg width="270" height="38" viewBox="0 0 270 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M135.533 37.7193C146.962 34.8464 157.96 31.6147 169.075 28.4956L269.965 0.813982C229.74 0.126638 81.1974 0.574508 30.3119 0.605377L0.0664062 0.624L101.243 28.6253C109.244 30.7539 128.316 36.3226 135.533 37.7193Z" fill="#CCCCCC" />
  </svg>
);

export const FunnelBottomCircle1 = () => (
  <svg width="73" height="19" viewBox="0 0 73 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.30487 7.64603L2.67037 7.98722L2.30487 7.64603C1.48498 8.52434 1.20047 9.41486 1.40732 10.2742C1.6041 11.0918 2.2194 11.7567 2.90828 12.2787C4.29115 13.3267 6.27182 14.02 7.16626 14.3231C14.1771 16.699 24.4943 17.8784 34.9003 17.9943C45.3111 18.1101 55.8732 17.162 63.3856 15.2487C64.6057 14.9379 66.115 14.5163 67.5527 13.9512C68.9815 13.3896 70.3847 12.6691 71.3596 11.7406C72.2104 10.9303 72.5167 10.0672 72.3118 9.20971C72.1196 8.40553 71.5029 7.73229 70.8151 7.19625C69.428 6.11526 67.4384 5.35472 66.5328 5.05052C55.7866 1.44054 40.2621 0.879575 28.7706 1.64304C26.2648 1.8095 20.7027 2.20993 15.2657 3.10277C12.5481 3.54905 9.84773 4.12054 7.56981 4.85235C5.31306 5.57735 3.38948 6.48415 2.30487 7.64603Z"
      fill="#BA3232" stroke="#BA3232" />
  </svg>

);

export const FunnelBottomCircle2 = () => (
  <svg width="338" height="69" viewBox="0 0 338 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M130.785 2.60883C118.802 3.30439 92.2628 4.97485 66.3413 8.69449C53.3815 10.5542 40.5634 12.9279 29.7908 15.952C19.0416 18.9696 10.2453 22.6566 5.40508 27.1874C1.77772 30.583 0.730214 33.7964 1.54653 36.76C2.34582 39.6617 4.89523 42.1576 8.05452 44.2497C14.3917 48.4462 23.5812 51.2722 27.8888 52.5478C61.1556 62.3992 110.292 67.3267 160.012 67.8102C209.736 68.2938 260.1 64.333 295.831 56.381C301.642 55.0876 308.774 53.3451 315.542 51.0203C322.3 48.699 328.742 45.7818 333.144 42.1189C336.873 39.0154 337.985 35.9415 337.186 33.0189C336.407 30.1725 333.851 27.6409 330.678 25.4807C324.309 21.1431 315.05 18.0352 310.753 16.7738C259.684 1.78244 185.689 -0.578662 130.785 2.60883Z"
      fill="white" stroke="#CCCCCC" />
  </svg>

);

export const FunnelBottomCircle3 = () => (
  <svg width="566" height="114" viewBox="0 0 566 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M218.552 3.01949C198.416 4.195 153.825 7.01767 110.277 13.3022C88.5031 16.4444 66.9774 20.4537 48.8932 25.5593C30.8323 30.6583 16.1203 36.8725 8.05082 44.4692L8.39355 44.8332L8.05082 44.4692C2.0091 50.1569 0.336828 55.4628 1.66252 60.3029C2.97154 65.0822 7.16689 69.2412 12.4587 72.7654C23.0604 79.8258 38.456 84.5901 45.7013 86.7478C101.565 103.385 184.107 111.712 267.654 112.529C351.206 113.347 435.819 106.653 495.834 93.2206C505.597 91.0355 517.568 88.0935 528.925 84.1706C540.271 80.251 551.051 75.3366 558.399 69.1865C564.606 63.9913 566.376 58.9252 565.081 54.1641C563.806 49.4779 559.599 45.2586 554.282 41.6176C543.625 34.3187 528.109 29.0777 520.891 26.947C435.12 1.626 310.81 -2.36705 218.552 3.01949Z"
      fill="white" stroke="#CCCCCC" />
  </svg>
);
