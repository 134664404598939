import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DocumentTitle } from '../../common/components';
import { Loading } from '../../common/styled';
import { Resolver } from '../../core/components';

import { currentTeamIdSelector } from '../../common/store';
import { loadMentors } from '../../store/mentors/mentorsSlice';

import MentorsPage from '../../modules/mentors/containers/Mentors';

const MentorsPageResolver = (props) => {
  const { loadMentors, teamId } = props;
  const intl = useIntl();
  const loadDependencies = () =>
    teamId ? loadMentors(teamId) : Promise.resolve();

  return (
    <>
      <DocumentTitle>{intl.formatMessage({ id: 'label.mentors' })}</DocumentTitle>
      <Resolver
        successComponent={MentorsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

MentorsPageResolver.propTypes = {
  loadMentors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { loadMentors };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MentorsPageResolver)
);
