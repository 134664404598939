import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import DrawerComponent from '../../../../common/components/DrawerComponent';
import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import MentorCard from '../../components/MentorCard';

import { tabsWithReviews } from '../../../../constants/drawerTabs';
import { DRAWER_TABS } from '../../../../constants/drawerTabs';

const MentorDrawer = ({ isOpen, onClose, mentor, sendInvitation }) => {
  const intl = useIntl();
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);

  return (
    <DrawerComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <DrawerTitle
        title={intl.formatMessage({ id: 'label.expertProfile' })}
        onClose={onClose}
      />

      <DrawerTabs
        sx={{ mt: 4, mb: 50 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
        tabs={tabsWithReviews}
      />

      <MentorCard
        mentor={mentor}
        sendInvitation={sendInvitation}
      />
    </DrawerComponent>
  );
};

export default MentorDrawer;
