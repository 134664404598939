const MuiButton = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(6, 20),
      borderRadius: '5px',
      boxShadow: 'none',
      fontWeight: 600,

      '&.link': {
        padding: 0,
        paddingLeft: '4px',
        fontWeight: 400,

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      '&.Mui-disabled .MuiButton-startIcon svg path': {
        fill: 'rgba(0, 0, 0, 0.26)',
      }
    }),
    outlinedSecondary: ({ theme }) => ({
      borderColor: theme.palette.general.accentLight,
      color: theme.palette.general.accentLight,

      '& .MuiButton-startIcon svg path': {
        fill: theme.palette.general.accentLight,
      },

      '&:hover': {
        borderColor: theme.palette.general.accentLight,
      },
    }),
    outlinedError: ({ theme }) => ({
      borderColor: theme.palette.error.secondary,
      color: theme.palette.error.secondary,

      '& .MuiButton-startIcon svg path': {
        fill: theme.palette.error.secondary,
      },
    }),
    containedSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.general.accentLight,
      color: theme.palette.common.white,
    }),
    textSecondary: ({ theme }) => ({
      color: theme.palette.general.accentLight,
    }),
    containedError: ({ theme }) => ({
      backgroundColor: theme.palette.error.secondary,
      color: theme.palette.common.white,
    }),
  },
};

export default MuiButton;
