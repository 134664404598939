import { formatTimestampInRomanian } from '../../utils/dateHelpers';

export const getAllPaymentsSerializer = (payments) => {
  const paymentsData = payments.data || [];
  const formattedPayments = paymentsData.map((payment) => {
    const { status, amount, currency, created, id, invoice, description } =
      payment;
    return {
      status: status === 'succeeded' ? 'Platit' : 'Neplatit',
      amount: `${amount / 100} ${currency.toUpperCase()}`,
      date: formatTimestampInRomanian(created),
      id,
      invoiceId: invoice,
      plan: description,
      actions: 'Descarca factura',
      customStyles: {
        status: {
          color: status === 'succeeded' ? '#00B87C' : '#FF0000',
        },
        actions: {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          cursor: 'pointer',
        },
      },
    };
  });

  return formattedPayments;
};

export const getAllProductsSerializer = (productDetails) => {
  const productsData = productDetails || {};
  const { products, prices } = productsData;

  const result = {
    monthly: [],
    yearly: [],
  };

  prices.data.forEach((price) => {
    const correspondingProduct = products.data.find((product) => {
      const { product: productId } = price;
      return product.id === productId;
    });

    if (price.recurring.interval === 'month') {
      result.monthly.push({
        ...correspondingProduct,
        price: price.unit_amount / 100,
        recurrence: 'luna',
        priceId: price.id,
      });
    } else if (price.recurring.interval === 'year') {
      result.yearly.push({
        ...correspondingProduct,
        price: price.unit_amount / 100,
        recurrence: 'an',
        priceId: price.id,
      });
    }

    Object.keys(result).forEach((key) => {
      result[key].sort((a, b) => a.price - b.price);
    });
  });

  return result;
};
