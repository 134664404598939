import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import store from './store/store';

import { theme } from './assets/styles';
import appTheme from './assets/theme/theme';
import './assets/theme/index.css';

import AppComponent from './AppComponent';

const App = () => {

  return (
    <MuiThemeProvider theme={appTheme}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AppComponent />
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
