import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import ComingSoon from '../../common/components/_ComingSoon';
import ButtonComponent from '../../common/components/ButtonComponent';
import DrawerComponent from '../../common/components/DrawerComponent';
import PageTitle from '../../common/components/PageTitle';
import Tabs from '../../common/components/Tabs';
import { TabContent } from '../../common/components/Tabs/index.styled';

import { RESOURCES_TABS } from '../../modules/resources/constants';

import { SchoolIcon } from '../../assets/icons';

import Articles from '../../modules/resources/containers/Articles';
import Courses from '../../modules/resources/containers/Courses';
import SideGuide from '../../modules/resources/containers/SideGuide';
import { GuidePaper } from './index.styled';

const pageComponents = {
  chapters: {
    component: <Courses />,
  },
  articles: {
    component: <Articles />,
  },
  patterns: {
    component: <ComingSoon />,
  },
};

export const ResourcesPage = () => {
  const intl = useIntl();
  const theme = useTheme();
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const tabFromQuery = query.get('tab') || 'articles';
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/resources?tab=${tab}`);
  };

  const handleCloseDrawer = () => setIsDrawerOpen(false);

  const handleOpenDrawer = () => setIsDrawerOpen(true);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PageTitle title={intl.formatMessage({ id: 'label.resources' })} />

        {isTablet && (
          <ButtonComponent
            onClick={handleOpenDrawer}
            icon={<SchoolIcon size="18px" />}
            label={intl.formatMessage({ id: 'label.content' })}
          />
        )}
      </Box>

      <Tabs
        tabs={RESOURCES_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
      />

      <Box display="flex" gap={20} position="relative" width="100%" height="calc(100% - 118px)">
        <TabContent offsetTop={0} className="scrollbar">
          {pageComponents[activeTab].component}
        </TabContent>

        {!isTablet && (
          <GuidePaper className="scrollbar">
            <SideGuide />
          </GuidePaper>
        )}
      </Box>

      <DrawerComponent isOpen={isDrawerOpen} onClose={handleCloseDrawer}>
        <SideGuide />
      </DrawerComponent>
    </>
  );
};
