import { Box, Typography } from '@mui/material';
import React from 'react';

import { InfoIcon } from '../index.styled';

const InfoMessage = ({ message }) => (
  <Box display="flex" mt={40} alignItems="flexStart">
    <InfoIcon />
    <Box width="80%" ml={10}>
      <Typography color="common.black">
        {message}
      </Typography>
    </Box>
  </Box>
);

export default InfoMessage;
