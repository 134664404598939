import { get } from 'lodash-es';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogComponent from '../../../../common/components/DialogComponent';
import DeleteDetails from '../../components/DeleteDetails';
import DeleteReason from '../../components/DeleteReason';
import MoveToFreeAccount from '../../components/MoveToFreeAccount';

import { setCurrentUserTeam } from '../../../../common/store';
import { currentTeamSelector } from '../../../../common/store/userTeamsSelector';
import { notifySuccess } from '../../../../core/store';
import { saveQuestionAnswer } from '../../../../requests/chestionar';
import { downgradeSubscription } from '../../../../requests/payments';
import { deleteAccount } from '../../../../requests/userProfile';
import { logout } from '../../../../store/auth/login';

import { USER_ROLES } from '../../../../constants/roles';
import { handleApiError } from '../../../../utils/errorUtils';

import { MULTIPLE_ANSWER_TYPE, SINGLE_ANSWER_TYPE } from '../../../onboarding/steps';

const DeleteSteps = {
  reason: 'reason',
  freeAccount: 'freeAccount',
  transferOwnership: 'transferOwnership',
};

const TitleId = {
  [DeleteSteps.reason]: 'modal.title.sorryYouAreGivingUpYourAccount',
  [DeleteSteps.transferOwnership]: 'modal.title.deleteProfile',
  [DeleteSteps.freeAccount]: 'modal.title.chooseYourFreeIdea'
};

const DeleteAccount = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [step, setStep] = useState(DeleteSteps.reason);
  const userTeams = useSelector((state) => state.common?.userTeams?.userTeams);
  const currentTeam = useSelector(currentTeamSelector) || {};
  const role = useSelector((state) => get(state.account, 'user.role'));
  const loggedUser = useSelector((state) => state.userProfile.userProfile?.userId);

  const { subscription: currentSubscription, _id: userId, email } = loggedUser;
  const hasActiveSubscription = Boolean(currentSubscription?.amountDecimal);

  const formatTransferOwnershipMap = (ideas, idea) => {
    return ideas
      .filter(({ id }) => id !== idea)
      .reduce((acc, curr) => {
        acc[curr.id] = curr.newOwner || 'none';
        return acc;
      }, {});
  };

  const handleDowngradeSubscription = async (selectedTeamId, transferOwnershipMap = {}) => {
    try {
      setIsSubmitting(true);
      await downgradeSubscription({
        transferOwnershipMap,
        selectedTeam: selectedTeamId,
        verifyEmail: email
      });

      if (currentTeam._id !== selectedTeamId) {
        dispatch(setCurrentUserTeam(userId, selectedTeamId));
      }

      dispatch(notifySuccess(<FormattedMessage id="success.subscriptionUpdated" />));
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteAccount = async (transferOwnershipMap = {}) => {
    try {
      setIsSubmitting(true);
      const deleteReason = {
        questionId: 'deleteAccountReason',
        questionType: MULTIPLE_ANSWER_TYPE,
        multipleAnswers: [
          ...deleteData.reason,
          deleteData.otherReason,
        ],
      };
      const whatCouldWeDoBetter = {
        questionId: 'whatCouldWeDoBetter',
        questionType: SINGLE_ANSWER_TYPE,
        singleAnswer: deleteData.description,
      };

      await saveQuestionAnswer(deleteReason);
      await saveQuestionAnswer(whatCouldWeDoBetter);
      await deleteAccount({
        transferOwnershipMap,
        verifyEmail: email
      });

      dispatch(logout());
      dispatch(notifySuccess(<FormattedMessage id="success.accountDeleted" />));
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitDeleteReason = (values) => {
    if (role !== USER_ROLES.Founder) {
      handleDeleteAccount();
    } else if (role === USER_ROLES.Founder && (!userTeams?.length || userTeams?.members?.length === 1)) {
      handleDeleteAccount(userTeams?.length ? { [currentTeam._id]: 'none' } : null);
    } else {
      setDeleteData(values);
      setStep(DeleteSteps.transferOwnership);
    }
  };

  const submitSelectFreeAccount = () => {
    if (userTeams?.length <= 1) {
      handleDowngradeSubscription(currentTeam._id);
    } else {
      setStep(DeleteSteps.freeAccount);
    }
  };

  const submitDeleteAccount = (values) => {
    handleDeleteAccount({
      ...formatTransferOwnershipMap(values.ideas),
      ...formatTransferOwnershipMap(values.uniqueOwnerIdeas),
    });
  };

  const submitDowngradeAccount = (values) => {
    handleDowngradeSubscription(
      values.idea,
      {
        ...formatTransferOwnershipMap(values.ideas, values.idea),
        ...formatTransferOwnershipMap(values.uniqueOwnerIdeas, values.idea),
      },
    );
  };

  return (
    <>
      <ButtonComponent
        variant="outlined"
        color="error"
        onClick={() => setIsDialogOpen(true)}
        label={intl.formatMessage({ id: 'label.deleteAccount' })}
      />

      {isDialogOpen && (
        <DialogComponent
          open={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          title={intl.formatMessage({ id: TitleId[step] })}
        >
          {step === DeleteSteps.reason && (
            <DeleteReason
              hasActiveSubscription={hasActiveSubscription}
              onSubmit={submitDeleteReason}
              onFreeAccount={submitSelectFreeAccount}
              isSubmitting={isSubmitting}
            />
          )}
          {step === DeleteSteps.transferOwnership && (
            <DeleteDetails
              userId={userId}
              userTeams={userTeams}
              onSubmit={submitDeleteAccount}
              isSubmitting={isSubmitting}
            />
          )}
          {step === DeleteSteps.freeAccount && (
            <MoveToFreeAccount
              userId={userId}
              userTeams={userTeams}
              onSubmit={submitDowngradeAccount}
              isSubmitting={isSubmitting}
            />
          )}
        </DialogComponent>
      )}
    </>
  );
};

export default DeleteAccount;
