import React from 'react';
import { Table } from 'react-bootstrap';

import { TableData, TableHead } from '../styled/GenericTableComponents';

const GenericTable = ({
  headers = [],
  rows = [],
  rowOnClick = null,
  cellOnClick = null,
}) => (
  <div>
    <Table>
      <thead>
        <tr>
          {headers.map((header) => (
            <TableHead key={header.key} style={{ ...header.customStyles }}>
              {header.name}
            </TableHead>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id} onClick={rowOnClick ? () => rowOnClick(row) : null}>
            {headers.map((header) => (
              <TableData
                key={header.key}
                style={{ ...row.customStyles[header.key] }}
                onClick={cellOnClick ? () => cellOnClick(row, header) : null}
              >
                <span style={{ display: 'flex', gap: '4px' }}>
                  {header.cellIcon && header.cellIcon}
                  <span>{row[header.key]}</span>
                </span>
              </TableData>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default GenericTable;
