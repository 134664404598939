import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { Step } from '../index.styled';

const Stepper = ({ activeStep, steps }) => {
  const intl = useIntl();

  return (
    <Box mb={20}>
      <span>
        {intl.formatMessage(
          { id: 'label.questionNo' },
          { value: `${activeStep + 1}/${steps.length}` }
        )}
      </span>
      <Box display="flex" gap={2}>
        {steps.map((el, idx) => (
          <Step
            key={el.questionCode}
            activeStep={activeStep >= idx}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Stepper;
