import { Box, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { getPlatformStats } from '../../../requests/system';

import { ReactComponent as FoundersIcon } from '../../../assets/icons/platformStats/Founders.svg';
import { ReactComponent as HypothesisIcon } from '../../../assets/icons/platformStats/Login_Hypothesis.svg';
import { ReactComponent as MyIdeasIcon } from '../../../assets/icons/platformStats/Login_Idei.svg';
import { ReactComponent as MentorIcon } from '../../../assets/icons/platformStats/Mentor.svg';
import { ReactComponent as ExperimentsIcon } from '../../../assets/icons/platformStats/Navigation_Experiment-1.svg';

const Element = ({ children, value = 0, isLoading }) => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
    {isLoading
      ? <Skeleton height={27} width={27} />
      : <Typography variant="h2" color="primary">{value}</Typography>}
    <Box height="50px" display="flex" alignItems="center">
      {children}
    </Box>
  </Box>
);

const PlatformStats = () => {
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    try {
      setIsLoading(true);
      const { data } = await getPlatformStats();
      setStats(data);
    } catch (e) {
      setStats({});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" gap={16} alignItems="center" justifyContent="center">
      <Element isLoading={isLoading} value={stats.numberOfFounders}>
        <FoundersIcon style={{ height: '50px', width: '50px' }} />
      </Element>
      <Element isLoading={isLoading} value={stats.numberOfMentors}>
        <MentorIcon style={{ height: '50px', width: '50px' }} />
      </Element>
      <Element isLoading={isLoading} value={stats.numberOfTeams}>
        <MyIdeasIcon style={{ height: '40px', width: '40px' }} />
      </Element>
      <Element isLoading={isLoading} value={stats.numberOfHypotheses}>
        <HypothesisIcon style={{ height: '40px', width: '40px' }} />
      </Element>
      <Element isLoading={isLoading} value={stats.numberOfExperiments}>
        <ExperimentsIcon style={{ height: '40px', width: '40px' }} />
      </Element>
    </Box>
  );
};

export default PlatformStats;
