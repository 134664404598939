import {
  AccountProfileIcon,
  AccountConditionsIcon,
  PaymentIcon,
  SettingsIcon,
  SubscriptionIcon,
} from '../../assets/icons';

export const PROFILE_PAGE_TABS = [
  {
    key: 'profile',
    label: 'label.profile',
    icon: <AccountProfileIcon />,
  },
  {
    key: 'settings',
    label: 'label.settings',
    icon: <SettingsIcon />,
  },
  {
    key: 'subscriptions',
    label: 'label.subscriptions',
    icon: <SubscriptionIcon />,
  },
  {
    key: 'payments',
    label: 'label.payments',
    icon: <PaymentIcon />,
  },
  {
    key: 'conditions',
    label: 'label.conditions',
    icon: <AccountConditionsIcon />,
  },
];
