import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import AddButton from '../../../../common/components/AddButton';
import DetailsCard from '../../../../common/components/DetailsCard';
import DetailsDrawerWrapper from '../../../../common/components/DetailsDrawerWrapper';
import DialogAlert from '../../../../common/components/DialogAlert';
import { getAllIndustriesByTeamId, deleteIndustryById } from '../../../../store/contextAnalysis';
import IndustryDetails from '../../components/IndustryDetails';
import { IndustryModal } from '../../components/IndustryModal';

import { currentTeamIdSelector } from '../../../../common/store';

import { INDUSTRY_TYPE } from '../../../../constants/industry';
import { INDUSTRY_TYPES_LABELS } from '../../../../constants/labels';
import { CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';
import { INDUSTRY_ADORNMENTS } from '../../constants';

import { IndustryContentWrapper } from './index.styled';

const DIALOG_TYPES = {
  edit: 'EDIT',
  delete: 'DELETE',
};

const Industries = ({
  teamId,
  getAllIndustriesByTeamId,
  teamIndustries,
  deleteIndustryById,
}) => {
  const intl = useIntl();
  const [dialogType, setDialogType] = useState('');
  const [industryType, setIndustryType] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const industryTypeLabel = selectedIndustry?.industryType
    ? intl.formatMessage({ id: INDUSTRY_TYPES_LABELS[selectedIndustry?.industryType]?.title })
    : '';

  useEffect(() => {
    if (teamId) {
      getAllIndustriesByTeamId(teamId);
    }
  }, [teamId]);

  const handleOpenDialog = (type, industry) => {
    setDialogType(type);

    if (industry) {
      setSelectedIndustry(industry);
    }
  };

  const handleClose = () => setDialogType('');

  const handleCloseDialog = () => {
    setDialogType('');
    setSelectedIndustry(null);
  };

  const handleAddIndustry = (type) => {
    setIndustryType(type);
    handleOpenDialog(DIALOG_TYPES.edit);
  };

  const handleViewIndustry = (industry) => {
    setSelectedIndustry(industry);
    setIsDrawerOpen(true);
  };

  const handleEditIndustry = (industry) => {
    setSelectedIndustry(industry);
    handleOpenDialog(DIALOG_TYPES.edit);
    setIsDrawerOpen(false);
  };

  const handleDeleteIndustry = () => {
    deleteIndustryById(selectedIndustry._id, teamId);
    setSelectedIndustry(null);
    setIsDrawerOpen(false);
    handleCloseDialog();
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedIndustry(null);
  };

  const renderLowerCase = (chunks) => (
    <Typography component="span" textTransform="lowercase" variant="h3" color="primary">
      {chunks}
    </Typography>
  );

  return (
    <>
      <Grid height='100%' container>
        {Object.keys(INDUSTRY_TYPE).map((type, idx) => (
          <Grid height='100%' item xs key={type}>
            <IndustryContentWrapper isFirst={idx === 0} isLast={idx === Object.keys(INDUSTRY_TYPE).length - 1}>
              <Box
                id={`industry-type-${type}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                  gap: 8,
                }}
              >
                <Typography variant="body1" fontWeight={700} color="primary">
                  {intl.formatMessage({ id: INDUSTRY_TYPES_LABELS[type].title })}
                </Typography>
                <AddButton onClick={() => handleAddIndustry(type)} />
              </Box>
              {teamIndustries && teamIndustries[type] && (
                <Box className="scrollbar" sx={{ overflowY: 'auto' }}>
                  {teamIndustries[type].map((industry, index) => (
                    <DetailsCard
                      key={industry._id}
                      title={industry.title}
                      description={industry.description}
                      onView={() => handleViewIndustry(industry)}
                      onEdit={() => handleEditIndustry(industry)}
                      onDelete={() => handleOpenDialog(DIALOG_TYPES.delete, industry)}
                      ml={5}
                      sx={{ mb: teamIndustries[type]?.length !== index + 1 ? 10 : 0 }}
                    >
                      <Typography variant="body1" color="primary" fontWeight={700}>
                        {`${INDUSTRY_ADORNMENTS[industry.influence]} `}
                      </Typography>
                    </DetailsCard>
                  ))}
                </Box>
              )}
              {teamIndustries && !teamIndustries[type] && (
                <Typography variant="body1" className="italic" color="textSecondary">
                  {intl.formatMessage({ id: INDUSTRY_TYPES_LABELS[type].description })}
                </Typography>
              )}
            </IndustryContentWrapper>
          </Grid>
        ))}
      </Grid>

      <IndustryModal
        industryType={industryType}
        selectedIndustry={selectedIndustry}
        setSelectedIndustry={setSelectedIndustry}
        isModalOpen={dialogType === DIALOG_TYPES.edit}
        onCloseModal={handleCloseDialog}
      />

      <DialogAlert
        isModalOpen={dialogType === DIALOG_TYPES.delete}
        onClose={handleClose}
        onSubmit={handleDeleteIndustry}
        hasActions
        title={intl.formatMessage({ id: 'modal.title.deleteIndustryElement' })}
        content={intl.formatMessage({ id: 'modal.content.deleteIndustry' })}
      />

      <DetailsDrawerWrapper
        isDrawerOpen={isDrawerOpen}
        onCloseDrawer={handleCloseDrawer}
        onEdit={() => handleEditIndustry(selectedIndustry)}
        onDelete={() => handleOpenDialog(DIALOG_TYPES.delete, selectedIndustry)}
        commentType={CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB.industry}
        drawerTitle={industryTypeLabel
          ? intl.formatMessage(
            { id: 'label.visualizeType' },
            { type: industryTypeLabel, c: renderLowerCase }
          ) : ''
        }
      >
        <IndustryDetails industry={selectedIndustry} />
      </DetailsDrawerWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  teamIndustries: state.contextAnalysis.teamIndustries,
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  getAllIndustriesByTeamId,
  deleteIndustryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Industries);
