import { Box } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => `
  min-width: 440px;
  min-height: 200px;
  padding: ${theme.spacing(20)};
  border-radius: 10px;
`);

export const DialogContent = styled(MuiDialogContent)(() => `
  overflow-y: auto;
  flex: initial;
  padding: 0;
`);

export const DialogActionWrapper = styled(Box)(({ theme }) => `
  position: sticky;
  bottom: 0;
  height: 50px;
  display: flex;
  align-items: flex-end;
  background-color: ${theme.palette.common.white};
  width: 100%;
  z-index: 1;
`);
