import { styled } from '@mui/material/styles';

export const ChartBackgroundColumn = styled('div')(({ theme }) => `
  border: 2px solid ${theme.palette.general.accentLight};
  height: 100%;
  border-radius: 4px;
  opacity: 0.5;
  position: relative;
`);

export const ChartBackgroundColumnPaddedBox = styled('div')(({ theme }) => `
  position: absolute;
  bottom: -30px;
  color: ${theme.palette.general.lightBlue2};
`);
