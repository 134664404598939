import { ClickAwayListener, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import ButtonComponent from '../../../common/components/ButtonComponent';

import {
  deleteCommentById,
  editCommentById,
  getAllComments,
  postNewComment,
} from '../store';

import { COMMENT_TOOLBAR_CONFIG } from '../../../constants/richTextEditor';

import { CloseIcon } from '../../../assets/icons';

import RenderRichText from '../../../fields/RichTextEditor';
import { BoxShadow, Content } from '../index.styled';
import CommentAndRepliesList from './CommentAndRepliesList';

const GenericComments = ({
  teamId,
  userId,
  type,
  entityId = null,
  comments = [],
  fetchComments,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const query = new URLSearchParams(useLocation().search);
  const queryCommentId = query.get('comment') || '';
  const [commentToReplyTo, setCommentToReplyTo] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [edditedReply, setEdditedReply] = useState(null);
  const [showRichText, setShowRichText] = useState(false);

  const fetchFullComments = () => {
    dispatch(
      getAllComments({
        teamId,
        filterParams: {
          isMainThread: true,
        },
        userId,
      })
    );
  };

  const handleFetchComments = async () => {
    await fetchComments();
    fetchFullComments();
  };

  const generateRichTextEditorTitle = () => {
    if (commentToReplyTo) {
      return 'label.answerComment';
    }

    if (editingComment) {
      return 'label.editComment';
    }

    if (edditedReply) {
      return 'label.editAnswer';
    }

    return 'label.addComment';
  };

  const schema = Yup.object().shape({
    description: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  const initialValues = {
    description: '',
  };

  const handleAbort = () => {
    setEditingComment(null);
    setCommentToReplyTo(null);
    setEdditedReply(null);
    inputRef.current.clearEditor();
  };

  const handleClickOutside = () => {
    if (showRichText) {
      handleAbort();
    }
  };

  const handleHideRichText = () => {
    setShowRichText(false);
  };

  const handleAddNewComment = () => {
    setShowRichText(true);
    inputRef.current.focusEditor();
  };

  const handleSubmit = (values) => {
    if (edditedReply) {
      dispatch(
        editCommentById({
          commentId: edditedReply._id,
          comment: values.description,
          teamId,
          type,
          userId,
          ...(entityId && { entityId }),
        })
      );

      setEdditedReply(null);
      inputRef.current.clearEditor();
      setShowRichText(false);
      return;
    }

    if (editingComment) {
      dispatch(
        editCommentById({
          commentId: editingComment._id,
          comment: values.description,
          teamId,
          type,
          userId,
          ...(entityId && { entityId }),
        })
      );

      setEditingComment(null);
      inputRef.current.clearEditor();
      setShowRichText(false);
      return;
    }

    if (commentToReplyTo) {
      dispatch(
        postNewComment({
          message: values.description,
          teamId,
          type,
          userId,
          parentId: commentToReplyTo._id,
          ...(entityId && { entityId }),
        })
      );

      setCommentToReplyTo(null);
      inputRef.current.clearEditor();
      setShowRichText(false);
      return;
    }

    dispatch(
      postNewComment({
        message: values.description,
        teamId,
        type,
        userId,
        ...(entityId && { entityId }),
      })
    );

    inputRef.current.clearEditor();
    setShowRichText(false);
  };

  const handleReply = (e, comment) => {
    setShowRichText(true);
    e.stopPropagation();
    setCommentToReplyTo(comment);
    inputRef.current.clearEditor();
    inputRef.current.focusEditor();
  };

  const handleDeleteComment = (e, commentId) => {
    e.stopPropagation();
    dispatch(
      deleteCommentById({
        commentId,
        teamId,
        type,
        userId,
        ...(entityId && { entityId }),
      })
    );
  };

  const handleDeleteReply = (e, replyId) => {
    e.stopPropagation();
    dispatch(
      deleteCommentById({
        commentId: replyId,
        teamId,
        type,
        userId,
        ...(entityId && { entityId }),
      })
    );
  };

  const handleEditComment = (e, comment) => {
    setShowRichText(true);
    e.stopPropagation();
    setEdditedReply(null);
    setCommentToReplyTo(null);
    setEditingComment(comment);

    inputRef.current.setContentAndFocus(comment.title);
  };

  const handleEditReply = (e, reply, comment) => {
    setShowRichText(true);
    e.stopPropagation();
    setCommentToReplyTo(null);
    setEditingComment(null);
    setEdditedReply({ ...reply, hypothesisCommentId: comment._id });
    inputRef.current.setContentAndFocus(reply.title);
  };

  return (
    <Content>
      <Box
        className="scrollbar"
        flexGrow={1}
        sx={{ overflowY: 'auto' }}
      >
        {comments.length ? (
          <CommentAndRepliesList
            fetchComments={handleFetchComments}
            queryCommentId={queryCommentId}
            commentsArray={comments}
            handleDeleteComment={handleDeleteComment}
            handleEditComment={handleEditComment}
            handleReply={handleReply}
            handleDeleteReply={handleDeleteReply}
            handleEditReply={handleEditReply}
          />
        ) : (
          <Box>
            <Typography className="italic" color="general.accentLight">
              {intl.formatMessage({ id: 'label.noComments' })}
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <BoxShadow />
        <ClickAwayListener onClickAway={handleClickOutside}>
          <Box display={showRichText ? 'block' : 'none'} mt={16}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
              <Typography variant="h3" fontWeight={600} color="primary">
                {intl.formatMessage({ id: generateRichTextEditorTitle() })}
              </Typography>
              <IconButton color="primary" onClick={handleHideRichText}>
                <CloseIcon fill="currentColor" />
              </IconButton>
            </Box>
            <Box height="230px">
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
              >
                {({ values }) => (
                  <RenderRichText
                    show={showRichText}
                    name="description"
                    config={COMMENT_TOOLBAR_CONFIG}
                    ref={inputRef}
                    height="230px"
                  >
                    <Box display="flex" justifyContent="flex-end" p={10}>
                      <ButtonComponent
                        color="secondary"
                        sx={{ ml: 10 }}
                        onClick={() => handleSubmit(values)}
                        label={`${intl.formatMessage({ id: 'button.send'})} ➔`}
                      />
                    </Box>
                  </RenderRichText>
                )}
              </Formik>
            </Box>
          </Box>
        </ClickAwayListener>

        <Box display={showRichText ? 'none' : 'flex'} mt={28}>
          <Box display="flex" justifyContent="center" width="100%">
            <ButtonComponent
              color="secondary"
              fullWidth
              sx={{ width: '90%' }}
              onClick={handleAddNewComment}
              label={intl.formatMessage({ id: 'button.addNewComment' })}
            />
          </Box>
        </Box>
      </Box>
    </Content>
  );
};

export default GenericComments;
