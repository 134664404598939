import { EXISTING_FLAGS, isFeatureDisabled } from '../../utils/featureFlag';

import {
  FlagIcon,
  QuizIcon,
} from '../../assets/icons';

export const BMC_MODELS_TABS = [
  {
    key: 'viabilityModel',
    label: 'label.viabilityModel',
    icon: <QuizIcon />,
    id: 'bmc-tab-viability-model',
  },
  {
    key: 'terminalsModel',
    label: 'label.terminalsModel',
    icon: <FlagIcon />,
    id: 'bmc-tab-terminals-model',
    isHidden: isFeatureDisabled(EXISTING_FLAGS.bmcModels.terminalsModel),
  },
];
