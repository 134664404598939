import MuiButton from '@mui/material/Button';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';

export const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => (`
  padding: ${theme.spacing(3)};
  border-radius: 5px;
  box-shadow: none;
  background-color: ${theme.palette.common.white};
  gap: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.general.accentLight};

  & .MuiButtonGroup-grouped:not(:first-of-type) {
    border-radius: 5px;
    border: 0;
  }

  & .MuiButtonGroup-grouped:not(:last-of-type) {
    border-radius: 5px;
    border: 0;
  }

  & .activeButton {
    background-color: ${theme.palette.general.accentLight};
    color: ${theme.palette.common.white};
    font-weight: 600;
  }

  & .Mui-disabled:not(.activeButton) {
    background-color: transparent !important;
    color: ${theme.palette.text.primary};
  }

  & .Mui-disabled.activeButton {
    background-color: ${theme.palette.general.accentLight};
    color: ${theme.palette.common.white};
  }
`));

export const Button = styled(MuiButton)(({ theme }) => (`
  padding: ${theme.spacing(3, 20)};
  border-radius: 5px;
  background-color: ${theme.palette.common.white};
  color: ${theme.palette.general.accentLight};
  font-size: 12px;
  line-height: 16.34px;

  &:hover {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.white};
  }
`));
