import { FormattedMessage } from 'react-intl';

export const AVAILABLE_FEATURES_BY_SUBSCRIPTION_PLAN = {
  free: [
    {
      text: <FormattedMessage id="features.option1" />,
      sign: '⚠',
      id: 1,
      color: 'orange',
    },
    {
      text: <FormattedMessage id="features.option2" />,
      sign: '✓',
      id: 2,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option3" />,
      sign: '✓',
      id: 3,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option4" />,
      sign: '✓',
      id: 4,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option5" />,
      sign: '✓',
      id: 5,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option6" />,
      sign: '✓',
      id: 6,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option7" />,
      sign: '⚠',
      id: 7,
      color: 'orange',
    },
    {
      text: <FormattedMessage id="features.option8" />,
      sign: '⊘',
      id: 8,
      color: 'red',
    },
    {
      text: <FormattedMessage id="features.option9" />,
      sign: '⊘',
      id: 9,
      color: 'red',
    },
    {
      text: <FormattedMessage id="features.option10" />,
      sign: '⊘',
      id: 10,
      color: 'red',
    },
    {
      text: <FormattedMessage id="features.option11" />,
      sign: '⊘',
      id: 11,
      color: 'red',
    },
    {
      text: <FormattedMessage id="features.option11" />,
      sign: '⊘',
      id: 11,
      color: 'red',
    },
  ],
  paid: [
    {
      text: <FormattedMessage id="features.option13" />,
      sign: '✓',
      id: 1,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option2" />,
      sign: '✓',
      id: 2,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option3" />,
      sign: '✓',
      id: 3,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option4" />,
      sign: '✓',
      id: 4,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option5" />,
      sign: '✓',
      id: 5,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option6" />,
      sign: '✓',
      id: 6,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option13" />,
      sign: '✓',
      id: 7,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option8" />,
      sign: '✓',
      id: 8,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option9" />,
      sign: '✓',
      id: 9,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option10" />,
      sign: '✓',
      id: 10,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option11" />,
      sign: '✓',
      id: 11,
      color: 'blue',
    },
    {
      text: <FormattedMessage id="features.option12" />,
      sign: '✓',
      id: 12,
      color: 'blue',
    },
  ],
};
