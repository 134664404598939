import { Box, Typography } from '@mui/material';

import { CustomButton, Root } from './index.styled';

const SquareButtonsField = ({
  label,
  name,
  value,
  onChange,
  option1,
  option2,
  sx = {},
}) => (
  <Root sx={sx}>
    <Typography variant="body1" color="textSecondary">
      {label}
    </Typography>

    <Box display="flex" alignItems="center" gap={4}>
      <CustomButton onClick={() => onChange(name, true)} isActive={!!value}>
        {option1}
      </CustomButton>
      <CustomButton onClick={() => onChange(name, false)} isActive={!value}>
        {option2}
      </CustomButton>
    </Box>
  </Root>
);

export default SquareButtonsField;
