const MuiFormControlLabel = {
  styleOverrides: {
    root: ({ theme: muiTheme }) => ({
      color: muiTheme.palette.text.primary,
    }),
  },
};

const MuiFormHelperText = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginLeft: 0,
      ...theme.typography.caption,
    }),
  },
};

const MuiFormLabel = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiTypography-root': {
        ...theme.typography.body2,
        fontWeight: 600,
      },
    }),
  },
};

export {
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
};
