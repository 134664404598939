import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

const TransferOwnership = ({ onClose, isOpen, onSubmit }) => {
  const intl = useIntl();
  return (
    <DialogComponent
      open={isOpen}
      handleClose={onClose}
      onSubmit={onSubmit}
      hasActions
      submitLabel={'button.transferOwnership'}
      title={intl.formatMessage({ id: 'modal.title.makeAdmin' })}
    >
      <Typography variant="body1" mb={20}>
        {intl.formatMessage({ id: 'modal.content.makeAdmin' })}
      </Typography>
    </DialogComponent>
  );
};

export default TransferOwnership;
