import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Navigation from '../../components/Navigation';
import RowPillAnswer from '../../components/RowPillAnswer';
import StepTitle from '../../components/StepTitle';

import { Content } from '../../index.styled';

const RowsOptionsStep = ({
  isInvited,
  stepInfo,
  selectedAnswers,
  onSelect,
  onSubmit,
  onBack,
  isDisabled,
  hasBackButton,
}) => {
  const userRole = useSelector((state) => state?.account?.user?.role);
  return (
    <>
      <Content>
        <Box mt={50} mb={30}>
          <StepTitle title={stepInfo.question} />

          {stepInfo.questionAdditionalInformation && (
            <Typography className="italic">
              {stepInfo.questionAdditionalInformation}
            </Typography>
          )}

          <Box mt={60}>
            <RowPillAnswer
              isInvited={isInvited}
              selectedAnswers={selectedAnswers}
              onSelect={onSelect}
              options={stepInfo.answerOptions}
              preselected={stepInfo.preselected?.[userRole]}
            />
          </Box>
        </Box>
      </Content>

      <Navigation
        onBack={onBack}
        onSubmit={onSubmit}
        disabled={isDisabled}
        hasBackButton={hasBackButton}
      />
    </>
  );
};

export default RowsOptionsStep;
