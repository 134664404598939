import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { formatRewardUM, formatUserName } from '../../../../utils/formatters';
import { getExperienceTitle } from '../../../../utils/labels';

import { AddIcon } from '../../../../assets/icons';

import { ProfileAvatar } from '../../../userProfileDetails/containers/UserDetails/index.styled';
import ExperienceDetails from '../ExperienceDetails';
import { CustomChip, ProChip } from './index.styled';

const Mentor = ({ mentor, sendInvitation, openDetails, rewardAtTop }) => {
  const intl = useIntl();
  const buttonName = useMemo(() => {
    if (mentor.isInvited) {
      return intl.formatMessage({ id: 'label.invited' });
    }

    return mentor.isMember
      ? intl.formatMessage({ id: 'label.member' })
      : intl.formatMessage({ id: 'button.invite' });
  }, [mentor.userId?._id]);

  const handleInvite = (e) => {
    e.stopPropagation();
    sendInvitation(mentor);
  };

  const openMentorDetails = () => {
    if (openDetails) {
      openDetails(mentor);
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" onClick={openMentorDetails}>
      <Box display="flex" alignItems="start" gap={10} mb={20}>
        <ProfileAvatar src={mentor.profilePicture} variant="square" size={60} />
        <Box>
          <Box display="flex" gap={8}>
            <Typography variant="h3" color="primary">
              {formatUserName(mentor)}
            </Typography>
            {mentor.proMember && (
              <ProChip
                size="small"
                variant="outlined"
                label={intl.formatMessage({ id: 'label.pro' })}
              />
            )}
          </Box>

          {mentor.title && (
            <Typography className="faded">
              {mentor.title}
            </Typography>
          )}
          {!!mentor.numberOfActiveTeams && (
            <CustomChip>
              <Typography variant="body2" color="primary">
                {intl.formatMessage({ id: 'label.nrOfIdeas' }, { count: mentor.numberOfActiveTeams  })}
              </Typography>
            </CustomChip>
          )}
        </Box>
      </Box>

      <Box mb={20} display="flex" alignItems="center" gap={8} flexWrap="wrap">
        {mentor.expertise?.map((expertise, index) => (
          <CustomChip key={`expertise-${index}`}>
            <Typography variant="body2" color="primary">
              {getExperienceTitle(expertise)}
            </Typography>
          </CustomChip>
        ))}
        {rewardAtTop && mentor.rewardPreference?.map((reward, index) => (
          <CustomChip key={`reward-${index}`}>
            <Typography variant="body2" color="primary">
              {`${reward.title} - ${formatRewardUM(reward)}`}
            </Typography>
          </CustomChip>
        ))}
      </Box>

      <Box mb={15}>
        <Typography fontWeight={600} color="primary" gutterBottom>
          {intl.formatMessage({ id: 'label.description' })}
        </Typography>
        <Typography>
          {mentor.about}
        </Typography>
      </Box>

      <Box>
        {mentor.expertise?.map((expertise, index) => (
          <ExperienceDetails key={index} experience={expertise} />
        ))}
      </Box>

      {!rewardAtTop && (
        <Box mt={15}>
          <Typography fontWeight={600} color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.rewardPreference' })}
          </Typography>
          {mentor.rewardPreference?.map((reward, index) => (
            <CustomChip key={`reward-${index}`} sx={{ mr: 8 }}>
              <Typography variant="body2" color="primary">
                {`${reward.title} - ${formatRewardUM(reward)}`}
              </Typography>
            </CustomChip>
          ))}
        </Box>
      )}

      <Box flexGrow={1} display="flex" alignItems="flex-end" mt={20}>
        <Box width="100%">
          <ButtonComponent
            size="small"
            fullWidth
            onClick={handleInvite}
            disabled={mentor.isInvited || mentor.isMember}
            icon={<AddIcon fill="currentColor" width='22px' height='22px' />}
            label={buttonName}
            color="secondary"
          />
          {/*<Box display="flex" gap={8} width="100%" mt={8}>*/}
          {/*  <ButtonComponent*/}
          {/*    size="small"*/}
          {/*    disabled*/}
          {/*    fullWidth*/}
          {/*    icon={<AddReactionIcon fill="currentColor" width='22px' height='22px' />}*/}
          {/*    label={intl.formatMessage({ id: 'button.addReview' })}*/}
          {/*    color="secondary"*/}
          {/*    variant="outlined"*/}
          {/*  />*/}
          {/*  <ButtonComponent*/}
          {/*    size="small"*/}
          {/*    disabled*/}
          {/*    fullWidth*/}
          {/*    icon={<EventAvailableIcon fill="currentColor" width='22px' height='22px' />}*/}
          {/*    label={intl.formatMessage({ id: 'button.availability' })}*/}
          {/*    color="secondary"*/}
          {/*    variant="outlined"*/}
          {/*  />*/}
          {/*</Box>*/}
        </Box>
      </Box>
    </Box>
  );
};

export default Mentor;
