import { Drawer } from '@mui/material';

const modalProps = {
  keepMounted: true, // Better open performance on mobile.
};

const paperProps = {
  sx: { p: 20, width: '400px' },
  className: 'scrollbar',
};

const DrawerComponent = ({ isOpen, onClose, children, sx = {} }) => (
  <Drawer
    variant="temporary"
    anchor="right"
    open={isOpen}
    onClose={onClose}
    ModalProps={modalProps}
    PaperProps={paperProps}
    sx={sx}
  >
    {children}
  </Drawer>
);

export default DrawerComponent;
