import { styled } from '@mui/material/styles';

export const HypothesisCardContainer = styled('div')(({
  customerSegment,
  theme,
  empathy,
  floatRight,
  allowClick,
  experiment,
}) => `
  min-height: 87px;
  justify-content: space-between;
  ${floatRight && 'float: right;'}
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  border: 1px solid ${theme.palette.general.accentLight};
  ${empathy && 'width: 100%;'}
  &:not(:last-child) {
    margin-bottom: ${theme.spacing(10)};
  }
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: ${theme.spacing(10)};
  ${allowClick && 'cursor: pointer;'}
  ${customerSegment && `padding: ${theme.spacing(0, 0, 0, 10)};`}
  ${customerSegment && 'flex-direction: row;'}
  ${experiment && `
    background: ${theme.palette.background.secondary};
    padding: ${theme.spacing(8, 5)};
    justify-content: space-between;
  `}
`);

export const HypothesisColor = styled('div')(({ backgroundColor, theme, marginBottom }) => `
  margin-right: ${theme.spacing(3)};
  border-radius: 2px;
  width: 10px;
  height: 10px;
  background-color: ${backgroundColor || 'initial'};
  margin-bottom: ${marginBottom || '0px'};
`);

export const HypothesisTitle = styled('div')(({
  theme,
  lineClamp,
  height,
  width = '100%',
  decorationThrough,
  hover,
}) => `
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.palette.text.primary};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineClamp || 2};
  margin-bottom: ${theme.spacing(5)};
  overflow: hidden;
  height: ${height || ''};
  width: ${width};
  text-decoration: ${decorationThrough ? `line-through ${theme.palette.general.lightBlue3}` : 'none'};
  ${hover && `
    :hover {
      -webkit-line-clamp: initial;
      white-space: normal;
      height: auto;
    }
  `}
`);

export const ColorColumnDiv = styled('div')(({ backgroundColor, theme }) => `
  background-color: ${backgroundColor || theme.palette.general.accentLight};
  height: 100%;
  width: 9px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
`);

export const SegmentCartContainer = styled('div')(({ marginBottom, customStyles, borderColor, theme }) => `
  min-height: 87px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  border: ${borderColor ? `1px solid ${borderColor}` : '1px solid #74a3fe'};
  border-radius: 10px;
  margin-bottom: ${marginBottom};
  padding: ${theme.spacing(10, 15, 10, 10)};
  cursor: pointer;
  ${customStyles}
`);

export const EmptyRoot = styled('div')(({ theme, hasColumn }) => `
  padding: ${theme.spacing(10)};
  position: relative;
  height: 87px;
  width: 300px;
  border: 1px dashed ${theme.palette.general.borderAccent};
  border-right-style: ${hasColumn ? 'solid' : 'dashed'};
  border-radius: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`);
