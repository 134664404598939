import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash-es';

import RichTextContainer from '../../../../common/components/RichTextContainer';

import { Root, ValueWrapper } from './index.styled';

const SegmentDetails = ({
  title,
  placeholder,
  size = null,
  content,
}) => (
  <Root>
    <Box display="flex" alignItems="center" gap={10} mb={5} pr={5}>
      <Typography variant="h3" fontWeight={600} color="primary">
        {title}
      </Typography>
      {!isNil(size) && (
        <ValueWrapper variant="body1" fontWeight={600} color="primary">
          {size}
        </ValueWrapper>
      )}
    </Box>
    <Box className="scrollbar" sx={{ overflowY: 'scroll' }} height="95px" pr={5}>
      {content ? (
        <RichTextContainer text={content} fontSize="14px">
          {content}
        </RichTextContainer>
      ) : (
        <Typography variant="body1" className="italic" color="general.placeholder">
          {placeholder}
        </Typography>
      )}
    </Box>
  </Root>
);

export default SegmentDetails;
