import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import DialogAlert from '../../../common/components/DialogAlert';
import GenericEmptyTable from '../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../common/components/GenericTableComponent';

import { currentTeamIdSelector } from '../../../common/store';
import { useApi } from '../../../hooks/useApi';
import { deleteDetailByIdDeprecated, getAllDetailsDeprecated } from '../../../requests/details';

import { DETAIL_CATEGORIES } from '../../../constants/detail';
import { NOT_APPLICABLE } from '../../../constants/general';
import { DETAIL_CATEGORY_LABELS } from '../../../constants/labels';
import {
  activityTypeOptions,
  problemSeverityOptions,
} from '../../../constants/options';
import { formatDate } from '../../../utils/dateHelpers';
import { searchLabel } from '../../../utils/labels';

import { TrashIconOutlined } from '../../../assets/icons';

const DetailsComponent = () => {
  const intl = useIntl();
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const [{ result: details = [] }, fetchDetails] = useApi(getAllDetailsDeprecated);
  const [, deleteDetail] = useApi(deleteDetailByIdDeprecated);
  const [idToDelete, setIdToDelete] = useState('');

  const handleFetchDetails = () => {
    const payload = {
      params: { teamId },
    };
    fetchDetails(payload);
  };

  const handleDeleteDetail = async () => {
    const payload = {
      params: { detailId: idToDelete },
    };
    await deleteDetail(payload);
    await handleFetchDetails();
    setIdToDelete('');
  };

  const determineLevelOfInterest = (activityType, levelOfInterest) =>
    levelOfInterest || '-';

  const determineContactDisponibility = (
    activityType,
    contactDisponibility
  ) => {
    if (!contactDisponibility) {
      return '-';
    }
    return intl.formatMessage({ id: contactDisponibility === true ? 'label.yes' : 'label.no' });
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: intl.formatMessage({ id: 'label.designation' }), key: 'description' },
    { type: 'string', content: intl.formatMessage({ id: 'label.activity' }), key: 'activity' },
    { type: 'string', content: intl.formatMessage({ id: 'label.category' }), key: 'category' },
    { type: 'string', content: intl.formatMessage({ id: 'label.severity' }), key: 'problemSeverity' },
    { type: 'string', content: intl.formatMessage({ id: 'label.purchase' }), key: 'levelOfInterest' },
    { type: 'string', content: intl.formatMessage({ id: 'label.contact' }), key: 'contactDisponibility' },
    { type: 'string', content: intl.formatMessage({ id: 'label.date' }), key: 'createdAt' },
    ...(details.length
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const createRows = () =>
    details.map(
      (
        {
          _id,
          title,
          activityType,
          problemSeverity,
          category,
          createdAt,
          levelOfInterest,
          contactDisponibility,
          parentDetailId = null,
        },
        index
      ) => {
        const categoryLabel = DETAIL_CATEGORY_LABELS[category];
        const activityLabel = searchLabel(activityType, activityTypeOptions);
        const problemSeverityLabel = searchLabel(problemSeverity, problemSeverityOptions);
        const formattedTitle = category === DETAIL_CATEGORIES.userStory && parentDetailId?.title
          ? `${intl.formatMessage({ id: 'label.userStory' })} - ${parentDetailId?.title}`
          : title;

        return {
          rowId: _id,
          order: index + 1,
          title: formattedTitle,
          activityType: activityLabel ? intl.formatMessage({ id: activityLabel }) : NOT_APPLICABLE,
          category: categoryLabel ? intl.formatMessage({ id: categoryLabel }) : NOT_APPLICABLE,
          problemSeverity: problemSeverityLabel ? intl.formatMessage({ id: problemSeverityLabel }) : NOT_APPLICABLE,
          levelOfInterest: determineLevelOfInterest(activityType, levelOfInterest),
          contactDisponibility: determineContactDisponibility(activityType, contactDisponibility),
          createdAt: formatDate(createdAt),
          // creator: <CreatorCell firstName={firstName} lastName={lastName} />, // TO add here profile picture from above
          action: (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setIdToDelete(_id);
              }}
              color="error"
            >
              <TrashIconOutlined />
            </IconButton>
          ),
        };
      });

  useEffect(() => {
    if (teamId) {
      handleFetchDetails();
    }
  }, []);
  return (
    <>
      {details && !!details.length
        ? <GenericTableComponent rows={createRows()} headerCells={createTableHeaderCells()} />
        : (
          <GenericEmptyTable
            headers={createTableHeaderCells()}
            detailMessage={`${intl.formatMessage({ id: 'label.noDetailWasAdded' })}...`}
          />
        )}

      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteDetail' })}
          hasActions
          onSubmit={handleDeleteDetail}
        />
      )}
    </>
  );
};

export default DetailsComponent;
