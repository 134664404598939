import update from 'immutability-helper';

import { loadCommunity as doLoadTeams } from '../../requests/leaderboard';

// Actions
const START_LOAD = 'teams/START_LOAD';
const COMPLETE_LOAD = 'teams/COMPLETE_LOAD';
const FAIL_LOAD = 'teams/FAIL_LOAD';

const RESET = 'teams/RESET';

// Initial state
const initialState = {
  isLoading: false,
  teams: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          teams: action.teams,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          teams: undefined,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (teams) => ({
  type: COMPLETE_LOAD,
  teams,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadCommunity = (params) => (dispatch) => {
    dispatch(startLoad());
    return doLoadTeams(params)
      .then((res) => dispatch(completeLoad(res.data)))
      .catch(() => dispatch(failLoad()));
  };
