import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import AccordionComponent from '../../../../common/components/AccordionComponent';
import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogAlert from '../../../../common/components/DialogAlert';
import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import InformationRow from '../../../../common/components/InformationRow';
import { Loading } from '../../../../common/styled';
import GenericComments from '../../../commentsAndNotifications/components/GenericComments';
import AddCharacteristicsModal from '../../components/AddCharacteristicsModal';

import { currentTeamIdSelector } from '../../../../common/store';
import {
  getClientProfileCharacteristic,
  httpDeleteCharacteristicsElement,
  updateClientProfileCharacteristics,
} from '../../../../requests/hypothesis';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { DRAWER_TABS } from '../../../../constants/drawerTabs';
import { handleApiError } from '../../../../utils/errorUtils';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';

import {
  CampaignIcon,
  EditIcon,
  TrashIconOutlined,
} from '../../../../assets/icons';

import UserStoryDetailDrawer from '../UserStoryDetailDrawer';
import { Title } from './index.styled';

const DIALOG_TYPE = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

const CharacteristicDetails = ({
  onRefresh,
  onDelete,
  initialCharacteristic,
  onCloseDrawer,
  onBack = null,
  canEdit,
  userStoriesCount,
}) => {
  const intl = useIntl();
  const { hypothesisId } = useParams();
  const dispatch = useDispatch();

  const [characteristic, setCharacteristic] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogType, setDialogType] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeUserStory, setActiveUserStory] = useState(null);
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);

  const currentUserId = useSelector((state) => state.userProfile.userProfile.userId._id);
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile]) || [];
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));

  useEffect(() => {
    if (initialCharacteristic) {
      fetchCharacteristic();
    }
  }, [initialCharacteristic]);

  const fetchCharacteristic = async () => {
    setIsLoading(true);

    try {
      const response = await getClientProfileCharacteristic(initialCharacteristic?.id);
      setCharacteristic(response?.data);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile,
          entityId: hypothesisId,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  const handleOpen = (type) => {
    setDialogType(type);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      await httpDeleteCharacteristicsElement(initialCharacteristic?.id);
      handleClose();
      onRefresh();

      if (onDelete) {
        onDelete();
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditCharacteristic = async (values) => {
    setIsSubmitting(true);
    try {
      await updateClientProfileCharacteristics(initialCharacteristic?.id, values);
      handleClose();
      fetchCharacteristic();
      onRefresh();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewUserStory = (userStory) => setActiveUserStory(userStory);

  const handleCloseViewUserStory = () => setActiveUserStory(null);

  const handleDeleteUserStory = () => {
    handleCloseViewUserStory();
    onRefresh();
  };

  if (activeUserStory) {
    return (
      <UserStoryDetailDrawer
        initialDetail={{ _id: activeUserStory?._id }}
        onClose={handleCloseViewUserStory}
        onSuccessDelete={handleDeleteUserStory}
        parentDetail={activeUserStory?.parentDetailId}
        onBack={handleCloseViewUserStory}
        onRefresh={onRefresh}
      />
    );
  }

  return (
    <>
      <DrawerTitle
        title={intl.formatMessage({ id: 'label.visualizeProfileElement' })}
        onClose={onCloseDrawer}
        onBack={onBack}
      />

      <DrawerTabs
        sx={{ mt: 4, mb: 10 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />

      {activeDrawerTab === DRAWER_TABS.details ? (
        <Box display="flex" flexDirection="column" gap={20}>
          <InformationRow
            value={characteristic?.description}
            label={intl.formatMessage({ id: 'label.title' })}
          />

          {canEdit && (
            <Box display="flex" alignItems="center" gap={10}>
              <ButtonComponent
                onClick={() => handleOpen(DIALOG_TYPE.EDIT)}
                variant="outlined"
                color="secondary"
                icon={<EditIcon fill="currentColor" size="14px" />}
                label={intl.formatMessage({ id: 'button.edit' })}
              />
              <ButtonComponent
                onClick={() => handleOpen(DIALOG_TYPE.DELETE)}
                icon={<TrashIconOutlined size="16px" />}
                color="error"
                label={intl.formatMessage({ id: 'button.delete' })}
              />
            </Box>
          )}

          <Divider sx={{ my: 10, borderColor: 'general.accentLight2' }} />

          <AccordionComponent
            title={intl.formatMessage(
              { id: 'label.connectedUserStoriesCount' },
              { count: characteristic?.userStories?.length || 0, totalCount: userStoriesCount }
            )}
          >
            <Box display="flex" flexDirection="column" gap={8}>
              {characteristic?.userStories?.map(({ _id, parentDetailId }) => (
                <Box display="flex" alignItems="center" gap={4} key={_id}>
                  <CampaignIcon size="18px" />
                  <Title
                    variant="body1"
                    className="text-ellipsis"
                    onClick={() => handleViewUserStory({ _id, parentDetailId })}
                  >
                    {parentDetailId?.title}
                  </Title>
                </Box>
              ))}
            </Box>
          </AccordionComponent>

          <Loading isLoading={isLoading} />

          <DialogAlert
            isModalOpen={isOpen && dialogType === DIALOG_TYPE.DELETE}
            onClose={handleClose}
            title={intl.formatMessage({ id: 'modal.title.deleteElementProfile' })}
            content={intl.formatMessage({
              id: 'modal.content.deleteElementProfile',
            })}
            hasActions
            onSubmit={handleDelete}
            isDisabled={isSubmitting}
          />

          <AddCharacteristicsModal
            isModalOpen={isOpen && dialogType === DIALOG_TYPE.EDIT}
            onClose={handleClose}
            characteristic={characteristic}
            onSubmit={handleEditCharacteristic}
            isEditMode
            isSubmitting={isSubmitting}
          />
        </Box>
      ) : (
        <GenericComments
          fetchComments={fetchComments}
          comments={comments}
          teamId={teamId}
          userId={currentUserId}
          type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile}
          entityId={hypothesisId}
        />
      )}
    </>
  );
};

export default CharacteristicDetails;
