import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DialogAlert from '../../../../common/components/DialogAlert';

import { currentTeamSelector } from '../../../../common/store/userTeamsSelector';
import { notifySuccess } from '../../../../core/store';
import { deleteMentor, resendInvitation } from '../../../../requests/mentors';

import { handleApiError } from '../../../../utils/errorUtils';

import { ReplayIcon, ThreeDotsIcon, TrashIconOutlined } from '../../../../assets/icons';

const MentorMenu = ({ member, runUpdateTeamMentors }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteEmail, setDeleteEmail] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentTeam = useSelector(currentTeamSelector) || {};
  const open = Boolean(anchorEl);
  const { _id: teamId } = currentTeam;

  const resendMentorInvitation = async () => {
    try {
      setIsSubmitting(true);
      await resendInvitation(teamId, member.email);
      dispatch(notifySuccess(intl.formatMessage({ id: 'success.invitationSendSuccessfully' })));
      handleCloseMenu();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteMentorUser = async () => {
    try {
      setIsSubmitting(true);
      await deleteMentor(teamId, member.email);
      await runUpdateTeamMentors();
      setDeleteEmail(null);
      handleCloseMenu();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="moreButton"
        aria-controls={open ? 'moreMenu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <ThreeDotsIcon color="white" size={18} />
      </IconButton>
      <Menu
        id="moreMenu"
        MenuListProps={{
          'aria-labelledby': 'moreButton',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {member.isInvited && (
          <MenuItem disabled={isSubmitting} onClick={resendMentorInvitation}>
            <Typography color="general.accentLight" display="flex" alignItems="center" gap={8}>
              <ReplayIcon size={18} />
              {intl.formatMessage({ id: 'button.resendInvitation' })}
            </Typography>
          </MenuItem>
        )}

        <MenuItem disabled={isSubmitting} onClick={() => setDeleteEmail(true)}>
          <Typography color="error.secondary" display="flex" alignItems="center" gap={8}>
            <TrashIconOutlined size={18} />
            {intl.formatMessage({ id: 'button.delete' })}
          </Typography>
        </MenuItem>
      </Menu>

      {deleteEmail && (
        <DialogAlert
          isModalOpen
          onClose={() => setDeleteEmail(false)}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteMentor' })}
          hasActions
          isDisabled={isSubmitting}
          onSubmit={deleteMentorUser}
        />
      )}
    </>
  );
};

export default MentorMenu;
