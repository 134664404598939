import { styled } from '@mui/material/styles';

export const MyIdeaTag = styled('div')(
  ({ theme }) => `
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  color: ${theme.palette.general.purple};
  border: 1px solid ${theme.palette.general.purple};
`
);

export const MyIdeaTitle = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  margin-left: ${theme.spacing(10)}
`
);

export const AvatarWrapper = styled('div')(
  ({ theme, hasPicture }) => `
    border-radius: 10px;
    cursor: pointer;
    border: ${
      hasPicture ? 'none' : `1px solid ${theme.palette.general.accentLight}`
    };
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img { 
      height: ${hasPicture ? '100%' : '50px'};
      width: ${hasPicture ? '100%' : '50px'};
      border-radius: 10px;
    }
`
);
