export const BMC_CUSTOMER_SEGMENT_COLORS = [
  '#FF6B6B',
  '#FFD166',
  '#06D6A0',
  '#118AB2',
  '#073B4C',
  '#EF476F',
  '#2A9D8F',
  '#264653',
  '#E76F51',
  '#F4A261',
  '#2A9D8F',
  '#E9C46A',
  '#F9C74F',
  '#90BE6D',
];

export const IMPORTANCE_LABELS = {
  high: 'label.big',
  medium: 'label.medium',
  low: 'label.small',
};

export const STATUS_LABELS = {
  validated: 'label.valid',
  invalidated: 'label.invalid',
};

export const DEFAULT_FILTERS = {
  importance: {
    high: false,
    medium: false,
    low: false,
  },
  status: {
    validated: false,
    invalidated: false,
  }
};
