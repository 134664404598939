import { Box, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import PlansDescription from '../../components/PlansDescription';

import {
  cancelSubscription,
  initCheckoutSession,
  updateSubscription
} from '../../../../requests/payments';

import { handleApiError } from '../../../../utils/errorUtils';
import { formatSubscriptionPrice } from '../../../../utils/formatters';

import {
  SubscriptionBox,
  SubscriptionsTableContainer,
} from './index.styled';

const Subscriptions = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [isYearlyActive, setIsYearlyActive] = useState(true);
  const currentSubscription = useSelector((state) => state?.userProfile?.userProfile?.userId?.subscription) || {};
  const userProfile = useSelector((state) => state?.userProfile?.userProfile) || {};
  const subscriptionProducts = useSelector((state) => state.userProfile?.allSubscriptionProducts) || {};

  const currentSubscriptionPrice = formatSubscriptionPrice(currentSubscription?.amountDecimal);
  const activeSubscriptionChoices = subscriptionProducts[isYearlyActive ? 'yearly' : 'monthly'] || [];

  useEffect(() => {
    if (currentSubscription?.planRecurringPeriod) {
      setIsYearlyActive(currentSubscription.planRecurringPeriod === 'yearly');
    }
  }, [currentSubscription?.planRecurringPeriod]);

  const handleToggle = () => {
    setIsYearlyActive(!isYearlyActive);
  };

  const handleUpgrade = async (item) => {
    setIsLoading(true);

    try {
      if (currentSubscription?.stripeSubscriptionId && currentSubscription?.stripeCustomerId) {
        await updateSubscription({ planId: item.priceId });
        ReactGA.gtag('event', 'change_subscription', {
          userId: userProfile?.userId?._id,
          type: 'subscription',
          value: item?.price,
          currency: item.currency,
          items: [item],
          previousAmount: currentSubscriptionPrice,
          previousRecurrence: currentSubscription.planRecurringPeriod,
        });
        window.location.reload();
      } else {
        const { data } = await initCheckoutSession(item.priceId);

        ReactGA.gtag('event', 'purchase', {
          userId: userProfile?.userId?._id,
          type: 'subscription',
          value: item?.price,
          currency: item.currency,
          items: [item],
        });

        if (data.redirectUrL) {
          window.location.href = data.redirectUrL;
        }
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!currentSubscriptionPrice) {
      return;
    }

    try {
      setIsLoading(true);
      await cancelSubscription();
      ReactGA.gtag('event', 'unsubscribe', {
        userId: userProfile?.userId?._id,
        previousAmount: currentSubscriptionPrice,
        previousRecurrence: currentSubscription.planRecurringPeriod,
      });
      window.location.reload();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SubscriptionsTableContainer className="scrollbar">
      <Table>
        <thead>
          <tr>
            <th className="pl-0">
              <Typography variant="h2" color="primary">
                {intl.formatMessage({ id: 'label.subscriptionTypes' })}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography color="primary" className={!isYearlyActive && 'faded'}>
                  {intl.formatMessage({ id: 'label.yearly' })}
                </Typography>

                <Switch checked={!isYearlyActive} onClick={handleToggle} />

                <Typography color="primary" className={isYearlyActive && 'faded'}>
                  {intl.formatMessage({ id: 'label.monthly' })}
                </Typography>
              </Box>
            </th>

            <th>
              <SubscriptionBox>
                <Typography color="primary" variant="h3">
                  {`1. ${intl.formatMessage({ id: 'label.explorer' })}`}
                </Typography>
                <Typography className="faded" fontWeight={700}>
                  {intl.formatMessage({ id: 'label.free' })}
                </Typography>
                {!currentSubscription?.cancelAt && (
                  <ButtonComponent
                    color="secondary"
                    disabled={isLoading}
                    onClick={handleCancelSubscription}
                    label={(
                      intl.formatMessage({
                        id: currentSubscriptionPrice
                          ? 'label.unsubscribe'
                          : 'label.currentPlan'
                      })
                    )}
                  />
                )}
              </SubscriptionBox>
            </th>

            {activeSubscriptionChoices.map((product, index) => (
              <th
                key={`${product.name}-${index}`}
                onClick={() => !isLoading && handleUpgrade(product)}
              >
                <SubscriptionBox>
                  <Typography variant="h3" color="primary">{`${index + 2}.`} {product.name}</Typography>
                  <Typography className="faded" fontWeight={700}>
                    {product.price} lei / {product.recurrence}
                  </Typography>
                  <ButtonComponent
                    color="secondary"
                    disabled={currentSubscriptionPrice === product.price}
                    label={(
                      intl.formatMessage({
                        id: currentSubscriptionPrice === product.price
                          ? 'label.currentPlan'
                          : 'label.upgrade'
                      })
                    )}
                  />
                </SubscriptionBox>
              </th>
            ))}
          </tr>
        </thead>
        <PlansDescription />
      </Table>
    </SubscriptionsTableContainer>
  );
};

export default Subscriptions;
