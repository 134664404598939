import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogComponent from '../../../../common/components/DialogComponent';

import { notifySuccess } from '../../../../core/store';
import { changePassword } from '../../../../requests/auth';

import { handleApiError } from '../../../../utils/errorUtils';

import ChangePasswordForm from '../ChangePasswordForm';

const ChangePassword = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleChangePassword = async ({ oldPassword, newPassword }) => {
    setIsSubmitting(true);

    try {
      await changePassword({ oldPassword, newPassword });
      dispatch(notifySuccess(intl.formatMessage({ id: 'success.passwordChangedSuccessfully' })));
      setIsDialogOpen(false);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ButtonComponent
        variant="outlined"
        onClick={() => setIsDialogOpen(true)}
        label={intl.formatMessage({ id: 'label.changePassword' })}
      />
      {isDialogOpen && (
        <DialogComponent
          open={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
          title={intl.formatMessage({ id: 'label.changePassword' })}
        >
          <ChangePasswordForm onSubmit={handleChangePassword} isSubmitting={isSubmitting} />
        </DialogComponent>
      )}
    </>
  );
};

export default ChangePassword;
