import React from 'react';

import { BmcButtonContainer } from '../../../../common/styled/Headings';

import { AddIcon } from '../../../../assets/icons';

export const AddButton = ({ onClick, disabled = false, id }) => (
  <BmcButtonContainer
    disabled={disabled}
    onClick={onClick}
    id={`button-add-${id}`}
  >
    <AddIcon width={16} height={16} fill={disabled ? '#ccc' : '#74A3FE'} />
  </BmcButtonContainer>
);
