import { DEFAULT_DESCRIPTION } from '../../constants/general';

export const getInitialValues = (article) => ({
  title: article?.title || '',
  description: article?.description || DEFAULT_DESCRIPTION,
  subtitle: article.subtitle || '',
  category: article.categoryId || '',
  isHidden: !article?.isVisible || false,
});

export const formatPayload = (values) => ({
  ...(values.title !== '' ? { title: values.title } : {}),
  ...(values.subtitle !== '' ? { subtitle: values.subtitle } : {}),
  ...(values.description !== '' ? { description: values.description } : {}),
  ...(values.category !== '' ? { categoryId: values.category } : {}),
  ...(values.isHidden !== '' ? { isVisible: !values.isHidden } : {}),
});
