import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash-es';

import { Counter } from '../../../assets/theme/styled/Counter';

const SectionTitle = ({ title, variant = 'h2', counter, mb = 20 }) => (
  <Box display="flex" alignItems="center" gap={8} mb={mb}>
    <Typography variant={variant} color="primary">
      {title}
    </Typography>
    {!isNil(counter) && <Counter>{counter}</Counter>}
  </Box>
);

export default SectionTitle;
