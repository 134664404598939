import { FormControl, Box, Switch, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogComponent from '../../../../common/components/DialogComponent';

import { editProfile, loadProfile } from '../../../../store/userProfile/userProfileSlice';

import { INDIVIDUAL_CUSTOMER_TYPE, LEGAL_CUSTOMER_TYPE } from '../../../../constants/general';

import { SaveIconOutlined } from '../../../../assets/icons';

import InputField from '../../../../fields/InputField';

const BillingDetailsModal = ({ isModalOpen, setIsModalOpen, editProfile }) => {
  const [isJuridic, setIsJuridic] = useState(true);
  const intl = useIntl();

  const handleSubmit = (values) => {
    const formData = isJuridic
      ? { legalPerson: { ...values } }
      : { individual: { ...values } };
    const payload = {
      invoiceDetails: {
        customerType: isJuridic
          ? LEGAL_CUSTOMER_TYPE
          : INDIVIDUAL_CUSTOMER_TYPE,
        ...formData,
      },
    };
    editProfile(payload);
    setIsModalOpen(false);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    cnp: '',
    mobilePhone: '',
    companyName: '',
    registerNumber: '',
    cui: '',
    bankName: '',
    bankNumber: '',
  };

  const schema = Yup.object().shape({
    lastName: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    firstName: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleToggle = () => {
    setIsJuridic(!isJuridic);
  };

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title={intl.formatMessage({ id: 'label.addInvoiceDetails' })}
    >
      <Box mb={16} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={isJuridic && 'faded'} color="primary" fontWeight={600}>
          {intl.formatMessage({ id: 'label.individual' })}
        </Typography>
        <Switch
          checked={isJuridic}
          onChange={handleToggle}
        />
        <Typography className={!isJuridic && 'faded'} color="primary" fontWeight={600}>
          {intl.formatMessage({ id: 'label.legalEntity' })}
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FormControl style={{ width: '100%' }}>
              <Grid container>
                {isJuridic ? (
                  <>
                    <InputField
                      md={12}
                      id="companyName"
                      label={intl.formatMessage({ id: 'label.designation' })}
                    />
                    <InputField
                      md={12}
                      id="registerNumber"
                      label={intl.formatMessage({ id: 'label.registerNumber' })}
                    />
                    <InputField
                      md={12}
                      id="address"
                      label={intl.formatMessage({ id: 'label.officeAddress' })}
                      multiline
                      rows={3}
                    />
                    <InputField
                      md={12}
                      id="cui"
                      label={intl.formatMessage({ id: 'label.personalIdentificationNumber' })}
                    />
                    <InputField
                      md={12}
                      id="bankName"
                      label={intl.formatMessage({ id: 'label.bank' })}
                    />
                    <InputField
                      md={12}
                      id="bankNumber"
                      label={intl.formatMessage({ id: 'label.IBAN' })}
                    />

                    <Grid item xs={12}>
                      <Box mb={15}>
                        <Typography variant="h3" color="primary">
                          {intl.formatMessage({ id: 'label.legalRepresentative' })}
                        </Typography>
                      </Box>
                    </Grid>
                    <InputField
                      md={12}
                      id="lastName"
                      label={intl.formatMessage({ id: 'label.name' })}
                    />
                    <InputField
                      md={12}
                      id="email"
                      label={intl.formatMessage({ id: 'label.email' })}
                    />
                    <InputField
                      md={12}
                      id="firstName"
                      label={intl.formatMessage({ id: 'label.firstName' })}
                    />
                    <InputField
                      md={12}
                      id="mobilePhone"
                      label={intl.formatMessage({ id: 'label.mobilePhone' })}
                    />
                  </>
                ) : (
                  <>
                    <InputField
                      md={12}
                      id="lastName"
                      label={intl.formatMessage({ id: 'label.name' })}
                    />
                    <InputField
                      md={12}
                      id="firstName"
                      label={intl.formatMessage({ id: 'label.firstName' })}
                    />
                    <InputField
                      md={12}
                      id="address"
                      label={intl.formatMessage({ id: 'label.fullAddress' })}
                      multiline
                      rows={3}
                    />
                    <InputField
                      md={12}
                      id="cnp"
                      label={intl.formatMessage({ id: 'label.CNP' })}
                    />
                    <InputField
                      md={12}
                      id="mobilePhone"
                      label={intl.formatMessage({ id: 'label.mobilePhone' })}
                    />
                    <InputField
                      md={12}
                      id="email"
                      label={intl.formatMessage({ id: 'label.email' })}
                    />
                  </>
                )}
              </Grid>
            </FormControl>
            <ButtonComponent
              type="submit"
              label={intl.formatMessage({ id: 'button.save' })}
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
            />
          </form>
        )}
      </Formik>
    </DialogComponent>
  );
};

const mapDispatchToProps = {
  loadProfile,
  editProfile,
};

export default withRouter(connect('', mapDispatchToProps)(BillingDetailsModal));
