import { Box } from '@mui/material';
import ButtonMui from '@mui/material/Button';
import ButtonGroupMui from '@mui/material/ButtonGroup';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) =>  `
   border: 1px solid ${theme.palette.general.borderAccent};
   border-radius: 4px;
   display: flex;
   align-items: center;
`);

export const ButtonGroup = styled(ButtonGroupMui)(({ theme }) => `
   width: 100%;
   padding: ${theme.spacing(3)};
   border-color: unset;
   gap: ${theme.spacing(4)};

   & .MuiButtonGroup-grouped {
     width: 100%;
     border-radius: 4px;
     border-color: unset;
     border-right: 0;
   }
`);

export const Button = styled(
  ButtonMui,
  { shouldForwardProp: (prop) => prop !== 'active' },
)(({ theme, active }) => `
   font-size: 12px;
   font-weight: 600;
   padding: ${theme.spacing(2, 0)};
   background-color: ${active ? theme.palette.general.accentLight : theme.palette.common.white};
   color: ${active ? theme.palette.common.white : theme.palette.general.accentLight};
   
   :hover {
      background-color: ${theme.palette.general.accentLight};
      color: ${theme.palette.common.white};
   }
`);
