import { EXISTING_FLAGS, isFeatureDisabled } from '../../utils/featureFlag';

import { ComputerIcon, DocumentIcon, EditDocumentIcon } from '../../assets/icons';

export const RESOURCES_TABS = [
  {
    key: 'chapters',
    label: 'label.course',
    icon: <ComputerIcon />,
  },
  {
    key: 'articles',
    label: 'label.articles',
    icon: <EditDocumentIcon />,
  },
  {
    key: 'patterns',
    label: 'label.patterns',
    icon: <DocumentIcon />,
    isHidden: isFeatureDisabled(EXISTING_FLAGS.resources.patterns),
  },
];
