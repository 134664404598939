import { merge } from 'lodash-es';

import { DEFAULT_LANGUAGE } from '../constants/general';

import AppLocale from './index';

export const mergeWithDefaultLanguage = (language) =>
  merge(
    {},
    AppLocale[DEFAULT_LANGUAGE],
    AppLocale[language],
  );
