import { CUSTOMER_SEGMENT_HYPOTHESES_TABS, HYPOTHESES_TABS } from './constants';

export const getPageTabs = (isCustomerSegment) => {
  if (isCustomerSegment) {
    return CUSTOMER_SEGMENT_HYPOTHESES_TABS;
  }

  return HYPOTHESES_TABS;
};

export const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  const hour = String(date.getUTCHours()).padStart(2, '0');
  const minute = String(date.getUTCMinutes()).padStart(2, '0');

  return `${day}/${month}/${year}, ${hour}:${minute}`;
};
