import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ActionsWrapper = styled('div')(({ theme }) => `
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  position: absolute;
  right: 4px;
  background-color: ${theme.palette.common.white}
`);

export const Container = styled('div')(() => `
  display: flex;
  align-items: center;
  justify-content: space-between;
`);

export const Card = styled(Box)(({ theme }) => `
  border: 1px solid ${theme.palette.general.accentLight};
  height: 150px;
  border-radius: 10px;
  padding: ${theme.spacing(10, 10, 20)};
  position: relative;
  &:hover > :first-child > :last-child {
    opacity: 1;
  }
`);
