import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const articleSchema = Yup.object({
  title: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  subtitle: Yup.string(),
  description: Yup.string(),
  category: Yup.string(),
  isHidden: Yup.boolean(),
});
