import { Grid, TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const InputField = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
  value,
  placeholder,
  disabled = false,
  type,
  autoFocus = false,
  required = false,
  sx = { marginBottom: '15px' },
  onBlur,
  ...remainingProps
}) => {
  const [field, meta] = useField(id);
  return (
    <Grid item xs={12} md={md} sx={sx}>
      <TextField
        {...field}
        {...remainingProps}
        required={required}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        onBlur={onBlur || field?.onBlur}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
        defaultValue={value}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...(type && { type })}
      />
    </Grid>
  );
};

export default InputField;
