import { get } from 'lodash-es';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import DialogComponent from '../../../../common/components/DialogComponent';

import {
  editHypothesis,
  addHypothesis,
  addClientProfile,
  editClientProfile,
  loadHypothesis,
} from '../../../../store/hypothesis/hypothesisSlice';

import AddPersonInfoForm from '../AddPersonInfoForm';

const AddPersonInfoModal = (props) => {
  const {
    isModalOpen,
    closeModal,
    entityId,
    setIsModalOpen,
    editClientProfile,
    global,
    hypothesis,
    addClientProfile,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleModalClose = () =>
    global ? closeModal() : setIsModalOpen(false);

  const onSubmit = async (values) => {
    const { uploadedFile, ...remainingValues } = values;

    if (!hypothesis.clientProfile) {
      const addPromise = addClientProfile(remainingValues, uploadedFile, entityId);
      addPromise.then(() => {
        dispatch(loadHypothesis(entityId));
        handleModalClose();
      });
      return addPromise;
    }

    const editPromise = editClientProfile(
      { ...hypothesis.clientProfile, ...remainingValues },
      uploadedFile,
      hypothesis.clientProfile._id
    );
    editPromise.then(() => {
      dispatch(loadHypothesis(entityId));
      handleModalClose();
    });
    return editPromise;
  };

  const handleCloseModal = () => {
    handleModalClose();
  };

  return (
    <DialogComponent
      open={isModalOpen}
      title={intl.formatMessage({ id: 'modal.title.updateProfileDetails' })}
      handleClose={handleCloseModal}
    >
      <AddPersonInfoForm
        global={global}
        onSubmit={onSubmit}
        personInfo={hypothesis.clientProfile}
      />
    </DialogComponent>
  );
};
const mapStateToProps = (state) => ({
  isEditing: state.hypotheses.isEditing,
  isCreating: state.hypotheses.isCreating,
  firstName: get(state.userProfile, 'userProfile.firstName'),
  lastName: get(state.userProfile, 'userProfile.lastName'),
});

const mapDispatchToProps = {
  addHypothesis,
  editHypothesis,
  addClientProfile,
  editClientProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPersonInfoModal)
);
