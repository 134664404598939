import { Grid, IconButton } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { TrashIconOutlined } from '../../assets/icons';

import InputField from '../../fields/InputField';

const RewardRow = ({ position, values, setValues, sx }) => {
  const intl = useIntl();

  const deleteExperience = () => {
    values.rewardPreference.splice(position, 1);
    setValues(values);
  };

  return (
    <Grid container spacing={16} sx={sx}>
      <Grid item xs={12} md={6}>
        <InputField
          id={`rewardPreference[${position}].title`}
          label={intl.formatMessage({ id: 'label.description' })}
        />
      </Grid>
      <Grid item xs={5.5} md={2.5}>
        <InputField
          id={`rewardPreference[${position}].value`}
          label={intl.formatMessage({ id: 'label.value' })}
        />
      </Grid>
      <Grid item xs={5.5} md={2.5}>
        <InputField
          id={`rewardPreference[${position}].unitOfMeasurement`}
          label={intl.formatMessage({ id: 'label.um' })}
        />
      </Grid>
      <Grid item xs={1}>
        {values.rewardPreference?.length > 1 && (
          <IconButton sx={{ mb: 15 }} color="error" onClick={deleteExperience}>
            <TrashIconOutlined />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default RewardRow;
