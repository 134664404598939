import { Typography } from '@mui/material';
import { Formik } from 'formik';
import { get } from 'lodash-es';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../components/ButtonComponent';
import { DialogActionWrapper } from '../../components/DialogComponent/index.styled';

import { CLIENT_STAGE_OPTIONS } from '../../../constants/options';
import { DEFAULT_TOOLBAR_CONFIG } from '../../../constants/richTextEditor';
import { getFormikErrors } from '../../../utils/errorUtils';
import { detailClientMinuteSchema } from '../../../utils/validation/detailSchema';
import { getInitialValues } from './utils';

import { SaveIconOutlined } from '../../../assets/icons';

import CounterField from '../../../fields/CounterField';
import InputField from '../../../fields/InputField';
import RadioFieldGroup from '../../../fields/RadioFieldGroup';
import RenderRichText from '../../../fields/RichTextEditor';
import SquareButtonsField from '../../../fields/SquareButtonsField';

const ClientMinuteForm = ({ onSubmit, formValues }) => {
  const intl = useIntl();

  const handleChangeCounter = (fieldName, values, setValues, oldValue, newValue) => {
    const array = get(values, fieldName) || [];

    if (oldValue > newValue) {
      array.pop();
    } else {
      array.push({ name: '' });
    }

    setValues({ ...values, [fieldName]: array, [`${fieldName}Number`]: newValue });
  };

  const handleValidation = (values) => {
    try {
      detailClientMinuteSchema.validateSync(values, { abortEarly: false });
    } catch (e) {
      return getFormikErrors(e);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(formValues)}
      onSubmit={onSubmit}
      validate={handleValidation}
    >
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>

          <InputField id="title" label={intl.formatMessage({ id: 'label.title' })} required />

          <RenderRichText
            name="description"
            label={intl.formatMessage({ id: 'label.discussionSummary' })}
            height="200px"
            config={DEFAULT_TOOLBAR_CONFIG}
            isCountVisible
          />

          <RadioFieldGroup
            id="clientStage"
            options={CLIENT_STAGE_OPTIONS}
            fullWidth
            label={intl.formatMessage({ id: 'label.clientStage' })}
            required
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
          />

          <SquareButtonsField
            name="contactDisponibility"
            label={intl.formatMessage({ id: 'label.possibilityOfContact' })}
            value={values?.contactDisponibility}
            onChange={setFieldValue}
            option1={<Typography color="textSecondary">{intl.formatMessage({ id: 'label.yes' })}</Typography>}
            option2={<Typography color="textSecondary">{intl.formatMessage({ id: 'label.no' })}</Typography>}
            sx={{ mb: 15 }}
          />

          <CounterField
            id="nextStepsNumber"
            label={intl.formatMessage({ id: 'label.nextSteps' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter('nextSteps', values, setValues, oldValue, newValue)
            )}
          />
          {values?.nextSteps?.map((email, index) => (
            <InputField
              key={`nextSteps-${index}`}
              id={`nextSteps[${index}].name`}
              label={intl.formatMessage({ id: 'label.stepCount' }, { count: index + 1})}
            />
          ))}

          <InputField id="cost" label={intl.formatMessage({ id: 'label.estimatedCost' })} />

          <CounterField
            id="linksNumber"
            label={intl.formatMessage({ id: 'label.numberOfLikes' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter('links', values, setValues, oldValue, newValue)
            )}
          />
          {values?.links?.map((link, index) => (
            <InputField
              key={`links-${index}`}
              id={`links[${index}].name`}
              label={intl.formatMessage({ id: 'label.link' })}
              placeholder="www.link.com"
            />
          ))}

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default ClientMinuteForm;
