import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { DESCRIPTION_SHORT_MAX_SIZE } from '../../constants/general';
import { TARGET_INDICATORS } from '../../constants/targetIndicators';

import { getDescriptionSchema } from './genericSchema';

const valueValidation = Yup
  .string()
  .nullable()
  .when('description', {
    is: (description) => !!description,
    then: (field) => field
      .required(<FormattedMessage id='validate.mandatoryField' />),
  });

export const segmentDimensionSchema = Yup.object({
  [TARGET_INDICATORS.tam]: Yup.object({
    description: getDescriptionSchema(DESCRIPTION_SHORT_MAX_SIZE),
    value: valueValidation,
  }),
  [TARGET_INDICATORS.sam]: Yup.object({
    description: getDescriptionSchema(DESCRIPTION_SHORT_MAX_SIZE),
    value: valueValidation,
  }),
  [TARGET_INDICATORS.som]: Yup.object({
    description: getDescriptionSchema(DESCRIPTION_SHORT_MAX_SIZE),
    value: valueValidation,
  }),
  [TARGET_INDICATORS.earlyAdopters]: Yup.object({
    description: getDescriptionSchema(DESCRIPTION_SHORT_MAX_SIZE),
    value: valueValidation,
  }),
  linksNumber: Yup.number(),
  links: Yup.array().of(
    Yup.object({
      name: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    })
  ),
});
