import { useRef, useState } from 'react';

import { IframeStyled } from './index.styled';

const Iframe = ({
  content,
  title,
  className,
  sx = {},
  heightOffSet = 0,
  hasDynamicHeight = false,
}) => {
  const iframeRef = useRef(null);
  const [height, setHeight] = useState('100%');

  const adjustIframeHeight =  () => {
    if (iframeRef?.current && iframeRef?.current?.contentWindow) {
      setHeight(iframeRef.current.contentWindow.document.body.scrollHeight + heightOffSet + 'px');
    }
  };

  return (
    <IframeStyled
      title={title}
      srcDoc={content}
      className={className}
      sx={{
        ...sx,
        ...(hasDynamicHeight ? { height: `${height} !important` } : {}),
      }}
      ref={iframeRef}
      loading="eager"
      onLoadCapture={adjustIframeHeight}
    />
  );
};

export default Iframe;
