import { Chip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomChip = styled(Box)(({ theme }) =>  `
  padding: ${theme.spacing(4, 6)};
  border-radius: 4px;
  background-color: ${theme.palette.general.lightBlue};
  display: inline-block;
`);

export const ProChip = styled(Chip)(({ theme }) =>  `
  border-radius: 4px;
  border-color: ${theme.palette.general.yellow};
  
  & .MuiChip-label {
    color: ${theme.palette.general.yellow};
    font-weight: 600;
  }
`);
