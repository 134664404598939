import { get } from 'lodash-es';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentTitle } from '../../common/components';
import { Loading } from '../../common/styled';
import { Resolver } from '../../core/components';

import { currentTeamIdSelector } from '../../common/store';
import {
  loadProfile,
  loadHypothesesPercentage,
  loadExperimentsPercentage,
  getAllSubscriptionProducts,
} from '../../store/userProfile/userProfileSlice';

import { USER_ROLES } from '../../constants/roles';

import ProfilePage from '../../modules/userProfile/containers/Profile';

const ProfilePageResolver = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const role = useSelector((state) => get(state.account, 'user.role'));

  const loadDependencies = async () => {
    await loadProfile();

    if (teamId && role === USER_ROLES.Founder) {
      Promise.all([
        dispatch(loadExperimentsPercentage(teamId)),
        dispatch(loadHypothesesPercentage(teamId)),
        dispatch(getAllSubscriptionProducts()),
      ]);
    } else {
      Promise.all([
        dispatch(getAllSubscriptionProducts()),
      ]);
    }
  };

  return (
    <>
      <DocumentTitle>{intl.formatMessage({ id: 'label.profile' })}</DocumentTitle>
      <Resolver
        successComponent={ProfilePage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

export default ProfilePageResolver;
