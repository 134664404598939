import { USER_ROLES } from '../../../constants/roles';

import {
  BmcNavIcon,
  ContextAnalysisNavIcon,
  HypothesesNavIcon,
  IdeasNavIcon,
  MultipleGroupIcon,
  ResourcesNavIcon,
  GroupIcon,
  OpenInNewIcon,
  MenuBookIcon,
  ThermostatIcon,
} from '../../../assets/icons/navbar';

export const getFounderOptions = () => ([
  [
    {
      id: 'context-analysis-1',
      route: '/opportunity-analysis?tab=trends',
      title: 'label.contextAnalysis',
      icon: ContextAnalysisNavIcon,
    },
    {
      id: 'mrl-nav',
      route: '/canvas',
      title: 'label.BMC',
      icon: BmcNavIcon,
    },
    {
      id: 'boards',
      route: '/boards?tab=hypothesis',
      title: 'label.boards',
      icon: HypothesesNavIcon,
    },
    {
      id: 'idea',
      route: '/idea?tab=description',
      title: 'label.myIdea',
      icon: IdeasNavIcon,
    },
  ],
  [
    {
      id: 'articles',
      route: '/resources?tab=chapters',
      title: 'label.resources',
      icon: ResourcesNavIcon,
    },
    {
      id: 'leaderboard',
      route: '/community?tab=leaderboard',
      title: 'label.community',
      icon: MultipleGroupIcon,
    },
  ],
]);

const getMentorOptions = () => [
  [
    {
      id: 'mrl-nav',
      route: '/canvas',
      title: 'label.BMC',
      icon: BmcNavIcon,
    },
    {
      id: 'idea',
      route: '/idea?tab=description',
      title: 'label.myIdea',
      icon: IdeasNavIcon,
    },
  ],
  [
    {
      id: 'leaderboard',
      route: '/community?tab=leaderboard',
      title: 'label.community',
      icon: MultipleGroupIcon,
    },
    {
      id: 'articles',
      route: '/resources?tab=chapters',
      title: 'label.resources',
      icon: ResourcesNavIcon,
    },
  ]
];

const getAcceleratorOptions = (cohortId) => [
  [
    {
      id: 'cohorts',
      route: 'cohorts',
      title: 'label.cohorts',
      icon: GroupIcon,
    },
    {
      id: 'teams',
      route: `/accelerator/${cohortId}/teams`,
      title: 'label.teams',
      isDisabled: !cohortId,
      icon: IdeasNavIcon,
    },
    {
      id: 'leaderboard',
      route: `/accelerator/${cohortId}/leaderboard`,
      title: 'label.community',
      isDisabled: !cohortId,
      icon: MultipleGroupIcon,
    },
  ],
];

const getAdminOptions = () => [
  [
    {
      id: 'users',
      route: '/admin/users',
      title: 'label.users',
      icon: GroupIcon,
    },
    {
      id: 'adminMrlGuide',
      route: '/admin/mrl?tab=mrlSteps',
      title: 'label.mrlGuide',
      icon: ThermostatIcon,
    },
    {
      id: 'adminResources',
      route: '/admin/resources',
      title: 'label.resources',
      icon: MenuBookIcon,
    },
    {
      id: 'adminContactForm',
      route: '/admin/contact-form',
      title: 'label.form',
      icon: OpenInNewIcon,
    },
  ],
];

export const getNavigationOptions = (role, cohortId) => {
  if (role === USER_ROLES.Founder) {
    return getFounderOptions();
  }

  if (role === USER_ROLES.Mentor) {
    return getMentorOptions();
  }

  if (role === USER_ROLES.Accelerator) {
    return getAcceleratorOptions(cohortId);
  }

  if (role === USER_ROLES.Admin) {
    return getAdminOptions();
  }

  return [];
};
