import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import {
  Input,
  Heading2,
  SubmitButton,
  ButtonDiv,
} from '../../../../common/styled';

const RenderForm = ({
  handleSubmit,
  handleChange,
  submitCount,
  values,
  errors,
  passwordConfirmed,
}) => {
  const intl = useIntl();

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="validationFormikReset01">
          <Heading2 marginBottom="10px">{intl.formatMessage({ id: 'label.newPassword' })}</Heading2>
          <Input
            type="password"
            placeholder={intl.formatMessage({ id: 'placeholder.newPassword' })}
            name="password"
            value={values.password}
            onChange={handleChange}
            isInvalid={submitCount > 0 && !!errors.password}
          />

          <Form.Control.Feedback type="invalid">
            {submitCount > 0 && errors.password}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="validationFormikReset02">
          <Heading2 marginBottom="10px">{intl.formatMessage({ id: 'label.confirmPassword' })}</Heading2>
          <Input
            type="password"
            placeholder={intl.formatMessage({ id: 'placeholder.newPassword' })}
            name="passwordConfirmation"
            value={values.passwordConfirmation}
            onChange={handleChange}
            isInvalid={submitCount > 0 && !!errors.passwordConfirmation}
          />
          <Form.Control.Feedback type="invalid">
            {submitCount > 0 && errors.passwordConfirmation}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      {!passwordConfirmed && (
        <Form.Row>
          <Form.Group as={Col} md="12">
            {intl.formatMessage({ id: 'validate.passwordsNotMatching' })}
          </Form.Group>
        </Form.Row>
      )}
      <ButtonDiv>
        <SubmitButton type="submit">{intl.formatMessage({ id: 'button.savePassword' })}</SubmitButton>
      </ButtonDiv>
    </Form>
  );
};

const ResetPasswordForm = ({ handleSubmit }) => {
  const intl = useIntl();
  const [passwordConfirmed, setPasswordConfirmed] = useState(true);

  const onSubmit = (values) => {
    if (values.password === values.passwordConfirmation) {
      handleSubmit(values);
    } else {
      setPasswordConfirmed(false);
    }
  };

  const schema = Yup.object({
    password: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    passwordConfirmation: Yup.string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' }))
      .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'validate.passwordsNotMatching' })),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{ password: '', passwordConfirmation: '' }}
    >
      {(props) => (
        <RenderForm {...props} passwordConfirmed={passwordConfirmed} />
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
