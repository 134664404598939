import { toast } from 'react-toastify';

const buildErrorMessage = (err) => {
  const name = err?.name ? `${err.name}: ` : '';
  const message = err?.message || 'Unknown error';
  return `${name}${message}`;
};

export const handleApiError = (apiError) => {
  const error = apiError?.response?.data;

  if (Array.isArray(error)) {
    error.forEach((err) => {
      const errorMessage = buildErrorMessage(err);
      toast.error(errorMessage, {
        className: 'toastify toastify-type-error',
          bodyClassName: 'toastify-body',
          progressClassName: 'toastify-progress',
          autoClose: 4500,
      });
    });
  } else {
    const errorMessage = buildErrorMessage(error);
    toast.error(errorMessage, {
      className: 'toastify toastify-type-error',
      bodyClassName: 'toastify-body',
      progressClassName: 'toastify-progress',
      autoClose: 4500,
    });
  }
};

export const getFormikErrors = (e) => {
  const errors = {};

  e.inner.forEach((error) => {
    const path = error.path;

    // Split the path for nested fields, especially arrays
    const pathArray = path.split('.');

    // Reduce the path array into the nested error object structure
    pathArray.reduce((acc, curr, idx) => {
      if (idx === pathArray.length - 1) {
        acc[curr] = error.message;
      } else {
        if (!acc[curr]) acc[curr] = isNaN(pathArray[idx + 1]) ? {} : [];
        return acc[curr];
      }
    }, errors);
  });

  for (const key in errors) {
    if (key.includes('[')) {
      const [arrayField, index] = key.split(/\[|\]/);
      const arrayIndex = parseInt(index, 10);
      if (!errors[arrayField]) {
        errors[arrayField] = [];
      }
      errors[arrayField][arrayIndex] = errors[key];
      delete errors[key];
    }
  }

  return errors;
};
