import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import PageTitle from '../../../../common/components/PageTitle';
import Tabs from '../../../../common/components/Tabs';
import { TabContent } from '../../../../common/components/Tabs/index.styled';
import { Loading } from '../../../../common/styled';

import { useApi } from '../../../../hooks/useApi';
import { getLoggedUserNextPayment } from '../../../../requests/payments';
import {
  subscribeUserToMailchimp,
  unsubscribeUserFromMailchimp,
  uploadProfilePicture,
} from '../../../../store/userProfile/userProfileSlice';
import { loadMrl } from '../../../mrl/store';

import { PROFILE_PAGE_TABS } from '../../constants';

import Payments from '../../../userPayments/containers/Payments';
import UserInformation from '../../../userProfileDetails/containers/UserInformation';
import Settings from '../../../userSettings/containers/Settings';
import Subscriptions from '../../../userSubscriptions/containers/Subscriptions';
import Conditions from '../../../userTermsAndConditions/containers/Conditions/Conditions';

const Profile = (props) => {
  const {
    userProfile,
    isLoading,
    userTeams,
    subscribeUserToMailchimp,
    unsubscribeUserFromMailchimp,
  } = props;

  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'profile';
  const history = useHistory();
  const intl = useIntl();

  const [activeTab, setActiveTab] = useState(tabFromQuery);

  useEffect(() => {
    setActiveTab(tabFromQuery);
  }, [tabFromQuery]);

  const [{ result: nextPaymentData }, runGetNextPayment] = useApi(
    getLoggedUserNextPayment,
    null
  );

  useEffect(() => {
    runGetNextPayment();
  }, []);

  const handleTabChange = (tab) => {
    history.push(`/profile?tab=${tab}`);
  };

  const pageComponents = {
    profile: {
      title: intl.formatMessage({ id: 'label.myProfile' }),
      component: (
        <UserInformation userProfile={userProfile} userTeams={userTeams} />
      ),
    },
    settings: {
      title: intl.formatMessage({ id: 'label.settings' }),
      component: (
        <Settings
          userProfile={userProfile}
          subscribeUserToMailchimp={subscribeUserToMailchimp}
          unsubscribeUserFromMailchimp={unsubscribeUserFromMailchimp}
        />
      ),
    },
    subscriptions: {
      title: intl.formatMessage({ id: 'label.subscription' }),
      component: <Subscriptions nextPaymentData={nextPaymentData} />,
    },
    payments: {
      title: intl.formatMessage({ id: 'label.payments' }),
      component: <Payments nextPaymentData={nextPaymentData} />,
    },
    conditions: {
      title: intl.formatMessage({ id: 'label.conditions' }),
      component: <Conditions />,
    },
  };

  return (
    <>
      <PageTitle title={pageComponents[activeTab]?.title} />
      <Tabs
        tabs={PROFILE_PAGE_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
      />
      <TabContent className="scrollbar">
        {pageComponents[activeTab]?.component}
      </TabContent>
      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  hypothesesPercentage: state.userProfile.hypothesesPercentage,
  experimentsPercentage: state.userProfile.experimentsPercentage,
  userProfile: state.userProfile.userProfile,
  isLoading: state.userProfile.isLoading,
  role: state.account.user.role,
  userTeams: state.common.userTeams.userTeams,
  mrlLoading: state.mrl.isLoading,
  mrlData: state.mrl.mrls,
  isEditing: state.mrl.isEditing,
});

const mapDispatchToProps = {
  uploadProfilePicture,
  loadMrl,
  subscribeUserToMailchimp,
  unsubscribeUserFromMailchimp,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
