import { Formik, useField } from 'formik';
import { get } from 'lodash-es';
import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import PageTitle from '../../common/components/PageTitle';
import {
  ButtonDiv,
  Heading2,
  Input,
  LinkIcon,
  PositionParent,
  SaveIcon,
  SubmitButton,
} from '../../common/styled';

import { addContactFormUrl } from '../store/contactForm';

const RenderLinkField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  const [meta] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <PositionParent>
        <LinkIcon />
        <Input
          formlink
          type={type}
          name={name}
          placeholder={placeholder}
          value={fieldValue}
          onChange={(e) => {
            handleChange(e);
          }}
          isInvalid={meta.touched && !!meta.error}
        />
        <Form.Control.Feedback type="invalid">
          {meta.touched && meta.error}
        </Form.Control.Feedback>
      </PositionParent>
    </Form.Group>
  );
};

const RenderForm = ({ handleSubmit, handleChange, values }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderLinkField
        values={values}
        md="6"
        name="formUrl"
        label="Introduceți linkul către formular"
        placeholder="https://"
        handleChange={handleChange}
        controlId="validationFormik08"
      />
    </Form.Row>

    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);
const ContactForm = ({ addContactFormUrl }) => {
  const schema = Yup.object({
    formUrl: Yup.string()
      .required('Câmp obligatoriu')
      .url('URL Invalid (linkul trebuie sa contina http:// sau https://)'),
  });

  const handleSubmit = (values) => {
    addContactFormUrl(values);
  };

  return (
    <>
      <PageTitle title="Formular" />

      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{ formUrl: '' }}
      >
        {(props) => <RenderForm {...props} isEditMode={false} />}
      </Formik>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { addContactFormUrl };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactForm)
);
