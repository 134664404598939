import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LOCALES } from '../languages/langTexts';
import { EXPERIMENT_TYPE, TRUST_DATA } from '../modules/hypothesisActivities/constants';

import { BMC_TYPE, BUSINESS_OBJECTIVES } from './bmc';
import { DETAIL_CATEGORIES, CLIENT_STAGE } from './detail';
import { SUBSCRIPTION_TYPE } from './hypothesis';
import { INDUSTRY_IMPACT, INDUSTRY_TYPE } from './industry';
import { TEAM_ROLES } from './roles';
import { TARGET_INDICATORS } from './targetIndicators';

export const EXPERIMENT_TYPE_LABELS = {
  [EXPERIMENT_TYPE.clientProfile]: 'label.clientProfile',
  [EXPERIMENT_TYPE.significantProblems]: 'label.significantProblem',
  [EXPERIMENT_TYPE.valueProposition]: 'label.valueProposition',
  [EXPERIMENT_TYPE.minValuableSolution]: 'label.minValuableSolution',
  [EXPERIMENT_TYPE.sourceOfIncome]: 'label.sourceOfIncome',
  [EXPERIMENT_TYPE.customerAcquisitionChannels]: 'label.customerAcquisitionChannels',
  [EXPERIMENT_TYPE.segmentSize]: 'label.segmentSize',
  [EXPERIMENT_TYPE.mvp10]: 'label.mvp10',
  [EXPERIMENT_TYPE.mvp20]: 'label.mvp20',
  [EXPERIMENT_TYPE.finalSolution]: 'label.finalSolution',
  [EXPERIMENT_TYPE.clientProblem]: 'label.clientProblem',
  [EXPERIMENT_TYPE.minValueSentence]: 'label.minValueSentence',
};

export const TRUST_DATA_LABELS = {
  [TRUST_DATA.high]: 'label.big',
  [TRUST_DATA.medium]: 'label.medium',
  [TRUST_DATA.small]: 'label.small',
};

export const CLIENT_STAGE_LABELS = {
  [CLIENT_STAGE.notInterested]: 'label.notInterested',
  [CLIENT_STAGE.prospect]: 'label.prospect',
  [CLIENT_STAGE.qualified]: 'label.qualified',
  [CLIENT_STAGE.activated]: 'label.activated',
  [CLIENT_STAGE.payingClient]: 'label.payingClient',
  [CLIENT_STAGE.loyalClient]: 'label.loyalClient',
  [CLIENT_STAGE.clientAccountClosed]: 'label.clientClosedAccount',
};

export const DETAIL_CATEGORY_LABELS = {
  [DETAIL_CATEGORIES.listaBusinessAngels]: 'label.businessAngelsList',
  [DETAIL_CATEGORIES.listaFonduriDeInvestitii]: 'label.investmentsFundsList',
  [DETAIL_CATEGORIES.termSheetAgreement]: 'label.termSheetAgreement',
  [DETAIL_CATEGORIES.listaFinantariPublice]: 'label.publicFinancingList',
  [DETAIL_CATEGORIES.intentieScrisoareCumparare]: 'label.letterOfIntentPurchase',
  [DETAIL_CATEGORIES.ordinComandaFerma]: 'label.firmOrder',
  [DETAIL_CATEGORIES.documentVanzare]: 'label.saleDocument',
  [DETAIL_CATEGORIES.documentatieTehnicaSolutie]: 'label.solutionTechnicalDocumentation',
  [DETAIL_CATEGORIES.feedbackDemo]: 'label.feedbackDemo',
  [DETAIL_CATEGORIES.analizaDateDemo]: 'label.dataAnalysisDemo',
  [DETAIL_CATEGORIES.politicaDePret]: 'label.pricePolicy',
  [DETAIL_CATEGORIES.chestionarDescoperireNevoi]: 'label.needsDiscoveryQuestionnaire',
  [DETAIL_CATEGORIES.structuraInterviu]: 'label.interviewStructure',
  [DETAIL_CATEGORIES.analiza]: 'label.analysis',
  [DETAIL_CATEGORIES.detaliiDeContact]: 'label.contactDetails',
  [DETAIL_CATEGORIES.imagine]: 'label.image',
  [DETAIL_CATEGORIES.inregistrareAudio]: 'label.audioRecording',
  [DETAIL_CATEGORIES.inregistrareVideo]: 'label.videoRecording',
  [DETAIL_CATEGORIES.librarieDeDate]: 'label.dataLibrary',
  [DETAIL_CATEGORIES.mockupSchita]: 'label.mockupSketch',
  [DETAIL_CATEGORIES.prototipMVPLoFi]: 'label.prototypeMVPLoFi',
  [DETAIL_CATEGORIES.prototipMVPHiFi]: 'label.prototypeMVPHifi',
  [DETAIL_CATEGORIES.prezentareIdee]: 'label.ideaPresentation',
  [DETAIL_CATEGORIES.pitchDeckPentruInvestitori]: 'label.pitchDeckForInvestors',
  [DETAIL_CATEGORIES.prezentareEchipa]: 'label.teamPresentation',
  [DETAIL_CATEGORIES.proofOfConcept]: 'label.proofOfConcept',
  [DETAIL_CATEGORIES.raspunsChestionar]: 'label.quizAnswer',
  [DETAIL_CATEGORIES.raport]: 'label.report',
  [DETAIL_CATEGORIES.studiuDePiata]: 'label.marketStudy',
  [DETAIL_CATEGORIES.strategieDePenetrareAPietei]: 'label.marketPenetrationStrategy',
  [DETAIL_CATEGORIES.cashFlow]: 'label.cashFlow',
  [DETAIL_CATEGORIES.argumentareDimensiuneaPietei]: 'label.marketSizeArgument',
  [DETAIL_CATEGORIES.intelegereaAsociatilor]: 'label.understandingAssociates',
  [DETAIL_CATEGORIES.analizaSWOT]: 'label.swotAnalysis',
  [DETAIL_CATEGORIES.analizaPESTLE]: 'label.pestleAnalysis',
  [DETAIL_CATEGORIES.analiza5Forte]: 'label.5forteAnalysis',
  [DETAIL_CATEGORIES.analizaTrenduri]: 'label.trendsAnalysis',
  [DETAIL_CATEGORIES.analizaCompetitiei]: 'label.competitionAnalysis',
  [DETAIL_CATEGORIES.solutionRoadMap]: 'label.solutionRoadMap',
  [DETAIL_CATEGORIES.design]: 'label.design',
  [DETAIL_CATEGORIES.designSolutie]: 'label.designSolution',
  [DETAIL_CATEGORIES.specificatiiTehniceSolutie]: 'label.solutionTechnicalSpecs',
  [DETAIL_CATEGORIES.userFlow]: 'label.userFlow',
  [DETAIL_CATEGORIES.customerJourney]: 'label.customerJourney',
  [DETAIL_CATEGORIES.listaClientiPotentiali]: 'label.potentialClientsList',
  [DETAIL_CATEGORIES.altaCategorie]: 'label.anotherCategory',
  [DETAIL_CATEGORIES.salesScript]: 'label.salesScript',
  [DETAIL_CATEGORIES.document]: 'label.document',
  [DETAIL_CATEGORIES.procedure]: 'label.procedure',
  [DETAIL_CATEGORIES.diagram]: 'label.diagram',
  [DETAIL_CATEGORIES.listInterviewQuestions]: 'label.listInterviewQuestions',
  [DETAIL_CATEGORIES.minuteGeneral]: 'label.minuteGeneral',
  [DETAIL_CATEGORIES.stakeholder]: 'label.stakeholder',
  [DETAIL_CATEGORIES.elevatorPitch]: 'label.elevatorPitch',
  [DETAIL_CATEGORIES.detailedSegmentAnalysis]: 'label.detailedSegmentAnalysis',
  [DETAIL_CATEGORIES.client]: 'label.client',
  [DETAIL_CATEGORIES.minuteClient]: 'label.minuteClient',
  [DETAIL_CATEGORIES.userStory]: 'label.userStory',
};

export const INDUSTRY_IMPACT_LABELS = {
  [INDUSTRY_IMPACT.veryPositive]: <FormattedMessage id="label.veryPositive++" />,
  [INDUSTRY_IMPACT.positive]: <FormattedMessage id="label.positive+" />,
  [INDUSTRY_IMPACT.negative]: <FormattedMessage id="label.negative-" />,
  [INDUSTRY_IMPACT.veryNegative]: <FormattedMessage id="label.veryNegative--" />,
};

export const INDUSTRY_TYPES_LABELS = {
  [INDUSTRY_TYPE.rivalry]: {
    title: 'label.rivals',
    description: 'label.rivals.description',
  },
  [INDUSTRY_TYPE.entryBarriers]: {
    title: 'label.entryBarrier',
    description: 'label.entryBarrier.description',
  },
  [INDUSTRY_TYPE.providers]: {
    title: 'label.providers',
    description: 'label.providers.description',
  },
  [INDUSTRY_TYPE.clients]: {
    title: 'label.clients',
    description: 'label.clients.description',
  },
  [INDUSTRY_TYPE.subtitledProducts]: {
    title: 'label.substitutableProducts',
    description: 'label.substitutableProducts.description',
  },
};

export const TARGET_INDICATORS_LABELS = {
  [TARGET_INDICATORS.dailyActiveUsers]: 'label.dailyActiveUsers',
  [TARGET_INDICATORS.earlyAdopters]: 'label.earlyAdopters',
  [TARGET_INDICATORS.averageTransactionValue]: 'label.averageTransactionSize',
  [TARGET_INDICATORS.sam]: 'label.sam',
  [TARGET_INDICATORS.som]: 'label.som',
  [TARGET_INDICATORS.tam]: 'label.tam',
  [TARGET_INDICATORS.burnRate]: 'label.burnRate',
  [TARGET_INDICATORS.conversionRate]: 'label.conversionRate',
  [TARGET_INDICATORS.customerAquizitionCost]: 'label.customerAquizitionCost',
  [TARGET_INDICATORS.customerLifetimeValueLTV]: 'label.customerLifetimeValueLTV',
  [TARGET_INDICATORS.customerLifetimeCLV]: 'label.customerLifetimeCLV',
  [TARGET_INDICATORS.userStories]: 'label.userStories',
  [TARGET_INDICATORS.customerInterviews]: 'label.customerInterviews',
  [TARGET_INDICATORS.marketShare]: 'label.marketShare',
  [TARGET_INDICATORS.netPromoterScore]: 'label.netPromoterScore',
  [TARGET_INDICATORS.dailyUsageRatio]: 'label.dailyUsageRatio',
  [TARGET_INDICATORS.customerSatisfaction]: 'label.customerSatisfaction',
  [TARGET_INDICATORS.reviews4Stars]: 'label.reviews4Stars',
  [TARGET_INDICATORS.innovationRate]: 'label.innovationRate',
  [TARGET_INDICATORS.returnOnInvestment]: 'label.returnOnInvestment',
  [TARGET_INDICATORS.channelConversionRate]: 'label.channelConversionRate',
  [TARGET_INDICATORS.channelReturnOnInvestment]: 'label.channelReturnOnInvestment',
  [TARGET_INDICATORS.numberOfQualifiedLeads]: 'label.numberOfQualifiedLeads',
  [TARGET_INDICATORS.numberOfLeads]: 'label.numberOfLeads',
  [TARGET_INDICATORS.numberOfMarketingLeads]: 'label.numberOfMarketingLeads',
  [TARGET_INDICATORS.numberOfActivations]: 'label.numberOfActivations',
  [TARGET_INDICATORS.activationRate]: 'label.activationRate',
  [TARGET_INDICATORS.marketingLeadToQualifiedLeadRatio]: 'label.marketingLeadToQualifiedLeadRatio',
  [TARGET_INDICATORS.qualifiedLeadToCustomerRatio]: 'label.qualifiedLeadToCustomerRatio',
  [TARGET_INDICATORS.kFactor]: 'label.kFactor',
  [TARGET_INDICATORS.customerReferralRate]: 'label.customerReferralRate',
  [TARGET_INDICATORS.returnOnAdvertisingSpending]: 'label.returnOnAdvertisingSpending',
  [TARGET_INDICATORS.averageResponseTime]: 'label.averageResponseTime',
  [TARGET_INDICATORS.customerEngagementRate]: 'label.customerEngagementRate',
  [TARGET_INDICATORS.churnRate]: 'label.churnRate',
  [TARGET_INDICATORS.retentionRate]: 'label.retentionRate',
  [TARGET_INDICATORS.monthlyRecurringRevenue]: 'label.monthlyRecurringRevenue',
  [TARGET_INDICATORS.annualRecurringRevenue]: 'label.annualRecurringRevenue',
  [TARGET_INDICATORS.grossMargin]: 'label.grossMargin',
  [TARGET_INDICATORS.monthlyActiveUsers]: 'label.monthlyActiveUsers',
  [TARGET_INDICATORS.revenueGrowthRate]: 'label.revenueGrowthRate',
  [TARGET_INDICATORS.averageOrderSize]: 'label.averageOrderSize',
  [TARGET_INDICATORS.marketProductFit]: 'label.marketProductFit',
  [TARGET_INDICATORS.investmentInRD]: 'label.investmentInRD',
  [TARGET_INDICATORS.onTimeDeliveryRate]: 'label.onTimeDeliveryRate',
  [TARGET_INDICATORS.cycleTime]: 'label.cycleTime',
  [TARGET_INDICATORS.timeToMarket]: 'label.timeToMarket',
  [TARGET_INDICATORS.processInnovationRate]: 'label.processInnovationRate',
  [TARGET_INDICATORS.newTechnologyAdoptionRate]: 'label.newTechnologyAdoptionRate',
  [TARGET_INDICATORS.initialInvestment]: 'label.initialInvestment',
  [TARGET_INDICATORS.revenuePerEmployee]: 'label.revenuePerEmployee',
  [TARGET_INDICATORS.employeeProductivity]: 'label.employeeProductivity',
  [TARGET_INDICATORS.employeeTurnover]: 'label.employeeTurnover',
  [TARGET_INDICATORS.intellectualPropertyAssets]: 'label.intellectualPropertyAssets',
  [TARGET_INDICATORS.partnerSatisfaction]: 'label.partnerSatisfaction',
  [TARGET_INDICATORS.partnerPerformance]: 'label.partnerPerformance',
  [TARGET_INDICATORS.dependabilityRate]: 'label.dependabilityRate',
  [TARGET_INDICATORS.jointRevenueGeneration]: 'label.jointRevenueGeneration',
  [TARGET_INDICATORS.cashRunway]: 'label.cashRunway',
  [TARGET_INDICATORS.operatingCashFlow]: 'label.operatingCashFlow',
  [TARGET_INDICATORS.breakEven]: 'label.breakEven',
  [TARGET_INDICATORS.netProfitMargin]: 'label.netProfitMargin',
  [TARGET_INDICATORS.operatingExpenses]: 'label.operatingExpensesOPEX',
  [TARGET_INDICATORS.paybackPeriod]: 'label.paybackPeriod',
  [TARGET_INDICATORS.costPerUnit]: 'label.costPerUnit',
};

export const BMC_LABELS = {
  [BMC_TYPE.customerSegments]: 'label.customerSegments',
  [BMC_TYPE.customerRelationships]: 'label.customerRelationships',
  [BMC_TYPE.valuePropositions]: 'label.valuePropositions',
  [BMC_TYPE.revenueStreams]: 'label.revenueStreams',
  [BMC_TYPE.channels]: 'label.channels',
  [BMC_TYPE.costStructure]: 'label.costStructure',
  [BMC_TYPE.keyResources]: 'label.keyResources',
  [BMC_TYPE.keyActivities]: 'label.keyActivities',
  [BMC_TYPE.keyPartners]: 'label.keyPartners',
};

export const BUSINESS_OBJECTIVES_LABELS = {
  [BUSINESS_OBJECTIVES.tenThousand]: 10000,
  [BUSINESS_OBJECTIVES.oneHundredThousand]: 100000,
  [BUSINESS_OBJECTIVES.oneMillion]: 1000000,
  [BUSINESS_OBJECTIVES.tenMillion]: 10000000,
  [BUSINESS_OBJECTIVES.oneHundredMillion]: 100000000,
};

export const TEAM_ROLES_LABELS = {
  [TEAM_ROLES.Hustler]: 'label.hustler',
  [TEAM_ROLES.Hacker]: 'label.hacker',
  [TEAM_ROLES.Hipster]: 'label.hipster',
};

export const LOCALES_LABELS = {
  [LOCALES.ROMANIAN]: 'label.romanian',
  [LOCALES.ENGLISH]: 'label.english',
};

export const SUBSCRIPTION_TYPE_LABELS = {
  [SUBSCRIPTION_TYPE.oneTimePayment]: 'label.oneTimePayment',
  [SUBSCRIPTION_TYPE.subscription]: 'label.subscription',
};
