import { Editor } from '@monaco-editor/react';
import { Grid } from '@mui/material';

import Iframe from '../Iframe';
import { IframeWrapper, Root } from './index.styled';

const HtmlEditor = ({
  content,
  onChange,
  sx = {},
  topOffset = '236px',
}) => (
  <Root container sx={sx} topOffset={topOffset}>
    <Grid item xs={12} md={6}>
      <Editor
        language="html"
        theme="vs-dark"
        value={content}
        options={{
          fontSize: 14,
          formatOnType: true,
          autoClosingBrackets: 'always',
          inlineSuggest: { enabled: true },
          minimap: { enabled: false },
        }}
        className="htmlEditor"
        onChange={onChange}
      />
    </Grid>
    <IframeWrapper item xs={12} md={6} className="scrollbar" topOffset={topOffset}>
      <Iframe title="Preview" content={content} hasDynamicHeight heightOffSet={24} />
    </IframeWrapper>
  </Root>
);

export default HtmlEditor;
