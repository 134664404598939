import { Typography, Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { experienceOptions } from '../../../../constants/options';
import { searchLabel } from '../../../../utils/labels';

const ExperienceDetails = ({ experience }) => {
  const intl = useIntl();
  const titleName = useMemo(() => {
    if (!experience.title) {
      return '';
    }
    const label = searchLabel(experience.title, experienceOptions);
    return label ? intl.formatMessage({ id: label }) : experience.title;
  }, [experience.title]);

  return (
    <Box mb={8}>
      <Typography fontWeight={700}>
        {`${titleName} - ${experience.years} ${intl.formatMessage({ id: 'label.years' })}`}
      </Typography>
      {experience.description ? (
        <Typography>{experience.description}</Typography>
      ) : (
        <Typography variant="body1">
          {intl.formatMessage({ id: 'label.uncompleted' })}
        </Typography>
      )}
    </Box>
  );
};

export default ExperienceDetails;
