import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CommentIcon, FillStarIcon, InfoIcon } from '../assets/icons';
import palette from '../assets/theme/palette';

export const DRAWER_TABS = {
  details: 'details',
  comments: 'comments',
  reviews: 'reviews',
};

export const tabsWithComments = [
  {
    id: DRAWER_TABS.details,
    icon: <InfoIcon />,
    label: <FormattedMessage id="label.details" />,
  },
  {
    id: DRAWER_TABS.comments,
    icon: <CommentIcon fill="currentColor" />,
    label: <FormattedMessage id="label.comments" />,
  }
];

export const tabsWithReviews = [
  {
    id: DRAWER_TABS.details,
    icon: <InfoIcon />,
    label: <FormattedMessage id="label.details" />,
  },
  {
    id: DRAWER_TABS.reviews,
    icon: <FillStarIcon fill={palette.general.yellow} />,
    label: <FormattedMessage id="label.reviews" />,
  }
];
