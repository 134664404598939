import { Typography } from '@mui/material';

import { Root, ValueWrapper } from './index.styled';

const SectionDetails = ({ value, text, top, sx= {} }) => (
  <Root top={top} sx={sx}>
    <ValueWrapper>
      <Typography variant="h2" color="primary">
        {value}
      </Typography>
    </ValueWrapper>

    {text && (
      <Typography
        variant="body1"
        fontWeight="700"
        color="common.white"
        textTransform="uppercase"
        mt={8}
        textAlign="center"
      >
        {text}
      </Typography>
    )}
  </Root>
);

export default SectionDetails;
