import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import DialogComponent from '../../../../common/components/DialogComponent';
import ProfileForm from '../../components/ProfileForm';

import { loadProfile, editProfile } from '../../../../store/userProfile/userProfileSlice';

import { setUserLanguage } from '../../../../utils/storageUtils';

const ProfileDialog = (props) => {
  const {
    loadProfile, isModalOpen, setIsModalOpen, editProfile, userProfile, role
  } = props;
  const intl = useIntl();

  const onSubmit = async (values) => {
    const auxValues = { ...values };
    delete auxValues.photo;
    await editProfile(auxValues, values.photo);
    setIsModalOpen(false);
    if (values.photo) {
      window.location.reload();
    }
    await loadProfile();

    if (values?.language) {
      setUserLanguage(values.language);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DialogComponent
      title={intl.formatMessage({ id: 'label.myProfile' })}
      open={isModalOpen}
      handleClose={handleCloseModal}
    >
      <ProfileForm
        onSubmit={onSubmit}
        userProfile={userProfile}
        role={role}
      />
    </DialogComponent>
  );
};
const mapStateToProps = (state) => ({
  isEditing: state.userProfile.isEditing,
  userProfile: state.userProfile.userProfile,
});

const mapDispatchToProps = {
  loadProfile,
  editProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileDialog)
);
