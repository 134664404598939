import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import EmptyHypothesisCard from '../../../../common/components/HypothesisCard/index.empty';
import TargetIndicator from '../../../../common/components/TargetIndicator';

const EmptySegments = () => {
  const intl = useIntl();

  return (
    <>
      <div>
        <EmptyHypothesisCard text={intl.formatMessage({ id: 'label.emptyClientSegment' })} hasColumn />
        <Box mt={6} display="flex" flexDirection="column" gap={10} width="300px" ml={12}>
          <TargetIndicator placeholder={intl.formatMessage({ id: 'label.missingSAMIndicator' })} />
          <TargetIndicator placeholder={intl.formatMessage({ id: 'label.missingCustomerLifetime' })} />
        </Box>
      </div>

      <div>
        <EmptyHypothesisCard text={intl.formatMessage({ id: 'label.emptyClientSegment' })} hasColumn />
        <Box mt={6} display="flex" flexDirection="column" gap={10} width="300px" ml={12}>
          <TargetIndicator placeholder={intl.formatMessage({ id: 'label.missingSAMIndicator' })} />
          <TargetIndicator placeholder={intl.formatMessage({ id: 'label.missingCustomerLifetime' })} />
        </Box>
      </div>

      <div>
        <EmptyHypothesisCard text={intl.formatMessage({ id: 'label.emptyClientSegment' })} hasColumn />
        <Box mt={6} display="flex" flexDirection="column" gap={10} width="300px" ml={12}>
          <TargetIndicator placeholder={intl.formatMessage({ id: 'label.missingSAMIndicator' })} />
          <TargetIndicator placeholder={intl.formatMessage({ id: 'label.missingCustomerLifetime' })} />
        </Box>
      </div>

      <Box mt={20}>
        <EmptyHypothesisCard text={intl.formatMessage({ id: 'label.emptyRevenueStream' })} />
        <Box mt={6} display="flex" flexDirection="column" gap={10} width="300px" ml={12}>
          <TargetIndicator placeholder={intl.formatMessage({ id: 'label.missingMonthlyValueIndicator' })} />
        </Box>
      </Box>
    </>
  );
};

export default EmptySegments;
