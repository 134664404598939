import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DialogAlert from '../../../common/components/DialogAlert';
import GenericEmptyTable from '../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../common/components/GenericTableComponent';

import { currentTeamIdSelector } from '../../../common/store';
import {
  deleteTargetIndicator,
  getAllTargetIndicators,
} from '../../../store/targetIndicators/targetIndicatorsSlice';

import { NOT_APPLICABLE } from '../../../constants/general';
import { TARGET_INDICATORS_LABELS } from '../../../constants/labels';
import { formatDate } from '../../../utils/dateHelpers';

import { TrashIconOutlined } from '../../../assets/icons';

const IndicatorsComponent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [idToDelete, setIdToDelete] = useState('');
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const indicators = useSelector((state) => state.targetIndicators.targetIndicators || []);

  const handleFetchIndicators = async () => {
    await dispatch(getAllTargetIndicators(teamId));
  };

  const handleDeleteIndicator = async () => {
    await dispatch(deleteTargetIndicator(idToDelete));
    await handleFetchIndicators();
    setIdToDelete('');
  };

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: intl.formatMessage({ id: 'label.designation' }), key: 'targetIndicatorType' },
    { type: 'string', content: intl.formatMessage({ id: 'label.targetValue' }), key: 'value' },
    { type: 'string', content: intl.formatMessage({ id: 'label.date' }), key: 'createdAt' },
    // { type: 'string', content: 'BMC', key: 'columnBMC' },
    // { type: 'string', content: 'Importanta', key: 'importance' },
    // { type: 'string', content: 'Cost', key: 'cost' }, ??????
    // {
    //   type: 'component',
    //   content: <Box>icon</Box>,
    //   hideSortIcon: true,
    //   key: 'ceva'
    // },
    // { type: 'string', content: 'Concluzie', key: 'conclusion' },
    ...(indicators.length
      ? [{ type: 'string', content: '', key: 'delete', hideSortIcon: true }]
      : []),
  ];

  const createRows = () =>
    indicators.map(({ _id, targetIndicatorType, value, createdAt }, index) => {
      const targetIndicatorLabel = TARGET_INDICATORS_LABELS?.[targetIndicatorType] || '';

      return {
        rowId: _id,
        order: index + 1,
        targetIndicatorType: targetIndicatorLabel ? intl.formatMessage({ id: targetIndicatorLabel }) : NOT_APPLICABLE,
        value,
        createdAt: formatDate(createdAt),
        // creator: <CreatorCell firstName={firstName} lastName={lastName} />, // TO add here profile picture from above
        action: (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setIdToDelete(_id);
            }}
            color="error"
          >
            <TrashIconOutlined />
          </IconButton>
        ),
      };
    });

  useEffect(() => {
    if (teamId) {
      handleFetchIndicators();
    }
  }, []);

  return (
    <>
      {indicators && !!indicators.length
        ? <GenericTableComponent rows={createRows()} headerCells={createTableHeaderCells()} />
        : (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          detailMessage={`${intl.formatMessage({ id: 'label.noIndicatorWasAdded' })}...`}
        />
      )}

      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteIndicator' })}
          hasActions
          onSubmit={handleDeleteIndicator}
        />
      )}
    </>
  );
};

export default IndicatorsComponent;
