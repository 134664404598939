import { Box, Dialog, DialogActions, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { CloseIcon, SaveIconOutlined } from '../../../assets/icons';

import ButtonComponent from '../ButtonComponent';
import { Paper, DialogContent } from './index.styled';

const DialogComponent = ({
  open,
  id,
  handleClose,
  title,
  children,
  onSubmit,
  maxWidth = 'sm',
  hasActions = false,
  submitLabel = 'button.save',
  isDisabled = false,
  bodySx = { pt: 8 },
  mb = '22px',
  IconComponent = <SaveIconOutlined />,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      id={id}
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      PaperComponent={Paper}
    >
      {title && (
        <Box display="flex" gap={12} alignItems="center" justifyContent="space-between" mb={mb}>
          <Typography variant="h2" color="primary">
            {title}
          </Typography>
          <IconButton onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      <DialogContent className="scrollbar" sx={bodySx}>
        {children}
      </DialogContent>

      {hasActions && (
        <DialogActions sx={{ p: 0 }}>
          <ButtonComponent
            icon={IconComponent}
            onClick={onSubmit}
            disabled={isDisabled}
            label={intl.formatMessage({ id: submitLabel })}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogComponent;
