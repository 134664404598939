import { Grid, Typography } from '@mui/material';
import React from 'react';

import { PillQuestionAnswer } from '../index.styled';
import InfoMessage from './InfoMessage';

const RowPillAnswer = ({
  options,
  onSelect,
  selectedAnswers,
  isInvited,
  preselected,
}) => {
  const detailsOfSelectedAnswers = options.filter(({ value, onSelectMessage }) => (
    selectedAnswers.includes(value) && onSelectMessage
  ));

  const handleSelect = (option) => {
    if (isInvited && preselected && preselected.values.includes(option.value) && preselected.disabled) {
      return;
    }
    onSelect(option.value);
  };

  return (
    <>
      <Grid container spacing={16}>
        {options.map((option) => (
          <Grid item key={option.value} xs={12} sm={6} md={4} lg={4}>
            <PillQuestionAnswer
              selected={selectedAnswers.includes(option.value)}
              onClick={() => handleSelect(option)}
            >
              <Typography>
                {option.label}
              </Typography>
            </PillQuestionAnswer>
          </Grid>
        ))}
      </Grid>

      {detailsOfSelectedAnswers?.map(({ onSelectMessage, value }) => (
        <InfoMessage key={`additionalInfo-${value}`} message={onSelectMessage} />
      ))}
    </>
  );
};

export default RowPillAnswer;
