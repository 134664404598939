import { FormattedMessage } from 'react-intl';

import { experienceOptions } from '../constants/options';
import { USER_ROLES } from '../constants/roles';

export const getUserRoleAsLabel = (role, userId, teamCreatedBy, userEquity) => {
  if (userId === teamCreatedBy) {
    return <FormattedMessage id='label.founder' />;
  }

  if (role === USER_ROLES.Founder && userEquity > 0) {
    return <FormattedMessage id='label.founder' />;
  }

  if (role === USER_ROLES.Founder && !userEquity) {
    return <FormattedMessage id='label.partner' />;
  }

  if (role === USER_ROLES.Mentor) {
    return <FormattedMessage id='label.expert' />;
  }

  if (role === USER_ROLES.Accelerator) {
    return <FormattedMessage id='label.accelerator' />;
  }

  return <FormattedMessage id='label.unknownRole' />;
};

export const searchLabel = (value, collection) => (
  collection?.find((item) => item.value === value)?.label || ''
);

export const getExperienceTitle = (experience) => {
  const label = searchLabel(experience.title, experienceOptions);

  if (label) {
    return <FormattedMessage id={label} />;
  }
  return experience.title;
};

export const capitalize = (string) => {
  if (!string) return string;

  const [first, ...rest] = string;
  return first.toUpperCase() + rest.join('').toLowerCase();
};
