import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'hasPicture' },
)(({ theme, hasPicture }) =>  `
   padding: ${theme.spacing(10)};
   display: flex;
   align-items: center;
   justify-content: space-between;
   border: ${
    hasPicture
      ? `1px solid ${theme.palette.general.borderAccent}`
      : `1px dashed ${theme.palette.general.borderAccent}`};
   border-radius: 5px;
   
   label div {
      border-color: ${theme.palette.general.borderAccent};
   }
`);
