import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import TargetIndicator from '../../../../common/components/TargetIndicator';

const HypothesesIndicators = ({ targetIndicators = [] }) => {
  const intl = useIntl();
  return (
    <>
      <Typography variant="h2" color="primary">
        {intl.formatMessage({ id: 'label.indicatorsOrTargetPrice' })}
      </Typography>

      <Box display="flex" flexDirection="column" gap={10}>
        {targetIndicators.map((item, index) => {
          if (!item.targetIndicatorType) {
            return null;
          }

          return <TargetIndicator key={index} targetIndicator={item} />;
        })}
      </Box>
    </>
  );
};

export default HypothesesIndicators;
