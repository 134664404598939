import { Typography, Box } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { PaperContent } from '../../../layouts/AuthLayout/auth.styled';

import { notifySuccess } from '../../../core/store';
import { resendConfirmationEmail } from '../../../requests/auth';

import { handleApiError } from '../../../utils/errorUtils';

import ButtonComponent from '../ButtonComponent';

let interval = null;

const VerifyEmail = ({ email, goToLogin }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isResendDisable, setIsResendDisable] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [lastSentAt, setLastSentAt] = useState(0);

  useEffect(() => {
    if (lastSentAt) {
      startCheck();
    }
  }, [lastSentAt]);

  useEffect(() => {
    return () => stopCheck();
  }, []);

  const getDiffInSeconds = () => {
    const secondsDiff = differenceInSeconds(new Date().getTime(), new Date(lastSentAt).getTime());
    return 60 - secondsDiff;
  };

  const handleDisableState = () => {
    const diff = getDiffInSeconds();

    if (diff >= 0) {
      setSeconds(diff);
      setIsResendDisable(false);
    }

    if (diff <= 0) {
      stopCheck();
      setLastSentAt(0);
    }
  };

  const startCheck = () => {
    if (!interval) {
      interval = setInterval(handleDisableState, 1000);
    }
  };

  const stopCheck = () => {
    clearInterval(interval);
    interval = null;
  };

  const handleResend = async () => {
    if (!isResendDisable) {
      try {
        setIsResendDisable(true);
        await resendConfirmationEmail(email);
        setLastSentAt(new Date().getTime());
        dispatch(notifySuccess(intl.formatMessage({ id: 'label.verificationEmailSent' })));
      } catch (e) {
        handleApiError(e);
      }
    }
  };

  return (
    <Box>
      <PaperContent elevation={0}>
        <Typography color="primary" variant="h1" fontWeight={700} mb={12}>
          {intl.formatMessage({ id: 'label.verifyYourEmail' })}
        </Typography>
        <Typography mb={24}>
          {intl.formatMessage(
            { id: 'label.verifyEmailSent' },
            { email: <b>{email}</b> }
          )}
        </Typography>

        <Typography mb={12}>
          {intl.formatMessage({ id: 'label.didntReceivedEmail' })}
          {' '}
          {seconds
            ? (
              <Typography component="span">
                {intl.formatMessage({ id: 'label.tryAgainXSeconds' }, { seconds })}
              </Typography>
            ) : (
              <Typography
                component="span"
                color={isResendDisable ? 'general.grayLight2' : 'primary'}
                className={isResendDisable ? 'default' : 'pointer'}
                onClick={handleResend}
                fontWeight={700}
              >
                {intl.formatMessage({ id: 'label.resendEmail' })}
              </Typography>
            )}
        </Typography>

        <ButtonComponent
          size="large"
          fullWidth
          label={intl.formatMessage({ id: 'label.goToLoginPage' })}
          onClick={goToLogin}
        />
      </PaperContent>
    </Box>
  );
};

export default VerifyEmail;
