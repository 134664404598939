
const MuiOutlinedInput = {
  styleOverrides: {
    root: ({ theme: muiTheme }) => ({
      color: muiTheme.palette.text.primary,
      borderRadius: '5px',

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${muiTheme.palette.general.borderAccent}`,
        },
      },
      '&:hover:not(&.Mui-focused, &.Mui-disabled)': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: muiTheme.palette.general.borderAccent,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: muiTheme.palette.general.borderAccent,
      },
      '&.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: muiTheme.palette.general.borderGray,
        },
      },
      '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
        color: muiTheme.palette.text.secondary,
      }
    }),
    input: ({ theme: muiTheme }) => ({
      '&::placeholder': {
        WebkitTextFillColor: muiTheme.palette.general.placeholder,
        fontStyle: 'italic',
        ...muiTheme.typography.body2,
        opacity: 1,
      },
    }),
  },
};

export default MuiOutlinedInput;
