import { TARGET_INDICATORS } from '../../constants/targetIndicators';

export const getInitialValues = (viabilityModel) => {
  const customerSegments = viabilityModel.customerSegments?.map(({ id }) => id?._id) || [];

  return {
    businessObjective: viabilityModel?.businessObjective || '',
    objectiveCompletionTime: viabilityModel?.objectiveCompletionTime || 1,
    customerConversionRate: viabilityModel?.customerConversionRate || 1,
    qualifiedCustomerConversionRate: viabilityModel?.qualifiedCustomerConversionRate || 1,
    customerDropRate: viabilityModel?.customerDropRate || 1,
    mvpDevelopmentDuration: viabilityModel?.mvpDevelopmentDuration || 1,
    customerSegments,
    revenueStreamsHypothesisId: viabilityModel?.revenueStreamsHypothesisId?._id || '',
  };
};

export const formatValuesForServer = (values) => {
  const customerSegments = values?.customerSegments?.map((id) => ({ id })) || [];

  return {
    ...values,
    customerSegments,
  };
};

export const getGroupedIndicators = (model) => {
  const { viabilityModel, targetIndicators } = model || {};
  const REQUIRED_INDICATORS = [
    TARGET_INDICATORS.averageTransactionValue,
    TARGET_INDICATORS.sam,
    TARGET_INDICATORS.customerLifetimeCLV
  ];
  let groupedIndicators = {};
  const hypotheses = [
    ...viabilityModel?.customerSegments,
    viabilityModel?.revenueStreamsHypothesisId
  ];

  hypotheses?.map((item) => {
    const hypothesis = item?.id || item;
    const filteredIndicators = {};

    targetIndicators?.forEach((targetIndicator) => {
      const { hypothesisId, targetIndicatorType } = targetIndicator;

      if (hypothesisId === hypothesis?._id && REQUIRED_INDICATORS.includes(targetIndicatorType)) {
        filteredIndicators[targetIndicatorType] = targetIndicator;
      }
    });

    groupedIndicators = {
      ...groupedIndicators,
      [hypothesis?._id]: filteredIndicators,
    };
  });

  return groupedIndicators;
};
