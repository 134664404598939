import { Typography } from '@mui/material';
import React from 'react';

import { Root } from './index.styled';

const EmptyState = ({ message, direction = 'center', sx = {} }) => {
  return (
    <Root sx={sx} direction={direction}>
      <Typography className="faded italic" >
        {message}
      </Typography>
    </Root>
  );
};

export default EmptyState;
