import { filter, get } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { loadHypotheses } from '../../../../store/hypothesis/hypothesisSlice';
import { currentTeamIdSelector } from '../../../store';

import { CLIENT_STAGE_OPTIONS } from '../../../../constants/options';
import { DEFAULT_TOOLBAR_CONFIG } from '../../../../constants/richTextEditor';
import { DEFAULT_COUNTER_VALUES } from './constants';

import AutocompleteField from '../../../../fields/AutocompleteField';
import CounterField from '../../../../fields/CounterField';
import InputField from '../../../../fields/InputField';
import RenderRichText from '../../../../fields/RichTextEditor';

const DetailClientForm = ({
  values,
  onSetValues,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const hypotheses = useSelector((state) => state.hypotheses.hypotheses) || [];
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));

  const getBMCOptions = useCallback((columnBMC) => {
    const results = filter(hypotheses, { columnBMC });

    return results?.map(({ title, _id, color }) => ({
      label: title,
      value: _id,
      color: color,
    }));
  }, [hypotheses]);

  useEffect(() => {
    if (teamId) {
      dispatch(loadHypotheses(teamId));
    }
  }, [teamId]);

  const handleChangeCounter = (fieldName, values, oldValue, newValue) => {
    const array = get(values, fieldName) || [];

    if (oldValue > newValue) {
      array.pop();
    } else {
      array.push(DEFAULT_COUNTER_VALUES[fieldName]);
    }

    onSetValues({ ...values, [fieldName]: array, [`${fieldName}Number`]: newValue });
  };

  return (
    <>
      <InputField
        id="title"
        label={intl.formatMessage({ id: 'label.title' })}
        required
      />

      <InputField id="phone" label={intl.formatMessage({ id: 'label.phone' })} />

      <CounterField
        id="emailsNumber"
        label={intl.formatMessage({ id: 'label.email' })}
        minValue={0}
        onChangeValue={(oldValue, newValue) => handleChangeCounter('emails', values, oldValue, newValue)}
      />
      {values?.emails?.map((email, index) => (
        <InputField
          key={`emails-${index}`}
          id={`emails[${index}].email`}
          label={intl.formatMessage({ id: 'label.email' })}
        />
      ))}

      <InputField id="residence" label={intl.formatMessage({ id: 'label.residence' })} />
      <InputField id="company" label={intl.formatMessage({ id: 'label.company' })} />
      <InputField id="role" label={intl.formatMessage({ id: 'label.role' })} />

      <AutocompleteField
        id="clientStage"
        label={intl.formatMessage({ id: 'label.clientStage' })}
        options={CLIENT_STAGE_OPTIONS}
        getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
        required
      />

      <AutocompleteField
        id="revenueStreamsHypothesisId"
        label={intl.formatMessage({ id: 'label.revenueStreams' })}
        options={getBMCOptions('revenueStreams')}
        required
      />

      <AutocompleteField
        id="customerRelationshipsHypothesisId"
        label={intl.formatMessage({ id: 'label.customerRelationships' })}
        options={getBMCOptions('customerRelationships')}
        required
      />

      <RenderRichText
        name="otherInfo"
        label={intl.formatMessage({ id: 'label.otherInfo' })}
        height="200px"
        config={DEFAULT_TOOLBAR_CONFIG}
        isCountVisible
      />

      <InputField id="cost" label={intl.formatMessage({ id: 'label.estimatedCost' })} />
      <InputField id="clientSourceInfo" label={intl.formatMessage({ id: 'label.clientSourceInfo' })} />
      <InputField id="tag" label={intl.formatMessage({ id: 'label.tagYogaClass' })} />

      <CounterField
        id="socialMediaLinksNumber"
        label={intl.formatMessage({ id: 'label.socialNetworkAccount' })}
        minValue={0}
        onChangeValue={(oldValue, newValue) => handleChangeCounter('socialMediaLinks', values, oldValue, newValue)}
      />
      {values?.socialMediaLinks?.map((email, index) => (
        <InputField
          key={`socialMediaLinks-${index}`}
          id={`socialMediaLinks[${index}].name`}
          label={intl.formatMessage({ id: 'label.linkSocialNetwork' })}
        />
      ))}
    </>
  );
};

export default DetailClientForm;
