import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import InformationRow from '../../../components/InformationRow';
import LinkComponent from '../../../components/LinkComponent';

import { getDetailById } from '../../../../requests/details';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { handleApiError } from '../../../../utils/errorUtils';

import SegmentInfo from './SegmentInfo';

const UserStoryDetail = ({
  detail,
  parentDetail,
  onLoading,
}) => {
  const intl = useIntl();
  const [completedDetail, setCompletedDetail] = useState(detail);

  useEffect(() => {
    if (detail?._id) {
      fetchCompletedDetail();
    }
  }, [detail]);

  const fetchCompletedDetail = async () => {
    onLoading({ loading: true });

    try {
      const response = await getDetailById(detail?._id);
      setCompletedDetail(response?.data);
    } catch (e) {
      handleApiError(e);
    } finally {
      onLoading({ loading: false });
    }
  };

  return (
    <>
      <InformationRow
        label={intl.formatMessage({ id: 'label.detailName' })}
        value={parentDetail?.title}
      />

      <InformationRow
        label={intl.formatMessage({ id: 'label.segment' })}
        value={parentDetail?.customerSegmentHypothesisId?.title}
        isContained
        backgroundColor={parentDetail?.customerSegmentHypothesisId?.color}
        color="primary"
      />

      <Grid container spacing={16}>
        {completedDetail?.characteristicsFromCustomerSegments?.length > 0 && (
          <Grid item xs={12} gap={4}>
            {completedDetail?.characteristicsFromCustomerSegments?.map(({ id: info }) => (
              <div key={info._id}>
                <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
                  {intl.formatMessage({ id: 'label.characteristics' })}
                </Typography>
                <SegmentInfo name={info?.description} />
              </div>
            ))}
          </Grid>
        )}

        {completedDetail?.tasksFromCustomerSegments?.length > 0 && (
          <Grid item xs={12} gap={4}>
            <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
              {intl.formatMessage({ id: 'label.tasksOrProblemsIntensity' })}
            </Typography>
            <Box display="flex" flexDirection="column" gap={4} mt={2}>
            {completedDetail?.tasksFromCustomerSegments?.map(({ id: info, intensity }) => (
              <SegmentInfo key={info._id} name={info?.description} intensity={intensity} />
            ))}
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.context' })}
            value={completedDetail.taskContext}
            richText
          />
        </Grid>

        {completedDetail?.winsFromCustomerSegments?.length > 0 && (
          <Grid item xs={12} gap={4}>
            <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
              {intl.formatMessage({ id: 'label.winsAndIntensity' })}
            </Typography>
            <Box display="flex" flexDirection="column" gap={4} mt={2}>
              {completedDetail?.winsFromCustomerSegments?.map(({ id: info, intensity }) => (
                <SegmentInfo key={info._id} name={info?.description} intensity={intensity} />
              ))}
            </Box>
          </Grid>
        )}

        {completedDetail?.painsFromCustomerSegments?.length > 0 && (
          <Grid item xs={12} gap={4}>
            <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
              {intl.formatMessage({ id: 'label.painsAndIntensity' })}
            </Typography>
            <Box display="flex" flexDirection="column" gap={4} mt={2}>
              {completedDetail?.painsFromCustomerSegments?.map(({ id: info, intensity }) => (
                <SegmentInfo key={info._id} name={info?.description} intensity={intensity} />
              ))}
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.currentSolutions' })}
            value={completedDetail.currentSolution}
            richText
          />
        </Grid>

        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.otherInfo' })}
            value={completedDetail.otherInfo}
            richText
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
            {intl.formatMessage({ id: 'label.links' })}
          </Typography>
          {completedDetail?.links?.length > 0
            ? completedDetail?.links.map((link, index) => (
              <LinkComponent key={index} domain={link.name} />
            ))
            : NOT_APPLICABLE}
        </Grid>
      </Grid>
    </>
  );
};

export default UserStoryDetail;
