import { http } from '../core/services/http';

import {
  GET_QUESTIONNAIRE_PROGRESS,
  POST_QUESTIONNAIRE_ANSWER,
  PUT_FINISH_QUESTIONAIRE,
  UPDATE_QUESTIONNAIRE_ANSWER,
} from '../constants/apiRoutes';
import { formatRoute } from '../utils/formatters';

export const getQuestionnaireProgress = (userId) => (
  http.get(formatRoute(GET_QUESTIONNAIRE_PROGRESS, { userId }))
);

export const saveQuestionAnswer = (data) => (
  http.post(POST_QUESTIONNAIRE_ANSWER, data)
);

export const updateQuestionAnswer = (questionId, data) => (
  http.put(formatRoute(UPDATE_QUESTIONNAIRE_ANSWER, { questionId }), data)
);

export const putFinishQuestionnaire = () => (
  http.put(PUT_FINISH_QUESTIONAIRE)
);
