import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { AddIcon } from '../../assets/icons';

import ButtonComponent from './ButtonComponent';

export const AddReward = ({ setValues, values, color = 'primary', variant = 'contained' }) => {
  const intl = useIntl();

  const addExperience = () => {
    setValues({
      ...values,
      rewardPreference: [
        ...values.rewardPreference,
        { title: '', value: '', rewardPreference: '' }
      ]
    });
  };

  return (
    <Box display="flex" justifyContent="flex-end" mt={8}>
      <ButtonComponent
        color={color}
        variant={variant}
        label={intl.formatMessage({ id: 'button.addReward' })}
        icon={<AddIcon fill='currentColor' width='22px' height='22px' />}
        onClick={addExperience}
      />
    </Box>
  );
};

export default AddReward;
