import { styled } from '@mui/material/styles';

export const TrendWrapper = styled('div')(({ theme, isTrendFavorable }) => `
  border: 1px solid ${theme.palette.general.accentLight};
  padding: ${theme.spacing(14.5, 15)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  
  
  & svg {
    ${!isTrendFavorable ? 'transform: scaleY(-1);' : ''}
  
    & path {
      fill: ${isTrendFavorable ? theme.palette.success.main : theme.palette.error.secondary};
    }
  }
`);
