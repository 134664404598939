import { ExclamationMarkIcon, HappyIcon, SadIcon, SearchPersonIcon } from '../../assets/icons';

export const getCharacteristicInfo = (type) => {
  if (type === 'who') {
    return {
      title: 'label.characteristics',
      IconComponent: SearchPersonIcon,
    };
  }

  if (type === 'task') {
    return {
      title: 'label.tasksOrProblems',
      IconComponent: ExclamationMarkIcon,
    };
  }

  if (type === 'pain') {
    return {
      title: 'label.fears',
      IconComponent: SadIcon,
    };
  }

  if (type === 'win') {
    return {
      title: 'label.gains',
      IconComponent: HappyIcon,
    };
  }
};

export const compareUserStories = (a, b, direction = 'DESC') => {
  const lengthA = a?.userStories?.length || 0;
  const lengthB = b?.userStories?.length || 0;
  const order = direction === 'ASC' ? 1 : -1;

  if (lengthA > lengthB) return order;
  if (lengthA < lengthB) return -order;
  return 0;
};
