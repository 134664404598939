import { DETAIL_CATEGORIES } from '../../../../constants/detail';

import { DocumentIcon, Paperclip, CampaignIcon } from '../../../../assets/icons';

export const getDetailIcon = (category) => {
  if (category === DETAIL_CATEGORIES.minuteClient) {
    return DocumentIcon;
  }

  if (category === DETAIL_CATEGORIES.userStory) {
    return CampaignIcon;
  }

  return Paperclip;
};
