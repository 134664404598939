export const getInitialValues = (detail, hypothesisId) => ({
  category: detail?.category || '',
  title: detail?.title || '',
  description: detail?.description || '',
  cost: detail?.cost || 0,
  status: detail?.status || '',
  linksNumber: detail?.links?.length || 0,
  links: detail?.links || [],
  // TODO: to be enabled in future -> isPublic: false,
  // Client Values
  customerSegmentHypothesisId: detail?.customerSegmentHypothesisId || hypothesisId,
  phone: detail?.phone || '',
  emailsNumber: detail?.emails?.length || 0,
  emails: detail?.emails || [],
  residence: detail?.residence || '',
  company: detail?.company || '',
  role: detail?.role || '',
  clientStage: detail?.clientStage || '',
  revenueStreamsHypothesisId: detail?.revenueStreamsHypothesisId || '',
  customerRelationshipsHypothesisId: detail?.customerRelationshipsHypothesisId || '',
  otherInfo: detail?.otherInfo || '',
  clientSourceInfo: detail?.clientSourceInfo || '',
  tag: detail?.tag || '',
  socialMediaLinksNumber: detail?.socialMediaLinksNumber?.length || 0,
  socialMediaLinks: detail?.socialMediaLinks || [],
});
