import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Navigation from '../../components/Navigation';
import RowPillAnswer from '../../components/RowPillAnswer';
import StepTitle from '../../components/StepTitle';

import { getAllTeams, saveTeamInvitation } from '../../../../requests/teams';

import { USER_ROLES } from '../../../../constants/roles';
import { handleApiError } from '../../../../utils/errorUtils';

import SendIcon from '../../../../assets/icons/SendIcon';

import AutocompleteField from '../../../../fields/AutocompleteField';
import InputField from '../../../../fields/InputField';
import { Content, InviteButton } from '../../index.styled';

const NumberOfMembersStep = ({
  stepInfo,
  onSelect,
  selectedAnswers,
  userProfile,
  onSubmit,
  onBack,
  isDisabled,
  hasBackButton,
}) => {
  const intl = useIntl();
  const [canSubmit, setCanSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [team, setTeam] = useState(null);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const { data } = await getAllTeams(userProfile?.userId?._id);
      setTeam(data[0]);
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleSubmitInvite = async (values, resetForm) => {
    try {
      setIsSubmitting(true);
      await saveTeamInvitation(team?._id, { ...values });
      await fetchTeams();
      resetForm();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const initialValues = {
    lastName: '',
    firstName: '',
    email: '',
    role: USER_ROLES.Founder,
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values?.lastName) {
      errors.lastName = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values?.firstName) {
      errors.firstName = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values?.email) {
      errors.email = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values.role) {
      errors.role = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!Object.keys(errors).length) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }

    return errors;
  };

  const getLabel = (label) => intl.formatMessage({ id: label }).toLowerCase();

  return (
    <>
      <Content>
        <Box mt={50} mb={30}>
          <StepTitle title={stepInfo.question} />

          <RowPillAnswer
            onSelect={onSelect}
            options={stepInfo.answerOptions}
            selectedAnswers={selectedAnswers}
          />

          <Box mt={50}>
            <Typography variant="h2" color="primary" mb={16}>
              {intl.formatMessage({ id: 'label.inviteMembersToTeam' })}
            </Typography>

            <Box display="flex" alignItems="center" mb={32}>
              <Formik
                initialValues={initialValues}
                validate={validateForm}
              >
                {({ handleSubmit, values, resetForm }) => (
                  <form
                    style={{ width: '100%' }}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <Box display="flex" alignItems="center" gap={16}>
                      <InputField
                        id="lastName"
                        label={getLabel('label.name')}
                      />
                      <InputField
                        id="firstName"
                        label={getLabel('label.firstName')}
                      />
                      <InputField
                        id="email"
                        label={getLabel('label.email')}
                      />
                      <AutocompleteField
                        id="role"
                        sx={{ minWidth: '150px', mb: '15px' }}
                        label={getLabel('label.chooseRole')}
                        options={[
                          { label: getLabel('label.founder'), value: USER_ROLES.Founder },
                          { label: getLabel('label.expert'), value: USER_ROLES.Mentor },
                        ]}
                      />
                      <InviteButton
                        disabled={!canSubmit || isSubmitting}
                        onClick={() => handleSubmitInvite(values, resetForm)}
                      >
                        <SendIcon size={18} />
                      </InviteButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>

            {team?.membersToInvite?.length > 0 && (
              <>
                <Typography variant="h2" color="primary" mb={16}>
                  {intl.formatMessage({ id: 'label.invitations' })}
                </Typography>
                <Box pl={32}>
                  <ul>
                    {team.membersToInvite.map(({ email }) => (
                      <li key={email}>
                        <Typography gutterBottom>
                          {email}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Content>

      <Navigation
        onBack={onBack}
        onSubmit={onSubmit}
        disabled={isSubmitting || isDisabled}
        hasBackButton={hasBackButton}
      />
    </>
  );
};

export default NumberOfMembersStep;
