import { Box, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PageTitle from '../../../../common/components/PageTitle';
import Tabs from '../../../../common/components/Tabs';
import { TabContent } from '../../../../common/components/Tabs/index.styled';
import CommentsDrawer from '../../../commentsAndNotifications/components/CommentsDrawer';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { BMC_LABELS } from '../../../../constants/labels';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';
import { formatTimestamp, getPageTabs } from '../../utils';

import { VerifiedIcon } from '../../../../assets/icons';

import DetailsTable from '../../../../common/containers/DetailsTable';
import HypothesisActivities from '../../../hypothesisActivities/containers/HypothesisActivities';
import HypothesisDescription from '../../../hypothesisDescription/containers/HypothesisDescription';
import HypothesisProfile from '../../../hypothesisProfile/containers/HypothesisProfile';
import HypothesisSegmentDimension from '../../../hypothesisSegmentDimension/containers/HypothesisSegmentDimension';

const HypothesisPage = () => {
  const intl = useIntl();
  const theme = useTheme();
  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'description';
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const history = useHistory();

  const hypothesis = useSelector((state) => state.hypotheses.hypothesis);

  const [, setIsPersonInfoModalOpen] = useState(false);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB[activeTab]]) || [];
  const currentUserId = useSelector((state) => state.userProfile.userProfile.userId._id);

  const tabs = useMemo(() => (
    getPageTabs(hypothesis.columnBMC === 'customerSegments')
  ), [hypothesis.columnBMC]);

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB[activeTab],
          entityId: hypothesis._id,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  useEffect(() => {
    fetchComments();
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/hypothesis/${hypothesis._id}?tab=${tab}`);
  };

  const pageComponents = {
    description: <HypothesisDescription hypothesis={hypothesis} />,
    segmentDimension: <HypothesisSegmentDimension />,
    profile: (
      <HypothesisProfile
        hypothesis={hypothesis}
        setIsPersonInfoModalOpen={setIsPersonInfoModalOpen}
      />
    ),
    activities: <HypothesisActivities />,
    details: <DetailsTable section="hypotheses" hypothesis={hypothesis} />,
  };

  useEffect(
    () => () => localStorage.removeItem('previousHypothesisLocation'),
    []
  );

  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        <Box display="flex" alignItems="center" gap={10} justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={10}>
            <PageTitle
              title={hypothesis.title}
              onBack={history.goBack}
              mb={0}
            />
            {hypothesis.hypothesisStage === 'validated' && (
              <VerifiedIcon size="24px" color={theme.palette.success.main} />
            )}
          </Box>

          <CommentsDrawer
            fetchComments={fetchComments}
            comments={comments}
            teamId={teamId}
            userId={currentUserId}
            type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB[activeTab]}
            entityId={hypothesis._id}
          />
        </Box>
        <Typography className="faded">
          {BMC_LABELS?.[hypothesis?.columnBMC]
            ? intl.formatMessage({ id: BMC_LABELS?.[hypothesis.columnBMC] })
            : NOT_APPLICABLE
          }
          {'|'}
          {intl.formatMessage({ id: 'label.lastEditedBy' })}
          {': '}
          {hypothesis.createdBy}
          {'|'}
          {formatTimestamp(hypothesis.updatedAt)}
        </Typography>
      </Box>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={handleTabChange}
      />

      <TabContent className="scrollbar" offsetTop="140px">
        {pageComponents[activeTab]}
      </TabContent>
    </>
  );
};

export default HypothesisPage;
