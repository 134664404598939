import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import DrawerComponent from '../../../common/components/DrawerComponent';
import DrawerTitle from '../../../common/components/DrawerTitle';

import { UnreadNotificationIcon } from '../../../assets/icons';

import { OrangeCircle } from '../index.styled';
import GenericComments from './GenericComments';

const CommentsDrawer = ({
  teamId,
  userId,
  type,
  entityId = null,
  comments = [],
  fetchComments,
}) => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const queryCommentId = query.get('comment') || '';
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(!!queryCommentId);

  const hasUnreadMessages = !!comments.find((comment) => comment.unread || comment.unreadReplies);

  return (
    <>
      <OrangeCircle onClick={() => setNotificationDrawerOpen(true)}>
        <UnreadNotificationIcon
          hasUnreadMessages={hasUnreadMessages}
        />
      </OrangeCircle>

      <DrawerComponent
        isOpen={notificationDrawerOpen}
        onClose={() => setNotificationDrawerOpen(false)}
      >
        <>
          <DrawerTitle
            title={intl.formatMessage({ id: 'label.comments' })}
            counter={comments.length}
            onClose={() => setNotificationDrawerOpen(false)}
          />
          <GenericComments
            teamId={teamId}
            userId={userId}
            type={type}
            entityId={entityId}
            comments={comments}
            fetchComments={fetchComments}
          />
        </>
      </DrawerComponent>
    </>
  );
};

export default CommentsDrawer;
