import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React from 'react';

import TabLabel from './TabLabel';

function a11yProps(index, id) {
  return {
    id: id || `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

const Tabs = ({ tabs, activeTab, onChange, id = '' }) => {

  const handleTabChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <MuiTabs
      id={id}
      value={activeTab}
      onChange={handleTabChange}
      aria-label="tab"
      sx={{ mb: 20 }}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
    >
      {tabs.map((tab, index) => (
        <Tab
          key={`${id}-${index}`}
          label={<TabLabel tab={tab} />}
          value={tab.key}
          {...a11yProps(tab.key, tab.id)}
        />
      ))}
    </MuiTabs>
  );
};

export default Tabs;
