import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import ManageTeamEquityForm from '../ManageTeamEquityForm';

const ManageTeamModal = ({ onClose, isOpen, members, teamId, runUpdateTeamMembers }) => {
  const intl = useIntl();
  return (
    <DialogComponent
      open={isOpen}
      handleClose={onClose}
      title={intl.formatMessage({ id: 'modal.title.editAssociateStructure' })}
    >
      <ManageTeamEquityForm
        runUpdateTeamMembers={runUpdateTeamMembers}
        members={members}
        onClose={onClose}
        teamId={teamId}
      />
    </DialogComponent>
  );
};

export default ManageTeamModal;
