import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

import { TrashIconOutlined, EditIcon } from '../../assets/icons';

const RoundElement = ({ number, borderColor, numberColor }) => (
  <Box
    sx={{
      width: 22,
      height: 22,
      borderRadius: '50%',
      border: `1px solid ${borderColor}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: `${numberColor}`,
    }}
  >
    {number}
  </Box>
);
const Category = ({ name, handleEdit, handleDelete, order, isDisabled }) => {
  const categoryColor = isDisabled ? '#DEE7FC' : '#74A3FE';
  return (
    <Box
      onClick={!isDisabled ? handleEdit : () => {}}
      title="Nu se poate edita sau sterge categoria generala"
      sx={{
        border: `1px solid ${categoryColor}`,
        width: '70%',
        height: '64px',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        cursor: !isDisabled && 'pointer',
        '&:hover': {
          backgroundColor: !isDisabled && '#DEE7FC',
        },
      }}
    >
      <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        {/* <DragIndicator fill={categoryColor} /> */}
        <RoundElement
          number={order}
          borderColor="#74A3FE"
          numberColor="#74A3FE"
        />
        <Typography color="primary">{name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '15px' }}>
        <IconButton onClick={(e) => handleEdit(e)} disabled={isDisabled}>
          {!isDisabled && <EditIcon width="16" height="18" />}
        </IconButton>
        <IconButton color="error" onClick={(e) => handleDelete(e)} disabled={isDisabled}>
          {!isDisabled && <TrashIconOutlined width="16" height="18" />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default Category;
