import { http } from '../../core/services/http';

export const addArticle = (payload) => {
  const url = '/articles/add';
  return http.post(url, payload);
};

export const editArticle = (articleId, payload) => {
  const url = `/articles/edit/${articleId}`;
  return http.put(url, payload);
};

export const getAllArticles = (isVisible, categories) => {
  const url = `/articles/get-all?filters=${JSON.stringify({
    isVisible,
    categories,
  })}`;
  return http.get(url);
};

export const getArticleById = (articleId) => {
  const url = `/articles/get-by-id/${articleId}`;
  return http.get(url);
};

export const deleteArticle = (articleId) => {
  const url = `/articles/delete/${articleId}`;
  return http.delete(url);
};
