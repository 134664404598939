import { Button } from './index.styled';

const ButtonComponent = (props) => {
  const {
    label = '',
    isLoading = false,
    variant = 'contained',
    color = 'primary',
    icon = null,
    iconPlacement = 'start',
    disabled = false,
    id,
    ...remainingProps
  } = props;
  const isDisabled = disabled || isLoading;

  return (
    <Button
      {...remainingProps}
      id={id}
      disabled={isDisabled}
      variant={variant}
      color={color}
      endIcon={iconPlacement === 'end' && icon}
      startIcon={iconPlacement === 'start' && icon}
      hasLabel={!!label}
    >
      {label}
    </Button>
  );
};

export default ButtonComponent;
