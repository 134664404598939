export const DETAIL_CATEGORIES = {
  listaBusinessAngels: 'listaBusinessAngels',
  listaFonduriDeInvestitii: 'listaFonduriDeInvestitii',
  termSheetAgreement: 'termSheetAgreement',
  listaFinantariPublice: 'listaFinantariPublice',
  intentieScrisoareCumparare: 'intentieScrisoareCumparare',
  ordinComandaFerma: 'ordinComandaFerma',
  documentVanzare: 'documentVanzare',
  documentatieTehnicaSolutie: 'documentatieTehnicaSolutie',
  feedbackDemo: 'feedbackDemo',
  analizaDateDemo: 'analizaDateDemo',
  politicaDePret: 'politicaDePret',
  chestionarDescoperireNevoi: 'chestionarDescoperireNevoi',
  structuraInterviu: 'structuraInterviu',
  analiza: 'analiza',
  detaliiDeContact: 'detaliiDeContact',
  imagine: 'imagine',
  inregistrareAudio: 'inregistrareAudio',
  inregistrareVideo: 'inregistrareVideo',
  librarieDeDate: 'librarieDeDate',
  mockupSchita: 'mockupSchita',
  prototipMVPLoFi: 'prototipMVPLoFi',
  prototipMVPHiFi: 'prototipMVPHiFi',
  prezentareIdee: 'prezentareIdee',
  pitchDeckPentruInvestitori: 'pitchDeckPentruInvestitori',
  prezentareEchipa: 'prezentareEchipa',
  proofOfConcept: 'proofOfConcept',
  raspunsChestionar: 'raspunsChestionar',
  raport: 'raport',
  studiuDePiata: 'studiuDePiata',
  strategieDePenetrareAPietei: 'strategieDePenetrareAPietei',
  cashFlow: 'cashFlow',
  argumentareDimensiuneaPietei: 'argumentareDimensiuneaPietei',
  intelegereaAsociatilor: 'intelegereaAsociatilor',
  analizaSWOT: 'analizaSWOT',
  analizaPESTLE: 'analizaPESTLE',
  analiza5Forte: 'analiza5Forte',
  analizaTrenduri: 'analizaTrenduri',
  analizaCompetitiei: 'analizaCompetitiei',
  solutionRoadMap: 'solutionRoadMap',
  design: 'design',
  designSolutie: 'designSolutie',
  specificatiiTehniceSolutie: 'specificatiiTehniceSolutie',
  userFlow: 'userFlow',
  customerJourney: 'customerJourney',
  listaClientiPotentiali: 'listaClientiPotentiali',
  altaCategorie: 'altaCategorie',
  salesScript: 'salesScript',
  document: 'document',
  procedure: 'procedure',
  diagram: 'diagram',
  listInterviewQuestions: 'listInterviewQuestions',
  minuteGeneral: 'minuteGeneral',
  stakeholder: 'stakeholder',
  elevatorPitch: 'elevatorPitch',
  detailedSegmentAnalysis: 'detailedSegmentAnalysis',
  client: 'client',
  minuteClient: 'minuteClient',
  userStory: 'userStory',
};

export const CLIENT_STAGE = {
  notInterested: 'notInterested',
  prospect: 'prospect',
  qualified: 'qualified',
  activated: 'activated',
  payingClient: 'payingClient',
  loyalClient: 'loyalClient',
  clientAccountClosed: 'clientAccountClosed',
};
