import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import InformationRow from '../../../components/InformationRow';
import LinkComponent from '../../../components/LinkComponent';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { CLIENT_STAGE_LABELS } from '../../../../constants/labels';

const ClientDetail = ({ detail }) => {
  const intl = useIntl();
  const clientStage = CLIENT_STAGE_LABELS?.[detail?.clientStage]
    ? intl.formatMessage({ id: CLIENT_STAGE_LABELS?.[detail?.clientStage] })
    : '';

  return (
    <>
      <InformationRow
        label={intl.formatMessage({ id: 'label.detailName' })}
        value={detail?.title}
      />

      <Grid container spacing={16}>
        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.segment' })}
            value={detail?.customerSegmentHypothesisId?.title}
            isContained
            backgroundColor={detail?.customerSegmentHypothesisId?.color}
            color="primary"
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.phone' })}
            value={detail?.phone}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
            {intl.formatMessage({ id: 'label.email' })}
          </Typography>
          {detail?.emails?.length > 0
            ? detail?.emails.map(({ email }, index) => (
              <Typography
                variant="body1"
                key={index}
                gutterBottom
                sx={{ wordBreak: 'break-all' }}
              >
                {email}
              </Typography>
            ))
            : NOT_APPLICABLE}
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.residence' })}
            value={detail?.residence}
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.company' })}
            value={detail?.company}
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.role' })}
            value={detail?.role}
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.estimatedCost' })}
            value={detail?.cost}
          />
        </Grid>

        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.clientStage' })}
            value={clientStage}
            isContained
            color="primary"
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.revenueStreams' })}
            value={detail?.revenueStreamsHypothesisId?.title}
            isContained
            color="primary"
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.customerRelationships' })}
            value={detail?.customerRelationshipsHypothesisId?.title}
            isContained
            color="primary"
          />
        </Grid>

        <Grid item xs={12}>
          <InformationRow
            richText
            label={intl.formatMessage({ id: 'label.otherInfo' })}
            value={detail?.otherInfo}
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.infoSource' })}
            value={detail?.clientSourceInfo}
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.tag' })}
            value={detail?.tag}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
            {intl.formatMessage({ id: 'label.socialNetworkAccount' })}
          </Typography>
          {detail?.socialMediaLinks?.length > 0
            ? detail?.socialMediaLinks.map((link, index) => (
              <LinkComponent key={index} domain={link.name} />
            ))
            : NOT_APPLICABLE}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
            {intl.formatMessage({ id: 'label.links' })}
          </Typography>
          {detail?.links?.length > 0
            ? detail?.links.map((link, index) => (
              <LinkComponent key={index} domain={link.name} />
            ))
            : NOT_APPLICABLE}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientDetail;
