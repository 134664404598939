import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import AvatarImage from '../../../../common/components/AvatarImage';
import ButtonComponent from '../../../../common/components/ButtonComponent';
import InformationRow from '../../../../common/components/InformationRow';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { TASK_STATUS_OPTIONS } from '../../../../constants/options';
import { formatDate } from '../../../../utils/dateHelpers';
import { formatUserName } from '../../../../utils/formatters';
import { searchLabel } from '../../../../utils/labels';
import {
  TASK_STATUS_COLOR,
} from '../../constants';

import {
  EditIcon,
  TrashIconOutlined,
} from '../../../../assets/icons';

import TaskDetailsList from '../TaskDetailsList';
import { TaskStatus } from './index.styled';

// TODO: read the right value for TBD
const TaskDetails = ({
  previewedTask,
  handleDeleteTask,
  handleEditTask,
  setTaskDetailModalId,
  setPreview,
}) => {
  const intl = useIntl();
  const { userResponsibleId } = previewedTask || {};
  const statusText = searchLabel(previewedTask.status, TASK_STATUS_OPTIONS);

  return (
    <>
      <Box mt={20} gap={20} display="flex" flexDirection="column">
        <InformationRow
          label={intl.formatMessage({ id: 'label.title' })}
          value={previewedTask?.title}
        />

        <InformationRow
          richText
          label={intl.formatMessage({ id: 'label.description' })}
          value={previewedTask?.description}
        />

        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
              {intl.formatMessage({ id: 'label.personInCharge' })}
            </Typography>
            <Box display="flex" alignItems="center" gap={8}>
              {userResponsibleId?.profilePicture && (
                <AvatarImage
                  width="20px"
                  height="20px"
                  src={userResponsibleId?.profilePicture}
                  isRounded
                />
              )}
              <Typography variant="body1" color="primary" fontWeight={600}>
                {userResponsibleId
                  ? formatUserName(userResponsibleId)
                  : NOT_APPLICABLE
                }
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <InformationRow
              label={intl.formatMessage({ id: 'label.totalCost' })}
              value={previewedTask?.cost}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
              {intl.formatMessage({ id: 'label.status' })}
            </Typography>
            <Box display="flex" alignItems="center" gap={4}>
              <TaskStatus backgroundColor={TASK_STATUS_COLOR[previewedTask?.status]} />
              <Typography variant="body1" color="primary" fontWeight="600" ml={4}>
                {statusText}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <InformationRow
              label={intl.formatMessage({ id: 'label.creationDate' })}
              value={previewedTask?.completionDate ? formatDate(previewedTask?.completionDate) : null}
            />
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" gap={10}>
          <ButtonComponent
            onClick={handleEditTask}
            variant="outlined"
            color="secondary"
            icon={<EditIcon fill="currentColor" size="14px" />}
            label={intl.formatMessage({ id: 'button.edit' })}
          />
          <ButtonComponent
            onClick={() => handleDeleteTask(previewedTask?._id)}
            icon={<TrashIconOutlined size="16px" />}
            color="error"
            label={intl.formatMessage({ id: 'button.delete' })}
          />
        </Box>

        <Divider />

        <TaskDetailsList
          task={previewedTask}
          setTaskDetailModalId={setTaskDetailModalId}
          setPreview={setPreview}
        />
      </Box>
    </>
  );
};

export default TaskDetails;
