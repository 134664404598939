import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const HypothesisOnboardingRouter = () => {
  const history = useHistory();
  const userTeams = useSelector((state) => state?.hypotheses?.hypotheses);
  const firstCustomerSegment = userTeams.find(
    (team) => team.columnBMC === 'customerSegments'
  );

  useEffect(() => {
    if (firstCustomerSegment?._id) {
      history.push(`/hypothesis/${firstCustomerSegment._id}?tab=profile`);
    }
  }, [firstCustomerSegment]);

  return null;
};

export default HypothesisOnboardingRouter;
