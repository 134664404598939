import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BMC_TYPE, BUSINESS_OBJECTIVES } from './bmc';
import { CLIENT_STAGE, DETAIL_CATEGORIES } from './detail';
import { SUBSCRIPTION_TYPE } from './hypothesis';
import { INDUSTRY_IMPACT } from './industry';
import { TEAM_ROLES } from './roles';
import { TARGET_INDICATORS } from './targetIndicators';

export const ideaCategoryOptions = [
  { label: <FormattedMessage id="label.finTech" />, value: 'FinTech' },
  { label: <FormattedMessage id="label.agroTech" />, value: 'AgroTech' },
  { label: <FormattedMessage id="label.webMobile" />, value: 'Web/mobile' },
  { label: <FormattedMessage id="label.healthTech" />, value: 'HealthTech' },
  { label: <FormattedMessage id="label.propTech" />, value: 'PropTech' },
  { label: <FormattedMessage id="label.edTech" />, value: 'EdTech' },
  { label: <FormattedMessage id="label.greenEnergyBlueEnergy" />, value: 'Green Energy Blue Energy' },
  { label: <FormattedMessage id="label.recycling" />, value: 'Recycling' },
  { label: <FormattedMessage id="label.tech" />, value: 'Tech' },
  { label: <FormattedMessage id="label.insurTech" />, value: 'InsurTech' },
  { label: <FormattedMessage id="label.manufacturing" />, value: 'Manufacturing' },
  { label: <FormattedMessage id="label.services" />, value: 'Servicii/Services' },
  { label: <FormattedMessage id="label.agriculture" />, value: 'Agriculture' },
  { label: <FormattedMessage id="label.retail" />, value: 'Comerț/Retail' },
  {
    label: <FormattedMessage id="label.hospitalityIndustry" />,
    value: 'HORECA/Hospitality industry',
  },
  { label: <FormattedMessage id="label.bioTech" />, value: 'BioTech' },
  { label: <FormattedMessage id="label.madTech" />, value: 'MadTech' },
  { label: <FormattedMessage id="label.other" />, value: 'Other' },
  { label: <FormattedMessage id="label.b2b" />, value: 'B2B' },
];

export const ideaRoleOptions = [
  { label: <FormattedMessage id="label.hustler" />, value: TEAM_ROLES.Hustler },
  { label: <FormattedMessage id="label.hacker" />, value: TEAM_ROLES.Hacker },
  { label: <FormattedMessage id="label.hipster" />, value: TEAM_ROLES.Hipster },
];

export const activityTypeOptions = [
  { value: 'faceToFace', label: 'label.faceToFace' },
  { value: 'online', label: 'label.online' },
  { value: 'byPhone', label: 'label.telephone' },
  { value: 'email', label: 'label.email' },
  { value: 'hybrid', label: 'label.hybrid' },
  { value: 'deskResearch', label: 'label.deskResearch' },
  { value: 'anotherMethod', label: 'label.anotherMethod' },
];

export const categoryOptions = [
  { value: DETAIL_CATEGORIES.listaBusinessAngels, label: 'label.businessAngelsList' },
  { value: DETAIL_CATEGORIES.listaFonduriDeInvestitii, label: 'label.investmentsFundsList' },
  { value: DETAIL_CATEGORIES.termSheetAgreement, label: 'label.termSheetAgreement' },
  { value: DETAIL_CATEGORIES.listaFinantariPublice, label: 'label.publicFinancingList' },
  {
    value: DETAIL_CATEGORIES.intentieScrisoareCumparare,
    label: 'label.letterOfIntentPurchase',
  },
  { value: DETAIL_CATEGORIES.ordinComandaFerma, label: 'label.firmOrder' },
  { value: DETAIL_CATEGORIES.documentVanzare, label: 'label.saleDocument' },
  {
    value: DETAIL_CATEGORIES.documentatieTehnicaSolutie,
    label: 'label.solutionTechnicalDocumentation',
  },
  { value: DETAIL_CATEGORIES.feedbackDemo, label: 'label.feedbackDemo' },
  { value: DETAIL_CATEGORIES.analizaDateDemo, label: 'label.dataAnalysisDemo' },
  { value: DETAIL_CATEGORIES.politicaDePret, label: 'label.pricePolicy' },
  {
    value: DETAIL_CATEGORIES.chestionarDescoperireNevoi,
    label: 'label.needsDiscoveryQuestionnaire',
  },
  { value: DETAIL_CATEGORIES.structuraInterviu, label: 'label.interviewStructure' },
  { value: DETAIL_CATEGORIES.analiza, label: 'label.analysis' },
  { value: DETAIL_CATEGORIES.detaliiDeContact, label: 'label.contactDetails' },
  { value: DETAIL_CATEGORIES.imagine, label: 'label.image' },
  { value: DETAIL_CATEGORIES.inregistrareAudio, label: 'label.audioRecording' },
  { value: DETAIL_CATEGORIES.inregistrareVideo, label: 'label.videoRecording' },
  { value: DETAIL_CATEGORIES.librarieDeDate, label: 'label.dataLibrary' },
  { value: DETAIL_CATEGORIES.mockupSchita, label: 'label.mockupSketch' },
  { value: DETAIL_CATEGORIES.prototipMVPLoFi, label: 'label.prototypeMVPLoFi' },
  { value: DETAIL_CATEGORIES.prototipMVPHiFi, label: 'label.prototypeMVPHifi' },
  { value: DETAIL_CATEGORIES.prezentareIdee, label: 'label.ideaPresentation' },
  { value: DETAIL_CATEGORIES.pitchDeckPentruInvestitori, label: 'label.pitchDeckForInvestors' },
  { value: DETAIL_CATEGORIES.prezentareEchipa, label: 'label.teamPresentation' },
  { value: DETAIL_CATEGORIES.proofOfConcept, label: 'label.proofOfConcept' },
  { value: DETAIL_CATEGORIES.raspunsChestionar, label: 'label.quizAnswer' },
  { value: DETAIL_CATEGORIES.raport, label: 'label.report' },
  { value: DETAIL_CATEGORIES.studiuDePiata, label: 'label.marketStudy' },
  {
    value: DETAIL_CATEGORIES.strategieDePenetrareAPietei,
    label: 'label.marketPenetrationStrategy',
  },
  { value: DETAIL_CATEGORIES.cashFlow, label: 'label.cashFlow' },
  { value: DETAIL_CATEGORIES.argumentareDimensiuneaPietei, label: 'label.marketSizeArgument' },
  { value: DETAIL_CATEGORIES.intelegereaAsociatilor, label: 'label.understandingAssociates' },
  { value: DETAIL_CATEGORIES.analizaSWOT, label: 'label.swotAnalysis' },
  { value: DETAIL_CATEGORIES.analizaPESTLE, label: 'label.pestleAnalysis' },
  { value: DETAIL_CATEGORIES.analiza5Forte, label: 'label.5forteAnalysis' },
  { value: DETAIL_CATEGORIES.analizaTrenduri, label: 'label.trendsAnalysis' },
  { value: DETAIL_CATEGORIES.analizaCompetitiei, label: 'label.competitionAnalysis' },
  { value: DETAIL_CATEGORIES.solutionRoadMap, label: 'label.solutionRoadMap' },
  { value: DETAIL_CATEGORIES.design, label: 'label.design' },
  { value: DETAIL_CATEGORIES.designSolutie, label: 'label.designSolution' },
  {
    value: DETAIL_CATEGORIES.specificatiiTehniceSolutie,
    label: 'label.solutionTechnicalSpecs',
  },
  { value: DETAIL_CATEGORIES.userFlow, label: 'label.userFlow' },
  { value: DETAIL_CATEGORIES.customerJourney, label: 'label.customerJourney' },
  { value: DETAIL_CATEGORIES.listaClientiPotentiali, label: 'label.potentialClientsList' },
  { value: DETAIL_CATEGORIES.altaCategorie, label: 'label.anotherCategory' },
  { value: DETAIL_CATEGORIES.salesScript, label: 'label.salesScript' },
  { value: DETAIL_CATEGORIES.document, label: 'label.document' },
  { value: DETAIL_CATEGORIES.procedure, label: 'label.procedure' },
  { value: DETAIL_CATEGORIES.diagram, label: 'label.diagram' },
  { value: DETAIL_CATEGORIES.listInterviewQuestions, label: 'label.listInterviewQuestions' },
  { value: DETAIL_CATEGORIES.minuteGeneral, label: 'label.minuteGeneral' },
  { value: DETAIL_CATEGORIES.stakeholder, label: 'label.stakeholder' },
  { value: DETAIL_CATEGORIES.elevatorPitch, label: 'label.elevatorPitch' },
  { value: DETAIL_CATEGORIES.detailedSegmentAnalysis, label: 'label.detailedSegmentAnalysis' },
];

export const extendedCategoryOptions = [
  ...categoryOptions,
  { value: 'client', label: 'label.client' },
];

export const problemSeverityOptions = [
  { value: 1, label: <FormattedMessage id="label.dontKnowTheProblem" /> },
  { value: 2, label: <FormattedMessage id="label.knowsTheProblem" /> },
  { value: 3, label: <FormattedMessage id="label.heCreatedOrWorkForASol" /> },
  { value: 5, label: <FormattedMessage id="label.heIsSearchingForASol" /> },
  {
    value: 6,
    label: <FormattedMessage id="label.heIsSpendingMoneyForFixing" />,
  },
];

export const experienceOptions = [
  { value: 'softwareDevelopment', label: 'label.softwareDevelopment' },
  { value: 'webDevelopment', label: 'label.webDevelopment' },
  { value: 'mobileDevelopment', label: 'label.mobileDevelopment' },
  { value: 'devOps', label: 'label.devOps' },
  { value: 'qa', label: 'label.qa' },
  { value: 'it', label: 'label.it' },
  { value: 'dataScience', label: 'label.dataScience' },
  { value: 'cloudComputing', label: 'label.cloudComputing' },
  { value: 'cybersecurity', label: 'label.cybersecurity' },
  { value: 'graphicDesign', label: 'label.graphicDesign' },
  { value: 'uxAndUi', label: 'label.uxAndUi' },
  { value: 'webAndMobileDesign', label: 'label.webAndMobileDesign' },
  { value: 'artIllustration', label: 'label.artIllustration' },
  { value: 'animation', label: 'label.animation' },
  { value: 'videoProduction', label: 'label.videoProduction' },
  { value: 'photography', label: 'label.photography' },
  { value: 'productDesign', label: 'label.productDesign' },
  { value: 'contentWriting', label: 'label.contentWriting' },
  { value: 'copywriting', label: 'label.copywriting' },
  { value: 'technicalWriting', label: 'label.technicalWriting' },
  { value: 'editingAndProofreading', label: 'label.editingAndProofreading' },
  { value: 'translation', label: 'label.translation' },
  { value: 'creativeWriting', label: 'label.creativeWriting' },
  { value: 'grantWriting', label: 'label.grantWriting' },
  { value: 'digitalMarketing', label: 'label.digitalMarketing' },
  { value: 'SEOandSEM', label: 'label.SEOandSEM' },
  { value: 'socialMediaMarketing', label: 'label.socialMediaMarketing' },
  { value: 'emailMarketing', label: 'label.emailMarketing' },
  { value: 'contentMarketing', label: 'label.contentMarketing' },
  { value: 'marketingStrategy', label: 'label.marketingStrategy' },
  { value: 'publicRelations', label: 'label.publicRelations' },
  { value: 'brandManagement', label: 'label.brandManagement' },
  { value: 'virtualAssistance', label: 'label.virtualAssistance' },
  { value: 'customerService', label: 'label.customerService' },
  { value: 'dataEntry', label: 'label.dataEntry' },
  { value: 'projectManagement', label: 'label.projectManagement' },
  { value: 'businessAnalysis', label: 'label.businessAnalysis' },
  { value: 'transcription', label: 'label.transcription' },
  { value: 'marketResearch', label: 'label.marketResearch' },
  { value: 'accountingAndBookkeeping', label: 'label.accountingAndBookkeeping' },
  { value: 'financialAnalysisAndModeling', label: 'label.financialAnalysisAndModeling' },
  { value: 'businessConsulting', label: 'label.businessConsulting' },
  { value: 'HRconsulting', label: 'label.HRconsulting' },
  { value: 'legalConsulting', label: 'label.legalConsulting' },
  { value: 'managementConsulting', label: 'label.managementConsulting' },
  { value: 'investmentBanking', label: 'label.investmentBanking' },
  { value: 'recruitment', label: 'label.recruitment' },
  { value: 'talentManagement', label: 'label.talentManagement' },
  { value: 'employeeRelations', label: 'label.employeeRelations' },
  { value: 'HRstrategy', label: 'label.HRstrategy' },
  { value: 'performanceManagement', label: 'label.performanceManagement' },
  { value: 'compensationAndBenefits', label: 'label.compensationAndBenefits' },
  { value: 'trainingAndDevelopment', label: 'label.trainingAndDevelopment' },
  { value: 'skillsAssessment', label: 'label.skillsAssessment' },
  { value: 'eLearning', label: 'label.eLearning' },
  { value: 'coachingAndMentoring', label: 'label.coachingAndMentoring' },
  { value: 'publicPolicyAnalysis', label: 'label.publicPolicyAnalysis' },
  { value: 'advocacy', label: 'label.advocacy' },
  { value: 'socialProjectManagement', label: 'label.socialProjectManagement' },
  { value: 'communityDevelopment', label: 'label.communityDevelopment' },
  { value: 'internationalRelations', label: 'label.internationalRelations' },
  { value: 'developmentCooperation', label: 'label.developmentCooperation' },
  { value: 'humanitarianAid', label: 'label.humanitarianAid' },
  { value: 'crisisManagement', label: 'label.crisisManagement' },
  { value: 'voiceOver', label: 'label.voiceOver' },
  { value: 'mixingAndMastering', label: 'label.mixingAndMastering' },
  { value: 'musicProduction', label: 'label.musicProduction' },
  { value: 'songwriting', label: 'label.songwriting' },
  { value: 'audioEditing', label: 'label.audioEditing' },
  { value: 'jinglesAndIntros', label: 'label.jinglesAndIntros' },
  { value: 'soundDesign', label: 'label.soundDesign' },
  { value: 'salesManagement', label: 'label.salesManagement' },
  { value: 'businessDevelopment', label: 'label.businessDevelopment' },
  { value: 'B2BandB2Csales', label: 'label.B2BandB2Csales' },
  { value: 'leadGeneration', label: 'label.leadGeneration' },
  { value: 'salesStrategy', label: 'label.salesStrategy' },
  { value: 'clientRelations', label: 'label.clientRelations' },
  { value: 'negotiation', label: 'label.negotiation' },
  { value: 'accountManagementJob', label: 'label.accountManagementJob' },
  { value: 'productManagement', label: 'label.productManagement' },
  { value: 'customerSuccessManagement', label: 'label.customerSuccessManagement' },
  { value: 'supplyChainManagement', label: 'label.supplyChainManagement' },
  { value: 'operationsManagement', label: 'label.operationsManagement' },
  { value: 'legalCompliance', label: 'label.legalCompliance' },
  { value: 'procurement', label: 'label.procurement' },
  { value: 'qualityAssurance', label: 'label.qualityAssurance' },
  { value: 'riskManagement', label: 'label.riskManagement' },
  { value: 'eventPlanning', label: 'label.eventPlanning' },
];

export const CLIENT_STAGE_OPTIONS = [
  { value: CLIENT_STAGE.notInterested, label: 'label.notInterested' },
  { value: CLIENT_STAGE.prospect, label: 'label.prospect' },
  { value: CLIENT_STAGE.qualified, label: 'label.qualified' },
  { value: CLIENT_STAGE.activated, label: 'label.activated' },
  { value: CLIENT_STAGE.payingClient, label: 'label.payingClient' },
  { value: CLIENT_STAGE.loyalClient, label: 'label.loyalClient' },
  { value: CLIENT_STAGE.clientAccountClosed, label: 'label.clientClosedAccount' },
];

export const CUSTOMER_SEGMENTS_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.earlyAdopters, label: 'label.earlyAdopters' },
  { value: TARGET_INDICATORS.customerLifetimeValueLTV, label: 'label.customerLifetimeValueLTV' },
  { value: TARGET_INDICATORS.customerLifetimeCLV, label: 'label.customerLifetimeCLV' },
  { value: TARGET_INDICATORS.userStories, label: 'label.userStories' },
  { value: TARGET_INDICATORS.customerInterviews, label: 'label.customerInterviews' },
  { value: TARGET_INDICATORS.marketShare, label: 'label.marketShare' },
];

export const VALUE_PROPOSITION_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.netPromoterScore, label: 'label.netPromoterScore' },
  { value: TARGET_INDICATORS.dailyUsageRatio, label: 'label.dailyUsageRatio' },
  { value: TARGET_INDICATORS.customerSatisfaction, label: 'label.customerSatisfaction' },
  { value: TARGET_INDICATORS.reviews4Stars, label: 'label.reviews4Stars' },
  { value: TARGET_INDICATORS.innovationRate, label: 'label.innovationRate' },
];

export const CHANNELS_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.channelConversionRate, label: 'label.channelConversionRate' },
  { value: TARGET_INDICATORS.channelReturnOnInvestment, label: 'label.channelReturnOnInvestment' },
];

export const CUSTOMER_RELATIONSHIPS_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.numberOfQualifiedLeads, label: 'label.numberOfQualifiedLeads' },
  { value: TARGET_INDICATORS.numberOfLeads, label: 'label.numberOfLeads' },
  { value: TARGET_INDICATORS.numberOfMarketingLeads, label: 'label.numberOfMarketingLeads' },
  { value: TARGET_INDICATORS.numberOfActivations, label: 'label.numberOfActivations' },
  { value: TARGET_INDICATORS.customerAquizitionCost, label: 'label.customerAquizitionCost' },
  { value: TARGET_INDICATORS.activationRate, label: 'label.activationRate' },
  { value: TARGET_INDICATORS.marketingLeadToQualifiedLeadRatio, label: 'label.marketingLeadToQualifiedLeadRatio' },
  { value: TARGET_INDICATORS.qualifiedLeadToCustomerRatio, label: 'label.qualifiedLeadToCustomerRatio' },
  { value: TARGET_INDICATORS.kFactor, label: 'label.kFactor' },
  { value: TARGET_INDICATORS.customerReferralRate, label: 'label.customerReferralRate' },
  { value: TARGET_INDICATORS.returnOnAdvertisingSpending, label: 'label.returnOnAdvertisingSpending' },
  { value: TARGET_INDICATORS.averageResponseTime, label: 'label.averageResponseTime' },
  { value: TARGET_INDICATORS.customerEngagementRate, label: 'label.customerEngagementRate' },
];

export const REVENUE_STREAMS_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.averageTransactionValue, label: 'label.averageTransactionSize' },
  { value: TARGET_INDICATORS.churnRate, label: 'label.churnRate' },
  { value: TARGET_INDICATORS.retentionRate, label: 'label.retentionRate' },
  { value: TARGET_INDICATORS.monthlyRecurringRevenue, label: 'label.monthlyRecurringRevenue' },
  { value: TARGET_INDICATORS.annualRecurringRevenue, label: 'label.annualRecurringRevenue' },
  { value: TARGET_INDICATORS.grossMargin, label: 'label.grossMargin' },
  { value: TARGET_INDICATORS.dailyActiveUsers, label: 'label.dailyActiveUsers' },
  { value: TARGET_INDICATORS.monthlyActiveUsers, label: 'label.monthlyActiveUsers' },
  { value: TARGET_INDICATORS.revenueGrowthRate, label: 'label.revenueGrowthRate' },
  { value: TARGET_INDICATORS.averageOrderSize, label: 'label.averageOrderSize' },
  { value: TARGET_INDICATORS.marketProductFit, label: 'label.marketProductFit' },
];

export const KEY_ACTIVITIES_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.investmentInRD, label: 'label.investmentInRD' },
  { value: TARGET_INDICATORS.onTimeDeliveryRate, label: 'label.onTimeDeliveryRate' },
  { value: TARGET_INDICATORS.cycleTime, label: 'label.cycleTime' },
  { value: TARGET_INDICATORS.timeToMarket, label: 'label.timeToMarket' },
  { value: TARGET_INDICATORS.processInnovationRate, label: 'label.processInnovationRate' },
  { value: TARGET_INDICATORS.newTechnologyAdoptionRate, label: 'label.newTechnologyAdoptionRate' },
];

export const KEY_RESOURCES_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.initialInvestment, label: 'label.initialInvestment' },
  { value: TARGET_INDICATORS.revenuePerEmployee, label: 'label.revenuePerEmployee' },
  { value: TARGET_INDICATORS.employeeProductivity, label: 'label.employeeProductivity' },
  { value: TARGET_INDICATORS.employeeTurnover, label: 'label.employeeTurnover' },
  { value: TARGET_INDICATORS.returnOnInvestment, label: 'label.returnOnInvestment' },
  { value: TARGET_INDICATORS.intellectualPropertyAssets, label: 'label.intellectualPropertyAssets' },
];

export const KEY_PARTNERS_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.partnerSatisfaction, label: 'label.partnerSatisfaction' },
  { value: TARGET_INDICATORS.partnerPerformance, label: 'label.partnerPerformance' },
  { value: TARGET_INDICATORS.dependabilityRate, label: 'label.dependabilityRate' },
  { value: TARGET_INDICATORS.jointRevenueGeneration, label: 'label.jointRevenueGeneration' },
];

export const COST_STRUCTURE_INDICATORS_OPTIONS = [
  { value: TARGET_INDICATORS.cashRunway, label: 'label.cashRunway' },
  { value: TARGET_INDICATORS.burnRate, label: 'label.burnRate' },
  { value: TARGET_INDICATORS.operatingCashFlow, label: 'label.operatingCashFlow' },
  { value: TARGET_INDICATORS.breakEven, label: 'label.breakEven' },
  { value: TARGET_INDICATORS.netProfitMargin, label: 'label.netProfitMargin' },
  { value: TARGET_INDICATORS.operatingExpenses, label: 'label.operatingExpensesOPEX' },
  { value: TARGET_INDICATORS.paybackPeriod, label: 'label.paybackPeriod' },
  { value: TARGET_INDICATORS.costPerUnit, label: 'label.costPerUnit' },
];

export const TARGET_INDICATORS_OPTIONS = {
  [BMC_TYPE.customerSegments]: CUSTOMER_SEGMENTS_INDICATORS_OPTIONS,
  [BMC_TYPE.customerRelationships]: CUSTOMER_RELATIONSHIPS_INDICATORS_OPTIONS,
  [BMC_TYPE.valuePropositions]: VALUE_PROPOSITION_INDICATORS_OPTIONS,
  [BMC_TYPE.revenueStreams]: REVENUE_STREAMS_INDICATORS_OPTIONS,
  [BMC_TYPE.channels]: CHANNELS_INDICATORS_OPTIONS,
  [BMC_TYPE.costStructure]: COST_STRUCTURE_INDICATORS_OPTIONS,
  [BMC_TYPE.keyResources]: KEY_RESOURCES_INDICATORS_OPTIONS,
  [BMC_TYPE.keyActivities]: KEY_ACTIVITIES_INDICATORS_OPTIONS,
  [BMC_TYPE.keyPartners]: KEY_PARTNERS_INDICATORS_OPTIONS,
};

export const TASK_STATUS_OPTIONS = [
  { value: 'inPreparation', label: <FormattedMessage id="label.preparing" /> },
  { value: 'inProgress', label: <FormattedMessage id="label.implementing" /> },
  { value: 'done', label: <FormattedMessage id="label.finalized" /> },
];

export const INDUSTRY_IMPACT_OPTIONS = [
  { value: INDUSTRY_IMPACT.veryPositive, label: <FormattedMessage id="label.veryPositive++" /> },
  { value: INDUSTRY_IMPACT.positive, label: <FormattedMessage id="label.positive+" /> },
  { value: INDUSTRY_IMPACT.negative, label: <FormattedMessage id="label.negative-" /> },
  { value: INDUSTRY_IMPACT.veryNegative, label: <FormattedMessage id="label.veryNegative--" /> },
];

export const SWOT_DROPDOWN_OPTIONS = [
  { value: 'strengths', label: 'label.strengths' },
  { value: 'weaknesses', label: 'label.weaknesses' },
  { value: 'opportunities', label: 'label.opportunities' },
  { value: 'threats', label: 'label.threats' },
];

export const DETAIL_STATUS_OPTIONS = [
  { value: 'inPreparation', label: <FormattedMessage id="label.preparing" /> },
  { value: 'inProgress', label: <FormattedMessage id="label.implementing" /> },
  { value: 'done', label: <FormattedMessage id="label.finalized" /> },
];

export const EXPERIENCE_OPTIONS = [
  { label: <FormattedMessage id="label.xYears" values={{ value: '0-5' }} />, value: '[0, 5]' },
  { label: <FormattedMessage id="label.xYears" values={{ value: '5-10' }} />, value: '[5, 10]' },
  { label: <FormattedMessage id="label.xYears" values={{ value: '10-15' }} />, value: '[10, 15]' },
  { label: <FormattedMessage id="label.xYears" values={{ value: '15+' }} />, value: '[15]' },
];

export const BMC_OPTIONS = [
  { value: BMC_TYPE.customerSegments, label: 'label.customerSegments' },
  { value: BMC_TYPE.customerRelationships, label: 'label.customerRelationships' },
  { value: BMC_TYPE.valuePropositions, label: 'label.valuePropositions' },
  { value: BMC_TYPE.revenueStreams, label: 'label.revenueStreams' },
  { value: BMC_TYPE.channels, label: 'label.channels' },
  { value: BMC_TYPE.costStructure, label: 'label.costStructure' },
  { value: BMC_TYPE.keyResources, label: 'label.keyResources' },
  { value: BMC_TYPE.keyActivities, label: 'label.keyActivities' },
  { value: BMC_TYPE.keyPartners, label: 'label.keyPartners' },
];

export const BUSINESS_OBJECTIVES_OPTIONS = [
  { value: BUSINESS_OBJECTIVES.tenThousand, label: 'label.businessObjective.tenThousand' },
  { value: BUSINESS_OBJECTIVES.oneHundredThousand, label: 'label.businessObjective.oneHundredThousand' },
  { value: BUSINESS_OBJECTIVES.oneMillion, label: 'label.businessObjective.oneMillion' },
  { value: BUSINESS_OBJECTIVES.tenMillion, label: 'label.businessObjective.tenMillion' },
  { value: BUSINESS_OBJECTIVES.oneHundredMillion, label: 'label.businessObjective.oneHundredMillion' },
];

export const SUBSCRIPTION_TYPE_OPTIONS = [
  { value: SUBSCRIPTION_TYPE.oneTimePayment, label: 'label.oneTimePayment' },
  { value: SUBSCRIPTION_TYPE.subscription, label: 'label.subscription' },
];
