import { size } from 'lodash-es';
import React from 'react';

import {
  ProfileImageUploaderLabel,
  ProfileImageUploaderInput,
} from '../styled';

import AvatarImage from './AvatarImage';

const ProfileImageUploader = ({
  imageUrl,
  disabled,
  upload,
  width,
  height,
  onFilesSelected
}) => {

  const handleFilesSelected = (event) => size(event.target.files) !== 0 && onFilesSelected(event.target.files);

  return (
    <ProfileImageUploaderLabel>
      <ProfileImageUploaderInput
        accept="image/jpg,image/png,image/jpeg"
        onChange={handleFilesSelected}
        type="file"
        disabled={disabled}
      />
      <AvatarImage
        width={width}
        height={height}
        upload={upload}
        src={imageUrl}
        medium
        marginRight={0}
      />
    </ProfileImageUploaderLabel>
  );
};

export default ProfileImageUploader;
