import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { DocumentTitle } from '../../../../common/components';
import PlatformStats from '../../../../common/components/PlatformStats';
import VerifyEmail from '../../../../common/components/VerifyEmail';
import AuthLayout from '../../../../layouts/AuthLayout';
import { FormWrapper } from '../../../../layouts/AuthLayout/auth.styled';
import LoginForm from '../../components/LoginForm';

import { useLogin } from '../../../../hooks/useAuth';

const Login = () => {
  const intl = useIntl();
  const { onLogin, email, showResend, setShowResend } = useLogin();

  return (
    <AuthLayout>
      {showResend
        ? <VerifyEmail email={email} goToLogin={() => setShowResend(false)} />
        : (
          <>
            <DocumentTitle>{intl.formatMessage({ id: 'label.login' })}</DocumentTitle>
            <FormWrapper>
              <Box my={40}>
                <PlatformStats />
              </Box>
              <Typography variant="h2" textAlign="center" color="primary" mb={24}>
                {intl.formatMessage({ id: 'label.signIn' })}
              </Typography>
              <LoginForm
                onSubmit={onLogin}
              />
            </FormWrapper>
          </>
        )}
    </AuthLayout>
  );
};

export default Login;
