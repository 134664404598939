import update from 'immutability-helper';

import { notifyError, notifySuccess } from '../../../core/store/notifications';
import {
  loadMrl as doLoadMrl,
  editMrl as doEditMrl,
  getMrlById as doGetMrlById,
  editMrlOrder as doEditMrlOrder,
} from '../services/mrl';

// Actions

const START_LOAD_MRL = 'mrl/START_LOAD_MRL';
const COMPLETE_LOAD_MRL = 'mrl/COMPLETE_LOAD_MRL';
const FAIL_LOAD_MRL = 'mrl/FAIL_LOAD_MRL';

const START_EDIT_MRL = 'mrl/START_EDIT_MRL';
const COMPLETE_EDIT_MRL = 'mrl/COMPLETE_EDIT_MRL';
const FAIL_EDIT_MRL = 'mrl/FAIL_EDIT_MRL';

const START_FIND_MRL = 'mrl/START_FIND_MRL';
const COMPLETE_FIND_MRL = 'mrl/COMPLETE_FIND_MRL';
const FAIL_FIND_MRL = 'mrl/FAIL_FIND_MRL';

const START_EDIT_MRL_ORDER = 'mrl/START_EDIT_MRL';
const COMPLETE_EDIT_MRL_ORDER = 'mrl/COMPLETE_EDIT_MRL';
const FAIL_EDIT_MRL_ORDER = 'mrl/FAIL_EDIT_MRL';

const SET_IS_OPEN = 'mrl/SET_IS_OPEN';

const RESET = 'mrl/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isOpen: false,
  isDeleting: false,
  isCreating: false,
  isEditing: false,
  mrls: undefined,
  currentMrl: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_MRL:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_MRL:
      return update(state, {
        $merge: {
          isLoading: false,
          mrls: action.mrls,
        },
      });

    case FAIL_LOAD_MRL:
      return update(state, {
        $merge: {
          isLoading: false,
          mrls: undefined,
        },
      });

    case SET_IS_OPEN:
      return update(state, { $merge: { isOpen: action.isOpen } });

    case START_FIND_MRL:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_FIND_MRL:
      return update(state, {
        $merge: {
          isLoading: false,
          currentMrl: action.currentMrl,
        },
      });

    case FAIL_FIND_MRL:
      return update(state, {
        $merge: {
          isLoading: false,
          currentMrl: undefined,
        },
      });

    case START_EDIT_MRL:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_MRL:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_MRL:
      return update(state, { $merge: { isEditing: false } });

    case START_EDIT_MRL_ORDER:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_MRL_ORDER:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_MRL_ORDER:
      return update(state, { $merge: { isEditing: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoadMrl = () => ({
  type: START_LOAD_MRL,
});

const completeLoadMrl = (mrls) => ({
  type: COMPLETE_LOAD_MRL,
  mrls,
});

const failLoadMrl = () => ({
  type: FAIL_LOAD_MRL,
});

const startEditMrl = () => ({
  type: START_EDIT_MRL,
});

const completeEditMrl = () => ({
  type: COMPLETE_EDIT_MRL,
});

const failEditMrl = () => ({
  type: FAIL_EDIT_MRL,
});

const startFindMrl = () => ({
  type: START_FIND_MRL,
});

const completeFindMrl = (currentMrl) => ({
  type: COMPLETE_FIND_MRL,
  currentMrl,
});

const failFindMrl = () => ({
  type: FAIL_FIND_MRL,
});

const startEditMrlOrder = () => ({
  type: START_FIND_MRL,
});

const completeEditMrlOrder = (currentMrl) => ({
  type: COMPLETE_FIND_MRL,
  currentMrl,
});

const failEditMrlOrder = () => ({
  type: FAIL_FIND_MRL,
});

export const setIsOpen = (isOpen) => ({
  type: SET_IS_OPEN,
  isOpen,
});

export const loadMrl = () => (dispatch) => {
  dispatch(startLoadMrl());
  const loadMrlPromise = doLoadMrl();

  loadMrlPromise
    .then((res) => {
      dispatch(completeLoadMrl(res.data));
    })
    .catch((error) => {
      dispatch(failLoadMrl());
      dispatch(notifyError(error.response.data));
    });
  return loadMrlPromise;
};

export const getMrlById = (id) => (dispatch) => {
  dispatch(startFindMrl());
  const findMrlPromise = doGetMrlById(id);

  findMrlPromise
    .then((res) => {
      dispatch(completeFindMrl(res.data));
    })
    .catch((error) => {
      dispatch(failFindMrl());
      dispatch(notifyError(error.response.data));
    });
  return findMrlPromise;
};

export const editMrl = (values, id) => (dispatch) => {
  dispatch(startEditMrl());
  const editMrlPromise = doEditMrl(values, id);

  editMrlPromise
    .then((res) => {
      dispatch(completeEditMrl(res.data));
      dispatch(notifySuccess('Modificările s-au realizat cu success!'));
    })
    .catch((error) => {
      dispatch(failEditMrl());
      dispatch(notifyError(error.response.data));
    });
  return editMrlPromise;
};

export const editMrlOrder = (values) => async (dispatch) => {
  try {
    dispatch(startEditMrlOrder());
    const res = await doEditMrlOrder(values);
    dispatch(completeEditMrlOrder(res.data));
    dispatch(notifySuccess('Modificările s-au realizat cu success!'));
  } catch (error) {
    dispatch(failEditMrlOrder());
    dispatch(notifyError(error.response.data));
  }
};
