import { FormattedMessage } from 'react-intl';

export const TERMINALS_TABLE_HEADER = [
  { type: 'string', content: <FormattedMessage id="label.month" />, key: 'month' },
  { type: 'string', content: <FormattedMessage id="label.possibleClients" />, key: 'possibleClients' },
  { type: 'string', content: <FormattedMessage id="label.qualifiedClients" />, key: 'qualifiedClients' },
  { type: 'string', content: <FormattedMessage id="label.newPayingClients" />, key: 'newPayingClients' },
  { type: 'string', content: <FormattedMessage id="label.mrrEst" />, key: 'mrrEst' },
  { type: 'string', content: <FormattedMessage id="label.cumulativeRevenue" />, key: 'cumulativeRevenue' },
  { type: 'string', content: <FormattedMessage id="label.arrEst" />, key: 'arrEst' },
];
