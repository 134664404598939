import { http } from '../core/services/http';

import {
  GET_INVITED_MEMBER_DATA,
  POST_SIGN_UP_INVITED_MEMBER,
} from '../constants/apiRoutes';
import { formatRoute } from '../utils/formatters';

export const getInvitedMemberData = (params) => http.get(formatRoute(GET_INVITED_MEMBER_DATA, params));

export const postRegisterInvitedMember = (payload) => (
  http.post(POST_SIGN_UP_INVITED_MEMBER, payload)
);
