import { DETAIL_CATEGORIES } from '../constants/detail';

export const formatDetailForServer = (detail) => {
  if (detail?.category === DETAIL_CATEGORIES.userStory) {
    return {
      category: detail?.category,
      characteristicsFromCustomerSegments: detail?.characteristicsFromCustomerSegments,
      tasksFromCustomerSegments: detail?.tasksFromCustomerSegments,
      taskContext: detail?.taskContext || '',
      winsFromCustomerSegments: detail?.winsFromCustomerSegments,
      painsFromCustomerSegments: detail?.painsFromCustomerSegments,
      currentSolution: detail?.currentSolution || '',
      otherInfo: detail?.otherInfo || '',
      links: detail?.links,
    };
  }

  if (detail?.category === DETAIL_CATEGORIES.minuteClient) {
    return {
      category: detail?.category,
      title: detail?.title,
      description: detail?.description,
      clientStage: detail?.clientStage,
      contactDisponibility: detail?.contactDisponibility,
      nextSteps: detail?.nextSteps,
      cost: +detail?.cost,
      links: detail?.links,
    };
  }

  if (detail?.category === DETAIL_CATEGORIES.client) {
    return {
      category: detail?.category,
      title: detail?.title,
      cost: +detail?.cost,
      customerSegmentHypothesisId: detail?.customerSegmentHypothesisId,
      phone: detail?.phone,
      emails: detail?.emails,
      residence: detail?.residence,
      company: detail?.company,
      role: detail?.role,
      clientStage: detail?.clientStage,
      revenueStreamsHypothesisId: detail?.revenueStreamsHypothesisId,
      customerRelationshipsHypothesisId: detail?.customerRelationshipsHypothesisId,
      otherInfo: detail?.otherInfo,
      clientSourceInfo: detail?.clientSourceInfo,
      tag: detail?.tag,
      socialMediaLinks: detail?.socialMediaLinks,
      links: detail?.links,
    };
  }

  return {
    category: detail?.category,
    title: detail?.title,
    description: detail?.description,
    cost: +detail?.cost,
    status: detail?.status,
    links: detail?.links,
  };
};
