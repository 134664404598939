import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import TrendsForm from '../TrendsForm';

const AddTrendsModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    teamId,
    selectedTrend,
    setSelectedTrend,
    onRefreshData,
  } = props;
  const intl = useIntl();

  const handleCloseModal = () => {
    setSelectedTrend(null);
    setIsModalOpen(false);
  };

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title={intl.formatMessage({ id: 'modal.title.addTrend' })}
    >
      <TrendsForm
        teamId={teamId}
        selectedTrend={selectedTrend}
        setIsModalOpen={setIsModalOpen}
        setSelectedTrend={setSelectedTrend}
        onRefreshData={onRefreshData}
      />
    </DialogComponent>
  );
};

export default AddTrendsModal;
