import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import DialogComponent from '../../components/DialogComponent';
import HypothesisForm from '../../forms/HypothesisForm';

import { NOT_APPLICABLE } from '../../../constants/general';
import { BMC_LABELS } from '../../../constants/labels';

const AddHypothesisModal = ({
  handleClose,
  isModalOpen,
  handleSubmitHypothesis,
  hypothesisFormValues,
  segmentOptions,
  isEdit,
}) => {
  const intl = useIntl();
  const columnBMCLabel = BMC_LABELS?.[hypothesisFormValues?.columnBMC] || '';
  const title = isEdit ? 'modal.title.editHypothesis' : 'modal.title.addANewHypothesis';

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={handleClose}
      title={intl.formatMessage({ id: title })}
      maxWidth="xs"
      bodySx={{ pt: 0 }}
      mb="10px"
    >
      <Typography variant="h3" color="general.accentLight" mb={30} fontWeight={600}>
        {columnBMCLabel ? intl.formatMessage({ id: columnBMCLabel }) : NOT_APPLICABLE}
      </Typography>

      <HypothesisForm
        handleSubmit={handleSubmitHypothesis}
        segmentOptions={segmentOptions}
        formValues={hypothesisFormValues}
      />
    </DialogComponent>
  );
};

export default AddHypothesisModal;
