import { Box, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { SaveIconOutlined, ThumbsUpIcon } from '../../../../assets/icons';

import InputField from '../../../../fields/InputField';
import SquareButtonsField from '../../../../fields/SquareButtonsField';

const ConclusionForm = ({ teamConclusions, handleSubmitConclusion }) => {
  const intl = useIntl();
  const theme = useTheme();

  const schema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    description: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    isTrendFavorable: Yup.boolean().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  const initialValues = {
    title: teamConclusions?.title || '',
    description: teamConclusions?.description || '',
    isTrendFavorable: teamConclusions?.isTrendFavorable || false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitConclusion}
      validationSchema={schema}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField id="title" label={intl.formatMessage({ id: 'label.title' })} />

          <InputField
            id="description"
            label={intl.formatMessage({ id: 'label.description' })}
            multiline
            rows={4}
          />

          <SquareButtonsField
            name="isTrendFavorable"
            label={intl.formatMessage({ id: 'label.willTrendBeFavorable' })}
            value={values?.isTrendFavorable}
            onChange={setFieldValue}
            option1={<ThumbsUpIcon size="20px" fill={theme.palette.success.main} />}
            option2={(
              <Box sx={{ transform: 'scaleY(-1)' }}>
                <ThumbsUpIcon size="20px" fill={theme.palette.error.secondary} />
              </Box>
            )}
            sx={{ mb: 15 }}
          />

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default ConclusionForm;
