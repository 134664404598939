import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { NOT_APPLICABLE } from '../../../constants/general';
import { TARGET_INDICATORS_LABELS } from '../../../constants/labels';

import { Indicator } from './index.styled';

const TargetIndicator = ({
  targetIndicator = null,
  placeholder = '',
  hasMissingInfo = false,
}) => {
  const intl = useIntl();
  const targetIndicatorLabel = TARGET_INDICATORS_LABELS?.[targetIndicator?.targetIndicatorType];
  const label = targetIndicatorLabel
    ? intl.formatMessage({ id: targetIndicatorLabel })
    : NOT_APPLICABLE;
  const details = targetIndicator?.unitOfMeasurement
    ? [label, targetIndicator?.value, targetIndicator?.unitOfMeasurement]
    : [label, targetIndicator?.value];

  return (
    <Indicator hasDashedBorder={!targetIndicator} hasMissingInfo={hasMissingInfo}>
      {targetIndicator
        ? <Typography variant="body1" color="primary">{details?.join(' / ')}</Typography>
        : <Typography variant="body1" color="general.placeholder" className="italic">{placeholder}</Typography>
      }
    </Indicator>
  );
};

export default TargetIndicator;
