import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RouterPropTypes from 'react-router-prop-types';

import { DocumentTitle } from '../../common/components';
import { Loading } from '../../common/styled';
import { Resolver } from '../../core/components';

import { loadHypothesis } from '../../store/hypothesis/hypothesisSlice';

import HypothesisPage from '../../modules/hypothesis/containers/HypothesisPage';

const HypothesisDetailsPageResolver = (props) => {
  const {
    loadHypothesis,
    match: {
      params: { hypothesisId },
    },
  } = props;
  const intl = useIntl();

  const loadDependencies = async () => {
    if (hypothesisId) {
      await loadHypothesis(hypothesisId);
    }
  };

  return (
    <>
      <DocumentTitle>{intl.formatMessage({ id: 'label.hypothesis' })}</DocumentTitle>
      <Resolver
        successComponent={HypothesisPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

HypothesisDetailsPageResolver.propTypes = {
  loadHypothesis: PropTypes.func.isRequired,
  match: RouterPropTypes.match,
};

const mapDispatchToProps = { loadHypothesis };

export default withRouter(
  connect(undefined, mapDispatchToProps)(HypothesisDetailsPageResolver)
);
