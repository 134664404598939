import { IconButton, Popover, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { editProfile } from '../../../../store/userProfile/userProfileSlice';

import { LOCALES_LABELS } from '../../../../constants/labels';
import { LOCALES } from '../../../../languages/langTexts';
import { handleApiError } from '../../../../utils/errorUtils';

import { CountryFlag, LanguageOption } from './index.styled';

const LanguageSelector = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const userProfile = useSelector(state => state?.userProfile?.userProfile);
  const popperOpen = Boolean(anchorEl);

  const handleOpenPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSelectLanguage = async (language) => {
    try {
      const payload = {
        ...userProfile,
        language,
      };
      await dispatch(editProfile(payload, '', 'success.appLanguageSuccessfully'));
      handleClose();
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <>
      <Tooltip
        id="btn-languages"
        title={intl.formatMessage({ id: 'label.languages' })}
        placement="bottom"
      >
        <IconButton onClick={handleOpenPopper}>
          <CountryFlag countryCode={userProfile?.language?.split('-')[1] || 'RO'} svg />
        </IconButton>
      </Tooltip>
      <Popover
        open={popperOpen}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
      >
        {Object.values(LOCALES).map((language) => (
          <LanguageOption
            key={language}
            onClick={() => handleSelectLanguage(language)}
            language={language === userProfile?.language}
          >
            <CountryFlag countryCode={language?.split('-')[1]} svg />
            <Typography variant="body1">
              {intl.formatMessage({ id: LOCALES_LABELS[language] })}
            </Typography>
          </LanguageOption>
        ))}
      </Popover>
    </>
  );
};

export default LanguageSelector;
