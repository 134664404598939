import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { CenterContent, LinkText, PaperContent } from '../../../../layouts/AuthLayout/auth.styled';

import { requestPasswordReset } from '../../../../requests/auth';

import { handleApiError } from '../../../../utils/errorUtils';

import InputField from '../../../../fields/InputField';

const RequestPasswordReset = () => {
  const intl = useIntl();
  const { push } = useHistory();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onSubmit = async ({ email }, { resetForm }) => {
    try {
      await requestPasswordReset(email);
      setShowConfirmation(true);
    } catch (e) {
      handleApiError(e);
    } finally {
      resetForm();
    }
  };

  const schema = Yup.object({
    email: Yup
      .string()
      .email(intl.formatMessage({ id: 'validate.invalidEmail' }))
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  return (
    <CenterContent>
      <PaperContent elevation={0}>
        {showConfirmation ? (
          <div>
            <Typography variant="h2" textAlign="center" color="primary" mb={20}>
              {intl.formatMessage({ id: 'label.emailSendForResetPassword' })}
            </Typography>
            <Typography textAlign="center" color="primary" mb={16}>
              {intl.formatMessage({ id: 'label.accessLinkFromEmail' })}
            </Typography>
            <ButtonComponent
              size="large"
              fullWidth
              label={intl.formatMessage({ id: 'label.backToLogin' })}
              onClick={() => push('/account/login')}
            />
          </div>
        ) : (
          <Formik
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={{ email: '' }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <Typography variant="h2" textAlign="center" color="primary">
                      {intl.formatMessage({ id: 'pageTitle.resetPassword' })}
                    </Typography>
                  </Grid>
                  <InputField
                    id="email"
                    label={intl.formatMessage({ id: 'placeholder.email' })}
                  />
                  <Grid item xs={12}>
                    <ButtonComponent
                      size="large"
                      disabled={isSubmitting}
                      type="submit"
                      fullWidth
                      label={intl.formatMessage({ id: 'button.resetPassword' })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LinkText to="/account/login">
                      <Typography textAlign="center" fontWeight={600}>
                        {`< ${intl.formatMessage({ id: 'label.backToLogin' })}`}
                      </Typography>
                    </LinkText>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
      </PaperContent>
    </CenterContent>
  );
};

export default RequestPasswordReset;
