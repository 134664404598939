import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../components/DialogComponent';
import ActivitiesForm from '../../forms/ActivityForm';

const AddActivityModal = ({
  onClose,
  isModalOpen,
  onSubmit,
  activity,
  teamMembers,
  isEditMode = false,
}) => {
  const intl = useIntl();

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={onClose}
      maxWidth="xs"
      title={intl.formatMessage({ id: isEditMode ? 'modal.title.editActivity' : 'modal.title.addActivity' })}
    >
      <ActivitiesForm
        activity={activity}
        onSubmit={onSubmit}
        teamMembers={teamMembers}
      />
    </DialogComponent>
  );
};

export default AddActivityModal;
