import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { NavigationBarIconContainer } from '../index.styled';

const NavigationOption = ({
  IconComponent,
  title,
  onClick,
  isDisabled = false,
}) => {
  const intl = useIntl();

  return (
    <NavigationBarIconContainer
      onClick={onClick}
      className="icon-container"
      sx={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      {IconComponent}
      <Typography variant="caption" fontWeight={600} textAlign="center">
        {intl.formatMessage({ id: title })}
      </Typography>
    </NavigationBarIconContainer>
  );
};

export default NavigationOption;
