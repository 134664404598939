import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import ConclusionForm from '../ConclusionForm';

const AddConclusionModal = ({
  handleClose,
  isModalOpen,
  handleSubmit,
  handleUpdate,
  conclusion,
  isEditMode = false,
}) => {
  const intl = useIntl();
  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={handleClose}
      title={intl.formatMessage({ id: isEditMode ? 'modal.title.editConclusion' : 'modal.title.addAnConclusion' })}
      maxWidth="md"
    >
      <ConclusionForm
        handleUpdate={handleUpdate}
        handleSubmit={handleSubmit}
        conclusion={conclusion}
      />
    </DialogComponent>
  );
};

export default AddConclusionModal;
