import { styled } from '@mui/material/styles';

export const TitleWrapper = styled('div')(({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(8)};
  svg > path {
    fill: ${theme.palette.primary.main}
  }
`);
