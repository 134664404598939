import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import PageTitle from '../../../common/components/PageTitle';
import { ExcelButton } from '../../../common/styled';
import { SpaceBetweenBox } from '../../../common/styled/ReusablePositioningComponents';

import {
  NotificationsFilterContainer,
  NotificationsFilterOption,
} from '../index.styled';

const CommentsAndNotificationsHeader = ({
  commentsLength,
  setReadFilterValue,
  readFilterValue,
  handleCsvDownload,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <SpaceBetweenBox>
        <PageTitle
          title={intl.formatMessage({ id: 'label.notificationsAndComments' })}
          counter={commentsLength}
        />
        <Box onClick={handleCsvDownload}>
          <ExcelButton />
        </Box>
      </SpaceBetweenBox>
      <NotificationsFilterContainer>
        <NotificationsFilterOption
          onClick={() => setReadFilterValue('all')}
          active={readFilterValue === 'all'}
        >
          {intl.formatMessage({ id: 'label.all' })}
        </NotificationsFilterOption>
        <NotificationsFilterOption
          onClick={() => setReadFilterValue(false)}
          active={readFilterValue === false}
        >
          {intl.formatMessage({ id: 'label.new' })}
        </NotificationsFilterOption>
        <NotificationsFilterOption
          onClick={() => setReadFilterValue(true)}
          active={readFilterValue === true}
        >
          {intl.formatMessage({ id: 'label.archive' })}
        </NotificationsFilterOption>
      </NotificationsFilterContainer>
    </Box>
  );
};

export default CommentsAndNotificationsHeader;
