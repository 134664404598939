import { Formik } from 'formik';
import React from 'react';
import ReactGA from 'react-ga4';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { notifySuccess } from '../../../../core/store';
import { addTeamMember, addTeamMentor } from '../../../../requests/teams';

import { ideaRoleOptions } from '../../../../constants/options';
import { USER_ROLES } from '../../../../constants/roles';
import { handleApiError } from '../../../../utils/errorUtils';

import { SaveIconOutlined } from '../../../../assets/icons';

import { DropdownField } from '../../../../fields/DropdownField';
import InputField from '../../../../fields/InputField';

const InviteUser = ({
  isMentor,
  teamId,
  runUpdateTeamMentors,
  runUpdateTeamMembers,
  handleClose,
}) => {
  const intl = useIntl();
  const userProfile = useSelector((state) => state.userProfile?.userProfile);
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    lastName: '',
    firstName: '',
    ...(!isMentor && {
      role: [],
    }),
  };

  const handleSubmit = async (values) => {
    try {
      if (isMentor) {
        await addTeamMentor(teamId, {
          email: values.email,
          lastName: values.lastName,
          firstName: values.firstName,
        });

        if (runUpdateTeamMentors) {
          runUpdateTeamMentors();
        }
      } else {
        await addTeamMember(teamId, {
          email: values.email,
          lastName: values.lastName,
          firstName: values.firstName,
          equity: 0,
          role: values.role,
        });
        runUpdateTeamMembers();
      }

      handleClose();
      dispatch(
        notifySuccess(
          intl.formatMessage({ id: 'success.invitationSendSuccessfully' })
        )
      );

      ReactGA.gtag('event', 'invite_user', {
        userId: userProfile?.userId?._id,
        inviteeRole: isMentor ? USER_ROLES.Mentor : USER_ROLES.Founder,
        ...(!isMentor
          ? {
              inviteeFounderRole: values.role,
            }
          : {}),
      });
    } catch (e) {
      handleApiError(e);
    }
  };

  const schema = Yup.object().shape({
    email: Yup
      .string()
      .email(intl.formatMessage({ id: 'validate.invalidEmail' }))
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    firstName: Yup
      .string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    lastName: Yup
      .string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    ...(!isMentor && {
      role: Yup
        .array()
        .of(Yup.string())
        .min(1, intl.formatMessage({ id: 'validate.mandatoryField' })),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField
            id="lastName"
            required
            label={intl.formatMessage({ id: 'label.name' })}
          />
          <InputField
            id="firstName"
            required
            label={intl.formatMessage({ id: 'label.firstName' })}
          />
          <InputField
            id="email"
            required
            label={intl.formatMessage({ id: 'label.email' })}
          />
          {!isMentor && (
            <>
              <DropdownField
                values={values}
                required
                setFieldValue={setFieldValue}
                label={intl.formatMessage({ id: 'label.role' })}
                name="role"
                options={ideaRoleOptions}
                md={12}
                multiple
              />
            </>
          )}

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              disabled={isSubmitting}
              label={intl.formatMessage({ id: 'button.invite' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default InviteUser;
