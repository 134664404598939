import { Typography, Box, Divider } from '@mui/material';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Counter } from '../../../../assets/theme/styled/Counter';

import { experienceOptions } from '../../../../constants/options';
import { searchLabel } from '../../../../utils/labels';

const ExperienceDetails = ({ experience }) => {
  const intl = useIntl();
  const titleName = useMemo(() => {
    if (!experience.title) {
      return '';
    }
    const label = searchLabel(experience.title, experienceOptions);
    return label ? intl.formatMessage({ id: label }) : experience.title;
  }, [experience.title]);

  return (
    <Box mb={15}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} color="primary" gutterButtom>
          {titleName}
        </Typography>
        <Box display="flex" alignItems="center" gap={4}>
          <Typography variant="body2" color="primary" gutterButtom>
            <Counter>{experience.years}</Counter>
          </Typography>
          <Typography variant="body2" color="primary" gutterButtom>
            {intl.formatMessage({ id: 'label.years' })}
          </Typography>
        </Box>
      </Box>

      {experience.description ? (
        <Typography>{experience.description}</Typography>
      ) : (
        <Typography variant="body1">
          {intl.formatMessage({ id: 'label.uncompleted' })}
        </Typography>
      )}

      <Divider sx={{ mt: 5 }} />
    </Box>
  );
};

export default ExperienceDetails;
