import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import ConclusionForm from '../ConclusionForm';

const AddConclusionModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmitConclusion,
  teamConclusions,
}) => {
  const intl = useIntl();

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={setIsModalOpen}
      title={intl.formatMessage({ id: 'modal.title.contextAnalysisConclusion' })}
    >
      <ConclusionForm
        teamConclusions={teamConclusions}
        handleSubmitConclusion={handleSubmitConclusion}
      />
    </DialogComponent>
  );
};

export default AddConclusionModal;
