import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';

import { StyledLink } from '../../../common/components/_Sidebar/index.styled';
import Iframe from '../../../common/components/Iframe';
import {
  ButtonText,
  ExempleButtonContainer,
  TaskContainer,
  TransitionTaskContainer,
} from '../styled/MrlStyled';

import { CheckIcon } from '../../../assets/icons';
import { ReactComponent as ExampleIcon } from '../../../assets/icons/ExampleIcon.svg';

const MrlTask = ({
  isCompleted,
  title,
  isOpen,
  onMrlTaskOpen,
  description,
  link,
  buttonName,
}) => (
  <Box>
    <TaskContainer onClick={() => onMrlTaskOpen(title)}>
      <Box pr={10}>
        <CheckIcon checked={isCompleted} />
      </Box>

      <span>{title}</span>
      <div style={{ flex: 1 }}>
        {!isOpen ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ float: 'right' }}
          />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} style={{ float: 'right' }} />
        )}
      </div>
    </TaskContainer>

    <TransitionTaskContainer isOpen={isOpen}>
      {isOpen && (
        <>
          <Iframe title="Preview" content={description} hasDynamicHeight />
          {link && (
            <Box display="flex" justifyContent="space-between" mt={10}>
              <StyledLink
                href={link}
                target="_self"
                rel="noopener noreferrer"
              >
                <ExempleButtonContainer>
                  <ExampleIcon />{' '}
                  <ButtonText>{buttonName || 'Exemplu'}</ButtonText>
                </ExempleButtonContainer>
              </StyledLink>
            </Box>
          )}
        </>
      )}
    </TransitionTaskContainer>
  </Box>
);

export default MrlTask;
