import { Button, ButtonGroup } from './index.styled';

const GroupButton = ({
  buttonsConfig,
  variant = 'contained',
  sx,
  disabled = false,
}) => (
  <ButtonGroup variant={variant} sx={sx}>
    {buttonsConfig.map(({
      label, onClick, id, isActive, icon
    }) => (
      <Button
        onClick={onClick}
        key={id}
        className={isActive ? 'activeButton' : ''}
        disabled={disabled}
        startIcon={icon}
      >
        {label}
      </Button>
    ))}
  </ButtonGroup>
);

export default GroupButton;
