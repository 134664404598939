import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const AuthRoot = styled('div')(({ theme }) => `
  display: flex;
  gap: ${theme.spacing(8)};
  min-height: 100vh;
`);

export const FormRoot = styled('div')(({ theme }) => `
  padding: ${theme.spacing(50, 16, 20)};
  background-color: ${theme.palette.common.white};
  min-height: 100vh;
  width: 800px;

  ${theme.breakpoints.down('sm')} {
    min-width: 100%;
  };
`);

export const FormWrapper = styled('div')(({ theme }) => `
  padding: ${theme.spacing(0, 30)};
  
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(0, 10)};
  };
`);

export const ImageWrapper = styled('div')(({ theme }) => `
  padding: ${theme.spacing(100, 50)};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  
  ${theme.breakpoints.up(2000)} {
    & .MuiTypography-root {
      width: 800px;
    };
  };

  ${theme.breakpoints.down('md')} {
    display: none;
  };
`);

export const LinkText = styled(Link)(({ theme }) => `
  color: ${theme.palette.primary.main};
  :hover {
    color: ${theme.palette.general.accentHover};
    text-decoration: underline;
  }
`);

export const Delimiter = styled('div')(({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  border: none;
  margin: 20px auto;
  &::before,
  &::after {
    content: '';
    border-bottom: 1px solid ${theme.palette.general.borderGray};
    flex: 1 0 auto;
    height: 9px;
    margin: 0;
  };
  span {
    text-align: center;
    flex: 0.2 0 auto;
    margin: 0;
  };
`);

export const CenterContent = styled('div')(() => `
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`);

export const PaperContent = styled(Paper)(({ theme }) => `
  padding: ${theme.spacing(30)};
  border-radius: 4px;
  width: 500px;
  box-shadow: none;
  
  ${theme.breakpoints.down('sm')} {
    width: 100%;
    margin: ${theme.spacing(16)};
  }
`);
