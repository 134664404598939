import { ContentPaste, Help, Paperclip, Science, UpcommingIcon, UpwardsTrend } from '../../assets/icons';

export const BOARDS_TABS = [
  {
    key: 'hypothesis',
    label: 'label.hypotheses',
    icon: <Help/>,
  },
  {
    key: 'activities',
    label: 'label.activities',
    icon: <Science />,
  },
  {
    key: 'tasks',
    label: 'label.tasks',
    icon: <ContentPaste/>,
  },
  {
    key: 'details',
    label: 'label.details',
    icon: <Paperclip />,
  },
  {
    key: 'indicators',
    label: 'label.indicators',
    icon: <UpwardsTrend />,
  },
  {
    key: 'conclusions',
    label: 'label.conclusions',
    icon: <UpcommingIcon/>,
  },
];
