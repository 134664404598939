import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';

import { HtmlContainer } from '../../modules/mrl/styled/MrlStyled';

const RichTextContainer = ({ text, children, fontSize, color }) => {
  const [needsToBeParsed, setNeedsToBeParsed] = useState(false);
  const [textToRender, setTextToRender] = useState(text);

  useEffect(() => {
    try {
      const rawContentFromDatabase = JSON.parse(text);
      const html = draftToHtml(rawContentFromDatabase);
      setTextToRender(html);
      setNeedsToBeParsed(!!html);
    } catch (err) {
      setTextToRender(text);
      setNeedsToBeParsed(false);
    }
  }, [text]);

  return (
    needsToBeParsed
      ? (
        <HtmlContainer fontSize={fontSize} color={color}>
          {parse(textToRender)}
        </HtmlContainer>
      )
      : children
  );
};

export default RichTextContainer;
