import { http } from '../../../core/services/http';

export const httpPostNewComment = ({
  message,
  type,
  teamId,
  parentId,
  entityId,
}) =>
  http.post(`/comments/add/teams/${teamId}`, {
    title: message,
    commentType: type,
    ...(parentId && { parentCommentId: parentId }),
    ...(entityId && { entityId }),
  });

export const httpGetAllComments = ({ filterParams, userId, teamId }) =>
  http.get(`/comments/get-all/teams/${teamId}`, {
    params: {
      filters: {
        ...(filterParams && { ...filterParams }),
        ...(userId && { userId }),
      },
    },
  });

export const httpEditCommentById = ({ comment, isRead, commentId }) =>
  http.put(`/comments/edit/${commentId}`, {
    ...(comment && { title: comment }),
    ...(isRead && { isRead }),
  });

export const httpDeleteCommentById = ({ commentId }) =>
  http.delete(`/comments/delete/${commentId}`);

export const httpGetCommentById = ({ commentId }) =>
  http.get(`/comments/get-by-id/${commentId}`);
