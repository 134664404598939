import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';

import { ButtonsWrapper } from './index.styled';

const RadioFieldGroup = ({
  id,
  label,
  options,
  fullWidth = false,
  required = false,
  getOptionLabel = null,
}) => {
  const [field, meta] = useField(id);

  return (
    <Grid item xs={12} sx={{ marginBottom: '15px'}}>
      <FormControl component="fieldset" error={meta.touched && !!meta.error} fullWidth={fullWidth}>
        <ButtonsWrapper>
          <FormLabel component="legend" required={required}>
            {label}
          </FormLabel>
          <RadioGroup {...field}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio />}
                label={getOptionLabel ? getOptionLabel(option) : option.label}
                sx={{ marginBottom: 0 }}
              />
            ))}
          </RadioGroup>
        </ButtonsWrapper>

        {meta.touched && meta.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default RadioFieldGroup;
