import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import React from 'react';

const CheckboxFieldGroup = ({
  label,
  options,
  onChange,
  values,
  name,
}) => {

  const handleChange = (optionValue) => {
    const newValue = values.includes(optionValue)
      ? values.filter((val) => val !== optionValue)
      : [...values, optionValue];
    onChange(name, newValue);
  };

  return (
    <FormControl
      component="fieldset"
      variant="outlined"
      fullWidth
      sx={{ px: 15 }}
    >
      <FormLabel component="legend" sx={{ color: '#74A3FE' }}>
        {label}
      </FormLabel>
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={values?.includes(option.value)}
                onChange={() => handleChange(option.value)}
                name={option.label}
                sx={{
                  color: '#74A3FE',
                  padding: '0 5px 0 0',
                }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxFieldGroup;
