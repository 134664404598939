const MuiIconButton = {
  styleOverrides: {
    root: ({ theme: muiTheme }) => ({
      '&.outlinedSquare': {
        border: '2px solid',
        borderRadius: '4px',
        padding: '4px',
      },
      '&.faded': {
        color: muiTheme.palette.general.accentLight,
      },
    }),
    colorSecondary: ({ theme: muiTheme }) => ({
      color: muiTheme.palette.general.accentLight,
    }),
    colorError: ({ theme: muiTheme }) => ({
      color: muiTheme.palette.error.secondary,
    })
  },
};

export default MuiIconButton;
