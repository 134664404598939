import { Accordion, Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import { ArrowDown } from '../../../assets/icons';

import { ArrowIcon } from './index.styled';

const AccordionComponent = ({ title, children, actions, pl = 24 }) => {
  const [accordionOpen, setAccordionOpen] = useState(true);

  const toggleTasksAccordion = () => setAccordionOpen(!accordionOpen);

  return (
    <Accordion
      expanded={accordionOpen}
      sx={{
        boxShadow: 'none',
        '&:before': { height: '0px' },
        '&.Mui-expanded': {
          mt: 0,
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gap={10} sx={{ cursor: 'pointer' }} onClick={toggleTasksAccordion}>
          <ArrowIcon expanded={accordionOpen}>
            <ArrowDown size="18px" />
          </ArrowIcon>
          <Typography fontWeight={600} color="primary">
            {title}
          </Typography>
        </Box>

        {actions}
      </Box>

      <Box pl={pl} pt={10}>
        {children}
      </Box>
    </Accordion>
  );
};

export default AccordionComponent;
