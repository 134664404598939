import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import AddUserStoryModal from '../../../../common/modals/AddUserStoryModal';
import { Loading } from '../../../../common/styled';
import GenericComments from '../../../commentsAndNotifications/components/GenericComments';

import { currentTeamIdSelector } from '../../../../common/store';
import { deleteDetail, getDetailById } from '../../../../requests/details';
import { editDetail } from '../../../../store/details/detailsSlice';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { DETAIL_CATEGORIES } from '../../../../constants/detail';
import { DRAWER_TABS } from '../../../../constants/drawerTabs';
import { formatDetailForServer } from '../../../../utils/detailUtils';
import { handleApiError } from '../../../../utils/errorUtils';
import { getCharacteristicsAsOptions } from '../../../../utils/hypothesisUtils';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';

import { EditIcon, TrashIconOutlined } from '../../../../assets/icons';

import UserStoryDetail from '../../../../common/drawers/ContentDetailDrawer/UserStoryDetail';

const UserStoryDetailDrawer = ({
  initialDetail,
  parentDetail,
  onClose,
  onSuccessDelete,
  onBack,
  onRefresh,
}) => {
  const intl = useIntl();
  const { hypothesisId } = useParams();
  const dispatch = useDispatch();

  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [detail, setDetail] = useState({});
  const [detailToEdit, setDetailToEdit] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: false });

  const currentUserId = useSelector((state) => state.userProfile.userProfile.userId._id);
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile]) || [];
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const characteristics = useSelector((state) => (
    state?.hypotheses?.hypothesis?.clientProfile?.characteristics || []
  ));

  useEffect(() => {
    if (initialDetail?._id) {
      fetchDetail();
    }
  }, [initialDetail?._id]);

  const fetchDetail = async () => {
    setIsLoading({ loading: true });

    try {
      const response = await getDetailById(initialDetail?._id);
      setDetail(response?.data);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading({ loading: false });
    }
  };

  // TODO: this needs to be changed when we adjust the comments
  // TODO: take into account that should look at activeCharacteristic as well
  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile,
          entityId: hypothesisId,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleOpenDialog = () => setIsDialogOpen(true);

  const handleDelete = async () => {
    try {
      await deleteDetail(initialDetail?._id);

      if (onSuccessDelete) {
        onSuccessDelete();
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleUpdateSuccess = (updatedDetail) => {
    onRefresh();
    setDetail(updatedDetail);
    handleCloseDialog();
  };

  const handleSubmit = (formValues) => {
    const adjustedValues = formatDetailForServer({ ...formValues, category: DETAIL_CATEGORIES.userStory });
    const payload = {
      ...detail,
      ...adjustedValues,
    };

    if (detail) {
      dispatch(editDetail(payload, detail?._id, handleUpdateSuccess));
    }
  };

  const formatSegment = ({ _id }, intensity) => ({ id: _id, intensity });

  const handleEdit = () => {
    const {
      characteristicsFromCustomerSegments: characteristics,
      tasksFromCustomerSegments: tasks,
      painsFromCustomerSegments: pains,
      winsFromCustomerSegments: wins,
    } = detail;

    setDetailToEdit({
      ...detail,
      characteristicsFromCustomerSegments: characteristics?.map(({ id, intensity }) => formatSegment(id, intensity)),
      tasksFromCustomerSegments: tasks?.map(({ id, intensity }) => formatSegment(id, intensity)),
      painsFromCustomerSegments: pains?.map(({ id, intensity }) => formatSegment(id, intensity)),
      winsFromCustomerSegments: wins?.map(({ id, intensity }) => formatSegment(id, intensity)),
    });
    handleOpenDialog();
  };

  return (
    <>
      <DrawerTitle
        title={intl.formatMessage({ id: 'label.viewUserStory' })}
        onClose={onClose}
        onBack={onBack}
      />

      <DrawerTabs
        sx={{ mt: 4, mb: 10 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />

      {activeDrawerTab === DRAWER_TABS.details ? (
        <Box mt={20} gap={20} display="flex" flexDirection="column">
          <UserStoryDetail detail={detail} parentDetail={parentDetail} onLoading={setIsLoading}/>

          <Box display="flex" alignItems="center" gap={10}>
            <ButtonComponent
              onClick={handleEdit}
              variant="outlined"
              color="secondary"
              icon={<EditIcon fill="currentColor" size="14px" />}
              label={intl.formatMessage({ id: 'button.edit' })}
            />
            <ButtonComponent
              onClick={handleDelete}
              icon={<TrashIconOutlined size="16px" />}
              color="error"
              label={intl.formatMessage({ id: 'button.delete' })}
            />
          </Box>

          <Loading isLoading={isLoading?.loading} />
        </Box>
      ) : (
        <GenericComments
          fetchComments={fetchComments}
          comments={comments}
          teamId={teamId}
          userId={currentUserId}
          type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile}
          entityId={hypothesisId}
        />
      )}

      {isDialogOpen && (
        <AddUserStoryModal
          isOpen={isDialogOpen}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          characteristics={getCharacteristicsAsOptions(characteristics)}
          formValues={detailToEdit}
        />
      )}
    </>
  );
};

export default UserStoryDetailDrawer;
