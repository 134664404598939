import { find, get } from 'lodash-es';
import { createSelector } from 'reselect';

export const userTeamsSelector = (state) => state.common.userTeams.userTeams;

export const currentTeamIdSelector = createSelector(
  userTeamsSelector,
  (userTeams) => get(find(userTeams, (team) => !!team.selected), '_id'),
);

export const currentTeamSelector = createSelector(
  userTeamsSelector,
  (userTeams) => userTeams?.find((team) => !!team.selected),
);
