import { Typography } from '@mui/material';

import { ExternalLink } from '../../../assets/icons';

import { Content } from './index.styled';

const LinkComponent = ({ domain }) => (
  <Content>
    <a href={domain} target="_blank" rel="noreferrer"  className="text-ellipsis">
      <Typography
        color="primary"
        gutterBottom
      >
        {domain}
      </Typography>
    </a>
    <ExternalLink />
  </Content>
);

export default LinkComponent;
