import { useIntl } from 'react-intl';

import { DETAIL_STATUS_OPTIONS } from '../../../../constants/options';
import { DEFAULT_TOOLBAR_CONFIG } from '../../../../constants/richTextEditor';

import InputField from '../../../../fields/InputField';
import RadioFieldGroup from '../../../../fields/RadioFieldGroup';
import RenderRichText from '../../../../fields/RichTextEditor';

const DetailGeneralForm = () => {
  const intl = useIntl();

  return (
    <>
      <InputField
        id="title"
        label={intl.formatMessage({ id: 'label.detailName' })}
        placeholder="Maria Pop"
        required
      />

      <RenderRichText
        name="description"
        label={intl.formatMessage({ id: 'label.addADescription' })}
        height="200px"
        config={DEFAULT_TOOLBAR_CONFIG}
        isCountVisible
      />

      <InputField id="cost" label={intl.formatMessage({ id: 'label.estimatedCost' })} required />

      <RadioFieldGroup
        id="status"
        options={DETAIL_STATUS_OPTIONS}
        fullWidth
        label={intl.formatMessage({ id: 'label.study' })}
        required
      />
    </>
  );
};

export default DetailGeneralForm;
