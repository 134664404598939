
export const variants = {
  h1: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '33px',
  },
  h2: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '27px',
  },
 h3: {
   fontSize: '16px',
   fontWeight: 700,
   lineHeight: '23px',
 },
  body1: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  body2: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  caption: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '17px',
  },
};
