import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import VerifyEmail from '../../../../common/components/VerifyEmail';
import { WhiteCard } from '../../../../common/styled';
import ResetPasswordForm from '../../components/ResetPasswordForm';

import { useLogin } from '../../../../hooks/useAuth';
import { resetPassword } from '../../../../requests/auth';

import { handleApiError } from '../../../../utils/errorUtils';

import { AccountPage, AccountPanel } from '../../index.styled';

const ResetPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onLogin, email: userEmail, showResend, setShowResend } = useLogin();
  const location = useLocation();

  const onSubmit = async ({ password }) => {
    try {
      setIsSubmitting(true);
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const email = queryParams.get('email');
      await resetPassword(token, email, password);
      await onLogin({ email, password });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const goToLogin = () => {
    setShowResend(false);
    return history.push('/account/login');
  };

  return (
    <AccountPage>
      <AccountPanel isLoading={isSubmitting}>
        <WhiteCard noOverflow padding="30px" borderRadius="10px">
          {showResend
            ? <VerifyEmail email={userEmail} goToLogin={goToLogin} />
            : <ResetPasswordForm handleSubmit={onSubmit} />
          }
        </WhiteCard>
      </AccountPanel>
    </AccountPage>
  );
};

export default ResetPassword;
