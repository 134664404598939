import { Box } from '@mui/material';
import { size } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import GenericEmptyTable from '../../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../../common/components/GenericTableComponent';
import SectionTitle from '../../../../common/components/SectionTitle';

import { useDebounce } from '../../../../hooks/useDebounce';
import { loadCommunity } from '../../../../store/leaderboard/leaderboardSlice';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { formatDate } from '../../../../utils/dateHelpers';
import { capitalize } from '../../../../utils/labels';

import SearchField from '../../../../fields/SearchField';

const Leaderboard = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.leaderboard.teams || []);
  const [search, setSearch] = useState('');
  const [isClearing, setIsClearing] = useState(false);
  const debounceSearch = useDebounce([search], 1000);

  const createTableHeaderCells = () => [
    { type: 'string', content: '#', key: 'order' },
    { type: 'string', content: intl.formatMessage({ id: 'label.designation' }), key: 'name' },
    { type: 'string', content: capitalize(intl.formatMessage({ id: 'label.year' })), key: 'dateOfEstablishment' },
    { type: 'string', content: intl.formatMessage({ id: 'label.members' }), key: 'membersCount' },
    { type: 'string', content: intl.formatMessage({ id: 'label.irl' }), key: 'irl' },
    { type: 'string', content: intl.formatMessage({ id: 'label.hypotheses' }), key: 'hypothesesCount' },
    {
      type: 'string',
      content: intl.formatMessage({ id: 'label.hypothesesValidated' }),
      key: 'hypothesesValidatedCount',
    },
    {
      type: 'string',
      content: intl.formatMessage({ id: 'label.hypothesesInvalid' }),
      key: 'hypothesesInvalidatedCount',
    },
    { type: 'string', content: intl.formatMessage({ id: 'label.activities' }), key: 'activitiesCount' },
    { type: 'string', content: intl.formatMessage({ id: 'label.conclusions' }), key: 'conclusionsCount' },
  ];

  const createRows = () =>
    teams.map(
      (
        {
          _id,
          name,
          irl,
          dateOfEstablishment,
          membersCount,
          hypothesesCount,
          hypothesesValidatedCount,
          hypothesesInvalidatedCount,
          activitiesCount,
          conclusionsCount,
        },
        index
      ) => {
        return {
          rowId: _id,
          order: index + 1,
          name,
          year: dateOfEstablishment
            ? formatDate(dateOfEstablishment)
            : NOT_APPLICABLE,
          membersCount,
          irl,
          hypothesesCount,
          hypothesesValidatedCount,
          hypothesesInvalidatedCount,
          activitiesCount,
          conclusionsCount,
        };
      }
    );

  useEffect(() => {
    dispatch(loadCommunity({ search }));

    if (isClearing) {
      setIsClearing(false);
    }
  }, [debounceSearch]);

  const handleChangeSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleClearSearch = () => {
    setIsClearing(true);
    setSearch('');
  };

  return (
    <>
      <Box display="flex" gap={16} alignItems="center" mb={20}>
        <SectionTitle
          mb={0}
          title={intl.formatMessage({ id: 'label.teams' })}
          counter={size(teams)}
        />
        <SearchField
          name="search-teams"
          id="search-teams"
          fullWidth
          search={search}
          onClearSearch={handleClearSearch}
          onChange={handleChangeSearch}
          placeholder={intl.formatMessage({ id: 'placeholder.search' })}
        />
      </Box>

      {teams?.length ? (
        <GenericTableComponent
          rows={createRows()}
          headerCells={createTableHeaderCells()}
        />
      ) : (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          detailMessage={`${intl.formatMessage({ id: 'label.noTeamsWasAdded' })}...`}
        />
      )}
    </>
  );
};

export default Leaderboard;
