import { styled } from '@mui/material/styles';

export const ConclusionWrapper = styled('div')(({ theme }) => `
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  cursor: pointer;
  margin-left: ${theme.spacing(2)};
  
  :hover {
    text-decoration: underline;
  };
`);
