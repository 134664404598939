import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import {
  SubmitButton,
  ButtonDiv,
} from '../../../../common/styled';
import { Delimiter, LinkText } from '../../../../layouts/AuthLayout/auth.styled';

import { facebookLogin } from '../../../../store/auth/login';

import InputField from '../../../../fields/InputField';

const initialValues = {
  email: '',
  password: '',
};

const LoginForm = ({ onSubmit }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleFacebookLogin = () => dispatch(facebookLogin());

  const schema = Yup.object({
    email: Yup
      .string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    password: Yup
      .string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={8}>
            <InputField
              placeholder={intl.formatMessage({ id: 'placeholder.email' })}
              id="email"
              label={intl.formatMessage({ id: 'label.email' })}
              md={12}
            />
            <InputField
              type="password"
              id="password"
              label={intl.formatMessage({ id: 'label.password' })}
              placeholder={intl.formatMessage({ id: 'placeholder.password' })}
              md={12}
            />
            <Grid item xs={6}>
              <LinkText to="/account/forgot-password">
                {intl.formatMessage({ id: 'label.forgotPassword' })}
              </LinkText>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <LinkText to="/account/register">
                {intl.formatMessage({ id: 'label.noAccountRegister' })}
              </LinkText>
            </Grid>
            <Grid item xs={12} mt={8}>
              <ButtonComponent
                size="large"
                fullWidth
                type="submit"
                disabled={isSubmitting}
                label={intl.formatMessage({ id: 'button.login' })}
              />
            </Grid>
          </Grid>
          <Delimiter>
            <Typography variant="body2" component="span">
              {intl.formatMessage({ id: 'label.or' })}
            </Typography>
          </Delimiter>
          <ButtonDiv>
            <SubmitButton
              style={{ width: '205px' }}
              facebookButton
              type="button"
              onClick={handleFacebookLogin}
            >
              <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '5px' }} />
              {intl.formatMessage({ id: 'label.loginWithFacebook' })}
            </SubmitButton>
          </ButtonDiv>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
