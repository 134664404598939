import { Box, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';
import { postNewTrend, editTrendById } from '../../../../store/contextAnalysis';

import { currentTeamIdSelector } from '../../../../common/store';

import { DEFAULT_TOOLBAR_CONFIG } from '../../../../constants/richTextEditor';

import { SaveIconOutlined, UpwardsTrend } from '../../../../assets/icons';

import InputField from '../../../../fields/InputField';
import RenderRichText from '../../../../fields/RichTextEditor';
import SquareButtonsField from '../../../../fields/SquareButtonsField';

const TrendsForm = ({
  postNewTrend,
  teamId,
  setIsModalOpen,
  selectedTrend,
  editTrendById,
  setSelectedTrend,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const schema = Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({ id: 'validate.mandatoryField' })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: 'validate.mandatoryField' })
    ),
    isTrendFavorable: Yup.boolean().required(
      intl.formatMessage({ id: 'validate.mandatoryField' })
    ),
  });

  const initialValues = {
    title: selectedTrend?.title || '',
    description: selectedTrend?.description || '',
    isTrendFavorable: selectedTrend?.isTrendFavorable || false,
  };

  const handleSubmit = (values) => {
    if (selectedTrend) {
      editTrendById(selectedTrend._id, values, teamId);
      setIsModalOpen(false);
      setSelectedTrend(null);
      return;
    }

    postNewTrend(values, teamId);
    setIsModalOpen(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField id="title" label={intl.formatMessage({ id: 'label.title' })} />

          <RenderRichText
            name="description"
            label={intl.formatMessage({ id: 'label.description' })}
            config={DEFAULT_TOOLBAR_CONFIG}
          />

          <SquareButtonsField
            name="isTrendFavorable"
            label={intl.formatMessage({ id: 'label.willTrendBeFavorable' })}
            value={values?.isTrendFavorable}
            onChange={setFieldValue}
            option1={<UpwardsTrend fill={theme.palette.success.main} />}
            option2={<Box sx={{ transform: 'scaleY(-1)' }}><UpwardsTrend fill={theme.palette.error.secondary} /></Box>}
            sx={{ mb: 15 }}
          />

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  postNewTrend,
  editTrendById,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrendsForm);
