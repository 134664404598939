import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => `
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${theme.palette.general.borderAccent2};
  width: 100%;
  margin-bottom: 16px;
`);

export const PaymentsPageContainer = styled('div')(() => `
  width: 100%;
  overflow-y: auto;
  height: 100%;
`);
