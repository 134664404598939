import React from 'react';
import { FormattedMessage } from 'react-intl';

import { APP_NAME } from '../../constants/general';
import { USER_ROLES } from '../../constants/roles';
import { LOCALES } from '../../languages/langTexts';

import manWorking from '../../assets/onboarding/bulb-puzzle.svg';
import bullseyeImage from '../../assets/onboarding/bullseye.svg';
import crossRoadsImage from '../../assets/onboarding/crossroads.svg';
import describeYourselfImage from '../../assets/onboarding/describeYourself.svg';
import expertiseImage from '../../assets/onboarding/expertise.svg';
import languageImage from '../../assets/onboarding/language.svg';
import manThinkingImage from '../../assets/onboarding/man-thinking.svg';
import moneyImage from '../../assets/onboarding/money.svg';
import partyImage from '../../assets/onboarding/party.svg';
import pieChartImage from '../../assets/onboarding/pie-chart.svg';
import privateImage from '../../assets/onboarding/private.svg';
import puzzleImage from '../../assets/onboarding/puzzle.svg';
import questionMarkImage from '../../assets/onboarding/question-mark.jpg';
import roleSelectionImage from '../../assets/onboarding/role-selection.svg';
import speakerImage from '../../assets/onboarding/speaker.svg';
import starsImage from '../../assets/onboarding/stars.svg';

import ColumnOptionsStep from './containers/ColumnOptionsStep';
import ConfirmationStep from './containers/ConfirmationStep';
import DescribeIdeaStep from './containers/DescribeIdeaStep';
import DescribeYourselfStep from './containers/DescribeYourselfStep';
import ExperienceStep from './containers/ExperienceStep';
import NumberOfMembersStep from './containers/NumberOfMembersStep';
import RewardStep from './containers/RewardStep';
import Role from './containers/Role';
import RowsOptionsStep from './containers/RowsOptionsStep';
import SubscriptionTypeStep from './containers/SubscriptionTypeStep';

export const MULTIPLE_ANSWER_TYPE = 'multiple';
export const SINGLE_ANSWER_TYPE = 'single';
export const BOOLEAN_ANSWER_TYPE = 'boolean';

export const QuestionCodes = {
  language: 'language',
  accountType: 'accountType',
  whatBringsYouHere: 'whatBringsYouHere',
  whatWouldHelp: 'whatWouldHelp',
  describeYourIdea: 'describeYourIdea',
  howManyMembers: 'howManyMembers',
  areaOfInterest: 'areaOfInterest',
  doYouNeedHelp: 'doYouNeedHelp',
  makeProfilePublic: 'makeProfilePublic',
  howDidYouFoundUs: 'howDidYouFoundUs',
  whatsYourCurrentJob: 'whatsYourCurrentJob',
  yourOpinionIsImportant: 'yourOpinionIsImportant',
  subscriptionType: 'subscriptionType',
  experience: 'experience',
  reward: 'reward',
  describeYourself: 'describeYourself',
  confirmation: 'confirmation',
};

export const ACCOUNT_TYPE_CHOICE_QUESTION = {
  image: roleSelectionImage,
  question: 'question.accountType',
  answerOptions: [
    {
      value: USER_ROLES.Founder,
      label: <FormattedMessage id="label.founder" />,
      onSelectMessage: <FormattedMessage id="founder.description" />,
    },
    {
      value: USER_ROLES.Mentor,
      label: <FormattedMessage id="label.expert" />,
      onSelectMessage: <FormattedMessage id="mentor.description" />,
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  questionCode: QuestionCodes.accountType,
  component: Role,
};

export const LANGUAGE_QUESTION = {
  image: languageImage,
  question: 'question.language',
  answerOptions: [
    {
      value: LOCALES.ROMANIAN,
      label: <FormattedMessage id="label.romanian" />,
      onSelectMessage: <FormattedMessage id="language.description" />,
    },
    {
      value: LOCALES.ENGLISH,
      label: <FormattedMessage id="label.english" />,
      onSelectMessage: <FormattedMessage id="language.description" />,
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  questionCode: QuestionCodes.language,
  component: ColumnOptionsStep,
};

export const WHAT_BRINGS_YOU_QUESTION = {
  image: bullseyeImage,
  question: 'question.whatBringsYouHere',
  questionAdditionalInformation: <FormattedMessage id="label.maximThreeAnswers" values={{ number: 3 }} />,
  answerOptions: [
    { value: 'whatBringsYouHere.option1', label: <FormattedMessage id="whatBringsYouHere.option1" /> },
    { value: 'whatBringsYouHere.option2', label: <FormattedMessage id="whatBringsYouHere.option2" /> },
    { value: 'whatBringsYouHere.option3', label: <FormattedMessage id="whatBringsYouHere.option3" /> },
    { value: 'whatBringsYouHere.option4', label: <FormattedMessage id="whatBringsYouHere.option4" /> },
    { value: 'whatBringsYouHere.option5', label: <FormattedMessage id="whatBringsYouHere.option5" /> },
    { value: 'whatBringsYouHere.option6', label: <FormattedMessage id="whatBringsYouHere.option6" /> },
    { value: 'whatBringsYouHere.option7', label: <FormattedMessage id="whatBringsYouHere.option7" /> },
    { value: 'whatBringsYouHere.option8', label: <FormattedMessage id="whatBringsYouHere.option8" /> },
    { value: 'whatBringsYouHere.option9', label: <FormattedMessage id="whatBringsYouHere.option9" /> },
  ],
  answerLimit: 3,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
  questionCode: QuestionCodes.whatBringsYouHere,
  preselected: {
    [USER_ROLES.Founder]: {
      values: ['whatBringsYouHere.option4'],
      disabled: true,
    }
  },
  component: RowsOptionsStep
};

export const WHAT_WOULD_HELP_QUESTION = {
  image: puzzleImage,
  question: 'question.whatWouldHelp',
  questionAdditionalInformation: <FormattedMessage id="label.maximThreeAnswers" values={{ number: 3 }} />,
  answerOptions: [
    { value: 'whatWouldHelp.option1', label: <FormattedMessage id="whatWouldHelp.option1" /> },
    { value: 'whatWouldHelp.option2', label: <FormattedMessage id="whatWouldHelp.option2" /> },
    { value: 'whatWouldHelp.option3', label: <FormattedMessage id="whatWouldHelp.option3" /> },
    { value: 'whatWouldHelp.option4', label: <FormattedMessage id="whatWouldHelp.option4" /> },
    { value: 'whatWouldHelp.option5', label: <FormattedMessage id="whatWouldHelp.option5" /> },
    { value: 'whatWouldHelp.option6', label: <FormattedMessage id="whatWouldHelp.option6" /> },
    { value: 'whatWouldHelp.option7', label: <FormattedMessage id="whatWouldHelp.option7" /> },
    { value: 'whatWouldHelp.option8', label: <FormattedMessage id="whatWouldHelp.option8" /> },
    { value: 'whatWouldHelp.option9', label: <FormattedMessage id="whatWouldHelp.option9" /> },
    { value: 'whatWouldHelp.option10', label: <FormattedMessage id="whatWouldHelp.option10" /> },
    { value: 'whatWouldHelp.option11', label: <FormattedMessage id="whatWouldHelp.option11" /> },
    { value: 'whatWouldHelp.option12', label: <FormattedMessage id="whatWouldHelp.option12" /> },
    { value: 'whatWouldHelp.option13', label: <FormattedMessage id="whatWouldHelp.option13" /> },
    { value: 'whatWouldHelp.option14', label: <FormattedMessage id="whatWouldHelp.option14" /> },
    { value: 'whatWouldHelp.option15', label: <FormattedMessage id="whatWouldHelp.option15" /> },
    { value: 'whatWouldHelp.option16', label: <FormattedMessage id="whatWouldHelp.option16" /> },
  ],
  answerLimit: 3,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
  questionCode: QuestionCodes.whatWouldHelp,
  component: RowsOptionsStep,
};

export const DESCRIBE_YOUR_IDEA_QUESTION = {
  image: manThinkingImage,
  question: 'question.describeYourIdea',
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  answerWithQuestion: true,
  questionCode: QuestionCodes.describeYourIdea,
  component: DescribeIdeaStep,
};

export const HOW_MANY_MEMBERS_QUESTION = {
  image: pieChartImage,
  question: 'question.howManyMembers',
  answerOptions: [
    { value: 'howManyMembers.option1', label: <FormattedMessage id="howManyMembers.option1" /> },
    { value: '2-5', label: '2-5' },
    { value: '6-9', label: '6-9' },
    { value: '10-49', label: '10-49' },
    { value: '50-249', label: '50-249' },
    { value: '+250', label: '+250' },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  questionCode: QuestionCodes.howManyMembers,
  component: NumberOfMembersStep,
};

export const WHAT_DO_YOU_FOCUS_ON_QUESTION = {
  image: manWorking,
  question: 'question.areaOfInterest',
  answerOptions: [
    { value: 'areaOfInterest.option1', label: <FormattedMessage id="areaOfInterest.option1" /> },
    { value: 'areaOfInterest.option2', label: <FormattedMessage id="areaOfInterest.option2" /> },
    { value: 'areaOfInterest.option3', label: <FormattedMessage id="areaOfInterest.option3" /> },
    { value: 'areaOfInterest.option4', label: <FormattedMessage id="areaOfInterest.option4" /> },
    { value: 'areaOfInterest.option5', label: <FormattedMessage id="areaOfInterest.option5" /> },
    { value: 'areaOfInterest.option6', label: <FormattedMessage id="areaOfInterest.option6" /> },
    {
      value: 'areaOfInterest.option7',
      label: <FormattedMessage id="areaOfInterest.option7" values={{ appName: APP_NAME }} />,
    },
    { value: 'areaOfInterest.option8', label: <FormattedMessage id="areaOfInterest.option8" /> },
    { value: 'areaOfInterest.option9', label: <FormattedMessage id="areaOfInterest.option9" /> },
    { value: 'areaOfInterest.option10', label: <FormattedMessage id="areaOfInterest.option10" /> },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  questionCode: QuestionCodes.areaOfInterest,
  component: RowsOptionsStep,
};

export const WANT_TO_BE_HELPED_QUESTION = {
  image: crossRoadsImage,
  question: 'question.doYouNeedHelp',
  answerOptions: [
    {
      label: <FormattedMessage id="label.yes" />,
      value: true,
      onSelectMessage: <FormattedMessage id="doYouNeedHelp.description" />,
    },
    {
      label: <FormattedMessage id="label.no" />,
      value: false,
    },
  ],
  answerLimit: 1,
  questionAnswerType: BOOLEAN_ANSWER_TYPE,
  questionCode: QuestionCodes.doYouNeedHelp,
  component: ColumnOptionsStep,
};

export const HOW_DID_YOU_HEAR_ABOUT_US_QUESTION = {
  image: speakerImage,
  question: 'question.howYouFoundUs',
  answerOptions: [
    { value: 'howYouFoundUs.option1', label: <FormattedMessage id="howYouFoundUs.option1" /> },
    { value: 'howYouFoundUs.option2', label: <FormattedMessage id="howYouFoundUs.option2" /> },
    { value: 'howYouFoundUs.option3', label: <FormattedMessage id="howYouFoundUs.option3" /> },
    { value: 'howYouFoundUs.option4', label: <FormattedMessage id="howYouFoundUs.option4" /> },
    { value: 'howYouFoundUs.option5', label: <FormattedMessage id="howYouFoundUs.option5" /> },
    { value: 'howYouFoundUs.option6', label: <FormattedMessage id="howYouFoundUs.option6" /> },
    { value: 'howYouFoundUs.option7', label: <FormattedMessage id="howYouFoundUs.option7" /> },
    { value: 'howYouFoundUs.option8', label: <FormattedMessage id="howYouFoundUs.option8" /> },
    { value: 'howYouFoundUs.option9', label: <FormattedMessage id="howYouFoundUs.option9" /> },
    { value: 'howYouFoundUs.option10', label: <FormattedMessage id="howYouFoundUs.option10" /> },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  questionCode: QuestionCodes.howDidYouFoundUs,
  component: RowsOptionsStep,
};

export const WHAT_IS_YOUR_JOB_QUESTION = {
  image: questionMarkImage,
  question: 'question.whatsYourCurrentJob',
  questionAdditionalInformation: <FormattedMessage id="label.maximThreeAnswers" values={{ number: 3 }} />,
  answerOptions: [
    { value: 'whatsYourCurrentJob.option1', label: <FormattedMessage id="whatsYourCurrentJob.option1" /> },
    { value: 'whatsYourCurrentJob.option2', label: <FormattedMessage id="whatsYourCurrentJob.option2" /> },
    { value: 'whatsYourCurrentJob.option3', label: <FormattedMessage id="whatsYourCurrentJob.option3" /> },
    { value: 'whatsYourCurrentJob.option4', label: <FormattedMessage id="whatsYourCurrentJob.option4" /> },
    { value: 'whatsYourCurrentJob.option5', label: <FormattedMessage id="whatsYourCurrentJob.option5" /> },
    { value: 'whatsYourCurrentJob.option6', label: <FormattedMessage id="whatsYourCurrentJob.option6" /> },
    { value: 'whatsYourCurrentJob.option7', label: <FormattedMessage id="whatsYourCurrentJob.option7" /> },
    { value: 'whatsYourCurrentJob.option8', label: <FormattedMessage id="whatsYourCurrentJob.option8" /> },
    { value: 'whatsYourCurrentJob.option9', label: <FormattedMessage id="whatsYourCurrentJob.option9" /> },
    { value: 'whatsYourCurrentJob.option10', label: <FormattedMessage id="whatsYourCurrentJob.option10" /> },
    { value: 'whatsYourCurrentJob.option11', label: <FormattedMessage id="whatsYourCurrentJob.option11" /> },
    { value: 'whatsYourCurrentJob.option12', label: <FormattedMessage id="whatsYourCurrentJob.option12" /> },
    { value: 'whatsYourCurrentJob.option13', label: <FormattedMessage id="whatsYourCurrentJob.option13" /> },
    { value: 'whatsYourCurrentJob.option14', label: <FormattedMessage id="whatsYourCurrentJob.option14" /> },
    { value: 'whatsYourCurrentJob.option15', label: <FormattedMessage id="whatsYourCurrentJob.option15" /> },
    { value: 'whatsYourCurrentJob.option16', label: <FormattedMessage id="whatsYourCurrentJob.option16" /> },
  ],
  answerLimit: 3,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
  questionCode: QuestionCodes.whatsYourCurrentJob,
  component: RowsOptionsStep,
};

export const FEEDBACK_COLLECTION_QUESTION = {
  image: starsImage,
  question: 'question.feedbackCollection',
  questionAdditionalInformation: <FormattedMessage id="label.chooseAnswer" />,
  answerOptions: [
    { value: 'feedbackCollection.option1', label: <FormattedMessage id="feedbackCollection.option1" /> },
    { value: 'feedbackCollection.option2', label: <FormattedMessage id="feedbackCollection.option2" /> },
    { value: 'feedbackCollection.option3', label: <FormattedMessage id="feedbackCollection.option3" /> },
    { value: 'feedbackCollection.option4', label: <FormattedMessage id="feedbackCollection.option4" /> },
    { value: 'feedbackCollection.option5', label: <FormattedMessage id="feedbackCollection.option5" /> },
  ],
  answerLimit: 4,
  questionAnswerType: MULTIPLE_ANSWER_TYPE,
  questionCode: QuestionCodes.yourOpinionIsImportant,
  component: RowsOptionsStep,
};

export const SUBSCRIPTION_TYPE_CHOICE_QUESTION = {
  question: 'question.subscriptionType',
  answerOptions: [
    {
      value: 'free',
      label: <FormattedMessage id="label.freeLimited" />,
      onSelectMessage: <FormattedMessage id="freePlan.description" />,
    },
    {
      value: 'paid',
      label: <FormattedMessage id="label.paidFull" />,
      onSelectMessage: <FormattedMessage id="paidPlan.description" values={{ price: '69 lei' }} />,
    },
  ],
  answerLimit: 1,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  purchaseStatus: true,
  questionCode: QuestionCodes.subscriptionType,
  component: SubscriptionTypeStep,
};

export const EXPERIENCE = {
  question: 'question.whatIsYourExperience',
  questionAdditionalInformation: <FormattedMessage id="label.maximThreeAnswers" values={{ number: 3 }} />,
  questionCode: QuestionCodes.experience,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  answerWithQuestion: true,
  answerLimit: 1,
  image: expertiseImage,
  component: ExperienceStep,
};

export const REWARD = {
  question: 'question.whaIsTheReward',
  questionAdditionalInformation: <FormattedMessage id="label.maximThreeAnswers" values={{ number: 5 }} />,
  questionCode: QuestionCodes.reward,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  answerWithQuestion: true,
  answerLimit: 1,
  image: moneyImage,
  component: RewardStep,
};

export const DESCRIBE_YOURSELF = {
  question: 'question.describeYourself',
  questionCode: QuestionCodes.describeYourself,
  questionAnswerType: SINGLE_ANSWER_TYPE,
  answerWithQuestion: true,
  answerLimit: 1,
  image: describeYourselfImage,
  component: DescribeYourselfStep,
};

export const ACCOUNT_FLOW_FINAL = {
  questionCode: QuestionCodes.confirmation,
  image: partyImage,
  component: ConfirmationStep,
};

export const MAKE_PROFILE_PUBLIC_QUESTION = {
  image: privateImage,
  question: 'question.makeYourProfilePublic',
  answerOptions: [
    {
      label: <FormattedMessage id="label.yes" />,
      value: true,
    },
    {
      label: <FormattedMessage id="label.no" />,
      value: false,
    },
  ],
  answerLimit: 1,
  questionAnswerType: BOOLEAN_ANSWER_TYPE,
  questionCode: QuestionCodes.makeProfilePublic,
  component: ColumnOptionsStep,
};

export const ONBOARDING_FLOW_BY_ROLE = {
  [USER_ROLES.Founder]: [
    LANGUAGE_QUESTION,
    ACCOUNT_TYPE_CHOICE_QUESTION,
    WHAT_BRINGS_YOU_QUESTION,
    WHAT_WOULD_HELP_QUESTION,
    DESCRIBE_YOUR_IDEA_QUESTION,
    HOW_MANY_MEMBERS_QUESTION,
    WHAT_DO_YOU_FOCUS_ON_QUESTION,
    WANT_TO_BE_HELPED_QUESTION,
    WHAT_IS_YOUR_JOB_QUESTION,
    HOW_DID_YOU_HEAR_ABOUT_US_QUESTION,
    FEEDBACK_COLLECTION_QUESTION,
    SUBSCRIPTION_TYPE_CHOICE_QUESTION,
    ACCOUNT_FLOW_FINAL,
  ],
  [USER_ROLES.Mentor]: [
    LANGUAGE_QUESTION,
    ACCOUNT_TYPE_CHOICE_QUESTION,
    DESCRIBE_YOURSELF,
    {
      ...WHAT_BRINGS_YOU_QUESTION,
      answerOptions: [
        { value: 'whatBringsYouHere.option10', label: <FormattedMessage id="whatBringsYouHere.option10" /> },
        {
          value: 'whatBringsYouHere.option11',
          label: <FormattedMessage id="whatBringsYouHere.option11" values={{ appName: APP_NAME }} />,
        },
        { value: 'whatBringsYouHere.option12', label: <FormattedMessage id="whatBringsYouHere.option12" /> },
        {
          value: 'whatBringsYouHere.option13',
          label: <FormattedMessage id="whatBringsYouHere.option13" values={{ appName: APP_NAME }} />,
        },
        { value: 'whatBringsYouHere.option14', label: <FormattedMessage id="whatBringsYouHere.option14" /> },
        { value: 'whatBringsYouHere.option15', label: <FormattedMessage id="whatBringsYouHere.option15" /> },
        { value: 'whatBringsYouHere.option9', label: <FormattedMessage id="whatBringsYouHere.option9" /> },
      ]
    },
    {
      ...WHAT_WOULD_HELP_QUESTION,
      answerOptions: [
        { value: 'whatWouldHelp.option1', label: <FormattedMessage id="whatWouldHelp.option1" /> },
        { value: 'whatWouldHelp.option2', label: <FormattedMessage id="whatWouldHelp.option2" /> },
        { value: 'whatWouldHelp.option3', label: <FormattedMessage id="whatWouldHelp.option3" /> },
        { value: 'whatWouldHelp.option13', label: <FormattedMessage id="whatWouldHelp.option13" /> },
        { value: 'whatWouldHelp.option4', label: <FormattedMessage id="whatWouldHelp.option4" /> },
        { value: 'whatWouldHelp.option5', label: <FormattedMessage id="whatWouldHelp.option5" /> },
        { value: 'whatWouldHelp.option6', label: <FormattedMessage id="whatWouldHelp.option6" /> },
        { value: 'whatWouldHelp.option8', label: <FormattedMessage id="whatWouldHelp.option8" /> },
        { value: 'whatWouldHelp.option17', label: <FormattedMessage id="whatWouldHelp.option17" /> },
        { value: 'whatWouldHelp.option18', label: <FormattedMessage id="whatWouldHelp.option18" /> },
        { value: 'whatWouldHelp.option14', label: <FormattedMessage id="whatWouldHelp.option14" /> },
        { value: 'whatWouldHelp.option16', label: <FormattedMessage id="whatWouldHelp.option16" /> },
      ]
    },
    MAKE_PROFILE_PUBLIC_QUESTION,
    EXPERIENCE,
    REWARD,
    WANT_TO_BE_HELPED_QUESTION,
    WHAT_IS_YOUR_JOB_QUESTION,
    HOW_DID_YOU_HEAR_ABOUT_US_QUESTION,
    FEEDBACK_COLLECTION_QUESTION,
    SUBSCRIPTION_TYPE_CHOICE_QUESTION,
    ACCOUNT_FLOW_FINAL,
  ],
  invitedFounder: [
    LANGUAGE_QUESTION,
    ACCOUNT_TYPE_CHOICE_QUESTION,
    WHAT_BRINGS_YOU_QUESTION,
    WHAT_WOULD_HELP_QUESTION,
    DESCRIBE_YOUR_IDEA_QUESTION,
    WHAT_DO_YOU_FOCUS_ON_QUESTION,
    WANT_TO_BE_HELPED_QUESTION,
    WHAT_IS_YOUR_JOB_QUESTION,
    HOW_DID_YOU_HEAR_ABOUT_US_QUESTION,
    FEEDBACK_COLLECTION_QUESTION,
    SUBSCRIPTION_TYPE_CHOICE_QUESTION,
    ACCOUNT_FLOW_FINAL,
  ]
};
