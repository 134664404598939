import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import { DocumentTitle } from '../../common/components';
import { Loading } from '../../common/styled';
import { Resolver } from '../../core/components';

import { currentTeamIdSelector } from '../../common/store';
import { loadHypotheses } from '../../store/hypothesis/hypothesisSlice';

import { USER_ROLES } from '../../constants/roles';

import BusinessModelCanvasPage from '../../modules/bmc/containers/BusinessModelCanvasPage';

const BusinessModelCanvasPageResolver = (props) => {
  const intl = useIntl();
  const { loadHypotheses, teamId, role, userTeams } = props;
  const { push } = useHistory();

  useEffect(() => {
    if (role === USER_ROLES.Admin) {
      push('/admin/users');
    }
  }, [role, push]);

  const loadDependencies = async () => {
    if (teamId && userTeams && role !== USER_ROLES.Admin) {
      await loadHypotheses(teamId);
    }
  };

  return (
    <>
      <DocumentTitle>{intl.formatMessage({ id: 'label.businessModelCanvas' })}</DocumentTitle>
      <Resolver
        successComponent={BusinessModelCanvasPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

BusinessModelCanvasPageResolver.propTypes = {
  loadHypotheses: PropTypes.func.isRequired,
  teamId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
  userTeams: state.common.userTeams.userTeams,
  role: get(state.account, 'user.role'),
});

const mapDispatchToProps = { loadHypotheses };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessModelCanvasPageResolver)
);
