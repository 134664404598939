import React from 'react';

import { AddIcon } from '../../../assets/icons';

import ButtonComponent from '../ButtonComponent';

const AddButton = ({ onClick, id, p = 1, disabled = false, color = 'primary', size = 'small' }) => (
  <ButtonComponent
    id={id}
    icon={<AddIcon fill='currentColor' width='22px' height='22px' />}
    size={size}
    color={color}
    sx={{ p, minWidth: 'initial' }}
    onClick={onClick}
    disabled={disabled}
  />
);

export default AddButton;
