import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { SaveIconOutlined } from '../../../../assets/icons';

import InputField from '../../../../fields/InputField';

const ChangePasswordForm = ({ onSubmit, isSubmitting }) => {
  const intl = useIntl();

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const schema = Yup.object({
    oldPassword: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    newPassword: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    confirmPassword: Yup.string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField'}))
      .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: 'validate.passwordsNotMatching' })),
  });

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={schema}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField
            id="oldPassword"
            label={intl.formatMessage({ id: 'label.oldPassword' })}
            md={12}
            required
            type="password"
          />
          <InputField
            id="newPassword"
            label={intl.formatMessage({ id: 'label.newPassword' })}
            md={12}
            required
            type="password"
          />
          <InputField
            id="confirmPassword"
            label={intl.formatMessage({ id: 'label.confirmNewPassword' })}
            md={12}
            required
            type="password"
          />

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
              disabled={isSubmitting}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
