import { Avatar, Box, Checkbox, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import AccordionComponent from '../../../../common/components/AccordionComponent';
import AddButton from '../../../../common/components/AddButton';
import SpinnerComponent from '../../../../common/components/Spinner';

import { avatarInitials } from '../../../../utils/formatters';

import TaskDetail from '../TaskDetail';
import { Content, TaskWrapper } from './index.styled';

const Tasks = ({
  tasks,
  handleToggleTask,
  setIsAddTaskModalOpen,
  setPreview,
  isSubmitting,
  isLoadingResources,
}) => {
  const intl= useIntl();

  const openModal = () => {
    setIsAddTaskModalOpen(true);
  };

  const handleClickTask = (task) => setPreview(task, 'TASK');

  return (
    <AccordionComponent
      title={intl.formatMessage({ id: 'label.tasks' })}
      actions={<AddButton onClick={openModal} />}
      pl={0}
    >
      {isLoadingResources && !isSubmitting
        ? <SpinnerComponent size="30px" />
        : (tasks.length > 0
            ? (tasks?.map((task) => (
            <Content key={task._id} isTaskDone={task?.status === 'done'}>
              <Grid container spacing={10}>
                <Grid item xs={11}>
                  <TaskWrapper>
                    <Checkbox
                      size="small"
                      onClick={() => handleToggleTask(task._id, task?.status)}
                      checked={task?.status === 'done'}
                      disabled={isSubmitting}
                    />
                    <Typography
                      variant="body2"
                      onClick={() => handleClickTask(task)}
                    >
                      {task.title}
                    </Typography>
                  </TaskWrapper>
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  {task.userResponsibleId && (
                    <Avatar
                      sx={{
                        height: '20px',
                        width: '20px',
                        fontSize: '10px',
                      }}
                      variant="circular"
                      src={task?.userResponsibleId?.profilePicture}
                    >
                      {avatarInitials(task.userResponsibleId)}
                    </Avatar>
                  )}
                </Grid>
              </Grid>

              <Box display="flex" flexDirection="column" gap={10} ml={25} width="70%" mb={8}>
                {task?.details?.map((detail) => (
                  <TaskDetail detail={detail} key={detail?._id} onPreview={setPreview} />
                ))}
              </Box>
            </Content>
          ))
        ) : (
          <Typography variant="body2" className="italic" color="textSecondary">
            {intl.formatMessage({ id: 'label.emptyTasksSection' })}
          </Typography>
        )
      )}
    </AccordionComponent>
  );
};
export default Tasks;
