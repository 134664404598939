import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import AddCharacteristicsForm from '../AddCharacteristicsForm';

const AddCharacteristicsModal = (props) => {
  const {
    isModalOpen,
    isEditMode,
    onClose,
    onSubmit,
    characteristic,
    isSubmitting,
  } = props;
  const intl = useIntl();
  const title = isEditMode
    ? 'modal.title.editACriteria'
    : 'modal.title.addACriteria';

  return (
    <DialogComponent
      open={isModalOpen}
      title={intl.formatMessage({ id: title })}
      handleClose={onClose}
    >
      <AddCharacteristicsForm
        onSubmit={onSubmit}
        characteristic={characteristic}
        isSubmitting={isSubmitting}
      />
    </DialogComponent>
  );
};

export default AddCharacteristicsModal;
