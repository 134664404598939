import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ClampedBox = styled(
  Typography,
  { shouldForwardProp: (props) => props !== 'lineClamp' },
)(({ lineClamp, color, theme }) =>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: ${lineClamp || 2};
  color: ${color || theme.palette.text.primary };

  span {
    color: ${color || theme.palette.text.primary } !important;
  }

  & > * {
    margin: 0;
  }
`);
