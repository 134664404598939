const swotTypeStrengths = 'strengths';
const swotTypeEntryWeaknesses = 'weaknesses';
const swotTypeOpportunities = 'opportunities';
const swotTypeThreats = 'threats';

export const SWOT_TYPES = {
  strengths: {
    value: swotTypeStrengths,
    label: 'label.strengths',
    drawerName: 'label.strength',
  },
  weaknesses: {
    value: swotTypeEntryWeaknesses,
    label: 'label.weaknesses',
    drawerName: 'label.weakness',
  },
  opportunities: {
    value: swotTypeOpportunities,
    label: 'label.opportunities',
    drawerName: 'label.opportunity',
  },
  threats: {
    value: swotTypeThreats,
    label: 'label.threats',
    drawerName: 'label.threat',
  },
};
