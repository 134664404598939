import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router';

import ButtonComponent from '../../common/components/ButtonComponent';
import DialogAlert from '../../common/components/DialogAlert';

import { useApi } from '../../hooks/useApi';
import { deleteChapterById } from '../../requests/chapters';

import {
  AccordionExpandIcon,
  AddIcon,
  TrashIconOutlined,
  DragIndicator,
  EditIcon,
} from '../../assets/icons';

import Subchapter from './Subchapter';

const ChapterAccordion = ({
  title = '',
  subchapters = [],
  createNewSubchapter,
  chapterId,
  order,
  fetchChapters,
  chaptersProvided,
  isVisible,
  isDragDiabled,
}) => {
  const history = useHistory();

  const [isExpanded, setExpanded] = useState(false);
  const [isDeleteChapterModalOpen, setIsDeleteChapterModalOpen] =
    useState(false);
  const [chapterIdToDelete, setChapterIdToDelete] = useState(null);
  const chapterColor = isVisible ? '#74A3FE' : '#DEE7FC';

  const handleAccordionChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const editChapter = (chapterId, order) => {
    history.push(
      `/admin/mrl/course/form?chapterId=${chapterId}&order=${order}&isEdit=true`
    );
  };

  const [, deleteChapter] = useApi(deleteChapterById);

  const handleOpen = (event, chapterId) => {
    event.stopPropagation();
    setIsDeleteChapterModalOpen(true);
    setChapterIdToDelete(chapterId);
  };

  const onDeleteChapter = async () => {
    const payload = {
      params: { chapterId: chapterIdToDelete },
    };

    await deleteChapter(payload);
    await fetchChapters();
  };

  const handleDeleteChapter = () => {
    onDeleteChapter();
    setIsDeleteChapterModalOpen(false);
    setChapterIdToDelete(null);
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleAccordionChange}
      sx={{
        border: `1px solid ${chapterColor}`,
        // width: '70%',
        minHeight: '64px',
        borderRadius: '6px',
        padding: '0 20px',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<AccordionExpandIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '64px',
        }}
        {...chaptersProvided.dragHandleProps}
      >
        <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          {!isDragDiabled && <DragIndicator fill={chapterColor} />}
          <Typography className={!isVisible ? 'faded' : ''}>{title}</Typography>
        </Box>

        <Box sx={{ display: 'flex', ml: 'auto', mr: '10px' }}>
          <IconButton onClick={() => editChapter(chapterId, order)}>
            <EditIcon width="16" height="18" />
          </IconButton>
          <IconButton
            color="error"
            onClick={(event) => handleOpen(event, chapterId)}
          >
            <TrashIconOutlined width="16" height="18" />
          </IconButton>
        </Box>
      </AccordionSummary>
      <Droppable droppableId={`subchapters-${chapterId}`} type="SUBCHAPTER">
        {(subchaptersProvided) => (
          <AccordionDetails
            sx={{
              borderTop: '1px solid #DEE7FC',
              padding: '20px 10px',
            }}
            ref={subchaptersProvided.innerRef}
            {...subchaptersProvided.droppableProps}
          >
            <Typography variant="h3" color="primary">Subcapitole</Typography>
            <Box sx={{ marginTop: '10px' }}>
              {!subchapters.length ? (
                <Typography className="faded">
                  Adauga un subcapitol facand click pe butonul de mai jos...
                </Typography>
              ) : (
                subchapters.map((subchapter, index) => (
                  <Draggable
                    key={subchapter._id}
                    draggableId={subchapter._id}
                    index={index}
                  >
                    {(subchaptersProvided) => (
                      <Box
                        ref={subchaptersProvided.innerRef}
                        {...subchaptersProvided.draggableProps}
                        {...subchaptersProvided.dragHandleProps}
                        id={subchapter._id}
                      >
                        <Subchapter
                          key={index}
                          data={subchapter}
                          editChapter={() =>
                            editChapter(subchapter._id, subchapter.order)
                          }
                          deleteChapter={(event) =>
                            handleDeleteChapter(event, subchapter._id)
                          }
                        />
                      </Box>
                    )}
                  </Draggable>
                ))
              )}
              {subchaptersProvided.placeholder}
            </Box>
            <Box sx={{ marginTop: '15px' }}>
              <ButtonComponent
                color="secondary"
                onClick={createNewSubchapter}
                icon={AddIcon}
                label="Adauga un subcapitol"
              />
            </Box>
          </AccordionDetails>
        )}
      </Droppable>
      <DialogAlert
        isModalOpen={isDeleteChapterModalOpen}
        onClose={() => setIsDeleteChapterModalOpen(false)}
        title="Confirmare stergere"
        content="Sunteti sigur ca doriti stergerea capitolului?"
        hasActions
        onSubmit={handleDeleteChapter}
      />
    </Accordion>
  );
};

export default ChapterAccordion;
