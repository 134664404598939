import { Box, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import AddButton from '../../../../common/components/AddButton';
import SortButton from '../../../../common/components/SortButton';

import { PROFILE_HYPOTHESIS_PLACEHOLDERS } from '../../constants';
import { getCharacteristicInfo } from '../../utils';

import { NewTabIcon } from '../../../../assets/icons/navbar';

import CharacteristicRow from '../../containers/CharacteristicRow';
import CharacteristicDrawer from '../../containers/CharacteristicsSectionDrawer';
import { TitleWrapper, Root, Header, Content } from './index.styled';

const CharacteristicsSection = ({
  id,
  characteristics,
  onAddClick,
  onSortClick,
  onRefresh,
  type,
  isAllowedToManageHypothesis,
  userStoriesCount,
}) => {
  const intl = useIntl();
  const [direction, setDirection] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { title, IconComponent } = getCharacteristicInfo(type);
  const placeholder = PROFILE_HYPOTHESIS_PLACEHOLDERS?.[type];

  const handleSort = (newDirection) => {
    onSortClick(newDirection);
    setDirection(newDirection);
  };

  const handleOpenDrawer = () => setIsOpen(true);

  const handleCloseDrawer = () => setIsOpen(false);

  return (
    <Root className="scrollbar">
      <Header>
        <TitleWrapper onClick={handleOpenDrawer}>
          {IconComponent && <IconComponent />}
          <Box display="flex" alignItems="flex-end" gap={8}>
            <Typography variant="h2">
              {intl.formatMessage({ id: title })}
            </Typography>
            <NewTabIcon size="20px" />
          </Box>
        </TitleWrapper>
        {isAllowedToManageHypothesis && (
          <Box display="flex" alignItems="center" gap={10}>
            <SortButton isDisabled={!characteristics.length}>
              <MenuItem onClick={() => handleSort('ASC')}>
                <Typography
                  variant="body2"
                  color={direction === 'ASC' ? 'textSecondary' : 'textPrimary'}
                >
                  {intl.formatMessage({ id: 'label.sortAscendingByUserStories' })}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSort('DESC')}>
                <Typography
                  variant="body2"
                  color={direction === 'DESC' ? 'textSecondary' : 'textPrimary'}
                >
                  {intl.formatMessage({ id: 'label.sortDescendingByUserStories', })}
                </Typography>
              </MenuItem>
            </SortButton>

            <AddButton onClick={onAddClick} p={5} />
          </Box>
        )}
      </Header>

      {!characteristics.length && (
        <Typography
          variant="body1"
          className="italic"
          color="textSecondary"
        >
          {placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        </Typography>
      )}

      {!!characteristics.length && (
        <Content id={id} className="scrollbar">
          {characteristics.map((characteristic, index) => (
            <CharacteristicRow
              key={characteristic.id}
              characteristic={characteristic}
              index={index}
              id={id}
              onRefresh={onRefresh}
              canEdit={isAllowedToManageHypothesis}
              userStoriesCount={userStoriesCount}
            />
          ))}
        </Content>
      )}

      {isOpen && (
        <CharacteristicDrawer
          characteristics={characteristics}
          onClose={handleCloseDrawer}
          isOpen={isOpen}
          type={title}
          onRefresh={() => onRefresh({ isLoadingDisabled: true })}
          id={id}
          userStoriesCount={userStoriesCount}
        />
      )}
    </Root>
  );
};

export default CharacteristicsSection;
