import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DocumentTitle } from '../../common/components';
import { Loading } from '../../common/styled';
import { Resolver } from '../../core/components';

import { loadUsers } from '../store';

import UsersPage from './UsersPage';

const UsersPageResolver = (props) => {
  const { loadUsers } = props;
  const loadDependencies = async () => {
    await loadUsers();
  };

  return (
    <>
      <DocumentTitle>Users</DocumentTitle>
      <Resolver
        successComponent={UsersPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

UsersPageResolver.propTypes = {
  loadUsers: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadUsers };

export default withRouter(
  connect(undefined, mapDispatchToProps)(UsersPageResolver)
);
