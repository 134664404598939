import { Typography, Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { TabSvgIcon } from './index.styled';

const TabLabel = ({ tab }) => {
  const intl = useIntl();
  return (
    <Box display="flex" alignItems="center" gap={8}>
      <TabSvgIcon>{tab.icon}</TabSvgIcon>
      <Typography>{intl.formatMessage({ id: tab.label })}</Typography>
    </Box>
  );
};

export default TabLabel;
