import { Box, Typography } from '@mui/material';
import { get, map, size, filter, isEqual } from 'lodash-es';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import HypothesisCard from '../../../../common/components/HypothesisCard';
import PageTitle from '../../../../common/components/PageTitle';
import AddHypothesisModal from '../../../../common/modals/AddHypothesisModal';
import { Loading } from '../../../../common/styled';
import { BmcColumnHeader } from '../../../../common/styled/Headings';
import { TitleButtonsDiv } from '../../../../common/styled/MentorsPage';
import {
  deleteOpportunityAnalysisComment,
  editOpportunityAnalysisComment,
  addOpportunityAnalysisComment,
  editOpportunityAnalysis,
  addOpportunityAnalysisReply,
  editOpportunityAnalysisReply,
  deleteOpportunityAnalysisReply,
} from '../../../../store/contextAnalysis';
import CommentsDrawer from '../../../commentsAndNotifications/components/CommentsDrawer';
import { AddButton } from '../../components/AddButton';
import { BmcSegmentDrawer } from '../../components/BmcSegmentDrawer';

import {
  openModal,
  currentTeamIdSelector,
  setCurrentUserTeam,
} from '../../../../common/store';
import { currentTeamSelector } from '../../../../common/store/userTeamsSelector';
import { getViabilityModelStatus } from '../../../../requests/viabilityModels';
import { isMentorSelector, isAcceleratorSelector } from '../../../../store/auth/login';
import {
  getBMCData,
  loadHypotheses,
  editHypothesis,
  editHypothesesOrder,
  addHypothesis,
} from '../../../../store/hypothesis/hypothesisSlice';
import { createTargetIndicators } from '../../../../store/targetIndicators/targetIndicatorsSlice';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { USER_ROLES } from '../../../../constants/roles';
import { handleApiError } from '../../../../utils/errorUtils';
import { compareOrder } from '../../../../utils/sort';
import { commentTypeBmc } from '../../../commentsAndNotifications/constants';
import { DEFAULT_FILTERS } from '../../constants';
import {
  filterAndUpdateStates, filterAndUpdateSubProperties,
  filterItemsByWeek,
  getCurrentWeekForDate,
  getHypothesisColors,
  getNewHypothesisColor,
  getViabilityModelIcon, groupItemsByColumnBMC,
} from '../../utils';

import { NewTabIcon } from '../../../../assets/icons/navbar';

import BmcFilters from '../BmcFilters';
import {
  BusinessModelCanvasContainer,
  BusinessModelCanvasRow,
  BusinessModelCanvasColumn,
  NoResultsFound,
  HypothesisCardsContainer,
} from './index.styled';

const BusinessModelCanvasPage = (props) => {
  const {
    canvasData,
    isMentor,
    teamId,
    loadHypotheses,
    isAccelerator,
    editHypothesis,
    editHypothesesOrder,
    role,
    hypotheses,
    userProfile,
    addHypothesis,
    createTargetIndicators,
  } = props;
  const intl = useIntl();
  const history = useHistory();
  const SLIDESHOW_DELAY = 1000; // 10s

  const [columns, setColumns] = useState(undefined);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const isDragAndDropActive = !(role === USER_ROLES.Founder);
  const [allowClick, setAllowClick] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [maxWeekNumber, setMaxWeek] = useState(100);
  const [currentWeek, setCurrentWeek] = useState(maxWeekNumber);
  const [startDate, setStartDate] = useState(new Date());
  const [intervalObject, setIntervalObject] = useState(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(undefined);
  const [isHypothesisModalOpen, setIsHypothesisModalOpen] = useState(false);
  const [columnBMCValue, setColumnBMCValue] = useState('');
  const [viabilityModelStatus, setViabilityModelStatus] = useState({});

  const currentUserId = useSelector((state) => state?.userProfile?.userProfile?.userId?._id);
  const bmcComments = useSelector((state) => state.comments[commentTypeBmc]) || [];
  const currentTeam = useSelector(currentTeamSelector);
  const dispatch = useDispatch();

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: commentTypeBmc,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  useEffect(() => {
    if (currentUserId && teamId) {
      fetchComments();
    }
  }, [teamId, currentUserId]);

  useEffect(() => {
    if (teamId) {
      fetchViabilityModelStatus();
    }
  }, [teamId]);

  const fetchViabilityModelStatus = async () => {
    setIsLoading(true);

    try {
      const response = await getViabilityModelStatus(teamId);
      setViabilityModelStatus(response?.data);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenHypothesisModal = (columnBMCValue) => {
    setIsHypothesisModalOpen(true);
    setColumnBMCValue(columnBMCValue);
  };

  const handleCloseHypothesisModal = () => {
    setIsHypothesisModalOpen(false);
  };

  const segmentHypothesis = filter(hypotheses, {
    columnBMC: 'customerSegments',
  });

  const segmentOptions = map(segmentHypothesis, (el) => ({
    label: `${el.title}`,
    value: `${el._id}`,
    color: `${el.color}`,
  }));

  const onCreateHypothesis = async (values) => {
    const usedColors = [];
    hypotheses.forEach((existingHypothesis) => {
      if (existingHypothesis.columnBMC === 'customerSegments') {
        usedColors.push(existingHypothesis.color);
      }
    });

    const { firstName } = userProfile.userProfile;
    const { lastName } = userProfile.userProfile;
    const createdBy = `${firstName} ${lastName}`;
    const payload = {
      title: values.title,
      createdBy,
      teamId,
      description: values.description,
      columnBMC: values.columnBMC,
      importance: values.importance,
      segment: values.segment,
      hypothesisStage: values.hypothesisStage,
      hypothesisCategory: 'dezirabila',
      color: getNewHypothesisColor(usedColors),
      ...(values.subscriptionType ? { subscriptionType: values.subscriptionType } : {}),
    };

    const addedHypothesis = await addHypothesis(payload);

    if (addedHypothesis.status === 200 && values?.targetIndicators?.length > 0) {
      await createTargetIndicators({
        hypothesisId: addedHypothesis.data._id,
        targetIndicators: values?.targetIndicators,
      });
    }

    loadHypotheses(teamId);
    setIsHypothesisModalOpen(false);
  };

  useEffect(() => {
    if (currentWeek === maxWeekNumber && !isPaused && intervalObject) {
      setIsPaused(true);
      setAllowClick(true);
    }
  }, [currentWeek, intervalObject, isPaused, maxWeekNumber]);

  const getReducedWeek = useCallback(
    (inputWeek) => {
      let week = inputWeek;

      if (week > maxWeekNumber) {
        week %= maxWeekNumber;
      }

      if (week === 0) {
        return maxWeekNumber;
      }

      return week;
    },
    [maxWeekNumber]
  );

  const intervalFunction = useCallback(() => {
    setCurrentWeek((x) => getReducedWeek(x + 1)); // currentWeek state cannot be discovered in interval
  }, [isPaused, maxWeekNumber, setIsPaused, setCurrentWeek]);

  useEffect(() => {
    if (!isPaused && !intervalObject) {
      intervalFunction();
      setIntervalObject(setInterval(intervalFunction, SLIDESHOW_DELAY));
    }

    if (isPaused && intervalObject) {
      clearInterval(intervalObject);
      setIntervalObject(undefined);
    }
  }, [isPaused, intervalObject]);

  const getFilteredDataForWeek = useCallback(
    (week) => {
      const newCanvasData = {};

      for (const key of Object.keys(canvasData)) {
        const filteredItems = filterItemsByWeek(canvasData[key], week, startDate);
        const updatedStatesItems = filterAndUpdateStates(filteredItems, week, startDate);
        newCanvasData[key] = filterAndUpdateSubProperties(updatedStatesItems, week, startDate);
      }

      return groupItemsByColumnBMC([].concat(...Object.values(newCanvasData)));
    },
    [startDate, canvasData]
  );

  useEffect(() => {
    if (currentTeam) {
      setStartDate(new Date(currentTeam.createdAt));
    }
  }, [currentTeam]);

  useEffect(() => {
    if (startDate) {
      const currentWeekNumber = getCurrentWeekForDate(new Date(), startDate);
      setMaxWeek(currentWeekNumber);
      setCurrentWeek(currentWeekNumber);
    }
  }, [startDate]);

  const handleOpenDrawer = (segmentName) => {
    if (columns[segmentName].items.length === 0) {
      return;
    }

    setSelectedSegment({
      column: segmentName,
      items: columns[segmentName].items,
    });
    setIsDrawerOpen(true);
  };

  const updateOrder = (list) => {
    const newList = [];

    list.map((item, index) => {
      const newItem = item;
      newItem.order = index;
      newItem.importance = item.importanceValue;
      newList.push(newItem);
      return newItem;
    });

    editHypothesesOrder(newList);
    return newList;
  };

  const updateHypothesis = (values, entityId, currentHypothesis) => {
    const newValues = {
      ...values,
      ...currentHypothesis,
      importance: currentHypothesis.importanceValue,
    };

    return editHypothesis(entityId, newValues);
  };

  const orderHypothesis = (list) => list.sort(compareOrder);

  useEffect(() => {
    const filteredDataForWeek = getFilteredDataForWeek(currentWeek);
    setColumns({
      keyPartners: {
        items: filteredDataForWeek.keyPartners || [],
      },
      channels: {
        items: filteredDataForWeek.channels || [],
      },
      costStructure: {
        items: filteredDataForWeek.costStructure || [],
      },
      customerRelationships: {
        items: filteredDataForWeek.customerRelationships || [],
      },
      customerSegments: {
        items: filteredDataForWeek.customerSegments || [],
      },
      keyActivities: {
        items: filteredDataForWeek.keyActivities || [],
      },
      keyResources: {
        items: filteredDataForWeek.keyResources || [],
      },
      revenueStreams: {
        items: filteredDataForWeek.revenueStreams || [],
      },
      valuePropositions: {
        items: filteredDataForWeek.valuePropositions || [],
      },
    });
  }, [
    teamId,
    canvasData,
    currentWeek,
  ]);

  const getNewWeek = useCallback(
    (decrease = false) => getReducedWeek(currentWeek + (decrease ? -1 : 1)),
    [getReducedWeek, currentWeek]
  );

  const setAllowedProperties = (weekNumber) => {
    setCurrentWeek(weekNumber);
    if (weekNumber === maxWeekNumber) {
      setAllowClick(true);
    } else {
      setAllowClick(false);
    }
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];

      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      removed.columnBMC = result.destination.droppableId;
      destItems.splice(destination.index, 0, removed);
      updateOrder(destItems);
      setColumns((prev) => ({
        ...prev,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      }));
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);

      removed.order = result.destination.index;
      copiedItems.splice(destination.index, 0, removed);
      const orderedItems = updateOrder(copiedItems);
      setColumns((prev) => ({
        ...prev,
        [source.droppableId]: {
          ...prev[source.droppableId],
          items: orderedItems,
        },
      }));
    }
    const currentHypothesis = columns[result.source.droppableId].items[result.source.index];

    updateHypothesis(
      {
        order: result.destination.index,
        columnBMC: result.destination.droppableId,
      },
      result.draggableId,
      currentHypothesis
    );
  };

  const handleViabilityModel = () => history.push('/canvas-models');

  const ViabilityModelIcon = useMemo(() => (
    getViabilityModelIcon(viabilityModelStatus)
  ), [viabilityModelStatus]);

  if (!columns) {
    return;
  }

  const missingFirstCustomerSegment = columns.customerSegments.items.length === 0 && isEqual(filters, DEFAULT_FILTERS);

  return (
    <>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <PageTitle mb={0} title={intl.formatMessage({ id: 'label.businessModelCanvas' })} />

        <Box display="flex" alignItems="center" gap={10}>
          <ButtonComponent
            icon={<ViabilityModelIcon fill='currentColor' size='24px' />}
            size="small"
            variant="outlined"
            color="secondary"
            sx={{ p: 3, minWidth: 'initial' }}
            onClick={handleViabilityModel}
          />

          <CommentsDrawer
            fetchComments={fetchComments}
            type={commentTypeBmc}
            userId={currentUserId}
            teamId={teamId}
            comments={bmcComments}
          />

          <BmcFilters
            isPaused={isPaused}
            setIsPaused={setIsPaused}
            setAllowedProperties={setAllowedProperties}
            setAllowClick={setAllowClick}
            currentWeek={currentWeek}
            setCurrentWeek={setCurrentWeek}
            maxWeekNumber={maxWeekNumber}
            getNewWeek={getNewWeek}
            filters={filters}
            setFilters={setFilters}
          />
        </Box>
      </Box>

      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
        <BusinessModelCanvasContainer>
          <BusinessModelCanvasRow borderBottom height="calc(60% - 10px)">
            <Droppable droppableId="keyPartners">
              {(provided) => (
                <BusinessModelCanvasColumn firstRow singleOnRow>
                  <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                    <BmcColumnHeader
                      isActive={!missingFirstCustomerSegment}
                      hasInfo={columns?.keyPartners?.items?.length !== 0}
                      onClick={() => handleOpenDrawer('keyPartners')}
                    >
                      {intl.formatMessage({ id: 'label.keyPartners' })}
                      <Box display="flex" alignItems="center" mt={5}>
                        <NewTabIcon size="14px" />
                      </Box>
                    </BmcColumnHeader>
                    {!isMentor && !isAccelerator && allowClick && (
                      <AddButton
                        disabled={missingFirstCustomerSegment}
                        id="keyPartners"
                        onClick={() =>
                          handleOpenHypothesisModal('keyPartners')
                        }
                      />
                    )}
                  </TitleButtonsDiv>
                  <HypothesisCardsContainer
                    className="scrollbar"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!size(columns.keyPartners.items) ? (
                      <NoResultsFound />
                    ) : (
                      <>
                        {map(
                          orderHypothesis(columns.keyPartners.items),
                          (hypothesis, index) => (
                            <Draggable
                              isDragDisabled={
                                isDragAndDropActive || !allowClick
                              }
                              key={hypothesis._id}
                              draggableId={hypothesis._id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <HypothesisCard
                                    key={hypothesis._id}
                                    hypothesis={hypothesis}
                                    canvasData={canvasData}
                                    allowClick={allowClick}
                                    backgroundColor={getHypothesisColors(hypothesis)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                      </>
                    )}
                  </HypothesisCardsContainer>
                </BusinessModelCanvasColumn>
              )}
            </Droppable>

            <Box display="flex" flexDirection="column" gap={20} mx={15} width="100%">
              <Droppable droppableId="keyActivities">
                {(provided) => (
                  <BusinessModelCanvasColumn
                    borderBottom
                    noPadding
                    multipleChildren
                    height="calc(50% - 10px)"
                  >
                    <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                      <BmcColumnHeader
                        hasInfo={columns?.keyActivities?.items?.length !== 0}
                        isActive={!missingFirstCustomerSegment}
                        onClick={() => handleOpenDrawer('keyActivities')}
                      >
                        {intl.formatMessage({ id: 'label.keyActivities' })}
                        <Box display="flex" alignItems="center" mt={5}>
                          <NewTabIcon size="14px" />
                        </Box>
                      </BmcColumnHeader>
                      {!isMentor && !isAccelerator && allowClick && (
                        <AddButton
                          disabled={missingFirstCustomerSegment}
                          id="keyActivities"
                          onClick={() =>
                            handleOpenHypothesisModal('keyActivities')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      className="scrollbar"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {!size(columns.keyActivities.items) ? (
                        <NoResultsFound />
                      ) : (
                        <>
                          {map(
                            orderHypothesis(columns.keyActivities.items),
                            (hypothesis, index) => (
                              <Draggable
                                isDragDisabled={
                                  isDragAndDropActive || !allowClick
                                }
                                key={hypothesis._id}
                                draggableId={hypothesis._id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <HypothesisCard
                                      key={hypothesis._id}
                                      hypothesis={hypothesis}
                                      canvasData={canvasData}
                                      allowClick={allowClick}
                                      backgroundColor={getHypothesisColors(hypothesis)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>
              <Droppable droppableId="keyResources">
                {(provided) => (
                  <BusinessModelCanvasColumn noPadding multipleChildren height="calc(50% - 10px)">
                    <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                      <BmcColumnHeader
                        isActive={!missingFirstCustomerSegment}
                        hasInfo={columns?.keyResources?.items?.length !== 0}
                        onClick={() => handleOpenDrawer('keyResources')}
                      >
                        {intl.formatMessage({ id: 'label.keyResources' })}
                        <Box display="flex" alignItems="center" mt={5}>
                          <NewTabIcon size="14px" />
                        </Box>
                      </BmcColumnHeader>
                      {!isMentor && !isAccelerator && allowClick && (
                        <AddButton
                          id="keyResources"
                          disabled={missingFirstCustomerSegment}
                          onClick={() =>
                            handleOpenHypothesisModal('keyResources')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      className="scrollbar"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {!size(columns.keyResources.items) ? (
                        <NoResultsFound />
                      ) : (
                        <>
                          {map(
                            orderHypothesis(columns.keyResources.items),
                            (hypothesis, index) => (
                              <Draggable
                                isDragDisabled={
                                  isDragAndDropActive || !allowClick
                                }
                                key={hypothesis._id}
                                draggableId={hypothesis._id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <HypothesisCard
                                      key={hypothesis._id}
                                      hypothesis={hypothesis}
                                      canvasData={canvasData}
                                      allowClick={allowClick}
                                      backgroundColor={getHypothesisColors(hypothesis)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>
            </Box>
            <Droppable droppableId="valuePropositions">
              {(provided) => (
                <BusinessModelCanvasColumn borderLeft firstRow singleOnRow>
                  <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                    <BmcColumnHeader
                      isActive={!missingFirstCustomerSegment}
                      hasInfo={columns?.valuePropositions?.items?.length !== 0}
                      onClick={() => handleOpenDrawer('valuePropositions')}
                    >
                      {intl.formatMessage({ id: 'label.valuePropositions' })}
                      <Box display="flex" alignItems="center" mt={5}>
                        <NewTabIcon size="14px" />
                      </Box>
                    </BmcColumnHeader>
                    {!isMentor && !isAccelerator && allowClick && (
                      <AddButton
                        disabled={missingFirstCustomerSegment}
                        id="valuePropositions"
                        onClick={() =>
                          handleOpenHypothesisModal('valuePropositions')
                        }
                      />
                    )}
                  </TitleButtonsDiv>
                  <HypothesisCardsContainer
                    className="scrollbar"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!size(columns.valuePropositions.items) ? (
                      <NoResultsFound />
                    ) : (
                      <>
                        {map(
                          orderHypothesis(columns.valuePropositions.items),
                          (hypothesis, index) => (
                            <Draggable
                              isDragDisabled={
                                isDragAndDropActive || !allowClick
                              }
                              key={hypothesis._id}
                              draggableId={hypothesis._id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <HypothesisCard
                                    key={hypothesis._id}
                                    hypothesis={hypothesis}
                                    canvasData={canvasData}
                                    allowClick={allowClick}
                                    backgroundColor={getHypothesisColors(hypothesis)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                      </>
                    )}
                  </HypothesisCardsContainer>
                </BusinessModelCanvasColumn>
              )}
            </Droppable>
            <Box display="flex" flexDirection="column" gap={20} mx={15} width="100%">
              <Droppable droppableId="customerRelationships">
                {(provided) => (
                  <BusinessModelCanvasColumn
                    borderBottom
                    noPadding
                    multipleChildren
                    height="calc(50% - 10px)"
                  >
                    <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                      <BmcColumnHeader
                        isActive={!missingFirstCustomerSegment}
                        hasInfo={columns?.customerRelationships?.items?.length !== 0}
                        onClick={() =>
                          handleOpenDrawer('customerRelationships')
                        }
                      >
                        {intl.formatMessage({ id: 'label.customerRelationships' })}
                        <Box display="flex" alignItems="center" mt={5}>
                          <NewTabIcon size="14px" />
                        </Box>
                      </BmcColumnHeader>
                      {!isMentor && !isAccelerator && allowClick && (
                        <AddButton
                          disabled={missingFirstCustomerSegment}
                          id="customerRelationships"
                          onClick={() =>
                            handleOpenHypothesisModal('customerRelationships')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="scrollbar"
                    >
                      {!size(columns.customerRelationships.items) ? (
                        <NoResultsFound />
                      ) : (
                        <>
                          {map(
                            orderHypothesis(
                              columns.customerRelationships.items
                            ),
                            (hypothesis, index) => (
                              <Draggable
                                isDragDisabled={
                                  isDragAndDropActive || !allowClick
                                }
                                key={hypothesis._id}
                                draggableId={hypothesis._id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <HypothesisCard
                                      key={hypothesis._id}
                                      hypothesis={hypothesis}
                                      canvasData={canvasData}
                                      allowClick={allowClick}
                                      backgroundColor={getHypothesisColors(hypothesis)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>

              <Droppable droppableId="channels">
                {(provided) => (
                  <BusinessModelCanvasColumn noPadding multipleChildren height="calc(50% - 10px)">
                    <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                      <BmcColumnHeader
                        isActive={!missingFirstCustomerSegment}
                        hasInfo={columns?.channels?.items?.length !== 0}
                        onClick={() => handleOpenDrawer('channels')}
                      >
                        {intl.formatMessage({ id: 'label.channels' })}
                        <Box display="flex" alignItems="center" mt={5}>
                          <NewTabIcon size="14px" />
                        </Box>
                      </BmcColumnHeader>
                      {!isMentor && !isAccelerator && allowClick && (
                        <AddButton
                          disabled={missingFirstCustomerSegment}
                          id="channels"
                          onClick={() =>
                            handleOpenHypothesisModal('channels')
                          }
                        />
                      )}
                    </TitleButtonsDiv>
                    <HypothesisCardsContainer
                      className="scrollbar"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {!size(columns.channels.items) ? (
                        <NoResultsFound />
                      ) : (
                        <>
                          {map(
                            orderHypothesis(columns.channels.items),
                            (hypothesis, index) => (
                              <Draggable
                                isDragDisabled={
                                  isDragAndDropActive || !allowClick
                                }
                                key={hypothesis._id}
                                draggableId={hypothesis._id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <HypothesisCard
                                      key={hypothesis._id}
                                      hypothesis={hypothesis}
                                      canvasData={canvasData}
                                      allowClick={allowClick}
                                      backgroundColor={getHypothesisColors(hypothesis)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </>
                      )}
                    </HypothesisCardsContainer>
                  </BusinessModelCanvasColumn>
                )}
              </Droppable>
            </Box>
            <Droppable isDropDisabled droppableId="customerSegments">
              {(provided) => (
                <BusinessModelCanvasColumn borderLeft firstRow singleOnRow>
                  <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                    <BmcColumnHeader
                      isActive={!missingFirstCustomerSegment}
                      hasInfo={columns?.customerSegments?.items?.length !== 0}
                      onClick={() => handleOpenDrawer('customerSegments')}
                    >
                      {intl.formatMessage({ id: 'label.customerSegments' })}
                      <Box display="flex" alignItems="center" mt={5}>
                        <NewTabIcon size="14px" />
                      </Box>
                    </BmcColumnHeader>
                    {!isMentor && !isAccelerator && allowClick && (
                      <AddButton
                        id="customerSegments"
                        onClick={() =>
                          handleOpenHypothesisModal('customerSegments')
                        }
                      />
                    )}
                  </TitleButtonsDiv>
                  <HypothesisCardsContainer
                    className="scrollbar"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {missingFirstCustomerSegment ? (
                      <NoResultsFound>
                        <Typography className="faded">
                          {intl.formatMessage({ id: 'label.noCustomerSegment' })}
                        </Typography>
                        <Typography className="faded" marginTop="20px">
                          {intl.formatMessage({ id: 'label.startByCompletingIdeasBMC' })}
                        </Typography>
                      </NoResultsFound>
                    ) : (
                      <>
                        {map(
                          orderHypothesis(columns.customerSegments.items),
                          (hypothesis, index) => (
                            <Draggable
                              isDragDisabled
                              key={hypothesis._id}
                              draggableId={hypothesis._id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <HypothesisCard
                                    customerSegment="customerSegments"
                                    key={hypothesis._id}
                                    hypothesis={hypothesis}
                                    canvasData={canvasData}
                                    allowClick={allowClick}
                                    backgroundColor={hypothesis.color}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                      </>
                    )}
                  </HypothesisCardsContainer>
                </BusinessModelCanvasColumn>
              )}
            </Droppable>
          </BusinessModelCanvasRow>
          <BusinessModelCanvasRow height="calc(40% - 10px)">
            <Droppable droppableId="costStructure">
              {(provided) => (
                <BusinessModelCanvasColumn lastRow singleOnRow secondRow>
                  <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                    <BmcColumnHeader
                      isActive={!missingFirstCustomerSegment}
                      hasInfo={columns?.costStructure?.items?.length !== 0}
                      onClick={() => handleOpenDrawer('costStructure')}
                    >
                      {intl.formatMessage({ id: 'label.costStructure' })}
                      <Box display="flex" alignItems="center" mt={5}>
                        <NewTabIcon size="14px" />
                      </Box>
                    </BmcColumnHeader>
                    {!isMentor && !isAccelerator && allowClick && (
                      <AddButton
                        disabled={missingFirstCustomerSegment}
                        id="costStructure"
                        onClick={() =>
                          handleOpenHypothesisModal('costStructure')
                        }
                      />
                    )}
                  </TitleButtonsDiv>
                  <HypothesisCardsContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="scrollbar"
                  >
                    {!size(columns.costStructure.items) ? (
                      <NoResultsFound />
                    ) : (
                      <>
                        <Box display="flex" gap={15}>
                          <Box width="50%">
                            {map(
                              orderHypothesis(columns.costStructure.items),
                              (hypothesis, index) =>
                                index % 2 === 0 && (
                                  <Draggable
                                    isDragDisabled={
                                      isDragAndDropActive || !allowClick
                                    }
                                    key={hypothesis._id}
                                    draggableId={hypothesis._id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <HypothesisCard
                                          key={index}
                                          hypothesis={hypothesis}
                                          canvasData={canvasData}
                                          allowClick={allowClick}
                                          backgroundColor={getHypothesisColors(hypothesis)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                            )}
                          </Box>
                          <Box width="50%">
                            {map(
                              orderHypothesis(columns.costStructure.items),
                              (hypothesis, index) =>
                                Math.abs(index % 2) === 1 && (
                                  <Draggable
                                    isDragDisabled={
                                      isDragAndDropActive || !allowClick
                                    }
                                    key={hypothesis._id}
                                    draggableId={hypothesis._id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <HypothesisCard
                                          floatRight
                                          key={index}
                                          hypothesis={hypothesis}
                                          canvasData={canvasData}
                                          allowClick={allowClick}
                                          backgroundColor={getHypothesisColors(hypothesis)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </HypothesisCardsContainer>
                </BusinessModelCanvasColumn>
              )}
            </Droppable>
            <Droppable droppableId="revenueStreams">
              {(provided) => (
                <BusinessModelCanvasColumn lastRow borderLeft singleOnRow>
                  <TitleButtonsDiv marginBottom="15px" alignItems marginTop="12px">
                    <BmcColumnHeader
                      isActive={!missingFirstCustomerSegment}
                      hasInfo={columns?.revenueStreams?.items?.length !== 0}
                      onClick={() => handleOpenDrawer('revenueStreams')}
                    >
                      {intl.formatMessage({ id: 'label.revenueStreams' })}
                      <Box display="flex" alignItems="center" mt={5}>
                        <NewTabIcon size="14px" />
                      </Box>
                    </BmcColumnHeader>
                    {!isMentor && !isAccelerator && allowClick && (
                      <AddButton
                        disabled={missingFirstCustomerSegment}
                        id="revenueStreams"
                        onClick={() =>
                          handleOpenHypothesisModal('revenueStreams')
                        }
                      />
                    )}
                  </TitleButtonsDiv>
                  <HypothesisCardsContainer
                    className="scrollbar"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!size(columns.revenueStreams.items) ? (
                      <NoResultsFound />
                    ) : (
                      <>
                        <Box display="flex" gap={15}>
                          <Box width="50%">
                            {map(
                              columns.revenueStreams.items,
                              (hypothesis, index) =>
                                index % 2 === 0 && (
                                  <Draggable
                                    isDragDisabled={
                                      isDragAndDropActive || !allowClick
                                    }
                                    key={hypothesis._id}
                                    draggableId={hypothesis._id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <HypothesisCard
                                          key={index}
                                          hypothesis={hypothesis}
                                          canvasData={canvasData}
                                          allowClick={allowClick}
                                          backgroundColor={getHypothesisColors(hypothesis)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                            )}
                          </Box>
                          <Box width="50%">
                            {map(
                              columns.revenueStreams.items,
                              (hypothesis, index) =>
                                Math.abs(index % 2) === 1 && (
                                  <Draggable
                                    isDragDisabled={
                                      isDragAndDropActive || !allowClick
                                    }
                                    key={hypothesis._id}
                                    draggableId={hypothesis._id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <HypothesisCard
                                          floatRight
                                          key={index}
                                          hypothesis={hypothesis}
                                          canvasData={canvasData}
                                          allowClick={allowClick}
                                          backgroundColor={getHypothesisColors(hypothesis)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                            )}
                          </Box>
                        </Box>
                        </>
                      )}
                  </HypothesisCardsContainer>
                </BusinessModelCanvasColumn>
              )}
            </Droppable>
          </BusinessModelCanvasRow>
        </BusinessModelCanvasContainer>
      </DragDropContext>

      <BmcSegmentDrawer
        isDrawerOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        selectedSegment={selectedSegment}
      />

      <AddHypothesisModal
        isModalOpen={isHypothesisModalOpen}
        setIsModalOpen={setIsHypothesisModalOpen}
        segmentOptions={segmentOptions}
        handleClose={handleCloseHypothesisModal}
        handleSubmitHypothesis={onCreateHypothesis}
        hypothesisFormValues={{ columnBMC: columnBMCValue }}
      />

      <Loading isLoading={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  hypothesis: state.hypotheses.hypothesis,
  hypotheses: state.hypotheses.hypotheses,
  isLoadingOpportunity: state.opportunityAnalysis.isLoading,
  isEditing: state.opportunityAnalysis.isEditing,
  isCreating: state.opportunityAnalysis.isCreating,
  userTeams: state.common.userTeams.userTeams,
  opportunityAnalysis: state.opportunityAnalysis.opportunityAnalysis,
  isLoading: state.hypotheses.isLoadingHypotheses,
  canvasData: getBMCData(state.hypotheses),
  teamId: currentTeamIdSelector(state.common.userTeams),
  isMentor: isMentorSelector(state.account),
  isAccelerator: isAcceleratorSelector(state.account),
  role: get(state.account, 'user.role'),
  userId: get(state.account, 'user._id'),
  userProfile: state.userProfile,
});

const mapDispatchToProps = {
  openModal,
  loadHypotheses,
  deleteOpportunityAnalysisComment,
  editOpportunityAnalysisComment,
  addOpportunityAnalysisComment,
  editOpportunityAnalysis,
  addOpportunityAnalysisReply,
  editOpportunityAnalysisReply,
  deleteOpportunityAnalysisReply,
  editHypothesis,
  editHypothesesOrder,
  setCurrentUserTeam,
  addHypothesis,
  createTargetIndicators,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessModelCanvasPage)
);
