import { DETAIL_CATEGORIES } from '../../../constants/detail';

import ClientDetail from './ClientDetail';
import ClientMinuteDetail from './ClientMinuteDetail';
import GeneralDetail from './GeneralDetail';
import UserStoryDetail from './UserStoryDetail';

export const getDetailComponent = (category) => {
  if (category === DETAIL_CATEGORIES.client) {
    return {
      DetailComponent: ClientDetail,
      title: 'label.viewClient'
    };
  }

  if (category === DETAIL_CATEGORIES.minuteClient) {
    return {
      DetailComponent: ClientMinuteDetail,
      title: 'label.viewClientMinute'
    };
  }

  if (category === DETAIL_CATEGORIES.userStory) {
    return {
      DetailComponent: UserStoryDetail,
      title: 'label.viewUserStory'
    };
  }

  return {
    DetailComponent: GeneralDetail,
    title: 'label.viewDetail'
  };
};
