import { Box, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import InformationRow from '../../../../common/components/InformationRow';
import GenericComments from '../../../commentsAndNotifications/components/GenericComments';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { DRAWER_TABS } from '../../../../constants/drawerTabs';
import { EXPERIMENT_TYPE_LABELS, TRUST_DATA_LABELS } from '../../../../constants/labels';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';
import { statusPositive } from '../../constants';

import { EditIcon, ThumbsDownIcon, ThumbsUpIcon, TrashIconOutlined } from '../../../../assets/icons';

const ConclusionDrawer = ({
  setPreviewedConclusion,
  onCloseDrawer,
  previewedConclusion,
  onDeleteConclusion,
  onEditConclusion,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);

  const currentUserId = useSelector((state) => state.userProfile.userProfile.userId._id);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities]) || [];
  const hypothesis = useSelector((state) => state.hypotheses.hypothesis);

  const type = EXPERIMENT_TYPE_LABELS?.[previewedConclusion?.experimentType];
  const trust = TRUST_DATA_LABELS[previewedConclusion.dataTrust];

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities,
          entityId: hypothesis._id,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  const handleClose = () => {
    onCloseDrawer();
    setPreviewedConclusion(null);
  };

  const handleBack = () => {
    setPreviewedConclusion(null);
  };

  return (
    <>
      <DrawerTitle
        title={intl.formatMessage({ id: 'label.visualizeConclusion' })}
        onClose={handleClose}
        onBack={handleBack}
      />

      <DrawerTabs
        sx={{ mt: 4 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />

      {activeDrawerTab === DRAWER_TABS.details
        ? (
          <Box display="flex" flexDirection="column" gap={20} my={20}>
            <InformationRow
              label={intl.formatMessage({ id: 'label.summaryConclusion' })}
              value={previewedConclusion.title}
              richText
            />
            <InformationRow
              label={intl.formatMessage({ id: 'label.detailedObservations' })}
              value={previewedConclusion.description}
              richText
            />
            <InformationRow
              label={intl.formatMessage({ id: 'label.trustInData' })}
              value={trust ? intl.formatMessage({ id: trust }): ''}
            />
            <div>
              <Typography variant="body1" color="textSecondary" className="italic">
                {intl.formatMessage({ id: 'label.validatedInvalidatedElement' })}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {previewedConclusion?.status === statusPositive
                  ? <ThumbsUpIcon fill={theme.palette.success.main} size="16px" />
                  : <ThumbsDownIcon fill={theme.palette.error.secondary} size="16px" />}
                <Typography variant="body1" fontWeight={700} ml={10}>
                  {type ? intl.formatMessage({ id: type }) : ''}
                </Typography>
              </Box>
            </div>
          </Box>
        ) : (
          <GenericComments
            fetchComments={fetchComments}
            comments={comments}
            teamId={teamId}
            userId={currentUserId}
            type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities}
            entityId={hypothesis._id}
          />
        )}

      <Box display="flex" alignItems="center" gap={10}>
        <ButtonComponent
          onClick={onEditConclusion}
          variant="outlined"
          color="secondary"
          icon={<EditIcon fill="currentColor" size="14px" />}
          label={intl.formatMessage({ id: 'button.edit' })}
        />
        <ButtonComponent
          onClick={() => onDeleteConclusion(previewedConclusion?._id)}
          icon={<TrashIconOutlined size="16px" />}
          color="error"
          label={intl.formatMessage({ id: 'button.delete' })}
        />
      </Box>
    </>
  );
};

export default ConclusionDrawer;
