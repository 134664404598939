import { styled } from '@mui/material/styles';

export const IndustryWrapper = styled('div')(({ theme }) => `
  border: 1px solid ${theme.palette.general.accentLight};
  padding: ${theme.spacing(14.5, 15)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
`);
