import { Box, IconButton, Typography } from '@mui/material';
import { isNil } from 'lodash-es';

import { Counter } from '../../../assets/theme/styled/Counter';

import { ArrowLeft, CloseIcon } from '../../../assets/icons';

import { TitleWrapper } from './index.styled';

const DrawerTitle = ({ title, counter, onClose, onBack }) => (
  <TitleWrapper>
    <Box display="flex" alignItems="center" gap={8}>
      {onBack && (
        <IconButton onClick={onBack}>
          <ArrowLeft height={17} width={21} />
        </IconButton>
      )}
      <Typography variant="h3" color="primary">
        {title}
      </Typography>
      {!isNil(counter) && <Counter>{counter}</Counter>}
    </Box>
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </TitleWrapper>
);

export default DrawerTitle;
