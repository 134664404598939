import { alpha, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import ButtonComponent from '../../../../common/components/ButtonComponent';

export const Button = styled(
  ButtonComponent,
  { shouldForwardProp: (props) => !['isError', 'isSelected'].includes(props) },
)(({ theme, isError, isSelected }) => `
  padding: ${theme.spacing(6, 10)};
  border-color: ${isSelected ? theme.palette.primary.main :theme.palette.general.borderAccent};
  border-radius: 5px;
  background-color: ${isSelected ? alpha(theme.palette.primary.main, 0.1) : theme.palette.common.white};
  
  & svg path {
    fill: ${isError ? theme.palette.error.secondary : theme.palette.success.main};
  }
  
  &:hover {
    background-color: ${alpha(theme.palette.primary.main, 0.1)};
  }
`);

export const ActionsWrapper = styled(Box)(({ theme }) =>  `
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: space-between;
   gap: ${theme.spacing(20)};
   
   & .MuiAutocomplete-root .MuiInputBase-root {
      padding: 1px 0 1px 4px;
      border-radius: 5px;
   }
`);

export const Root = styled(Box)(() =>  `
   display: flex;
   flex-direction: column;
`);
