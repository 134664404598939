import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router';

import {
  ContactForm,
  EditArticle,
  MrlPage,
  MrlStagePage,
  ResourcesPageResolver,
  UsersPageResolver,
} from '../admin/pages';
import EditChapter from '../admin/pages/EditChapter';
import Page from '../layouts/Page';
import AdminRoute from '../routes/AdminRoute';

const AdminRouter = () => (
  <Page>
    <Switch>
      <AdminRoute exact path="/admin/users" component={UsersPageResolver} />
      <AdminRoute exact path="/admin/mrl" component={MrlPage} />
      <AdminRoute
        exact
        path="/admin/mrl/article/form"
        component={EditArticle}
      />
      <AdminRoute exact path="/admin/mrl/course/form" component={EditChapter} />
      <AdminRoute exact path="/admin/stage/:id" component={MrlStagePage} />
      <AdminRoute
        exact
        path="/admin/resources"
        component={ResourcesPageResolver}
      />
      <AdminRoute exact path="/admin/contact-form" component={ContactForm} />
      <Redirect path="/admin" to={{ pathname: '/admin/users' }} />
    </Switch>
  </Page>
);
export default withRouter(AdminRouter);
