export const INDUSTRY_IMPACT = {
  veryPositive: 'veryPositive',
  positive: 'positive',
  negative: 'negative',
  veryNegative: 'veryNegative',
};

export const INDUSTRY_TYPE = {
  rivalry: 'rivalry',
  entryBarriers: 'entryBarriers',
  providers: 'providers',
  clients: 'clients',
  subtitledProducts: 'subtitledProducts',
};
