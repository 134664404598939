import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Content = styled(Box)(({ theme }) => `
  display: flex;
  gap: ${theme.spacing(8)};
  cursor: pointer;
  
  & a {
    .MuiTypography-root {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    :hover {
      text-decoration: underline;
    }
  }

  & > svg > path {
    fill: ${theme.palette.primary.main};
  }
`);
