import { Popover, useTheme } from '@mui/material';
import { useState } from 'react';

import { SortIcon } from '../../../assets/icons';

import ButtonComponent from '../ButtonComponent';

export const SortButton = ({
  children,
  isDisabled = false,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const popperOpen = Boolean(anchorEl);

  const handleToggleFilters = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <ButtonComponent
        icon={<SortIcon fill='currentColor' size='18px' />}
        size="small"
        color="secondary"
        variant="outlined"
        sx={{ py: 5, px: 6, minWidth: 'initial' }}
        onClick={handleToggleFilters}
        disabled={isDisabled}
      />

      {popperOpen && (
        <Popover
          id="simple-popper"
          open={popperOpen}
          anchorEl={anchorEl}
          onClose={handleToggleFilters}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                padding: 0,
                border: `1px solid ${theme.palette.general.accentLight}`,
              },
            },
          }}
        >
          {children}
        </Popover>
      )}
    </>
  );
};

export default SortButton;
