import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import AccordionComponent from '../../../../common/components/AccordionComponent';
import AddButton from '../../../../common/components/AddButton';

import TaskDetail from '../TaskDetail';

const TaskDetailsList = ({
   task,
   setTaskDetailModalId,
   setPreview,
 }) => {
  const intl= useIntl();

  return (
    <AccordionComponent
      title={intl.formatMessage({ id: 'label.details' })}
      actions={<AddButton onClick={() => setTaskDetailModalId(task?._id)} />}
      pl={0}
    >
      <Box display="flex" flexDirection="column" gap={10} ml={2}>
        {task?.details?.length > 0
          ? task.details.map((detail) => <TaskDetail detail={detail} key={detail?._id} onPreview={setPreview} />)
          : (
            <Typography variant="body2" className="italic" color="textSecondary">
              {intl.formatMessage({ id: 'label.emptyDetailsSection' })}
            </Typography>
          )}
      </Box>
    </AccordionComponent>
  );
};
export default TaskDetailsList;
