import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../common/components/ButtonComponent';
import HtmlEditor from '../../common/components/HtmlEditor';
import { MarginContainer } from '../../modules/mrl/styled/MrlStyled';
import {
  TaskContainer,
  StageTitle,
} from '../styled/MrlStyled';

import { SaveIconOutlined } from '../../assets/icons';

const MrlTask = ({
  title,
  isOpen,
  saveChanges,
  currentDescription,
  setSelectedTask,
  selectedTask,
  exempleLink,
  buttonDescription,
}) => {
  const intl = useIntl();
  const [description, setDescription] = useState(currentDescription);
  const [link, setLink] = useState(exempleLink);
  const [buttonName, setButtonName] = useState(buttonDescription);
  const [taskName, setTaskName] = useState(title);

  const handleSave = () => {
    saveChanges({
      title,
      link,
      description,
      buttonName,
      taskName,
    });
  };

  return (
    <MarginContainer marginBottom="1vh">
      <TaskContainer
        onClick={(event) => {
          if (event.detail === 1) {
            if (!selectedTask) {
              setSelectedTask(title);
              return;
            }
            setSelectedTask(undefined);
            event.stopPropagation();
          }
        }}
      >
        <div>{title}</div>
        <MarginContainer marginRight="1vw">
          {!isOpen ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronUp} />
          )}
        </MarginContainer>
      </TaskContainer>

      {isOpen && (
        <div>
          <StageTitle
            placeholder="Nume task"
            value={taskName}
            onChange={(e) => {
              setTaskName(e.target.value);
            }}
          />
          <HtmlEditor content={description} onChange={setDescription} sx={{ height: '100%', mb: 10 }} />

          <StageTitle
            placeholder="Link buton"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
          />

          <StageTitle
            placeholder="Nume buton"
            value={buttonName}
            onChange={(e) => {
              setButtonName(e.target.value);
            }}
          />

          <MarginContainer
            display="flex"
            justifyContent="flex-end"
            marginTop="1vh"
          >
            <ButtonComponent
              variant="contained"
              color="secondary"
              onClick={handleSave}
              icon={<SaveIconOutlined fill="currentColor" size="18px" />}
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </MarginContainer>
        </div>
      )}
    </MarginContainer>
  );
};

export default MrlTask;
