import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Formik, useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { GridIcon } from '../../../../assets/icons';

const RenderNameField = (props) => {
  const { label, placeholder, type = 'text' } = props;
  const [field, meta] = useField(props);
  return (
    <Grid item xs={12}>
      <TextField
        {...field}
        fullWidth
        label={label}
        type={type}
        placeholder={placeholder}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
      />
    </Grid>
  );
};

const RenderForm = ({ values, handleSubmit }) => {
  const isSaveDisabled = !values.teamCode;
  const intl = useIntl();

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={16}>
        <RenderNameField
          name="teamCode"
          label={intl.formatMessage({ id: 'label.ideaCode' })}
          placeholder={intl.formatMessage({ id: 'placeholder.ideaCode' })}
        />
      </Grid>
      <Box mt={24} display="flex" justifyContent="flex-start">
        <ButtonComponent
          color="seconday"
          disabled={isSaveDisabled}
          type="submit"
          icon={GridIcon}
          label={intl.formatMessage({ id: 'button.save' })}
        />
      </Box>
    </form>
  );
};

const JoinTeamForm = ({ onSubmit }) => {
  const intl = useIntl();

  const handleSubmit = (values) => {
    onSubmit(values.teamCode);
  };

  const schema = Yup.object({
    teamCode: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        teamCode: '',
      }}
    >
      {(props) => <RenderForm {...props} />}
    </Formik>
  );
};

export default JoinTeamForm;
