import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useField } from 'formik';
import { useIntl } from 'react-intl';

export const DropdownField = (props) => {
  const {
    label,
    name,
    options,
    placeholder,
    md = 6,
    multiple = false,
    sx= { marginBottom: '15px' },
    required,
    disabled = false,
  } = props;
  const intl = useIntl();
  const [field, meta, helpers] = useField(props);

  const fieldValue = multiple
    ? Array.isArray(field.value)
      ? field.value
      : []
    : field.value;

  return (
    <Grid item xs={12} md={md} sx={sx}>
      <FormControl fullWidth error={meta.touched && !!meta.error} required={Boolean(required)}>
        <InputLabel id={`${name}-label`} disabled={disabled}>{label}</InputLabel>
        <Select
          {...field}
          disabled={disabled}
          multiple={multiple}
          value={fieldValue}
          label={label}
          labelId={`${name}-label`}
          id={name}
          placeholder={placeholder}
          onChange={(event) => {
            if (multiple && event.target.value.length <= 3) {
              helpers.setValue(event.target.value);
              helpers.setTouched(true);
            } else if (!multiple) {
              helpers.setValue(event.target.value);
              helpers.setTouched(true);
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {meta.touched && meta.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
        {multiple && fieldValue.length > 3 && (
          <FormHelperText>{intl.formatMessage({ id: 'label.maxXOptions' }, { number: 3 })}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};
