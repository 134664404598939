import styled from 'styled-components';

export const ConditionsContainer = styled.div`
  background: #ffffff;
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;
  color: ${(props) => props.theme.blueAccent};
`;

export const ConditionsTitle = styled.h1`
  font-family: 'Raleway', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  margin-top: 0px;
`;

export const ConditionsHeading = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
`;

export const ConditionsText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
`;

export const Anchor = styled.a`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  margin: 0 2px;
`;
