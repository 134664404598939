export const getInitialValues = (detail) => ({
  characteristicsFromCustomerSegmentsNumber: detail?.characteristicsFromCustomerSegments?.length || 0,
  characteristicsFromCustomerSegments: detail?.characteristicsFromCustomerSegments || [],
  tasksFromCustomerSegmentsNumber: detail?.tasksFromCustomerSegments?.length || 0,
  tasksFromCustomerSegments: detail?.tasksFromCustomerSegments || [],
  taskContext: detail?.taskContext || '',
  winsFromCustomerSegmentsNumber: detail?.winsFromCustomerSegments?.length || 0,
  winsFromCustomerSegments: detail?.winsFromCustomerSegments || [],
  painsFromCustomerSegmentsNumber: detail?.painsFromCustomerSegments?.length || 0,
  painsFromCustomerSegments: detail?.painsFromCustomerSegments || [],
  currentSolution: detail?.currentSolution || '',
  otherInfo: detail?.otherInfo || '',
  linksNumber: detail?.links?.length || 0,
  links: detail?.links || [],
});
