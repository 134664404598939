import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useField } from 'formik';
import { get } from 'lodash-es';
import React from 'react';
import DatePicker from 'react-datepicker';

import { Root } from './index.styled';

const DatePickerField = (props) => {
  const { values, name, placeholder, label, setFieldValue } = props;
  const [meta] = useField(props);
  const fieldValue = get(values, name);

  // eslint-disable-next-line react/display-name
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      inputRef={ref}
      value={value}
      label={label}
      placeholder={placeholder}
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} />
          </InputAdornment>
        ),
      }}
    />
  ));

  return (
    <Root>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={fieldValue}
        onChange={(date) => setFieldValue(name, date)}
        customInput={<CustomInput />}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </Root>
  );
};

export default DatePickerField;
