import { styled } from '@mui/material/styles';

export const TabSvgIcon = styled('div')(() => `
  width: 20px;
  height: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
`);
