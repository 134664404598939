/* eslint max-len: 0 */
import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';

import { fadeAndSlideInUp, loadingOverlay } from '../../assets/styles';

export const AccountPanel = styled.div`
  width: 560px;
  animation: ${fadeAndSlideInUp} 0.3s ease-out;

  ${(props) =>
    props.isLoading &&
    css`
      ${loadingOverlay};
    `};
`;

export const AccountPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  background: ${(props) => props.theme.bodyBackgroundColor};
`;

export const IdeeaMemberAvatar = styled(Avatar)`
  background-color: #98a6ad;
  width: ${(props) => `${props.size || 40}px !important;`};
  height: ${(props) => `${props.size || 40}px !important;`};
  border-radius: 50%;
`;
