import { Grid, Typography } from '@mui/material';
import React from 'react';

import { formatRewardUM } from '../../../../utils/formatters';

const RewardDetails = ({ rewardPreference }) => {
  return (
    <Grid container spacing={8}>
      {rewardPreference?.map((reward, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Typography fontWeight={700}>
            {`${reward.title} - ${formatRewardUM(reward)}`}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default RewardDetails;
