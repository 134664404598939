import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

export const ChartFilters = ({
  handleCheck,
  selectedFilters,
  competitorMetrics,
}) => {
  const { competitorList = [] } = competitorMetrics;

  const options = competitorList.map((item) => item.name);

  return (
    <Box display="flex" flexDirection="column">
      {options.map((option) => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              value={option}
              checked={selectedFilters.includes(option)}
              onChange={handleCheck}
              name={option}
              color="primary"
            />
          }
          label={<Typography variant="body1" color="primary">{option}</Typography>}
        />
      ))}
    </Box>
  );
};
