import {
  CREATE_TASK,
  UPDATE_TASK_BY_ID,
  DELETE_TASK_BY_ID,
  GET_ALL_TASKS_BY_TEAM,
  GET_ALL_TASKS_BY_ACTIVITY,
  GET_TASK_DETAILS_BY_ID,
  CREATE_DETAIL,
} from '../constants/apiRoutes';
import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from '../utils/api/Api';

export const createTask = ({ payload }) =>
  runPostApiRequest({
    endpoint: CREATE_TASK,
    params: payload.params,
    payload: payload.taskData,
  });

export const updateTaskById = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: UPDATE_TASK_BY_ID,
    params: payload.params,
    payload: payload.taskData,
  });

export const deleteTaskById = ({ payload }) =>
  runDeleteApiRequest({
    endpoint: DELETE_TASK_BY_ID,
    params: payload.params,
  });

export const getAllTasksByTeam = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_ALL_TASKS_BY_TEAM,
    params: payload.params,
    queryParams: payload.queryParams,
  });

export const getAllTasksByActivity = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_ALL_TASKS_BY_ACTIVITY,
    params: payload.params,
    queryParams: payload.queryParams,
  });

export const getTaskDetailsById = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_TASK_DETAILS_BY_ID,
    params: payload.params,
  });

export const postTaskDetail = ({ payload }) =>
  runPostApiRequest({
    endpoint: CREATE_DETAIL,
    params: payload.params,
    payload: payload.detailData,
  });
