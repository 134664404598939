import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import InviteUser from '../InviteUser';

const AddUserToIdeaModal = ({
  isMentor = false,
  isOpen = false,
  handleClose,
  runUpdateTeamMembers,
  runUpdateTeamMentors,
  teamId,
}) => {
  const intl = useIntl();
  const title = isMentor
    ? 'modal.title.inviteMentor'
    : 'modal.title.inviteMember';

  return (
    <DialogComponent
      handleClose={handleClose}
      open={isOpen}
      title={intl.formatMessage({ id: title })}
    >
      <InviteUser
        isMentor={isMentor}
        teamId={teamId}
        runUpdateTeamMembers={runUpdateTeamMembers}
        runUpdateTeamMentors={runUpdateTeamMentors}
        handleClose={handleClose}
      />
    </DialogComponent>
  );
};

export default AddUserToIdeaModal;
