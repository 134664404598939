
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import Resource from '../../components/Resource';

import { getAllChapters, getChapterById } from '../../../../requests/chapters';

import { getPrevAndNextChapter } from '../../../../admin/utils/chapterUtils';
import { handleApiError } from '../../../../utils/errorUtils';
import { formatCurrentUrl } from '../../../../utils/formatters';

import { ChevronLeft, ChevronRight } from '../../../../assets/icons';

import { Footer } from './index.styled';

const Courses = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const chapterId = query.get('id');

  const [isLoading, setIsLoading] = useState(false);
  const [subChapter, setSubChapter] = useState({});
  const [chapters, setChapters] = useState([]);
  const [previousSubchapter, setPreviousSubchapter] = useState({});
  const [nextSubchapter, setNextSubchapter] = useState({});

  useEffect(() => {
    if (chapterId) {
      fetchChapter();
      fetchChapters();
    }
  }, [chapterId]);

  const fetchChapter = async () => {
    setIsLoading(true);

    try {
      const response = await getChapterById(chapterId);
      setSubChapter(response);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChapters = async () => {
    setIsLoading(true);

    try {
      const payload = { isVisible: true };
      const response = await getAllChapters(payload);
      setChapters(response);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigationClick = (subchapter) => {
    history.push(formatCurrentUrl('id', subchapter._id));
    history.push(formatCurrentUrl('chapterId', subchapter.chapterId));
  };

  const handleNavigationSubchapters = () => {
    const { previous, next } = getPrevAndNextChapter(subChapter, chapters);
    setPreviousSubchapter(previous);
    setNextSubchapter(next);
  };

  useEffect(() => {
    if (!!subChapter._id && !!chapters.length) {
      handleNavigationSubchapters();
    }
  }, [subChapter, chapters]);

  return (
    <>
      <Resource isLoading={isLoading} resource={subChapter} />
      <Footer justifyContent={previousSubchapter?.title ? 'space-between' : 'flex-end'}>
        {previousSubchapter?.title && (
          <ButtonComponent
            label={previousSubchapter.title}
            variant="outlined"
            color="secondary"
            onClick={() => handleNavigationClick(previousSubchapter)}
            icon={<ChevronLeft />}
          />
        )}
        {nextSubchapter?.title && (
          <ButtonComponent
            label={nextSubchapter.title}
            variant="outlined"
            color="secondary"
            onClick={() => handleNavigationClick(nextSubchapter)}
            icon={<ChevronRight />}
            iconPlacement="end"
          />
        )}
      </Footer>
    </>
  );
};

export default Courses;
