/* eslint-disable react/no-render-return-value */
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Normalize } from 'styled-normalize';
import 'react-toastify/dist/ReactToastify.css';

import ErrorBoundary from './common/components/_ErrorBoundary';

import { initFacebookSdk } from './utils/services/initFacebookSdk';

import { getEnv } from './utils/featureFlag';
import interceptor from './utils/interceptor';

import AccordionStyles from './assets/styles/AccordionStyles';
import GlobalStyle from './assets/styles/GlobalStyle';

import packageJson from '../package.json';
import App from './App';
import * as serviceWorker from './serviceWorker';

interceptor();

const startApp = () => {
  const root = document.getElementById('root');
  const reactRoot = createRoot(root);

  if (getEnv() === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    Sentry.init({
      enabled: true,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `${packageJson.name}@${packageJson.version}`,
      integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
        Sentry.browserTracingIntegration({
          tracePropagationTargets: ['localhost', 'https://launchpad.startuplaunchpad.ro'], // Add your URL or API paths
        })
      ],
      environment: getEnv(),
      tracesSampleRate: 1.0,
    });
  }

  reactRoot.render(
    <StrictMode>
      <Normalize />
      <GlobalStyle />
      <AccordionStyles />
      <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
        <App />
      </Sentry.ErrorBoundary>
    </StrictMode>
  );
};

initFacebookSdk().then(startApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
