import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import GenericTable from '../../../../common/components/GenericTable';
import SpinnerComponent from '../../../../common/components/Spinner';
import BillingDetailsModal from '../../components/BillingDetailsModal';
import PaymentsModal from '../../components/PaymentsModal';

import {
  getAllLoggedUserPayments,
  getInvoiceForPayment,
} from '../../../../store/userProfile/userProfileSlice';

import { LEGAL_CUSTOMER_TYPE } from '../../../../constants/general';
import { formatDate } from '../../../../utils/dateHelpers';
import { formatSubscriptionPrice } from '../../../../utils/formatters';
import { PAYMENTS_TABLE_HEADERS } from '../../constants';

import { Paper, PaymentsPageContainer } from './index.styled';

const Payments = ({ nextPaymentData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state?.userProfile?.userProfile) || {};
  const userPayments = useSelector((state) => state?.userProfile?.loggedUserPayments) || [];
  const isLoading = useSelector((state) => state?.userProfile?.isLoadingPayments) || false;
  const [isSubscriptionSelectionOpen, setIsSubscriptionSelectionOpen] = useState(false);
  const [isBillingDetailsOpen, setIsBillingDetailsOpen] = useState(false);

  const { invoiceDetails, userId } = userProfile;
  const { subscription: currentSubscription } = userId;
  const hasActiveSubscription = currentSubscription && !currentSubscription?.cancelAt;

  const hasCancelledSubscription = currentSubscription?.cancelAt;
  const subscriptionValue = currentSubscription?.amountDecimal
    ? `(${formatSubscriptionPrice(currentSubscription?.amountDecimal)})RON`
    : `(${intl.formatMessage({ id: 'label.free' })})`;

  const subscriptionReccurence = useMemo(() => {
    if (currentSubscription?.planRecurringPeriod) {
      return currentSubscription.planRecurringPeriod === 'year'
        ? intl.formatMessage({ id: 'label.yearly' })
        : intl.formatMessage({ id: 'label.monthly' });
    }

    return null;
  }, [currentSubscription?.planRecurringPeriod]);

  const userAddress = useMemo(() => {
    if (invoiceDetails) {
      invoiceDetails.customerType === 'legal' ? invoiceDetails.legalPerson.address : invoiceDetails.individual.address;
    }

    return '';
  }, [invoiceDetails]);

  useEffect(() => {
    dispatch(getAllLoggedUserPayments());
  }, []);

  const handleOpenSubscriptionSelection = () => {
    setIsSubscriptionSelectionOpen(true);
  };

  const getInvoiceById = (paymentId) => {
    dispatch(getInvoiceForPayment(paymentId));
  };

  const rowOnClickHandler = (row, header) => {
    if (header.key === 'actions') {
      return getInvoiceById(row.invoiceId);
    }
  };

  const renderAdditionalTableInformation = () => {
    if (isLoading) {
      return <SpinnerComponent isLoading />;
    }

    if (userPayments.length === 0) {
      return <Typography className="faded">{intl.formatMessage({ id: 'label.noTransactions' })}</Typography>;
    }

    return <Typography className="faded">{intl.formatMessage({ id: 'label.endOfHistory' })}</Typography>;
  };

  return (
    <PaymentsPageContainer className="scrollbar">
      <Box display="flex" gap={16}>
        <Paper elevation={0}>
          <Typography variant="h2" color="primary" mb={10}>
            {intl.formatMessage({ id: 'label.yourSubscription' })}
          </Typography>
          {hasCancelledSubscription && (
            <Typography className="faded">
              {intl.formatMessage({ id: 'label.yourSubscriptionWasCancel' })}
            </Typography>
          )}

          {hasActiveSubscription && (
            <>
              <Typography>
                {`${subscriptionReccurence} ${subscriptionValue}`}
              </Typography>
              <Typography className="faded">
                {intl.formatMessage(
                  { id: 'label.initiatedOn' },
                  {
                    value: formatDate(currentSubscription?.createdAt) || '-',
                  }
                )}
              </Typography>
            </>
          )}

          {!hasActiveSubscription && !hasCancelledSubscription && (
            <Typography className="faded italic">
              {intl.formatMessage({ id: 'label.noPlanSelected' })}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end" mt={8}>
            <ButtonComponent
              color="secondary"
              variant={!hasActiveSubscription && !hasCancelledSubscription ? 'contained' : 'outlined'}
              label={intl.formatMessage({ id: 'button.changePlan' })}
              onClick={handleOpenSubscriptionSelection}
            />
          </Box>
        </Paper>

        <Paper elevation={0}>
          <Typography variant="h2" color="primary" mb={10}>
            {intl.formatMessage({ id: 'label.nextPayment' })}
          </Typography>
          {nextPaymentData ? (
            <>
              <Typography>
                {nextPaymentData.subscriptionPrice} RON
              </Typography>
              <Typography className="faded">{nextPaymentData?.nextPaymentAttempt}</Typography>
            </>
          ) : (
            <Typography className="faded italic">
              {intl.formatMessage({ id: 'label.noPlanSelected' })}
            </Typography>
          )}
        </Paper>
      </Box>

      <Paper elevation={0}>
          <Typography variant="h2" color="primary" mb={20}>
            {intl.formatMessage({ id: 'label.invoiceData'})}
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <Typography color="primary">
                {intl.formatMessage({ id: 'label.paymentMethodDetails' })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="faded">
                {intl.formatMessage({ id: 'label.emptyField' })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <ButtonComponent
                  variant="outlined"
                  color="secondary"
                  label={intl.formatMessage({ id: 'button.edit' })}
                />
              </Box>
            </Grid>

            <Grid item xs={4} alignItems="center">
              <Typography color="primary">
                {intl.formatMessage({ id: 'label.billingAddress' })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {invoiceDetails && invoiceDetails.customerType ? (
                <Typography>{userAddress}</Typography>
              ) : (
                <Typography className="faded">{intl.formatMessage({ id: 'label.emptyField' })}</Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <ButtonComponent
                  variant="outlined"
                  color="secondary"
                  label={intl.formatMessage({ id: 'button.edit' })}
                  onClick={() => setIsBillingDetailsOpen(true)}
                />
              </Box>
            </Grid>

            {invoiceDetails && invoiceDetails.customerType === LEGAL_CUSTOMER_TYPE && (
              <>
                <Grid item xs={4}>
                  <Typography color="primary">
                    {intl.formatMessage({ id: 'label.taxIdentifier' })}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>{invoiceDetails.legalPerson.cui}</Typography>
                </Grid>
              </>
              )}
          </Grid>
      </Paper>

      <Paper elevation={0}>
        <Typography variant="h2" color="primary" mb={20}>
          {intl.formatMessage({ id: 'label.transactionHistory' })}
        </Typography>
        <GenericTable
          headers={PAYMENTS_TABLE_HEADERS}
          rows={isLoading ? [] : userPayments}
          cellOnClick={rowOnClickHandler}
        />
        {renderAdditionalTableInformation()}
      </Paper>

      <PaymentsModal
        isModalOpen={isSubscriptionSelectionOpen}
        setIsModalOpen={setIsSubscriptionSelectionOpen}
        nextPaymentData={nextPaymentData}
      />

      <BillingDetailsModal
        isModalOpen={isBillingDetailsOpen}
        setIsModalOpen={setIsBillingDetailsOpen}
      />
    </PaymentsPageContainer>
  );
};

export default Payments;
