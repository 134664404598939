import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const EllipsisLinkButton = styled(Button)`
  width: ${(props) => props.width};
  text-align: left;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
