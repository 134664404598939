import { Box, Divider, Typography } from '@mui/material';
import { isEqual } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { EXPERIENCE_OPTIONS, experienceOptions } from '../../../../constants/options';

import CheckboxFieldGroup from '../../../../fields/CheckboxFieldGroup';
import MultiSelectField from '../../../../fields/MutiSelectField';

const Filters = ({ filters, onFilter, onToggleFilters }) => {
  const intl = useIntl();
  const [localFilters, setLocalFilters] = useState(filters);
  const expertiseTitles = useMemo(() => {
    const options = experienceOptions.map((option) => ({
      ...option,
      label: intl.formatMessage({ id: option.label }),
    }));
    return [
      { label: intl.formatMessage({ id: 'placeholder.selectOptions' }), value: '' },
      ...options,
    ];
  }, []);

  const handleMultiSelect = ({ target: { value, name } }) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value?.filter((v) => v),
    }));
  };

  const handleCheckboxes = (name, values) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [name]: values,
    }));
  };

  const handleClear = () => setLocalFilters(filters);

  const handleSave = () => {
    if (!isEqual(filters, localFilters)) {
      onFilter(localFilters);
    }
    onToggleFilters();
  };

  return (
    <Box px={16} pt={8} width="250px">
      <Box mb={15}>
        <Typography color="primary" variant="body2" fontWeight={600} sx={{ pb: 10 }}>
          {`${intl.formatMessage({ id: 'label.expertise' })} (${localFilters.expertiseTitles?.length || 0})`}
        </Typography>
        <MultiSelectField
          id="expertiseTitles"
          name="expertiseTitles"
          size="small"
          sx={{ maxWidth: '350px' }}
          displayEmpty
          fullWidth
          options={expertiseTitles}
          value={localFilters.expertiseTitles || ['']}
          onChange={handleMultiSelect}
        />
      </Box>

      <Box mb={15}>
        <Typography color="primary" variant="body2" fontWeight={600} sx={{ pb: 10 }}>
          {`${intl.formatMessage({ id: 'label.experience' })} (${localFilters.expertiseYearRanges?.length || 0})`}
        </Typography>
        <CheckboxFieldGroup
          hideBorder
          onChange={handleCheckboxes}
          name="expertiseYearRanges"
          values={localFilters.expertiseYearRanges || []}
          options={EXPERIENCE_OPTIONS}
        />
      </Box>

      <Divider />

      <Box display="flex" gap={8} my={15}>
        <ButtonComponent
          label={intl.formatMessage({ id: 'button.cancel' })}
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={handleClear}
        />
        <ButtonComponent
          label={intl.formatMessage({ id: 'button.save' })}
          color="secondary"
          fullWidth
          onClick={handleSave}
        />
      </Box>
    </Box>
  );
};

export default Filters;
