import update from 'immutability-helper';

import {
  getAllTargetIndicators as doGetAllTargetIndicators,
  createTargetIndicators as doCreateTargetIndicators,
  updateTargetIndicator as doUpdateTargetIndicator,
  deleteTargetIndicator as doDeleteTargetIndicator,
} from '../../requests/targetIndicators';

const GET_ALL_TARGET_INDICATORS = 'GET_ALL_TARGET_INDICATORS';
const GET_ALL_TARGET_INDICATORS_SUCCESS = 'GET_ALL_TARGET_INDICATORS_SUCCESS';
const GET_ALL_TARGET_INDICATORS_ERROR = 'GET_ALL_TARGET_INDICATORS_ERROR';

const CREATE_TARGET_INDICATORS = 'CREATE_TARGET_INDICATORS';
const CREATE_TARGET_INDICATORS_SUCCESS = 'CREATE_TARGET_INDICATORS_SUCCESS';
const CREATE_TARGET_INDICATORS_ERROR = 'CREATE_TARGET_INDICATORS_ERROR';

const EDIT_TARGET_INDICATOR = 'EDIT_TARGET_INDICATOR';
const EDIT_TARGET_INDICATOR_SUCCESS = 'EDIT_TARGET_INDICATOR_SUCCESS';
const EDIT_TARGET_INDICATOR_ERROR = 'EDIT_TARGET_INDICATOR_ERROR';

const DELETE_TARGET_INDICATOR = 'DELETE_TARGET_INDICATOR';
const DELETE_TARGET_INDICATOR_SUCCESS = 'DELETE_TARGET_INDICATOR_SUCCESS';
const DELETE_TARGET_INDICATOR_ERROR = 'DELETE_TARGET_INDICATOR_ERROR';

const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  targetIndicators: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TARGET_INDICATORS:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ALL_TARGET_INDICATORS_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        targetIndicators: { $set: action.payload },
      });
    case GET_ALL_TARGET_INDICATORS_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });
    case CREATE_TARGET_INDICATORS:
      return update(state, {
        isCreating: { $set: true },
      });
    case CREATE_TARGET_INDICATORS_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case CREATE_TARGET_INDICATORS_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case EDIT_TARGET_INDICATOR:
      return update(state, {
        isEditing: { $set: true },
      });
    case EDIT_TARGET_INDICATOR_SUCCESS:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_TARGET_INDICATOR_ERROR:
      return update(state, {
        isEditing: { $set: false },
      });
    case DELETE_TARGET_INDICATOR:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_TARGET_INDICATOR_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_TARGET_INDICATOR_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });
    default:
      return state;
  }
};

export const getAllTargetIndicators =
  (teamId, hypothesisId) => async (dispatch) => {
    dispatch({ type: GET_ALL_TARGET_INDICATORS });
    try {
      const response = await doGetAllTargetIndicators(teamId, hypothesisId);
      dispatch({
        type: GET_ALL_TARGET_INDICATORS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: GET_ALL_TARGET_INDICATORS_ERROR, payload: error });
    }
  };

export const createTargetIndicators = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_TARGET_INDICATORS });
  try {
    const response = await doCreateTargetIndicators(payload);
    dispatch({
      type: CREATE_TARGET_INDICATORS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: CREATE_TARGET_INDICATORS_ERROR, payload: error });
  }
};

export const editTargetIndicator =
  (targetIndicatorId, values) => async (dispatch) => {
    dispatch({ type: EDIT_TARGET_INDICATOR });
    try {
      const response = await doUpdateTargetIndicator(targetIndicatorId, values);
      dispatch({ type: EDIT_TARGET_INDICATOR_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: EDIT_TARGET_INDICATOR_ERROR, payload: error });
    }
  };

export const deleteTargetIndicator =
  (targetIndicatorId) => async (dispatch) => {
    dispatch({ type: DELETE_TARGET_INDICATOR });
    try {
      const response = await doDeleteTargetIndicator(targetIndicatorId);
      dispatch({
        type: DELETE_TARGET_INDICATOR_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: DELETE_TARGET_INDICATOR_ERROR, payload: error });
    }
  };
