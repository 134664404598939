import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  GET_ACTIVITY_BY_ID,
  GET_ALL_ACTIVITIES_BY_TEAM,
  UPDATE_ACTIVITY,
} from '../constants/apiRoutes';
import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from '../utils/api/Api';

export const getAllActivitiesByTeam = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_ALL_ACTIVITIES_BY_TEAM,
    params: payload.params,
    queryParams: payload.queryParams,
  });

export const createActivity = ({ payload }) =>
  runPostApiRequest({
    endpoint: CREATE_ACTIVITY,
    params: payload.params,
    payload: payload.activityData,
  });

export const updateActivity = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: UPDATE_ACTIVITY,
    params: payload.params,
    payload: payload.activityData,
  });

export const deleteActivity = ({ payload }) =>
  runDeleteApiRequest({
    endpoint: DELETE_ACTIVITY,
    params: payload.params,
  });

export const getActivityById = (activityId) =>
  runGetApiRequest({
    endpoint: GET_ACTIVITY_BY_ID,
    params: { activityId },
  });
