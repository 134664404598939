import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as InfoIconSvg } from '../../assets/icons/info.svg';

export const Step = styled('div')(({ theme, activeStep }) => `
  width: 20px;
  height: 4px;
  background-color: ${activeStep ? theme.palette.primary.main : theme.palette.general.borderGray};
`);

export const ImageBox = styled('div')(({ imageUrl }) => `
  width: 500px;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-image: url(${imageUrl});
`);

export const Root =  styled('div')(({ theme }) => `
  background-color: ${theme.palette.common.white};
`);

export const LoadingState =  styled('div')(({ theme }) => `
  background-color: ${theme.palette.common.white};
   min-height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
`);

export const Main = styled('div')(({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 5% 10%;
  ${theme.breakpoints.up(2000)} {
     padding: 5% 20%;
  };
`);

export const BackgroundImage =  styled('div')(({ theme, imageUrl }) => `
  height: 60vh;
  width: 100%;
  position: absolute;
  top: 10%;
  right: 7%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-image: url(${imageUrl});
  ${theme.breakpoints.up(2000)} {
     right: 15%;
  };
`);

export const Content = styled('div')(({ theme, fullWidth }) => `
  width: ${fullWidth ? '100%' : '70%'};
  z-index: 1001;
  position: unset;
  flex-grow: 1;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  };
`);

export const FeatureRoot = styled('div')(({ theme }) => `
  background-color: ${theme.palette.general.orangeLight};
  padding: ${theme.spacing(20)};
  border-radius: 20px;
`);

export const FeatureSign = styled('span')(({ theme, color }) => `
  margin-right: ${theme.spacing(10)};
  font-weight: 700;
  font-size: 18px;
  color: ${color || theme.palette.common.black};
`);

export const BoxAnswer =  styled('div')(({ theme, selected }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(20, 10)};
  background-color: ${selected ? theme.palette.primary.main : theme.palette.background.main};
  border-radius: ${theme.spacing(20)};
  width: 250px;
  height: 150px;
  text-align: center;
  cursor: pointer;
  
  & .MuiTypography-root {
    color: ${selected ? theme.palette.general.grayLight : theme.palette.primary.main};
  }
`);

export const PillQuestionAnswer =  styled('div')(({ theme, gutterBottom, selected, disabled }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${gutterBottom ? theme.spacing(24) : 8};
  padding: ${theme.spacing(12, 24)};
  background-color: ${selected ? theme.palette.primary.main : theme.palette.background.main};
  color: ${selected ? theme.palette.general.grayLight : theme.palette.primary.main};
  border-radius: 40px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  
  & .MuiTypography-root {
    color: ${selected ? theme.palette.general.grayLight : theme.palette.primary.main};
    
    :hover {
      color: ${disabled ? 'current' : theme.palette.general.grayLight};
    }
  }
  
  :hover {
    background-color: ${disabled ? 'current' : theme.palette.primary.main};

    & .MuiTypography-root {
      color: ${disabled ? 'current' : theme.palette.general.grayLight};
    }
  }
`);

export const InfoIcon = styled(InfoIconSvg)(({ theme }) => `
  svg > path {
    stroke: ${theme.palette.common.black}
  }
`);

export const NavigationContainer = styled('div')(({ hasBackButton }) => `
  display: flex;
  justify-content: ${hasBackButton ? 'space-between' : 'flex-end'};
  z-index: 1001;
  position: relative;
`);

export const InviteButton = styled(IconButton)(({ theme }) => `
  svg > path {
    fill: ${theme.palette.common.white};
  }

  &.MuiButtonBase-root {
    background-color: ${theme.palette.primary.main};
    
    &.Mui-disabled {
      background-color: ${theme.palette.general.grayLight};
    };
    
    :hover {
      background-color: ${theme.palette.primary.secondary};
    }
  };
`);
