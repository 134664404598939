import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router';
import RouterPropTypes from 'react-router-prop-types';

import { renderRouteComponent } from '../utils/featureFlag';

const AuthRoute = ({ isLoggedIn, component: Component, feature, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isLoggedIn && feature) {
        return renderRouteComponent(feature, Component, props);
      }

      return isLoggedIn
        ? <Component {...props} />
        : (
          <Redirect
            to={{ pathname: '/account/login', state: { from: props.location } }}
          />
        );
      }
    }
  />
);

AuthRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.account.isLoggedIn,
});

export default withRouter(connect(mapStateToProps)(AuthRoute));
