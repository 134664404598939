import { Drawer, Popover, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import ChatBotDrawer from '../../modules/chatBot/components/ChatBotDrawer';

import OnboardingDashboard from '../../../modules/tour/components/OnboardingDashboard';

import { getLastContactFormUrl } from '../../../admin/store/contactForm';

import { DEFAULT_FEEDBACK_FORM } from '../../../constants/general';
import { USER_ROLES } from '../../../constants/roles';

import {
  // ChatBotNavIcon,
  CompassNavIcon,
  FeedbackNavIcon,
} from '../../../assets/icons/navbar';
import { ReactComponent as RocketIcon } from '../../../assets/icons/Navigation_Logo.svg';

import { AppLink, Content, Option } from './index.styled';
import NavigationOption from './NavigationOption';
import NavigationRoleOptions from './NavigationRoleOptions';

const Sidebar = ({ onCloseSidebar, isSidebarOpen }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const user = useSelector(state => state.account?.user);
  const contactForm = useSelector(state => state.contactForm?.contactForm);
  const [anchorEl, setAnchorEl] = useState(null);
  const popperOpen = Boolean(anchorEl);

  const handleTogglePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // const [isChatBotDrawerOpen, setIsChatBotDrawerOpen] = useState(false);
  //
  // const openDrawer = () => {
  //   setIsChatBotDrawerOpen(true);
  // };

  useEffect(() => {
    if (user?.role === USER_ROLES.Admin) {
      getLastContactFormUrl();
    }
  }, [user?.role]);

  const handleClickOnboarding = () => {
    onCloseSidebar();
    setAnchorEl(null);
  };

  const renderSidebar = () => (
    <Content className="metismenu side-nav">
      <li onClick={onCloseSidebar}>
        <AppLink to="/" className="side-sub-nav-link">
          <RocketIcon />
        </AppLink>
      </li>

      <NavigationRoleOptions onSelectOption={onCloseSidebar} />

      {/*<Option>*/}
      {/*  <NavigationOption*/}
      {/*    IconComponent={<ChatBotNavIcon />}*/}
      {/*    title='label.assistantAi'*/}
      {/*    onClick={openDrawer}*/}
      {/*  />*/}
      {/*</Option>*/}

      {user?.role !== USER_ROLES.Admin && (
        <>
          <Option id="feedback-form" onClick={onCloseSidebar}>
            <a
              href={contactForm?.formUrl || DEFAULT_FEEDBACK_FORM}
              target="_blank"
              rel="noopener noreferrer"
            >
              <NavigationOption
                IconComponent={<FeedbackNavIcon />}
                title='label.feedback'
              />
            </a>
          </Option>

          <Option>
            <NavigationOption
              onClick={handleTogglePopper}
              IconComponent={<CompassNavIcon />}
              title='label.onboarding'
            />

            <Popover
              open={popperOpen}
              anchorEl={anchorEl}
              onClose={handleTogglePopper}
              marginThreshold={100}
              slotProps={{
                paper: { sx: { border: 0, backgroundColor: 'transparent' }}
              }}
            >
              <OnboardingDashboard onStartTour={handleClickOnboarding} />
            </Popover>
          </Option>
        </>
      )}

      {/*{isChatBotDrawerOpen && (*/}
      {/*  <ChatBotDrawer*/}
      {/*    setDrawerStatus={setIsChatBotDrawerOpen}*/}
      {/*    isOpen={isChatBotDrawerOpen}*/}
      {/*  />*/}
      {/*)}*/}
    </Content>
  );

  if (isTablet && isSidebarOpen) {
    return (
      <Drawer anchor="left" open={isSidebarOpen} onClose={onCloseSidebar}>
        {renderSidebar()}
      </Drawer>
    );
  }

  return renderSidebar();
};

export default Sidebar;
