import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ButtonComponent from '../../../common/components/ButtonComponent';
import HtmlEditor from '../../../common/components/HtmlEditor';
import PageTitle from '../../../common/components/PageTitle';
import {
  MarginContainer,
} from '../../../modules/mrl/styled/MrlStyled';
import MrlTask from '../../components/MrlTask';
import {
  CounterContainer,
  HeadingStage,
  MrlGuideContainer,
  StageTitle,
} from '../../styled/MrlStyled';

import { loadMrl, getMrlById, editMrl } from '../../../modules/mrl/store';

import { DEFAULT_DESCRIPTION } from '../../../constants/general';
import { compareOrder } from '../../../utils/sort';

import { SaveIconOutlined } from '../../../assets/icons';

const MrlStagePage = (props) => {
  const intl = useIntl();
  const [description, setDescription] = useState();
  const [stage, setStage] = useState();
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [columns, setColumns] = useState();
  const history = useHistory();

  const {
    match: {
      params: { id },
    },
    editMrl,
    mrl,
    getMrlById,
  } = props;

  useEffect(() => {
    if (id) {
      getMrlById(id);
    }
  }, [id]);

  useEffect(() => {
    if (mrl) {
      setColumns({ tasks: { items: mrl.tasks } });
      setStage(mrl);
      setDescription(mrl?.description || DEFAULT_DESCRIPTION);
    }
  }, [mrl]);

  const submitHandler = async () => {
    const payload = {
      ...stage,
      descriptionHtml: description,
      description: description,
    };
    await editMrl(payload,id);
    await getMrlById(id);
  };

  const saveChangesOnTask = ({
    title,
    description,
    link,
    buttonName,
    taskName,
  }) => {
    const auxiliar = { ...stage };

    for (const item of auxiliar.tasks) {
      if (item.name === title) {
        item.descriptionHtml = description;
        item.description = description;
        item.link = link;
        item.buttonName = buttonName;
        item.name = taskName;
        break;
      }
    }
    setStage(auxiliar);
    editMrl(auxiliar, id);
  };

  const updateOrder = (list) => {
    const newList = [];

    list.map((item, index) => {
      const newItem = item;
      newItem.order = index;
      newList.push(newItem);
      return newItem;
    });

    const newColumnList = {
      [list[0].columnBMC]: {
        items: newList,
      },
    };
    setColumns((prev) => ({ ...prev, ...newColumnList }));
    editMrl({ ...mrl, tasks: newList }, id);
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];

      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      removed.columnBMC = result.destination.droppableId;
      destItems.splice(destination.index, 0, removed);
      updateOrder(destItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);

      removed.order = result.destination.index;
      copiedItems.splice(destination.index, 0, removed);
      updateOrder(copiedItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const sortItems = (list) => list ? list.sort(compareOrder) : [];

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'label.backToContent' })}
        onBack={history.goBack}
      />
      <MrlGuideContainer className="scrollbar">
        {stage && (
          <>
            <HeadingStage>Etapa</HeadingStage>
            <MarginContainer display="flex" width="100%">
              <CounterContainer>{stage?.order + 1}</CounterContainer>
              <MarginContainer width="100%" marginLeft="10px">
                <StageTitle
                  placeholder="Etapa"
                  value={stage.name}
                  onChange={(e) => {
                    setStage({
                      ...stage,
                      ...{ name: e.target.value },
                    });
                  }}
                />
                <StageTitle
                  placeholder="Subtitlu"
                  value={stage.subtitle}
                  onChange={(e) => {
                    setStage({
                      ...stage,
                      ...{ subtitle: e.target.value },
                    });
                  }}
                />
                <HtmlEditor content={description} onChange={setDescription} sx={{ height: '100%', mb: 10 }} />
              </MarginContainer>
            </MarginContainer>
            <MarginContainer
              display="flex"
              justifyContent="flex-end"
            >
              <ButtonComponent
                variant="contained"
                color="secondary"
                onClick={submitHandler}
                icon={<SaveIconOutlined fill="currentColor" size="18px" />}
                label={intl.formatMessage({ id: 'button.save' })}
              />
            </MarginContainer>
          </>
        )}

        <>
          <HeadingStage>Taskuri</HeadingStage>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            <Droppable droppableId="tasks">
              {(provided) => (
                <MarginContainer display="block">
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {sortItems(columns?.tasks?.items).map((item, index) => (
                      <Draggable
                        key={item?._id}
                        draggableId={item?._id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item?._id}
                            onClick={() => {
                              setSelectedTask(item);
                            }}
                          >
                            <MrlTask
                              title={item?.name}
                              exempleLink={item?.link}
                              buttonDescription={item?.buttonName}
                              currentDescription={item?.description}
                              saveChanges={saveChangesOnTask}
                              setSelectedTask={setSelectedTask}
                              selectedTask={selectedTask}
                              isOpen={selectedTask && selectedTask?.name === item?.name}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                </MarginContainer>
              )}
            </Droppable>
          </DragDropContext>
        </>
      </MrlGuideContainer>
    </>
  );
};

MrlStagePage.propTypes = {
  match: RouterPropTypes.match,
};

const mapDispatchToProps = {
  loadMrl,
  getMrlById,
  editMrl,
};

const mapStateToProps = (state) => ({
  mrl: state.mrl.currentMrl,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MrlStagePage)
);
