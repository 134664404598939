import { http } from '../core/services/http';

export const loadMentors = (teamId, params) =>
  http.get(`/profile/get-all-mentors/${teamId}`, { params });

export const deleteMentor = (teamId, email) => (
  http.put(`/team/${teamId}/remove-mentor/${email}`)
);

export const resendInvitation = (teamId, email) => (
  http.post(`/team/${teamId}/mentor-invitation/resend-email/${email}`)
);

export const addMentorInvitation = (mentorId, teamId) => {
  const values = {
    mentorId,
    teamId,
  };
  return http.post('/invitations/add', values);
};

export const deleteInvitation = (invitationId) =>
  http.delete(`/invitations/delete/${invitationId}`);

export const loadInvitations = (mentorId) =>
  http.get(`/invitations/get-all/${mentorId}`);

export const acceptSehInvitation = (invitationId) =>
  http.post(`/seh-invitation/accept/${invitationId}`);

export const refuseSehInvitation = (invitationId) =>
  http.delete(`/seh-invitation/refuse/${invitationId}`);
