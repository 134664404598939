import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogAlert from '../../../../common/components/DialogAlert';
import { editConclusion } from '../../../../store/contextAnalysis/contextAnalysisSlice';
import AddConclusionModal from '../../components/AddConclusionModal';

import { currentTeamIdSelector } from '../../../../common/store';
import { getTeam } from '../../../../store/idea/teamSlice';

import {
  AddIcon,
  EditIcon,
  ThumbsUpIcon,
  TrashIconOutlined
} from '../../../../assets/icons';

import { TitleWrapper } from './index.styled';

const ConclusionPage = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const teamConclusions = useSelector((state) => (
    state.team.team && state.team.team.analysisConclusion || {}
  ));
  const {
    description = '',
    title = '',
    isTrendFavorable,
  } = teamConclusions || {};

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleModalStatus = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleGetTeam = async () => {
    await dispatch(getTeam(teamId));
  };

  const handleSubmitConclusion = async (formValues) => {
    const payload = { analysisConclusion: formValues };
    await dispatch(editConclusion(payload, teamId));
    handleModalStatus();
    handleGetTeam();
  };

  const handleOpenDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteConclusion = async () => {
    setIsSubmitting(true);
    const payload = {
      analysisConclusion: {
        description: '',
        title: '',
      },
    };

    await dispatch(editConclusion(payload, teamId));
    await handleGetTeam();
    setIsDeleteOpen(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (teamId) {
      handleGetTeam();
    }
  }, []);

  return (
    <>
      {description && title ? (
        <Grid
          container
          spacing={16}
          direction="column"
          sx={{ width: '50%', margin: '0 auto', padding: 0 }}
        >
          <TitleWrapper isTrendFavorable={isTrendFavorable}>
            <Box display="flex" alignItems="center" gap={8}>
              <Box sx={!isTrendFavorable ? { transform: 'scaleY(-1)' } : {}}>
                <ThumbsUpIcon fill={isTrendFavorable ? theme.palette.success.main : theme.palette.error.secondary} />
              </Box>
              <Typography variant="h2" fontWeight={400}>
                {title}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={4}>
              <IconButton sx={{ color: 'general.accentLight' }} onClick={handleModalStatus} id="conclusionButton">
                <EditIcon size="18px" />
              </IconButton>
              <IconButton color="error" onClick={handleOpenDelete}>
                <TrashIconOutlined />
              </IconButton>
            </Box>
          </TitleWrapper>
          <Typography variant="body1" color="primary" pt={20}>
            {description}
          </Typography>
        </Grid>
      ) : (
        <>
          <Typography className="italic" color="textSecondary" mb={20}>
            {intl.formatMessage({ id: 'label.addConclusionByClicking' })}
          </Typography>
          <ButtonComponent
            id="conclusionButton"
            variant="contained"
            color="secondary"
            onClick={handleModalStatus}
            icon={<AddIcon fill="currentColor" width="18px" height="18px" />}
            label={intl.formatMessage({ id: 'button.addConclusion' })}
          />
        </>
      )}
      <AddConclusionModal
        teamId={teamId}
        teamConclusions={teamConclusions}
        handleSubmitConclusion={handleSubmitConclusion}
        isModalOpen={isModalOpen}
        setIsModalOpen={handleModalStatus}
      />

      {isDeleteOpen && (
        <DialogAlert
          isModalOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteConclusion' })}
          hasActions
          isDisabled={isSubmitting}
          onSubmit={handleDeleteConclusion}
        />
      )}
    </>
  );
};

export default ConclusionPage;
