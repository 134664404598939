import { isNil } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { DESCRIPTION_LONG_MAX_SIZE } from '../../constants/general';
import { checkNumberMax, checkNumberMin } from './utils';

import { getDescriptionSchema } from './genericSchema';

export const detailClientSchema = Yup.object({
  category: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  title: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  phone: Yup.string(),
  emailsNumber: Yup.number(),
  emails: Yup.array().of(
    Yup.object({
      email: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />)
    })
  ),
  residence: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE),
  company: Yup.string(),
  role: Yup.string(),
  clientStage: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  revenueStreamsHypothesisId: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  customerRelationshipsHypothesisId: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  otherInfo: Yup.string(),
  cost: Yup.string(),
  clientSourceInfo: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE),
  tag: Yup.string(),
  socialMediaLinksNumber: Yup.number(),
  socialMediaLinks: Yup.array().of(
    Yup.object({
      name: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />)
    })
  ),
  linksNumber: Yup.number(),
  links: Yup.array().of(
    Yup.object({
      name: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    })
  ),
});

export const detailGeneralSchema = Yup.object({
  category: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  title: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  description: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE),
  cost: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  status: Yup
    .string()
    .required(<FormattedMessage id='validate.mandatoryField' />),
  linksNumber: Yup.number(),
  links: Yup.array().of(
    Yup.object({
      name: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    })
  ),
  // TODO: to be enabled in future -> isPublic: Yup.boolean(),
});

export const detailClientMinuteSchema = Yup.object({
  title: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  description: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE),
  cost: Yup.string(),
  clientStage: Yup
    .string()
    .required(<FormattedMessage id='validate.mandatoryField' />),
  nextStepsNumber: Yup.number(),
  nextSteps: Yup.array().of(
    Yup.object({
      name: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    })
  ),
  linksNumber: Yup.number(),
  links: Yup.array().of(
    Yup.object({
      name: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    })
  ),
});

const profileSegmentSchema = Yup.object({
  id: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  intensity: Yup
    .string()
    .test(
      'min-number-size',
      <FormattedMessage id='validate.pickNumberBiggerThanOrEqual' values={{ number: 1 }} />,
      (value) => isNil(value) || checkNumberMin(value, 1),
    )
    .test(
      'max-number-size',
      <FormattedMessage id='validate.pickNumberLessOrEqual' values={{ number: 5 }} />,
      (value) => isNil(value) || checkNumberMax(value, 5),
    )
    .test(
      'isInteger',
      <FormattedMessage id='validate.pickInteger' />,
      (value) => isNil(value) || Number.isInteger(+value),
    )
    .required(<FormattedMessage id='validate.mandatoryField' />),
});

export const detailUserStorySchema = Yup.object({
  characteristicsFromCustomerSegments: Yup.array().of(Yup.object({
    id: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  })),
  tasksFromCustomerSegments: Yup.array().of(profileSegmentSchema),
  taskContext: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE),
  winsFromCustomerSegments: Yup.array().of(profileSegmentSchema),
  painsFromCustomerSegments: Yup.array().of(profileSegmentSchema),
  currentSolution: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE),
  otherInfo: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE),
  linksNumber: Yup.number(),
  links: Yup.array().of(
    Yup.object({
      name: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
    })
  ),
});
