import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PageTitle from '../../../../common/components/PageTitle';
import Tabs from '../../../../common/components/Tabs';
import { TabContent } from '../../../../common/components/Tabs/index.styled';
import CommentsDrawer from '../../../commentsAndNotifications/components/CommentsDrawer';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { BMC_MODELS_BY_TAB } from '../../../commentsAndNotifications/constants';
import { BMC_MODELS_TABS } from '../../constants';

import TerminalsModel from '../../../bmcModelsTerminals/containers/TerminalsModel';
import ViabilityModel from '../../../bmcModelsViability/containers/ViabilityModel';

const BmcModelsPage = () => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const tabFromQuery = query.get('tab') || 'viabilityModel';
  const [activeTab, setActiveTab] = useState(tabFromQuery);
  const currentUserId = useSelector((state) => (
    state.userProfile.userProfile && state.userProfile.userProfile.userId._id
  ));
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const contextAnalysisComments = useSelector((state) => (
    state.comments[BMC_MODELS_BY_TAB[activeTab]]
  )) || [];

  useEffect(() => {
    const queryTab =
      new URLSearchParams(location.search).get('tab') || 'viabilityModel';
    setActiveTab(queryTab);
  }, [location]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/canvas-models?tab=${tab}`);
  };

  const pageComponents = {
    viabilityModel: {
      title: intl.formatMessage({ id: 'label.viabilityModel' }),
      component: <ViabilityModel />,
    },
    terminalsModel: {
      title: intl.formatMessage({ id: 'label.terminalsModel' }),
      component: <TerminalsModel />,
    },
  };

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: BMC_MODELS_BY_TAB[activeTab],
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  const handleBack = () => history.push('/canvas');

  useEffect(() => {
    if (!teamId || !currentUserId) return;
    fetchComments();
  }, [teamId, currentUserId, activeTab]);

  return (
    <>
      <Box display="flex" alignItems="flexStart" justifyContent="space-between">
        <PageTitle
          title={intl.formatMessage(
            { id: 'label.businessModelCanvasType' },
            { type: pageComponents[activeTab]?.title }
          )}
          onBack={handleBack}
        />
        <CommentsDrawer
          fetchComments={fetchComments}
          type={BMC_MODELS_BY_TAB[activeTab]}
          teamId={teamId}
          userId={currentUserId}
          comments={contextAnalysisComments}
        />
      </Box>

      <Tabs
        tabs={BMC_MODELS_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
        id="bmc-models"
      />

      <TabContent className="scrollbar">
        {pageComponents[activeTab].component}
      </TabContent>
    </>
  );
};

export default BmcModelsPage;
