import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const TourImageComponent = ({ title, text = [], image }) => {
  const intl = useIntl();
  const currentUser = useSelector((state) => state?.userProfile?.userProfile);
  const { firstName } = currentUser;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'start',
      }}
    >
      <img src={image} alt="rocket" height="200px" width="200px" />
      <Typography variant="h2" color="primary" my={15}>
        {intl.formatMessage({ id: title }, { name: firstName })}
      </Typography>

      <Box display="flex" flexDirection="column" gap={10}>
        {text.map((piece, idx) => (
          <Typography variant="body1" color="primary" key={idx} style={{ color: 'black' }}>
            {intl.formatMessage({ id: piece })}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
