import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CreateTeamModal from '../../../../common/modals/CreateTeamModal';
import FirstIdeaComponent from '../../components/FirstIdeaComponent';
import IdeaDetailsSection from '../../components/IdeaDetailsSection';

import { useApi } from '../../../../hooks/useApi';
import {
  getMyIdeeaMembers,
  getMyIdeeaMentors,
} from '../../../../requests/myIdeea';

import { canEditIdea } from '../../../../utils/permissions';

import MembersBreakdownSection from '../MembersBreakdownSection';

const IdeaDescription = ({ currentTeam = {} }) => {
  const [isAddIdeaModalOpen, setIsAddIdeaModalOpen] = useState(false);
  const { _id: teamId } = currentTeam;
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const isAllowedToEdit = canEditIdea(loggedUser);

  const [{ result: teamMembers }, runGetTeamMembers] = useApi(getMyIdeeaMembers);
  const [{ result: teamMentors }, runGetTeamMentors] = useApi(getMyIdeeaMentors);

  const updateTeamMembers = async () => {
    await runGetTeamMembers({
      params: {
        teamId: teamId,
      },
    });
  };

  const updateTeamMentors = async () => {
    await runGetTeamMentors({
      params: {
        teamId: teamId,
      },
    });
  };

  useEffect(() => {
    if (!teamId) return;

    updateTeamMembers();
    updateTeamMentors();
  }, []);

  return (
    <>
      <Grid container spacing={20}>
        <Grid item xs={12} lg={6}>
          {currentTeam?._id ? (
            <IdeaDetailsSection
              canEdit={isAllowedToEdit}
              team={currentTeam}
              setIsAddIdeaModalOpen={setIsAddIdeaModalOpen}
            />
          ) : (
            <FirstIdeaComponent canEdit={isAllowedToEdit} setIsModalOpen={setIsAddIdeaModalOpen} />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <MembersBreakdownSection
            members={teamMembers}
            mentors={teamMentors}
            currentTeam={currentTeam}
            runUpdateTeamMembers={updateTeamMembers}
            runUpdateTeamMentors={updateTeamMentors}
          />
        </Grid>
      </Grid>

      {isAddIdeaModalOpen && (
        <CreateTeamModal
          isModalOpen={isAddIdeaModalOpen}
          setIsModalOpen={setIsAddIdeaModalOpen}
          team={currentTeam}
          isEditMode={!!currentTeam?._id}
        />
      )}
    </>
  );
};

export default IdeaDescription;
