import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import InformationRow from '../../../../common/components/InformationRow';
import LinkComponent from '../../../../common/components/LinkComponent';
import ExperienceDetails from '../../components/ExperienceDetails';
import RewardDetails from '../../components/RewardDetails';

import { USER_ROLE_NAMES, USER_ROLES } from '../../../../constants/roles';
import { formatDate } from '../../../../utils/dateHelpers';
import { formatUserName } from '../../../../utils/formatters';

import { ExternalLink } from '../../../../assets/icons';
import { EditIcon } from '../../../../assets/icons';
import { theme } from '../../../../assets/styles';

import ProfileModal from '../ProfileDialog';
import { Role, ProfileAvatar } from './index.styled';

const UserDetails = ({ userProfile }) => {
  const intl = useIntl();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const role = userProfile?.userId?.role || USER_ROLES.Founder;

  return (
    <>
      <Box display="flex" alignItems="center" gap={10} mb={40}>
        <ProfileAvatar src={userProfile?.profilePicture} variant="square" />
        <Box display="flex" flexDirection="column" gap={10}>
          {userProfile && (
            <Typography variant="h2" color="primary">
              {formatUserName(userProfile)}
            </Typography>
          )}
          <Role role={role}>
            <Typography>
              {intl.formatMessage({ id: USER_ROLE_NAMES[role] })}
            </Typography>
          </Role>
        </Box>
      </Box>

      <Box>
        <InformationRow
          sx={{ mb: 20 }}
          value={userProfile?.email}
          label={intl.formatMessage({ id: 'label.email' })}
          placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
        />
      </Box>

      <Box>
        <InformationRow
          sx={{ mb: 20 }}
          value={userProfile?.title}
          label={intl.formatMessage({ id: 'label.title' })}
          placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
        />
      </Box>

      <Box>
        <InformationRow
          sx={{ mb: 20 }}
          value={userProfile?.about}
          label={intl.formatMessage({ id: 'label.description' })}
          placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
        />
      </Box>

      <Grid container mb={20} spacing={16}>
        <Grid item xs={12} md={6}>
          <InformationRow
            value={userProfile?.birthDate ? formatDate(userProfile.birthDate) : null}
            label={intl.formatMessage({ id: 'label.dbo' })}
            placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InformationRow
            value={userProfile?.address}
            label={intl.formatMessage({ id: 'label.location' })}
            placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
          />
        </Grid>

        <Grid item xs={12}>
          <InformationRow
            value={userProfile?.phone}
            label={intl.formatMessage({ id: 'label.phone' })}
            placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
          />
        </Grid>

        {role === USER_ROLES.Mentor && (
          <Grid item xs={12} md={6}>
            <Box>
              <Typography className="italic" color="textSecondary">
                {intl.formatMessage({ id: 'label.calendarLink' })}
              </Typography>
              {userProfile.appointmentsCalendar ? (
                <LinkComponent domain={userProfile.appointmentsCalendar} />
              ) : (
                <Typography>
                  {intl.formatMessage({ id: 'label.uncompleted' })}
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={role === USER_ROLES.Mentor ? 6 : 12}>
          <Box>
            <Typography className="italic" color="textSecondary">
              {intl.formatMessage({ id: 'label.linkedinProfile' })}
            </Typography>
            {userProfile?.linkedIn ? (
              <Box sx={{ display: 'flex', gap: 5, cursor: 'pointer' }}>
                <a href={userProfile.linkedIn} target="_blank" rel="noreferrer">
                  <Typography>{userProfile.linkedIn}</Typography>
                </a>
                <ExternalLink fill={theme.accentLight} />
              </Box>
            ) : (
              <Typography>
                {intl.formatMessage({ id: 'label.uncompleted' })}
              </Typography>
            )}
          </Box>
        </Grid>
        {role === USER_ROLES.Mentor && (
          <>
            <Grid item xs={12}>
              {!userProfile?.expertise?.length ? (
                <InformationRow
                  label={intl.formatMessage({ id: 'label.expertise' })}
                  placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
                />
              ) : (
                <>
                  <Typography className="italic" color="textSecondary">
                    {intl.formatMessage({ id: 'label.expertise' })}
                  </Typography>
                  {userProfile?.expertise?.map((expertise, index) => (
                    <ExperienceDetails key={index} experience={expertise} />
                  ))}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {!userProfile?.rewardPreference?.length ? (
                <InformationRow
                  label={intl.formatMessage({ id: 'label.rewardPreference' })}
                  placeholder={intl.formatMessage({ id: 'label.uncompleted' })}
                />
              ) : (
                <>
                  <Typography className="italic" color="textSecondary">
                    {intl.formatMessage({ id: 'label.rewardPreference' })}
                  </Typography>
                  <RewardDetails rewardPreference={userProfile?.rewardPreference} />
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>

      <ButtonComponent
        sx={{ my: 20 }}
        onClick={() => setIsProfileModalOpen(true)}
        icon={<EditIcon />}
        label={intl.formatMessage({ id: 'button.edit' })}
        variant="outlined"
        color="secondary"
      />

      <ProfileModal
        isModalOpen={isProfileModalOpen}
        setIsModalOpen={setIsProfileModalOpen}
        role={role}
      />
    </>
  );
};

export default UserDetails;
