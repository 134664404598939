import { Box } from '@mui/material';
import React from 'react';

import AddButton from '../../../../common/components/AddButton';
import FilterButton from '../../../../common/components/FilterButton';

import { TrendFilters } from '../TrendFilters';

const TrendsPageHeader = ({
  selectedFilters,
  onCheck,
  openAddTrendModal,
  isFilterVisible,
}) => {
  const getFilterCount = () => {
    let count = 0;
    Object.values(selectedFilters).forEach((checked) => {
      count += checked ? 1 : 0;
    });
    return count;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {isFilterVisible && (
        <FilterButton counter={getFilterCount()} sx={{ mr: 10 }}>
          {() => (
            <TrendFilters onCheck={onCheck} selectedFilters={selectedFilters} />
          )}
        </FilterButton>
      )}

      <AddButton
        id="context-analysis-4"
        p={5}
        onClick={openAddTrendModal}
      />
    </Box>
  );
};

export default TrendsPageHeader;
