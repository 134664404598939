import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleWrapper = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'isTrendFavorable' }
)(({ theme, isTrendFavorable }) =>  `
  border-bottom: 2px solid ${isTrendFavorable 
    ? theme.palette.success.main
    : theme.palette.error.secondary
  };
  paddingBottom: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`);
