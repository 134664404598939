import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogAlert from '../../../../common/components/DialogAlert';
import { Loading } from '../../../../common/styled';
import AddViabilityModelDialog from '../../components/AddViabilityModelDialog';
import EmptySegments from '../../components/EmptySegments';
import FunnelChart from '../../components/FunnelChart';
import HypothesisSection from '../../components/HypothesisSection';

import { currentTeamIdSelector } from '../../../../common/store';
import { notifySuccess } from '../../../../core/store';
import { editViabilityModel, getViabilityModel, removeViabilityModelData } from '../../../../requests/viabilityModels';

import { handleApiError } from '../../../../utils/errorUtils';
import { canManageBMC } from '../../../../utils/permissions';
import { formatValuesForServer, getGroupedIndicators } from '../../utils';

import { EditIcon, TrashIconOutlined } from '../../../../assets/icons';

import { Root } from './index.styled';

const DialogType = {
  Edit: 'EDIT',
  Delete: 'Delete',
};

const ViabilityModel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dialogType, setDialogType] = useState(DialogType.Edit);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [model, setViabilityModel] = useState({});
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const isAllowedToManageBMC = canManageBMC(loggedUser);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));

  const hasData = useMemo(() => (
    model?.viabilityModel?.customerSegments?.length
  ), [model?.viabilityModel?.customerSegments]);

  useEffect(() => {
    if (teamId) {
      fetchViabilityModel();
    }
  }, [teamId]);

  const fetchViabilityModel = async () => {
    setIsLoading(true);

    try {
      const response = await getViabilityModel(teamId);

      setViabilityModel({
        ...response?.data,
        groupedIndicators: getGroupedIndicators(response?.data),
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = (type) => {
    setIsOpen(true);
    setDialogType(type);
  };

  const handleClose = () => setIsOpen(false);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    try {
      const formattedValues = formatValuesForServer(values);

      await editViabilityModel(model?.viabilityModel?._id, formattedValues);
      await fetchViabilityModel();
      dispatch(notifySuccess(<FormattedMessage id="success.viabilityModelUpdated" />));
      handleClose();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      await removeViabilityModelData(model?.viabilityModel?._id);
      await fetchViabilityModel();
      dispatch(notifySuccess(<FormattedMessage id="success.viabilityModelRemoved" />));
      handleClose();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Root>
      <Box display="flex" flexDirection="column" gap={22}>
        {hasData ? (
          <>
            {model?.viabilityModel?.customerSegments?.map(({ id: hypothesis }) => (
              <HypothesisSection
                key={hypothesis?._id}
                hypothesis={hypothesis}
                groupedIndicators={model?.groupedIndicators}
                isCustomerSegment
              />
            ))}

            <Box key={model?.viabilityModel?.revenueStreamsHypothesisId?._id} mt={20}>
              <HypothesisSection
                hypothesis={model?.viabilityModel?.revenueStreamsHypothesisId}
                groupedIndicators={model?.groupedIndicators}
              />
            </Box>
          </>
        ) : <EmptySegments />}
      </Box>

      <Box display="flex" flexDirection="column" gap={20}>
        <FunnelChart model={model} hasData={hasData} />

        {isAllowedToManageBMC && (
          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={10}>
            <ButtonComponent
              onClick={() => handleOpen(DialogType.Edit)}
              variant="outlined"
              color="secondary"
              icon={<EditIcon fill="currentColor" size="14px" />}
              label={intl.formatMessage({ id: 'button.edit' })}
            />
            <ButtonComponent
              onClick={() => handleOpen(DialogType.Delete)}
              icon={<TrashIconOutlined size="16px" />}
              color="error"
              variant="outlined"
              disabled={!hasData}
              label={intl.formatMessage({ id: 'button.resetData' })}
            />
          </Box>
        )}
      </Box>

      {isOpen && dialogType === DialogType.Delete && (
        <DialogAlert
          isModalOpen={isOpen}
          onClose={handleClose}
          onSubmit={handleDelete}
          hasActions
          isDisabled={isSubmitting}
          submitLabel="button.delete"
          title={intl.formatMessage({ id: 'modal.title.deleteViabilityModel' })}
          content={intl.formatMessage({ id: 'modal.content.deleteViabilityModel' })}
        />
      )}

      {isOpen && dialogType === DialogType.Edit && (
        <AddViabilityModelDialog
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
          formValues={model?.viabilityModel}
        />
      )}

      <Loading isLoading={isLoading} />
    </Root>
  );
};

export default ViabilityModel;
