import { Grid } from '@mui/material';

import { limitCharacters } from '../../../../utils/textFormatters';

import {
  ChartBackgroundColumn,
  ChartBackgroundColumnPaddedBox,
} from './index.styled';

export const ChartColumns = ({ criterias = [] }) =>
  criterias.map((entry, index) => (
    <Grid key={index} sx={{ height: '350px' }} item xs>
      <ChartBackgroundColumn>
        <ChartBackgroundColumnPaddedBox>
          <h6>{entry && <b> {limitCharacters(entry, 35)}</b>}</h6>
        </ChartBackgroundColumnPaddedBox>
      </ChartBackgroundColumn>
    </Grid>
  ));
