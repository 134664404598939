import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DocumentTitle } from '../../../../common/components';
import SpinnerComponent from '../../../../common/components/Spinner';
import VerifyEmail from '../../../../common/components/VerifyEmail';
import AuthLayout from '../../../../layouts/AuthLayout';
import { FormWrapper } from '../../../../layouts/AuthLayout/auth.styled';
import RegisterForm from '../../components/RegisterForm';

import { notifyError, notifySuccess } from '../../../../core/store';
import { isEmailRegistered, register } from '../../../../requests/auth';
import { getInvitedMemberData, postRegisterInvitedMember } from '../../../../requests/registration';

import { handleApiError } from '../../../../utils/errorUtils';

const Register = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('invitedUser');
  const [isLoading, setIsLoading] = useState(false);
  const [invitationInfo, setInvitationInfo] = useState(null);
  const [showResend, setShowResend] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    getInvitedUserData();
  }, []);

  const getInvitedUserData = async () => {
    if (token) {
      try {
        setIsLoading(true);
        const { data: userData } = await getInvitedMemberData({ memberToken: token });
        setInvitationInfo(userData);
      } catch (e) {
        handleApiError(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRegistration = async (values, { setSubmitting }) => {
    try {
      const { data } = await isEmailRegistered(values?.email);

      if (data?.isRegistered) {
        setSubmitting(false);
        dispatch(notifyError({ message: intl.formatMessage({ id: 'label.emailAddressTaken' }) }));
      } else {
        setEmail(values?.email);
        await register(values);
        ReactGA.gtag('event', 'sign_up', { method: 'Email' });
        setShowResend(true);
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleInvitation = async (values, { setSubmitting }) => {
    try {
      await postRegisterInvitedMember({ ...values, memberToken: token });
      dispatch(notifySuccess(intl.formatMessage({ id: 'success.accountCreatedSuccessfully' })));
      ReactGA.gtag('event', 'join_team', { teamId: invitationInfo?.teamId });
      return history.push('/account/login');
    } catch (e) {
      handleApiError(e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async (values, setters) => {
    if (invitationInfo) {
      await handleInvitation(values, setters);
    } else {
      await handleRegistration(values, setters);
    }
  };

  const goToLogin = () => {
    setShowResend(false);
    return history.push('/account/login');
  };

  return (
    <AuthLayout>
      {showResend
        ? <VerifyEmail email={email} goToLogin={goToLogin} />
        : (
          <>
            <DocumentTitle>{intl.formatMessage({ id: 'label.register' })}</DocumentTitle>
            <FormWrapper>
              <Typography variant="h2" textAlign="center" color="primary" mb={24}>
                {intl.formatMessage({ id: token ? 'label.createAccountToJoinTeam' : 'label.createAccount' })}
              </Typography>
              {isLoading
                ? <SpinnerComponent />
                : (
                  <RegisterForm
                    invitedUserData={invitationInfo}
                    onSubmit={handleSubmit}
                  />
                )}
            </FormWrapper>
          </>
        )}
    </AuthLayout>
  );
};

export default Register;
