import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'color' }
)(({ theme, color }) =>  `
  margin: ${theme.spacing(5, 10)};
  border: 1px solid ${color};
  border-right: 7px solid ${color};
  border-radius: 8px;
  padding: ${theme.spacing(2, 10)};
  cursor: pointer;
`);
