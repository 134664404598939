export const getInitialValues = (detail) => ({
  title: detail?.title || '',
  description: detail?.description || '',
  clientStage: detail?.clientStage || '',
  contactDisponibility: detail?.contactDisponibility || false,
  nextStepsNumber: detail?.nextSteps?.length || 0,
  nextSteps: detail?.nextSteps || [],
  cost: detail?.cost || 0,
  linksNumber: detail?.links?.length || 0,
  links: detail?.links || [],
});
