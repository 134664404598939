import { Form, Formik } from 'formik';
import { get } from 'lodash-es';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { DEFAULT_TOOLBAR_CONFIG } from '../../../../constants/richTextEditor';
import { DIMENSION_SEGMENT_INDICATORS, TARGET_INDICATORS } from '../../../../constants/targetIndicators';
import { getFormikErrors } from '../../../../utils/errorUtils';
import { segmentDimensionSchema } from '../../../../utils/validation/segmentDimensionSchema';
import { getInitialValues, getTypeLabels } from '../../utils';

import { SaveIconOutlined } from '../../../../assets/icons';

import CounterField from '../../../../fields/CounterField';
import InputField from '../../../../fields/InputField';
import RenderRichText from '../../../../fields/RichTextEditor';

const SegmentDimensionForm = ({
  onSubmit,
  formValues,
  isSubmitting,
}) => {
  const intl = useIntl();

  const handleChangeCounter = (values, setValues, oldValue, newValue) => {
    const array = get(values, 'links') || [];

    if (oldValue > newValue) {
      array.pop();
    } else {
      array.push({ name: '' });
    }

    setValues({ ...values, links: array, linksNumber: newValue });
  };

  const handleValidation = (values) => {
    try {
      segmentDimensionSchema.validateSync(values, { abortEarly: false });
    } catch (e) {
      return getFormikErrors(e);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(formValues || {})}
      onSubmit={onSubmit}
      validate={handleValidation}
    >
      {({ values, setValues }) => (
        <Form noValidate>
          {DIMENSION_SEGMENT_INDICATORS.map((type) => {
            const { richLabel, inputLabel } = getTypeLabels(type);
            const intlRichLabel = richLabel ? intl.formatMessage({ id: richLabel }) : '';

            return (
              <div key={type}>
                <RenderRichText
                  name={`${type}.description`}
                  label={type === TARGET_INDICATORS.earlyAdopters ? `"${intlRichLabel}"` : intlRichLabel}
                  height="160px"
                  config={DEFAULT_TOOLBAR_CONFIG}
                />

                <InputField
                  type="number"
                  id={`${type}.value`}
                  label={inputLabel ? intl.formatMessage({ id: inputLabel }) : ''}
                />
              </div>
            );
          })}

          <CounterField
            id="linksNumber"
            label={intl.formatMessage({ id: 'label.numberOfLikes' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter(values, setValues, oldValue, newValue)
            )}
          />
          {values?.links?.map((link, index) => (
            <InputField
              key={`links-${index}`}
              id={`links[${index}].name`}
              label={intl.formatMessage({ id: 'label.link' })}
              placeholder="www.link.com"
            />
          ))}

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
              disabled={isSubmitting}
            />
          </DialogActionWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default SegmentDimensionForm;
