import { styled } from '@mui/material/styles';

export const Counter = styled('div')(({ theme }) =>`
  border: 1px solid ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  padding: 1px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`);
