import { Box, Grid, Typography } from '@mui/material';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { CHART_COLORS, CHART_LEGEND_NUMBERS } from '../../constants';

import { ChartColumns } from '../ChartColumns';

const renderEmptyChart = () => (
  <Box
    sx={{
      width: '100%',
      height: '360px',
      border: '2px solid #74a3fe',
      borderRadius: '10px',
    }}
  />
);

export const CompetitionChart = ({
  chartValues,
  teamCompetitorMetrics = {},
  selectedFilters = [],
}) => {
  const { criteriaList = [], competitorList = [] } = teamCompetitorMetrics;

  if (!chartValues || chartValues.length === 0) {
    return renderEmptyChart();
  }

  const filterChartData = (chartData, filterList = []) => {
    if (filterList.length === 0) {
      return chartData;
    }
    const indexesOfFilertedValues = selectedFilters.map((filter) =>
      competitorList.findIndex((competitor) => competitor.name === filter)
    );

    return chartData.map((entry) => {
      const { name, ...chartEntries } = entry;

      const filteredEntries = Object.entries(chartEntries)
        .filter((entry, index) => indexesOfFilertedValues.includes(index))
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {}
        );

      return {
        name,
        ...filteredEntries,
      };
    });
  };
  const filteredChartValues = filterChartData(chartValues, selectedFilters);

  const keys = Object.keys(chartValues[0]).filter((key) => key !== 'name');
  const lines = keys.map((key, index) => (
    <Line
      key={key}
      type="linear"
      dataKey={key}
      stroke={CHART_COLORS[index]}
      strokeWidth={3}
      dot={false}
    />
  ));

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={filteredChartValues}
          margin={{ top: 20, right: 40, bottom: 25, left: 40 }}
        >
          {lines}
          <XAxis dataKey="name" hide />
          <YAxis domain={[0, 5]} hide />
        </LineChart>
      </ResponsiveContainer>

      <Box
        sx={{
          position: 'absolute',
          left: '-10px',
          display: 'flex',
          flexDirection: 'column',
          height: '320px',
          justifyContent: 'space-between',
        }}
      >
        {CHART_LEGEND_NUMBERS.map((number) => (
          <Box key={number}>
            <Typography variant="body1" color="primary" className="faded">
              {number}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '360px',
          top: '0',
        }}
      >
        <Grid container spacing={10}>
          <ChartColumns criterias={criteriaList} />
        </Grid>
      </Box>
    </Box>
  );
};
