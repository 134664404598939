import { Typography, Box } from '@mui/material';
import React from 'react';

import { PillQuestionAnswer } from '../index.styled';
import InfoMessage from './InfoMessage';

const RowPillAnswer = ({
   options,
   onSelect,
   selectedAnswers,
   width,
 }) => {
  const detailsOfSelectedAnswers = options.filter(({ value, onSelectMessage }) => (
    selectedAnswers.includes(value) && onSelectMessage
  ));

  return (
    <div>
      <Box width={width}>
        {options.map((option) => (
          <PillQuestionAnswer
            key={option.value}
            gutterBottom
            selected={selectedAnswers.includes(option.value)}
            onClick={() => onSelect(option.value)}
          >
            <Typography>
              {option.label}
            </Typography>
          </PillQuestionAnswer>
        ))}
      </Box>

      {detailsOfSelectedAnswers?.map(({ onSelectMessage, value }) => (
        <InfoMessage key={`additionalInfo-${value}`} message={onSelectMessage} />
      ))}
    </div>
  );
};

export default RowPillAnswer;
