import { convertDraftToPlainText } from '../editorUtils';

export const checkMaxCharacters = (value, maxSize) => {
  const plainText = convertDraftToPlainText(value || '');
  return plainText.length <= maxSize;
};

export const checkNumberMax = (value, max) => +value <= max;

export const checkNumberMin = (value, min) => min <= +value;
