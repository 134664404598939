import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import DialogComponent from '../../../../common/components/DialogComponent';
import { Loading } from '../../../../common/styled';

import { currentTeamIdSelector } from '../../../../common/store';
import { loadHypotheses } from '../../../../requests/hypothesis';

import { BMC_TYPE } from '../../../../constants/bmc';
import { handleApiError } from '../../../../utils/errorUtils';

import ViabilityModelForm from '../ViabilityModelForm';

const AddViabilityModelDialog = ({
  onClose,
  isOpen,
  onSubmit,
  formValues,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const [options, setOptions] = useState({
    customerSegments: [],
    revenueStreams: [],
  });

  useEffect(() => {
    if (teamId) {
      fetchHypothesis(teamId);
    }
  }, [teamId]);

  const fetchHypothesis = async () => {
    setIsLoading(true);

    try {
      const hypotheses = await loadHypotheses(teamId);
      const customerSegmentsAsOptions = hypotheses
        ?.filter(({ columnBMC }) => columnBMC === BMC_TYPE.customerSegments)
        ?.map(({ title, _id, color }) => ({ label: title, value: _id, color }));
      const revenueStreamsAsOptions = hypotheses
        ?.filter(({ columnBMC }) => columnBMC === BMC_TYPE.revenueStreams)
        ?.map(({ title, _id }) => ({ label: title, value: _id }));

      setOptions({
        customerSegments: customerSegmentsAsOptions,
        revenueStreams: revenueStreamsAsOptions,
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogComponent
      open={isOpen}
      handleClose={onClose}
      maxWidth="xs"
      title={intl.formatMessage({ id: 'modal.title.viabilityMode' })}
    >
      <ViabilityModelForm
        formValues={formValues}
        onSubmit={onSubmit}
        options={options}
      />

      <Loading isLoading={isLoading} />
    </DialogComponent>
  );
};

export default AddViabilityModelDialog;
