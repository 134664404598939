import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) =>`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing(10)};
  
  svg {
    flex: none;
    margin-top: 4px;
  }
  
  & p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover p {
    text-decoration: underline;
    font-weight: 600;
  }
`);
