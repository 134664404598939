import { Typography, Box, useTheme } from '@mui/material';
import { some, size } from 'lodash-es';
import { useHistory } from 'react-router-dom';

import {
  AccountProfileIcon,
  ExperimentVialIcon,
  LineAndBarsIcon,
  PaperClipIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  VerifiedIcon,
} from '../../../assets/icons';

import { ClampedText } from '../ClampedText';
import {
  HypothesisCardContainer,
  HypothesisColor,
  HypothesisTitle,
  ColorColumnDiv,
  SegmentCartContainer,
} from './index.styled';

const ResolutionBox = ({ resolutionLength, IconComponent }) =>
  !!resolutionLength && (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #74A3FE',
        padding: '1px 3px',
        borderRadius: '4px',
      }}
    >
      <IconComponent fill="#74A3FE" />
      <Typography color="primary" marginLeft="5px">{resolutionLength}</Typography>
    </Box>
  );

const HypothesisCard = (props) => {
  const {
    hypothesis,
    backgroundColor,
    floatRight,
    customerSegment,
    allowClick,
    mb = '10px',
  } = props;
  const { push } = useHistory();
  const theme = useTheme();

  const {
    resolutions = [],
    experiments = [],
    KPIIndicators = [],
    clientProfile = null,
  } = hypothesis;

  const positiveResolutions = resolutions.filter(
    (resolution) =>
      resolution.outcome === 'validatedWithMVP' ||
      resolution.outcome === 'validatedWithoutMVP'
  );

  const negativeResolutions = resolutions.filter(
    (resolution) =>
      resolution.outcome === 'invalidatedWithMVP' ||
      resolution.outcome === 'invalidatedWithoutMVP'
  );

  const handleClick = allowClick
    ? () => push(`/hypothesis/${hypothesis._id}?tab=description`)
    : () => {};

  const resolutionCondition = some(
    hypothesis.resolutions,
    (resolution) =>
      resolution?.outcome === 'invalidatedWithMVP' ||
      resolution?.outcome === 'invalidatedWithoutMVP'
  );

  const colorColumn = customerSegment && (
    <ColorColumnDiv backgroundColor={backgroundColor} />
  );

  const hypothesisDetails = size(hypothesis.details) > 0 && (
    <PaperClipIcon fill="#74A3FE" />
  );

  const experimentBox = size(experiments) > 0 && (
    <ResolutionBox
      resolutionLength={experiments.length}
      IconComponent={ExperimentVialIcon}
    />
  );

  const KPIIndicatorsBox = size(KPIIndicators) > 0 && (
    <LineAndBarsIcon fill="#74A3FE" />
  );

  const clientProfileBox = clientProfile && (
    <AccountProfileIcon size="15px" fill="#74A3FE" />
  );

  return customerSegment ? (
    <SegmentCartContainer
      onClick={handleClick}
      customStyles="flex-grow: 1;"
      borderColor={backgroundColor}
      customerSegment
      marginBottom={mb}
    >
      <HypothesisTitle
        decorationThrough={
          resolutionCondition || hypothesis.hypothesisStage === 'invalidated'
        }
      >
        <ClampedText lineClamp={2} withTooltip text={hypothesis.title} />
      </HypothesisTitle>

      {colorColumn}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '10px',
          gap: '3px',
        }}
      >
        {hypothesisDetails}
        <ResolutionBox
          resolutionLength={positiveResolutions.length}
          IconComponent={ThumbsUpIcon}
        />
        <ResolutionBox
          resolutionLength={negativeResolutions.length}
          IconComponent={ThumbsDownIcon}
        />
        {experimentBox}
        {KPIIndicatorsBox}
        {clientProfileBox}
        {hypothesis.hypothesisStage === 'validated' && (
          <VerifiedIcon size="17px" color={theme.palette.success.main} />
        )}
      </Box>
    </SegmentCartContainer>
  ) : (
    <HypothesisCardContainer
      style={{ marginBottom: mb }}
      customerSegment={customerSegment}
      floatRight={floatRight}
      allowClick={allowClick}
      onClick={handleClick}
    >
      <HypothesisTitle
        decorationThrough={resolutionCondition || hypothesis.hypothesisStage === 'invalidated'}
      >
        <ClampedText lineClamp={2} withTooltip text={hypothesis.title} />
      </HypothesisTitle>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: backgroundColor?.length > 0  ? 'space-between' : 'flex-end',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '3px',
          }}
        >
          {backgroundColor?.length > 0 && (
            <div style={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
              {backgroundColor.map((color, idx) => (
                <HypothesisColor key={idx} backgroundColor={color} />
              ))}
            </div>
          )}

          <div
            style={{
              display: 'flex',
              gap: '3px',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {hypothesisDetails}
            {clientProfileBox}
            {KPIIndicatorsBox}
            {experimentBox}
            <ResolutionBox
              resolutionLength={positiveResolutions.length}
              IconComponent={ThumbsUpIcon}
            />
            <ResolutionBox
              resolutionLength={negativeResolutions.length}
              IconComponent={ThumbsDownIcon}
            />
            {hypothesis.hypothesisStage === 'validated' && (
              <VerifiedIcon size="17px" color={theme.palette.success.main} />
            )}
          </div>
        </div>
      </Box>
    </HypothesisCardContainer>
  );
};

export default HypothesisCard;
