import { Box } from '@mui/material';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogAlert from '../../../../common/components/DialogAlert';
import { Loading } from '../../../../common/styled';
import AddSegmentDimension from '../../components/AddSegmentDimension';
import SegmentDetails from '../../components/SegmentDetails';

import { notifySuccess } from '../../../../core/store';
import {
  addSegmentDimension,
  deleteSegmentDimension,
  getSegmentDimension, updateSegmentDimension,
} from '../../../../requests/segmentDimension';

import { DIMENSION_SEGMENT_INDICATORS, TARGET_INDICATORS } from '../../../../constants/targetIndicators';
import { handleApiError } from '../../../../utils/errorUtils';
import { canManageHypothesis } from '../../../../utils/permissions';
import { formatTargetIndicators, getSegmentInfo } from '../../utils';

import { AddIcon, EditIcon, TrashIconOutlined } from '../../../../assets/icons';

import SegmentDetailsDrawer from '../SegmentDetailsDrawer';
import { SegmentCircle, ValueWrapper, Line } from './index.styled';

const DIALOG_TYPE = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  DETAILS: 'DETAILS',
  CREATE: 'CREATE',
};

const HypothesisSegmentDimension = () => {
  const intl = useIntl();
  const { hypothesisId } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [segmentDimension, setSegmentDimension] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasData = Object?.keys(segmentDimension)?.length > 0;
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const isAllowedToManageHypothesis = canManageHypothesis(loggedUser);

  useEffect(() => {
    if (hypothesisId) {
      fetchSegmentDimension(hypothesisId);
    }
  }, [hypothesisId]);

  const fetchSegmentDimension = async () => {
    setIsLoading(true);

    try {
      const response = await getSegmentDimension(hypothesisId);
      setSegmentDimension(response?.data || {});
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSegmentDimension = async () => {
    setIsSubmitting(true);

    try {
      await deleteSegmentDimension(segmentDimension?._id);
      fetchSegmentDimension();
      handleClose();
      handleCloseDrawer();
      dispatch(notifySuccess(<FormattedMessage id="success.deletedSegmentDimensionSuccessfully" />));
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSegment = async (values) => {
    setIsSubmitting(true);

    try {
      const isCreate = dialogType === DIALOG_TYPE.CREATE;
      const targetIndicators = formatTargetIndicators(values);
      const fn = isCreate ? addSegmentDimension : updateSegmentDimension;
      const successMessage = isCreate
        ? 'success.updatedSegmentDimensionSuccessfully'
        : 'success.updatedSegmentDimensionSuccessfully';

      await fn({ ...segmentDimension, targetIndicators, hypothesisId, links: values?.links || [] });

      fetchSegmentDimension();
      handleClose();
      dispatch(notifySuccess(<FormattedMessage id={successMessage} />));
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpen = (type) => {
    if (type === DIALOG_TYPE.DETAILS) {
      setIsDrawerOpen(true);
    } else {
      setIsOpen(true);
    }

    setDialogType(type);
  };

  const handleClose = () => {
    setIsOpen(false);
    setDialogType(null);
  };

  const handleCloseDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <Box display="flex" alignItems="center" gap={40} position="relative">
        <Box position="relative" display="flex" justifyContent="center" minWidth="450px" height="450px">
          {DIMENSION_SEGMENT_INDICATORS.map((type) => {
            const { size, bgColor } = getSegmentInfo(type);
            const foundSegment = segmentDimension?.targetIndicators?.find(({ targetIndicatorType }) => (
              targetIndicatorType === type
            ));

            return (
              <SegmentCircle key={type} size={size} bgColor={bgColor}>
                <ValueWrapper variant="h2" color="primary">
                  {intl.formatNumber(foundSegment?.value || 0)}
                </ValueWrapper>
              </SegmentCircle>
            );
          })}

          <Line length={249} top="164px" left="245px" angle={-100} />
          <Line length={235} top="257px" left="259px" angle={-100} />
          <Line length={239} top="410px" left="255px" angle={-80} />
          <Line length={245} top="534px" left="250px" angle={-80} />
        </Box>

        <Box display="flex" flexDirection="column" gap={10} width="100%">
          {DIMENSION_SEGMENT_INDICATORS.map((type) => {
            const { title, placeholder } = getSegmentInfo(type);
            const intlTitle = intl.formatMessage({ id: title });
            const foundSegment = segmentDimension?.targetIndicators?.find(({ targetIndicatorType }) => (
              targetIndicatorType === type
            ));

            return (
              <SegmentDetails
                key={type}
                title={type === TARGET_INDICATORS.earlyAdopters ? `"${intlTitle}"` : intlTitle}
                placeholder={intl.formatMessage({ id: placeholder })}
                content={foundSegment?.description}
                size={isNil(foundSegment?.value)
                  ? foundSegment?.value
                  : intl.formatNumber(foundSegment?.value)
                }
              />
            );
          })}
        </Box>

        {isAllowedToManageHypothesis && (
          <Box position="absolute" display="flex" alignItems="center" gap={20} sx={{ bottom: 0, left: 0 }}>
            <ButtonComponent
              color="secondary"
              variant={hasData ? 'outlined': 'contained'}
              onClick={() => handleOpen(hasData ? DIALOG_TYPE.DETAILS : DIALOG_TYPE.CREATE)}
              icon={hasData
                ? <EditIcon fill="currentColor" size="14px" />
                : <AddIcon fill='currentColor' width='22px' height='22px' />
              }
              label={intl.formatMessage({
                id: hasData ? 'button.edit' : 'button.addDimensionSegment'
              })}
            />

            {hasData && (
              <ButtonComponent
                variant="outlined"
                color="error"
                onClick={() => handleOpen(DIALOG_TYPE.DELETE)}
                icon={<TrashIconOutlined fill="currentColor" size="14px" />}
                label={intl.formatMessage({ id: 'button.deleteData' } )}
              />
            )}
          </Box>
        )}

        <AddSegmentDimension
          isOpen={isOpen && [DIALOG_TYPE.CREATE, DIALOG_TYPE.EDIT].includes(dialogType)}
          onClose={handleClose}
          onSubmit={handleSegment}
          formValues={hasData ? segmentDimension : null}
          isSubmitting={isSubmitting}
        />

        <DialogAlert
          isModalOpen={isOpen && dialogType === DIALOG_TYPE.DELETE}
          onClose={handleClose}
          onSubmit={handleDeleteSegmentDimension}
          hasActions
          isDisabled={isSubmitting}
          title={intl.formatMessage({ id: 'modal.title.deleteSegmentDimension' })}
          content={intl.formatMessage({ id: 'modal.content.deleteSegmentDimension' })}
        />

        <SegmentDetailsDrawer
          isDrawerOpen={isDrawerOpen}
          onCloseDrawer={handleCloseDrawer}
          segmentDimension={segmentDimension}
          onEdit={() => handleOpen(DIALOG_TYPE.EDIT)}
          onDelete={() => handleOpen(DIALOG_TYPE.DELETE)}
        />
      </Box>

      <Loading isLoading={isLoading} />
    </>
  );
};

export default HypothesisSegmentDimension;
