import ComingSoon from '../common/components/_ComingSoon';

export const EXISTING_FLAGS = {
  myIdea: {
    mrlGuide: 'mrlGuide',
    publicProfile: 'publicProfile',
  },
  resources: {
    patterns: 'patterns',
  },
  bmcModels: {
    terminalsModel: 'terminalsModel',
  },
  user: {
  }
};

/**
 * Flag routes
 */
const flagRoutes = {
  local: [],
  staging: [],
  production: [],
};

/**
 * Flag features
 */
const flagFeature = {
  local: [],
  staging: [
    EXISTING_FLAGS.bmcModels.terminalsModel,
  ],
  production: [
    EXISTING_FLAGS.bmcModels.terminalsModel,
    EXISTING_FLAGS.myIdea.mrlGuide,
    EXISTING_FLAGS.myIdea.publicProfile,
    EXISTING_FLAGS.resources.patterns,
  ],
};

export const isRouteDisabled = (feature) => {
  const flags = flagRoutes[getEnv()] || [];
  return flags?.includes(feature);
};

export const isFeatureDisabled = (feature) => {
  const flags = flagFeature[getEnv()] || [];
  return flags?.includes(feature);
};

export const getEnv = () => {
  const { hostname } = window.location;

  if (hostname.includes('staging')) {
    return 'staging';
  }

  if (hostname.includes('localhost')) {
    return 'local';
  }

  return 'production';
};

export const renderRouteComponent = (feature, Component, props = {}) => {
  const isDisabled = isRouteDisabled(feature);

  return isDisabled ? <ComingSoon /> : <Component {...props} />;
};
