import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import InformationRow from '../../../../common/components/InformationRow';

import { UpwardsTrend } from '../../../../assets/icons';

import { TrendWrapper } from './index.styled';

const TrendDetails = ({ trend = {} }) => {
  const intl = useIntl();
  const trendLabel = trend?.isTrendFavorable ? 'label.unfavorableTrend' : 'label.favorableTrend';

  return (
    <>
      <InformationRow
        label={intl.formatMessage({ id: 'label.title' })}
        value={trend?.title}
      />

      <InformationRow
        richText
        label={intl.formatMessage({ id: 'label.description' })}
        value={trend?.description}
      />

      <TrendWrapper isTrendFavorable={trend?.isTrendFavorable}>
        <Typography variant="body1" color="textSecondary">
          {intl.formatMessage({ id: trendLabel })}
        </Typography>

        <UpwardsTrend />
      </TrendWrapper>
    </>
  );
};

export default TrendDetails;
