import React from 'react';
import { FormattedMessage } from 'react-intl';

import { APP_NAME } from '../../../../constants/general';

export const DELETE_REASONS = {
  toHardToUse: 'toHardToUse',
  notSuitableForMe: 'notSuitableForMe',
  notEnoughTimeToGetUsedWithTheApp: 'notEnoughTimeToGetUsedWithTheApp',
  myProjectEnded: 'myProjectEnded',
  functionalitiesAreUnderDeveloped: 'functionalitiesAreUnderDeveloped',
  needDifferentFunctionalities: 'needDifferentFunctionalities',
  isToExpensive: 'isToExpensive',
  foundSomethingBetter: 'foundSomethingBetter',
  notUpToExpectations: 'notUpToExpectations',
  foundAFreeOption: 'foundAFreeOption',
  otherReason: 'otherReason',
};

export const DELETE_REASONS_OPTIONS = [
  {
    label: <FormattedMessage id="label.toHardToUse" />,
    value: DELETE_REASONS.toHardToUse,
  },
  {
    label: <FormattedMessage id="label.notSuitableForMe" />,
    value: DELETE_REASONS.notSuitableForMe,
  },
  {
    label: <FormattedMessage id="label.notEnoughTimeToGetUsedWithTheApp" />,
    value: DELETE_REASONS.notEnoughTimeToGetUsedWithTheApp,
  },
  {
    label: <FormattedMessage id="label.myProjectEnded" />,
    value: DELETE_REASONS.myProjectEnded,
  },
  {
    label: <FormattedMessage id="label.functionalitiesAreUnderDeveloped" />,
    value: DELETE_REASONS.functionalitiesAreUnderDeveloped,
  },
  {
    label: <FormattedMessage id="label.needDifferentFunctionalities" values={{ appName: APP_NAME }} />,
    value: DELETE_REASONS.needDifferentFunctionalities,
  },
  {
    label: <FormattedMessage id="label.isToExpensive" />,
    value: DELETE_REASONS.isToExpensive,
  },
  {
    label: <FormattedMessage id="label.foundSomethingBetter" />,
    value: DELETE_REASONS.foundSomethingBetter,
  },
  {
    label: <FormattedMessage id="label.notUpToExpectations" />,
    value: DELETE_REASONS.notUpToExpectations,
  },
  {
    label: <FormattedMessage id="label.foundAFreeOption" />,
    value: DELETE_REASONS.foundAFreeOption,
  },
  {
    label: <FormattedMessage id="label.otherReason" />,
    value: DELETE_REASONS.otherReason,
  },
];
