import { Box, Grid, Typography } from '@mui/material';

import PageTitle from '../PageTitle';
import icons  from './icons';

const IconsList = () => {
  const sortedIcons = icons.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <PageTitle title="Icons" mb={10} />

      <Grid container spacing={16}>
        {sortedIcons.map(({ Icon, name }, index) => (
          <Grid item xs={2} key={index}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Icon size={24} height={24} width={24} fill="#000" />
              <Typography variant="body2" sx={{ mt: 2 }}>
                {name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default IconsList;
