import React from 'react';
import { useIntl } from 'react-intl';

import InformationRow from '../../../../common/components/InformationRow';

import { SWOT_TYPES } from '../../constants';

const SwotDetails = ({ swot = {} }) => {
  const intl = useIntl();
  const drawerName = SWOT_TYPES[swot?.swotType]?.drawerName;

  return (
    <>
      <InformationRow
        label={intl.formatMessage({ id: 'label.type' })}
        value={drawerName ? intl.formatMessage({ id: drawerName }) : ''}
      />

      <InformationRow
        richText
        label={intl.formatMessage({ id: 'label.description' })}
        value={swot?.description}
      />
    </>
  );
};

export default SwotDetails;
