import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const CheckboxField = ({
  name,
  label,
  md = 12,
  disabled = false,
  sx = { marginBottom: '15px' },
}) => {
  const [field, meta] = useField({ name, type: 'checkbox' });

  return (
    <Grid item xs={12} md={md} sx={sx}>
      <FormControl component="fieldset" error={meta.touched && !!meta.error}>
        <FormControlLabel
          control={<Checkbox {...field} disabled={disabled} checked={field.value} />}
          label={label}
        />
        {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default CheckboxField;
