import { statusNegative, statusPositive } from '../../constants';

export const formatConclusion = (formikConclusion) => {
  const { summary, selectedSegment, observations, trustLevel } = formikConclusion;
  const statusText = selectedSegment.status ? statusPositive : statusNegative;

  return {
    title: summary,
    description: observations,
    dataTrust: trustLevel,
    experimentType: selectedSegment?.value,
    status: statusText,
  };
};
