
import { Box, Typography } from '@mui/material';
import React from 'react';

import { tabsWithComments } from '../../../constants/drawerTabs';

import { ButtonGroup, Button, Root } from './index.styled';

const DrawerTabs = ({ active, onClick, sx, tabs = tabsWithComments }) => {
  return (
    <Root sx={sx}>
      <ButtonGroup
        width="100%"
        disableElevation
        variant="contained"
        aria-label="Disabled button group"
      >
        {tabs.map((tab) => (
          <Button key={tab.id} active={active === tab.id} onClick={() => onClick(tab.id)}>
            <Box display="flex" alignItems="center" gap={8}>
              {tab.icon}
              <Typography variant="body2" fontWeight={600}>
                {tab.label}
              </Typography>
            </Box>
          </Button>
        ))}
      </ButtonGroup>
    </Root>
  );
};

export default DrawerTabs;
