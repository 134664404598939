import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DocumentTitle } from '../../../common/components';
import { Loading } from '../../../common/styled';
import { Resolver } from '../../../core/components';

import { currentTeamIdSelector } from '../../../common/store';
import { loadProfile } from '../../../store/userProfile/userProfileSlice';

import NotificationsPage from './NotificationsPage';

const NotificationsPageResolver = (props) => {
  const { loadProfile } = props;
  const loadDependencies = () => loadProfile();

  return (
    <>
      <DocumentTitle>Notifications</DocumentTitle>
      <Resolver
        successComponent={NotificationsPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { loadProfile };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsPageResolver)
);
