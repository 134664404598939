import update from 'immutability-helper';

import {
  getAllCategories as doGetAllCategories,
  addCategory as doAddCategory,
  editCategory as doEditCategory,
  deleteCategory as doDeleteCategory,
} from '../services/categories';

const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
const GET_ALL_CATEGORIES_ERROR = 'GET_ALL_CATEGORIES_ERROR';

const ADD_CATEGORY = 'ADD_CATEGORY';
const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';

const EDIT_CATEGORY = 'EDIT_CATEGORY';
const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';

const DELETE_CATEGORY = 'DELETE_CATEGORY';
const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID';
const GET_CATEGORY_BY_ID_SUCCESS = 'GET_CATEGORY_BY_ID_SUCCESS';
const GET_CATEGORY_BY_ID_ERROR = 'GET_CATEGORY_BY_ID_ERROR';

const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  categories: undefined,
  category: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ALL_CATEGORIES_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        categories: { $set: action.payload },
      });
    case GET_ALL_CATEGORIES_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });

    case ADD_CATEGORY:
      return update(state, {
        isCreating: { $set: true },
      });
    case ADD_CATEGORY_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case ADD_CATEGORY_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });

    case EDIT_CATEGORY:
      return update(state, {
        isEditing: { $set: true },
      });
    case EDIT_CATEGORY_SUCCESS:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_CATEGORY_ERROR:
      return update(state, {
        isEditing: { $set: false },
      });

    case GET_CATEGORY_BY_ID:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_CATEGORY_BY_ID_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        category: { $set: action.payload },
      });
    case GET_CATEGORY_BY_ID_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });

    case DELETE_CATEGORY:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_CATEGORY_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_CATEGORY_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });
    default:
      return state;
  }
};

export const getAllCategories = () => async (dispatch) => {
  dispatch({ type: GET_ALL_CATEGORIES });
  try {
    const response = await doGetAllCategories();
    dispatch({
      type: GET_ALL_CATEGORIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: GET_ALL_CATEGORIES_ERROR, payload: error });
  }
};

export const addCategory = (payload) => async (dispatch) => {
  dispatch({ type: ADD_CATEGORY });
  try {
    await doAddCategory(payload);
    dispatch({ type: ADD_CATEGORY_SUCCESS });
  } catch (error) {
    dispatch({ type: ADD_CATEGORY_ERROR, payload: error });
  }
};

export const editCategory = (categoryId, payload) => async (dispatch) => {
  dispatch({ type: EDIT_CATEGORY });
  try {
    await doEditCategory(categoryId, payload);
    dispatch({ type: EDIT_CATEGORY_SUCCESS });
  } catch (error) {
    dispatch({ type: EDIT_CATEGORY_ERROR, payload: error });
  }
};

export const deleteCategory = (categoryId) => async (dispatch) => {
  dispatch({ type: DELETE_CATEGORY });
  try {
    await doDeleteCategory(categoryId);
    dispatch({ type: DELETE_CATEGORY_SUCCESS });
  } catch (error) {
    dispatch({ type: DELETE_CATEGORY_ERROR, payload: error });
  }
};
