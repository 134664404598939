import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio
} from '@mui/material';
import Select from '@mui/material/Select';
import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogComponent from '../../../../common/components/DialogComponent';
import { SaveIcon } from '../../../../common/styled';

import {
  cancelSubscription,
  initCheckoutSession,
  updateSubscription,
} from '../../../../requests/payments';

import { handleApiError } from '../../../../utils/errorUtils';
import { formatSubscriptionPrice } from '../../../../utils/formatters';

const PaymentsModal = ({
  isModalOpen,
  setIsModalOpen,
  nextPaymentData = {},
}) => {
  const intl = useIntl();
  const handleCloseModal = () => setIsModalOpen(false);
  const [selectedSubscription, setSelectedSubscription] = useState(nextPaymentData?.subscriptionPrice || 0);
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = useSelector((state) => state?.userProfile?.userProfile) || {};
  const subscriptionOptions = useSelector((state) => state?.userProfile?.allSubscriptionProducts || {});
  const currentSubscription = useSelector((state) => state?.userProfile?.userProfile?.userId?.subscription) || null;
  const reccurenceTranslation = {
    monthly: intl.formatMessage({ id: 'label.month' })?.toLowerCase(),
    yearly: intl.formatMessage({ id: 'label.year' }),
  };
  const planOption = useMemo(() => {
    const options = [{ value: 0, label: intl.formatMessage({ id: 'label.free' }) }];
    Object.entries(subscriptionOptions).map(
      ([reccurencePeriod, sectionOptions]) => {
        sectionOptions.forEach((option) => {
          options.push({
            label: `${option.price} lei / ${reccurenceTranslation[reccurencePeriod]}`,
            id: option.id,
            priceId: option.priceId,
            recurrence: reccurencePeriod,
            value: option.price,
          });
        });
      }
    );

    return options;
  }, [subscriptionOptions]);

  useEffect(() => {
    setSelectedSubscription(nextPaymentData?.subscriptionPrice || 0);
  }, [nextPaymentData?.subscriptionPrice]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
  };

  const handleChange = ({ target: { value } }) => {
    setSelectedSubscription(value);
  };

  const handleSave = async () => {
    const subscriptionOption = planOption.find(
      (option) => option.value === selectedSubscription
    );

    if (nextPaymentData?.subscriptionPrice === selectedSubscription) {
      setIsModalOpen(false);
      return;
    }

    try {
      setIsLoading(true);

      if (!selectedSubscription) {
        await cancelSubscription();
        setIsModalOpen(false);
        ReactGA.gtag('event', 'unsubscribe', {
          userId: userProfile?.userId?._id,
          previousAmount: formatSubscriptionPrice(currentSubscription?.amountDecimal),
          previousRecurrence: currentSubscription.planRecurringPeriod,
        });
        window.location.reload();
        return;
      }

      if (!currentSubscription?.stripeSubscriptionId && !currentSubscription?.stripeCustomerId) {
        const { data } = await initCheckoutSession(subscriptionOption.priceId);

        ReactGA.gtag('event', 'purchase', {
          userId: userProfile?.userId?._id,
          type: 'subscription',
          value: subscriptionOption?.price,
          currency: subscriptionOption.currency,
          items: [subscriptionOption],
        });

        if (data?.redirectUrL) {
          window.location.href = data.redirectUrL;
        }
      } else {
        await updateSubscription({ planId: subscriptionOption.priceId });
        ReactGA.gtag('event', 'change_subscription', {
          userId: userProfile?.userId?._id,
          value: subscriptionOption?.price,
          currency: subscriptionOption.currency,
          items: [subscriptionOption],
          previousAmount: formatSubscriptionPrice(currentSubscription?.amountDecimal),
          previousRecurrence: currentSubscription.planRecurringPeriod,
        });

        window.location.reload();
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogComponent
      open={isModalOpen}
      title={intl.formatMessage({ id: 'label.changePlan' })}
      handleClose={handleCloseModal}
    >
      <Box>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">
            {intl.formatMessage({ id: 'label.plan' })}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={selectedSubscription}
            MenuProps={MenuProps}
            onChange={handleChange}
            renderValue={(selected) => {
              const selectedOption = planOption.find(
                (option) => option.value === selected
              );
              return selectedOption.label;
            }}
            input={<OutlinedInput label={intl.formatMessage({ id: 'label.plan' })} />}
          >
            {planOption.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Radio checked={option.value === selectedSubscription} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ButtonComponent
          sx={{ mt: 30}}
          color="secondary"
          icon={<SaveIcon />}
          label={intl.formatMessage({ id: 'button.save' })}
          disabled={isLoading}
          onClick={handleSave}
        />
      </Box>
    </DialogComponent>
  );
};

export default PaymentsModal;
