import { Avatar, Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import MemberRole from '../../../../common/components/MemberRole';
import SectionTitle from '../../../../common/components/SectionTitle';
import CreateTeamModal from '../../../../common/modals/CreateTeamModal';

import { useApi } from '../../../../hooks/useApi';
import { deleteTeamById } from '../../../../requests/teams';

import {
  AddIcon,
  PeopleIcon,
  TrashIconOutlined,
  EditIcon,
} from '../../../../assets/icons';

import JoinTeamModal from '../JoinTeamModal';
import { IdeaActions, IdeaBox, MyIdeasTitle, MyIdeasScroll } from './index.styled';

const MyIdeas = ({ userTeams }) => {
  const intl = useIntl();

  const [, runDeleteIdea] = useApi(deleteTeamById);

  const [isJoinTeamModalOpen, setIsJoinTeamModalOpen] = useState(false);
  const [isAddIdeaModalOpen, setIsAddIdeaModalOpen] = useState(false);
  const [editedTeam, setEditedTeam] = useState(null);
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);

  const handleEditIdea = (idea) => {
    setEditedTeam(idea);
    setIsAddIdeaModalOpen(true);
  };

  const handleDeleteIdea = async (idea) => {
    await runDeleteIdea({
      params: {
        teamId: idea._id,
      },
    });
  };

  return (
    <>
      <MyIdeasTitle>
        <SectionTitle
          mb={0}
          title={intl.formatMessage({ id: 'label.myIdeas' })}
          counter={userTeams.length}
        />
        <Box display="flex" gap={20}>
          <ButtonComponent
            color="secondary"
            variant="outlined"
            onClick={() => setIsJoinTeamModalOpen(true)}
            icon={PeopleIcon}
            id="join-cohort-button"
            label={intl.formatMessage({ id: 'button.joinTheTeam' })}
          />
          <ButtonComponent
            color="secondary"
            onClick={() => setIsAddIdeaModalOpen(true)}
            icon={AddIcon}
            id="add-ideea-button"
            label={intl.formatMessage({ id: 'button.addIdea' })}
          />
        </Box>
      </MyIdeasTitle>

      <MyIdeasScroll className="scrollbar">
        {userTeams && userTeams.map((item) => {
          const foundMember = item?.members?.find(({ userId }) => userId === loggedUser?.userId?._id) || {};

          return (
            <IdeaBox key={item._id}>
              <Avatar
                sx={{ width: '67px', height: '67px' }}
                src={item.logo}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h3" color="primary" gutterBottom>
                  {item.name}
                </Typography>
                <MemberRole user={{ ...foundMember, userRole: loggedUser?.userId?.role }} />
              </Box>

              <IdeaActions>
                <IconButton className="faded" onClick={() => handleEditIdea(item)}>
                  <EditIcon size={16} />
                </IconButton>
                <IconButton color="error" onClick={() => handleDeleteIdea(item)}>
                  <TrashIconOutlined />
                </IconButton>
              </IdeaActions>
            </IdeaBox>
          );
        })}
        {userTeams.length === 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography color="textSecondary">
              {intl.formatMessage({ id: 'label.noBusinessIdea' })}
            </Typography>
          </Box>
        )}
      </MyIdeasScroll>

      {isAddIdeaModalOpen && (
        <CreateTeamModal
          isModalOpen={isAddIdeaModalOpen}
          setIsModalOpen={setIsAddIdeaModalOpen}
          setEditedTeam={setEditedTeam}
          isEditMode={!!editedTeam}
          team={editedTeam}
        />
      )}

      <JoinTeamModal
        isOpen={isJoinTeamModalOpen}
        setIsModalOpen={setIsJoinTeamModalOpen}
      />
    </>
  );
};

export default MyIdeas;
