import React from 'react';
import { useIntl } from 'react-intl';

import { DocumentTitle } from '../../common/components';

import Community from '../../modules/community/containers/CommunityPage';

const LeaderboardPageResolver = () => {
  const intl = useIntl();

  return (
    <>
      <DocumentTitle>{intl.formatMessage({ id: 'label.teams' })}</DocumentTitle>
      <Community />
    </>
  );
};

export default LeaderboardPageResolver;
