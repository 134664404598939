import {
  CREATE_CONCLUSION,
  UPDATE_CONCLUSION_BY_ID,
  DELETE_CONCLUSION_BY_ID,
  GET_ALL_CONCLUSIONS_BY_TEAM,
  GET_CONCLUSIONS_DETAILS_BY_ID,
} from '../constants/apiRoutes';
import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
} from '../utils/api/Api';

export const createConclusion = ({ payload }) =>
  runPostApiRequest({
    endpoint: CREATE_CONCLUSION,
    params: payload.params,
    payload: payload.conclusionData,
  });

export const updateConclusionById = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: UPDATE_CONCLUSION_BY_ID,
    params: payload.params,
    payload: payload.conclusionData,
  });

export const deleteConclusionById = ({ payload }) =>
  runDeleteApiRequest({
    endpoint: DELETE_CONCLUSION_BY_ID,
    params: payload.params,
  });

export const getAllConclusionsByTeam = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_ALL_CONCLUSIONS_BY_TEAM,
    params: payload.params,
    queryParams: payload.queryParams,
  });

export const getConclusionDetailsById = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_CONCLUSIONS_DETAILS_BY_ID,
    params: payload.params,
  });
