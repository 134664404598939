import { Formik } from 'formik';
import { get } from 'lodash-es';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import TargetIndicatorRow from '../../../modules/hypothesisDescription/components/TargetIndicatorRow';
import ButtonComponent from '../../components/ButtonComponent';
import CustomerSegmentChip from '../../components/CustomerSegmentChip';
import CustomerSegmentOption from '../../components/CustomerSegmentOption';
import { DialogActionWrapper } from '../../components/DialogComponent/index.styled';

import { BMC_TYPE } from '../../../constants/bmc';
import { SUBSCRIPTION_TYPE_OPTIONS, TARGET_INDICATORS_OPTIONS } from '../../../constants/options';
import { HYPOTHESIS_STAGE_OPTIONS, IMPORTANCE_OPTIONS } from '../../../modules/hypothesisDescription/constants';

import { SaveIconOutlined } from '../../../assets/icons';

import AutocompleteField from '../../../fields/AutocompleteField';
import CounterField from '../../../fields/CounterField';
import InputField from '../../../fields/InputField';
import CustomMultipleSelectField from '../formComponents/CustomMultipleSelectField';

const HypothesisForm = ({
  segmentOptions,
  handleSubmit,
  formValues,
}) => {
  const intl = useIntl();
  const { columnBMC } = formValues;
  const isCustomerSegment = columnBMC === BMC_TYPE.customerSegments;

  const initialValues = {
    title: formValues.title || '',
    description: formValues.description || '',
    columnBMC: formValues.columnBMC || '',
    importance: formValues.importance || 'medium',
    segment: formValues.segment || [],
    hypothesisStage: formValues.hypothesisStage || '',
    targetIndicatorsNumber: formValues?.targetIndicators?.length || 0,
    targetIndicators: formValues?.targetIndicators?.length
      ? formValues.targetIndicators
      : [],
    ...(columnBMC === BMC_TYPE.revenueStreams ? { subscriptionType: formValues.subscriptionType || '' } : {}),
  };

  // eslint-disable-next-line no-unused-vars
  const CustomOptionComponent = ({ option, selected, ...otherProps }) => (
    <CustomerSegmentOption {...otherProps} color={option.color} label={option.label} />
  );

  const schema = Yup.object({
    title: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    description: Yup.string(),
    importance: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    hypothesisStage: Yup.string(),
    segment: !isCustomerSegment
      ? Yup.array().of(Yup.string()).min(1, intl.formatMessage({ id: 'validate.mandatoryField' }))
      : Yup.array().of(Yup.string()),
    targetIndicators: Yup.array().of(
      Yup.object({
        targetIndicatorType: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
        value: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
        unitOfMeasurement: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
      })
    ),
    ...(columnBMC === BMC_TYPE.revenueStreams
      ? { subscriptionType: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })) }
      : {}),
  });

  const handleChangeCounter = (values, setValues, oldValue, newValue) => {
    const array = get(values, 'targetIndicators') || [];

    if (oldValue > newValue) {
      array.pop();
    } else {
      array.push({ targetIndicatorType: '', value: '', unitOfMeasurement: '' });
    }

    setValues({ ...values, targetIndicators: array, targetIndicatorsNumber: newValue });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, values, setValues }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField id="title" label={`${intl.formatMessage({ id: 'label.hypothesisTitle' })} *`}/>
          <InputField
            id="description"
            label={intl.formatMessage({ id: 'label.descriptionHypothesis' })}
            rows={3}
            multiline
          />
          <AutocompleteField
            fullWidth
            id="importance"
            label={`${intl.formatMessage({ id: 'label.hypothesisImportance' })} *`}
            options={IMPORTANCE_OPTIONS}
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
            placeholder={intl.formatMessage({ id: 'placeholder.hypothesisImportance' })}
          />
          {!isCustomerSegment && (
            <CustomMultipleSelectField
              id="segment"
              label={`${intl.formatMessage({ id: 'label.segment' })} *`}
              options={segmentOptions}
              customSelectedOptionComponent={CustomerSegmentChip}
              customOptionComponent={CustomOptionComponent}
              placeholder={intl.formatMessage({ id: 'placeholder.segment' })}
            />
          )}
          {columnBMC === BMC_TYPE.revenueStreams && (
            <AutocompleteField
              fullWidth
              id="subscriptionType"
              label={`${intl.formatMessage({ id: 'label.subscriptionType' })} *`}
              options={SUBSCRIPTION_TYPE_OPTIONS}
              getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
            />
          )}
          <CounterField
            id="targetIndicatorsNumber"
            label={intl.formatMessage({ id: 'label.indicatorsNumber' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => handleChangeCounter(values, setValues, oldValue, newValue)}
          />
          {values.targetIndicators.map((indicator, index) => {
            const options = TARGET_INDICATORS_OPTIONS?.[columnBMC]?.filter(({ value }) => (
              !values?.targetIndicators?.some(({ targetIndicatorType }) => (
                indicator?.targetIndicatorType !== targetIndicatorType && targetIndicatorType === value
              ))
            ));

            return (
              <TargetIndicatorRow
                key={index}
                position={index}
                values={values}
                setValues={setValues}
                options={options}
              />
            );
          })}
          <AutocompleteField
            fullWidth
            id="hypothesisStage"
            label={intl.formatMessage({ id: 'label.hypothesisStage' })}
            options={HYPOTHESIS_STAGE_OPTIONS}
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
            placeholder={intl.formatMessage({ id: 'placeholder.hypothesisStage' })}
          />
          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default HypothesisForm;
