import AddIcon from '../assets/icons/richText/add-icon.svg';
import bold from '../assets/icons/richText/format_bold.svg';
import italic from '../assets/icons/richText/format_italic.svg';
import bullet from '../assets/icons/richText/format_list_bulleted.svg';
import striketrough from '../assets/icons/richText/format_strikethrough.svg';
import underline from '../assets/icons/richText/format_underlined.svg';
import link from '../assets/icons/richText/link.svg';

export const FONT_FAMILY_OPTIONS = [
  'Roboto',
  'Open Sans',
  'Railway',
  'Arial',
  'Georgia',
  'Impact',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];

export const PLATFORM_TOOLBAR_CONFIG = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'remove',
    'history',
  ],
  inline: {
    inDropdown: false,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  },
};

export const COMMENT_TOOLBAR_CONFIG = {
  options: ['link', 'emoji'],
  emoji: {
    icon: AddIcon,
    className: 'toolbar-option',
  },
  link: {
    inDropdown: false,
    className: 'toolbar-option',
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link'],
    link: { icon: link, className: undefined },
    linkCallback: undefined,
  },
};

const uploadImageCallBack = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve({ data: { link: e.target.result } });
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });

export const CHAPTER_TOOLBAR_CONFIG = {
  options: [
    'inline',
    'list',
    'emoji',
    'image',
    'embedded',
    'link',
    'textAlign',
  ],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { className: 'toolbar-option' },
    italic: { className: 'toolbar-option' },
    underline: { className: 'toolbar-option' },
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: { className: 'toolbar-option' },
    ordered: { className: 'toolbar-option' },
  },
  emoji: {
    className: 'toolbar-option',
  },
  image: {
    className: 'toolbar-option',
    uploadCallback: uploadImageCallBack,
    previewImage: true,
    alt: { present: false, mandatory: false },
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
  },
  embedded: {
    className: 'toolbar-option',
    previewImage: true,
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  link: {
    inDropdown: false,
    className: 'toolbar-option',
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link'],
    link: { className: undefined },
    linkCallback: undefined,
  },
};

export const DEFAULT_TOOLBAR_CONFIG = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    bold: { className: 'toolbar-option', icon: bold },
    italic: { className: 'toolbar-option', icon: italic },
    underline: { className: 'toolbar-option', icon: underline },
    strikethrough: {
      className: 'toolbar-option',
      icon: striketrough,
    },
  },
  list: {
    options: ['unordered'],
    unordered: { className: 'toolbar-option', icon: bullet },
  },
};
