import update from 'immutability-helper';

import {
  deleteDetail as doDeleteDetail,
  editDetail as doEditDetail,
  getAllDetails as doGetAllDetails,
  postDetail as doPostDetail,
} from '../../requests/details';

import { handleApiError } from '../../utils/errorUtils';

// Actions
const START_LOAD = 'details/START_LOAD';
const COMPLETE_LOAD_EXPERIMENT_DETAILS =
  'details/COMPLETE_LOAD_EXPERIMENT_DETAILS';
const COMPLETE_LOAD_HYPOTHESIS_DETAILS =
  'details/COMPLETE_LOAD_HYPOTHESIS_DETAILS';
const FAIL_LOAD = 'details/FAIL_LOAD';
const RESET = 'details/RESET';

const GET_ALL_DETAILS = 'GET_ALL_DETAILS';
const GET_ALL_DETAILS_SUCCESS = 'GET_ALL_DETAILS_SUCCESS';

const POST_DETAIL = 'POST_DETAIL';
const POST_DETAIL_SUCCESS = 'POST_DETAIL_SUCCESS';

const EDIT_DETAIL = 'EDIT_DETAIL';
const EDIT_DETAIL_SUCCESS = 'EDIT_DETAIL_SUCCESS';

const DELETE_DETAIL = 'DELETE_DETAIL';
const DELETE_DETAIL_SUCCESS = 'DELETE_DETAIL_SUCCESS';

// Initial state
const initialState = {
  isLoading: false,
  hypothesisDetails: [],
  experimentDetails: [],
  details: [],
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD_EXPERIMENT_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
          experimentDetails: action.experimentDetails,
        },
      });

    case GET_ALL_DETAILS_SUCCESS:
      return update(state, {
        details: { $set: action.payload },
      });

    case COMPLETE_LOAD_HYPOTHESIS_DETAILS:
      return update(state, {
        $merge: {
          isLoading: false,
          hypothesisDetails: action.hypothesisDetails,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

export const getAllDetails = (teamId, filters) => async (dispatch) => {
  dispatch({ type: GET_ALL_DETAILS });
  try {
    const response = await doGetAllDetails(teamId, filters);
    dispatch({ type: GET_ALL_DETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    handleApiError(error);
  }
};

export const postDetail = (detail, teamId, onSuccess) => async (dispatch) => {
  dispatch({ type: POST_DETAIL });
  try {
    const response = await doPostDetail(detail, teamId);
    dispatch({ type: POST_DETAIL_SUCCESS, payload: response.data });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const editDetail = (detail, id, onSuccess) => async (dispatch) => {
  dispatch({ type: EDIT_DETAIL });
  try {
    const response = await doEditDetail(detail, id);
    dispatch({ type: EDIT_DETAIL_SUCCESS, payload: response.data });

    if (onSuccess) {
      onSuccess(response?.data);
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteDetail = (id) => async (dispatch) => {
  dispatch({ type: DELETE_DETAIL });
  try {
    const response = await doDeleteDetail(id);
    dispatch({ type: DELETE_DETAIL_SUCCESS, payload: response.data });
  } catch (error) {
    handleApiError(error);
  }
};
