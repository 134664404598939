import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Tabs from '../../common/components/Tabs';
import { TabContent } from '../../common/components/Tabs/index.styled';

import { MRL_TABS } from '../constants/constants';

import Articles from './Articles';
import Courses from './Courses';
import { MrlGuide } from './index';

const MrlPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const tabFromQuery = query.get('tab') || 'mrlSteps';

  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/admin/mrl?tab=${tab}`);
  };

  const pageComponents = {
    mrlSteps: {
      title: 'Etape ghid',
      component: <MrlGuide />,
    },
    articles: {
      title: 'Articles',
      component: <Articles />,
    },
    chapters: {
      title: 'Cursuri',
      component: <Courses />,
    },
  };

  return (
    <>
      <Tabs
        tabs={MRL_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
      />

      <TabContent className="scrollbar">
        {pageComponents[activeTab].component}
      </TabContent>
    </>
  );
};

export default MrlPage;
