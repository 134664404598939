import styled, { css } from 'styled-components';

export const BusinessModelCanvasContainer = styled.div`
  width: 100%;
  height: calc(100% - 53px);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BusinessModelCanvasRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${({ height }) => height && height};
`;

export const BusinessModelCanvasColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
  border: ${({ container }) => !container && '1px solid #74A3FE'};
  padding-right: 15px;
  ${(props) =>
  !!props.singleOnRow &&
  css`
      background: #ffffff;
      border-radius: 10px;
    `}

  ${(props) =>
  !!props.secondRow &&
  css`
      margin-right: 15px;
    `}
  height: ${(props) => (props.height || '100%')};
  max-width: ${(props) => (props.firstRow ? '20%' : '50%')};
  ${(props) =>
  !!props.empathy &&
  css`
      width: 33%;
      height: 540px;
    `}
  ${(props) =>
  !!props.noPadding &&
  css`
      padding: 0;
    `}
  ${(props) =>
  !!props.multipleChildren &&
  css`
      max-width: 100%;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      max-height: 28vh;
      border-radius: 10px;
      padding: 12px;
      padding-top: 0;
    `};
`;

export const NoResultsFound = styled.div`
  font-size: 14px;
  padding-right: 20px;
  font-style: italic;
`;

export const HypothesisCardsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
