import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => `
  padding-top: ${theme.spacing(48)};
  display: flex;
  gap: ${theme.spacing(100)};
  justify-content: center;
  
  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
    gap: ${theme.spacing(20)};
  };
`);
