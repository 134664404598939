import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DocumentTitle } from '../../common/components';
import { Loading } from '../../common/styled';
import { Resolver } from '../../core/components';

import { loadResources } from '../store';

import ResourcesPage from './ResourcesPage';

const ResourcesPageResolver = (props) => {
  const { loadResources } = props;
  const loadDependencies = async () => {
    await loadResources();
  };

  return (
    <>
      <DocumentTitle>Resources</DocumentTitle>
      <Resolver
        successComponent={ResourcesPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

ResourcesPageResolver.propTypes = {
  loadResources: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadResources };

export default withRouter(
  connect(undefined, mapDispatchToProps)(ResourcesPageResolver)
);
