import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Resource from '../../components/Resource';

import { getArticleById } from '../../../../requests/articles';

import { handleApiError } from '../../../../utils/errorUtils';

const Articles = () => {
  const query = new URLSearchParams(useLocation().search);
  const articleId = query.get('id');

  const [isLoading, setIsLoading] = useState(false);
  const [article, setArticle] = useState({});

  const fetchArticle = async () => {
    setIsLoading(true);

    try {
      const response = await getArticleById(articleId);
      setArticle(response);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (articleId) {
      fetchArticle();
    }
  }, [articleId]);

  return <Resource isLoading={isLoading} resource={article} />;
};

export default Articles;
