import styled from 'styled-components';

export const MrlGuideContainer = styled.div`
  height: calc(100% - 53px);
  padding-right: 8px;
  overflow-y: auto;
`;

export const MrlGuideStep = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 12px;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 5px;
  height: 6vh;
  margin-bottom: 1vh;
  cursor: pointer;
`;

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 0.761905px solid #868699;
  border-radius: 50%;
  width: 4vh;
  height: 4vh;
  color: black;
  font-weight: bold;
`;

export const StepLabel = styled.div`
  color: black;
  font-size: 14px;
  margin-left: 1vw;
`;

export const HeadingStage = styled.p`
  font-weight: bold;
  color: #3f3f3f;
  font-size: 24px;
`;

export const StageTitle = styled.input`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 5px;
  width: 100%;
  height: 5vh;
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  padding: 10px;
  margin-bottom: 10px;
`;

export const TaskContainer = styled.div`
  background: #eef5ff;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  border: ${(props) => `1px solid ${props.theme.blueAccent}`};
  border-radius: 5px;
  height: 5vh;
  margin-bottom: 1vh;
  cursor: pointer;
`;
