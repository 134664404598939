export const getGroupedCharacteristics = (characteristics) => {
  const groups = {
    who: [],
    task: [],
    pain: [],
    win: [],
  };

  if (!characteristics) {
    return groups;
  }

  characteristics?.forEach((characteristic) => {
    if (groups?.[characteristic?.type]) {
      groups[characteristic?.type].push(characteristic);
    }
  });

  return groups;
};

export const getCharacteristicsAsOptions = (characteristics) => {
  const groups = {
    who: [],
    task: [],
    pain: [],
    win: [],
  };

  if (!characteristics) {
    return groups;
  }

  characteristics?.forEach(({ _id, description, type  }) => {
    if (groups?.[type]) {
      groups[type].push({ value: _id, label: description });
    }
  });

  return groups;
};

export const filterCharacteristics = (options, currentSelection, optionToIgnore) => {
  return options?.filter(({ value }) => {
    const foundSelection = currentSelection?.some(({ id }) => (
      id === value && id !== optionToIgnore?.id
    ));
    return !foundSelection;
  });
};

export const countUserStory = (rows) => {
  const foundUserStories = [];

  rows?.map((row) => {
    Object.values(row).forEach((section) => {
      section?.forEach(({ userStories }) => {
        userStories?.forEach((id) => {
          if (!foundUserStories?.includes(id)) {
            foundUserStories.push(id);
          }
        });
      });
    });
  });

  return foundUserStories?.length;
};
