import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TabContent = styled(
  Paper,
  { shouldForwardProp: (props) => props !== 'offsetTop' },
)(({ theme, offsetTop = '118px' }) => `
  width: 100%;
  height: calc(100% - ${offsetTop});
  overflow-y: auto;
  position: relative;
  padding: ${theme.spacing(20)};
  border-radius: 4px;
`);
