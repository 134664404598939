import { USER_ROLES } from '../constants/roles';

export const canInviteUsers = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageUsers = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canEditIdea = (user) =>  user?.userId?.role === USER_ROLES.Founder;

export const canManageDetails = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageHypothesis = (user) => user?.userId?.role === USER_ROLES.Founder;

export const canManageBMC = (user) => user?.userId?.role === USER_ROLES.Founder;
