import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const MyDatePicker = styled(DatePicker)`
  width: 100% !important;
  :focus,
  :active,
  ::after,
  ::before {
    box-shadow: none;
    outline: none;
    border-color: #007bff;
    border-color: ${(props) => props.theme.text};
  }
`;
