import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../components/DialogComponent';
import TaskForm from '../../forms/TaskForm';

const AddTaskModal = ({
  handleClose,
  isModalOpen,
  handleSubmit,
  formValues,
  handleUpdate,
  teamMembers,
  isEditMode,
  editedTask,
}) => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: isEditMode ? 'modal.title.editTask' : 'modal.title.addATask',
  });

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={handleClose}
      title={title}
    >
      <TaskForm
        handleUpdate={handleUpdate}
        formValues={formValues}
        handleSubmit={handleSubmit}
        teamMembers={teamMembers}
        isEditMode={isEditMode}
        editedTask={editedTask}
      />
    </DialogComponent>
  );
};

export default AddTaskModal;
