import { createTheme } from '@mui/material/styles';

import MuiButton from './components/MuiButton';
import {
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiFormLabel,
} from './components/MuiForm';
import MuiIconButton from './components/MuiIconButton';
import MuiInputLabel from './components/MuiInputLabel';
import { MuiMenuItem, MuiMenu } from './components/MuiMenu';
import MuiOutlinedInput from './components/MuiOutlinedInput';
import MuiSelect from './components/MuiSelect';
import MuiSwitch from './components/MuiSwitch';
import MuiTab from './components/MuiTab';
import MuiTabs from './components/MuiTabs';
import MuiTooltip from './components/MuiTooltip';
import MuiTypography from './components/MuiTypography';

import palette from './palette';
import { variants } from './variants';

const appTheme = createTheme({
  palette,
  spacing: 1,
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: variants.h1,
    h2: variants.h2,
    h3: variants.h3,
    body1: variants.body1,
    body2: variants.body2,
    caption: variants.caption,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiOutlinedInput,
    MuiInputLabel,
    MuiSwitch,
    MuiFormControlLabel,
    MuiFormHelperText,
    MuiFormLabel,
    MuiTooltip,
    MuiSelect,
    MuiTypography,
    MuiButton,
    MuiIconButton,
    MuiMenuItem,
    MuiMenu,
    MuiTabs,
    MuiTab,
    MuiAutocomplete :{
      styleOverrides: {
        endAdornment: {
          '& .MuiSvgIcon-root': ({ theme: muiTheme }) => ({
            color: muiTheme.palette.text.secondary,
          }),
        }
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme: muiTheme }) => ({
          '& svg path': {
            fill: muiTheme.palette.general.accentLight,
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme: muiTheme }) => ({
          marginTop: muiTheme.spacing(2),
          boxShadow: 'none',
          borderRadius: '5px',
          border: `1px solid ${muiTheme.palette.general.borderAccent}`,
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme: muiTheme }) => ({
          padding: muiTheme.spacing(4),
          marginLeft: muiTheme.spacing(4),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme: muiTheme }) => ({
          borderColor: muiTheme.palette.general.lightBlue1,
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        },
      },
    },
  },
});

export default appTheme;
