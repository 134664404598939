import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IndustryContentWrapper = styled(
  Box,
  { shouldForwardProp: (prop) => !['isLast', 'isFirst'].includes(prop) }
)(({ theme, isLast, isFirst }) =>  `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(0, isLast ? 0: 20, 0, isFirst ? 0 : 20)};
  border-right: ${!isLast ? `1px solid ${theme.palette.general.lightBlue1}` : '0'};
  height: 100%;
`);
