import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DrawerComponent from '../../../../common/components/DrawerComponent';
import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import InformationRow from '../../../../common/components/InformationRow';
import LinkComponent from '../../../../common/components/LinkComponent';
import GenericComments from '../../../commentsAndNotifications/components/GenericComments';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { DRAWER_TABS } from '../../../../constants/drawerTabs';
import { NOT_APPLICABLE } from '../../../../constants/general';
import { DIMENSION_SEGMENT_INDICATORS } from '../../../../constants/targetIndicators';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';
import { getTypeLabels } from '../../utils';

import { EditIcon, TrashIconOutlined } from '../../../../assets/icons';

const SegmentDetailsDrawer = ({
  isDrawerOpen,
  onCloseDrawer,
  segmentDimension,
  onEdit,
  onDelete,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);
  const currentUserId = useSelector((state) => state?.userProfile?.userProfile?.userId?._id);
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB.segmentDimension]) || [];

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: { commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB.segmentDimension },
        teamId,
        userId: currentUserId,
      })
    );
  };

  return (
    <DrawerComponent
      isOpen={isDrawerOpen}
      onClose={onCloseDrawer}
    >
      <DrawerTitle
        title={intl.formatMessage({ id: 'label.visualizeSegmentDimension' })}
        onClose={onCloseDrawer}
      />
      <DrawerTabs
        sx={{ mt: 4 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />

      {activeDrawerTab === DRAWER_TABS.details ? (
        <Box mt={20} gap={24} display="flex" flexDirection="column">
          {DIMENSION_SEGMENT_INDICATORS?.map((type) => {
            const { value, description } = segmentDimension?.targetIndicators?.find(({ targetIndicatorType }) => (
              targetIndicatorType === type
            )) || {};
            const { richLabel } = getTypeLabels(type);
            const hasValue = !isNil(value);

            return (
              <Box key={type} display="flex" flexDirection="column" gap={8}>
                <InformationRow
                  label={richLabel ? intl.formatMessage({ id: richLabel }) : ''}
                  value={description}
                  richText
                />

                <InformationRow
                  label={intl.formatMessage({ id: 'label.dimension' })}
                  value={hasValue ? intl.formatNumber(value) : NOT_APPLICABLE}
                />
              </Box>
            );
          })}

          <div>
            <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
              {intl.formatMessage({ id: 'label.links' })}
            </Typography>
            {segmentDimension?.links?.length > 0
              ? segmentDimension?.links.map((link, index) => (
                <LinkComponent key={index} domain={link.name} />
              )) : NOT_APPLICABLE}
          </div>

          <Box display="flex" alignItems="center" gap={10}>
            <ButtonComponent
              onClick={onEdit}
              variant="outlined"
              color="secondary"
              icon={<EditIcon fill="currentColor" size="14px" />}
              label={intl.formatMessage({ id: 'button.edit' })}
            />
            <ButtonComponent
              onClick={onDelete}
              icon={<TrashIconOutlined size="16px" />}
              color="error"
              label={intl.formatMessage({ id: 'button.delete' })}
            />
          </Box>
        </Box>
      )  : (
        <GenericComments
          fetchComments={fetchComments}
          type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB.segmentDimension}
          teamId={teamId}
          userId={currentUserId}
          comments={comments}
        />
      )}
    </DrawerComponent>
  );
};

export default SegmentDetailsDrawer;
