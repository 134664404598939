import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { AddIcon, ArrowDown } from '../../assets/icons';
import { theme } from '../../assets/styles';

import ButtonComponent from './ButtonComponent';

const headerCellStyle = {
  maxWidth: '100px',
  textAlign: 'center',
  color: theme.blueAccent,
  fontWeight: 'bold',
  padding: '0 0 10px 0',
};

const centeredDivStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: '5px',
  cursor: 'pointer',
};
const GenericEmptyTable = ({
  headers,
  onAddClick,
  addButtonText,
  detailMessage,
}) => (
  <Box>
    <Table>
      <TableHead>
        <TableRow sx={{ '& > th': { borderBottom: '1px solid #B1CCFE' } }}>
          {headers.map((cell, index) => (
            <TableCell key={index} style={headerCellStyle}>
              <Box sx={centeredDivStyle}>
                <span>{cell.content}</span>
                {!cell.hideSortIcon && <ArrowDown />}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>

    <Typography className="italic" color="textSecondary" mt={40}>
      {detailMessage}
    </Typography>

    {addButtonText && onAddClick && (
      <ButtonComponent
        variant="contained"
        label={addButtonText}
        icon={<AddIcon fill='currentColor' width='22px' height='22px' />}
        onClick={onAddClick}
        sx={{ mt: 20 }}
      />
    )}
  </Box>
);

export default GenericEmptyTable;
