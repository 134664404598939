import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { AccordionExpandIcon } from '../../../assets/icons';

const ChaptersSideGuide = ({
  chaptersListResult,
  expandedAccordions,
  handleAccordionChange,
  subchapterId,
  isAdminPage,
  handleSubchapterClick,
  onReadChapter,
  chaptersRead,
}) => {
  const intl = useIntl();

  const getSubchapterReadCount = (subchapters, chaptersRead) => {
    let count = 0;

    subchapters?.forEach(({ _id }) => {
      const found = chaptersRead?.some((chapterReadId) => chapterReadId === _id);

      if (found) {
        count += 1;
      }
    });

    return count;
  };

  return (
    <>
      <Typography variant="h3" color="primary" mb={5}>
        {intl.formatMessage({ id: 'label.content' })}
      </Typography>
      <Box
        className="scrollbar"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '5px',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
        }}
      >
        {!!chaptersListResult?.length &&
          chaptersListResult.map((chapter, index) => {
            const count = getSubchapterReadCount(chapter?.subchapters, chaptersRead);

            return (
            <Box key={chapter._id}>
              <Accordion
                expanded={!!expandedAccordions[`panel${index}`]}
                onChange={handleAccordionChange(`panel${index}`)}
                sx={{
                  borderRadius: '6px',
                  boxShadow: 'none',
                  border: 'none',
                  borderBottom: '1px solid #E0E0E0',
                  padding: '5px 0',
                }}
              >
                <AccordionSummary
                  expandIcon={<AccordionExpandIcon width="12px" />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  sx={{
                    minHeight: '30px',
                    padding: 0,
                    margin: 0,
                    '&.Mui-expanded': {
                      minHeight: '30px',
                      margin: 0,
                      '& .MuiAccordionSummary-content': {
                        margin: '0px',
                      },
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: '0px',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <Typography color="primary" fontSize="16px">{chapter.title}</Typography>
                    <Divider orientation="vertical" flexItem />
                    <Box>
                      <Typography color="primary" fontSize="16px">{count}/{chapter.subchapters.length}</Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '5px 0' }}>
                  <Typography component={'span'}>
                    {chapter.subchapters.map((subchapter) => (
                      <Box
                        key={subchapter._id}
                        sx={{
                          display: 'flex',
                          gap: '5px',
                          alignItems: 'center',
                          backgroundColor:
                            subchapterId === subchapter._id ? '#BBBBBB' : '',
                        }}
                      >
                        {!isAdminPage && (
                          <Checkbox
                            sx={{
                              padding: '2px',
                              '& .MuiSvgIcon-root': {
                                fontSize: '20px',
                              },
                            }}
                            checked={chaptersRead?.includes(subchapter?._id) || false}
                            onChange={(e) => onReadChapter(e, subchapter._id)}
                          />
                        )}
                        <Box
                          sx={{ cursor: 'pointer', fontSize: '14px' }}
                          onClick={() =>
                            handleSubchapterClick(subchapter._id, chapter._id)
                          }
                        >
                          {subchapter.title}
                        </Box>
                      </Box>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
          })}
      </Box>
    </>
  );
};

export default ChaptersSideGuide;
