import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import HypothesisCard from '../../../../common/components/HypothesisCard';
import EmptyHypothesisCard from '../../../../common/components/HypothesisCard/index.empty';
import TargetIndicator from '../../../../common/components/TargetIndicator';

import { TARGET_INDICATORS } from '../../../../constants/targetIndicators';

const HypothesisSection = ({
  hypothesis,
  groupedIndicators,
  isCustomerSegment = false,
}) => {
  const intl = useIntl();
  const sam = groupedIndicators[hypothesis?._id][TARGET_INDICATORS.sam];
  const customerLifetime = groupedIndicators[hypothesis?._id][TARGET_INDICATORS.customerLifetimeCLV];
  const averageTransactionValue = groupedIndicators[hypothesis?._id][TARGET_INDICATORS.averageTransactionValue];

  return (
    <div>
      <Box width="300px">
        {hypothesis
          ? (
            <HypothesisCard
              hypothesis={hypothesis}
              customerSegment={isCustomerSegment}
              allowClick
              mb={0}
              backgroundColor={
                isCustomerSegment
                  ? hypothesis?.color
                  : hypothesis?.segment?.map(({ color }) => color) || []
              }
            />
        ) : (
          <EmptyHypothesisCard
            text={intl.formatMessage({ id:
              isCustomerSegment ? 'label.emptyClientSegment' : 'label.emptyRevenueStream'
            })}
            hasColumn={isCustomerSegment}
          />
        )}
      </Box>

      <Box mt={6} display="flex" flexDirection="column" gap={10} width="300px" ml={12}>
        {isCustomerSegment ? (
          <>
            <TargetIndicator
              targetIndicator={sam}
              hasMissingInfo={!sam}
              placeholder={intl.formatMessage({ id: 'label.missingSAMIndicator' })}
            />
            <TargetIndicator
              targetIndicator={customerLifetime}
              hasMissingInfo={!customerLifetime}
              placeholder={intl.formatMessage({ id: 'label.missingCustomerLifetime' })}
            />
          </>
        ) : (
          <TargetIndicator
            targetIndicator={averageTransactionValue}
            hasMissingInfo={!averageTransactionValue}
            placeholder={intl.formatMessage({ id: 'label.missingMonthlyValueIndicator' })}
          />
        )}
      </Box>
    </div>
  );
};

export default HypothesisSection;
