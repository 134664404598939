import { DEFAULT_DESCRIPTION } from '../../constants/general';

export const getInitialValues = (chapter) => ({
  title: chapter?.title || '',
  description: chapter?.description || DEFAULT_DESCRIPTION,
  isVisible: chapter?.isVisible || false,
});

export const formatCreatePayload = (values, chapterId, order) => ({
  ...(values.title !== '' ? { title: values.title } : {}),
  ...(values.description !== '' ? { description: values.description } : {}),
  ...(values.isVisible !== '' ? { isVisible: values.isVisible } : {}),
  ...(chapterId ? { chapterId } : {}), //chapterId exists only when creating a subchapter
  ...(order ? { order } : {}),
});

export const formatEditPayload = (values) => ({
  ...(values.title !== '' ? { title: values.title } : {}),
  ...(values.description !== ''
    ? { description: values.description }
    : {}),
  ...(values.isVisible !== '' ? { isVisible: values.isVisible } : {}),
});

export const getPrevAndNextChapter = (subChapter, chapters) => {
  const currentChapterId = subChapter.chapterId;
  const currentChapterIndex = chapters.findIndex(
    (chapter) => chapter._id === currentChapterId
  );

  const currentChapter = chapters[currentChapterIndex];
  const subChapterIndex = currentChapter.subchapters.findIndex(
    (sChapter) => sChapter._id === subChapter._id
  );

  let previous = {};
  let next = {};

  // Handle previous subchapter
  if (subChapterIndex > 0) {
    previous = currentChapter.subchapters[subChapterIndex - 1];
  } else if (currentChapterIndex > 0) {
    let prevChapterIndex = currentChapterIndex;
    do {
      prevChapterIndex--;
      const prevChapter = chapters[prevChapterIndex];
      if (prevChapter.subchapters.length > 0) {
        previous = prevChapter.subchapters[prevChapter.subchapters.length - 1];
        break;
      }
    } while (prevChapterIndex > 0);
  }

  // Handle next subchapter
  if (subChapterIndex < currentChapter.subchapters.length - 1) {
    next = currentChapter.subchapters[subChapterIndex + 1];
  } else {
    let nextChapterIndex = currentChapterIndex;
    while (nextChapterIndex < chapters.length - 1) {
      nextChapterIndex++;
      const nextChapter = chapters[nextChapterIndex];
      if (nextChapter.subchapters.length > 0) {
        next = nextChapter.subchapters[0];
        break;
      }
    }
  }

  return { previous, next };
};
