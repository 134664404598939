import { filter, trim } from 'lodash-es';

import { http } from '../core/services/http';

export const isCohortCodeUsed = (cohortCode) =>
  http.get(`/cohort/is-cohort-code-used/${cohortCode}`);

export const idea = (values) => {
  const registerValues = {
    ...values,
  };
  return http.post('/team/add', registerValues);
};

export const joinTeam = (teamCode) => {
  const values = { teamCode };
  return http.put('/team/join', values);
};

export const removeMember = (userId, teamId) =>
  http.put(`/team/remove-member/${userId}/${teamId}`);

export const mentorLeave = (userId, teamId) =>
  http.put(`/team/mentor-leave/${userId}/${teamId}`);

export const leaveTeamAndReassignInformations = (userId, teamId, newOwnerId) =>
  http.post(`/team/leave-team-and-reassign-information/${userId}/${teamId}`, {
    newOwnerId,
  });

export const editEquityMember = (userId, teamId, values) =>
  http.put(`/team/edit-equity-member/${userId}/${teamId}`, values);

export const getTeam = (teamId) => http.get(`/team/get/${teamId}`);
export const editTeam = (teamId, values) => {
  const registerValues = {
    ...values,
    KPIs: filter(values.KPIs, (elem) => elem !== ''),
    foundsDestination: filter(values.foundsDestination, (elem) => elem !== ''),
  };
  return http.put(`/team/edit/${teamId}`, registerValues);
};

export const uploadTeamImage = (teamId, image) => {
  const formData = new FormData();
  formData.append('image', image);

  const res = http.post(`/team/edit-team-logo/${teamId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const joinCohort = (teamId, values) =>
  http.put(`/team/join-cohort/${teamId}`, {
    cohortCode: trim(values.cohortCode),
  });

export const leaveCohort = (teamId) => http.put(`/team/leave-cohort/${teamId}`);
