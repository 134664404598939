import { Box, FormControl, FormHelperText, FormLabel, Grid } from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';

import ButtonComponent from '../../common/components/ButtonComponent';

import { AddIcon, RemoveIcon } from '../../assets/icons';

import InputField from '../InputField';
import { Content } from './index.styled';

const CounterField = ({
  id,
  label,
  sx = {},
  md = 12,
  maxValue = 3,
  minValue = 1,
  onChangeValue,
  required = false,
  isEditable = false,
}) => {
  const [field, meta] = useField(id);
  const { value } = field;
  const [showEdit, setShowEdit] = useState(false);

  const isIncrementEnabled = value < maxValue;
  const isDecrementEnabled = value > minValue;
  const hasError = meta.touched && !!meta.error && value !== '';

  const handleIncrement = () => {
    if (isIncrementEnabled) {
      onChangeValue(value, value + 1);
    }
  };

  const handleDecrement = () => {
    if (isDecrementEnabled) {
      onChangeValue(value, value - 1);
    }
  };

  const handleOpenEdit = () => setShowEdit(true);

  const handleCloseEdit = () => {
    if (value === '') {
      onChangeValue(value, minValue || 0);
    }

    setShowEdit(false);
  };

  return (
    <Grid item xs={12} md={md} mb={15}>
      <FormControl error={hasError} sx={{ width: '100%' }}>
        <Content sx={sx} hasError={hasError}>
          <FormLabel required={required} sx={{ mb: 0 }}>
            {label}
          </FormLabel>
          <Box display="flex" alignItems="center" gap={8}>
            <ButtonComponent
              icon={<RemoveIcon fill='currentColor' width='12px' height='16px' />}
              size="small"
              sx={{ p: 2, px: 4, minWidth: 'initial' }}
              onClick={handleDecrement}
              disabled={!isDecrementEnabled}
              variant="outlined"
              color="secondary"
            />
            {showEdit && isEditable
              ? (
                <InputField
                  id={id}
                  sx={{ mb: 0, height: '22px' }}
                  onBlur={handleCloseEdit}
                  autoFocus
                  type="number"
                />
              )
              : (
                <Box
                  minWidth={isEditable ? '34px' : 'initial'}
                  sx={{ cursor: isEditable ? 'pointer' : 'initial', height: '22px' }}
                  onClick={handleOpenEdit}
                  display="flex"
                  justifyContent="center"
                  color={hasError ? 'error.main' : 'general.accentLight'}
                >
                  {value}
                </Box>
              )}
            <ButtonComponent
              icon={<AddIcon fill='currentColor' width='16px' height='16px' />}
              size="small"
              sx={{ p: 2, minWidth: 'initial' }}
              onClick={handleIncrement}
              disabled={!isIncrementEnabled}
              variant="outlined"
              color="secondary"
            />
          </Box>
        </Content>
        {hasError && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default CounterField;
