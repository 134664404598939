import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const Menu = styled(MuiMenu)(({ theme }) => `
  & .MuiPaper-root {
    margin-top: ${theme.spacing(1.5)};
    min-width: 170px;
  }
`);

export const LogoutWrapper = styled('div')(({ theme }) => `
  display: flex;
  gap: ${theme.spacing(8)};

  & svg path {
    fill: ${theme.palette.error.main};
  }
`);
