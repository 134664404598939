const MuiMenu = {
  styleOverrides: {
    paper: ({ theme }) => ({
      boxShadow: 'none',
      border: `1px solid ${theme.palette.general.borderAccent2}`,
      '& ::-webkit-scrollbar': {
        width: '5px',
      },
      '& ::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.general.borderAccent,
        borderRadius: '8px',
        backgroundClip: 'padding-box',
      },

      '& ul': {
        padding: 0,
      },
    }),
  },
};

const MuiMenuItem = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&:hover': {
        backgroundColor: theme.palette.general.lightBlue,
      },
      padding: theme.spacing(12, 16),
    }),
  },
};

export {
  MuiMenu,
  MuiMenuItem,
};
