import { Box, IconButton, useTheme } from '@mui/material';
import React from 'react';

import {
  EyeIcon,
  EditIcon,
  TrashIconOutlined,
} from '../../../assets/icons';

import { ClampedRichText } from '../ClampedRichText';
import { ClampedText } from '../ClampedText';
import { ActionsWrapper, Card, Container } from './index.styled';

const DetailsCard = ({
  title,
  description,
  children,
  onView,
  onEdit,
  onDelete,
  ml = 10,
  sx = {},
}) => {
  const theme = useTheme();

  return (
    <Card sx={sx}>
      <Container>
        <Box display="flex" alignItems="center">
          {children}
          <ClampedText
            text={title}
            variant="body1"
            color="primary"
            fontWeight={700}
            sx={{ ml }}
          />
        </Box>
        <ActionsWrapper>
          <IconButton onClick={onView}>
            <EyeIcon fill={theme.palette.general.accentLight} />
          </IconButton>
          <IconButton onClick={onEdit}>
            <EditIcon size="18px" fill={theme.palette.general.accentLight} />
          </IconButton>
          <IconButton color="error" onClick={onDelete}>
            <TrashIconOutlined />
          </IconButton>
        </ActionsWrapper>
      </Container>
      <ClampedRichText
        lineClamp={4}
        text={description}
        variant="body1"
      />
    </Card>
  );
};

export default DetailsCard;
