import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import InformationRow from '../../../../common/components/InformationRow';

import { INDUSTRY_IMPACT_LABELS } from '../../../../constants/labels';

import { IndustryWrapper } from './index.styled';

const IndustryDetails = ({ industry = {} }) => {
  const intl = useIntl();
  const industryInfluence = industry?.influence
    ? INDUSTRY_IMPACT_LABELS[industry.influence]
    : '';

  return (
    <>
      <InformationRow
        label={intl.formatMessage({ id: 'label.type' })}
        value={industry?.title}
      />

      <InformationRow
        richText
        label={intl.formatMessage({ id: 'label.description' })}
        value={industry?.description}
      />

      <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
        {intl.formatMessage({ id: 'label.industryInfluence' })}
      </Typography>
      <IndustryWrapper>
        <Typography variant="body1" color="textSecondary">
          {industryInfluence}
        </Typography>
      </IndustryWrapper>
    </>
  );
};

export default IndustryDetails;
