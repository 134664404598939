import { Formik } from 'formik';
import { isObject } from 'lodash-es';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { SaveIconOutlined } from '../../../../assets/icons';

import InputField from '../../../../fields/InputField';

const AddCharacteristicsForm = (props) => {
  const { onSubmit, characteristic, isSubmitting } = props;
  const intl = useIntl();

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    description: Yup.string().required('Câmp obligatoriu'),
  });

  const defaultValues = {
    description: characteristic?.description || '',
  };

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField
            id="description"
            label={intl.formatMessage({ id: 'label.description' })}
            multiline
            rows={3}
            autoFocus
          />
          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
              disabled={isSubmitting}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
  defaultColumnBMC:
    isObject(state.common.modal.props) &&
    state.common.modal.props.initialValues.columnBMC,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCharacteristicsForm);
