import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import InformationRow from '../../../components/InformationRow';
import LinkComponent from '../../../components/LinkComponent';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { DETAIL_CATEGORY_LABELS } from '../../../../constants/labels';

const GeneralDetail = ({ detail }) => {
  const intl = useIntl();

  return (
    <>
      <InformationRow
        label={intl.formatMessage({ id: 'label.detailName' })}
        value={detail?.title}
      />

      <InformationRow
        richText
        label={intl.formatMessage({ id: 'label.description' })}
        value={detail?.description}
      />

      <Grid container spacing={16}>
        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.category' })}
            value={DETAIL_CATEGORY_LABELS?.[detail?.category]
              ? intl.formatMessage({ id: DETAIL_CATEGORY_LABELS[detail.category] })
              : ''}
          />
        </Grid>

        <Grid item xs={6}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.estimatedCost' })}
            value={detail?.cost}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
            {intl.formatMessage({ id: 'label.links' })}
          </Typography>
          {detail?.links?.length > 0
            ? detail?.links.map((link, index) => (
              <LinkComponent key={index} domain={link.name} />
            ))
            : NOT_APPLICABLE}
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralDetail;
