import { http } from '../core/services/http';

import {
  ADD_SEGMENT_DIMENSION,
  DELETE_SEGMENT_DIMENSION,
  EDIT_SEGMENT_DIMENSION,
  GET_SEGMENT_DIMENSION,
} from '../constants/apiRoutes';
import { formatRoute } from '../utils/formatters';

export const getSegmentDimension = (hypothesisId) => {
  const endpoint = formatRoute(GET_SEGMENT_DIMENSION, { hypothesisId });
  return http.get(endpoint);
};

export const deleteSegmentDimension = (segmentId) => {
  const endpoint = formatRoute(DELETE_SEGMENT_DIMENSION, { segmentId });
  return http.delete(endpoint);
};

export const updateSegmentDimension = (payload) => {
  const endpoint = formatRoute(EDIT_SEGMENT_DIMENSION, { segmentId: payload?._id });
  return http.put(endpoint, payload);
};

export const addSegmentDimension = (payload) => (
  http.post(ADD_SEGMENT_DIMENSION, payload)
);
