export const TARGET_INDICATORS = {
  dailyActiveUsers: 'dailyActiveUsers',
  sam: 'sam',
  som: 'som',
  tam: 'tam',
  burnRate: 'burnRate',
  conversionRate: 'conversionRate',
  customerAquizitionCost: 'customerAquizitionCost',
  earlyAdopters: 'earlyAdopters',
  averageTransactionValue: 'averageTransactionValue',
  customerLifetimeValueLTV: 'customerLifetimeValueLTV',
  customerLifetimeCLV: 'customerLifetimeCLV',
  userStories: 'userStories',
  customerInterviews: 'customerInterviews',
  marketShare: 'marketShare',
  netPromoterScore: 'netPromoterScore',
  dailyUsageRatio: 'dailyUsageRatio',
  customerSatisfaction: 'customerSatisfaction',
  reviews4Stars: 'reviews4Stars',
  innovationRate: 'innovationRate',
  returnOnInvestment: 'returnOnInvestment',
  channelConversionRate: 'channelConversionRate',
  channelReturnOnInvestment: 'channelReturnOnInvestment',
  numberOfQualifiedLeads: 'numberOfQualifiedLeads',
  numberOfLeads: 'numberOfLeads',
  numberOfMarketingLeads: 'numberOfMarketingLeads',
  numberOfActivations: 'numberOfActivations',
  activationRate: 'activationRate',
  marketingLeadToQualifiedLeadRatio: 'marketingLeadToQualifiedLeadRatio',
  qualifiedLeadToCustomerRatio: 'qualifiedLeadToCustomerRatio',
  kFactor: 'kFactor',
  customerReferralRate: 'customerReferralRate',
  returnOnAdvertisingSpending: 'returnOnAdvertisingSpending',
  averageResponseTime: 'averageResponseTime',
  customerEngagementRate: 'customerEngagementRate',
  churnRate: 'churnRate',
  retentionRate: 'retentionRate',
  monthlyRecurringRevenue: 'monthlyRecurringRevenue',
  annualRecurringRevenue: 'annualRecurringRevenue',
  grossMargin: 'grossMargin',
  monthlyActiveUsers: 'monthlyActiveUsers',
  revenueGrowthRate: 'revenueGrowthRate',
  averageOrderSize: 'averageOrderSize',
  marketProductFit: 'marketProductFit',
  investmentInRD: 'investmentInRD',
  onTimeDeliveryRate: 'onTimeDeliveryRate',
  cycleTime: 'cycleTime',
  timeToMarket: 'timeToMarket',
  processInnovationRate: 'processInnovationRate',
  newTechnologyAdoptionRate: 'newTechnologyAdoptionRate',
  initialInvestment: 'initialInvestment',
  revenuePerEmployee: 'revenuePerEmployee',
  employeeProductivity: 'employeeProductivity',
  employeeTurnover: 'employeeTurnover',
  intellectualPropertyAssets: 'intellectualPropertyAssets',
  partnerSatisfaction: 'partnerSatisfaction',
  partnerPerformance: 'partnerPerformance',
  dependabilityRate: 'dependabilityRate',
  jointRevenueGeneration: 'jointRevenueGeneration',
  cashRunway: 'cashRunway',
  operatingCashFlow: 'operatingCashFlow',
  breakEven: 'breakEven',
  netProfitMargin: 'netProfitMargin',
  operatingExpenses: 'operatingExpenses',
  paybackPeriod: 'paybackPeriod',
  costPerUnit: 'costPerUnit',
};

export const DIMENSION_SEGMENT_INDICATORS = [
  TARGET_INDICATORS.tam,
  TARGET_INDICATORS.sam,
  TARGET_INDICATORS.som,
  TARGET_INDICATORS.earlyAdopters,
];
