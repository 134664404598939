import { useState } from 'react';

export function useApi(doFetch, defaultResponse = {}, onError) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(defaultResponse);
  const [error, setError] = useState(null);

  const onFetch = async (payload = null, onSuccess) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await doFetch({ payload });
      setResult(response);

      if (onSuccess) {
        await onSuccess(response);
      }

      return response;
    } catch (e) {
      setError(e);
      if (onError) {
        onError(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return [{ isLoading, result, error }, onFetch];
}
