import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../components/DialogComponent';
import UserStoryForm from '../../forms/UserStoryForm';

const AddUserStoryModal = ({
  onClose,
  isOpen,
  onSubmit,
  formValues,
  characteristics,
}) => {
  const intl = useIntl();

  return (
    <DialogComponent
      open={isOpen}
      handleClose={onClose}
      maxWidth="xs"
      title={intl.formatMessage({ id: formValues ? 'modal.title.editUserStory' : 'modal.title.addUserStory' })}
    >
      <UserStoryForm
        formValues={formValues}
        onSubmit={onSubmit}
        characteristics={characteristics}
      />
    </DialogComponent>
  );
};

export default AddUserStoryModal;
