import { Typography, useTheme } from '@mui/material';

import { PaperClipIcon } from '../../../../assets/icons';

import { Root } from './index.styled';

const TaskDetail = ({
  onPreview,
  detail,
}) => {
  const theme = useTheme();

  const handleClick = () => onPreview(detail, 'DETAIL');

  return (
    <Root onClick={handleClick}>
      <PaperClipIcon fill={theme.palette.primary.main} />
      <Typography variant="body2">
        {detail?.title}
      </Typography>
    </Root>
  );
};

export default TaskDetail;
