import {
  FactoryIcon as FactoryIconSVG,
  GridBoxesIcon,
  Paperclip,
  SearchPersonIcon, UpcommingIcon,
  UpwardsTrend,
} from '../../assets/icons';

export const CONTEXT_ANALYSIS_TABS = [
  {
    key: 'trends',
    label: 'label.trends',
    icon: <UpwardsTrend />,
    id: 'context-analysis-tab-trends',
  },
  {
    key: 'industry',
    label: 'label.industry',
    icon: <FactoryIconSVG />,
    id: 'context-analysis-tab-industry',
  },
  {
    key: 'competition',
    label: 'label.competition',
    icon: <SearchPersonIcon />,
    id: 'context-analysis-tab-competition',
  },
  {
    key: 'swot',
    label: 'label.SWOT',
    icon: <GridBoxesIcon />,
    id: 'context-analysis-tab-swot',
  },
  {
    key: 'details',
    label: 'label.details',
    icon: <Paperclip />,
    id: 'context-analysis-tab-details',
  },
  {
    key: 'conclusions',
    label: 'label.conclusion',
    icon: <UpcommingIcon />,
    id: 'context-analysis-tab-conclusions',
  },
];
