import { LOCALES } from '../languages/langTexts';

export const DEFAULT_LOADING_BUFFER = 1000;
export const DEFAULT_LANGUAGE = LOCALES.ROMANIAN;

export const DEFAULT_FEEDBACK_FORM = 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAO__cQEjX5UMjkwTVdIVkw1UUZSNTRCOUNYVDRPWEo3Ri4u';
export const APP_NAME = 'LaunchPad';
export const NOT_APPLICABLE = '-';

export const LEGAL_CUSTOMER_TYPE = 'legal';
export const INDIVIDUAL_CUSTOMER_TYPE = 'individual';

export const DESCRIPTION_SHORT_MAX_SIZE = 300;
export const DESCRIPTION_LONG_MAX_SIZE = 500;

export const NAVBAR_HEIGHT = 70;
export const SIDEBAR_WIDTH = 90;

export const DEFAULT_DESCRIPTION = `<!DOCTYPE html>
<html>
<head>
  <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
  <style>
    body {
      overflow: hidden;
      font-family: 'Open Sans', serif;
      margin: 0;
      p, h1, h2, h3, h4 {
        margin: 0;
      }
      ul, ol {
        margin: 0;
        padding-left: 20px;
      }
    }
  </style>
</head>
<body>
  
  
</body>
</html>`;
