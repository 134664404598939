import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const equityManagementValidationSchema = Yup.object({
  members: Yup
    .array()
    .of(Yup.object({
      equity: Yup
        .number()
        .typeError(
          <FormattedMessage id='validate.pickNumberBetween' values={{ min: 0, max: 100 }} />
        )
        .min(
          0,
          <FormattedMessage id='validate.pickNumberBiggerThan' values={{ number: 0 }} />
        )
        .max(
          100,
          <FormattedMessage id='validate.pickNumberLessTha' />
        )
        .required(<FormattedMessage id='validate.mandatoryField' />),
      role: Yup
        .array()
        .of(Yup.string())
        .min(1, <FormattedMessage id='validate.mandatoryField' />),
    }))
});
