const MuiSwitch = {
  styleOverrides: {
    root: ({ theme: muiTheme }) => ({
      width: 48,
      height: 26,
      padding: 0,
      margin: 8,

      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor:  muiTheme.palette.general.accentLight,
      },
    }),

    switchBase: ({ theme: muiTheme }) => ({
      padding: 2,
      '&$checked': {
        transform: 'translateX(24px)',
        color: muiTheme.palette.common.white,
        '& + $track': {
          backgroundColor:  muiTheme.palette.general.accentLight,
          border: 'none',
        },
        '&:hover + $track': {
          backgroundColor:  muiTheme.palette.general.accentLight,
        },
      },
      '&$hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    }),

    thumb: ({ theme: muiTheme }) => ({
      width: 22,
      height: 22,
      backgroundColor: muiTheme.palette.common.white,
    }),
    track: ({ theme: muiTheme }) => ({
      borderRadius: 13,
      height: 26,
      opacity: 1,
      backgroundColor:  muiTheme.palette.general.borderGray,
      transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&.MuiSwitch-track': {
        opacity: '1 !important',
      },
    }),
  },
};

export default MuiSwitch;
