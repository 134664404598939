import { Box, Dialog, DialogActions, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { CloseIcon, TrashIconOutlined } from '../../../assets/icons';

import ButtonComponent from '../ButtonComponent';
import { Paper } from './index.styled';

const DialogAlert = ({
  id,
  isModalOpen,
  onClose,
  title,
  content,
  onSubmit,
  maxWidth = 'sm',
  hasActions = false,
  isDisabled = false,
  submitLabel = 'button.delete',
}) => {
  const intl = useIntl();

  return (
    <Dialog
      id={id}
      open={isModalOpen}
      maxWidth={maxWidth}
      onClose={onClose}
      PaperComponent={Paper}
    >
      {title && (
        <Box display="flex" gap={12} alignItems="center" justifyContent="space-between" pr={5}>
          <Typography variant="h2" color="primary">
            {title}
          </Typography>
          <IconButton onClick={onClose} color="primary">
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      <Typography variant="body1" color="primary" my={30}>
        {content}
      </Typography>

      {hasActions && (
        <DialogActions sx={{ justifyContent: 'flex-start', p: 0 }}>
          <ButtonComponent
            icon={<TrashIconOutlined />}
            onClick={onSubmit}
            color="error"
            disabled={isDisabled}
            label={intl.formatMessage({ id: submitLabel })}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogAlert;
