import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(
  Grid,
  { shouldForwardProp: (props) => props !== 'topOffset' },
)(({ theme, topOffset }) =>  `
   border: 1px solid ${theme.palette.general.borderAccent};
   width: 100%;
   border-radius: 5px;
   height: fit-content !important;

   & .htmlEditor {
      height: calc(100vh - ${topOffset});
   }
`);

export const IframeWrapper = styled(
  Grid,
  { shouldForwardProp: (props) => props !== 'topOffset' },
)(({ theme, topOffset }) =>  `
  height: calc(100vh - ${topOffset});
  overflow-y: auto;
  padding: ${theme.spacing(12)};
`);
