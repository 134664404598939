import { Box, Divider } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import HtmlEditor from '../../../common/components/HtmlEditor';
import PageTitle from '../../../common/components/PageTitle';
import { Loading } from '../../../common/styled';
import SectionTitle from '../../components/SectionTitle';

import { addArticle, editArticle, getArticleById } from '../../services/articles';
import { getAllCategories } from '../../services/categories';

import { handleApiError } from '../../../utils/errorUtils';
import { articleSchema } from '../../../utils/validation/articleSchema';
import { formatPayload, getInitialValues } from '../../utils/articleUtils';

import AutocompleteField from '../../../fields/AutocompleteField';
import InputField from '../../../fields/InputField';
import { Root } from './index.styled';

const EditArticle = () => {
  const intl = useIntl();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const articleId = query.get('articleId');
  const isEdit = !!articleId;

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [article, setArticle] = useState({});
  const [categories, setCategories] = useState([]);

  const categoryOptions = useMemo(() => (
    categories.map(({ name, _id: id }) => ({
      value: id,
      label: name,
    }))
  ), [categories]);

  const handleSubmitArticle = async (values) => {
    setIsSubmitting(true);

    try {
      await addArticle(formatPayload(values));
      history.push('/admin/mrl?tab=articles');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditArticle = async (values) => {
    setIsSubmitting(true);

    try {
      await editArticle(articleId, formatPayload(values));
      history.push('/admin/mrl?tab=articles');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const [articleResponse, categoriesResponse] = await Promise.all([
        getArticleById(articleId), getAllCategories()
      ]);
      setCategories(categoriesResponse?.data || []);
      setArticle(articleResponse?.data || {});
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (articleId) {
      fetchData();
    }
  }, [articleId]);

  return (
    <Root className="scrollbar">
      <PageTitle
        title={intl.formatMessage({ id: 'label.backToContent' })}
        onBack={history.goBack}
      />
      <Divider sx={{ borderColor: 'general.borderAccent' }} />
      <Box display="flex" justifyContent="center" mt={20} width="100%">
        <Formik
          initialValues={getInitialValues(article)}
          validationSchema={articleSchema}
          onSubmit={isEdit ? handleEditArticle : handleSubmitArticle}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate>
              <SectionTitle
                title={intl.formatMessage({ id: 'label.article' })}
                isDisabled={!values.title || isSubmitting}
                sx={{ mb: 10 }}
              />
              <InputField id="title" label={intl.formatMessage({ id: 'label.title' })} />
              <InputField id="subtitle" label={intl.formatMessage({ id: 'label.subtitle' })} />
              <AutocompleteField
                id="category"
                placeholder={intl.formatMessage({ id: 'placeholder.pickCategoryForArticle' })}
                label={intl.formatMessage({ id: 'label.category' })}
                options={categoryOptions}
                disabled={!categoryOptions.length}
              />
              <HtmlEditor
                content={values?.description}
                onChange={(value) => setFieldValue('description', value)}
                sx={{ height: '100%' }}
                topOffset="372px"
              />
            </form>
          )}
        </Formik>
      </Box>

      <Loading isLoading={isLoading} />
    </Root>
  );
};

export default EditArticle;
