import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import SegmentDimensionForm from '../SegmentDimensionForm';

const AddSegmentDimension = ({
  isOpen,
  onClose,
  onSubmit,
  formValues,
  isSubmitting,
}) => {
  const intl = useIntl();

  return (
    <DialogComponent
      open={isOpen}
      handleClose={onClose}
      title={intl.formatMessage({
        id: formValues ? 'modal.title.editSegmentDimension' : 'modal.title.addSegmentDimension'
      })}
    >
      <SegmentDimensionForm onSubmit={onSubmit} formValues={formValues} isSubmitting={isSubmitting} />
    </DialogComponent>
  );
};

export default AddSegmentDimension;
