import { convertFromRaw } from 'draft-js';

export const convertDraftToPlainText = (rawContent) => {
  try {
    const contentState = convertFromRaw(JSON.parse(rawContent));
    return contentState.getPlainText();
  } catch (e) {
    return rawContent;
  }
};
