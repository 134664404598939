import TextField from '@mui/material/TextField';

import {
  SearchIcon,
  CloseIcon,
} from '../../assets/icons';

import {
  CloseAdornment,
  SearchAdornment,
} from './index.styled';

export const SearchField = ({
   onClearSearch,
   name,
   search,
   onChange,
   variant = 'outlined',
   onSearch = () => {},
   onClick = () => {},
   onKeyDown = () => {},
   placeholder,
   id,
   disabled = false,
   sx = {},
   InputProps,
   ...remainingProps
}) => (
  <TextField
    {...remainingProps}
    size="small"
    name={name}
    variant={variant}
    onClick={onClick}
    InputProps={{
      startAdornment: (
        <SearchAdornment position="start" className="pointer" onClick={onSearch}>
          <SearchIcon size={18} />
        </SearchAdornment>
      ),
      endAdornment: search && onClearSearch && (
        <CloseAdornment position="start" onClick={onClearSearch} className="pointer">
          <CloseIcon size={18} />
        </CloseAdornment>
      ),
      ...InputProps,
    }}
    value={search}
    onChange={onChange}
    placeholder={placeholder}
    onKeyDown={onKeyDown}
    id={id}
    disabled={disabled}
    sx={sx}
  />
);

export default SearchField;
