import PropTypes from 'prop-types';

import { COMMENT_ROUTES_BY_TYPE, READABLE_ROUTES_BY_TYPE } from './constants';

export const generateCommentPathByType = ({ commentType, commentId }) => {
  const base = COMMENT_ROUTES_BY_TYPE[commentType];

  if (!base) {
    return null;
  }

  if (base.includes('?')) {
    return `${base}&comment=${commentId}`;
  }
  return `${base}?comment=${commentId}`;
};

export const generateReadablePathByType = (commentType) =>
  READABLE_ROUTES_BY_TYPE[commentType];

generateCommentPathByType.propTypes = {
  commentType: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
};
