import { http } from '../core/services/http';

import {
  ADD_TEAM_MEMBER,
  ADD_TEAM_MENTOR,
  DELETE_TEAM_BY_ID,
  EDIT_TEAM_EQUITY,
  GET_ALL_TEAMS,
  GET_TEAM_BY_ID,
  POST_NEW_TEAM,
  PUT_ACCEPT_TEAM_INVITE,
  PUT_REJECT_TEAM_INVITE, REMOVE_TEAM_MEMBER, RESEND_TEAM_MEMBER_INVITATION,
  SAVE_TEAM_INVITATION, TRANSFER_OWNERSHIP
} from '../constants/apiRoutes';
import {
  runDeleteApiRequest,
} from '../utils/api/Api';
import { formatRoute } from '../utils/formatters';

export const addTeam = (data) => http.post(POST_NEW_TEAM, data);

export const addTeamMember = (teamId, data) => (
  http.put(formatRoute(ADD_TEAM_MEMBER,  { teamId }), data)
);

export const addTeamMentor = (teamId, data) => (
  http.put(formatRoute(ADD_TEAM_MENTOR,  { teamId }), data)
);

export const getAllTeams = (userId) => (
  http.get(formatRoute(GET_ALL_TEAMS,  { userId }))
);

export const getTeamById = (params) => http.get(formatRoute(GET_TEAM_BY_ID, params));

export const saveTeamInvitation = (teamId, data) => (
  http.put(formatRoute(SAVE_TEAM_INVITATION,  { teamId }), data)
);

export const acceptTeamInvite = (payload) => http.put(PUT_ACCEPT_TEAM_INVITE, payload);

export const rejectTeamInvite = (payload) => http.put(PUT_REJECT_TEAM_INVITE, payload);

export function deleteTeamById({ payload }) {
  return runDeleteApiRequest({
    endpoint: DELETE_TEAM_BY_ID,
    params: payload.params,
  });
}

export const editTeamEquity = (teamId, payload) => {
  return http.put(formatRoute(EDIT_TEAM_EQUITY, { teamId }), payload);
};

export const deleteTeamMember = (teamId, email) => {
  return http.put(formatRoute(REMOVE_TEAM_MEMBER, { teamId, email }));
};

export const resendTeamMemberInvitation = (teamId, email) => {
  return http.post(formatRoute(RESEND_TEAM_MEMBER_INVITATION, { teamId, email }));
};

export const transferOwnership = (teamId, payload) => {
  return http.put(formatRoute(TRANSFER_OWNERSHIP, { teamId }), payload);
};
