const MuiTooltip = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.general.darkBlue,
      borderRadius: '3px',
      fontSize: '12px',
      lineHeight: '16.34px',
      padding: theme.spacing(8, 12),
      color: theme.palette.common.white,
      boxShadow: '0px 6px 24px rgba(141, 141, 141, 0.35)',
    }),
  },
};

export default MuiTooltip;
