import { http } from '../core/services/http';

import {
  RESET_ONBOARDING_PROGRESS,
  UPDATE_ONBOARDING_PROGRESS,
  SET_ACCOUNT_ROLE
} from '../constants/apiRoutes';
import { runUpdateApiRequest } from '../utils/api/Api';

export const updateOnboardingProgress = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: UPDATE_ONBOARDING_PROGRESS,
    payload: payload.stepData,
  });

export const resetOnboardingProgress = () =>
  runUpdateApiRequest({
    endpoint: RESET_ONBOARDING_PROGRESS,
  });

export const setUserRole = (role) => {
  return http.put(SET_ACCOUNT_ROLE, { role });
};
