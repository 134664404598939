import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PlatformEditorContainer = styled('div')(({ hasError, height, theme }) => `
  width: 100%;
  border: 1px solid ${hasError ? theme.palette.error.main : theme.palette.general.accentLight};
  min-height: 150px;
  height: ${height};
  border-radius: 5px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  resize: both;
  min-width: 100%;
  max-width: 100%;
  
  .rich-wrapper {
    height: calc(100% - 45px);
    overflow-y: hidden;
    padding-top: ${theme.spacing(10)};
  }
  
  .rich-toolbar {
    border: none;
  }
  
  .editor-override {
    padding: ${theme.spacing(0, 10, 10)};
    overflow-y: auto;
    overflow-x: hidden;
        
    > div {
      margin-top: ${theme.spacing(-18)};
    }
  }
  
  .rich-text-editor {
    cursor: text;
    position: relative;
  }
 
  & .rdw-editor-toolbar {
    position: absolute;
    bottom: 0;
    width: 70%;  
    z-index: 0;
    background-color: ${theme.palette.common.white};
  }
  
  & .rdw-link-modal, .rdw-emoji-modal {
    top: auto !important; 
    bottom: 100% !important;  
    transform: translateY(-10px); 
  }
`);

export const Root = styled('div')(({ hasError, theme }) => `
  position: relative;
  
  & .MuiFormHelperText-root {
    ${hasError ? `color: ${theme.palette.error.main}` : ''};
  };
`);

export const Label = styled(
  Typography,
  { shouldForwardProp: (prop) => prop !== 'hasError' }
)(({ hasError, theme }) => `
    font-size: 10.5px;
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(0, 5)};
    color: ${hasError ? theme.palette.error.main : theme.palette.general.accentLight};
`);
