import { Box, Checkbox, Divider } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';

import ButtonComponent from '../../common/components/ButtonComponent';
import CustomMultipleSelectField from '../../common/forms/formComponents/CustomMultipleSelectField';

import CheckboxFieldGroup from '../../fields/CheckboxFieldGroup/index.formik';

const CustomSelectedOptionComponent = ({ label, color }) => (
  <Box
    sx={{
      marginLeft: '10px',
      border: `1px solid ${color}`,
      borderRadius: '10px',
      padding: '2px 10px',
      cursor: 'pointer',
    }}
  >
    {label}
  </Box>
);

const CustomOptionComponent = ({ option, selected, ...otherProps }) => (
  <Box
    {...otherProps}
    sx={{
      display: 'flex',
      alignItems: 'center',
      margin: '5px 0',
      border: `1px solid ${option.color}`,
      borderRight: `7px solid ${option.color}`,
      borderRadius: '10px',
      padding: '2px 10px',
      cursor: 'pointer',
    }}
  >
    <Checkbox
      checked={selected}
      color="default"
      inputProps={{ 'aria-label': 'checkbox with default color' }}
      sx={{
        color: '#74A3FE',
        padding: '0 5px 0 0',
        '& .MuiSvgIcon-root': {
          fontSize: '30px',
        },
      }}
    />
    {option.label}
  </Box>
);

const createCategoriesOptions = (categories) =>
  categories.map((category) => ({
    value: category._id,
    label: category.name,
  }));
const ArticlesFilterForm = ({
  handleSubmit,
  handleClose,
  filters,
  categories,
}) => {
  const initialValues = filters
    ? { visibility: filters.visibility, categories: filters.categories }
    : { visibility: ['all'], categories: [] };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CheckboxFieldGroup
            id="visibility"
            singleSelect
            hideBorder
            label="Vizibilitate"
            checkboxStyles={{
              '& .MuiSvgIcon-root': {
                fontSize: '30px',
              },
            }}
            options={[
              { value: 'all', label: 'Toate' },
              { value: 'hidden', label: 'Ascunse' },
              { value: 'visible', label: 'Vizibile' },
            ]}
          />
          <CustomMultipleSelectField
            id="categories"
            label="Categorii"
            options={createCategoriesOptions(categories)}
            customSelectedOptionComponent={CustomSelectedOptionComponent}
            customOptionComponent={CustomOptionComponent}
          />
          <Divider sx={{ backgroundColor: '#74A3FE' }} />
          <Box
            sx={{
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <ButtonComponent
              color="secondary"
              variant="outlined"
              onClick={handleClose}
              label="Cancel"
            />

            <ButtonComponent
              color="secondary"
              type="submit"
              onClick={handleSubmit}
              label="Salveaza"
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ArticlesFilterForm;
