import { Typography, Box } from '@mui/material';
import { isNumber } from 'lodash-es';

import { NOT_APPLICABLE } from '../../../constants/general';

import RichTextContainer from '../RichTextContainer';
import { Content } from './index.styled';

const InformationRow = ({
  label,
  value,
  richText = false,
  placeholder = NOT_APPLICABLE,
  color = 'textPrimary',
  backgroundColor = '',
  fontWeight = 400,
  sx = {},
  isContained = false,
}) => {
  const valToDisplay = isNumber(value)
    ? value
    : value || placeholder;

  return (
    <Box sx={sx}>
      {label && (
        <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
          {label}
        </Typography>
      )}

      <Content isContained={isContained} backgroundColor={backgroundColor}>
        {richText ? (
          <RichTextContainer text={value} fontSize="14px">
            <Typography variant="body1" color={color}>
              {value || placeholder}
            </Typography>
          </RichTextContainer>
        ) : (
          <Typography variant="body1" color={color} fontWeight={fontWeight}>
            {valToDisplay}
          </Typography>
        )}
      </Content>
    </Box>
  );
};

export default InformationRow;
