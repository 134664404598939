import { Formik } from 'formik';
import { get } from 'lodash-es';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../components/ButtonComponent';
import { DialogActionWrapper } from '../../components/DialogComponent/index.styled';

import { DEFAULT_TOOLBAR_CONFIG } from '../../../constants/richTextEditor';
import { getFormikErrors } from '../../../utils/errorUtils';
import { filterCharacteristics } from '../../../utils/hypothesisUtils';
import { detailUserStorySchema } from '../../../utils/validation/detailSchema';
import { DEFAULT_COUNTER_VALUES } from './CharacteristicRow/constants';
import { getInitialValues } from './utils';

import { SaveIconOutlined } from '../../../assets/icons';

import CounterField from '../../../fields/CounterField';
import InputField from '../../../fields/InputField';
import RenderRichText from '../../../fields/RichTextEditor';
import CharacteristicRow from './CharacteristicRow';

const UserStoryForm = ({ onSubmit, formValues, characteristics }) => {
  const intl = useIntl();

  const handleChangeCounter = (fieldName, values, setValues, oldValue, newValue) => {
    const array = get(values, fieldName) || [];

    if (oldValue > newValue) {
      array.pop();
    } else {
      array.push(DEFAULT_COUNTER_VALUES[fieldName]);
    }

    setValues({ ...values, [fieldName]: array, [`${fieldName}Number`]: newValue });
  };

  const handleValidation = (values) => {
    try {
      detailUserStorySchema.validateSync(values, { abortEarly: false });
    } catch (e) {
      return getFormikErrors(e);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(formValues)}
      onSubmit={onSubmit}
      validate={handleValidation}
    >
      {({ handleSubmit, values, setValues }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CounterField
            id="characteristicsFromCustomerSegmentsNumber"
            label={intl.formatMessage({ id: 'label.confirmedCharacteristics' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter('characteristicsFromCustomerSegments', values, setValues, oldValue, newValue)
            )}
          />
          {values.characteristicsFromCustomerSegments.map((currentSelection, index) => {
            const remainingOptions = filterCharacteristics(
              characteristics?.who,
              values?.characteristicsFromCustomerSegments,
              currentSelection
            );

            return (
              <CharacteristicRow
                key={`characteristicsFromCustomerSegments-${index}`}
                options={remainingOptions}
                parentName="characteristicsFromCustomerSegments"
                position={index}
                values={values}
                label="label.characteristics"
                setValues={setValues}
              />
            );
          })}
          <CounterField
            id="tasksFromCustomerSegmentsNumber"
            label={intl.formatMessage({ id: 'label.confirmedTasksOrProblems' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter('tasksFromCustomerSegments', values, setValues, oldValue, newValue)
            )}
          />
          {values.tasksFromCustomerSegments.map((currentSelection, index) => {
            const remainingOptions = filterCharacteristics(
              characteristics?.task,
              values?.tasksFromCustomerSegments,
              currentSelection
            );

            return (
              <CharacteristicRow
                key={`tasksFromCustomerSegments-${index}`}
                options={remainingOptions}
                parentName="tasksFromCustomerSegments"
                position={index}
                label="label.tasksOrProblems"
                values={values}
                setValues={setValues}
                hasIntensity
                minSize={0}
              />
            );
          })}

          <RenderRichText
            name="taskContext"
            label={intl.formatMessage({ id: 'label.context' })}
            height="200px"
            config={DEFAULT_TOOLBAR_CONFIG}
            isCountVisible
          />

          <CounterField
            id="winsFromCustomerSegmentsNumber"
            label={intl.formatMessage({ id: 'label.confirmedGains' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter('winsFromCustomerSegments', values, setValues, oldValue, newValue)
            )}
          />
          {values.winsFromCustomerSegments.map((currentSelection, index) => {
            const remainingOptions = filterCharacteristics(
              characteristics?.win,
              values?.winsFromCustomerSegments,
              currentSelection
            );

            return (
              <CharacteristicRow
                key={`winsFromCustomerSegments-${index}`}
                options={remainingOptions}
                parentName="winsFromCustomerSegments"
                position={index}
                values={values}
                label="label.gains"
                setValues={setValues}
                hasIntensity
              />
            );
          })}
          <CounterField
            id="painsFromCustomerSegmentsNumber"
            label={intl.formatMessage({ id: 'label.confirmedFears' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter('painsFromCustomerSegments', values, setValues, oldValue, newValue)
            )}
          />
          {values.painsFromCustomerSegments.map((currentSelection, index) => {
            const remainingOptions = filterCharacteristics(
              characteristics?.pain,
              values?.painsFromCustomerSegments,
              currentSelection
            );

            return (
              <CharacteristicRow
                key={`painsFromCustomerSegments-${index}`}
                options={remainingOptions}
                parentName="painsFromCustomerSegments"
                position={index}
                label="label.fears"
                values={values}
                setValues={setValues}
                hasIntensity
              />
            );
          })}

          <RenderRichText
            name="currentSolution"
            label={intl.formatMessage({ id: 'label.currentSolutions' })}
            height="200px"
            config={DEFAULT_TOOLBAR_CONFIG}
            isCountVisible
          />

          <RenderRichText
            name="otherInfo"
            label={intl.formatMessage({ id: 'label.otherInfo' })}
            height="200px"
            config={DEFAULT_TOOLBAR_CONFIG}
            isCountVisible
          />

          <CounterField
            id="linksNumber"
            label={intl.formatMessage({ id: 'label.numberOfLikes' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => (
              handleChangeCounter('links', values, setValues, oldValue, newValue)
            )}
          />
          {values?.links?.map((link, index) => (
            <InputField
              key={`links-${index}`}
              id={`links[${index}].name`}
              label={intl.formatMessage({ id: 'label.link' })}
              placeholder="www.link.com"
            />
          ))}

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default UserStoryForm;
