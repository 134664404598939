import styled from 'styled-components';

export const BmcTimelapseFilterContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #74a3fe;
  padding: 3px 4px;
  border-radius: 5px;
  gap: 5px;
`;

export const BmcPlayButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffd3c6;
  padding: 3px 5px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
`;

export const InputWeek = styled.input`
  color: ${(props) => props.theme.blueAccent};
  border: none;
  width: 25px;
  padding: 0;
  text-align: center;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const BMCHistorySpan = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  color: ${(props) => props.theme.blueAccent};
  justify-content: center;
  gap: 4px;
    
  & input {
      font-weight: 600;
      height: 22px;
      width: 22px;
  }
`;
