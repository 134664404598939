import { Box, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';

import AccordionComponent from '../../../../common/components/AccordionComponent';
import AddButton from '../../../../common/components/AddButton';
import SpinnerComponent from '../../../../common/components/Spinner';

import { EXPERIMENT_TYPE_LABELS } from '../../../../constants/labels';
import { statusPositive } from '../../constants';

import {
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '../../../../assets/icons';

import { ConclusionWrapper } from './index.styled';

const Conclusions = ({
  setIsAddConclusionModalOpen,
  conclusions = [],
  setPreviewedConclusion,
  isLoading,
}) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
      <AccordionComponent
        title={intl.formatMessage({ id: 'label.conclusion' })}
        sx={{ mt: 20 }}
        pl={0}
        actions={<AddButton onClick={() => setIsAddConclusionModalOpen(true)} />}
      >
        <Box>
          {isLoading
            ? <SpinnerComponent size="30px" />
            : (
              <Box display="flex" flexDirection="column" gap={10}>
                {conclusions.length > 0
                  ? (conclusions.map((conclusion) => {
                      const type = EXPERIMENT_TYPE_LABELS?.[conclusion?.experimentType];

                      return (
                        <ConclusionWrapper key={conclusion?._id} onClick={() => setPreviewedConclusion(conclusion)}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {conclusion?.status === statusPositive
                              ? <ThumbsUpIcon fill={theme.palette.success.main} size="16px" />
                              : <ThumbsDownIcon fill={theme.palette.error.secondary} size="16px" />}
                            <Typography variant="body1" fontWeight={600} ml={10}>
                              {type ? intl.formatMessage({ id: type }) : ''}
                            </Typography>
                          </Box>
                        </ConclusionWrapper>
                      );
                    })
                  ) : (
                    <Typography variant="body2" className="italic" color="textSecondary">
                      {intl.formatMessage({ id: 'label.emptyConclusionsSection' })}
                    </Typography>
                  )}
              </Box>
            )
          }
        </Box>
      </AccordionComponent>
  );
};

export default Conclusions;
