import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.palette.general.accentLight};
  color: ${theme.palette.general.accentLight};
  border-radius: 5px;
  height: 32px;
  padding: ${theme.spacing(8, 20)};
  cursor: pointer;
  
  svg > path: {
    fill: ${theme.palette.general.accentLight};
  }
  
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(8)};
    
    & p {
      display: none;
    }
  };
`);
