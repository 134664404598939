import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

const PlansDescription = () => {
  const intl = useIntl();

  return (
    <tbody>
      <tr>
        <Typography variant="h2" color="primary" mt={80}>
          {intl.formatMessage({ id: 'label.ideasAndTeam' })}
        </Typography>
      </tr>

      <tr>
        <td className="pl-0">
          <Typography variant="h3" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.ideas' })}
          </Typography>
          <Typography color="textPrimary">
            {intl.formatMessage({ id: 'label.maxNoOfIdeas' })}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({id: 'label.nrOfIdeas'}, {count: 1})}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.unlimited' })}
          </Typography>
        </td>
      </tr>

      <tr>
        <td className="pl-0">
          <Typography variant="h3" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.collaboration' })}
          </Typography>
          <Typography color="textPrimary">
            {intl.formatMessage({ id: 'label.maxNoOfTeamMembers' })}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.membersPerIdea' }, { count: 1 })}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.membersPerIdea' }, { count: 6 })}
          </Typography>
        </td>
      </tr>

      <tr>
        <td className="pl-0">
          <Typography variant="h3" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.mentoring' })}
          </Typography>
          <Typography color="textPrimary">
            {intl.formatMessage({ id: 'label.maxNoOfMentors' })}
          </Typography>
        </td>
        <td />
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.mentorsPerIdea' }, { count: 3 })}
          </Typography>
        </td>
      </tr>

      <tr>
        <td className="pl-0">
          <Typography variant="h3" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.participationInAcceleratorPrograms'})}
          </Typography>
          <Typography color="textPrimary">
            {intl.formatMessage({ id: 'label.maxNoOfPrograms' })}
          </Typography>
        </td>
        <td />
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.nrOfPrograms' }, { count: 1 })}
          </Typography>
        </td>
      </tr>

      <tr>
        <Typography variant="h2" color="primary" mt={80}>
          {intl.formatMessage({ id: 'label.basicTools' })}
        </Typography>
      </tr>

      <tr>
        <td className="pl-0">
          <Typography variant="h3" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.businessModelCanvas' })}
          </Typography>
          <Typography color="textPrimary">
            {intl.formatMessage({ id: 'label.businessModelCanvasDescription' })}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.nrOfIdeas' }, { count:1 })}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.unlimited' })}
          </Typography>
        </td>
      </tr>

      <tr>
        <td className="pl-0">
          <Typography variant="h3" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.contextAnalysis' })}
          </Typography>
          <Typography color="textPrimary">
            {intl.formatMessage({ id: 'label.maxNoOfTeamMembers' })}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.membersPerIdea' }, { count: 1 })}
          </Typography>
        </td>
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.membersPerIdea' }, { count: 6 })}
          </Typography>
        </td>
      </tr>

      <tr>
        <td className="pl-0">
          <Typography variant="h3" color="primary" gutterBottom>
            {intl.formatMessage({ id: 'label.mentoring' })}
          </Typography>
          <Typography color="textPrimary">
            {intl.formatMessage({ id: 'label.maxNoOfMentors' })}
          </Typography>
        </td>
        <td />
        <td className="vertical-center">
          <Typography variant="h3" color="primary">
            {intl.formatMessage({ id: 'label.mentorsPerIdea' }, { count: 3 })}
          </Typography>
        </td>
      </tr>
    </tbody>
  );
};

export default PlansDescription;
