import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Loading } from '../../../../common/styled';
import Navigation from '../../components/Navigation';
import StepTitle from '../../components/StepTitle';

import { getAllTeams } from '../../../../requests/teams';

import { ideaCategoryOptions } from '../../../../constants/options';
import { handleApiError } from '../../../../utils/errorUtils';

import { DropdownField } from '../../../../fields/DropdownField';
import InputField from '../../../../fields/InputField';
import { Content } from '../../index.styled';

const DescribeIdeaStep = ({
  onSelect,
  stepInfo,
  userProfile,
  onSubmit,
  onBack,
  isDisabled,
  hasBackButton,
}) => {
  const intl = useIntl();
  const [team, setTeam] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const disableFields = userProfile?.userId?.isInvited;

  const initialValues = {
    name: team?.name || '',
    description: team?.description || '',
    ideaCategory: team?.ideaCategory || [],
    id: team?._id || '',
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllTeams(userProfile?.userId?._id);
      const firstTeam = data?.[0];
      setTeam(firstTeam);

      if (userProfile?.userId?.isInvited && firstTeam) {
        onSelect({
          name: firstTeam.name,
          description: firstTeam.description,
          ideaCategory: firstTeam.ideaCategory,
          questionAsAnswer: true,
        });
      }
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values.description) {
      errors.description = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values?.ideaCategory || values?.ideaCategory?.length < 1) {
      errors.ideaCategory = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!Object.keys(errors).length) {
      onSelect({
        ...values,
        questionAsAnswer: true,
        createIdea: !disableFields,
      });
    }

    return errors;
  };

  return (
    <>
      <Content>
        <Box mt={50} mb={30}>
          <StepTitle title={stepInfo.question} />
          {isLoading && <Loading isLoading />}
          <Box mt={50} mb={30} width="60%">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validate={validateForm}
            >
              {({ handleSubmit, values, setFieldValue }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <InputField
                    id="name"
                    label={intl.formatMessage({ id: 'label.ideaName' })}
                    disabled={disableFields}
                  />
                  <InputField
                    id="description"
                    label={intl.formatMessage({ id: 'label.ideaDescription' })}
                    multiline
                    rows={2}
                    disabled={disableFields}
                  />
                  <DropdownField
                    values={values}
                    onSelect={setFieldValue}
                    getOptionLabel={(option) => <span>{option.label}</span>}
                    label={intl.formatMessage({ id: 'label.industry' })}
                    placeholder={intl.formatMessage({ id: 'label.addMaximumThree' })}
                    name="ideaCategory"
                    options={ideaCategoryOptions}
                    md={12}
                    multiple
                    disabled={disableFields}
                  />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Content>

      <Navigation
        onBack={onBack}
        onSubmit={onSubmit}
        disabled={isDisabled}
        hasBackButton={hasBackButton}
      />
    </>
  );
};

export default DescribeIdeaStep;
