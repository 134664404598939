import { Grid, IconButton } from '@mui/material';
import { get } from 'lodash-es';
import { useIntl } from 'react-intl';

import { TrashIconOutlined } from '../../../../assets/icons';

import AutocompleteField from '../../../../fields/AutocompleteField';
import InputField from '../../../../fields/InputField';

const TargetIndicatorRow = ({
  position,
  values,
  setValues,
  sx = {},
  options = [],
}) => {
  const intl = useIntl();

  const handleDelete = () => {
    const array = get(values, 'targetIndicators');
    array.splice(position, 1);
    setValues({ ...values, targetIndicatorsNumber: values?.targetIndicatorsNumber - 1, targetIndicators: array });
  };

  return (
    <Grid container spacing={8} sx={sx} alignItems="flex-start">
      <Grid item xs={12} md={5.5}>
        <AutocompleteField
          id={`targetIndicators[${position}].targetIndicatorType`}
          placeholder={intl.formatMessage({ id: 'placeholder.pickIndicatorType' })}
          label={intl.formatMessage({ id: 'label.indicator' })}
          options={options}
          getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
        />
      </Grid>
      <Grid item xs={5.5} md={2.5}>
        <InputField
          id={`targetIndicators[${position}].value`}
          label={intl.formatMessage({ id: 'label.value' })}
        />
      </Grid>
      <Grid item xs={5.5} md={2.5}>
        <InputField
          id={`targetIndicators[${position}].unitOfMeasurement`}
          label={intl.formatMessage({ id: 'label.um' })}
        />
      </Grid>
      <Grid item xs={1.5} mt={8}>
        {values?.targetIndicators?.length > 0 && (
          <IconButton sx={{ mb: 15 }} color="error" onClick={handleDelete}>
            <TrashIconOutlined />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default TargetIndicatorRow;
