import { Autocomplete, Grid, TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const CustomMultipleSelectField = ({
  customSelectedOptionComponent,
  customOptionComponent,
  label,
  id,
  options,
  placeholder = '',
  md = 12,
}) => {
  const CustomSelectedOptionComponent = customSelectedOptionComponent;
  const CustomOptionComponent = customOptionComponent;

  const [field, meta, helpers] = useField(id);
  // Handle multiple values
  const selectedOptions = options.filter((option) =>
    field?.value?.includes(option.value)
  );

  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <Autocomplete
        multiple
        ListboxProps={{
          className: 'scrollbar',
        }}
        id={id}
        options={options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value} // Add this prop
        value={selectedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            fullWidth
            placeholder={placeholder}
          />
        )}
        // Update this to handle multiple values
        onChange={(event, newValues) => {
          helpers.setValue(newValues.map((item) => item.value));
        }}
        renderOption={(props, option, { selected }) => (
          <CustomOptionComponent
            {...props}
            option={option}
            selected={selected}
          />
        )}
        // Add this to render each selected option as your custom component
        {...(customSelectedOptionComponent && {
          renderTags: (value, getTagProps) =>
            value.map((option, index) => (
              <CustomSelectedOptionComponent
                key={option.value}
                {...option}
                {...getTagProps({ index })}
              />
            )),
        })}
      />
    </Grid>
  );
};

export default CustomMultipleSelectField;
