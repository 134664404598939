import { IconButton, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import Iframe from '../../../common/components/Iframe';
import {
  MrlContainer,
  MrlHeader,
  ProgressBarContainer,
  ProgressElement,
  ProgressCounter,
  MrlStepContainer,
} from '../styled';
import {
  MarginContainer,
  MrlTaskContainer,
} from '../styled/MrlStyled';

import { loadMrl } from '../store';

import { capitalizeFirstLetter } from '../../../utils/genericHelpers';
import { compareOrder } from '../../../utils/sort';

import { ChevronLeft, BookIcon } from '../../../assets/icons';

import MrlStep from './MrlStep';
import MrlTask from './MrlTask';

const Mrl = ({
  loadMrl,
  mrlData = [],
  isEditing,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState();
  const [closeTransition, setCloseTransition] = useState(false);
  const [selectedTaskToOpen, setSelectedTaskToOpen] = useState();
  const { push } = useHistory();

  const calculateCompletionStatus = (indexOfStep) => {
    const selectedStep = mrlData[indexOfStep - 1];
    const totalTasks = selectedStep.tasks.length;
    const completedTasks = selectedStep.tasks.filter(
      (task) => task.isDone
    ).length;
    return `${completedTasks}/${totalTasks}`;
  };

  useEffect(() => {
    loadMrl();
  }, [isEditing]);

  const handleTransition = (index) => {
    if (!closeTransition) {
      if (!selectedIndex) {
        return {};
      }
      if (index !== selectedIndex) {
        return {
          height: 0,
          display: 'none',
          transition: 'width 0.3s, height 0.2s',
        };
      }
      return {
        height: 0,
        transition: 'all .25s',
      };
    }
    if (selectedIndex) {
      return { height: '4vh' };
    }
    return {
      opacity: selectedIndex ? 0 : 1,
      transition: 'height 0.25s, width 1s, opacity 1.5s',
    };
  };

  const handleDescriptionStyle = () => {
    if (!selectedIndex) {
      return { display: 'none' };
    }
    return { transition: 'all .2s', transitionDelay: '.3s' };
  };

  const sortItems = (list) => list.sort(compareOrder);

  const handleMrlTaskOpen = (task) => {
    if (task === selectedTaskToOpen) {
      setSelectedTaskToOpen();
      return;
    }
    setSelectedTaskToOpen(task);
  };

  const handleBack = (e) => {
    setCloseTransition(true);
    setSelectedTaskToOpen('');
    setSelectedIndex(undefined);
    e.stopPropagation();
  };

  const sectionTitle = useMemo(() => (
    mrlData && selectedIndex
      ? `${capitalizeFirstLetter(mrlData[selectedIndex - 1].name)} | ${calculateCompletionStatus(selectedIndex)}`
      : ''
  ), [mrlData, selectedIndex]);

  return (
    <MrlContainer id="mrl-container">
      <MrlHeader>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {!selectedIndex ? (
            <>
              <BookIcon fill={theme.palette.primary.main} />
              <Typography variant="body1" color="primary" ml={10} fontWeight={600}>
                {intl.formatMessage({ id: 'label.mrlGuide' })}
              </Typography>
            </>
          ) : (
            <>
              <IconButton color="primary" sx={{ p: 0 }} onClick={handleBack}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="body1" color="primary" ml={5} fontWeight={600}>
                {sectionTitle}
              </Typography>
            </>
          )}
        </Box>
      </MrlHeader>

      <ProgressBarContainer>
        <ProgressElement width={`${(4 / 15) * 18}vw`} />
        <ProgressCounter>10%</ProgressCounter>
      </ProgressBarContainer>

      <MrlStepContainer className="scrollbar">
        {!selectedIndex && sortItems(mrlData).map((item, index) => (
          <div
            key={index}
            id={`mrl-${index}`}
            style={handleTransition(index + 1)}
            onClick={() => {
              if (!selectedIndex) {
                setSelectedIndex(index + 1);
                setCloseTransition(false);
              }
            }}
          >
            <MarginContainer display="flex" alignItems="center">
              <MrlStep
                number={index}
                title={item.name}
                isCompleted={false}
                tasks={item.tasks}
                description={item.description}
                subtitle={item.subtitle}
                isOpen={!selectedIndex}
                completionStatus={calculateCompletionStatus(index + 1)}
              />
            </MarginContainer>
          </div>
        ))}

        <div style={handleDescriptionStyle()}>
          <MrlTaskContainer>
            {selectedIndex && (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (e.target.tagName === 'A') {
                    if (
                      e.target.href.toString().includes('youtube') ||
                      e.target.href.toString().includes('vimeo')
                    ) {
                      push(`?video-player=${e.target.href}`);
                    } else {
                      window.location.replace(e.target.href);
                    }
                  }
                }}
              >
                <Iframe title="Preview" content={mrlData[selectedIndex - 1].description} hasDynamicHeight />
              </div>
            )}
          </MrlTaskContainer>

          <Box display="flex" flexDirection="column" gap={10} mt={10}>
            {mrlData[selectedIndex - 1] && mrlData[selectedIndex - 1].tasks.map((task) => (
              <MrlTask
                key={task._id}
                isCompleted={task.isDone}
                title={task.name}
                link={task.link}
                buttonName={task.buttonName}
                description={task.description}
                descriptionHtml={task.descriptionHtml}
                isOpen={selectedTaskToOpen === task.name}
                onMrlTaskOpen={handleMrlTaskOpen}
                push={push}
              />
            ))}
          </Box>
        </div>
      </MrlStepContainer>
    </MrlContainer>
  );
};

const mapDispatchToProps = {
  loadMrl,
};

const mapStateToProps = (state) => ({
  mrlLoading: state.mrl.isLoading,
  mrlData: state.mrl.mrls,
  isEditing: state.mrl.isEditing,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mrl));
