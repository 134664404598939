import React from 'react';
import { useIntl } from 'react-intl';

import {
  ConditionsHeading,
  ConditionsContainer,
  ConditionsText,
  ConditionsTitle,
  Anchor,
} from './index.styled';

const Conditions = () => {
  const intl = useIntl();
  return (
    <>
      <ConditionsContainer className="scrollbar">
        <ConditionsTitle>{intl.formatMessage({ id: 'terms.privacy' })}</ConditionsTitle>
        <ConditionsHeading>{intl.formatMessage({ id: 'terms.scope' })}</ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.privacy.description' })}
          <Anchor
            href="https://eur-lex.europa.eu/content/cookies/cookies-notice.html?locale=en"
            target="_blank"
          >
            Eur-lex
          </Anchor>
          .
        </ConditionsText>
        <ConditionsHeading>{intl.formatMessage({ id: 'terms.whatInfoDoWeCollect' })}</ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.whatInfoDoWeCollect.description1' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.whatInfoDoWeCollect.description2' })}
          <Anchor
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop%hl=ro"
            target="_blank"
          >
            Google cookies
          </Anchor>
          {intl.formatMessage({ id: 'terms.andHere' })}
          <Anchor
            href="https://www.facebook.com/policies/cookies/"
            target="_blank"
          >
            Facebook cookies
          </Anchor>
          ).
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.whatInfoDoWeCollect.description3' })}
        </ConditionsText>
        <ConditionsHeading>{intl.formatMessage({ id: 'terms.howDoWeUseTheInfo' })}</ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1' })}
        </ConditionsText>
        <ConditionsText>
          <ul style={{ marginLeft: '24px' }}>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item1' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item2' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item3' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item4' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item5' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item6' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item7' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item8' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description1.item9' })}
            </li>
          </ul>
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description2' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description3' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.howDoWeUseTheInfo.description4' })}
        </ConditionsText>
        <ConditionsHeading>
          {intl.formatMessage({ id: 'terms.howDoWePassYourDataToOthers' })}
        </ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.howDoWePassYourDataToOthers.description1' })}
        </ConditionsText>
        <ConditionsHeading>
          {intl.formatMessage({ id: 'terms.securityAndDataRetentionPeriod' })}
        </ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.securityAndDataRetentionPeriod.description1' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.securityAndDataRetentionPeriod.description2' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.securityAndDataRetentionPeriod.description3' })}
        </ConditionsText>
        <ConditionsHeading>{intl.formatMessage({ id: 'terms.intellectualPropertyRight' })}</ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.intellectualPropertyRight.description1' })}
        </ConditionsText>
        <ConditionsHeading>{intl.formatMessage({ id: 'terms.yourRightsAndObligations' })}</ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.yourRightsAndObligations.description1' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.yourRightsAndObligations.description2' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.yourRightsAndObligations.description3' })}
        </ConditionsText>
        <ConditionsHeading>
          {intl.formatMessage({ id: 'terms.interactionViaWebsite' })}
        </ConditionsHeading>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.interactionViaWebsite.description1' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2' })}
        </ConditionsText>
        <ConditionsText>
          <ul style={{ marginLeft: '24px' }}>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item1' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item2' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item3' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item4' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item5' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item6' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item7' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item8' })}
            </li>
            <li>
              {intl.formatMessage({ id: 'terms.interactionViaWebsite.description2.item9' })}
            </li>
          </ul>
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.interactionViaWebsite.description3' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.interactionViaWebsite.description4' })}
        </ConditionsText>
        <ConditionsText>
          {intl.formatMessage({ id: 'terms.interactionViaWebsite.description5' })}
        </ConditionsText>
        <ConditionsText>
          <ul style={{ marginLeft: '24px' }}>
            <li>
              Posta: Cluj Napoca, str. Ferdinand nr. 22-26, et. 3, Cluj, Romania
            </li>
            <li>
              Email: contact
              <Anchor href="mailto:contact@ptic.ro"> ptic.ro </Anchor>
            </li>
            <li>Mobil: {'{0}'}742 61 76 76</li>
          </ul>
        </ConditionsText>
        <ConditionsText>Ultima actualizare: 16.07.2020</ConditionsText>
      </ConditionsContainer>
    </>
  );
};

export default Conditions;
