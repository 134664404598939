import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactCountryFlag from 'react-country-flag';

export const LanguageOption = styled('div')(({ theme, isSelected }) => `
  padding: ${theme.spacing(8, 16)};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${theme.spacing(8)};
  background-color: ${isSelected ? alpha(theme.palette.primary.main, 0.1) : theme.palette.common.white};
  
  &:hover {
    background-color: ${alpha(theme.palette.primary.main, 0.1)};
  }
`);

export const CountryFlag = styled(ReactCountryFlag)(() => `
  height: 24px;
  width: 24px;
`);
