import { get } from 'lodash-es';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import DialogComponent from '../../../../common/components/DialogComponent';
import JoinTeamForm from '../../components/JoinTeamForm';

import { loadUserTeams } from '../../../../common/store';
import { joinTeam } from '../../../../store/idea/teamSlice';

const JoinTeamModal = (props) => {
  const intl = useIntl();
  const { joinTeam, isOpen, setIsModalOpen, loadUserTeams, userId } = props;
  const onSubmit = async (teamCode) => {
    await joinTeam(teamCode);
    setIsModalOpen(false);
    await loadUserTeams(userId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DialogComponent
      title={intl.formatMessage({ id: 'modal.title.joinTeam' })}
      open={isOpen}
      handleClose={handleCloseModal}
    >
      <JoinTeamForm onSubmit={onSubmit} />
    </DialogComponent>
  );
};
const mapStateToProps = (state) => ({
  userId: get(state.account, 'user._id'),
  isJoining: state.team.isJoining,
});

const mapDispatchToProps = {
  loadUserTeams,
  joinTeam,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JoinTeamModal)
);
