import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash-es';

import { Intensity } from './index.styled';

const SegmentInfo = ({ name, intensity = null }) => (
  <Box display="flex" gap={4} alignItems="center">
    {!isNil(intensity) && (
      <Intensity variant="body1" fontWeight={600} color="textSecondary">
        {intensity}
      </Intensity>
    )}

    <Typography variant="body1">
      {name}
    </Typography>
  </Box>
);

export default SegmentInfo;
