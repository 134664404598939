import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { DESCRIPTION_LONG_MAX_SIZE } from '../../constants/general';
import { USER_ROLES } from '../../constants/roles';

import { getDescriptionSchema } from './genericSchema';

export const getProfileSchema = (role) => (
  Yup.object({
    firstName: Yup.string().required(<FormattedMessage id="validate.mandatoryField" />),
    lastName: Yup.string().required(<FormattedMessage id="validate.mandatoryField" />),
    title: Yup.string().required(<FormattedMessage id="validate.mandatoryField" />),
    birthDate: Yup.string(),
    address: Yup.string(),
    phone: Yup.string(),
    profilePicture: Yup.string(),
    photo: Yup.mixed(),
    appointmentsCalendar: Yup.string(),
    linkedIn: Yup.string().url(<FormattedMessage id="validate.invalidUrl" />),
    about: getDescriptionSchema(DESCRIPTION_LONG_MAX_SIZE)
      .required(<FormattedMessage id="validate.mandatoryField" />),
    ...(role === USER_ROLES.Mentor ? {
      appointmentsCalendar: Yup.string(),
      expertise: Yup
        .array()
        .of(Yup.object({
          title: Yup.string().required(<FormattedMessage id="validate.mandatoryField" />),
          years: Yup.string().required(<FormattedMessage id="validate.mandatoryField" />),
        }))
        .required(<FormattedMessage id="validate.mandatoryField" />),
      rewardPreference: Yup
        .array()
        .of(Yup.object({
          title: Yup.string().required(<FormattedMessage id="validate.mandatoryField" />),
          value: Yup.string().required(<FormattedMessage id="validate.mandatoryField" />),
        }))
        .required(<FormattedMessage id="validate.mandatoryField" />),
    } : {})
  })
);
