import { Divider } from '@mui/material';
import { useIntl } from 'react-intl';

import DrawerComponent from '../../../../common/components/DrawerComponent';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import HypothesisCard from '../../../../common/components/HypothesisCard';

import { BMC_LABELS } from '../../../../constants/labels';
import { getHypothesisColors } from '../../utils';

export const BmcSegmentDrawer = ({
  isDrawerOpen,
  setIsOpen,
  selectedSegment = { items: [], column: '' },
}) => {
  const intl = useIntl();
  const title = BMC_LABELS?.[selectedSegment?.column] || '';

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  return (
    <DrawerComponent
      isOpen={isDrawerOpen}
      onClose={handleCloseDrawer}
    >
      <DrawerTitle
        title={title ? intl.formatMessage({ id: title }) : '-'}
        onClose={handleCloseDrawer}
      />

      <Divider sx={{ mb: 20, mt: 12, borderColor: 'general.accentLight2' }} />

      <div>
        {selectedSegment.items.map((item, idx) => {
          const isCustomerSegment = item?.columnBMC === 'customerSegments';

          return (
            <HypothesisCard
              customerSegment={isCustomerSegment}
              key={idx}
              hypothesis={item}
              allowClick
              backgroundColor={
                isCustomerSegment
                  ? item?.color
                  : getHypothesisColors(item)
              }
            />
          );
        })}
      </div>
    </DrawerComponent>
  );
};
