import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
`;

export const Loading = ({ isLoading }) => (
  <Overlay isLoading={isLoading}>
    <CircularProgress />
  </Overlay>
);
