import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { login } from '../requests/auth';
import { loadProfile } from '../requests/userProfile';
import { completeLogin } from '../store/auth/login';

import { USER_ROLES } from '../constants/roles';
import { handleApiError } from '../utils/errorUtils';

export const useLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitedUserToken = queryParams.get('redirectToAccept') || false;
  const [showResend, setShowResend] = useState(false);
  const [email, setEmail] = useState('');

  const onLogin = async ({ email, password }, { setSubmitting }) => {
    try {
      setEmail(email);
      const user = await login(email, password);
      dispatch(completeLogin(user));
      ReactGA.gtag('event', 'login', { method: 'Email' });

      const redirect  = invitedUserToken ? `/join?invitedUser=${invitedUserToken}` : '/';

      if (user.role === USER_ROLES.Admin) {
        history.push(redirect);
      } else {
        const { data: profile } = await loadProfile();
        history.push(profile.hasCompletedQuestionnaire ? redirect : '/form');
      }
    } catch (e) {
      if (e?.response?.data?.[0]?.name === 'Cont neconfirmat') {
        setShowResend(true);
      } else {
        handleApiError(e);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return {
    onLogin,
    email,
    showResend,
    setShowResend,
  };
};
