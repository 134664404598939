import { forEach } from 'lodash-es';
import { toast } from 'react-toastify';

// Actions
const SUCCESS = 'core/notifications/SUCCESS';
const WARNING = 'core/notifications/WARNING';
const ERROR = 'core/notifications/ERROR';

// Initial State
const initialState = {};

export const notifyErrorWithoutDispatch = (message) => {
  toast.error(message, {
    className: 'toastify toastify-type-error',
    bodyClassName: 'toastify-body',
    progressClassName: 'toastify-progress',
    autoClose: 4500,
  });
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      toast.success(action.message, {
        className: 'toastify toastify-type-success',
        bodyClassName: 'toastify toastify-body',
        progressClassName: 'toastify-progress',
        autoClose: 2500,
      });
      return state;

    case WARNING:
      toast.warn(action.message, {
        className: 'toastify toastify-type-warning',
        bodyClassName: 'toastify-body',
        progressClassName: 'toastify-progress',
        autoClose: 2500,
      });
      return state;

    case ERROR:
      notifyErrorWithoutDispatch(action.message);
      return state;

    default:
      return state;
  }
};

// Action Creators
export const notifySuccess = (message) => ({
  type: SUCCESS,
  message,
});

export const notifyWarning = (message) => ({
  type: WARNING,
  message,
});

const buildErrorMessage = (err) => {
  const name = err.name ? `${err.name}: ` : '';
  const message = err.message || 'Unknown error';
  return `${name}${message}`;
};

const dispatchError = (dispatch, message) => {
  dispatch({ type: ERROR, message });
};

export const notifyError = (error) => (dispatch) => {
  if (Array.isArray(error)) {
    forEach(error, (err) => {
      const errorMessage = buildErrorMessage(err);
      dispatchError(dispatch, errorMessage);
    });
  } else {
    const errorMessage = buildErrorMessage(error);
    dispatchError(dispatch, errorMessage);
  }
};
