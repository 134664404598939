import { Box } from '@mui/material';
import MuiCardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const CardMedia = styled(MuiCardMedia)(() =>  `
   height: 130px;
   width: 100%;
   border-radius: 10px;
`);

export const Content = styled(Box)(({ theme }) =>  `
  height: 130px;
  border-radius: 10px;
  border: 1px solid ${theme.palette.general.accentLight};
  padding: ${theme.spacing(20)};
`);
