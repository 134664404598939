import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../components/ButtonComponent';
import { DialogActionWrapper } from '../../components/DialogComponent/index.styled';

import { TASK_STATUS_OPTIONS } from '../../../constants/options';
import { DEFAULT_TOOLBAR_CONFIG } from '../../../constants/richTextEditor';

import { SaveIconOutlined } from '../../../assets/icons';

import AutocompleteField from '../../../fields/AutocompleteField';
import InputField from '../../../fields/InputField';
import RadioFieldGroup from '../../../fields/RadioFieldGroup';
import RenderRichText from '../../../fields/RichTextEditor';

const TaskForm = ({
  handleSubmit,
  formValues,
  handleUpdate,
  teamMembers,
  isEditMode,
  editedTask,
}) => {
  const intl = useIntl();
  const initialValues = formValues || {
    title: editedTask?.title || '',
    description: editedTask?.description || '',
    assignedTo: editedTask?.userId || '',
    status: editedTask?.status || '',
  };

  const schema = Yup.object({
    title: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    assignedTo: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    status: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={isEditMode ? handleUpdate : handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField id="title" label={intl.formatMessage({ id: 'label.taskTitle' })} />
          <RenderRichText
            name="description"
            label={intl.formatMessage({ id: 'label.addADescription' })}
            height="200px"
            config={DEFAULT_TOOLBAR_CONFIG}
          />
          <AutocompleteField
            id="assignedTo"
            placeholder={intl.formatMessage({ id: 'placeholder.pickPersonInCharge' })}
            label={intl.formatMessage({ id: 'label.allocatedTo' })}
            options={teamMembers}
          />
          <RadioFieldGroup
            id="status"
            options={TASK_STATUS_OPTIONS}
            fullWidth
            label={intl.formatMessage({ id: 'label.study' })}
          />
          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default TaskForm;
