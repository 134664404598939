import Tooltip from '@mui/material/Tooltip';
import React, { useRef, useState, useEffect } from 'react';

import { ClampedBox } from './index.styled';

export const ClampedText = ({
  text,
  lineClamp = 1,
  withTooltip = false,
  ...remainingProps
}) => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    const isOverflowing = () => {
      const element = titleRef.current;
      return element.scrollHeight > element.clientHeight;
    };
    setIsTextOverflowing(isOverflowing());
  }, [text]);

  const textContent = (
    <ClampedBox {...remainingProps} ref={titleRef} lineClamp={lineClamp}>
      {text}
    </ClampedBox>
  );

  return (
    <>
      {isTextOverflowing && withTooltip ? (
        <Tooltip
          title={text}
          arrow={false}
          classes={{ tooltip: 'clamped-tooltip' }}
        >
          {textContent}
        </Tooltip>
      ) : textContent}
    </>
  );
};
