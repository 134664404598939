import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import ArticlesSideGuide from '../../components/ArticlesSideGuide';
import ChaptersSideGuide from '../../components/ChaptersSideGuide';

import { useApi } from '../../../../hooks/useApi';
import { getAllArticles } from '../../../../requests/articles';
import { getAllChapters, markChapterAsRead } from '../../../../requests/chapters';

import { handleApiError } from '../../../../utils/errorUtils';

import { SideGuideContainer } from './index.styled';

const SideGuide = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const isAdminPage = pathname.includes('admin');

  const [tab, setTab] = useState('');
  const [chaptersRead, setChaptersRead] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState({
    panel0: true,
  });

  const queryParams = new URLSearchParams(window.location?.search) || '';

  const subchapterIdFromUrl = queryParams.get('id');
  const chapterIdFromUrl = queryParams.get('chapterId');

  const [{ result: chaptersListResult = [] }, fetchAllChapters] = useApi(getAllChapters);
  const [{ result: articlesListResult = [] }, fetchAllArticles] = useApi(getAllArticles);
  const userProfile = useSelector(state => state.userProfile.userProfile) || [];

  useEffect(() => {
    setChaptersRead(userProfile?.chaptersRead || []);
  }, [userProfile?.chaptersRead]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordions((prevExpandedAccordions) => {
      // Copy the current state
      const newExpandedAccordions = { ...prevExpandedAccordions };

      // If the panel is expanded, add it to the state, otherwise remove it
      if (isExpanded) {
        newExpandedAccordions[panel] = true;
      } else {
        delete newExpandedAccordions[panel];
      }

      return newExpandedAccordions;
    });
  };

  const addIdToUrl = (key, id) => {
    if (id) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set(key, id);
      history.push(currentUrl.pathname + currentUrl.search);
    }
  };

  const handleChaptersFetch = async (payload) => {
    await fetchAllChapters(payload);
  };

  const handleArticlesFetch = async () => {
    const payload = { isVisible: true };
    await fetchAllArticles(payload);
  };

  const handleSubchapterClick = (id, chapterId) => {
    addIdToUrl('id', id);
    addIdToUrl('chapterId', chapterId);
  };

  const handleArticleClick = (id) => {
    addIdToUrl('id', id);
  };

  useEffect(() => {
    const tabParam = queryParams.get('tab');
    setTab(tabParam);
  }, [queryParams]);

  useEffect(() => {
    if (tab === 'chapters') {
      isAdminPage
        ? handleChaptersFetch({})
        : handleChaptersFetch({ isVisible: true });
    }
    if (tab === 'articles') {
      handleArticlesFetch();
    }
  }, [tab]);

  useEffect(() => {
    if (!!chaptersListResult.length && tab === 'chapters') {
      addIdToUrl('id', chaptersListResult[0].subchapters[0]?._id);
      addIdToUrl('chapterId', chaptersListResult[0]?._id);
    }

    if (!!articlesListResult.length && tab === 'articles') {
      addIdToUrl('id', articlesListResult[0]?._id);
    }
  }, [chaptersListResult, articlesListResult]);

  useEffect(() => {
    if (chaptersListResult.length && chapterIdFromUrl) {
      const chapterIndex = chaptersListResult.findIndex(
        (chapter) => chapter?._id === chapterIdFromUrl
      );
      setExpandedAccordions((prevExpandedAccordions) => {
        const newExpandedAccordions = { ...prevExpandedAccordions };
        newExpandedAccordions[`panel${chapterIndex}`] = true;
        return newExpandedAccordions;
      });
    }
  }, [chapterIdFromUrl]);

  const subchapterId = subchapterIdFromUrl || chaptersListResult[0]?.subchapters[0]?._id;

  const handleReadChapter = async ({ target: { checked } }, chapterId) => {
    try {
      await markChapterAsRead(chapterId, { isRead: checked });

      setChaptersRead(checked
        ? [...chaptersRead, chapterId]
        : chaptersRead?.filter((id) => id !== chapterId)
      );
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <SideGuideContainer>
      <Box display="flex" flexDirection="column" height="100%">
        {tab === 'chapters' && (
          <ChaptersSideGuide
            chaptersListResult={chaptersListResult}
            expandedAccordions={expandedAccordions}
            handleAccordionChange={handleAccordionChange}
            subchapterId={subchapterId}
            isAdminPage={isAdminPage}
            handleSubchapterClick={handleSubchapterClick}
            onReadChapter={handleReadChapter}
            chaptersRead={chaptersRead}
          />
        )}
        {tab === 'articles' && (
          <ArticlesSideGuide
            handleArticleClick={handleArticleClick}
            articlesListResult={articlesListResult}
          />
        )}
      </Box>
    </SideGuideContainer>
  );
};

export default SideGuide;
