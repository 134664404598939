import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';

import { ClampedBox } from './index.styled';

export const ClampedRichText = ({ text, lineClamp, ...remainingProps }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    try {
      const contentObject = JSON.parse(text);
      const generatedHtml = draftToHtml(contentObject);
      setHtmlContent(generatedHtml);
    } catch (error) {
      setHtmlContent(text);
    }
  }, [text]);

  return <ClampedBox component="div" {...remainingProps} lineClamp={lineClamp}>{parse(htmlContent)}</ClampedBox>;
};
