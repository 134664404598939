import { isEqual } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Router } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { TourProvider } from './context/tourContext';
import JoyRideTour from './modules/tour/components/JoyRideTour';

import { loadUserTeams } from './common/store';
import { currentTeamIdSelector } from './common/store/userTeamsSelector';
import history from './core/services/history';
import { getAllComments } from './modules/commentsAndNotifications/store';
import { loginDetailsSelector } from './store/auth/selectors';
import { userLanguageSelector } from './store/userProfile/selectors';
import { loadProfile } from './store/userProfile/userProfileSlice';

import { USER_ROLES } from './constants/roles';
import { mergeWithDefaultLanguage } from './languages/utils';
import { getUserLanguage } from './utils/storageUtils';

import AppRouter from './AppRouter';

const AppComponent = () => {
  const dispatch = useDispatch();
  const userLanguage = useSelector(userLanguageSelector);
  const loginDetails = useSelector(loginDetailsSelector);
  const teamId = useSelector(currentTeamIdSelector, isEqual);
  const user = useSelector(state => state.account?.user);
  const [currentLanguage, setCurrentLanguage] = useState(mergeWithDefaultLanguage(getUserLanguage()));

  useEffect(() => {
    if (loginDetails?.isLoggedIn) {
      dispatch(loadProfile());
    }
  }, [loginDetails]);

  useEffect(() => {
    if (user?._id && teamId && user?.role === USER_ROLES.Founder) {
      const filterParams = { isMainThread: true };
      dispatch(getAllComments({ teamId, filterParams, userId: user?._id }));
    }
  }, [user?._id, teamId]);

  useEffect(() => {
    if (user?._id) {
      dispatch(loadUserTeams(user?._id));
    }
  }, [user?._id]);

  useEffect(() => {
    if (userLanguage) {
      const selectedAppLocale = mergeWithDefaultLanguage(userLanguage);
      setCurrentLanguage(selectedAppLocale);
    }
  }, [userLanguage]);

  return (
    <IntlProvider
      locale={currentLanguage.locale}
      defaultLocale={currentLanguage.locale}
      messages={currentLanguage.messages}
    >
      <TourProvider>
        <Router history={history}>
          <JoyRideTour />
          <Switch>
            <Route component={AppRouter} />
          </Switch>
        </Router>
        <ToastContainer transition={Slide} closeButton={false} />
      </TourProvider>
    </IntlProvider>
  );
};

export default AppComponent;
