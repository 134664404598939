import { isNil } from 'lodash-es';

import { DIMENSION_SEGMENT_INDICATORS, TARGET_INDICATORS } from '../../constants/targetIndicators';

export const getTypeLabels = (type) => {
  if (type === TARGET_INDICATORS.tam) {
    return {
      richLabel: 'label.shortTAM',
      inputLabel: 'label.tamDimension',
    };
  }

  if (type === TARGET_INDICATORS.sam) {
    return {
      richLabel: 'label.shortSAM',
      inputLabel: 'label.samDimension',
    };
  }

  if (type === TARGET_INDICATORS.som) {
    return {
      richLabel: 'label.shortSOM',
      inputLabel: 'label.somDimension',
    };
  }

  if (type === TARGET_INDICATORS.earlyAdopters) {
    return {
      richLabel: 'label.earlyAdopters',
      inputLabel: 'label.earlyAdoptersDimension',
    };
  }
};

export const getSegmentInfo = (type) => {
  if (type === TARGET_INDICATORS.tam) {
    return {
      bgColor: 'general.accentLight',
      size: 450,
      title: 'label.totalMarketTAM',
      placeholder: 'placeholder.totalMarketTAM',
    };
  }

  if (type === TARGET_INDICATORS.sam) {
    return {
      bgColor: 'general.orange',
      size: 350,
      title: 'label.realMarketSAM',
      placeholder: 'placeholder.realMarketSAM',
    };
  }

  if (type === TARGET_INDICATORS.som) {
    return {
      bgColor: 'general.yellow',
      size: 250,
      title: 'label.wonMarketSOM',
      placeholder: 'placeholder.wonMarketSOM',
    };
  }

  if (type === TARGET_INDICATORS.earlyAdopters) {
    return {
      bgColor: 'error.main',
      size: 130,
      title: 'label.earlyAdopters',
      placeholder: 'placeholder.earlyAdopters',
    };
  }
};

export const getInitialValues = (segmentDimension) => {
  const indicators = {};

  DIMENSION_SEGMENT_INDICATORS.forEach((type) => {
    const foundSegment = segmentDimension?.targetIndicators?.find(({ targetIndicatorType }) => (
      targetIndicatorType === type
    ));

    indicators[type] = foundSegment || {
      value: '',
      description: '',
    };
  });

  return {
    ...indicators,
    linksNumber: segmentDimension?.links?.length || 0,
    links: segmentDimension?.links || [],
  };
};

export const formatTargetIndicators = (formValues) => {
  const targetIndicators = [];

  Object.entries(formValues)?.forEach(([type, targetIndicator]) => {
    if (targetIndicator?._id || (!isNil(targetIndicator?.value) && targetIndicator?.value !== '')) {
      targetIndicators.push({
        ...targetIndicator,
        value: targetIndicator?.value?.toString(),
        targetIndicatorType: type,
        unitOfMeasurement: 'size',
      });
    }
  });

  return targetIndicators;
};
