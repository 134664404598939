import { styled } from '@mui/material/styles';

export const Content = styled('div')(({ theme, isTaskDone }) => `
  p {
    color: ${isTaskDone ? theme.palette.general.grayLight1 : theme.palette.text.primary};
  }
  
  svg path {
    fill: ${isTaskDone ? theme.palette.general.accentLight : theme.palette.primary.main};
  }
`);

export const TaskWrapper = styled('div')(() => `
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: -9px;

  & p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  :hover p {
    text-decoration: underline;
    font-weight: 600;
  }
`);
