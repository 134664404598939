import { styled } from '@mui/material/styles';

import { SIDEBAR_WIDTH } from '../../constants/general';

export const PageContainer = styled('div')(({ theme }) => `
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.common.white};
  display: flex;
  position: relative;
  gap: 30px;
`);

export const PageBody = styled('div')(({ theme }) => `
  position: relative;
  height: 100vh;
  padding-right: ${theme.spacing(30)};
  padding-bottom: ${theme.spacing(20)};
  width: calc(100vw - ${SIDEBAR_WIDTH}px - 30px);
  background: ${theme.palette.common.white};
  transition: 0.3s ease-out;
  
  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding-left: ${theme.spacing(20)};
  };
`);

export const PageTitle = styled('h2')(({ theme }) => `
  margin: ${theme.spacing(70, 0, 50, 50)};
`);
