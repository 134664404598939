import React from 'react';

import DialogComponent from '../../common/components/DialogComponent';

import CategoryForm from './CategoryForm';

const AddCategoryModal = ({
  isModalOpen,
  handleSubmit,
  categoryData,
  handleClose,
  allCategoryNames,
}) => (
  <DialogComponent
    open={isModalOpen}
    handleClose={handleClose}
    title={categoryData ? 'Editeaza categoria' : 'Adauga o categorie noua'}
  >
    <CategoryForm
      handleSubmit={handleSubmit}
      data={categoryData}
      allCategoryNames={allCategoryNames}
    />
  </DialogComponent>
);

export default AddCategoryModal;
