import { Box, Checkbox, Divider, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { IMPORTANCE_LABELS, STATUS_LABELS } from '../../constants';

const BmcFilterDropdown = ({
  onClose,
  filters,
  onSave,
}) => {
  const intl = useIntl();
  const [localFilters, setLocalFilters] = useState(filters);

  const handleCheckImportance = (key, isChecked) => {
    setLocalFilters((prevState) => ({
      ...prevState,
      importance: {
        ...prevState.importance,
        [key]: !isChecked,
      }
    }));
  };

  const handleCheckStatus = (key, isChecked) => {
    setLocalFilters((prevState) => ({
      ...prevState,
      status: {
        ...prevState.status,
        [key]: !isChecked,
      }
    }));
  };

  const handleSave = () => {
    onSave(localFilters);
    onClose();
  };

  const importanceCount = useMemo(() => (
    Object.values(localFilters?.importance).reduce((acc, cur) => acc + cur, 0)
  ), [localFilters?.importance]);

  const statusCount = useMemo(() => (
    Object.values(localFilters?.status).reduce((acc, cur) => acc + cur, 0)
  ), [localFilters?.status]);

  return (
    <Box>
      <Typography variant="body2" color="primary" fontWeight={600} pl={9}>
        {`${intl.formatMessage({ id: 'label.importance' })} (${importanceCount})`}
      </Typography>

      {localFilters?.importance && Object.entries(localFilters?.importance)?.map(([key, isChecked]) => (
        <Box key={key} className="d-flex align-items-center">
          <Checkbox
            onClick={() => handleCheckImportance(key, isChecked)}
            checked={isChecked}
          />
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage({ id: IMPORTANCE_LABELS[key] })}
          </Typography>
        </Box>
      ))}

      <Typography variant="body2" color="primary" fontWeight={600} pl={9} mt={6}>
        {`${intl.formatMessage({ id: 'label.hypothesisStatus' })} (${statusCount})`}
      </Typography>

      {localFilters?.status && Object.entries(localFilters?.status)?.map(([key, isChecked]) => (
        <Box key={key} className="d-flex align-items-center">
          <Checkbox
            onClick={() => handleCheckStatus(key, isChecked)}
            checked={isChecked}
          />
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage({ id: STATUS_LABELS[key] })}
          </Typography>
        </Box>
      ))}

      <Box ml={9} mt={6}>
        <Divider sx={{ borderColor: 'general.accentLight' }} />

        <Box display="flex" gap={8} mt={15}>
          <ButtonComponent
            label={intl.formatMessage({ id: 'button.cancel' })}
            variant="outlined"
            color="secondary"
            fullWidth
            size="small"
            onClick={onClose}
          />
          <ButtonComponent
            label={intl.formatMessage({ id: 'button.save' })}
            color="secondary"
            fullWidth
            size="small"
            onClick={handleSave}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BmcFilterDropdown;
