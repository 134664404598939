import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField, Typography,
} from '@mui/material';
import { Formik, useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';
import { postNewIndustry, editIndustryById } from '../../../../store/contextAnalysis';

import { currentTeamIdSelector } from '../../../../common/store';

import { INDUSTRY_IMPACT_OPTIONS } from '../../../../constants/options';

import { SaveIconOutlined } from '../../../../assets/icons';

import { InfluenceFactorWrapper } from './index.styled';

const RenderTextField = ({
  label,
  id,
  md = 12,
  multiline = false,
  rows = 1,
}) => {
  const [field, meta] = useField(id);
  return (
    <Grid item xs={12} md={md} sx={{ marginBottom: '15px' }}>
      <TextField
        {...field}
        id={id}
        multiline={multiline}
        rows={rows}
        label={label}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        fullWidth
      />
    </Grid>
  );
};

const RenderRadioGroup = ({ id, label, options }) => {
  const [field] = useField(id);
  return (
    <InfluenceFactorWrapper item xs={12}>
      <FormControl component="fieldset">
        <Typography variant="body1" color="textSecondary" mb={11}>
          {label}
        </Typography>
        <RadioGroup {...field} id={id}>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio sx={{ mr: 6 }} />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </InfluenceFactorWrapper>
  );
};

const IndustryForm = ({
  selectedIndustry,
  postNewIndustry,
  teamId,
  industryType,
  editIndustryById,
  handleCloseModal,
}) => {
  const intl = useIntl();
  const schema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    description: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    influence: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      industryType,
    };

    if (selectedIndustry) {
      editIndustryById(selectedIndustry._id, payload, teamId);
    } else {
      postNewIndustry(payload, teamId);
    }

    handleCloseModal();
  };

  const initialValues = selectedIndustry
    ? {
        title: selectedIndustry.title,
        description: selectedIndustry.description,
        influence: selectedIndustry.influence,
      }
    : {
        title: '',
        description: '',
        influence: INDUSTRY_IMPACT_OPTIONS[0].value,
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderTextField id="title" label={intl.formatMessage({ id: 'label.title' })} />
          <RenderTextField
            id="description"
            label={intl.formatMessage({ id: 'label.description' })}
            multiline
            rows={4}
          />
          <RenderRadioGroup
            id="influence"
            label={intl.formatMessage({ id: 'label.whatInfluenceHasThisFactor' })}
            options={INDUSTRY_IMPACT_OPTIONS}
          />
          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  postNewIndustry,
  editIndustryById,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndustryForm);
