import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ClampedText } from '../../../../common/components/ClampedText';
import DialogAlert from '../../../../common/components/DialogAlert';
import DrawerComponent from '../../../../common/components/DrawerComponent';
import AddCharacteristicsModal from '../../components/AddCharacteristicsModal';

import {
  httpDeleteCharacteristicsElement,
  updateClientProfileCharacteristics,
} from '../../../../requests/hypothesis';

import { handleApiError } from '../../../../utils/errorUtils';

import {
  EditIcon,
  EyeIcon,
  ThumbsUpIcon,
  TrashIconOutlined,
} from '../../../../assets/icons';

import CharacteristicDetails from '../CharacteristicDetails';
import {
  ActionsWrapper,
  CharacteristicParagraph,
  OutlinedBox,
} from './index.styled';

const DIALOG_TYPE = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

const CharacteristicRow = ({
  characteristic,
  id,
  index,
  onRefresh,
  onViewDetails,
  canEdit,
  userStoriesCount,
}) => {
  const intl = useIntl();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogType, setDialogType] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpen = (type) => {
    setDialogType(type);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      await httpDeleteCharacteristicsElement(characteristic?.id);
      handleClose();
      handleCloseDrawer();
      onRefresh();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditCharacteristic = async (values) => {
    setIsSubmitting(true);
    try {
      await updateClientProfileCharacteristics(characteristic?.id, values);
      handleClose();
      onRefresh({ isLoadingDisabled: true });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDrawer = () => {
    if (onViewDetails) {
      onViewDetails();
      return;
    }

    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <CharacteristicParagraph
        id={`${id}-element-${index}`}
        className={`element-${id}`}
      >
        <Box display="flex" alignItems="center" gap={10}>
          <OutlinedBox variant="secondary">
            <ThumbsUpIcon size="16px" />
            <Typography variant="body2" color="primary" fontWeight={600}>
              {characteristic?.userStories?.length}
            </Typography>
          </OutlinedBox>

          {characteristic?.averageIntensity > 0 && (
            <OutlinedBox variant="secondary" minWidth="40px">
              <Typography variant="body2" color="primary" fontWeight={600}>
                {characteristic?.averageIntensity}
              </Typography>
            </OutlinedBox>
          )}

          <ClampedText lineClamp={1} text={characteristic.description} />
        </Box>

        <ActionsWrapper className="actions">
          <IconButton onClick={handleOpenDrawer} color="secondary">
            <EyeIcon fill="currentColor" />
          </IconButton>

          {canEdit && (
            <>
              <IconButton
                onClick={() => handleOpen(DIALOG_TYPE.EDIT)}
                color="secondary"
              >
                <EditIcon size="18px" />
              </IconButton>

              <IconButton
                onClick={() => handleOpen(DIALOG_TYPE.DELETE)}
                color="error"
              >
                <TrashIconOutlined />
              </IconButton>
            </>
          )}
        </ActionsWrapper>
      </CharacteristicParagraph>

      <DialogAlert
        isModalOpen={isOpen && dialogType === DIALOG_TYPE.DELETE}
        onClose={handleClose}
        title={intl.formatMessage({ id: 'modal.title.deleteElementProfile' })}
        content={intl.formatMessage({
          id: 'modal.content.deleteElementProfile',
        })}
        hasActions
        onSubmit={handleDelete}
        isDisabled={isSubmitting}
      />

      <AddCharacteristicsModal
        isModalOpen={isOpen && dialogType === DIALOG_TYPE.EDIT}
        onClose={handleClose}
        characteristic={characteristic}
        onSubmit={handleEditCharacteristic}
        isEditMode
        isSubmitting={isSubmitting}
      />

      {isDrawerOpen && (
        <DrawerComponent isOpen={isDrawerOpen} onClose={handleCloseDrawer}>
          <CharacteristicDetails
            onRefresh={() => onRefresh({ isLoadingDisabled: true })}
            initialCharacteristic={characteristic}
            onCloseDrawer={handleCloseDrawer}
            canEdit={canEdit}
            userStoriesCount={userStoriesCount}
          />
        </DrawerComponent>
      )}
    </>
  );
};

export default CharacteristicRow;
