import { alpha, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiTableBody from '@mui/material/TableBody';

export const DefaultCell = styled(
  TableCell,
  { shouldForwardProp: (prop) => prop !== 'isHeader' }
)(({ theme, isHeader }) => `
  color: ${theme.palette.primary.main}; !important;
  padding: 0 !important;
  ${isHeader ? `
    background-color: ${alpha(theme.palette.general.accentLight2, 0.2)}
    color: ${theme.palette.primary.main};
  ` : ''}
`);

export const TableBody = styled(MuiTableBody)(() => `
  & .MuiTableRow-root:last-child td { 
    border-bottom: 0;
  }
`);
