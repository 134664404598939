import { styled } from '@mui/material/styles';

export const ArrowIcon = styled(
  'div',
  { shouldForwardProp: (props) => props !== 'expanded' },
)(({ theme, expanded }) => `
  svg > path {
    fill: ${theme.palette.primary.main};
  };
  transform: ${expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`);
