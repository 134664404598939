import update from 'immutability-helper';

import {
  postNewTrend as doPostNewTrend,
  getAllTrendsByTeamId as doGetAllTrendsByTeamId,
  deleteTrendById as doDeleteTrendById,
  editTrendById as doEditTrendById,
  gettAllIndustriesByTeamId as doGetAllIndustriesByTeamId,
  postNewIndustry as doPostNewIndustry,
  editIndustryById as doEditIndustryById,
  deleteIndustryById as doDeleteIndustryById,
  postNewSwotElement as doPostNewSwot,
  getAllSwotElementsByTeamId as doGetAllSwotElementsByTeamId,
  deleteSwotElementById as doDeleteSwotElementById,
  editSwotElementById as doEditSwotElementById,
  getAllCompetitorMetricsByTeamId as doGetAllCompetitorMetricsByTeamId,
  postNewCompetitor as doPostNewCompetitor,
  postCompetitionMetric as doPostCompetitionMetric,
  postNewCriteria as doPostNewCriteria,
  editCompetitor as doEditCompetitor,
  editCriteria as doEditCriteria,
  deleteCriteria as doDeleteCriteria,
  deleteCompetitor as doDeleteCompetitor,
  editConclusion as doEditConclusion,
} from '../../requests/contextAnalysis';

import {
  industriesSerializer,
  swotsSerializer,
  teamCompetitorsSerializer,
} from '../../utils/formatters';

const POST_NEW_TREND = 'POST_NEW_TREND';
const POST_NEW_TREND_SUCCESS = 'POST_NEW_TREND_SUCCESS';
const POST_NEW_TREND_ERROR = 'POST_NEW_TREND_ERROR';

const GET_ALL_TRENDS_BY_TEAM_ID = 'GET_ALL_TRENDS_BY_TEAM_ID';
const GET_ALL_TRENDS_BY_TEAM_ID_SUCCESS = 'GET_ALL_TRENDS_BY_TEAM_ID_SUCCESS';
const GET_ALL_TRENDS_BY_TEAM_ID_ERROR = 'GET_ALL_TRENDS_BY_TEAM_ID_ERROR';

const DELETE_TREND = 'DELETE_TREND';
const DELETE_TREND_SUCCESS = 'DELETE_TREND_SUCCESS';
const DELETE_TREND_ERROR = 'DELETE_TREND_ERROR';

const EDIT_TREND = 'EDIT_TREND';
const EDIT_TREND_SUCCESS = 'EDIT_TREND_SUCCESS';
const EDIT_TREND_ERROR = 'EDIT_TREND_ERROR';

const GET_ALL_INDUSTRIES_BY_TEAM_ID = 'GET_ALL_INDUSTRIES_BY_TEAM_ID';
const GET_ALL_INDUSTRIES_BY_TEAM_ID_SUCCESS =
  'GET_ALL_INDUSTRIES_BY_TEAM_ID_SUCCESS';
const GET_ALL_INDUSTRIES_BY_TEAM_ID_ERROR =
  'GET_ALL_INDUSTRIES_BY_TEAM_ID_ERROR';

const POST_NEW_INDUSTRY = 'POST_NEW_INDUSTRY';
const POST_NEW_INDUSTRY_SUCCESS = 'POST_NEW_INDUSTRY_SUCCESS';
const POST_NEW_INDUSTRY_ERROR = 'POST_NEW_INDUSTRY_ERROR';

const EDIT_INDUSTRY = 'EDIT_INDUSTRY';
const EDIT_INDUSTRY_SUCCESS = 'EDIT_INDUSTRY_SUCCESS';
const EDIT_INDUSTRY_ERROR = 'EDIT_INDUSTRY_ERROR';

const DELETE_INDUSTRY = 'DELETE_INDUSTRY';
const DELETE_INDUSTRY_SUCCESS = 'DELETE_INDUSTRY_SUCCESS';
const DELETE_INDUSTRY_ERROR = 'DELETE_INDUSTRY_ERROR';

const POST_NEW_SWOT = 'POST_NEW_SWOT';
const POST_NEW_SWOT_SUCCESS = 'POST_NEW_SWOT_SUCCESS';
const POST_NEW_SWOT_ERROR = 'POST_NEW_SWOT_ERROR';

const GET_ALL_SWOT_BY_TEAM_ID = 'GET_ALL_SWOT_BY_TEAM_ID';
const GET_ALL_SWOT_BY_TEAM_ID_SUCCESS = 'GET_ALL_SWOT_BY_TEAM_ID_SUCCESS';
const GET_ALL_SWOT_BY_TEAM_ID_ERROR = 'GET_ALL_SWOT_BY_TEAM_ID_ERROR';

const DELETE_SWOT = 'DELETE_SWOT';
const DELETE_SWOT_SUCCESS = 'DELETE_SWOT_SUCCESS';
const DELETE_SWOT_ERROR = 'DELETE_SWOT_ERROR';

const EDIT_SWOT = 'EDIT_SWOT';
const EDIT_SWOT_SUCCESS = 'EDIT_SWOT_SUCCESS';
const EDIT_SWOT_ERROR = 'EDIT_SWOT_ERROR';

const GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID =
  'GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID';
const GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_SUCCESS =
  'GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_SUCCESS';
const GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_ERROR =
  'GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_ERROR';

const POST_NEW_COMPETITOR = 'POST_NEW_COMPETITOR';
const POST_NEW_COMPETITOR_SUCCESS = 'POST_NEW_COMPETITOR_SUCCESS';
const POST_NEW_COMPETITOR_ERROR = 'POST_NEW_COMPETITOR_ERROR';

const POST_COMPETITION_METRIC = 'POST_COMPETITION_METRIC';
const POST_COMPETITION_METRIC_SUCCESS = 'POST_COMPETITION_METRIC_SUCCESS';
const POST_COMPETITION_METRIC_ERROR = 'POST_COMPETITION_METRIC_ERROR';

const POST_NEW_CRITERIA = 'POST_NEW_CRITERIA';
const POST_NEW_CRITERIA_SUCCESS = 'POST_NEW_CRITERIA_SUCCESS';
const POST_NEW_CRITERIA_ERROR = 'POST_NEW_CRITERIA_ERROR';

const EDIT_COMPETITOR = 'EDIT_COMPETITOR';
const EDIT_COMPETITOR_SUCCESS = 'EDIT_COMPETITOR_SUCCESS';
const EDIT_COMPETITOR_ERROR = 'EDIT_COMPETITOR_ERROR';

const EDIT_CRITERIA = 'EDIT_CRITERIA';
const EDIT_CRITERIA_SUCCESS = 'EDIT_CRITERIA_SUCCESS';
const EDIT_CRITERIA_ERROR = 'EDIT_CRITERIA_ERROR';

const DELETE_COMPETITOR = 'DELETE_COMPETITOR';
const DELETE_COMPETITOR_SUCCESS = 'DELETE_COMPETITOR_SUCCESS';
const DELETE_COMPETITOR_ERROR = 'DELETE_COMPETITOR_ERROR';

const DELETE_CRITERIA = 'DELETE_CRITERIA';
const DELETE_CRITERIA_SUCCESS = 'DELETE_CRITERIA_SUCCESS';
const DELETE_CRITERIA_ERROR = 'DELETE_CRITERIA_ERROR';

const EDIT_CONCLUSION = 'EDIT_CONCLUSION';
const EDIT_CONCLUSION_SUCCESS = 'EDIT_CONCLUSION_SUCCESS';
const EDIT_CONCLUSION_ERROR = 'EDIT_CONCLUSION_ERROR';

const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CONCLUSION:
      return update(state, {
        isCreating: { $set: true },
      });
    case EDIT_CONCLUSION_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case EDIT_CONCLUSION_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_NEW_TREND:
      return update(state, {
        isCreating: { $set: true },
      });
    case POST_NEW_TREND_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_NEW_TREND_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case GET_ALL_TRENDS_BY_TEAM_ID:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ALL_TRENDS_BY_TEAM_ID_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        teamTrends: { $set: action.payload },
      });
    case GET_ALL_TRENDS_BY_TEAM_ID_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });
    case DELETE_TREND:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_TREND_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_TREND_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });
    case GET_ALL_INDUSTRIES_BY_TEAM_ID:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ALL_INDUSTRIES_BY_TEAM_ID_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        teamIndustries: { $set: action.payload },
      });
    case GET_ALL_INDUSTRIES_BY_TEAM_ID_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });
    case POST_NEW_INDUSTRY:
      return update(state, {
        isCreating: { $set: true },
      });
    case POST_NEW_INDUSTRY_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_NEW_INDUSTRY_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case EDIT_INDUSTRY:
      return update(state, {
        isEditing: { $set: true },
      });
    case EDIT_INDUSTRY_SUCCESS:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_INDUSTRY_ERROR:
      return update(state, {
        isEditing: { $set: false },
      });
    case DELETE_INDUSTRY:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_INDUSTRY_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_INDUSTRY_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });
    case POST_NEW_SWOT:
      return update(state, {
        isCreating: { $set: true },
      });
    case POST_NEW_SWOT_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_NEW_SWOT_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case GET_ALL_SWOT_BY_TEAM_ID:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ALL_SWOT_BY_TEAM_ID_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        teamSwots: { $set: action.payload },
      });
    case GET_ALL_SWOT_BY_TEAM_ID_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });
    case DELETE_SWOT:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_SWOT_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_SWOT_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });
    case EDIT_SWOT:
      return update(state, {
        isEditing: { $set: true },
      });
    case EDIT_SWOT_SUCCESS:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_SWOT_ERROR:
      return update(state, {
        isEditing: { $set: false },
      });
    case GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID:
      return update(state, {
        isLoading: { $set: true },
      });
    case GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        teamCompetitorMetrics: { $set: action.payload },
      });
    case GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_ERROR:
      return update(state, {
        isLoading: { $set: false },
      });
    case POST_NEW_COMPETITOR:
      return update(state, {
        isCreating: { $set: true },
      });
    case POST_NEW_COMPETITOR_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_NEW_COMPETITOR_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_COMPETITION_METRIC:
      return update(state, {
        isCreating: { $set: true },
      });
    case POST_COMPETITION_METRIC_SUCCESS:
      return update(state, {
        isCreating: { $set: false },
      });
    case POST_COMPETITION_METRIC_ERROR:
      return update(state, {
        isCreating: { $set: false },
      });
    case EDIT_COMPETITOR:
      return update(state, {
        isEditing: { $set: true },
      });
    case EDIT_COMPETITOR_SUCCESS:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_COMPETITOR_ERROR:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_CRITERIA:
      return update(state, {
        isEditing: { $set: true },
      });
    case EDIT_CRITERIA_SUCCESS:
      return update(state, {
        isEditing: { $set: false },
      });
    case EDIT_CRITERIA_ERROR:
      return update(state, {
        isEditing: { $set: false },
      });
    case DELETE_COMPETITOR:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_COMPETITOR_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_COMPETITOR_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_CRITERIA:
      return update(state, {
        isDeleting: { $set: true },
      });
    case DELETE_CRITERIA_SUCCESS:
      return update(state, {
        isDeleting: { $set: false },
      });
    case DELETE_CRITERIA_ERROR:
      return update(state, {
        isDeleting: { $set: false },
      });

    default:
      return state;
  }
};

export const getAllTrendsByTeamId = (teamId, queryParams) => async (dispatch) => {
  dispatch({ type: GET_ALL_TRENDS_BY_TEAM_ID });
  try {
    const response = await doGetAllTrendsByTeamId(teamId, queryParams);

    dispatch({
      type: GET_ALL_TRENDS_BY_TEAM_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: GET_ALL_TRENDS_BY_TEAM_ID_ERROR, payload: error });
  }
};

export const postNewTrend = (trend, teamId) => async (dispatch) => {
  dispatch({ type: POST_NEW_TREND });
  try {
    const response = await doPostNewTrend(trend, teamId);
    dispatch({ type: POST_NEW_TREND_SUCCESS, payload: response.data });
    dispatch(getAllTrendsByTeamId(teamId));
  } catch (error) {
    dispatch({ type: POST_NEW_TREND_ERROR, payload: error });
  }
};

export const deleteTrendById = (trendId, teamId) => async (dispatch) => {
  dispatch({ type: DELETE_TREND });
  try {
    const response = await doDeleteTrendById(trendId);
    dispatch({ type: DELETE_TREND_SUCCESS, payload: response.data });
    dispatch(getAllTrendsByTeamId(teamId));
  } catch (error) {
    dispatch({ type: DELETE_TREND_ERROR, payload: error });
  }
};

export const editTrendById = (trendId, trend, teamId) => async (dispatch) => {
  dispatch({ type: EDIT_TREND });
  try {
    const response = await doEditTrendById(trendId, trend);
    dispatch({ type: EDIT_TREND_SUCCESS, payload: response.data });
    dispatch(getAllTrendsByTeamId(teamId));
  } catch (error) {
    dispatch({ type: EDIT_TREND_ERROR, payload: error });
  }
};

export const getAllIndustriesByTeamId = (teamId) => async (dispatch) => {
  dispatch({ type: GET_ALL_INDUSTRIES_BY_TEAM_ID });
  try {
    const response = await doGetAllIndustriesByTeamId(teamId);
    const serialized = industriesSerializer(response.data);

    dispatch({
      type: GET_ALL_INDUSTRIES_BY_TEAM_ID_SUCCESS,
      payload: serialized,
    });
  } catch (error) {
    dispatch({ type: GET_ALL_INDUSTRIES_BY_TEAM_ID_ERROR, payload: error });
  }
};

export const postNewIndustry = (industry, teamId) => async (dispatch) => {
  dispatch({ type: POST_NEW_INDUSTRY });
  try {
    const response = await doPostNewIndustry(industry, teamId);
    dispatch({ type: POST_NEW_INDUSTRY_SUCCESS, payload: response.data });
    dispatch(getAllIndustriesByTeamId(teamId));
  } catch (error) {
    dispatch({ type: POST_NEW_INDUSTRY_ERROR, payload: error });
  }
};

export const editIndustryById =
  (industryId, industry, teamId) => async (dispatch) => {
    dispatch({ type: EDIT_INDUSTRY });
    try {
      const response = await doEditIndustryById(industryId, industry);
      dispatch({ type: EDIT_INDUSTRY_SUCCESS, payload: response.data });
      dispatch(getAllIndustriesByTeamId(teamId));
    } catch (error) {
      dispatch({ type: EDIT_INDUSTRY_ERROR, payload: error });
    }
  };

export const deleteIndustryById = (industryId, teamId) => async (dispatch) => {
  dispatch({ type: DELETE_INDUSTRY });
  try {
    const response = await doDeleteIndustryById(industryId);
    dispatch({ type: DELETE_INDUSTRY_SUCCESS, payload: response.data });
    dispatch(getAllIndustriesByTeamId(teamId));
  } catch (error) {
    dispatch({ type: DELETE_INDUSTRY_ERROR, payload: error });
  }
};

export const getAllSwotElementsByTeamId = (teamId) => async (dispatch) => {
  dispatch({ type: GET_ALL_SWOT_BY_TEAM_ID });
  try {
    const response = await doGetAllSwotElementsByTeamId(teamId);
    const serialized = swotsSerializer(response.data);

    dispatch({
      type: GET_ALL_SWOT_BY_TEAM_ID_SUCCESS,
      payload: serialized,
    });
  } catch (error) {
    dispatch({ type: GET_ALL_SWOT_BY_TEAM_ID_ERROR, payload: error });
  }
};

export const postNewSwot = (swot, teamId) => async (dispatch) => {
  dispatch({ type: POST_NEW_SWOT });
  try {
    const response = await doPostNewSwot(swot, teamId);
    dispatch({ type: POST_NEW_SWOT_SUCCESS, payload: response.data });
    dispatch(getAllSwotElementsByTeamId(teamId));
  } catch (error) {
    dispatch({ type: POST_NEW_SWOT_ERROR, payload: error });
  }
};

export const deleteSwotById = (swotId, teamId) => async (dispatch) => {
  dispatch({ type: DELETE_SWOT });
  try {
    const response = await doDeleteSwotElementById(swotId);
    dispatch({ type: DELETE_SWOT_SUCCESS, payload: response.data });
    dispatch(getAllSwotElementsByTeamId(teamId));
  } catch (error) {
    dispatch({ type: DELETE_SWOT_ERROR, payload: error });
  }
};

export const editSwotById = (swotId, swot, teamId) => async (dispatch) => {
  dispatch({ type: EDIT_SWOT });
  try {
    const response = await doEditSwotElementById(swotId, swot);
    dispatch({ type: EDIT_SWOT_SUCCESS, payload: response.data });
    dispatch(getAllSwotElementsByTeamId(teamId));
  } catch (error) {
    dispatch({ type: EDIT_SWOT_ERROR, payload: error });
  }
};

export const getCompetitorMetrics = (teamId) => async (dispatch) => {
  dispatch({ type: GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID });
  try {
    const response = await doGetAllCompetitorMetricsByTeamId(teamId);
    const serialized = teamCompetitorsSerializer(response.data);

    console.log(serialized, response.data);

    dispatch({
      type: GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_SUCCESS,
      payload: serialized,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COMPETITOR_METRICS_BY_TEAM_ID_ERROR,
      payload: error,
    });
  }
};

export const postNewCompetitor = (competitor, teamId) => async (dispatch) => {
  dispatch({ type: POST_NEW_COMPETITOR });
  try {
    const response = await doPostNewCompetitor(competitor, teamId);
    dispatch({ type: POST_NEW_COMPETITOR_SUCCESS, payload: response.data });
    dispatch(getCompetitorMetrics(teamId));
  } catch (error) {
    dispatch({ type: POST_NEW_COMPETITOR_ERROR, payload: error });
  }
};

export const editCompetitor =
  (competitor, teamId, competitorId) => async (dispatch) => {
    dispatch({ type: EDIT_COMPETITOR });
    try {
      const response = await doEditCompetitor(competitor, teamId, competitorId);
      dispatch({ type: EDIT_COMPETITOR_SUCCESS, payload: response.data });
      dispatch(getCompetitorMetrics(teamId));
    } catch (error) {
      dispatch({ type: EDIT_COMPETITOR_ERROR, payload: error });
    }
  };

export const editCriteria =
  (criteria, teamId, criteriaId) => async (dispatch) => {
    dispatch({ type: EDIT_CRITERIA });
    try {
      const response = await doEditCriteria(criteria, teamId, criteriaId);
      dispatch({ type: EDIT_CRITERIA_SUCCESS, payload: response.data });
      dispatch(getCompetitorMetrics(teamId));
    } catch (error) {
      dispatch({ type: EDIT_CRITERIA_ERROR, payload: error });
    }
  };

export const postCompetitorMetric = (metric, teamId) => async (dispatch) => {
  dispatch({ type: POST_COMPETITION_METRIC });

  try {
    const response = await doPostCompetitionMetric(metric, teamId);

    dispatch({
      type: POST_COMPETITION_METRIC_SUCCESS,
      payload: response.data,
    });
    dispatch(getCompetitorMetrics(teamId));
  } catch (error) {
    dispatch({ type: POST_COMPETITION_METRIC_ERROR, payload: error });
  }
};

export const postNewCriteria = (criteria, teamId) => async (dispatch) => {
  dispatch({ type: POST_NEW_CRITERIA });
  try {
    const response = await doPostNewCriteria(criteria, teamId);
    dispatch({ type: POST_NEW_CRITERIA_SUCCESS, payload: response.data });
    dispatch(getCompetitorMetrics(teamId));
  } catch (error) {
    dispatch({ type: POST_NEW_CRITERIA_ERROR, payload: error });
  }
};

export const deleteCriteria = (criteriaId, teamId) => async (dispatch) => {
  dispatch({ type: DELETE_CRITERIA });
  try {
    const response = await doDeleteCriteria(teamId, criteriaId);
    dispatch({ type: DELETE_CRITERIA_SUCCESS, payload: response.data });
    dispatch(getCompetitorMetrics(teamId));
  } catch (error) {
    dispatch({ type: DELETE_CRITERIA_ERROR, payload: error });
  }
};

export const deleteCompetitor = (competitorId, teamId) => async (dispatch) => {
  dispatch({ type: DELETE_COMPETITOR });
  try {
    const response = await doDeleteCompetitor(teamId, competitorId);
    dispatch({ type: DELETE_COMPETITOR_SUCCESS, payload: response.data });
    dispatch(getCompetitorMetrics(teamId));
  } catch (error) {
    dispatch({ type: DELETE_COMPETITOR_ERROR, payload: error });
  }
};

export const editConclusion = (conclusion, teamId) => async (dispatch) => {
  dispatch({ type: EDIT_CONCLUSION });
  try {
    const response = await doEditConclusion(conclusion, teamId);
    dispatch({ type: EDIT_CONCLUSION_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: EDIT_CONCLUSION_ERROR, payload: error });
  }
};
