import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { USER_ROLES } from '../../../../constants/roles';

export const Role = styled('span')(({ theme, role }) => `
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  border: 1px solid ${role === USER_ROLES.Founder ? theme.palette.general.purple : theme.palette.general.orange};
  border-radius: 5px;
  width: max-content;
  padding: ${theme.spacing(3, 8)};
  
  & .MuiTypography-root {
    color: ${role === USER_ROLES.Founder ? theme.palette.general.purple : theme.palette.general.orange};
    font-weight: 600;
  }
`);

export const ProfileAvatar = styled(Avatar)`
  border-radius: 10px !important;
  background-color: #98a6ad;
  width: ${(props) => `${props.size || 100}px !important;`};;
  height: ${(props) => `${props.size || 100}px !important;`};;
`;
