import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import HypothesisCard from '../../../../common/components/HypothesisCard';

const HypothesisSegments = ({ segmentsHypothesis }) => {
  const intl = useIntl();
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Typography variant="h2" color="primary">
        {intl.formatMessage({ id: 'label.targetSegments' })}
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr', // Two equal-width columns
          gap: '20px',
        }}
      >
        {segmentsHypothesis.map((item, index) => (
          <HypothesisCard
            key={index}
            sx={{
              gridColumn: (index % 2) + 1,
            }}
            customerSegment="customerSegments"
            hypothesis={item}
            allowClick
            backgroundColor={item.color}
            mb={0}
          />
        ))}
      </Box>
    </Grid>
  );
};

export default HypothesisSegments;
