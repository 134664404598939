import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) =>  `
   border: 1px solid ${theme.palette.general.borderAccent};
   border-radius: 10px;
   padding: ${theme.spacing(10, 5, 10, 10)};
`);

export const ValueWrapper = styled(Typography)(({ theme }) =>  `
   background-color: ${theme.palette.general.lightBlue1};
   border-radius: 2px;
   padding: ${theme.spacing(2)};
   min-width: 80px;
   text-align: center;
`);
