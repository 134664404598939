import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ClampedBox = styled(
  Typography,
  { shouldForwardProp: (prop) => prop !== 'lineClamp' }
)(({ lineClamp }) =>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineClamp ? lineClamp: 1};
  display: -webkit-box;
  overflow: hidden;
  margin: 0;
`);
