import { Box } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import AddUserToIdeaModal from '../../components/AddUserToIdeaModal';
import JoinCohortModal from '../../components/JoinCohortModal';
import ManageTeamModal from '../../components/ManageTeamModal';
import MemberDetails from '../../components/MemberDetails';

import { PeopleIcon } from '../../../../assets/icons';

import MentorDetails from '../MentorDetails';

const MembersBreakdownSection = ({
  members = [],
  mentors = [],
  cohorts = [],
  currentTeam = {},
  runUpdateTeamMembers = () => {},
  runUpdateTeamMentors = () => {},
}) => {
  const intl = useIntl();
  const history = useHistory();
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const { _id: teamId } = currentTeam;
  const isOwner = loggedUser?.userId?._id === currentTeam?.createdBy;

  const [modalsState, setModalsState] = useState({
    isAddMemberModalOpen: false,
    isJoinCohortModalOpen: false,
    isManageTeamModalOpen: false,
  });

  const openModal = (modalName) => {
    setModalsState((prev) => ({ ...prev, [modalName]: true }));
  };

  const closeAllModals = () => {
    setModalsState({
      isAddMemberModalOpen: false,
      isJoinCohortModalOpen: false,
      isManageTeamModalOpen: false,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
      <MemberDetails
        title={intl.formatMessage({ id: 'label.members' })}
        placeholder={`${intl.formatMessage({ id: 'placeholder.noMembers' })}.`}
        userList={members}
        loggedUser={loggedUser}
        openModal={() => openModal('isAddMemberModalOpen')}
        type="members"
        runUpdateTeamMembers={runUpdateTeamMembers}
      >
        {isOwner && (
          <ButtonComponent
            icon={<PeopleIcon size={22} />}
            variant="outlined"
            color="secondary"
            size="small"
            label={intl.formatMessage({ id: 'label.teamPercentageAndRoles' })}
            onClick={() => openModal('isManageTeamModalOpen')}
          />
        )}
      </MemberDetails>

      <MentorDetails
        title={intl.formatMessage({ id: 'label.mentorsAndExperts' })}
        placeholder={`${intl.formatMessage({ id: 'placeholder.noMentors' })}.`}
        userList={mentors}
        loggedUser={loggedUser}
        runUpdateTeamMentors={runUpdateTeamMentors}
        openModal={() => history.push('/mentors')}
      />

      <MemberDetails
        title={intl.formatMessage({ id: 'label.programCohort' })}
        placeholder={`${intl.formatMessage({ id: 'placeholder.noCohort' })}.`}
        userList={cohorts}
        loggedUser={loggedUser}
        runUpdateTeamMembers={runUpdateTeamMembers}
        openModal={() => openModal('isJoinCohortModalOpen')}
        type="cohort"
      />

      <AddUserToIdeaModal
        isOpen={modalsState.isAddMemberModalOpen}
        runUpdateTeamMembers={runUpdateTeamMembers}
        runUpdateTeamMentors={runUpdateTeamMentors}
        handleClose={closeAllModals}
        teamId={teamId}
      />

      <JoinCohortModal
        isOpen={modalsState.isJoinCohortModalOpen}
        handleClose={closeAllModals}
        teamId={teamId}
      />

      <ManageTeamModal
        isOpen={modalsState.isManageTeamModalOpen}
        onClose={closeAllModals}
        runUpdateTeamMembers={runUpdateTeamMembers}
        teamId={teamId}
        members={members}
      />
    </Box>
  );
};

export default MembersBreakdownSection;
