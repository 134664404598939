const theme = {
  text: '#131336',
  blueAccent: '#2A5AB7',
  accentHover: '#063795',
  white: '#FFFFFF',
  danger: '#BA3232',
  dangerHover: '#890B0B',
  success: '#15BB89',
  successHover: '#058E65',
  textInactive: '#8E8EB5',
  accentLight: '#74A3FE',
  accentLight2: '#B1CCFE',
  accentLightHover: 'rgba(116, 163, 254, 0.15)',
  borderGray: '#CCCCCC',
  errorLight: '#FF3D00',
  purple: '#9441FE',
  black: 'black',

  bodyBackgroundColor: '#EFEFF4',
  backgroundBigScreen: '#F7F7F8',

  fontSizeBase: '14px',

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
};

export default theme;
