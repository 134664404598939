import { Grid, InputAdornment } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { notifyErrorWithoutDispatch } from '../../../../core/store';
import { editTeamEquity } from '../../../../requests/teams';

import { ideaRoleOptions } from '../../../../constants/options';
import { handleApiError } from '../../../../utils/errorUtils';
import { formatUserName } from '../../../../utils/formatters';
import { equityManagementValidationSchema } from '../../../../utils/validation/ideaSchema';

import { SaveIconOutlined } from '../../../../assets/icons';

import { DropdownField } from '../../../../fields/DropdownField';
import InputField from '../../../../fields/InputField';

const ManageTeamEquityForm = ({ members, teamId, onClose, runUpdateTeamMembers }) => {
  const intl = useIntl();
  const realMembers = members.filter((member) => member.userId);

  const initialValues = {
    members: realMembers.map((m) => ({
      userId: m.userId,
      equity: m.equity || 0,
      role: m.role || [],
    })),
  };

  const handleSubmit = async (values) => {
    const totalEquity = values.members.reduce(
      (sum, member) => sum + member.equity,
      0
    );

    if (totalEquity > 100) {
      return notifyErrorWithoutDispatch(intl.formatMessage({ id: 'error.percentageToHigh' }));
    }

    try {
      await editTeamEquity(teamId, values.members);
      await runUpdateTeamMembers();
      onClose();
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={equityManagementValidationSchema}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container spacing={16}>
            {values.members.map((entry, idx) => (
              <Fragment key={idx}>
                <InputField
                  type="number"
                  id={`members[${idx}].equity`}
                  label={formatUserName(realMembers[idx]) || realMembers[idx]?.email}
                  md={6}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <DropdownField
                  name={`members[${idx}].role`}
                  label={intl.formatMessage({ id: 'label.role' })}
                  options={ideaRoleOptions}
                  md={6}
                  multiple
                />
              </Fragment>
            ))}
          </Grid>

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              color="secondary"
              disabled={isSubmitting}
              icon={<SaveIconOutlined size="16px" />}
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ManageTeamEquityForm;
