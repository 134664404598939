import { Avatar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import InformationRow from '../../../../common/components/InformationRow';
import LinkComponent from '../../../../common/components/LinkComponent';

import { formatDate } from '../../../../utils/dateHelpers';

import { EditIcon } from '../../../../assets/icons';
import AddPhoto from '../../../../assets/img/add_photo.png';

import { AvatarWrapper, MyIdeaTag, MyIdeaTitle } from './index.styled';

const IdeaDetailsSection = ({ team, setIsAddIdeaModalOpen, canEdit }) => {
  const intl = useIntl();
  const {
    ideaCategory: tags = [],
    description = '',
    dateOfEstablishment = null,
    cui = null,
    headquarters = null,
    domain = null,
  } = team;

  return (
    <>
      <Box display="flex" alignItems="center" mb={40}>
        <AvatarWrapper
          hasPicture={!!team?.logo}
          onClick={() => canEdit? setIsAddIdeaModalOpen(true) : null}
        >
          <Avatar
            src={team?.logo || AddPhoto}
            variant="square"
            sx={{ height: '100%', width: '100%' }}
          />
        </AvatarWrapper>

        <MyIdeaTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h2" color="primary">
              {team?.name}
            </Typography>

            <Typography color="textSecondary">
              {intl.formatMessage({
                id: team?.isPublic ? 'label.publicIdea' : 'label.privateIdea',
              })}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex' }}>
            {tags.map((tag) => (
              <MyIdeaTag key={tag}>{tag}</MyIdeaTag>
            ))}
          </Box>
        </MyIdeaTitle>
      </Box>
      <Typography variant="h3" fontWeight={600} mb={20} color="primary">
        {intl.formatMessage({ id: 'label.generalDetails' })}
      </Typography>

      <InformationRow
        sx={{ mb: 20 }}
        value={description}
        richText
        label={intl.formatMessage({ id: 'label.description' })}
        placeholder={intl.formatMessage({ id: 'placeholder.noDescription' })}
      />

      <Box display="flex" alignItems="center" mb={20}>
        <Box width="50%">
          <InformationRow
            value={dateOfEstablishment ? formatDate(dateOfEstablishment) : null}
            label={intl.formatMessage({ id: 'label.dateOfEstablishment' })}
            placeholder={intl.formatMessage({
              id: 'placeholder.noEstablishmentDate',
            })}
          />
        </Box>
        <Box width="50%">
          <InformationRow
            value={cui}
            label={intl.formatMessage({
              id: 'label.personalIdentificationNumber',
            })}
            placeholder={intl.formatMessage({
              id: 'placeholder.noPersonalIdentificationNumber',
            })}
          />
        </Box>
      </Box>

      <InformationRow
        sx={{ mb: 20 }}
        value={headquarters}
        label={intl.formatMessage({ id: 'label.address' })}
        placeholder={intl.formatMessage({ id: 'placeholder.noAddress' })}
      />

      <Box mb={20}>
        <Typography className="italic" color="textSecondary">
          {intl.formatMessage({ id: 'label.website' })}
        </Typography>
        {domain ? (
          <LinkComponent domain={domain} />
        ) : (
          <Typography className="italic" color="primary">
            {intl.formatMessage({ id: 'placeholder.noWebsite' })}
          </Typography>
        )}
      </Box>

      <InformationRow
        sx={{ mb: 20 }}
        value={team?.teamCode}
        label={intl.formatMessage({ id: 'label.teamCode' })}
      />

      {canEdit && (
        <ButtonComponent
          onClick={() => setIsAddIdeaModalOpen(true)}
          variant="outlined"
          color="secondary"
          icon={<EditIcon fill="currentColor" size="14px" />}
          label={intl.formatMessage({ id: 'button.edit' })}
        />
      )}
    </>
  );
};

export default IdeaDetailsSection;
