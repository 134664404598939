import { Box } from '@mui/material';
import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import ColumnPillAnswer from '../../components/ColumnPillAnswer';
import Features from '../../components/Features';
import Navigation from '../../components/Navigation';
import StepTitle from '../../components/StepTitle';

import { AVAILABLE_FEATURES_BY_SUBSCRIPTION_PLAN } from '../../features';
import { Content } from '../../index.styled';

const SubscriptionTypeStep = ({
  stepInfo,
  selectedAnswers,
  onSelect,
  onSubmit,
  onBack,
  isDisabled,
  hasBackButton,
}) => {
  const userProfile = useSelector((state) => state?.userProfile?.userProfile) || {};

  const handleSubmit = () => {
    if (selectedAnswers.includes('paid')) {
      ReactGA.gtag('event', 'purchase', {
        userId: userProfile?.userId?._id,
        type: 'onboarding_subscription',
        value: 69,
        currency: 'ron',
      });
    }

    onSubmit();
  };

  return (
    <>
      <Content fullWidth>
        <Box mt={50} mb={30}>
          <StepTitle title={stepInfo.question} />

          <Box mt={60} display="flex" justifyContent="space-between" gap={8}>
            <Box>
              <ColumnPillAnswer
                width="200px"
                selectedAnswers={selectedAnswers}
                onSelect={onSelect}
                options={stepInfo.answerOptions}
              />
            </Box>

            <Features
              features={AVAILABLE_FEATURES_BY_SUBSCRIPTION_PLAN[selectedAnswers[0] || 'free']}
            />
          </Box>
        </Box>
      </Content>

      <Navigation
        onBack={onBack}
        onSubmit={handleSubmit}
        disabled={isDisabled}
        hasBackButton={hasBackButton}
      />
    </>
  );
};

export default SubscriptionTypeStep;
