import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import JoinCohortForm from '../JoinCohortForm';

const JoinCohortModal = ({ isOpen = false, handleClose, teamId }) => {
  const intl = useIntl();
  return (
    <DialogComponent
      open={isOpen}
      handleClose={handleClose}
      title={intl.formatMessage({ id: 'modal.title.joinACohort' })}
    >
      <JoinCohortForm teamId={teamId} handleClose={handleClose} />
    </DialogComponent>
  );
};

export default JoinCohortModal;
