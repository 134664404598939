import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import SwotForm from '../SwotForm';

export const SwotModal = (props) => {
  const intl = useIntl();
  const { isModalOpen, setIsModalOpen, swotType, selectedSwotElement } = props;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={handleCloseModal}
      title={intl.formatMessage({ id: 'modal.title.addSWOTElement' })}
    >
      <SwotForm
        handleCloseModal={handleCloseModal}
        swotType={swotType}
        selectedSwotElement={selectedSwotElement}
      />
    </DialogComponent>
  );
};
