import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash-es';
import React from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import {
  ColorColumnDiv,
  HypothesisCardContainer,
  HypothesisTitle,
} from '../../common/components/HypothesisCard/index.styled';
import { MarginContainer } from '../../modules/mrl/styled/MrlStyled';

import { theme } from '../../assets/styles';

const ContainerDiv = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  padding: 2.5px;
`;

const LabelDiv = styled.div`
  overflow: hidden;
  padding: 4px 0 4px 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: hsl(0, 0%, 20%);
`;

const LabelContainerDiv = styled.div`
  width: 90%;
  border: 1px solid ${(props) => props.color};
  color: hsl(0, 0%, 20%);
  border-radius: 5px;
  display: flex;
`;

const ForeverPlaceholderDiv = styled.div`
  padding-left: 10px;
  border-left: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  align-content: center;
  width: 50%;

  label {
    margin-bottom: 0;
  }
`;

const dropdownStyles = {
  menu: (baseStyle) => ({
    ...baseStyle,
    zIndex: 9999999999,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: theme.textInactive,
  }),
  control: (baseStyle, state) => ({
    width: '100%',
    minHeight: state.selectProps.showInfo ? '40px' : '60px',
    padding: '5px 26px 5px 5px',
    position: 'relative',
    background: 'right 2px center no-repeat',
    outline: 'none',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: get(state.selectProps, 'invalid')
      ? '#fa5c7c'
      : theme.accentLight,
    lineHeight: '19px',
    fontSize: '14px',
    color: theme.textInactive,
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: '0px 6px 2px 0',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    borderTopRightRadius: state.selectProps.showInfo ? '10px' : '0',
    borderBottomRightRadius: state.selectProps.showInfo ? '10px' : '0',
    color: state.selectProps.showInfo ? '#F05454' : 'hsl(0,0%,20%)',
  }),
  singleValue: (baseStyle) => ({
    ...baseStyle,
    top: '2px',
    transform: 'none',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '10px',
    padding: '3px',
  }),
  multiValue: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.selectProps.showInfo
      ? 'white'
      : 'rgb(230, 230, 230)',
    width: state.selectProps.showInfo ? '50%' : '100%',
    margin: state.selectProps.showInfo ? '0' : '2px',
    marginRight: state.selectProps.showInfo ? '0' : '22px',
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    whiteSpace: 'nowrap',
    top: '3px',
    transform: 'none',
    fontSize: '14px',
    fontFamily: ' Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: state.isFocused ? theme.text : theme.textInactive,
  }),
  indicatorsContainer: () => ({
    position: 'absolute',
    top: '0px',
    right: '0px',
  }),
  noOptionsMessage: (baseStyles) => ({
    ...baseStyles,
    textTransform: 'capitalize',
    lineHeight: '18px',
    fontSize: '12px',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    ':active': theme.text,
    paddingRight: '30px',
    backgroundColor:
      (state.isSelected && 'white') ||
      (state.isFocused && theme.bodyBackgroundColor) ||
      'transparent',
    fontSize: '16px',
    color: theme.text,
    fontFamily: ' Open Sans',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : 'normal',
    cursor: 'pointer',
  }),
};

const SingleValueOption = (props) => {
  const {
    data: { label, icon, value },
  } = props;
  return (
    <components.SingleValue {...props}>
      {!!icon && (
        <img
          src={icon}
          style={{
            width: value === 'validatedWithoutMVP' ? '18px' : '20px',
            height: value === 'validatedWithoutMVP' ? '18px' : '20px',
            marginRight: '5px',
            marginLeft: value === 'validatedWithoutMVP' ? '2px' : '0px',
          }}
          alt={label}
        />
      )}
      {label}
    </components.SingleValue>
  );
};

const IconOption = (props) => {
  const {
    data: { label, icon, value },
  } = props;
  return (
    <components.Option {...props}>
      {!!icon && (
        <img
          src={icon}
          style={{
            width: value === 'validatedWithoutMVP' ? '18px' : '20px',
            height: value === 'validatedWithoutMVP' ? '18px' : '20px',
            marginRight: '5px',
            marginLeft: value === 'validatedWithoutMVP' ? '2px' : '0px',
          }}
          alt={label}
        />
      )}
      {label}
    </components.Option>
  );
};

const SegmentOption = (props) => {
  const {
    data: { label, color },
  } = props;
  return (
    <components.Option {...props}>
      <HypothesisCardContainer
        style={{ marginBottom: '10px', background: 'none', minHeight: '40px' }}
        customerSegment="customerSegments"
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <HypothesisTitle
            customerSegment="customerSegments"
            lineClamp="1"
            hover={false}
            height="30px"
            width="84%"
          >
            {label}
          </HypothesisTitle>
          <ColorColumnDiv backgroundColor={color} />
        </div>
      </HypothesisCardContainer>
    </components.Option>
  );
};

const MultiValueContainer = (props) => {
  const {
    children,
    data: { label, color },
  } = props;
  return (
    <components.MultiValueContainer {...props} marginLeft="0">
      <ContainerDiv>
        <LabelContainerDiv color={color}>
          <LabelDiv>{label}</LabelDiv>
          <MarginContainer display="flex" marginLeft="auto">
            {children[1]}
          </MarginContainer>
        </LabelContainerDiv>
      </ContainerDiv>
    </components.MultiValueContainer>
  );
};

const Control = (props) => {
  const {
    children,
    hasValue,
    selectProps: { placeholder },
  } = props;
  return (
    <components.Control {...props}>
      <ContainerDiv>
        <div style={{ width: '50%' }}>{children[0]}</div>
        {hasValue && (
          <ForeverPlaceholderDiv>{placeholder}</ForeverPlaceholderDiv>
        )}
      </ContainerDiv>
      {children[1]}
    </components.Control>
  );
};

const DropdownIndicator = () => (
  <FontAwesomeIcon
    icon={faChevronDown}
    style={{ marginTop: '5px', fontSize: '18px' }}
  />
);

const Dropdown = ({
  options,
  value,
  onChange,
  defaultValue = value,
  isInvalid,
  placeholder,
  styles = dropdownStyles,
  isMulti,
  disabled,
  tooltipDescription,
  fieldRef,
  showCustomControl = false,
  showIconOption = false,
  showInfo = false,
  showSegmentColor = false,
  onBlur = () => {},
}) => {
  const handleChange = (selectedOption) => {
    if (onChange) onChange(selectedOption);
  };
  const customComponents = showCustomControl ? { DropdownIndicator } : {};
  const customComponentsInfo = showInfo
    ? { Control, MultiValueContainer, Option: SegmentOption }
    : {};

  const customComponentsIcon = showIconOption
    ? { Option: IconOption, SingleValue: SingleValueOption }
    : {};

  const customComponentsSegment = showSegmentColor
    ? { Option: SegmentOption }
    : {};

  return (
    <Select
      width="500px"
      value={value && value.value ? value : undefined}
      placeholder={placeholder}
      onChange={handleChange}
      isMulti={isMulti}
      onBlur={onBlur}
      isClearable={false}
      ref={fieldRef}
      showInfo={showInfo}
      options={options}
      tooltipDescription={tooltipDescription}
      styles={styles}
      isDisabled={disabled}
      defaultValue={defaultValue}
      invalid={isInvalid}
      classNamePrefix="select"
      components={
        showInfo
          ? customComponentsInfo
          : showSegmentColor
          ? customComponentsSegment
          : showCustomControl
          ? customComponents
          : showIconOption
          ? customComponentsIcon
          : {}
      }
    />
  );
};
export default Dropdown;
