import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';
import { push } from 'react-router-redux';

import { notifySuccess, notifyError } from '../../core/store';
import {
  idea as doCreateTeam,
  getTeam as doGetTeam,
  editTeam as doEditTeam,
  uploadTeamImage as doUploadTeamImage,
  removeMember as doRemoveMember,
  editEquityMember as doEditEquityMember,
  joinCohort as doJoinCohort,
  leaveCohort as doLeaveCohort,
  mentorLeave as doMentorLeave,
  leaveTeamAndReassignInformations as doLeaveTeamAndReassignInformations,
  joinTeam as doJoinTeam
} from '../../requests/idea';

// Actions
const START_LOAD = 'team/START_LOAD';
const COMPLETE_LOAD = 'team/COMPLETE_LOAD';
const FAIL_LOAD = 'team/FAIL_LOAD';
const START_CREATE = 'team/START_CREATE';
const COMPLETE_CREATE = 'team/COMPLETE_CREATE';
const FAIL_CREATE = 'team/FAIL_CREATE';
const START_JOIN_TEAM = 'team/START_JOIN_TEAM';
const COMPLETE_JOIN_TEAM = 'team/COMPLETE_JOIN_TEAM';
const FAIL_JOIN_TEAM = 'team/FAIL_JOIN_TEAM';

const START_EDIT = 'team/START_EDIT';
const COMPLETE_EDIT = 'team/COMPLETE_EDIT';
const FAIL_EDIT = 'team/FAIL_EDIT';

const START_REMOVE_MEMBER = 'team/START_REMOVE_MEMBER';
const COMPLETE_REMOVE_MEMBER = 'team/COMPLETE_REMOVE_MEMBER';
const FAIL_REMOVE_MEMBER = 'team/FAIL_REMOVE_MEMBER';

const START_MENTOR_LEAVE = 'team/START_MENTOR_LEAVE';
const COMPLETE_MENTOR_LEAVE = 'team/COMPLETE_MENTOR_LEAVE';
const FAIL_MENTOR_LEAVE = 'team/FAIL_MENTOR_LEAVE';

const START_LEAVE_TEAM_AND_REASSIGN = 'team/START_LEAVE_TEAM_AND_REASSIGN';
const COMPLETE_LEAVE_TEAM_AND_REASSIGN =
  'team/COMPLETE_LEAVE_TEAM_AND_REASSIGN';
const FAIL_LEAVE_TEAM_AND_REASSIGN = 'team/FAIL_LEAVE_TEAM_AND_REASSIGN';

const START_EDIT_MEMBER = 'team/START_EDIT_MEMBER';
const COMPLETE_EDIT_MEMBER = 'team/COMPLETE_EDIT_MEMBER';
const FAIL_EDIT_MEMBER = 'team/FAIL_EDIT_MEMBER';

const START_UPLOAD_IMAGE = 'team/START_UPLOAD_IMAGE';
const COMPLETE_UPLOAD_IMAGE = 'team/COMPLETE_UPLOAD_IMAGE';
const FAIL_UPLOAD_IMAGE = 'team/FAIL_UPLOAD_IMAGE';

const RESET = 'experiments/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isJoining: false,
  team: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: { isLoading: false, team: action.team },
      });

    case FAIL_LOAD:
      return update(state, { $merge: { isLoading: false } });

    case START_CREATE:
      return update(state, { $merge: { isCreating: true } });

    case COMPLETE_CREATE:
      return update(state, { $merge: { isCreating: false } });

    case FAIL_CREATE:
      return update(state, { $merge: { isCreating: false } });

    case START_EDIT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT:
      return update(state, { $merge: { isEditing: false } });
    case START_REMOVE_MEMBER:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_REMOVE_MEMBER:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_REMOVE_MEMBER:
      return update(state, { $merge: { isEditing: false } });

    case START_MENTOR_LEAVE:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_MENTOR_LEAVE:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_MENTOR_LEAVE:
      return update(state, { $merge: { isEditing: false } });
    case START_EDIT_MEMBER:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT_MEMBER:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT_MEMBER:
      return update(state, { $merge: { isEditing: false } });

    case START_UPLOAD_IMAGE:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_UPLOAD_IMAGE:
      return update(state, { $merge: { isEditing: false, team: action.team } });

    case FAIL_UPLOAD_IMAGE:
      return update(state, { $merge: { isEditing: false } });
    case START_JOIN_TEAM:
      return update(state, { $merge: { isJoining: true } });

    case COMPLETE_JOIN_TEAM:
      return update(state, { $merge: { isJoining: false } });

    case FAIL_JOIN_TEAM:
      return update(state, { $merge: { isJoining: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (team) => ({
  type: COMPLETE_LOAD,
  team,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startCreate = () => ({
  type: START_CREATE,
});

const completeCreate = () => ({
  type: COMPLETE_CREATE,
});

const failCreate = () => ({
  type: FAIL_CREATE,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startRemove = () => ({
  type: START_REMOVE_MEMBER,
});

const completeRemove = () => ({
  type: COMPLETE_REMOVE_MEMBER,
});

const failRemove = () => ({
  type: FAIL_REMOVE_MEMBER,
});

const startMentorLeave = () => ({
  type: START_MENTOR_LEAVE,
});

const completeMentorLeave = () => ({
  type: COMPLETE_MENTOR_LEAVE,
});

const failMentorLeave = () => ({
  type: FAIL_MENTOR_LEAVE,
});

const startEditMember = () => ({
  type: START_EDIT_MEMBER,
});

const completeEditMember = () => ({
  type: COMPLETE_EDIT_MEMBER,
});

const failEditMember = () => ({
  type: FAIL_EDIT_MEMBER,
});

const startUploadImage = () => ({
  type: START_UPLOAD_IMAGE,
});

const completeUploadImage = (team) => ({
  type: COMPLETE_UPLOAD_IMAGE,
  team,
});

const failUploadImage = () => ({
  type: FAIL_UPLOAD_IMAGE,
});

const startJoinTeam = () => ({
  type: START_JOIN_TEAM,
});

const completeJoinTeam = () => ({
  type: COMPLETE_JOIN_TEAM,
});

const failJoinTeam = () => ({
  type: FAIL_JOIN_TEAM,
});

const startLeaveTeamAndReassign = () => ({
  type: START_LEAVE_TEAM_AND_REASSIGN,
});

const completeLeaveTeamAndReassign = () => ({
  type: COMPLETE_LEAVE_TEAM_AND_REASSIGN,
});

const failLeaveTeamAndReassign = () => ({
  type: FAIL_LEAVE_TEAM_AND_REASSIGN,
});

export const getTeam = (teamId) => (dispatch) => {
  dispatch(startLoad());

  const getTeamPromise = doGetTeam(teamId);
  getTeamPromise
    .then((res) => dispatch(completeLoad(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoad());
    });
  return getTeamPromise;
};

export const createTeam = (values) => (dispatch) => {
  dispatch(startCreate());
  const CreateTeamPromise = doCreateTeam(values);
  CreateTeamPromise.then(() => {
    dispatch(notifySuccess(<FormattedMessage id="success.teamCreatedWithSuccess" />));
    dispatch(completeCreate());
  }).catch((error) => {
    dispatch(notifyError(error.response.data));
    dispatch(failCreate());
  });

  return CreateTeamPromise;
};

export const editTeam = (teamId, values) => (dispatch) => {
  dispatch(startEdit());
  const editTeamPromise = doEditTeam(teamId, values);
  editTeamPromise
    .then(() => {
      dispatch(notifySuccess(<FormattedMessage id="success.sectionUpdatedWithSuccess" />));
      dispatch(completeEdit());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEdit());
    });

  return editTeamPromise;
};

export const removeMember = (userId, teamId) => (dispatch) => {
  dispatch(startRemove());
  const removeMemberPromise = doRemoveMember(userId, teamId);
  removeMemberPromise
    .then(() => {
      dispatch(notifySuccess(<FormattedMessage id="success.memberDeletedSuccessfully" />));
      dispatch(completeRemove());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failRemove());
    });

  return removeMemberPromise;
};

export const mentorLeave = (userId, teamId) => (dispatch) => {
  dispatch(startMentorLeave());
  const mentorLeavePromise = doMentorLeave(userId, teamId);
  mentorLeavePromise
    .then(() => {
      dispatch(notifySuccess(<FormattedMessage id="success.youLeftTheTeamSuccessfully" />));
      dispatch(push('/'));
      dispatch(completeMentorLeave());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failMentorLeave());
    });

  return mentorLeavePromise;
};

export const leaveTeamAndReassignInformation =
  (userId, teamId, newOwnerId) => (dispatch) => {
    dispatch(startLeaveTeamAndReassign());
    const leaveTeamPromise = doLeaveTeamAndReassignInformations(
      userId,
      teamId,
      newOwnerId
    );
    leaveTeamPromise
      .then(() => {
        dispatch(notifySuccess(<FormattedMessage id="success.youLeftTheTeamSuccessfully" />));
        dispatch(push('/'));
        dispatch(completeLeaveTeamAndReassign());
      })
      .catch((error) => {
        dispatch(notifyError(error.response.data));
        dispatch(failLeaveTeamAndReassign());
      });

    return leaveTeamPromise;
  };

export const editEquityMember = (userId, teamId, values) => (dispatch) => {
  dispatch(startEditMember());
  const editEquityMemberPromise = doEditEquityMember(userId, teamId, values);
  editEquityMemberPromise
    .then(() => {
      dispatch(notifySuccess(<FormattedMessage id="success.memberUpdatedSuccessfully" />));
      dispatch(completeEditMember());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEditMember());
    });

  return editEquityMemberPromise;
};

export const uploadTeamImage = (teamId, image) => (dispatch) => {
  dispatch(startUploadImage());
  const uploadTeamImagePromise = doUploadTeamImage(teamId, image);
  uploadTeamImagePromise
    .then((res) => {
      dispatch(notifySuccess(<FormattedMessage id="success.sectionUpdatedWithSuccess" />));
      dispatch(completeUploadImage(res.data));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failUploadImage());
    });

  return uploadTeamImagePromise;
};

export const joinTeam = (teamCode) => (dispatch) => {
  dispatch(startJoinTeam());
  return doJoinTeam(teamCode)
    .then(() => {
      dispatch(notifySuccess(<FormattedMessage id="success.youJoinedATeamSuccessfully" />));
      dispatch(completeJoinTeam());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failJoinTeam());
    });
};

export const joinCohort = (teamId, cohortCode) => (dispatch) => {
  dispatch(startEdit());
  return doJoinCohort(teamId, cohortCode)
    .then(() => {
      dispatch(notifySuccess(<FormattedMessage id="success.youJoinedACohortSuccessfully" />));
      dispatch(completeEdit());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEdit());
    });
};

export const leaveCohort = (teamId) => (dispatch) => {
  dispatch(startEdit());
  return doLeaveCohort(teamId)
    .then(() => {
      dispatch(notifySuccess(<FormattedMessage id="success.youLeftTheCohortSuccessfully" />));
      dispatch(completeEdit());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEdit());
    });
};
