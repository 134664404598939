import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import DialogAlert from '../../../../common/components/DialogAlert';
import DrawerComponent from '../../../../common/components/DrawerComponent';
import AddDetailsModal from '../../../../common/modals/AddDetailsModal';
import AddTaskModal from '../../../../common/modals/AddTaskModal';
import AddConclusionModal from '../../components/AddConclusionModal';

import { currentTeamIdSelector } from '../../../../common/store';
import { useApi } from '../../../../hooks/useApi';
import { getActivityById } from '../../../../requests/activities';
import { createConclusion, deleteConclusionById, updateConclusionById } from '../../../../requests/conclusions';
import { editDetail } from '../../../../requests/details';
import {
  createTask,
  deleteTaskById,
  postTaskDetail,
  updateTaskById,
} from '../../../../requests/tasks';

import { BMC_TYPE } from '../../../../constants/bmc';
import { formatDetailForServer } from '../../../../utils/detailUtils';
import { handleApiError } from '../../../../utils/errorUtils';
import { TASK_STATUSES} from '../../constants';
import { formatConclusion } from './utils';

import ContentDetailDrawer from '../../../../common/drawers/ContentDetailDrawer';
import ActivityDetailsDrawer from '../ActivityDetailsDrawer';
import ConclusionDrawer from '../ConclusionDrawer';
import TaskDrawer from '../TaskDrawer';

const DeleteType = {
  activity: 'activity',
  task: 'task',
  conclusion: 'conclusion',
};

const ActivitiesDrawer = ({
  isOpen,
  handleCloseDrawer,
  activityId,
  teamMembers = [],
  tasks,
  conclusions,
  onFetchTasks,
  onFetchConclusions,
  handleDeleteActivity,
  handleEditActivity,
  isLoadingResources,
}) => {
  const intl = useIntl();
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );
  const [isAddConclusionModalOpen, setIsAddConclusionModalOpen] = useState(false);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [taskDetailModalId, setTaskDetailModalId] = useState(null);
  const [previewedTask, setPreviewedTask] = useState(null);
  const [previewedConclusion, setPreviewedConclusion] = useState(null);
  const [previewedDetail, setPreviewedDetail] = useState(null);
  const [deleteType, setDeleteType] = useState('');
  const [idToDelete, setIdToDelete] = useState('');
  const [activity, setActivity] = useState({});
  const hypothesis = useSelector((state) => state.hypotheses.hypothesis);

  const [, removeTask] = useApi(deleteTaskById, {}, handleApiError);
  const [, removeConclusion] = useApi(deleteConclusionById, {}, handleApiError);
  const [{ isLoading: isSubmittingConclusion}, runCreateConclusion] = useApi(createConclusion, {}, handleApiError);
  const [, runUpdateConclusion] = useApi(updateConclusionById, {}, handleApiError);
  const [, runCreateTask] = useApi(createTask, {}, handleApiError);
  const [{ isLoading: isSubmittingTask}, runUpdateTask] = useApi(updateTaskById, {}, handleApiError);
  const [, runCreateTaskDetail] = useApi(postTaskDetail, {}, handleApiError);

  useEffect(() => {
    if (activityId) {
      fetchActivity();
    }
  }, [activityId]);

  const fetchActivity = async () => {
    try {
      const response = await getActivityById(activityId);
      setActivity(response);
    } catch (e) {
      handleApiError(e);
    }
  };

  const deleteContent = () => {
    if (deleteType === DeleteType.activity) {
      return intl.formatMessage({ id: 'modal.content.deleteActivity' });
    }

    if (deleteType === DeleteType.task) {
      return intl.formatMessage({ id: 'modal.content.deleteTask' });
    }

    return intl.formatMessage({ id: 'modal.content.deleteConclusion' });
  };

  const handleEdit = () => {
    handleCloseDrawer();
    handleEditActivity(activity?._id);
  };

  const handleCloseDelete = () => {
    setDeleteType('');
    setIdToDelete('');
  };

  const handleDelete = () => {
    if (deleteType === DeleteType.activity) {
      return handleDeleteActivityItem();
    }

    if (deleteType === DeleteType.task) {
      return handleDeleteTask();
    }

    if (deleteType === DeleteType.conclusion) {
      return handleDeleteConclusion();
    }
  };

  const handleDeleteActivityItem = () => {
    handleDeleteActivity(activity?._id);
    handleCloseDrawer();
    handleCloseDelete();
  };

  const handleOpenDeleteTask = (taskId) => {
    setDeleteType(DeleteType.task);
    setIdToDelete(taskId);
  };

  const handleDeleteTask = async () => {
    await removeTask({
      params: {
        taskId: idToDelete,
      },
    }, () => {
      onFetchTasks();
      setPreviewedTask(null);
    });
    handleCloseDelete();
  };

  const handleOpenDeleteConclusion = (conclusionId) => {
    setDeleteType(DeleteType.conclusion);
    setIdToDelete(conclusionId);
  };

  const handleDeleteConclusion = () => removeConclusion(
    { params: { conclusionId: idToDelete } },
    () => {
      onFetchConclusions();
      setPreviewedConclusion(null);
      handleCloseDelete();
    }
  );

  const handleEditTask = () => setIsAddTaskModalOpen(true);

  const handleEditConclusion = () => setIsAddConclusionModalOpen(true);

  const handleUpdateTask = (taskData) => {
    runUpdateTask({
      params: { taskId: previewedTask?._id },
      taskData,
    }, () => {
      setIsAddTaskModalOpen(false);
      setPreviewedTask(null);
      onFetchTasks();
    });
  };

  const updateTaskStatus = (taskId, status) =>
    runUpdateTask({
      params: {
        taskId,
      },
      taskData: {
        status,
      },
    }, onFetchTasks);

  const handleToggleTask = async (taskId, currentTaskStatus) => {
    const status = currentTaskStatus === TASK_STATUSES.done
      ? TASK_STATUSES.inProgress
      : TASK_STATUSES.done;
    updateTaskStatus(taskId, status);
  };

  const handleSubmitConclusion = (values) => {
    runCreateConclusion({
      params: { activityId: activity?._id },
      conclusionData: formatConclusion(values),
    }, async () => {
      setIsAddConclusionModalOpen(false);
      await onFetchConclusions();
    });
  };

  const handleSubmitTask = async (values) => {
    const { title, status, description, assignedTo } = values;

    await runCreateTask({
      params: {
        activityId: activity?._id,
      },
      taskData: {
        userResponsibleId: assignedTo,
        title,
        description,
        status,
        completionDate: Date.now(),
      },
    }, async () => {
      setIsAddTaskModalOpen(false);
      await onFetchTasks();
    });
  };

  const handleCreateTaskDetail = async (values) => {
    const payload = {
      ...formatDetailForServer(values),
      taskId: taskDetailModalId,
      section: 'tasks',
      activityId: activity?._id,
    };

    await runCreateTaskDetail({
      params: { teamId },
      detailData: payload,
    }, (response) => {
      onFetchTasks();
      setPreviewedTask({
        ...previewedTask,
        cost: previewedTask?.cost ? previewedTask?.cost + response?.cost : response?.cost,
        details: previewedTask?.details ? [...previewedTask?.details, response] : [response],
      });
      fetchActivity();
      setTaskDetailModalId(null);
    });
  };

  const handleClose = () => {
    handleCloseDrawer();
    setPreviewedTask(null);
    setPreviewedDetail(null);
    setPreviewedConclusion(null);
  };

  const setPreview = (entity, entityType) => {
    if (entityType === 'TASK') {
      setPreviewedTask(entity);
    }

    if (entityType === 'CONCLUSION') {
      setPreviewedConclusion(entity);
    }

    if (entityType === 'DETAIL') {
      setPreviewedDetail(entity);
    }
  };

  const handleUpdateDetail = async (values) => {
    try {
      const response = await editDetail(formatDetailForServer(values), previewedDetail?._id);

      if (previewedTask) {
        setPreviewedTask({
          ...previewedTask,
          details: previewedTask?.details?.map((detail) => (
            detail?._id === previewedDetail?._id ? response?.data : detail
          ))
        });
      }

      setTaskDetailModalId(null);
      setPreviewedDetail(response?.data);
      onFetchTasks();
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleBackDetail = () => setPreviewedDetail(null);

  const handleSuccessDeleteDetail = () => {
    if (previewedTask) {
      setPreview({
        ...previewedTask,
        details: previewedTask?.details?.filter(({ _id }) => _id !== previewedDetail?._id)
      }, 'TASK');
    }
    onFetchTasks();
  };

  const handleCloseDetail = () => setPreview(null, 'DETAIL');

  const handleEditDetail = (detail) => setTaskDetailModalId(detail?.taskId);

  const handleUpdateConclusion = (values) => {
    runUpdateConclusion({
      params: { conclusionId: previewedConclusion._id },
      conclusionData: formatConclusion(values),
    }, () => {
      setIsAddConclusionModalOpen(false);
      setPreviewedConclusion(null);
      onFetchConclusions();
    });
  };

  return (
    <>
      <DrawerComponent
        isOpen={isOpen}
        onClose={handleClose}
      >
        {previewedTask && !previewedDetail && (
          <TaskDrawer
            previewedTask={previewedTask}
            handleCloseDrawer={handleCloseDrawer}
            setPreview={setPreview}
            handleDeleteTask={handleOpenDeleteTask}
            handleEditTask={handleEditTask}
            setTaskDetailModalId={setTaskDetailModalId}
          />
        )}

        {previewedConclusion && (
          <ConclusionDrawer
            setPreviewedConclusion={setPreviewedConclusion}
            onCloseDrawer={handleClose}
            previewedConclusion={previewedConclusion}
            onDeleteConclusion={handleOpenDeleteConclusion}
            onEditConclusion={handleEditConclusion}
          />
        )}

        {previewedDetail && (
          <ContentDetailDrawer
            initialDetail={previewedDetail}
            onCloseDrawer={handleCloseDetail}
            onEdit={handleEditDetail}
            onBack={handleBackDetail}
            onSuccessDelete={handleSuccessDeleteDetail}
          />
        )}

        {!previewedTask && !previewedConclusion && !previewedDetail && (
          <ActivityDetailsDrawer
            handleCloseDrawer={handleCloseDrawer}
            activity={activity}
            isLoadingResources={isLoadingResources}
            tasks={tasks}
            conclusions={conclusions}
            handleEdit={handleEdit}
            handleDelete={() => setDeleteType(DeleteType.activity)}
            responsable={activity?.userResponsibleId}
            setIsAddTaskModalOpen={setIsAddTaskModalOpen}
            handleToggleTask={handleToggleTask}
            setIsAddConclusionModalOpen={setIsAddConclusionModalOpen}
            setPreview={setPreview}
            isSubmittingTask={isSubmittingTask}
            isSubmittingConclusion={isSubmittingConclusion}
            setTaskDetailModalId={setTaskDetailModalId}
          />
        )}
      </DrawerComponent>

      <AddConclusionModal
        handleClose={() => setIsAddConclusionModalOpen(false)}
        handleSubmit={handleSubmitConclusion}
        isModalOpen={isAddConclusionModalOpen}
        conclusion={previewedConclusion}
        isEditMode={!!previewedConclusion}
        handleUpdate={handleUpdateConclusion}
      />
      <AddTaskModal
        handleClose={() => setIsAddTaskModalOpen(false)}
        handleSubmit={handleSubmitTask}
        handleUpdate={handleUpdateTask}
        isModalOpen={isAddTaskModalOpen}
        teamMembers={teamMembers}
        isEditMode={!!previewedTask}
        editedTask={previewedTask}
      />
      <AddDetailsModal
        isModalOpen={!!taskDetailModalId}
        handleClose={() => setTaskDetailModalId(null)}
        handleSubmitDetail={handleCreateTaskDetail}
        formValues={previewedDetail}
        handleUpdateDetail={handleUpdateDetail}
        hypothesisId={hypothesis?._id}
        hasExtendedCategories={hypothesis?.columnBMC === BMC_TYPE.customerSegments}
      />

      {deleteType && (
        <DialogAlert
          isModalOpen={deleteType}
          onClose={handleCloseDelete}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={deleteContent()}
          hasActions
          onSubmit={handleDelete}
        />
      )}
    </>
  );
};

export default ActivitiesDrawer;
