import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleWrapper = styled(Box)(({ theme }) => `
  color: ${theme.palette.primary.main};
  display: flex;
  align-items: center;
  gap: ${theme.spacing(10)};
  cursor: pointer;
`);

export const Root = styled(Box)(({ theme }) => `
  border: 1px solid ${theme.palette.general.borderAccent};
  background-color: ${theme.palette.common.white};
  width: 100%;
  height: 300px;
  border-radius: 10px;
  padding: ${theme.spacing(20)};
`);

export const Header  = styled(Box)(({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(10)};
  margin-bottom: ${theme.spacing(10)};
`);

export const Content = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(10)};
  paddingBottom: ${theme.spacing(4)};
  overflow-y: auto;
  height: calc(100% - 50px);
`);
