import update from 'immutability-helper';

import { notifyError } from '../../core/store';
import {
  loadMentors as doLoadMentors,
} from '../../requests/mentors';

// Actions
const START_LOAD = 'mentors/START_LOAD';
const COMPLETE_LOAD = 'mentors/COMPLETE_LOAD';
const FAIL_LOAD = 'mentors/FAIL_LOAD';

const START_LOAD_INVITATIONS = 'mentors-invitations/START_LOAD_INVITATIONS';
const COMPLETE_LOAD_INVITATIONS =
  'mentors-invitations/COMPLETE_LOAD_INVITATIONS';
const FAIL_LOAD_INVITATIONS = 'mentors-invitations/FAIL_LOAD_INVITATIONS';

const START_ADD = 'mentors-invitations/START_ADD';
const COMPLETE_ADD = 'mentors-invitations/COMPLETE_ADD';
const FAIL_ADD = 'mentors-invitations/FAIL_ADD';

const START_ACCEPT_SEH_INVITATION =
  'mentors-invitations/START_ACCEPT_SEH_INVITATION';
const COMPLETE_ACCEPT_SEH_INVITATION =
  'mentors-invitations/COMPLETE_ACCEPT_SEH_INVITATION';
const FAIL_ACCEPT_SEH_INVITATION =
  'mentors-invitations/FAIL_ACCEPT_SEH_INVITATION';

const START_REFUSE_SEH_INVITATION =
  'mentors-invitations/START_REFUSE_SEH_INVITATION';
const COMPLETE_REFUSE_SEH_INVITATION =
  'mentors-invitations/COMPLETE_REFUSE_SEH_INVITATION';
const FAIL_REFUSE_SEH_INVITATION =
  'mentors-invitations/FAIL_REFUSE_SEH_INVITATION';

const START_DELETE = 'mentors-invitations/START_DELETE';
const COMPLETE_DELETE = 'mentors-invitations/COMPLETE_DELETE';
const FAIL_DELETE = 'mentors-invitations/FAIL_DELETE';

const RESET = 'mentors/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isDeleting: false,
  isCreating: false,
  isAcceptingSehInvitation: false,
  isRefusingSehInvitation: false,
  mentors: undefined,
  invitations: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          mentors: action.mentors,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          mentors: undefined,
        },
      });

    case START_ADD:
      return update(state, {
        $merge: {
          isCreating: true,
        },
      });

    case COMPLETE_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case FAIL_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case START_LOAD_INVITATIONS:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD_INVITATIONS:
      return update(state, {
        $merge: {
          isLoading: false,
          invitations: action.invitations,
        },
      });

    case FAIL_LOAD_INVITATIONS:
      return update(state, {
        $merge: {
          isLoading: false,
          invitations: undefined,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case START_ACCEPT_SEH_INVITATION:
      return update(state, {
        $merge: {
          isAcceptingSehInvitation: true,
        },
      });

    case COMPLETE_ACCEPT_SEH_INVITATION:
      return update(state, {
        $merge: {
          isAcceptingSehInvitation: false,
        },
      });

    case FAIL_ACCEPT_SEH_INVITATION:
      return update(state, {
        $merge: {
          isAcceptingSehInvitation: false,
        },
      });

    case START_REFUSE_SEH_INVITATION:
      return update(state, {
        $merge: {
          isRefusingSehInvitation: true,
        },
      });

    case COMPLETE_REFUSE_SEH_INVITATION:
      return update(state, {
        $merge: {
          isRefusingSehInvitation: false,
        },
      });

    case FAIL_REFUSE_SEH_INVITATION:
      return update(state, {
        $merge: {
          isRefusingSehInvitation: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (mentors) => ({
  type: COMPLETE_LOAD,
  mentors,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadMentors = (teamId, queryParams) => (dispatch) => {
  dispatch(startLoad());
  const loadMentorsPromise = doLoadMentors(teamId, queryParams);

  loadMentorsPromise
    .then((res) => {
      dispatch(completeLoad(res.data));
    })
    .catch((error) => {
      dispatch(failLoad());
      dispatch(notifyError(error.response.data));
    });
  return loadMentorsPromise;
};
