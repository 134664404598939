import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import AddButton from '../../common/components/AddButton';
import ButtonComponent from '../../common/components/ButtonComponent';
import DualOptionsSelect from '../../common/components/DualOptionSelect';

import { FilterIcon } from '../../assets/icons';

const ArticlePageHeaderSection = ({
  hasFilters,
  headerTitle,
  handleFiltersModalStatus,
  filtersNumber,
  toggleValue,
  handleToggleValue,
  onAddClick,
}) => (
  <Grid
    container
    justifyContent="space-between"
    sx={{
      borderBottom: '1px solid #74A3FE',
      paddingBottom: '10px',
      gap: '10px',
      alignItems: 'flex-end',
      marginTop: '20px',
      width: '70%',
    }}
  >
    <Typography variant="h2" color="primary">{headerTitle}</Typography>
    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
      {hasFilters && (
        <ButtonComponent
          color="secondary"
          variant="outlined"
          onClick={handleFiltersModalStatus}
          icon={FilterIcon}
          sx={{ py: 2.75 }}
          label={`Filtre (${filtersNumber})`}
        />
      )}
      <DualOptionsSelect
        toggleValue={toggleValue}
        handleToggleValue={handleToggleValue}
      />
      <AddButton onClick={onAddClick} p={5} />
    </Box>
  </Grid>
);

export default ArticlePageHeaderSection;
