import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { reducer as contextAnalysisReducer } from './contextAnalysis/contextAnalysisSlice';
import { reducer as opportunityAnalysislReducer } from './contextAnalysis/opportunityAnalysisSlice';

import { reducer as adminReducer } from '../admin/store';
import { reducer as contactFormReducer } from '../admin/store/contactForm';
import { reducer as commonReducer } from '../common/store';
import { reducer as coreReducer } from '../core/store';
import { reducer as commentsReducer } from '../modules/commentsAndNotifications/store/comments';
import { reducer as mrlReducer } from '../modules/mrl/store/mrl';

import { reducer as experimentsReducer } from '../_deprecated/experiments/store/experiments';
import { reducer as authReducer } from './auth/login';
import { reducer as cohortsReducer } from './cohorts/cohortsSlice';
import { reducer as detailsReducer } from './details/detailsSlice';
import { reducer as hypothesesReducer } from './hypothesis/hypothesisSlice';
import { reducer as teamReducer } from './idea/teamSlice';
import { reducer as leaderboardReducer } from './leaderboard/leaderboardSlice';
import { reducer as mentorsReducer } from './mentors/mentorsSlice';
import { reducer as targetIndicatorsReducer } from './targetIndicators/targetIndicatorsSlice';
import { reducer as userProfileReducer } from './userProfile/userProfileSlice';

// import { reducer as experimentDetailReducer } from './_deprecated/experimentDetails/store/experimentsDetail';
// import { reducer as hypothesisDetailReducer } from './modules/hypothesisDetails/store/hypothesisDetail';
// import { reducer as resolutionReducer } from './modules/resolutions/store/resolution';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  core: coreReducer,
  account: authReducer,
  cohorts: cohortsReducer,
  admin: adminReducer,
  userProfile: userProfileReducer,
  common: commonReducer,
  experiments: experimentsReducer,
  hypotheses: hypothesesReducer,
  leaderboard: leaderboardReducer,
  team: teamReducer,
  opportunityAnalysis: opportunityAnalysislReducer,
  mentors: mentorsReducer,
  details: detailsReducer,
  contactForm: contactFormReducer,
  mrl: mrlReducer,
  contextAnalysis: contextAnalysisReducer,
  targetIndicators: targetIndicatorsReducer,
  comments: commentsReducer,
  // hypothesisDetail: hypothesisDetailReducer,
  // resolution: resolutionReducer,
  // experimentDetail: experimentDetailReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
