import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import ButtonComponent from '../../common/components/ButtonComponent';

import { AddIcon, EditIcon } from '../../assets/icons';

import InputField from '../../fields/InputField';

const CategoryForm = ({ handleSubmit, data = null, allCategoryNames = [] }) => {
  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Câmp obligatoriu')
      .notOneOf(allCategoryNames, 'Name already exists'),
  });

  const initialValues = data ? { name: data.name } : { name: '' };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <InputField id="name" label="Nume" />
          {data ? (
              <ButtonComponent
                color="secondary"
                type="submit"
                icon={EditIcon}
                sx={{ mt: 30 }}
                label="Editeaza"
              />
          ) : (
            <ButtonComponent
              color="secondary"
              type="submit"
              icon={AddIcon}
              sx={{ mt: 30 }}
              label="Adauga"
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default CategoryForm;
