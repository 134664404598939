import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ComingSoon from '../../common/components/_ComingSoon';
import PageTitle from '../../common/components/PageTitle';
import Tabs from '../../common/components/Tabs';
import { TabContent } from '../../common/components/Tabs/index.styled';

import { currentTeamSelector } from '../../common/store/userTeamsSelector';

import { MY_IDEA_TABS } from './constants';

import DetailsTable from '../../common/containers/DetailsTable';
import IdeaDescription from '../../modules/myIdeaDescription/containers/IdeaDescription';

const MyIdea = () => {
  const intl = useIntl();
  const currentTeam = useSelector(currentTeamSelector) || {};
  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'description';
  const [activeTab, setActiveTab] = useState(tabFromQuery);
  const history = useHistory();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/idea?tab=${tab}`);
  };

  const pageComponents = {
    description: <IdeaDescription currentTeam={currentTeam} />,
    details: <DetailsTable section="teams" />,
    mrl: <ComingSoon />,
    profile: <ComingSoon />,
  };

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'label.myIdea' })} />

      <Tabs
        tabs={MY_IDEA_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
      />

      <TabContent className="scrollbar">
        {pageComponents[activeTab]}
      </TabContent>
    </>
  );
};

export default MyIdea;
