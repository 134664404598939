import { Box } from '@mui/material';
import React from 'react';

import {
  generateCommentPathByType,
  generateReadablePathByType,
} from '../helpers';

const CommentPathRouter = ({ commentType, commentId, history, entityId }) => {
  let path = generateCommentPathByType({ commentType, commentId, entityId });
  const readablePath = generateReadablePathByType(commentType);

  if (entityId) {
    path = path.replace(':entityId', entityId);
  }

  const handleNavigation = () => {
    if (path) {
      history.push(path);
    }
  };

  return (
    <Box sx={{ textDecoration: 'underline' }} onClick={handleNavigation}>
      {readablePath}
    </Box>
  );
};

export default CommentPathRouter;
