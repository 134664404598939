import update from 'immutability-helper';
import { map, get, find, identity } from 'lodash-es';
import { push } from 'react-router-redux';
import { createSelector } from 'reselect';

import { notifyError, notifySuccess } from '../../core/store';
import {
  loadCohorts as doLoadCohorts,
  addCohort as doAddCohort,
  setCurrentUserCohort as doSetCurrentUserCohort,
  isCohortExpired as doIsCohortExpired,
  editCohort as doEditCohort,
  deleteCohort as doDeleteCohort,
} from '../../requests/cohorts';

// Actions
const START_LOAD = 'cohorts/START_LOAD';
const COMPLETE_LOAD = 'cohorts/COMPLETE_LOAD';
const FAIL_LOAD = 'cohorts/FAIL_LOAD';

const START_ADD = 'cohorts/START_ADD';
const COMPLETE_ADD = 'cohorts/COMPLETE_ADD';
const FAIL_ADD = 'cohorts/FAIL_ADD';

const START_EDIT = 'cohorts/START_EDIT';
const COMPLETE_EDIT = 'cohorts/COMPLETE_EDIT';
const FAIL_EDIT = 'cohorts/FAIL_EDIT';

const START_DELETE = 'cohorts/START_DELETE';
const COMPLETE_DELETE = 'cohorts/COMPLETE_DELETE';
const FAIL_DELETE = 'cohorts/FAIL_DELETE';

const COMPLETE_CHECK_COHORT_CODE = 'cohorts/COMPLETE_CHECK_COHORT_CODE';
const FAIL_CHECK_COHORT_CODE = 'cohorts/FAIL_CHECK_COHORT_CODE';

const UPDATE_SELECTED_COHORT = 'cohorts/UPDATE_SELECTED_COHORT';

const RESET = 'cohorts/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  cohorts: undefined,
  isCohortExpired: false,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          cohorts: action.cohorts,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          cohorts: undefined,
        },
      });

    case START_ADD:
      return update(state, {
        $merge: {
          isCreating: true,
        },
      });

    case COMPLETE_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });

    case FAIL_ADD:
      return update(state, {
        $merge: {
          isCreating: false,
        },
      });
    case START_EDIT:
      return update(state, {
        $merge: {
          isEditing: true,
        },
      });

    case COMPLETE_EDIT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case FAIL_EDIT:
      return update(state, {
        $merge: {
          isEditing: false,
        },
      });

    case START_DELETE:
      return update(state, {
        $merge: {
          isDeleting: true,
        },
      });

    case COMPLETE_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });

    case FAIL_DELETE:
      return update(state, {
        $merge: {
          isDeleting: false,
        },
      });
    case COMPLETE_CHECK_COHORT_CODE:
      return update(state, {
        $merge: {
          isCohortExpired: action.isCohortExpired,
        },
      });
    case FAIL_CHECK_COHORT_CODE:
      return update(state, {
        $merge: {
          isCohortExpired: false,
        },
      });

    case UPDATE_SELECTED_COHORT: {
      const { cohorts } = state;
      const updatedCohorts = map(cohorts, (cohort) =>
        cohort._id === action.cohortId
          ? { ...cohort, selected: true }
          : { ...cohort, selected: false }
      );
      return update(state, {
        $merge: {
          isLoading: false,
          cohorts: updatedCohorts,
        },
      });
    }

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (cohorts) => ({
  type: COMPLETE_LOAD,
  cohorts,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startAdd = () => ({
  type: START_ADD,
});

const completeAdd = () => ({
  type: COMPLETE_ADD,
});

const failAdd = () => ({
  type: FAIL_ADD,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startDelete = () => ({
  type: START_DELETE,
});

const completeDelete = () => ({
  type: COMPLETE_DELETE,
});

const failDelete = () => ({
  type: FAIL_DELETE,
});

const completeCheck = (isCohortExpired) => ({
  type: COMPLETE_CHECK_COHORT_CODE,
  isCohortExpired,
});

const failCheck = () => ({
  type: FAIL_CHECK_COHORT_CODE,
});

const updateSelectedCohort = (cohortId) => ({
  type: UPDATE_SELECTED_COHORT,
  cohortId,
});

const reset = () => ({
  type: RESET,
});

export const loadCohorts = (userId) => (dispatch) => {
  dispatch(startLoad());
  return doLoadCohorts(userId)
    .then((res) => dispatch(completeLoad(res)))
    .catch(() => dispatch(failLoad()));
};

export const addCohort = (values) => (dispatch) => {
  dispatch(startAdd());
  return doAddCohort(values)
    .then((res) => dispatch(completeAdd(res)))
    .catch(() => dispatch(failAdd()));
};

export const editCohort = (cohortId, values) => (dispatch) => {
  dispatch(startEdit());
  return doEditCohort(cohortId, values)
    .then(() => {
      dispatch(completeEdit());
      dispatch(notifySuccess('Cohorta a fost editata cu succes!'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEdit());
    });
};

export const deleteCohort = (cohortId) => (dispatch) => {
  dispatch(startDelete());
  return doDeleteCohort(cohortId)
    .then(() => dispatch(completeDelete()))
    .catch(() => dispatch(failDelete()));
};

export const checkIsCohortExpired = (cohortCode) => (dispatch) =>
  doIsCohortExpired(cohortCode)
    .then((res) => dispatch(completeCheck(res.data.isExpired)))
    .catch(() => dispatch(failCheck()));

export const setCurrentUserCohort = (userId, cohortId) => (dispatch) => {
  doSetCurrentUserCohort(userId, cohortId);
  dispatch(updateSelectedCohort(cohortId));
  dispatch(push(`/accelerator/${cohortId}/teams`));
};

export const resetUserCohorts = () => (dispatch) => dispatch(reset());

const selectCurrentCohort = (state) =>
  get(
    find(state.cohorts, (cohort) => !!cohort.selected),
    '_id'
  );

export const currentCohortIdSelector = createSelector(
  selectCurrentCohort,
  identity
);
