import React from 'react';

import ColumnOptionsStep from '../ColumnOptionsStep';

const Role = ({
   isInvited,
  ...remainingProps
 }) => {
  return (
    <ColumnOptionsStep
      {...remainingProps}
      disableOptions={isInvited}
    />
  );
};

export default Role;
