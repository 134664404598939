import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { HYPOTHESIS_IMPORTANCE_DETAILS } from '../../constants';

const HypothesisInfo = ({ hypothesis }) => {
  const intl = useIntl();
  const importanceDetails = HYPOTHESIS_IMPORTANCE_DETAILS?.[hypothesis?.importance];

  return (
    <Grid display="flex" flexDirection="column" gap={20}>
      <Typography variant="h2" color="primary">
        {intl.formatMessage({ id: 'label.hypothesis' })}
      </Typography>
      {hypothesis.description && (
        <Typography variant="body1">
          {hypothesis.description}
        </Typography>
      )}
      {importanceDetails && (
        <Box display="flex" alignItems="center" gap={5}>
          <Typography variant="body1" fontWeight={600} color="primary">
            {intl.formatMessage({ id: 'label.importance' })}:
          </Typography>
          {importanceDetails.icon}
          <Typography variant="body1" color="textSecondary">
            {intl.formatMessage({ id: importanceDetails.label })}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default HypothesisInfo;
