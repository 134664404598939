import { FormattedMessage } from 'react-intl';

export const formatRoute = (route, params) => {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
};

export const avatarInitials = (user) => {
  const parts = [user.firstName, user.lastName];
  return parts
    .filter((part) => part)
    .map((part) => part.substring(0, 1))
    .join('');
};

export const formatUserName = (user) => {
  const parts = [user.firstName, user.lastName];
  return parts.filter((part) => part).join(' ');
};

export const formatUserInitials = (user) => {
  const { email, firstName, lastName } = user || {};
  const nameInitials = [
    firstName?.[0],
    lastName?.[0],
  ].filter((letter) => letter);
  const initials = nameInitials?.length ? nameInitials.join('') : email?.charAt(0).toUpperCase();

  return initials || '';
};

export const formatMembersToOptions = (teamMembers) => (
  teamMembers
    ?.filter((member) => member?.userProfileId)
    ?.map((member) => ({
      value: member.userProfileId,
      label: formatUserName(member),
    }))
);

export const formatSubscriptionPrice = (price) => price ? price / 100 : 0;

export const formatRewardUM = (reward) => [reward.value, reward.unitOfMeasurement].filter((item) => item).join(' ');

export const industriesSerializer = (industries) => {
  const mappingByIndustryType = {};

  industries.forEach((industry) => {
    const { industryType } = industry;
    if (!mappingByIndustryType[industryType]) {
      mappingByIndustryType[industryType] = [];
    }
    mappingByIndustryType[industryType].push(industry);
  });

  return mappingByIndustryType;
};

export const swotsSerializer = (swots) => {
  const mappingBySwotType = {};

  swots.forEach((swot) => {
    const { swotType } = swot;
    if (!mappingBySwotType[swotType]) {
      mappingBySwotType[swotType] = [];
    }
    mappingBySwotType[swotType].push(swot);
  });

  return mappingBySwotType;
};

export const teamCompetitorsSerializer = (competitorData) => {
  const {
    competitors = [],
    competitionMetrics = [],
    criteria = [],
  } = competitorData;

  const initialHeader = [
    {
      value: '',
      coordinates: { competitorId: 'default' },
      disabled: true,
      label: <FormattedMessage id="label.competitorOrCriteria" />,
      id: 'defaultHeader',
    },
  ];

  const criteriaHeaders = criteria.map((criterion) => ({
    value: criterion.name,
    coordinates: { criteriaId: criterion._id },
    action: 'editCriteriaName',
    disabled: false,
    id: criterion._id,
  }));

  const headers = [...initialHeader, ...criteriaHeaders];
  const lastHeader = headers && headers[headers.length - 1];

  const criteriaMap = {};
  criteria.forEach((criterion) => {
    const { name } = criterion;
    criteriaMap[name] = criterion;
  });

  const competitorMap = {};
  competitors.forEach((competitor) => {
    const { name } = competitor;
    competitorMap[name] = competitor;
  });

  const additionalHeadersCount = 6 - headers.length;
  const additionalHeaders = Array.from(
    { length: additionalHeadersCount },
    (_, index) => ({
      value: '',
      coordinates: { criteriaId: null },
      disabled: false,
      action: 'addCriteria',
      id: `header-post-${lastHeader.id}-${index}`,
    })
  );

  return {
    competitorTableHeaders: [...headers, ...additionalHeaders],
    competitionMetrics,
    criteria: criteriaMap,
    criteriaList: Object.keys(criteriaMap),
    competitorList: competitors,
    competitors: competitorMap,
  };
};

export const formatCurrentUrl = (key, id) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(key, id);
  return currentUrl.pathname + currentUrl.search;
};
