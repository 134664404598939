import { styled } from '@mui/material/styles';

export const Indicator = styled('div')(({
  theme,
  hasDashedBorder,
  hasMissingInfo,
}) => `
  border: 1px ${hasDashedBorder ? 'dashed' : 'solid'} ${theme.palette.general.borderAccent};
  border-radius: 5px;
  padding: ${theme.spacing(10, 15)};
  ${hasMissingInfo 
    ? `
      & .MuiTypography-root { 
        color: ${theme.palette.error.secondary}; 
      }
      border-color: ${theme.palette.error.secondary};
    ` : ''
  }
`);
