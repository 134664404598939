import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import PageTitle from '../../../../common/components/PageTitle';
import Tabs from '../../../../common/components/Tabs';
import { TabContent } from '../../../../common/components/Tabs/index.styled';
import ActivitiesComponent from '../../components/ActivitiesComponent';
import ConclusionsComponent from '../../components/ConclusionsComponent';
import DetailsComponent from '../../components/DetailsComponent';
import HypothesisComponent from '../../components/HypothesisComponent';
import IndicatorsComponent from '../../components/IndicatorsComponent';
import TasksComponent from '../../components/TasksComponent';

import { BOARDS_TABS } from '../../constants';

const pageComponents = {
  hypothesis: {
    title: 'label.hypotheses',
    component: <HypothesisComponent />,
  },
  activities: {
    title: 'label.activities',
    component: <ActivitiesComponent />,
  },
  tasks: {
    title: 'label.tasks',
    component: <TasksComponent />,
  },
  details: {
    title: 'label.details',
    component: <DetailsComponent />,
  },
  indicators: {
    title: 'label.indicators',
    component: <IndicatorsComponent />,
  },
  conclusions: {
    title: 'label.conclusions',
    component: <ConclusionsComponent />,
  },
};

export const BoardsPage = () => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const tabFromQuery = query.get('tab') || 'activities';
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/boards?tab=${tab}`);
  };
  return (
    <>
      <PageTitle
        title={intl.formatMessage(
          { id: 'label.boardsType' },
          { type: intl.formatMessage({ id: pageComponents[activeTab]?.title }) }
        )}
      />

      <Tabs
        tabs={BOARDS_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
      />

      <TabContent className="scrollbar">
        {pageComponents[activeTab].component}
      </TabContent>
    </>
  );
};
