import update from 'immutability-helper';
import { FormattedMessage } from 'react-intl';

import { notifySuccess, notifyError } from '../../core/store';
import { setUserRole } from '../../requests/onboarding';
import {
  loadProfile as doLoadProfile,
  editProfile as doEditProfile,
  loadHypothesesPercentage as doLoadHypothesesPercentage,
  loadExperimentsPercentage as doLoadExperimentsPercentage,
  uploadProfilePicture as doUploadProfilePicture,
  subscribeUserToMailchimp as doSubscribeUserToMailchimp,
  getAllSubscriptionProducts as doGetAlSubscriptionProducts,
  unsubscribeUserFromMailchimp as doUnsubscribeUserFromMailchimp,
  getPaymentsForLoggedUser as doGetPaymentsForLoggedUser,
  getInvoiceById as doGetInvoiceById,
  startCheckoutSession as doStartCheckoutSession,
  updateSubscription as doUpdateSubscription,
} from '../../requests/userProfile';

import {
  getAllPaymentsSerializer,
  getAllProductsSerializer,
} from './serializers';

// Actions
const START_LOAD = 'profile/START_LOAD';
const COMPLETE_LOAD = 'profile/COMPLETE_LOAD';
const FAIL_LOAD = 'profile/FAIL_LOAD';

const START_LOAD_HYPOTHESES_PERCENTAGE =
  'profile/START_LOAD_HYPOTHESES_PERCENTAGE';
const COMPLETE_LOAD_HYPOTHESES_PERCENTAGE =
  'profile/COMPLETE_LOAD_HYPOTHESES_PERCENTAGE';
const FAIL_LOAD_HYPOTHESES_PERCENTAGE =
  'profile/FAIL_LOAD_HYPOTHESES_PERCENTAGE';

const START_LOAD_EXPERIMENTS_PERCENTAGE =
  'profile/START_LOAD_EXPERIMENTS_PERCENTAGE';
const COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE =
  'profile/COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE';
const FAIL_LOAD_EXPERIMENTS_PERCENTAGE =
  'profile/FAIL_LOAD_EXPERIMENTS_PERCENTAGE';

const START_EDIT = 'profile/START_EDIT';
const COMPLETE_EDIT = 'profile/COMPLETE_EDIT';
const FAIL_EDIT = 'profile/FAIL_EDIT';

const START_UPLOAD_PROFILE_PICTURE = 'profile/START_UPLOAD_PROFILE_PICTURE';
const COMPLETE_UPLOAD_PROFILE_PICTURE =
  'profile/COMPLETE_UPLOAD_PROFILE_PICTURE';
const FAIL_UPLOAD_PROFILE_PICTURE = 'profile/FAIL_UPLOAD_PROFILE_PICTURE';

const START_SUBSCRIBE_USER = 'profile/START_SUBSCRIBE_USER';
const COMPLETE_SUBSCRIBE_USER = 'profile/COMPLETE_SUBSCRIBE_USER';
const FAIL_SUBSCRIBE_USER = 'profile/FAIL_SUBSCRIBE_USER';

const START_UNSUBSCRIBE_USER = 'profile/START_UNSUBSCRIBE_USER';
const COMPLETE_UNSUBSCRIBE_USER = 'profile/COMPLETE_UNSUBSCRIBE_USER';
const FAIL_UNSUBSCRIBE_USER = 'profile/FAIL_UNSUBSCRIBE_USER';

const FAIL_GET_ALL_SUBSCRIPTION_PRODUCTS =
  'profile/FAIL_GET_ALL_SUBSCRIPTION_PRODUCTS';
const COMPLETE_LOAD_ALL_SUBSCRIPTION_PRODUCTS =
  'profile/COMPLETE_GET_ALL_SUBSCRIPTION_PRODUCTS';

const COMPLETE_GET_LOGGED_USER_PAYMENTS =
  'profile/COMPLETE_GET_LOGGED_USER_PAYMENTS';
const FAIL_GET_LOGGED_USER_PAYMENTS = 'profile/FAIL_GET_LOGGED_USER_PAYMENTS';
const START_GET_LOGGED_USER_PAYMENTS = 'profile/START_GET_LOGGED_USER_PAYMENTS';

const RESET = 'experiments/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isEditing: false,
  userProfile: undefined,
  hypothesesPercentage: undefined,
  experimentsPercentage: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: { isLoading: false, userProfile: action.userProfile },
      });

    case FAIL_LOAD:
      return update(state, { $merge: { isLoading: false } });

    case START_LOAD_HYPOTHESES_PERCENTAGE:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD_HYPOTHESES_PERCENTAGE:
      return update(state, {
        $merge: {
          isLoading: false,
          hypothesesPercentage: action.hypothesesPercentage,
        },
      });

    case FAIL_LOAD_HYPOTHESES_PERCENTAGE:
      return update(state, { $merge: { isLoading: false } });

    case START_LOAD_EXPERIMENTS_PERCENTAGE:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE:
      return update(state, {
        $merge: {
          isLoading: false,
          experimentsPercentage: action.experimentsPercentage,
        },
      });

    case FAIL_LOAD_EXPERIMENTS_PERCENTAGE:
      return update(state, { $merge: { isLoading: false } });

    case START_EDIT:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case FAIL_EDIT:
      return update(state, { $merge: { isEditing: false } });

    case START_UPLOAD_PROFILE_PICTURE:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_UPLOAD_PROFILE_PICTURE:
      return update(state, {
        $merge: { isEditing: false, userProfile: action.userProfile },
      });

    case FAIL_UPLOAD_PROFILE_PICTURE:
      return update(state, { $merge: { isEditing: false } });

    case START_SUBSCRIBE_USER:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_SUBSCRIBE_USER:
      return update(state, {
        $merge: { isEditing: false },
      });

    case FAIL_UNSUBSCRIBE_USER:
      return update(state, { $merge: { isEditing: false } });

    case START_UNSUBSCRIBE_USER:
      return update(state, { $merge: { isEditing: true } });

    case COMPLETE_UNSUBSCRIBE_USER:
      return update(state, {
        $merge: { isEditing: false },
      });

    case COMPLETE_LOAD_ALL_SUBSCRIPTION_PRODUCTS:
      return update(state, {
        $merge: {
          isLoading: false,
          allSubscriptionProducts: action.allSubscriptionProducts,
        },
      });

    case FAIL_GET_ALL_SUBSCRIPTION_PRODUCTS:
      return update(state, { $merge: { isLoading: false } });

    case START_GET_LOGGED_USER_PAYMENTS:
      return update(state, { $merge: { isLoadingPayments: true } });

    case COMPLETE_GET_LOGGED_USER_PAYMENTS:
      return update(state, {
        $merge: {
          isLoadingPayments: false,
          loggedUserPayments: action.loggedUserPayments,
        },
      });

    case FAIL_GET_LOGGED_USER_PAYMENTS:
      return update(state, { $merge: { isLoadingPayments: false } });

    case FAIL_SUBSCRIBE_USER:
      return update(state, { $merge: { isEditing: false } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (userProfile) => ({
  type: COMPLETE_LOAD,
  userProfile,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startLoadHypothesisPercentage = () => ({
  type: START_LOAD_HYPOTHESES_PERCENTAGE,
});

const completeLoadHypothesisPercentage = (hypothesesPercentage) => ({
  type: COMPLETE_LOAD_HYPOTHESES_PERCENTAGE,
  hypothesesPercentage,
});

const failLoadHypothesisPercentage = () => ({
  type: FAIL_LOAD_HYPOTHESES_PERCENTAGE,
});

const startLoadExperimentsPercentage = () => ({
  type: START_LOAD_EXPERIMENTS_PERCENTAGE,
});

const completeLoadExperimentsPercentage = (experimentsPercentage) => ({
  type: COMPLETE_LOAD_EXPERIMENTS_PERCENTAGE,
  experimentsPercentage,
});

const failLoadExperimentsPercentage = () => ({
  type: FAIL_LOAD_EXPERIMENTS_PERCENTAGE,
});

const startEdit = () => ({
  type: START_EDIT,
});

const completeEdit = () => ({
  type: COMPLETE_EDIT,
});

const failEdit = () => ({
  type: FAIL_EDIT,
});

const startUploadProfilePicture = () => ({
  type: START_UPLOAD_PROFILE_PICTURE,
});

const completeUploadProfilePicture = (userProfile) => ({
  type: COMPLETE_UPLOAD_PROFILE_PICTURE,
  userProfile,
});

const failUploadProfilePicture = () => ({
  type: FAIL_UPLOAD_PROFILE_PICTURE,
});

const startSubscribeUser = () => ({
  type: START_SUBSCRIBE_USER,
});

const completeSubscribeUser = () => ({
  type: COMPLETE_SUBSCRIBE_USER,
});

const completeLoadSubscriprionProducts = (subscriptionProducts) => ({
  type: COMPLETE_LOAD_ALL_SUBSCRIPTION_PRODUCTS,
  allSubscriptionProducts: subscriptionProducts,
});

const completeLoadPayments = (allUserPayments) => ({
  type: COMPLETE_GET_LOGGED_USER_PAYMENTS,
  loggedUserPayments: allUserPayments,
});

const startLoadPayments = () => ({
  type: START_GET_LOGGED_USER_PAYMENTS,
});

const failLoadPayments = () => ({
  type: FAIL_GET_LOGGED_USER_PAYMENTS,
});

const failLoadSubscriprionProducts = () => ({
  type: FAIL_GET_ALL_SUBSCRIPTION_PRODUCTS,
});

const failSubscribeUser = () => ({
  type: FAIL_SUBSCRIBE_USER,
});

const startUnsubscribeUser = () => ({
  type: START_UNSUBSCRIBE_USER,
});

const completeUnsubscribeUser = () => ({
  type: COMPLETE_UNSUBSCRIBE_USER,
});

const failUnsubscribeUser = () => ({
  type: FAIL_UNSUBSCRIBE_USER,
});

export const loadProfile = () => (dispatch) => {
  dispatch(startLoad());
  const loadProfilePromise = doLoadProfile();
  loadProfilePromise
    .then((res) => dispatch(completeLoad(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoad());
    });
  return loadProfilePromise;
};

export const loadHypothesesPercentage = (teamId) => (dispatch) => {
  dispatch(startLoadHypothesisPercentage());
  const loadHypothesesPercentagePromise = doLoadHypothesesPercentage(teamId);
  loadHypothesesPercentagePromise
    .then((res) => dispatch(completeLoadHypothesisPercentage(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoadHypothesisPercentage());
    });
  return loadHypothesesPercentagePromise;
};

export const loadExperimentsPercentage = (teamId) => (dispatch) => {
  dispatch(startLoadExperimentsPercentage());
  const loadExperimentsPercentagePromise = doLoadExperimentsPercentage(teamId);
  loadExperimentsPercentagePromise
    .then((res) => dispatch(completeLoadExperimentsPercentage(res.data)))
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failLoadExperimentsPercentage());
    });
  return loadExperimentsPercentagePromise;
};

export const editProfile = (
  values,
  photo,
  successMessage = 'success.profileEditedSuccessfully',
) => (dispatch) => {
  dispatch(startEdit());
  if (photo) {
    doUploadProfilePicture(photo[0]);
  }
  const editProfilePromise = doEditProfile(values);
  editProfilePromise
    .then(() => {
      dispatch(completeEdit());
      dispatch(notifySuccess(<FormattedMessage id={successMessage} />));
      dispatch(loadProfile());
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failEdit());
    });
  return editProfilePromise;
};

export const updateUserRole = (role) => (dispatch) => {
  dispatch(startEdit());
  const editRolePromise = setUserRole(role);
  editRolePromise
    .then(() => {
      dispatch(completeEdit());
      dispatch(loadProfile());
    })
    .catch(() => {
      dispatch(failEdit());
    });
  return editRolePromise;
};

export const uploadProfilePicture = (image) => (dispatch) => {
  dispatch(startUploadProfilePicture());
  const editProfilePromise = doUploadProfilePicture(image);
  editProfilePromise
    .then((res) => {
      dispatch(completeUploadProfilePicture(res.data));
      dispatch(notifySuccess('Profilul a fost editat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failUploadProfilePicture());
    });
  return editProfilePromise;
};

export const subscribeUserToMailchimp = (email) => (dispatch) => {
  dispatch(startSubscribeUser());
  const subscribeUserPromise = doSubscribeUserToMailchimp(email);
  subscribeUserPromise
    .then(() => {
      dispatch(completeSubscribeUser());
      dispatch(notifySuccess('Profilul a fost editat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failSubscribeUser());
    });
  return subscribeUserPromise;
};

export const getAllSubscriptionProducts = () => (dispatch) => {
  const loadSubscriptionsPromise = doGetAlSubscriptionProducts();
  loadSubscriptionsPromise
    .then((res) => {
      const formattedData = getAllProductsSerializer(res.data);
      dispatch(completeLoadSubscriprionProducts(formattedData));
    })
    .catch((error) => {
      dispatch(notifyError(error));
      dispatch(failLoadSubscriprionProducts());
    });
};

export const getAllLoggedUserPayments = () => (dispatch) => {
  const loadPaymentsPromise = doGetPaymentsForLoggedUser();

  dispatch(startLoadPayments());

  loadPaymentsPromise
    .then((res) => {
      const formattedData = getAllPaymentsSerializer(res.data);
      dispatch(completeLoadPayments(formattedData));
    })
    .catch((error) => {
      dispatch(notifyError(error));
      dispatch(failLoadPayments());
    });
};

export const startCheckoutSession = (productId) => (dispatch) => {
  const startCheckoutSessionPromise = doStartCheckoutSession(productId);
  startCheckoutSessionPromise
    .then((res) => {
      window.location.href = res.data.redirectUrL;
    })
    .catch((error) => {
      dispatch(notifyError(error));
      dispatch(failLoadPayments());
    });
};

export const updateUserSubscription = (planId) => (dispatch) => {
  const updateUserSubscriptionPromise = doUpdateSubscription(planId);

  updateUserSubscriptionPromise
    .then(() => {
      const loadProfilePromise = doLoadProfile();
      loadProfilePromise
        .then((res) => {
          dispatch(completeLoad(res.data));
          window.location.reload();
        })
        .catch((error) => {
          dispatch(notifyError(error.response.data));
          dispatch(failLoad());
        });
    })
    .catch((error) => {
      dispatch(notifyError(error));
      dispatch(failLoadPayments());
    });
};

export const getInvoiceForPayment = (paymentId) => (dispatch) => {
  const getInvoicePromise = doGetInvoiceById(paymentId);

  getInvoicePromise
    .then((res) => {
      const invoiceUrl = res.data.invoice_pdf;
      const link = document.createElement('a');
      link.href = invoiceUrl;
      link.download = 'invoice.pdf';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch(notifyError(error));
      dispatch(failLoadPayments());
    });
};

export const unsubscribeUserFromMailchimp = (email) => (dispatch) => {
  dispatch(startUnsubscribeUser());
  const unSubscribeUserPromise = doUnsubscribeUserFromMailchimp(email);
  unSubscribeUserPromise
    .then(() => {
      dispatch(completeUnsubscribeUser());
      dispatch(notifySuccess('Profilul a fost editat cu succes'));
    })
    .catch((error) => {
      dispatch(notifyError(error.response.data));
      dispatch(failUnsubscribeUser());
    });
  return unSubscribeUserPromise;
};
