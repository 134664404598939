import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import GenericEmptyTable from '../../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../../common/components/GenericTableComponent';
import GroupButton from '../../../../common/components/GroupButton';
import { Loading } from '../../../../common/styled';

import { currentTeamIdSelector } from '../../../../common/store';
import { getViabilityAccomplishedData, getViabilityModel } from '../../../../requests/viabilityModels';

import { handleApiError } from '../../../../utils/errorUtils';
import { TERMINALS_TABLE_HEADER } from '../../constants';

import { TableRowsIcon, ViewWeekIcon } from '../../../../assets/icons';

const TableView = {
  estimated: 'estimated',
  accomplished: 'accomplished',
};

const TerminalsModel = () => {
  const intl = useIntl();
  const [tableView, setTableView] = useState(TableView.estimated);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const [isLoading, setIsLoading] = useState(false);
  const [viabilityModel, setViabilityModel] = useState({});
  const [tablesData, setTablesData] = useState({
    [TableView.estimated]: [],
    [TableView.accomplished]: [],
  });

  const buttonsConfig = [
    {
      label: intl.formatMessage({ id: 'label.estimated' }),
      onClick: () => setTableView(TableView.estimated),
      id: TableView.estimated,
      isActive: tableView === TableView.estimated,
      icon: <TableRowsIcon />,
    },
    {
      label: intl.formatMessage({ id: 'label.accomplished' }),
      onClick: () => setTableView(TableView.accomplished),
      id: TableView.accomplished,
      isActive: tableView === TableView.accomplished,
      icon: <ViewWeekIcon />,
    },
  ];

  const TABLE_ROWS = useMemo(() => (
    tablesData?.[tableView]?.map((row) => ({
      month: row?.month,
      possibleClients: intl.formatNumber(row?.potentialClients || 0),
      qualifiedClients: intl.formatNumber(row?.qualifiedClients || 0),
      newPayingClients: intl.formatNumber(row?.payingClients || 0),
      mrrEst: intl.formatNumber(row?.MRR || row?.monthlyRevenue || 0),
      cumulativeRevenue: intl.formatNumber(row?.cumulativeRevenue),
      arrEst: intl.formatNumber(row?.ARR || row?.monthlyRevenue * 12 || 0),
    }))), [tablesData?.[tableView]]);

  useEffect(() => {
    if (teamId && tableView === TableView.accomplished) {
      fetchAccomplishedData();
    }

    if (teamId && tableView === TableView.estimated) {
      fetchEstimatedData();
    }
  }, [teamId, tableView]);

  const fetchAccomplishedData = async () => {
    setIsLoading(true);

    try {
      const response = await getViabilityAccomplishedData(teamId);

      setTablesData({
        ...tablesData,
        [TableView.accomplished]: response?.data || [],
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEstimatedData = async () => {
    setIsLoading(true);

    try {
      const response = await getViabilityModel(teamId);

      setViabilityModel(response?.data?.viabilityModel);
      setTablesData({
        ...tablesData,
        [TableView.estimated]: response?.data?.results || [],
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={20}>
        <Typography variant="h2" color="primary">
          {intl.formatMessage({ id: 'label.tractionProgress' })}
        </Typography>
        {viabilityModel?.completionDate && <GroupButton buttonsConfig={buttonsConfig} />}
      </Box>

      {tablesData?.[tableView]?.length > 0 ? (
        <GenericTableComponent
          rows={TABLE_ROWS}
          headerCells={TERMINALS_TABLE_HEADER}
        />
      ) : (
        <GenericEmptyTable
          headers={TERMINALS_TABLE_HEADER}
          detailMessage={`${intl.formatMessage({ id: 'label.missingViabilityModel' })}...`}
        />
      )}

      <Loading isLoading={isLoading} />
    </>
  );
};

export default TerminalsModel;
