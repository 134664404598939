import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({top }) => `
  position: absolute;
  top: ${top};
  z-index: 2;
`);

export const ValueWrapper = styled(Box)(({ theme }) => `
  border: 1px solid ${theme.palette.general.accentLight};
  border-radius: 10px;
  padding: ${theme.spacing(8, 10)};
  background-color: ${theme.palette.common.white};
  display: flex;
  justify-content: center;
  min-width: 100px;
`);
