import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OutlinedBox = styled(Box)(
  ({ theme, variant }) => `
  border: 1px solid ${
    variant === 'secondary'
      ? theme.palette.general.accentLight
      : theme.palette.primary.main
  };
  color: ${theme.palette.primary.main};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(4)};
  padding: ${theme.spacing(2, 6)};
  
  svg path {
    fill: ${theme.palette.primary.main};
  }
`);

export const CharacteristicParagraph = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.palette.general.borderAccent};
  border-radius: 10px;
  padding: ${theme.spacing(12)};
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
  min-height: 60px;
  
  :hover .actions {
    display: flex;
  }
`);

export const ActionsWrapper = styled(Box)(
  () => `
  display: none;
  align-items: center;
`);
