import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { checkMaxCharacters } from './utils';

export const getDescriptionSchema = (maxSize) => (
  Yup
    .string()
    .test(
      'max-description-length',
      <FormattedMessage id='validate.maxCharacters' values={{ max: maxSize }} />,
      (value) => checkMaxCharacters(value, maxSize),
    )
);
