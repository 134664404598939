import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const viabilityModelSchema = Yup.object({
  businessObjective: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
  objectiveCompletionTime: Yup
    .number()
    .nullable()
    .min(1, <FormattedMessage id='validate.pickNumberBiggerThanOrEqual' values={{ number: 1 }} />)
    .max(10, <FormattedMessage id='validate.pickNumberLessOrEqual' values={{ number: 10 }} />)
    .required(<FormattedMessage id='validate.mandatoryField' />),
  customerConversionRate: Yup
    .number()
    .nullable()
    .min(1, <FormattedMessage id='validate.pickNumberBiggerThanOrEqual' values={{ number: 1 }} />)
    .max(100, <FormattedMessage id='validate.pickNumberLessOrEqual' values={{ number: 100 }} />)
    .required(<FormattedMessage id='validate.mandatoryField' />),
  qualifiedCustomerConversionRate: Yup
    .number()
    .nullable()
    .min(1, <FormattedMessage id='validate.pickNumberBiggerThanOrEqual' values={{ number: 1 }} />)
    .max(100, <FormattedMessage id='validate.pickNumberLessOrEqual' values={{ number: 100 }} />)
    .required(<FormattedMessage id='validate.mandatoryField' />),
  customerDropRate: Yup
    .number()
    .nullable()
    .min(1, <FormattedMessage id='validate.pickNumberBiggerThanOrEqual' values={{ number: 1 }} />)
    .max(100, <FormattedMessage id='validate.pickNumberLessOrEqual' values={{ number: 100 }} />)
    .required(<FormattedMessage id='validate.mandatoryField' />),
  mvpDevelopmentDuration: Yup
    .number()
    .nullable()
    .min(1, <FormattedMessage id='validate.pickNumberBiggerThanOrEqual' values={{ number: 1 }} />)
    .max(36, <FormattedMessage id='validate.pickNumberLessOrEqual' values={{ number: 36 }} />)
    .required(<FormattedMessage id='validate.mandatoryField' />),
  customerSegments: Yup.array()
    .max(3, <FormattedMessage id='validate.customerSegmentsMaxSize' />)
    .required(<FormattedMessage id='validate.mandatoryField' />),
  revenueStreamsHypothesisId: Yup.string().required(<FormattedMessage id='validate.mandatoryField' />),
});
