import { Box, Switch, Typography } from '@mui/material';
import { useField } from 'formik';

const SwitchField = ({
  labelRight,
  labelLeft,
  id,
}) => {
  const [field] = useField(id);

  const handleChange = () => {
    field.onChange({
      target: {
        name: field.name,
        value: !field.value,
      },
    });
  };

  return (
    <Box display="flex" alignItems="center" gap={10}>
      {labelLeft && (
        <Typography
          variant="body2"
          className="italic"
          fontWeight={300} color={field?.value ? 'textSecondary': 'general.borderGray' }
        >
          {labelLeft}
        </Typography>
      )}

      <Switch
        checked={field.value}
        onChange={handleChange}
        name={field.name}
        disableRipple
        color="secondary"
      />

      {labelRight && (
        <Typography
          variant="body2"
          className="italic"
          fontWeight={300}
          color={field?.value ? 'textSecondary': 'general.borderGray' }
        >
          {labelRight}
        </Typography>
      )}
    </Box>
  );
};

export default SwitchField;
