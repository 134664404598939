import { Box, Typography } from '@mui/material';
import React from 'react';

import ArticlePageHeaderSection from './ArticlePageHeaderSection';
import Category from './Category';

const CategoryListEmptyState = () => (
  <Box sx={{ width: '70%', marginTop: '10px' }}>
    <Typography className="faded">
      Adauga o categorie facand click pe butonul din dreapta...
    </Typography>
  </Box>
);

const CategoryList = ({
  filters,
  toggleValue,
  handleToggleValue,
  categories = [],
  handleDeleteCategory,
  handleEditCategory,
  handleCategoriesModalStatus,
}) => (
  <>
    <ArticlePageHeaderSection
      filters={filters}
      toggleValue={toggleValue}
      handleToggleValue={handleToggleValue}
      onAddClick={handleCategoriesModalStatus}
      headerTitle="Categorii"
    />
    {!categories.length ? (
      <CategoryListEmptyState />
    ) : (
      <Box
        className="scrollbar"
        sx={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          overflowY: 'auto',
          maxHeight: '81vh',
          paddingBottom: '20px',
          width: '100%',
        }}
      >
        {categories.map(({ name, _id: categoryId }, index) => (
          <Category
            key={index}
            order={index + 1}
            name={name}
            handleDelete={(e) => handleDeleteCategory(categoryId, e)}
            handleEdit={(e) => handleEditCategory(categoryId, e)}
            isDisabled={name === 'general'}
          />
        ))}
      </Box>
    )}
  </>
);

export default CategoryList;
