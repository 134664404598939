import { CircularProgress } from '@mui/material';
import React from 'react';

import { Root, BackgroundImage, LoadingState } from '../index.styled';

const OnboardingLayout = ({ isLoading, children, imageUrl }) => {
  if (isLoading) {
    return (
      <LoadingState>
        <CircularProgress />
      </LoadingState>
    );
  }

  return (
    <Root>
      <BackgroundImage imageUrl={imageUrl} />
      {children}
    </Root>
  );
};

export default OnboardingLayout;
