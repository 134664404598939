import React from 'react';

import DialogComponent from '../../common/components/DialogComponent';

import ArticlesFilterForm from './ArticlesFilterForm';

const ArticlesFilterModal = ({
  isModalOpen,
  handleSubmit,
  handleClose,
  filters,
  categories,
}) => (
  <DialogComponent
    maxWidth="xs"
    open={isModalOpen}
    handleClose={handleClose}
  >
    <ArticlesFilterForm
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      filters={filters}
      categories={categories}
    />
  </DialogComponent>
);

export default ArticlesFilterModal;
