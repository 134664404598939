import styled from 'styled-components';

export const SubscriptionsTableContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  & table thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  & table thead th {
    border-bottom: none;
    border-top: none;
    background-color: white;
    vertical-align: middle !important;
  }

  & table tbody td {
    border-top: none;
    border-bottom: 1px solid #b1cbfd;
  }

  & table tbody tr {
    border-bottom: none;
    border-top: none;
  }
`;

export const SubscriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border: 1px solid #74a3fe;
  border-radius: 10px;
  margin-left: 10px;
  width: 200px;

  & p {
    white-space: nowrap;
    margin-bottom: 10px;
  }

  & button {
    white-space: nowrap;
  }
`;
