import { Typography, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { getMembersAsOptions, getTeamsAsOptions } from '../../utils';

import AutocompleteField from '../../../../fields/AutocompleteField';

const MoveToFreeAccount = ({ userTeams, userId, onSubmit, isSubmitting }) => {
  const intl = useIntl();

  const ideas = useMemo(() => {
    const multipleOwnersIdeas = [];
    const partnerIdeas = [];
    const uniqueOwnerIdeas = [];

    userTeams.forEach((team) => {
      if (team.createdBy === userId) {
        if (team.members?.length === 1) {
          uniqueOwnerIdeas.push(team);
        } else {
          multipleOwnersIdeas.push(team);
        }
      } else {
        partnerIdeas.push(team);
      }
    });

    return {
      multipleOwnersIdeas,
      partnerIdeas,
      uniqueOwnerIdeas,
    };
  }, [userTeams]);

  const initialValues = {
    idea: '',
    ideas: ideas?.multipleOwnersIdeas?.map((idea) => ({
      id: idea._id,
      name: idea.name,
      newOwner: '',
    })),
    uniqueOwnerIdeas: ideas?.uniqueOwnerIdeas?.map((idea) => ({ id: idea._id }))
  };

  const validationSchema = Yup.object({
    idea: Yup
      .string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <Box>
            <Typography>
              {intl.formatMessage({ id: 'modal.content.deleteAccountDesc7' })}
            </Typography>

            <div>
              <Typography fontWeight={700} my={15}>
                {intl.formatMessage({ id: 'modal.content.deleteAccountDesc8' })}
              </Typography>
              <AutocompleteField
                id="idea"
                label={intl.formatMessage({ id: 'label.ideas' })}
                options={getTeamsAsOptions(userTeams)}
              />
            </div>

            {(ideas?.partnerIdeas?.length > 0 || ideas?.uniqueOwnerIdeas?.length > 0) && (
              <div>
                <Typography fontWeight={700} my={15}>
                  {intl.formatMessage({ id: 'modal.content.deleteAccountDesc9' })}
                </Typography>

                {ideas?.partnerIdeas?.map((idea) => (
                  <Typography color="primary" key={idea._id} gutterBottom>
                    {idea.name}
                  </Typography>
                ))}

                {ideas?.uniqueOwnerIdeas?.map((idea) => (
                  <Typography color="primary" key={idea._id} gutterBottom>
                    {idea.name}
                  </Typography>
                ))}
              </div>
            )}

            {ideas?.multipleOwnersIdeas?.length > 0 && (
              <div>
                <Typography fontWeight={700} my={15}>
                  {intl.formatMessage({ id: 'modal.content.deleteAccountDesc6' })}
                </Typography>
                {ideas?.multipleOwnersIdeas?.map((idea, index) => (
                  <AutocompleteField
                    disabled={idea._id === values.idea}
                    key={idea._id}
                    id={`ideas[${index}].newOwner`}
                    label={idea.name}
                    options={getMembersAsOptions(idea, userId)}
                  />
                ))}
              </div>
            )}
          </Box>
          <ButtonComponent
            type="submit"
            disabled={isSubmitting}
            label={intl.formatMessage({ id: 'button.changePlan' })}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MoveToFreeAccount;
