import React from 'react';
import { Switch } from 'react-router';

import ConfirmAccount from '../modules/auth/containers/ConfirmAccount';
import Login from '../modules/auth/containers/Login';
import Logout from '../modules/auth/containers/Logout';
import Register from '../modules/auth/containers/Register';
import RequestPasswordReset from '../modules/auth/containers/RequestPasswordReset';
import ResetPassword from '../modules/auth/containers/ResetPassword';
import AuthRoute from './AuthRoute';
import GuestRoute from './GuestRoute';

const AccountRouter = () => (
  <Switch>
    <GuestRoute exact path="/account/login" component={Login} />
    <GuestRoute exact path="/account/register" component={Register} />
    <GuestRoute
      exact
      path="/account/forgot-password"
      component={RequestPasswordReset}
    />
    <GuestRoute
      exact
      path="/account/password-reset"
      component={ResetPassword}
    />
    <AuthRoute exact path="/account/logout" component={Logout} />
    <GuestRoute
      exact
      path="/account/confirm-account/:token"
      component={ConfirmAccount}
    />
  </Switch>
);

export default AccountRouter;
