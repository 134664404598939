import { Box, IconButton, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { TrashIconOutlined } from '../../../assets/icons';

import { ProfileImageUploader } from '../index';
import { Root } from './index.styled';

const UploadPicture = ({
  url,
  onSelect,
  onDelete,
  sx = {},
}) => {
  const intl = useIntl();

  return (
    <Root sx={sx} hasPicture={!!url}>
      <Box display="flex" alignItems="center" gap={10}>
        <ProfileImageUploader
          upload
          onFilesSelected={onSelect}
          imageUrl={url}
          width="40px"
          height="40px"
        />

        {!url && (
          <Typography variant="body1" color="general.accentLight2" className="italic">
            {intl.formatMessage({ id: 'label.uploadImage' })}
          </Typography>
        )}
      </Box>

      <IconButton onClick={onDelete} color="error">
        <TrashIconOutlined />
      </IconButton>
    </Root>
  );
};

export default UploadPicture;
