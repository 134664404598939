import React from 'react';
import { useIntl } from 'react-intl';

import CustomButton from '../../../common/components/ButtonComponent';

import { NavigationContainer } from '../index.styled';

const Navigation = ({ onBack, onSubmit, disabled, isPay, hasBackButton }) => {
  const intl = useIntl();

  return (
    <NavigationContainer hasBackButton={hasBackButton}>
      {hasBackButton && (
        <CustomButton
          variant="outlined"
          onClick={onBack}
          label={intl.formatMessage({ id: 'button.back' })}
        />
      )}
      <CustomButton
        disabled={disabled}
        onClick={onSubmit}
        label={intl.formatMessage({
          id: isPay
            ? 'button.payWithCard'
            : 'button.continue'
        })}
      />
    </NavigationContainer>
  );
};

export default Navigation;
