import { Avatar, Box, ClickAwayListener, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../common/components/ButtonComponent';

import { formatUserName } from '../../../utils/formatters';

import { CommentsPopperContainer, MenuContent, UnreadIndicator } from '../index.styled';

const CommentHeader = ({ user = {}, currentUserId, unread }) => {
  const intl = useIntl();
  const { profilePicture } = user;
  const isMe = currentUserId === user._id
    ? `(${intl.formatMessage({ id: 'label.you' })})`
    : '';

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        sx={{ width: '20px', height: '20px', marginRight: '5px' }}
        src={profilePicture}
      />
      <Typography color="primary" fontWeight={600}>
        {`${formatUserName(user)} ${isMe}`}
      </Typography>
      {unread && <UnreadIndicator ml={5} />}
    </Box>
  );
};

const PopperContainer = ({
  hideOptionsMenu,
  checkIfItsMine,
  setOpenedMenu,
  onEdit,
  onDelete,
  onCopyLink,
  item,
  entry = null,
  isReply = false,
}) => {
  const intl = useIntl();
  const belongsToMe = checkIfItsMine(item.userProfileId._id);

  const handleEdit = (e) => {
    if (isReply) {
      e.stopPropagation();
    }
    setOpenedMenu({ id: null, anchorEl: null });
    onEdit(e, item, entry); // will be handleEditComment or handleEditReply based on context
  };

  const handleDelete = (e) => {
    if (isReply) {
      e.stopPropagation();
    }
    setOpenedMenu({ id: null, anchorEl: null });
    onDelete(e, item._id);
  };

  const handleCopy = (e) => {
    if (isReply) {
      e.stopPropagation();
    }
    setOpenedMenu({ id: null, anchorEl: null });
    onCopyLink(item._id);
  };

  return (
    <ClickAwayListener onClickAway={hideOptionsMenu}>
      <CommentsPopperContainer>
        <MenuContent>
          {belongsToMe && (
            <ButtonComponent
              variant="text"
              size="small"
              color="secondary"
              className="link"
              onClick={handleEdit}
              label={intl.formatMessage({ id: 'button.edit' })}
            />
          )}
          <ButtonComponent
            variant="text"
            size="small"
            color="secondary"
            className="link"
            onClick={handleCopy}
            label={intl.formatMessage({ id: 'button.copyLink' })}
          />
          {belongsToMe && (
            <ButtonComponent
              variant="text"
              size="small"
              color="error"
              className="link"
              onClick={handleDelete}
              label={intl.formatMessage({ id: 'button.delete' })}
            />
          )}
        </MenuContent>
      </CommentsPopperContainer>
    </ClickAwayListener>
  );
};

export { CommentHeader, PopperContainer };
