import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';
import { postNewSwot, editSwotById } from '../../../../store/contextAnalysis';

import { currentTeamIdSelector } from '../../../../common/store';

import { SWOT_DROPDOWN_OPTIONS } from '../../../../constants/options';
import { SWOT_TYPES } from '../../constants';

import { SaveIconOutlined } from '../../../../assets/icons';

import AutocompleteField from '../../../../fields/AutocompleteField';
import InputField from '../../../../fields/InputField';

const SwotForm = ({
  selectedSwotElement,
  teamId,
  handleCloseModal,
  postNewSwot,
  swotType,
  editSwotById,
}) => {
  const intl = useIntl();
  const schema = Yup.object().shape({
    swotType: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    description: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      title: values.description,
    };

    if (selectedSwotElement) {
      editSwotById(selectedSwotElement._id, payload, teamId);
    } else {
      postNewSwot(payload, teamId);
    }

    handleCloseModal();
  };

  const initialValues =  {
    swotType: selectedSwotElement?.swotType || swotType || SWOT_TYPES?.strengths?.value,
    description: selectedSwotElement?.description || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <AutocompleteField
            id="swotType"
            label={intl.formatMessage({ id: 'label.type' })}
            options={SWOT_DROPDOWN_OPTIONS}
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
          />

          <InputField
            id="description"
            label={intl.formatMessage({ id: 'label.description' })}
            multiline
            rows={4}
          />

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  postNewSwot,
  editSwotById,
};
const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwotForm);
