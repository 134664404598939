import { styled } from '@mui/material/styles';

export const MyIdeasTitle =  styled('div')(({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(16)};

  ${theme.breakpoints.down('xl')} {
     flex-direction: column;
     align-items: flex-start;
  };
`);

export const IdeaBox = styled('div')(({ theme }) => `
  display: flex;
  align-items: center;
  padding: ${theme.spacing(10)};
  gap: 20px;
  border: 1px solid ${theme.palette.general.borderAccent};
  border-radius: 10px;
  flex: none;
  order: 0;
  margin-bottom: ${theme.spacing(20)};
  flex-grow: 0;
`);

export const IdeaActions = styled('div')(() => `
  margin: auto 10px auto auto;
`);

export const MyIdeasScroll = styled('div')(({ theme }) => `
  margin-top: 30px;
  background-color: ${theme.palette.common.white};
`);
