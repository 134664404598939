import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import uuid from 'react-uuid';
import * as Yup from 'yup';

import ButtonComponent from '../components/ButtonComponent';
import { DialogActionWrapper } from '../components/DialogComponent/index.styled';
import UploadPicture from '../components/UploadPicture';

import { ideaCategoryOptions } from '../../constants/options';
import { DEFAULT_TOOLBAR_CONFIG } from '../../constants/richTextEditor';

import { SaveIconOutlined } from '../../assets/icons';

import DatePickerField from '../../fields/DatePickerField';
import { DropdownField } from '../../fields/DropdownField';
import InputField from '../../fields/InputField';
import RenderRichText from '../../fields/RichTextEditor';
import SwitchField from '../../fields/SwitchField';

const RenderForm = ({
  handleSubmit,
  values,
  setFieldValue,
}) => {
  const intl = useIntl();

  const handleSelect = (files) => {
    setFieldValue('logo', {
      file: files[0],
      url: URL.createObjectURL(files[0])
    });
  };

  const handleDelete = () => {
    setFieldValue('logo', {});
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <UploadPicture
        onDelete={handleDelete}
        onSelect={handleSelect}
        sx={{ mb: 15 }}
        url={values?.logo?.url}
      />

      <InputField id="name" label={intl.formatMessage({ id: 'label.name' })} />

      <Grid container spacing={15}>
        <Grid item xs={6}>
          <InputField sx={{}} id="domain" label={intl.formatMessage({ id: 'label.website' })} />
        </Grid>
        <Grid item xs={6}>
          <InputField sx={{}} id="headquarters" label={intl.formatMessage({ id: 'label.city' })} />
        </Grid>
        <Grid item xs={6}>
          <InputField id="cui" label={intl.formatMessage({ id: 'label.personalIdentificationNumber' })} />
        </Grid>
        <Grid item xs={6}>
          <DatePickerField
            setFieldValue={setFieldValue}
            values={values}
            name="dateOfEstablishment"
            label={intl.formatMessage({ id: 'label.dateOfEstablishment' })}
            placeholder={intl.formatMessage({ id: 'placeholder.dd/mm/yyyy'})}
          />
        </Grid>
      </Grid>

      <DropdownField
        values={values}
        setFieldValue={setFieldValue}
        label={intl.formatMessage({ id: 'label.industry' })}
        placeholder={intl.formatMessage({ id: 'placeholder.addMaxNumber' }, { number: 3 })}
        name="ideaCategory"
        options={ideaCategoryOptions}
        md={12}
        multiple
      />

      <RenderRichText
        name="description"
        label={intl.formatMessage({ id: 'label.description' })}
        height="200px"
        config={DEFAULT_TOOLBAR_CONFIG}
      />

      <SwitchField
        id="isPublic"
        labelRight={intl.formatMessage({ id: 'label.publicIdeaProfileInfo' })}
      />

      <DialogActionWrapper mt={30}>
        <ButtonComponent
          type="submit"
          icon={<SaveIconOutlined size="16px" />}
          color="secondary"
          label={intl.formatMessage({ id: 'button.save' })}
        />
      </DialogActionWrapper>
    </form>
  );
};

const CreateTeamForm = ({
  onSubmit,
  team,
  isEditMode,
  isEditDescriptionMode,
}) => {
  const intl = useIntl();
  const [privateTeam, setPrivateTeam] = useState(false);

  const schema = Yup.object({
    name: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    ideaCategory: Yup.array().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    description: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    teamCode: Yup.string(),
    domain: Yup.string().url(intl.formatMessage({ id: 'validate.invalidUrl' })),
    dateOfEstablishment: Yup.string(),
    headquarters: Yup.string(),
    cui: Yup.string(),
    isPublic: Yup.boolean(),
  });

  const initialValues = {
    name: team && team.name ? team.name : '',
    ideaCategory: team && team.ideaCategory ? team.ideaCategory : [],
    description: team && team.description ? team.description : '',
    teamCode: team && team.teamCode ? team.teamCode : uuid(),
    domain: team && team.domain ? team.domain : '',
    dateOfEstablishment:
      team && team.dateOfEstablishment
        ? new Date(team.dateOfEstablishment)
        : '',
    headquarters: team && team.headquarters ? team.headquarters : '',
    cui: team && team.cui ? team.cui : '',
    isPublic: team && team.isPublic ? team.isPublic : false,
    logo: {
      url: team?.logo || '',
    },
  };

  return (
    <Formik
      validationSchema={
        !isEditDescriptionMode
          ? schema
          : Yup.object({
              description: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
            })
      }
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {(props) => (
        <RenderForm
          {...props}
          setPrivateTeam={() => setPrivateTeam(!privateTeam)}
          privateTeam={privateTeam}
          isEditMode={isEditMode}
        />
      )}
    </Formik>
  );
};

CreateTeamForm.propTypes = {};

export default CreateTeamForm;
