import { Box, Divider } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import HtmlEditor from '../../../common/components/HtmlEditor';
import PageTitle from '../../../common/components/PageTitle';
import { Loading } from '../../../common/styled';
import SectionTitle from '../../components/SectionTitle';

import {
  addChapter,
  editChapter,
  getChapterById,
} from '../../../requests/chapters';

import { handleApiError } from '../../../utils/errorUtils';
import { formatCreatePayload, formatEditPayload, getInitialValues } from '../../utils/chapterUtils';

import InputField from '../../../fields/InputField';
import { Root } from './index.styled';
// import { Root } from './index.styled';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  description: Yup.string(),
  isHidden: Yup.boolean(),
});

const EditChapter = () => {
  const intl = useIntl();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const chapterId = query.get('chapterId');
  const order = +query.get('order');
  const isEdit = query.get('isEdit');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chapter, setChapter] = useState({});

  useEffect(() => {
    if (isEdit && chapterId) {
      fetchData();
    }
  }, [chapterId, isEdit]);

  const handleCreateNewChapter = async (values) => {
    setIsSubmitting(true);

    try {
      await addChapter(formatCreatePayload(values, chapterId, order));
      history.push('/admin/mrl?tab=chapters');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditChapter = async (values) => {
    setIsSubmitting(true);

    try {
      const updateChapterPayload = {
        params: { chapterId },
        payload: formatEditPayload(values),
        order,
      };
      await editChapter(updateChapterPayload);
      history.push('/admin/mrl?tab=chapters');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await getChapterById(chapterId);
      setChapter(response);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Root>
      <PageTitle
        title={intl.formatMessage({ id: 'label.backToContent' })}
        onBack={history.goBack}
      />
      <Divider sx={{ borderColor: 'general.borderAccent' }} />
      <Box display="flex" justifyContent="center" mt={20} width="100%">
        <Formik
          initialValues={getInitialValues(chapter)}
          validationSchema={validationSchema}
          onSubmit={isEdit ? handleEditChapter : handleCreateNewChapter}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate>
              <SectionTitle
                title={intl.formatMessage({ id: 'label.chapter' })}
                isDisabled={!values.title || !values?.description || isSubmitting}
                sx={{ mb: 10 }}
              />
              <InputField id="title" label={intl.formatMessage({ id: 'label.title' })} />
              <HtmlEditor
                content={values?.description}
                onChange={(value) => setFieldValue('description', value)}
                topOffset="285px"
              />
            </form>
          )}
        </Formik>
      </Box>

      <Loading isLoading={isLoading} />
    </Root>
  );
};

export default EditChapter;
