import enUSMessages from './console-strings-en-US.json';
import roROMessages from './console-strings-ro-RO.json';
import { LOCALES } from './langTexts';

const AppLocale = {
  [LOCALES.ROMANIAN]: { messages: roROMessages, locale: LOCALES.ROMANIAN },
  [LOCALES.ENGLISH]: { messages: enUSMessages, locale: LOCALES.ENGLISH },
};

export default AppLocale;
