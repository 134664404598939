import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import DrawerComponent from '../../../../common/components/DrawerComponent';
import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import GenericComments from '../../../commentsAndNotifications/components/GenericComments';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { DRAWER_TABS } from '../../../../constants/drawerTabs';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';

import CharacteristicDetails from '../CharacteristicDetails';
import CharacteristicRow from '../CharacteristicRow';

const CharacteristicDrawer = ({
  characteristics,
  isOpen,
  onClose,
  type,
  onRefresh,
  id,
  userStoriesCount,
}) => {
  const intl = useIntl();
  const { hypothesisId } = useParams();
  const dispatch = useDispatch();

  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);
  const [activeCharacteristic, setActiveCharacteristic] = useState(null);

  const currentUserId = useSelector((state) => state.userProfile.userProfile.userId._id);
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile]) || [];
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));

  // TODO: this needs to be changed when we adjust the comments
  // TODO: take into account that should look at activeCharacteristic as well
  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile,
          entityId: hypothesisId,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  const handleOpenViewDetails = (characteristic) => setActiveCharacteristic(characteristic);

  const handleCloseViewDetails = () => setActiveCharacteristic(null);

  const renderLowerCase = (chunks) => (
    <Typography component="span" textTransform="lowercase" variant="h3" color="primary">
      {chunks}
    </Typography>
  );

  const handleClose = () => {
    if (activeCharacteristic) {
      setActiveCharacteristic(null);
      return;
    }

    onClose();
  };

  return (
    <DrawerComponent isOpen={isOpen} onClose={handleClose}>
      {activeCharacteristic ? (
        <CharacteristicDetails
          onRefresh={onRefresh}
          initialCharacteristic={activeCharacteristic}
          activeDrawerTab={activeDrawerTab}
          onDelete={handleCloseViewDetails}
          onBack={handleCloseViewDetails}
          userStoriesCount={userStoriesCount}
        />
      ) : (
        <>
          <DrawerTitle
            title={intl.formatMessage(
              { id: 'label.visualizeType' },
              { type: intl.formatMessage({ id: type }), c: renderLowerCase }
            )}
            onClose={handleClose}
          />

          <DrawerTabs
            sx={{ mt: 4, mb: 10 }}
            active={activeDrawerTab}
            onClick={setActiveDrawerTab}
          />

          {activeDrawerTab === DRAWER_TABS.details ? (
            <Box display="flex" flexDirection="column" gap={10} className="scrollbar" sx={{ overflowY: 'auto' }} pr={4}>
              {characteristics.map((characteristic, index) => (
                <CharacteristicRow
                  key={characteristic.id}
                  characteristic={characteristic}
                  index={index}
                  onRefresh={onRefresh}
                  id={id}
                  onViewDetails={() => handleOpenViewDetails(characteristic)}
                />
              ))}
            </Box>
          ) : (
            <GenericComments
              fetchComments={fetchComments}
              comments={comments}
              teamId={teamId}
              userId={currentUserId}
              type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB.profile}
              entityId={hypothesisId}
            />
          )}
        </>
      )}
    </DrawerComponent>
  );
};

export default CharacteristicDrawer;
