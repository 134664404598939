import {
  InfoIcon,
  ViewWeekIcon,
  SchoolIcon,
  BookIcon,
  EarthIcon,
  TableRowsIcon,
  ThermomenterOutlineIcon,
  GridBoxesIcon,
  AccountProfileIcon,
  AccountConditionsIcon,
  PaymentIcon,
  SettingsIcon,
  SubscriptionIcon,
  AddIcon,
  GridIcon,
  PeopleIcon,
  GroupOfPeopleIcon,
  ExternalLink,
  SaveIconOutlined,
  UpwardsTrend,
  TrashIconOutlined,
  EyeIcon,
  FilterIcon,
  ChartAnalysisIcon,
  DeleteIcon,
  EditIcon,
  CloseFullscreenIcon,
  CheckIcon,
  FullScreenIcon,
  ExperimentVialIcon,
  ThumbsDownIcon,
  PaperClipIcon,
  ThumbsUpIcon,
  LineAndBarsIcon,
  FormatListBulleted,
  Description,
  AccountCircle,
  Science,
  Paperclip,
  SearchPersonIcon,
  SortIcon,
  SadIcon,
  HappyIcon,
  ExclamationMarkIcon,
  ArrowLeft,
  UnreadNotificationIcon,
  ReplyIcon,
  ThreeDotsIcon,
  CommentIcon,
  DragIndicator,
  EyeIconCrossed,
  AccordionExpandIcon,
  UploadIcon,
  ContainedCloseIcon,
  ContainedCheckmarkIcon,
  ArrowDown,
  FactoryIcon,
  UpcommingIcon,
  ArrowForward,
  SearchIcon,
  ContentPaste,
  Help,
  CloseIcon,
  ComputerIcon,
  EditDocumentIcon,
  DocumentIcon,
  VerifiedIcon,
  ChevronLeft,
  ChevronRight,
  CampaignIcon,
  LogoutIcon,
  EventAvailableIcon,
  AddReactionIcon,
  FirstPageIcon,
  LastPageIcon,
  HighSignalIcon,
  MediumSignalIcon,
  LowSignalIcon,
  DownloadIcon,
  FillStarIcon,
  ReplayIcon,
  DonutLargeIcon,
  ContainedQuestionIcon,
  QuizIcon,
  FlagIcon,
  RemoveIcon,
  ShieldPersonIcon,
  MenuIcon,
} from '../../../assets/icons/index';

const icons = [
  { Icon: InfoIcon, name: 'InfoIcon' },
  { Icon: SchoolIcon, name: 'SchoolIcon' },
  { Icon: BookIcon, name: 'BookIcon' },
  { Icon: EarthIcon, name: 'EarthIcon' },
  { Icon: TableRowsIcon, name: 'TableRowsIcon' },
  { Icon: ThermomenterOutlineIcon, name: 'ThermomenterOutlineIcon' },
  { Icon: GridBoxesIcon, name: 'GridBoxesIcon' },
  { Icon: AccountProfileIcon, name: 'AccountProfileIcon' },
  { Icon: AccountConditionsIcon, name: 'AccountConditionsIcon' },
  { Icon: PaymentIcon, name: 'PaymentIcon' },
  { Icon: SettingsIcon, name: 'SettingsIcon' },
  { Icon: SubscriptionIcon, name: 'SubscriptionIcon' },
  { Icon: AddIcon, name: 'AddIcon' },
  { Icon: GridIcon, name: 'GridIcon' },
  { Icon: PeopleIcon, name: 'PeopleIcon' },
  { Icon: GroupOfPeopleIcon, name: 'GroupOfPeopleIcon' },
  { Icon: ExternalLink, name: 'ExternalLink' },
  { Icon: SaveIconOutlined, name: 'SaveIconOutlined' },
  { Icon: UpwardsTrend, name: 'UpwardsTrend' },
  { Icon: TrashIconOutlined, name: 'TrashIconOutlined' },
  { Icon: EyeIcon, name: 'EyeIcon' },
  { Icon: FilterIcon, name: 'FilterIcon' },
  { Icon: ChartAnalysisIcon, name: 'ChartAnalysisIcon' },
  { Icon: DeleteIcon, name: 'DeleteIcon' },
  { Icon: EditIcon, name: 'EditIcon' },
  { Icon: CloseFullscreenIcon, name: 'CloseFullscreenIcon' },
  { Icon: CheckIcon, name: 'CheckIcon' },
  { Icon: FullScreenIcon, name: 'FullScreenIcon' },
  { Icon: ExperimentVialIcon, name: 'ExperimentVialIcon' },
  { Icon: ThumbsDownIcon, name: 'ThumbsDownIcon' },
  { Icon: PaperClipIcon, name: 'PaperClipIcon' },
  { Icon: ThumbsUpIcon, name: 'ThumbsUpIcon' },
  { Icon: LineAndBarsIcon, name: 'LineAndBarsIcon' },
  { Icon: FormatListBulleted, name: 'FormatListBulleted' },
  { Icon: Description, name: 'Description' },
  { Icon: AccountCircle, name: 'AccountCircle' },
  { Icon: Science, name: 'Science' },
  { Icon: Paperclip, name: 'Paperclip' },
  { Icon: SearchPersonIcon, name: 'SearchPersonIcon' },
  { Icon: SortIcon, name: 'SortIcon' },
  { Icon: SadIcon, name: 'SadIcon' },
  { Icon: HappyIcon, name: 'HappyIcon' },
  { Icon: ExclamationMarkIcon, name: 'ExclamationMarkIcon' },
  { Icon: ArrowLeft, name: 'ArrowLeft' },
  { Icon: UnreadNotificationIcon, name: 'UnreadNotificationIcon' },
  { Icon: ReplyIcon, name: 'ReplyIcon' },
  { Icon: ThreeDotsIcon, name: 'ThreeDotsIcon' },
  { Icon: CommentIcon, name: 'CommentIcon' },
  { Icon: DragIndicator, name: 'DragIndicator' },
  { Icon: EyeIconCrossed, name: 'EyeIconCrossed' },
  { Icon: AccordionExpandIcon, name: 'AccordionExpandIcon' },
  { Icon: UploadIcon, name: 'UploadIcon' },
  { Icon: ArrowDown, name: 'ArrowDown' },
  { Icon: FactoryIcon, name: 'FactoryIcon' },
  { Icon: UpcommingIcon, name: 'UpcommingIcon' },
  { Icon: ArrowForward, name: 'ArrowForward' },
  { Icon: SearchIcon, name: 'SearchIcon' },
  { Icon: ContentPaste, name: 'ContentPaste' },
  { Icon: Help, name: 'Help' },
  { Icon: CloseIcon, name: 'CloseIcon' },
  { Icon: ComputerIcon, name: 'ComputerIcon' },
  { Icon: EditDocumentIcon, name: 'EditDocumentIcon' },
  { Icon: DocumentIcon, name: 'DocumentIcon' },
  { Icon: VerifiedIcon, name: 'VerifiedIcon' },
  { Icon: ChevronLeft, name: 'ChevronLeft' },
  { Icon: ChevronRight, name: 'ChevronRight' },
  { Icon: CampaignIcon, name: 'CampaignIcon' },
  { Icon: LogoutIcon, name: 'LogoutIcon' },
  { Icon: EventAvailableIcon, name: 'EventAvailableIcon' },
  { Icon: AddReactionIcon, name: 'AddReactionIcon' },
  { Icon: FirstPageIcon, name: 'FirstPageIcon' },
  { Icon: LastPageIcon, name: 'LastPageIcon' },
  { Icon: HighSignalIcon, name: 'HighSignalIcon' },
  { Icon: MediumSignalIcon, name: 'MediumSignalIcon' },
  { Icon: MenuIcon, name: 'MenuIcon' },
  { Icon: LowSignalIcon, name: 'LowSignalIcon' },
  { Icon: DownloadIcon, name: 'DownloadIcon' },
  { Icon: FillStarIcon, name: 'FillStarIcon' },
  { Icon: ReplayIcon, name: 'ReplayIcon' },
  { Icon: DonutLargeIcon, name: 'DonutLargeIcon' },
  { Icon: ContainedQuestionIcon, name: 'ContainedQuestionIcon' },
  { Icon: ContainedCloseIcon, name: 'ContainedCloseIcon' },
  { Icon: ContainedCheckmarkIcon, name: 'ContainedCheckmarkIcon' },
  { Icon: QuizIcon, name: 'QuizIcon' },
  { Icon: FlagIcon, name: 'FlagIcon' },
  { Icon: RemoveIcon, name: 'RemoveIcon' },
  { Icon: ViewWeekIcon, name: 'ViewWeekIcon' },
  { Icon: ShieldPersonIcon, name: 'ShieldPersonIcon' },
];

export default icons;
