import { Typography } from '@mui/material';
import React from 'react';

import { FeatureRoot, FeatureSign } from '../index.styled';

const Features = ({ features }) => {
  return (
    <FeatureRoot>
      {features.map((el) => (
        <Typography key={el.id} gutterBottom>
          <FeatureSign color={el.color}>
            {el?.sign}
          </FeatureSign>
          {el?.text}
        </Typography>
      ))}
    </FeatureRoot>
  );
};

export default Features;
