
const MuiSelect = {
  styleOverrides: {
    icon: ({ theme: muiTheme }) => ({
      color: muiTheme.palette.text.secondary,
    }),
  },
};

export default MuiSelect;
