import { http } from '../core/services/http';

import {
  CANCEL_SUBSCRIPTION, DOWNGRADE_ROUTE,
  GET_ALL_SUBSCRIPTIONS,
  START_CHECKOUT_SESSION,
  UPDATE_USER_SUBSCRIPTION,
  USER_NEXT_PAYMENT
} from '../constants/apiRoutes';
import { runGetApiRequest } from '../utils/api/Api';
import { formatTimestampInRomanian } from '../utils/dateHelpers';

export const initCheckoutSession = (planId) => http.post(START_CHECKOUT_SESSION, { planId });

export const updateSubscription = (data) => http.put(UPDATE_USER_SUBSCRIPTION, data);

export const cancelSubscription = (data) => http.put(CANCEL_SUBSCRIPTION, data);

const nextPaymentSerializer = (data) => {
  if (!data) return null;

  const nextPaymentData = {
    customerId: data?.customer,
    subscriptionPrice: data?.subtotal && data?.subtotal / 100,
    startedOn: formatTimestampInRomanian(data?.period_start),
    willEndOn: formatTimestampInRomanian(data?.period_end),
    nextPaymentAttempt: formatTimestampInRomanian(data?.next_payment_attempt),
  };

  return nextPaymentData;
};

export const getLoggedUserNextPayment = () =>
  runGetApiRequest({
    endpoint: USER_NEXT_PAYMENT,
    serializer: nextPaymentSerializer,
  });

export const getAllSubscriptionOptions = () =>
  runGetApiRequest({
    endpoint: GET_ALL_SUBSCRIPTIONS,
  });

export const downgradeSubscription = (payload) => (
  http.post(DOWNGRADE_ROUTE, payload)
);
