import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';

import AddReward from '../../../../common/components/AddReward';
import RewardRow from '../../../../common/components/RewardRow';
import Navigation from '../../components/Navigation';
import StepTitle from '../../components/StepTitle';

import { Content } from '../../index.styled';

const RewardStep = ({
  stepInfo,
  onSelect,
  userProfile,
  onSubmit,
  onBack,
  isDisabled,
  hasBackButton,
}) => {
  const initialValues = {
    rewardPreference: userProfile?.rewardPreference?.length
      ? userProfile.rewardPreference
      : [{ title: '', value: '', unitOfMeasurement: '' }],
  };

  useEffect(() => {
    if (userProfile?.rewardPreference?.length) {
      onSelect({
        rewardPreference: userProfile.rewardPreference,
        questionAsAnswer: true,
      });
    }
  }, [userProfile?._id]);

  const validateForm = (values) => {
    const expWithMissingValues = values.rewardPreference.find((exp) => !exp.title || !exp.value);

    if (!expWithMissingValues) {
      onSelect({
        ...values,
        questionAsAnswer: true,
      });
    }
  };

  return (
    <>
      <Content>
        <Box mt={50} mb={30}>
          <StepTitle title={stepInfo.question} />

          {stepInfo.questionAdditionalInformation && (
            <Typography className="italic">
              {stepInfo.questionAdditionalInformation}
            </Typography>
          )}

          <Box mt={60} width="60%">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validate={validateForm}
            >
              {({ handleSubmit, values, setValues }) => (
                <>
                  <form onSubmit={handleSubmit} noValidate>
                    {values.rewardPreference.map((value, index) => (
                      <RewardRow
                        key={index}
                        position={index}
                        values={values}
                        setValues={setValues}
                      />
                    ))}
                  </form>
                  {values?.rewardPreference?.length < 5 && (
                    <AddReward
                      values={values}
                      setValues={setValues}
                    />
                  )}
                </>
              )}
            </Formik>
          </Box>
        </Box>
      </Content>

      <Navigation
        onBack={onBack}
        onSubmit={onSubmit}
        disabled={isDisabled}
        hasBackButton={hasBackButton}
      />
    </>
  );
};

export default RewardStep;
