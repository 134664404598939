import { Box, Grid } from '@mui/material';
import { isEqual, size } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import EmptyState from '../../../../common/components/EmptyState';
import FilterButton from '../../../../common/components/FilterButton';
import PageTitle from '../../../../common/components/PageTitle';
import { Loading } from '../../../../common/styled';
import AddUserToIdeaModal from '../../../myIdeaDescription/components/AddUserToIdeaModal';
import Mentor from '../../components/MentorCard';

import { currentTeamIdSelector } from '../../../../common/store';
import { useDebounce } from '../../../../hooks/useDebounce';
import { addTeamMentor } from '../../../../requests/teams';
import { loadMentors } from '../../../../store/mentors/mentorsSlice';

import { APP_NAME } from '../../../../constants/general';
import { handleApiError } from '../../../../utils/errorUtils';

import { AddIcon } from '../../../../assets/icons';

import SearchField from '../../../../fields/SearchField';
import Filters from '../Filters';
import MentorDrawer from '../MentorDrawer';
import { Root } from './index.styled';

const Mentors = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.mentors.isLoading);
  const mentors = useSelector(state => state.mentors.mentors);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams), isEqual);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [isClearing, setIsClearing] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [mentor, setMentor] = useState(null);

  const debounceSearch = useDebounce([search], 1000);
  const showLoading = isLoading || isClearing || isInviting;
  const counter = Object.values(filters).reduce((acc, curr) => {
    return acc += acc + (curr?.length || 0);
  }, 0);

  useEffect(() => {
    if (teamId) {
      dispatch(loadMentors(teamId, { search }));

      if (isClearing) {
        setIsClearing(false);
      }
    }
  }, [debounceSearch]);

  const handleChangeSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleClearSearch = () => {
    setIsClearing(true);
    setSearch('');
  };

  const handleSendMentorInvitation = async (mentor) => {
   try {
     setIsInviting(true);
     await addTeamMentor(teamId, {
       email: mentor.email,
       lastName: mentor.lastName,
       firstName: mentor.firstName,
     });
     await dispatch(loadMentors(teamId));
   } catch (e) {
     handleApiError(e);
   } finally {
     setIsInviting(false);
   }
  };

  const handleFilters = (newFilters) => {
    const queryParams = {
      search,
      filters: {
        ...newFilters,
        expertiseYearRanges: newFilters.expertiseYearRanges?.map((exp) => JSON.parse(exp)) || [],
      },
    };
    dispatch(loadMentors(teamId, queryParams));
    setFilters(newFilters);
  };

  const handleCloseDetails = () => {
    setMentor(null);
    setIsDrawerOpen(false);
  };

  const handleOpenDetails = (mentor) => {
    setMentor(mentor);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'label.mentorsList' })}
        counter={size(mentors)}
      />
      <Box display="flex" justifyContent="space-between" mb={16}>
        <Box display="flex" gap={20} width="50%">
          <SearchField
            name="search-mentors"
            id="search-mentors"
            fullWidth
            search={search}
            onClearSearch={handleClearSearch}
            onChange={handleChangeSearch}
            placeholder={intl.formatMessage({ id: 'placeholder.search' })}
          />

          <div>
            <ButtonComponent
              size="small"
              onClick={() => setIsInviteOpen(true)}
              icon={<AddIcon fill="currentColor" width='22px' height='22px' />}
              label={intl.formatMessage({ id: 'button.invite' })}
              color="secondary"
            />
          </div>
        </Box>

        <FilterButton counter={counter}>
          {({ onToggleFilters }) => (
            <Filters
              filters={filters}
              onFilter={handleFilters}
              onToggleFilters={onToggleFilters}
            />
          )}
        </FilterButton>
      </Box>

      <Box className="scrollbar" sx={{ height: 'calc(100% - 106px)', overflowY: 'auto' }}>
        <Grid container spacing={20}>
          {mentors?.map((mentor) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={mentor.userId?._id}>
              <Root>
                <Mentor
                  rewardAtTop
                  mentor={mentor}
                  openDetails={handleOpenDetails}
                  sendInvitation={handleSendMentorInvitation}
                />
              </Root>
            </Grid>
          ))}
        </Grid>
      </Box>

      {!mentors?.length && !isLoading && !isClearing && (
        <EmptyState
          message={search
            ? intl.formatMessage({ id: 'label.noSearchResultsForMentors' }, { appName: APP_NAME })
            : intl.formatMessage({ id: 'label.noMentorsYet' }, { appName: APP_NAME })}
          sx={{ mt: 40 }}
        />
      )}
      <Loading isLoading={showLoading} />

      <AddUserToIdeaModal
        isMentor
        isOpen={isInviteOpen}
        handleClose={() => setIsInviteOpen(false)}
        teamId={teamId}
      />

      {isDrawerOpen && (
        <MentorDrawer
          isOpen={isDrawerOpen}
          onClose={handleCloseDetails}
          mentor={mentor}
          sendInvitation={handleSendMentorInvitation}
        />
      )}
    </>
  );
};

export default Mentors;
