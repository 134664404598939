import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import AddExperience from '../../../../common/components/AddExperience';
import AddReward from '../../../../common/components/AddReward';
import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';
import ExperienceRow from '../../../../common/components/ExperienceRow';
import RewardRow from '../../../../common/components/RewardRow';
import UploadPicture from '../../../../common/components/UploadPicture';

import { APP_NAME } from '../../../../constants/general';
import { USER_ROLES } from '../../../../constants/roles';
import { getProfileSchema } from '../../../../utils/validation/profileSchema';

import { SaveIconOutlined } from '../../../../assets/icons';

import DatePickerField from '../../../../fields/DatePickerField';
import InputField from '../../../../fields/InputField';
import SwitchField from '../../../../fields/SwitchField';

const RenderProfilePicture = ({ values, setFieldValue }) => {
  const [photo, setPhoto] = useState();

  const onFilesSelected = (files) => {
    setFieldValue('photo', files);
    setPhoto(URL.createObjectURL(files[0]));
  };

  const handleDeleteProfileImage = () => {
    setFieldValue('photo', '');
    setFieldValue('profilePicture', '');
    setPhoto(null);
  };

  return (
    <UploadPicture
      url={photo || values.profilePicture}
      onSelect={onFilesSelected}
      onDelete={handleDeleteProfileImage}
      sx={{ mb: 5 }}
    />
  );
};

const ProfileForm = ({ onSubmit, userProfile, role }) => {
  const intl = useIntl();
  const profileClone = JSON.parse(JSON.stringify(userProfile));

  const saveUpdates = (values) => {
    onSubmit(values);
  };

  const initialValues = {
    profilePicture: profileClone.profilePicture
      ? profileClone.profilePicture
      : '',
    title: profileClone.title,
    isPublic: profileClone.isPublic,
    firstName: profileClone.firstName ? profileClone.firstName : '',
    lastName: profileClone.lastName ? profileClone.lastName : '',
    birthDate: profileClone.birthDate ? new Date(profileClone.birthDate) : '',
    address: profileClone.address ? profileClone.address : '',
    phone: profileClone.phone ? profileClone.phone : '',
    linkedIn: profileClone.linkedIn ? profileClone.linkedIn : '',
    about: profileClone.about ? profileClone.about : '',
    ...(role === USER_ROLES.Mentor ? {
      appointmentsCalendar: profileClone.appointmentsCalendar ? profileClone.appointmentsCalendar : '',
      expertise: profileClone?.expertise?.length
        ? profileClone.expertise
        : [{ title: '', years: '', description: '' }],
      rewardPreference: profileClone?.rewardPreference?.length
        ? profileClone.rewardPreference
        : [{ title: '', value: '', unitOfMeasurement: '' }],
    } : {})
  };

  return (
    <Formik
      validationSchema={getProfileSchema(role)}
      onSubmit={saveUpdates}
      initialValues={initialValues}
    >
      {({ values, setFieldValue, setValues, handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={16} sx={{ mb: 16 }}>
            <Grid item xs={12}>
              <RenderProfilePicture
                name="photo"
                values={values}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" variant="h3" fontWeight={600} mb={16}>
                {intl.formatMessage({ id: 'label.generalDetails' })}
              </Typography>
            </Grid>
            <InputField
              id="firstName"
              label={`${intl.formatMessage({id: 'label.name'})}:`}
              required
              placeholder={intl.formatMessage({id: 'placeholder.name'})}
              md={12}
              sx={{ mb: 0 }}
            />
            <InputField
              id="lastName"
              required
              label={`${intl.formatMessage({id: 'label.firstName'})}:`}
              placeholder={intl.formatMessage({id: 'placeholder.firstName'})}
              md={12}
              sx={{ mb: 0 }}
            />
            <InputField
              id="title"
              required
              label={`${intl.formatMessage({id: 'label.title'})}:`}
              placeholder={intl.formatMessage({id: 'label.title'})}
              md={12}
              sx={{ mb: 0 }}
            />
            <InputField
              multiline
              rows={4}
              id="about"
              label={`${intl.formatMessage({id: 'label.saySomeWordsAboutYou'})}:`}
              required
              placeholder={intl.formatMessage({
                id: 'placeholder.saySomeWordsAboutYou',
              })}
              sx={{ mb: 0 }}
            />
            <Grid item xs={12} md={6}>
              <DatePickerField
                name="birthDate"
                label={`${intl.formatMessage({id: 'label.dbo'})}:`}
                placeholder="zz/ll/aaaa"
                values={values}
                setFieldValue={setFieldValue}
                sx={{ mb: 0 }}
              />
            </Grid>
            <InputField
              id="address"
              md={6}
              label={`${intl.formatMessage({id: 'label.location'})}:`}
              placeholder={intl.formatMessage({id: 'label.location'})}
              sx={{ mb: 0 }}
            />
            <InputField
              id="phone"
              label={`${intl.formatMessage({id: 'label.phone'})}:`}
              md={12}
              sx={{ mb: 0 }}
            />
            {role === USER_ROLES.Mentor && (
              <InputField
                id="appointmentsCalendar"
                label={`${intl.formatMessage({id: 'label.appointmentsCalendar'})}:`}
                placeholder={intl.formatMessage({id: 'placeholder.appointmentsCalendar'})}
                md={12}
                sx={{ mb: 0 }}
              />
            )}
            <InputField
              id="linkedIn"
              label={`${intl.formatMessage({id: 'label.linkedinProfile'})}:`}
              placeholder={intl.formatMessage({id: 'placeholder.linkedinProfile'})}
              md={12}
              sx={{ mb: 0 }}
            />

            {role === USER_ROLES.Mentor && (
              <>
                <Grid item xs={12}>
                  <Typography color="primary" variant="h3" fontWeight={600} mb={16}>
                    {intl.formatMessage({ id: 'label.expertise' })}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {values.expertise.map((value, index) => (
                    <ExperienceRow
                      sx={{ mb: 24 }}
                      key={index}
                      position={index}
                      values={values}
                      setValues={setValues}
                    />
                  ))}
                  {values?.expertise?.length < 5 && (
                    <AddExperience
                      variant="outlined"
                      color="secondary"
                      values={values}
                      setValues={setValues}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography color="primary" variant="h3" fontWeight={600} mb={16}>
                    {intl.formatMessage({ id: 'label.rewardPreference' })}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {values.rewardPreference.map((value, index) => (
                    <RewardRow
                      key={index}
                      position={index}
                      values={values}
                      setValues={setValues}
                    />
                  ))}
                  {values?.rewardPreference?.length < 5 && (
                    <AddReward
                      variant="outlined"
                      color="secondary"
                      values={values}
                      setValues={setValues}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <SwitchField
                    id="isPublic"
                    labelRight={intl.formatMessage(
                      { id: 'label.publicProfileDescription' },
                      { appName: APP_NAME }
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px"/>}
              color="secondary"
              label={intl.formatMessage({id: 'button.save'})}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default ProfileForm;
