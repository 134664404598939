import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import AccordionComponent from '../../../components/AccordionComponent';
import AddButton from '../../../components/AddButton';
import InformationRow from '../../../components/InformationRow';
import { Loading } from '../../../styled';

import { CLIENT_STAGE_LABELS } from '../../../../constants/labels';
import { getDetailIcon } from './utils';

import { Title } from './index.styled';

const DetailSection = ({
  title = 'label.discussionMinutes',
  onAdd,
  isLoading = false,
  emptyLabel,
  details,
  isDisabled,
  onViewDetails,
}) => {
  const intl = useIntl();

  return (
    <AccordionComponent
      title={intl.formatMessage({ id: title })}
      actions={<AddButton onClick={onAdd} disabled={isDisabled} />}
    >
      {details?.length > 0
        ? (
          <Box display="flex" flexDirection="column" gap={8}>
            {details?.map((detail) => {
              const Icon = getDetailIcon(detail?.category);
              const clientStage = detail?.clientStage
                ? intl.formatMessage({ id: CLIENT_STAGE_LABELS[detail?.clientStage] })
                : '';

              return (
                <Box key={detail?._id} display="flex" alignItems="center" justifyContent="space-between" gap={4}>
                  <Box display="flex" alignItems="center" gap={4}>
                    <Icon size="18px" />

                    <Title variant="body1" onClick={() => onViewDetails(detail)} className="text-ellipsis">
                      {detail?.title}
                    </Title>
                  </Box>

                  {clientStage && <InformationRow value={clientStage} isContained color="primary" />}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage({ id: emptyLabel })}
          </Typography>
        )}

      <Loading isLoading={isLoading} />
    </AccordionComponent>
  );
};

export default DetailSection;
