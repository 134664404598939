import {
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { logout } from '../../../../store/auth/login';

import { formatUserInitials } from '../../../../utils/formatters';

import UserAvatar from '../../UserAvatar';
import UserMenu from '../UserMenu';
import { AvatarWrapper } from './index.styled';

const User = () => {
  const history = useHistory();
  const userProfile = useSelector(state => state?.userProfile?.userProfile);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileRedirect = () => {
    history.push('/profile');
    handleClose();
  };

  const handleLogout = () => dispatch(logout());

  return (
    <>
      <AvatarWrapper onClick={handleOpenMenu}>
        <UserAvatar
          userInitials={formatUserInitials(userProfile)}
          src={userProfile?.profilePicture}
          height="38px"
          width="38px"
        />
      </AvatarWrapper>
      <UserMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        onLogout={handleLogout}
        onProfileRedirect={handleProfileRedirect}
      />
    </>
  );
};

export default User;
