import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PageTitle from '../../../../common/components/PageTitle';
import Tabs from '../../../../common/components/Tabs';
import { TabContent } from '../../../../common/components/Tabs/index.styled';
import { SpaceBetweenBox } from '../../../../common/styled/ReusablePositioningComponents';
import CommentsDrawer from '../../../commentsAndNotifications/components/CommentsDrawer';
import Competition from '../../../contextAnalysisCompetition/containers/Competition';
import ConclusionPage from '../../../contextAnalysisConclusion/containers/ConclusionPage';
import Industries from '../../../contextAnalysisIndustry/containers/Industries';
import SwotPage from '../../../contextAnalysisSWOT/containers/SwotPage';
import TrendsPage from '../../../contextAnalysisTrends/containers/TrendsPage';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';
import { CONTEXT_ANALYSIS_TABS } from '../../constants';

import DetailsTable from '../../../../common/containers/DetailsTable';

export const ContextAnalysis = () => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const history = useHistory();

  const tabFromQuery = query.get('tab') || 'trends';
  const [activeTab, setActiveTab] = useState(tabFromQuery);

  const location = useLocation();

  useEffect(() => {
    const queryTab =
      new URLSearchParams(location.search).get('tab') || 'trends';
    setActiveTab(queryTab);
  }, [location]);

  const currentUserId = useSelector(
    (state) =>
      state.userProfile.userProfile && state.userProfile.userProfile.userId._id
  );

  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const contextAnalysisComments =
    useSelector(
      (state) =>
        state.comments[CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB[activeTab]]
    ) || [];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/opportunity-analysis?tab=${tab}`);
  };

  const pageComponents = {
    trends: {
      title: intl.formatMessage({ id: 'label.trends' }),
      component: <TrendsPage />,
    },
    industry: {
      title: intl.formatMessage({ id: 'label.industry' }),
      component: <Industries />,
    },
    competition: {
      title: intl.formatMessage({ id: 'label.competition' }),
      component: <Competition />,
    },
    swot: {
      title: intl.formatMessage({ id: 'label.SWOT' }),
      component: <SwotPage />,
    },
    details: {
      title: intl.formatMessage({ id: 'label.details' }),
      component: <DetailsTable section="contextAnalysis" />,
    },
    conclusions: {
      title: intl.formatMessage({ id: 'label.contextAnalysisContext' }),
      component: <ConclusionPage />,
    },
  };

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB[activeTab],
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  useEffect(() => {
    if (!teamId || !currentUserId) return;
    fetchComments();
  }, [teamId, currentUserId, activeTab]);

  return (
    <>
      <SpaceBetweenBox>
        <PageTitle
          title={intl.formatMessage(
            { id: 'label.contextAnalysisType' },
            { type: pageComponents[activeTab]?.title }
          )}
        />
        <CommentsDrawer
          fetchComments={fetchComments}
          type={CONTEXT_ANALYSIS_COMMENTS_TYPES_BY_TAB[activeTab]}
          teamId={teamId}
          userId={currentUserId}
          comments={contextAnalysisComments}
        />
      </SpaceBetweenBox>

      <Tabs
        tabs={CONTEXT_ANALYSIS_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
        id="context-analysis-2"
      />

      <TabContent className="scrollbar">
        {pageComponents[activeTab].component}
      </TabContent>
    </>
  );
};
