import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as LoginImage } from '../../assets/icons/Login-Image.svg';

import { AuthRoot, FormRoot, ImageWrapper } from './auth.styled';

const AuthLayout = ({ children }) => {
  const intl = useIntl();

  return (
    <AuthRoot>
      <FormRoot>
        {children}
      </FormRoot>
      <ImageWrapper>
        <Typography variant="h1" textAlign="center" color="primary" mb={50}>
          {intl.formatMessage({ id: 'label.welcomeToLaunchPad' })}
        </Typography>
        <div className="imageContent">
          <LoginImage />
        </div>
      </ImageWrapper>
    </AuthRoot>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
