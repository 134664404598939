import { Box } from '@mui/material';
import { map, concat, size, get, find, reduce } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Export, SortTableHead } from '../../../common/components';
import PageTitle from '../../../common/components/PageTitle';
import {
  TableRow,
  TableHeadCell,
  TableBodyCell,
  Heading2,
  Heading4Regular,
  LinkTable,
} from '../../../common/styled';

import { BMC_OPTIONS } from '../../../constants/options';

const KPIPage = ({ hypotheses }) => {
  const intl = useIntl();
  const { push } = useHistory();
  const kpis = useMemo(
    () =>
      reduce(
        hypotheses,
        (kpiOptions, hypothesis) => {
          const bmcLabel = get(find(BMC_OPTIONS, { value: hypothesis.columnBMC }), 'label');

          const hypothesisKpis = map(hypothesis.KPIIndicators, (kpi) => ({
            title: kpi.title,
            hypothesisTitle: hypothesis.title,
            hypothesisId: hypothesis._id,
            value: kpi.targetValue,
            columnBMC: bmcLabel
              ? intl.formatMessage({ id: bmcLabel })
              : '-'
          }));

          const carry = concat(kpiOptions, hypothesisKpis);

          return carry;
        },
        []
      ),
    [hypotheses]
  );

  const [sortedKpis, setSortedKpis] = useState(kpis);

  const getDownloadData = useMemo(
    () =>
      map(sortedKpis, (kpi, index) => ({
        '#': index + 1,
        [intl.formatMessage({ id: 'label.indicatorName' })]: kpi.title,
        [intl.formatMessage({ id: 'label.targetValue' })]: kpi.targetValue,
        [intl.formatMessage({ id: 'label.hypothesis' })]: kpi.hypothesisTitle,
        [intl.formatMessage({ id: 'label.BMCSection' })]: kpi.columnBMC,
      })),
    [sortedKpis]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <PageTitle
          title={intl.formatMessage({ id: 'label.indicators' })}
          counter={size(kpis)}
        />
        <Export
          withMargin={false}
          withMarginBottom={false}
          data={getDownloadData}
          filename="kpi.csv"
        />
      </Box>

      <SortTableHead
        array={sortedKpis}
        setArray={setSortedKpis}
        cellSortKeys={[null, ['title']]}
      >
        <TableHeadCell alignLeft cellWidth="5%">
          <Heading2>#</Heading2>
        </TableHeadCell>
        <TableHeadCell alignLeft cellWidth="40%">
          <Heading2>{intl.formatMessage({ id: 'label.indicatorName' })}</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="15%">
          <Heading2>{intl.formatMessage({ id: 'label.targetValue' })}</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="20%">
          <Heading2>{intl.formatMessage({ id: 'label.hypothesis' })}</Heading2>
        </TableHeadCell>
        <TableHeadCell cellWidth="20%">
          <Heading2 center>{intl.formatMessage({ id: 'label.BMCSection' })}</Heading2>
        </TableHeadCell>
      </SortTableHead>
      {map(sortedKpis, (kpi, index) => (
        <TableRow key={index}>
          <TableBodyCell alignLeft cellWidth="5%">
            <Heading4Regular>{index + 1}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell alignLeft cellWidth="40%">
            <LinkTable onClick={() => push(`/hypothesis/${kpi.hypothesisId}`)}>
              {kpi.title}
            </LinkTable>
          </TableBodyCell>
          <TableBodyCell cellWidth="15%">
            <Heading4Regular>{kpi.value}</Heading4Regular>
          </TableBodyCell>
          <TableBodyCell cellWidth="20%">
            <LinkTable onClick={() => push(`/hypothesis/${kpi.hypothesisId}`)}>
              {kpi.hypothesisTitle}
            </LinkTable>
          </TableBodyCell>
          <TableBodyCell cellWidth="20%">
            <Heading4Regular>{kpi.columnBMC}</Heading4Regular>
          </TableBodyCell>
        </TableRow>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.hypotheses.isLoading,
  hypotheses: state.hypotheses.hypotheses,
});

export default withRouter(connect(mapStateToProps, null)(KPIPage));
