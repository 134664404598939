import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const InputLinkIcon = styled(FontAwesomeIcon)`
  z-index: 999;
  float: right;
  margin-bottom: -40px;
  margin-top: 12px;
  margin-right: 7px;
  color: #8190a0;
  cursor: pointer;
`;
