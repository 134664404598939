import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';

import { DEFAULT_TOOLBAR_CONFIG } from '../../../../constants/richTextEditor';
import {
  ACTIVITY_CONCLUSION_SEGMENT_OPTIONS,
  ACTIVITY_CONCLUSION_TRUST_OPTIONS
} from '../../constants';

import { SaveIconOutlined } from '../../../../assets/icons';

import AutocompleteField from '../../../../fields/AutocompleteField';
import RenderRichText from '../../../../fields/RichTextEditor';
import SegmentConclusion from '../SegmentConclusion';

const ConclusionForm = ({ handleSubmit, handleUpdate, conclusion }) => {
  const intl = useIntl();
  const initialValues = {
    summary: conclusion?.title || '',
    observations:conclusion?.description || '',
    trustLevel: conclusion?.dataTrust || '',
    selectedSegment: {
      value: conclusion?.experimentType || '',
      status: conclusion?.status === 'positive',
    },
  };

  const schema = Yup.object({
    summary: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    observations: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    trustLevel: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    selectedSegment: Yup.object({
      value: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
      status: Yup.string().optional(),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={conclusion ? handleUpdate : handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderRichText
            name="summary"
            label={intl.formatMessage({ id: 'label.summaryConclusion' })}
            height="200px"
            config={DEFAULT_TOOLBAR_CONFIG}
          />
          <RenderRichText
            name="observations"
            label={intl.formatMessage({ id: 'label.detailedObservations' })}
            height="200px"
            config={DEFAULT_TOOLBAR_CONFIG}
          />
          <AutocompleteField
            id="trustLevel"
            placeholder={intl.formatMessage({ id: 'placeholder.pickOption' })}
            label={intl.formatMessage({ id: 'label.trustInData' })}
            options={ACTIVITY_CONCLUSION_TRUST_OPTIONS}
          />

          <SegmentConclusion
            title={intl.formatMessage({ id: 'label.whichElementsAreConfirmedByExp' })}
            id="selectedSegment"
            options={ACTIVITY_CONCLUSION_SEGMENT_OPTIONS}
          />
          <DialogActionWrapper mt={15}>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default ConclusionForm;
