import {
  GET_TEAM_MEMBERS,
  GET_TEAM_MENTORS,
  JOIN_TEAM_COHORT,
} from '../constants/apiRoutes';
import { runGetApiRequest, runUpdateApiRequest } from '../utils/api/Api';

export const getMyIdeeaMembers = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_TEAM_MEMBERS,
    params: payload.params,
  });

export const getMyIdeeaMentors = ({ payload }) =>
  runGetApiRequest({
    endpoint: GET_TEAM_MENTORS,
    params: payload.params,
  });

export const joinCohort = ({ payload }) =>
  runUpdateApiRequest({
    endpoint: JOIN_TEAM_COHORT,
    params: payload.params,
    payload: payload.data,
    onFailMessage: 'Alaturarea la cohorta a esuat.',
  });
