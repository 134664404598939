import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../../../common/components/DialogComponent';

import IndustryForm from '../IndustryForm';

export const IndustryModal = (props) => {
  const {
    isModalOpen,
    onCloseModal,
    teamId,
    selectedIndustry,
    setSelectedIndustry,
    industryType,
  } = props;
  const intl = useIntl();

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={onCloseModal}
      title={intl.formatMessage({ id: 'modal.title.addIndustryElement' })}
    >
      <IndustryForm
        selectedIndustry={selectedIndustry}
        handleCloseModal={onCloseModal}
        setSelectedIndustry={setSelectedIndustry}
        industryType={industryType}
        teamId={teamId}
      />
    </DialogComponent>
  );
};
