import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DocumentTitle } from '../../../common/components';
import { Loading } from '../../../common/styled';
import { Resolver } from '../../../core/components';

import { currentTeamIdSelector } from '../../../common/store';
import { loadHypotheses } from '../../../store/hypothesis/hypothesisSlice';

import KPIPage from './KPIPage';

const KPIPageResolver = (props) => {
  const { loadHypotheses, teamId } = props;
  const intl = useIntl();

  const loadDependencies = () =>
    teamId ? Promise.all([loadHypotheses(teamId)]) : Promise.resolve();

  return (
    <>
      <DocumentTitle>{intl.formatMessage({ id: 'label.indicators' })}</DocumentTitle>
      <Resolver
        successComponent={KPIPage}
        loadingComponent={Loading}
        resolve={loadDependencies}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = {
  loadHypotheses,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(KPIPageResolver)
);
