import { Typography, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { APP_NAME } from '../../../../constants/general';
import { DEFAULT_TOOLBAR_CONFIG } from '../../../../constants/richTextEditor';

import CheckboxFieldGroup from '../../../../fields/CheckboxFieldGroup/index.formik';
import RenderRichText from '../../../../fields/RichTextEditor';
import { PaperContent } from './index.styled';
import { DELETE_REASONS, DELETE_REASONS_OPTIONS } from './options';

const DeleteReason = ({ hasActiveSubscription, onSubmit, onFreeAccount, isSubmitting }) => {
  const intl = useIntl();
  const initialValues = {
    reason: [],
    description: '',
    otherReason: '',
  };

  const validationSchema = Yup.object({
    reason: Yup
      .array()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    description: Yup
      .string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField' })),
  });

  return (
    <Box>
      <Typography mb={20}>
        {intl.formatMessage({ id: 'modal.content.deleteAccountDesc1' })}
      </Typography>
      <Typography>
        {intl.formatMessage({ id: 'modal.content.deleteAccountDesc2' }, { appName: APP_NAME })}
      </Typography>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <Box>
              <PaperContent elevation={0} sx={{ my: 20 }}>
                <CheckboxFieldGroup
                  hideBorder
                  id="reason"
                  name="reason"
                  options={DELETE_REASONS_OPTIONS}
                />

                {values?.reason?.includes(DELETE_REASONS.otherReason) && (
                  <RenderRichText
                    id="otherReason"
                    name="otherReason"
                    label={intl.formatMessage({ id: 'label.mentionTheReason' })}
                    height="200px"
                    config={DEFAULT_TOOLBAR_CONFIG}
                  />
                )}
              </PaperContent>

              <RenderRichText
                id="description"
                name="description"
                label={`${intl.formatMessage({ id: 'label.whatCouldWeDoBetter' })} *`}
                height="200px"
                config={DEFAULT_TOOLBAR_CONFIG}
              />
            </Box>

            <Box display="flex" gap={12} mt={30}>
              <ButtonComponent
                type="submit"
                color="error"
                disabled={isSubmitting}
                label={intl.formatMessage({ id: 'button.deleteAccount' })}
              />

              {hasActiveSubscription && (
                <ButtonComponent
                  onClick={onFreeAccount}
                  disabled={isSubmitting}
                  color="secondary"
                  label={intl.formatMessage({ id: 'button.freeAccount' })}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DeleteReason;
