import React from 'react';
import { FormattedMessage } from 'react-intl';

// PLATFORM FUNDAMENTALS
import HypothesisOnboardingRouter from './components/HypothesisOnboardingRouter';
import { TourImageComponent } from './components/TourStarters';

import { USER_ROLES } from '../../constants/roles';
import { bmcCallback, contextAnalysisCallback, mrlCallback, platformFundamentalsCallback } from './utils';

import BMCImage from '../../assets/icons/tour/BMC.svg';
import TelescopeImage from '../../assets/icons/tour/ContextAnalysis.svg';
import MrlImage from '../../assets/icons/tour/MRL.svg';
import RocketLaunch from '../../assets/icons/tour/RocketLaunch.svg';
import RocketMoonSuccess from '../../assets/icons/tour/RocketMoonSuccess.svg';

export const ONBOARDING_PATH_NAMES = {
  contextAnalysis: 'context-analysis',
  platformFundamentals: 'platform-fundamentals',
  mrl: 'mrl',
  bmc: 'bmc',
};

const FUNDAMENTALS_START = {
  placement: 'center',
  target: 'body',
  content: (
    <TourImageComponent
      title="guide.welcomeToLaunchPad.title"
      text={['guide.welcomeToLaunchPad.content']}
      image={RocketLaunch}
    />
  ),
  disableBeacon: true,
};

const FUNDAMENTALS_ADD_IDEA = {
  target: '#add-ideea-button',
  content: <FormattedMessage id="guide.addAnIdea.content" />,
  disableBeacon: true,
  title: <FormattedMessage id="guide.addAnIdea.title" />,
};

const FUNDAMENTALS_JOIN_COHORT = {
  target: '#join-cohort-button',
  content: <FormattedMessage id="guide.joinCohort.content" />,
  disableBeacon: true,
  title: <FormattedMessage id="guide.joinCohort.title" />,
};

const FUNDAMENTALS_NAVBAR_TOOLS = {
  target: '#navbar-tools',
  content: <FormattedMessage id="guide.workingTools.content" />,
  disableBeacon: true,
  title: <FormattedMessage id="guide.workingTools.title" />,
};

const FUNDAMENTALS_MRL = {
  target: '#mrl-container',
  content: <FormattedMessage id="guide.mrlGuide.content.1" />,
  disableBeacon: true,
  title: <FormattedMessage id="guide.mrlGuide.title" />,
  placement: 'left',
};

const FUNDAMENTALS_NOTIFICATION = {
  target: '#notifications-bell',
  content: <FormattedMessage id="guide.notificationsAndComments.content" />,
  disableBeacon: true,
  title: <FormattedMessage id="guide.notificationsAndComments.title" />,
};

const FUNDAMENTALS_FEEDBACK = {
  target: '#feedback-form',
  content: <FormattedMessage id="guide.yourOpinion.content" />,
  disableBeacon: true,
  title: <FormattedMessage id="guide.yourOpinion.title" />,
};

const FUNDAMENTALS_CONGRATS = {
  placement: 'center',
  target: 'body',
  content: (
    <TourImageComponent
      title="guide.congratsFirstStep.title"
      text={[
        'guide.congratsFirstStep.content.1',
        'guide.congratsFirstStep.content.2',
      ]}
      image={RocketMoonSuccess}
    />
  ),
  disableBeacon: true,
};

export const ONBOARDING_FUNDAMENTALS_STEPS = {
  [USER_ROLES.Founder]: [
    FUNDAMENTALS_START,
    FUNDAMENTALS_ADD_IDEA,
    FUNDAMENTALS_NAVBAR_TOOLS,
    FUNDAMENTALS_MRL,
    FUNDAMENTALS_NOTIFICATION,
    FUNDAMENTALS_FEEDBACK,
    FUNDAMENTALS_CONGRATS,
  ],
  [USER_ROLES.Mentor]: [
    FUNDAMENTALS_START,
    FUNDAMENTALS_JOIN_COHORT,
    FUNDAMENTALS_NAVBAR_TOOLS,
    FUNDAMENTALS_MRL,
    FUNDAMENTALS_NOTIFICATION,
    FUNDAMENTALS_FEEDBACK,
    FUNDAMENTALS_CONGRATS,
  ]
};

// Context Analysis
export const CONTEXT_ANALYSIS_ONBOARDING_STEPS = [
  {
    disableBeacon: true,
    target: '#context-analysis-1',
    content: <FormattedMessage id="guide.elementsContextAnalysis.content.1" />,
    title: <FormattedMessage id="guide.elementsContextAnalysis.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-2',
    content:
      <FormattedMessage id="guide.elementsContextAnalysis.content.2" />,
    title: <FormattedMessage id="guide.elementsContextAnalysis.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-trends',
    content: <FormattedMessage id="guide.trends.content" />,
    title: <FormattedMessage id="guide.trends.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-4',
    content: <FormattedMessage id="guide.addATrend.content" />,
    title: <FormattedMessage id="guide.addATrend.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-industry',
    content: <FormattedMessage id="guide.industry.content" />,
    title: <FormattedMessage id="guide.industry.title" />,
  },
  {
    disableBeacon: true,
    target: '#industry-type-rivalry',
    content: <FormattedMessage id="guide.addInfo.content" />,
    title: <FormattedMessage id="guide.addInfo.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-competition',
    content: <FormattedMessage id="guide.competition.content" />,
    title: <FormattedMessage id="guide.competition.title" />,
  },
  {
    disableBeacon: true,
    target: '#cell-00',
    content: <FormattedMessage id="guide.addCompetitor.content" />,
    title: <FormattedMessage id="guide.addCompetitor.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-swot',
    content: <FormattedMessage id="guide.swot.content" />,
    title: <FormattedMessage id="guide.swot.title" />,
  },
  {
    disableBeacon: true,
    target: '#strengths',
    content: <FormattedMessage id="guide.addStrongPoint.content" />,
    title: <FormattedMessage id="guide.addStrongPoint.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-details',
    content: <FormattedMessage id="guide.details.content" />,
    title: <FormattedMessage id="guide.details.title" />,
  },
  {
    disableBeacon: true,
    target: '#add-detail',
    content: <FormattedMessage id="guide.addDetail.content" />,
    title: <FormattedMessage id="guide.addDetail.title" />,
  },
  {
    disableBeacon: true,
    target: '#context-analysis-tab-conclusions',
    content: <FormattedMessage id="guide.conclusion.content" />,
    title: <FormattedMessage id="guide.conclusion.title" />,
  },
  {
    disableBeacon: true,
    target: '#conclusionButton',
    content: <FormattedMessage id="guide.addConclusion.content" />,
    title: <FormattedMessage id="guide.addConclusion.title" />,
  },
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="guide.congratsContextAnalysis.title"
        text={[
          'guide.congratsContextAnalysis.content.1',
          'guide.congratsContextAnalysis.content.2',
        ]}
        image={TelescopeImage}
      />
    ),
    disableBeacon: true,
  },
];

// MRL
export const MRL_ONBOARDING_STEPS = [
  {
    target: '#btn-mrlGuide',
    content: <FormattedMessage id='guide.guideClickAway.content' />,
    disableBeacon: true,
    title: '',
  },
  {
    target: '#mrl-container',
    content:
      <FormattedMessage id='guide.mrlGuide.content.2' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.mrlGuide.title' />,
  },
  {
    target: '#mrl-9',
    content: <FormattedMessage id='guide.keyStage.content' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.keyStage.title' />,
  },
  {
    target: '#mrl-switch',
    content: <FormattedMessage id='guide.hideOrShowAlways.content' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.hideOrShowAlways.title' />,
  },
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="guide.congratsMrlGuide.title"
        text={[
          'guide.congratsMrlGuide.content.1',
          'guide.congratsMrlGuide.content.1',
        ]}
        image={MrlImage}
      />
    ),
    disableBeacon: true,
  },
];

// BUSINESS MODEL CANVAS

export const BMC_ONBOARDING_STEPS = [
  {
    target: '#mrl-nav',
    content: <FormattedMessage id='guide.bmc.content' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.bmc.title' />,
  },
  {
    target: '#team-dropdown',
    content: <FormattedMessage id='guide.myIdeas.content' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.myIdeas.title' />,
  },
  {
    target: '#bmc-time-controls',
    content: <FormattedMessage id='guide.storyOfYourIdea.content' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.storyOfYourIdea.title' />,
  },
  {
    target: '#button-add-customerSegments',
    content: <HypothesisOnboardingRouter />,
    disableBeacon: true,
  },
  {
    target: '#hypothesis-profile',
    content: <FormattedMessage id='guide.idealClientProfile.content' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.idealClientProfile.title' />,
  },
  {
    target: '#middle-container',
    content: <FormattedMessage id='guide.persona.content' />,
    disableBeacon: true,
    title: <FormattedMessage id='guide.persona.title' />,
  },
  {
    placement: 'center',
    target: 'body',
    content: (
      <TourImageComponent
        title="guide.congratsBMC.title"
        text={[
          'guide.congratsBMC.content.1',
          'guide.congratsBMC.content.2',
        ]}
        image={BMCImage}
      />
    ),
    disableBeacon: true,
  },
];

export const joyRideStyleOverride = {
  zIndex: 9999,
  tooltipContent: {
    color: 'black',
    textAlign: 'start',
    padding: '10px 0',
    fontSize: '14px',
  },
  tooltipTitle: {
    color: '#2A5AB7',
    textAlign: 'start',
    fontSize: '16px',
    fontWeight: '600',
    padding: '10px 0',
  },
  buttonNext: {
    background: '#74A3FE',
  },
};

export const ONBOARDING_STEPS_BY_ROLE = {
  [USER_ROLES.Founder]: [
    {
      title: 'label.baseFeatures',
      pathName: ONBOARDING_PATH_NAMES.platformFundamentals,
      steps: ONBOARDING_FUNDAMENTALS_STEPS[USER_ROLES.Founder],
      onCallback: platformFundamentalsCallback,
    },
    {
      title: 'label.contextAnalysis',
      pathName: ONBOARDING_PATH_NAMES.contextAnalysis,
      steps: CONTEXT_ANALYSIS_ONBOARDING_STEPS,
      onCallback: contextAnalysisCallback,
    },
    {
      title: 'label.mrl',
      pathName: ONBOARDING_PATH_NAMES.mrl,
      steps: MRL_ONBOARDING_STEPS,
      onCallback: mrlCallback,
    },
    {
      title: 'label.businessModelCanvas',
      pathName: ONBOARDING_PATH_NAMES.bmc,
      steps: BMC_ONBOARDING_STEPS,
      onCallback: bmcCallback,
    },
  ],
  [USER_ROLES.Mentor]: [
    {
      title: 'label.baseFeatures',
      pathName: ONBOARDING_PATH_NAMES.platformFundamentals,
      steps: ONBOARDING_FUNDAMENTALS_STEPS[USER_ROLES.Mentor],
      onCallback: platformFundamentalsCallback,
    },
    {
      title: 'label.contextAnalysis',
      pathName: ONBOARDING_PATH_NAMES.contextAnalysis,
      steps: CONTEXT_ANALYSIS_ONBOARDING_STEPS,
      onCallback: contextAnalysisCallback,
    },
  ],
};
