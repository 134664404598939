import { Typography } from '@mui/material';

import { ColorColumnDiv, EmptyRoot } from './index.styled';

const EmptyHypothesisCard = ({ text, hasColumn = false }) => (
  <EmptyRoot hasColumn={hasColumn}>
    <Typography variant="body1" color="general.placeholder" className="italic">
      {text}
    </Typography>
    {hasColumn && <ColorColumnDiv />}
  </EmptyRoot>
);

export default EmptyHypothesisCard;
