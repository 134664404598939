import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import React from 'react';

import {
  ExpandButtonContainer,
  MarginContainer,
  MrlSelectedStepContainer,
  StepNumber,
} from '../styled/MrlStyled';

import { NOT_APPLICABLE } from '../../../constants/general';
import { capitalizeFirstLetter } from '../../../utils/genericHelpers';

const MrlStep = ({
  number,
  title,
  isCompleted,
  isOpen,
  subtitle,
  completionStatus,
}) => {
  return (
    <MrlSelectedStepContainer isOpen={isOpen}>
      <MarginContainer
        display="flex"
        width="100%"
        marginLeft={isOpen ? '0.3vw' : '-0.6vw'}
      >
        {isOpen && (
          <MarginContainer
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="16px"
            marginRight="10px"
          >
            <StepNumber isCompleted={isCompleted}>{number + 1}</StepNumber>

            <MarginContainer paddingBottom="3px" maxWidth="160px" marginLeft="10px">
              <>
                <Typography variant="body2" fontWeight={600} noWrap color="primary">
                  {`${capitalizeFirstLetter(
                  title
                )} | ${completionStatus}`}
                </Typography>
                <Typography variant="body2" className="faded" noWrap>
                  {subtitle || NOT_APPLICABLE}
                </Typography>
              </>
            </MarginContainer>
          </MarginContainer>
        )}
      </MarginContainer>
      {isOpen && (
        <ExpandButtonContainer>
          <FontAwesomeIcon icon={faChevronRight} color="#2A5AB7" />
        </ExpandButtonContainer>
      )}
    </MrlSelectedStepContainer>
  );
};

export default MrlStep;
