import MuiCardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) =>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${theme.spacing(50)};
`);

export const CardMedia = styled(MuiCardMedia)(() =>`
  height: 300px;
  width: 600px;
`);
