export const BMC_TYPE = {
  customerSegments: 'customerSegments',
  customerRelationships: 'customerRelationships',
  valuePropositions: 'valuePropositions',
  revenueStreams: 'revenueStreams',
  channels: 'channels',
  costStructure: 'costStructure',
  keyResources: 'keyResources',
  keyActivities: 'keyActivities',
  keyPartners: 'keyPartners',
};

export const BUSINESS_OBJECTIVES = {
  tenThousand: '10k / an',
  oneHundredThousand: '100k / an',
  oneMillion: '1 mil / an',
  tenMillion: '10 mil / an',
  oneHundredMillion: '100 mil / an',
};
