import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GuidePaper = styled(Paper)(({ theme }) => `
  overflow-y: auto;
  height: 100%;
  width: fit-content;
  min-width: 350px;
  padding: ${theme.spacing(20)};
  
  ${theme.breakpoints.down('lg')} {
     min-width: 300px;
  };
`);
