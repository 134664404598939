import { http } from '../core/services/http';

import {
  CREATE_DETAIL,
  DELETE_DETAIL_BY_ID,
  EDIT_DETAIL,
  GET_ALL_DETAILS,
  GET_DETAIL_BY_ID,
  GET_EXPERIMENT_DETAIL_ROUTE,
  GET_HYPOTHESIS_DETAIL_ROUTE,
  GET_PARENT_DETAILS,
} from '../constants/apiRoutes';
import { runDeleteApiRequest, runGetApiRequest } from '../utils/api/Api';
import { formatRoute } from '../utils/formatters';

export const loadExperimentDetails = (teamId) => {
  const endpoint = formatRoute(GET_EXPERIMENT_DETAIL_ROUTE, { teamId });
  return http.get(endpoint);
};

export const loadHypothesisDetails = (teamId)  => {
  const endpoint = formatRoute(GET_HYPOTHESIS_DETAIL_ROUTE, { teamId });
  return http.get(endpoint);
};

export const postDetail = (values, teamId) => {
  const endpoint = formatRoute(CREATE_DETAIL, { teamId });
  return http.post(endpoint, values);
};

export const editDetail = (values, detailId) => {
  const endpoint = formatRoute(EDIT_DETAIL, { detailId });
  return http.put(endpoint, values);
};

export const deleteDetail = (detailId) => {
  const endpoint = formatRoute(DELETE_DETAIL_BY_ID, { detailId });
  return http.delete(endpoint);
};

export const getAllDetails = (teamId, filters) => {
  const endpoint = formatRoute(GET_ALL_DETAILS, { teamId });
  return http.get(`${endpoint}?filters=${encodeURIComponent(JSON.stringify(filters))}`);
};

export const getDetailById = (detailId) => {
  const endpoint = formatRoute(GET_DETAIL_BY_ID, { detailId });
  return http.get(endpoint);
};

export const getParentDetails = (parentDetailId, params = {}) => {
  const endpoint = formatRoute(GET_PARENT_DETAILS, { parentDetailId });
  return http.get(endpoint, { params });
};

export function getAllDetailsDeprecated({ payload = {} }) {
  const defaultQueryParams = {
    filters: {
      ...(payload.filters || {}),
    },
  };

  return runGetApiRequest({
    endpoint: GET_ALL_DETAILS,
    params: payload.params,
    queryParams: { ...defaultQueryParams, ...payload.queryParams },
    onFailMessage: 'Something went wrong while fetching details',
    // serializer: getChaptersSerializer,
  });
}

export function deleteDetailByIdDeprecated({ payload = {} }) {
  return runDeleteApiRequest({
    endpoint: DELETE_DETAIL_BY_ID,
    params: payload.params,
    onFailMessage: 'Something went wrong while deleting detail',
  });
}
