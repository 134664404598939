import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { AddIcon } from '../../../../assets/icons';

const FirstIdeaComponent = ({ setIsModalOpen, canEdit }) => {
  const intl = useIntl();

  return (
    <Box>
      <Typography variant="h2" color="primary" mb={20}>
        {intl.formatMessage({ id: 'label.myIdea' })}
      </Typography>
      <Typography color="primary" mb={20}>
        {intl.formatMessage({ id: 'label.aBusinessIdeaRepresents' })}
      </Typography>
      <Typography color="primary" mb={20}>
        {intl.formatMessage({ id: 'label.addHereYourFirstIdea' })}
      </Typography>
      <ButtonComponent
        color="secondary"
        onClick={setIsModalOpen}
        icon={<AddIcon />}
        disabled={!canEdit}
        label={intl.formatMessage({ id: 'button.addIdea' })}
      />
    </Box>
  );
};

export default FirstIdeaComponent;
