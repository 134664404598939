import { useIntl } from 'react-intl';

import DialogComponent from '../../components/DialogComponent';
import ClientMinuteForm from '../../forms/ClientMinuteForm';

const AddClientMinuteModal = ({
  onClose,
  isOpen,
  onSubmit,
  formValues,
}) => {
  const intl = useIntl();

  return (
    <DialogComponent
      open={isOpen}
      handleClose={onClose}
      maxWidth="xs"
      title={intl.formatMessage({ id: formValues ? 'modal.title.editClientMinute' : 'modal.title.addClientMinute' })}
    >
      <ClientMinuteForm
        formValues={formValues}
        onSubmit={onSubmit}
      />
    </DialogComponent>
  );
};

export default AddClientMinuteModal;
