// TODO: we need user name, need to ask BE team
import { formatUserName } from '../../utils/formatters';

export const getMembersAsOptions = (team, userId) => {
  return team?.members
    .filter((member) => member.userId !== userId)
    .map((member) => ({ label: formatUserName(member), value: member.userId }));
};

export const getTeamsAsOptions = (teams) => {
  return teams
    .map((team) => ({ label: team.name, value: team._id }));
};
