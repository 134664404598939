import { Box, Divider, Typography } from '@mui/material';

import Iframe from '../../../../common/components/Iframe';
import { Loading } from '../../../../common/styled';

const Resource = ({ resource, isLoading }) => (
  <>
    <Typography variant="h1" color="primary" mb={8}>{resource.title}</Typography>

    <Divider sx={{ borderColor: 'general.lightBlue', borderWidth: '1.5px' }} />

    <Box
      mt={20}
      display="flex"
      flexDirection="column"
      gap={10}
    >
      <Iframe title="Preview" content={resource.description} hasDynamicHeight />
    </Box>

    <Loading isLoading={isLoading} />
  </>
);

export default Resource;
