import { Container } from '@mui/material';
// import { captureException } from '@sentry/react';
// import { useEffect } from 'react';
// import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

//  TODO: apply style and maybe add a more user friendly message
const ErrorBoundary = () => {
  // const error = useRouteError();
  //
  // useEffect(() => {
  //   captureException(error);
  // }, []);
  //
  // const formatError = () => {
  //   if (isRouteErrorResponse(error)) {
  //     return `${error.status} ${error.statusText}`;
  //   }
  //
  //   if (error instanceof Error) {
  //     return error.message;
  //   }
  //
  //   return 'Unknown Error';
  // };

  return (
    <Container id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {/*<p>*/}
      {/*  <i>{formatError()}</i>*/}
      {/*</p>*/}
    </Container>
  );
};

export default ErrorBoundary;
