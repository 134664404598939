import {
  httpPostNewComment,
  httpGetAllComments,
  httpEditCommentById,
  httpDeleteCommentById,
} from '../services/comments';

import { commentsSerializer } from './serializers';

// Actions

export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS';
export const UNREAD_COMMENTS = 'UNREAD_COMMENTS';

// Action Creators

const getAllCommentsAction = (payload, commentType = 'commentList') => ({
  type: GET_ALL_COMMENTS,
  payload,
  commentType,
});

const unreadCommentsAction = (flag = false) => ({
  type: UNREAD_COMMENTS,
  payload: flag,
});

const initialState = {
  commentList: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMMENTS:
      return {
        ...state,
        [action.commentType]: action.payload,
      };
    case UNREAD_COMMENTS:
      return {
        ...state,
        unreadComments: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const getAllComments =
  ({ filterParams = {}, userId, teamId }) =>
  (dispatch) => {
    const getCommentsPromise = httpGetAllComments({
      filterParams,
      userId,
      teamId,
    });

    getCommentsPromise.then((response) => {
      const { commentType } = filterParams;
      const serialized = commentsSerializer(response.data, userId);

      if (!commentType) {
        const userHasUnreadComments = !!serialized.find(
          (comment) => comment.unread || comment.unreadReplies
        );
        dispatch(unreadCommentsAction(userHasUnreadComments));
      }
      dispatch(getAllCommentsAction(serialized, commentType));
    });
  };

export const postNewComment =
  ({ message, type, teamId, parentId, userId, entityId }) =>
  (dispatch) => {
    const postCommentsPropise = httpPostNewComment({
      message,
      type,
      teamId,
      parentId,
      entityId,
    });
    postCommentsPropise.then(() => {
      dispatch(
        getAllComments({
          teamId,
          userId,
          filterParams: {
            commentType: type,
            entityId,
          },
        })
      );
    });
  };

export const editCommentById =
  ({ commentId, comment, isRead, teamId, type, userId, entityId }) =>
  (dispatch) => {
    const editCommentPromise = httpEditCommentById({
      commentId,
      comment,
      isRead,
    });
    editCommentPromise.then(() => {
      dispatch(
        getAllComments({
          teamId,
          filterParams: {
            commentType: type,
            ...(entityId && { entityId }),
          },
          userId,
        })
      );
    });
  };

export const deleteCommentById =
  ({ commentId, teamId, type, userId, entityId }) =>
  (dispatch) => {
    const deleteCommentPromise = httpDeleteCommentById({ commentId });
    deleteCommentPromise.then(() => {
      dispatch(
        getAllComments({
          teamId,
          userId,
          filterParams: {
            commentType: type,
            ...(entityId && { entityId }),
          },
        })
      );
    });
  };
