import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { APP_NAME } from '../../../constants/general';

const StepTitle = ({ title }) => {
  const intl = useIntl();
  return (
    <Typography variant="h1" color="primary" gutterBottom>
      {intl.formatMessage({ id: title }, { appName: APP_NAME })}
    </Typography>
  );
};

export default StepTitle;
