import {
  HighSignalIcon,
} from '../../assets/icons';

export const COMMUNITY_TABS = [
  {
    key: 'leaderboard',
    label: 'label.leaderboard',
    icon: <HighSignalIcon />,
  },
];
