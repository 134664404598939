import { Box, IconButton, Typography } from '@mui/material';
import { isNil } from 'lodash-es';

import { Counter } from '../../../assets/theme/styled/Counter';

import { ChevronLeft } from '../../../assets/icons';

const PageTitle = ({ title, counter, onBack, mb = 20 }) => (
  <Box display="flex" alignItems="center" gap={8} mb={mb}>
    {onBack && (
      <IconButton onClick={onBack} color="primary" sx={{ p: 5 }}>
        <ChevronLeft size="22px" />
      </IconButton>
    )}
    <Typography variant="h1" fontWeight={600} color="primary">
      {title}
    </Typography>
    {!isNil(counter) && <Counter>{counter}</Counter>}
  </Box>
);

export default PageTitle;
