import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { currentCohortIdSelector } from '../../../../store/cohorts/cohortsSlice';

import { getNavigationOptions } from '../navigation';
import NavigationOption from '../NavigationOption';
import { Option } from './index.styled';

// TODO: the dropdown for switching between Cohorts needs to be updated
// TODO: useNavigation() is for Cohorts
const Index = ({ onSelectOption }) => {
  const cohortId = currentCohortIdSelector(state => state.cohorts);
  const user = useSelector(state => state.account?.user);

  const options = useMemo(() => (
    getNavigationOptions(user?.role, cohortId)
  ), [user?.role, cohortId]);

  return (
    <div id="navbar-tools">
      {/*{userCohorts?.length > 0 && (*/}
      {/*  <li*/}
      {/*    id="mrl-nav"*/}
      {/*    className="side-nav-item"*/}
      {/*  >*/}
      {/*    <PositionMenu style={{ marginBottom: '10px' }}>*/}
      {/*        <CohortFilterForm*/}
      {/*          userCohorts={onGetCohortOptions()}*/}
      {/*          userCohortValue={onGetCohortValue()}*/}
      {/*          handleChangeCohort={onChangeCohort}*/}
      {/*        />*/}
      {/*    </PositionMenu>*/}
      {/*  </li>*/}
      {/*)}*/}

      {options.map((optionsList, index) => (
        <div key={`option-level-${index}`}>
          {optionsList.map((option) => {
            const IconComponent = option?.icon;

            return (
              <Option
                key={option?.id}
                id={option?.id}
                className={option?.isDisabled ? 'disabledNavItem' : ''}
                onClick={onSelectOption}
              >
                {option?.isExternalRoute ? (
                  <a
                    href={option?.route}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <NavigationOption
                      IconComponent={<IconComponent {...(option?.iconProps || {})} />}
                      title={option?.title}
                    />
                  </a>
                ) : (
                  <NavLink
                    activeClassName="activeNavItem"
                    to={option?.route}
                    onClick={(e) => !!option?.isDisabled && e.preventDefault()}
                  >
                    <NavigationOption
                      isDisabled={!!option?.isDisabled}
                      IconComponent={<IconComponent {...(option?.iconProps || {})} />}
                      title={option?.title}
                    />
                  </NavLink>
                )}
              </Option>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Index;
