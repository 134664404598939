import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import PageTitle from '../../../../common/components/PageTitle';
import Tabs from '../../../../common/components/Tabs';
import { TabContent } from '../../../../common/components/Tabs/index.styled';

import { COMMUNITY_TABS } from '../../constants';

import Leaderboard from '../../../leaderboard/containers/Leaderboard';

const Community = () => {
  const intl = useIntl();
  const query = new URLSearchParams(useLocation().search);
  const tabFromQuery = query.get('tab') || 'leaderboard';
  const [activeTab, setActiveTab] = useState(tabFromQuery);
  const history = useHistory();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.push(`/community?tab=${tab}`);
  };

  const pageComponents = {
    leaderboard: <Leaderboard />,
  };

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'label.community' })} />

      <Tabs
        tabs={COMMUNITY_TABS}
        activeTab={activeTab}
        onChange={handleTabChange}
      />

      <TabContent className="scrollbar">
        {pageComponents[activeTab]}
      </TabContent>
    </>
  );
};

export default Community;
