import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { NOT_APPLICABLE } from '../../../constants/general';
import { TEAM_ROLES_LABELS } from '../../../constants/labels';
import { USER_ROLES } from '../../../constants/roles';

const MemberRole = ({ user, index = 0 }) => {
  const intl = useIntl();

  if (user?.userRole !== USER_ROLES.Founder) {
    return (
      <Typography fontWeight={600} color="general.orange">
        {user?.userRole === USER_ROLES.Mentor
          ? intl.formatMessage({ id: 'label.expert' })
          : `${intl.formatMessage({ id: 'label.cohort' })} #${index + 1}`}
      </Typography>
    );
  }

  return (
    <Box display="flex" gap={10}>
      <Typography fontWeight={600} color="general.purple">
        {intl.formatMessage({ id: user?.equity ? 'label.associate' : 'label.coTeammate' })}
      </Typography>
      <Typography fontWeight={700} color="textSecondary">
        Equity: {user?.equity || 0}%
      </Typography>
      {user?.role?.map((role) => (
        <Typography className="faded" color="textSecondary" key={`${user._id}-${role}`}>
          {TEAM_ROLES_LABELS?.[role]
            ? intl.formatMessage({ id: TEAM_ROLES_LABELS?.[role]})
            : NOT_APPLICABLE
          }
        </Typography>
      ))}
    </Box>
  );
};

export default MemberRole;
