import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import LandedRocket from '../../../assets/onboarding/bulb-puzzle.svg';

import { Root, CardMedia } from './index.styled';

export const ComingSoon = () => {
  const intl = useIntl();

  return (
    <Root>
      <Typography variant="h1" color="primary">
        {intl.formatMessage({ id: 'label.comingSoon' })}<
        /Typography>
      <Typography variant="h3" color="primary">
        {intl.formatMessage({ id: 'label.workingOnImplementingThisFeaturesStayTuned' })}
      </Typography>
      <CardMedia
        component="img"
        src={LandedRocket}
        alt="Coming Soon Background"
      />
    </Root>
  );
};

export default ComingSoon;
