import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme, direction }) => `
  display: flex;
  align-items: center;
  justify-content: ${direction};
 
  & .MuiTypography-root {
    width: 500px;
    text-align: ${direction};
    
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    };
  }
`);
