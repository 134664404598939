import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { CenterContent, PaperContent } from '../../../../layouts/AuthLayout/auth.styled';

import { findConfirmationToken, resendConfirmationToken } from '../../../../requests/auth';

import { handleApiError } from '../../../../utils/errorUtils';

const ConfirmAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tokenJson, setTokenJson] = useState({});
  const intl = useIntl();
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    getConfirmationToken();
  }, []);

  const getConfirmationToken = async () => {
    try {
      setIsLoading(true);
      const { data } = await findConfirmationToken(token);
      setTokenJson(data);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const resendTokenHandler = async() => {
    try {
      setIsLoading(true);
      await resendConfirmationToken(token);
      history.push('/');
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderNotFound = () => (
    <div>
      <Typography mb={20}>
        {intl.formatMessage({ id: 'error.requestForAccountConfirmNoLongerAvailable' })}
      </Typography>
      <ButtonComponent
        size="large"
        fullWidth
        label={intl.formatMessage({ id: 'label.resendEmail' })}
        onClick={resendTokenHandler}
        disabled={isLoading}
      />
    </div>
  );

  const renderAlreadyConfirmed = () => (
    <div>
      <Typography mb={20} textAlign="center">
        {intl.formatMessage({ id: 'error.accountAlreadyActivated' })}
      </Typography>
      <ButtonComponent
        size="large"
        fullWidth
        label={intl.formatMessage({ id: 'label.goToLoginPage' })}
        onClick={() => history.push('/')}
        disabled={isLoading}
      />
    </div>
  );

  const renderSuccess = () => (
    <div>
      <Typography mb={20} textAlign="center">
        {intl.formatMessage({ id: 'success.accountActivatedSuccessfully' })}
      </Typography>
      <ButtonComponent
        size="large"
        fullWidth
        label={intl.formatMessage({ id: 'label.goToLoginPage' })}
        onClick={() => history.push('/')}
        disabled={isLoading}
      />
    </div>
  );

  return (
    <CenterContent>
      <PaperContent elevation={0}>
        {tokenJson.token === 'NOT_FOUND'
          ? renderNotFound()
          : tokenJson.token === 'ALREADY_CONFIRMED'
            ? renderAlreadyConfirmed()
            : renderSuccess()}
      </PaperContent>
    </CenterContent>
  );
};

export default ConfirmAccount;
