
const MuiTypography = {
  styleOverrides: {
    root: ({ theme: muiTheme }) => ({
      '&.faded': {
        color: muiTheme.palette.general.accentLight2,
      },
      '&.italic': {
        fontStyle: 'italic'
      },
    })
  },
};

export default MuiTypography;
