import { AccountCircle, DocumentIcon, DonutLargeIcon, Paperclip, Science } from '../../assets/icons';

export const HYPOTHESES_TABS = [
  {
    key: 'description',
    label: 'label.description',
    icon: <DocumentIcon />,
  },
  {
    key: 'activities',
    label: 'label.activities',
    icon: <Science />,
  },
  {
    key: 'details',
    label: 'label.details',
    icon: <Paperclip />,
  },
];

export const CUSTOMER_SEGMENT_HYPOTHESES_TABS = [
  {
    key: 'description',
    label: 'label.description',
    icon: <DocumentIcon />,
  },
  {
    key: 'segmentDimension',
    label: 'label.segmentDimension',
    icon: <DonutLargeIcon />,
  },
  {
    key: 'profile',
    label: 'label.profile',
    id: 'hypothesis-profile',
    icon: <AccountCircle />,
  },
  {
    key: 'activities',
    label: 'label.activities',
    icon: <Science />,
  },
  {
    key: 'details',
    label: 'label.details',
    icon: <Paperclip />,
  },
];
