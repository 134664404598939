import { Box, Divider } from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import GenericComments from '../../../modules/commentsAndNotifications/components/GenericComments';
import ButtonComponent from '../../components/ButtonComponent';
import DialogAlert from '../../components/DialogAlert';
import DrawerTabs from '../../components/DrawerTabs';
import DrawerTitle from '../../components/DrawerTitle';
import AddClientMinuteModal from '../../modals/AddClientMinuteModal';
import AddUserStoryModal from '../../modals/AddUserStoryModal';
import { Loading } from '../../styled';

import { getAllComments } from '../../../modules/commentsAndNotifications/store';
import { deleteDetail, getDetailById, getParentDetails } from '../../../requests/details';
import { editDetail, postDetail } from '../../../store/details/detailsSlice';
import { currentTeamIdSelector } from '../../store';

import { DETAIL_CATEGORIES } from '../../../constants/detail';
import { DRAWER_TABS } from '../../../constants/drawerTabs';
import { formatDetailForServer } from '../../../utils/detailUtils';
import { handleApiError } from '../../../utils/errorUtils';
import { getCharacteristicsAsOptions } from '../../../utils/hypothesisUtils';
import { getDetailComponent } from './utils';

import { EditIcon, TrashIconOutlined } from '../../../assets/icons';

import DetailSection from './DetailSection';

const ContentDetailDrawer = ({
  initialDetail,
  onCloseDrawer,
  onBack,
  commentType,
  onEdit,
  onSuccessDelete,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const characteristics = useSelector((state) => (
    state?.hypotheses?.hypothesis?.clientProfile?.characteristics || []
  ));
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const contextAnalysisComments = useSelector((state) => state.comments[commentType]) || [];
  const currentUserId = useSelector((state) => (
    state.userProfile.userProfile && state.userProfile.userProfile.userId._id
  ));
  const [detail, setDetail] = useState({});
  const [detailToEdit, setDetailToEdit] = useState(null);
  const [parentDetails, setParentDetails] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: false });
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);
  const [dialogType, setDialogType] = useState('');
  const [activeDetail, setActiveDetail] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const isClient = useMemo(() => activeDetail?.category === DETAIL_CATEGORIES.client, [activeDetail?.category]);

  const handleOpenDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDetail(activeDetail?._id);

      if ([DETAIL_CATEGORIES.minuteClient, DETAIL_CATEGORIES.userStory].includes(activeDetail?.category)) {
        fetchDetail();
        fetchParentDetails(activeDetail?.category);
        return;
      }

      setIsDeleteOpen(false);
      onSuccessDelete();
      onCloseDrawer();
    } catch (e) {
      handleApiError(e);
    }
  };

  useEffect(() => {
    if (initialDetail) {
      fetchDetail();
    }
  }, [initialDetail]);

  useEffect(() => {
    if (isClient && detail?._id) {
      fetchParentDetails(DETAIL_CATEGORIES.userStory);
      fetchParentDetails(DETAIL_CATEGORIES.minuteClient);
    }
  }, [detail?._id]);

  const fetchParentDetails = async (category) => {
    setIsLoading((prevState) => ({
      ...prevState,
      [category]: true,
    }));

    try {
      const params = { filters: { category } };
      const response = await getParentDetails(detail?._id, params);

      setParentDetails((prevState) => ({
        ...prevState,
        [category]: response?.data,
      }));
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        [category]: false,
      }));
    }
  };

  const fetchDetail = async () => {
    setIsLoading({ loading: true });

    try {
      const response = await getDetailById(initialDetail?._id);
      setDetail(response?.data);
      setActiveDetail(response?.data);
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsLoading({ loading: false });
    }
  };

  const handleEdit = () => {
    if ([DETAIL_CATEGORIES.minuteClient, DETAIL_CATEGORIES.userStory].includes(activeDetail?.category)) {
      setDialogType(activeDetail?.category);
      setDetailToEdit(activeDetail);
      return;
    }

    onEdit(activeDetail);
  };

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: { commentType },
        teamId,
        userId: currentUserId,
      })
    );
  };

  const handleOpenDialog = (category) => setDialogType(category);

  const handleCloseDialog = () => {
    setDialogType('');
    setDetailToEdit(null);
  };

  const handleSuccessUpdate = async (category, updatedDetail) => {
    await fetchDetail();
    setActiveDetail(updatedDetail);
    fetchParentDetails(category);
    handleCloseDialog();
  };

  const handleSuccessAdd = async (category) => {
    fetchDetail();
    fetchParentDetails(category);
    handleCloseDialog();
  };

  const handleSubmit = (formValues) => {
    const adjustedValues = formatDetailForServer({ ...formValues, category: dialogType });
    const payload = {
      ...adjustedValues,
      parentDetailId: detail?._id,
      section: detail?.section,
      hasParentDetail: true,
    };

    if (detailToEdit) {
      dispatch(editDetail(
        payload,
        detailToEdit?._id,
        (updatedDetail) => handleSuccessUpdate(dialogType, updatedDetail)
      ));
    } else {
      dispatch(postDetail(payload, teamId, () => handleSuccessAdd(dialogType)));
    }
  };

  const handleBack = activeDetail !== detail && !isEmpty(detail)
    ? () => setActiveDetail(detail)
    : onBack;

  const { DetailComponent, title } = useMemo(() => (
    getDetailComponent(activeDetail?.category)
  ), [activeDetail?.category]);

  return (
    <>
      <DrawerTitle
        title={intl.formatMessage({ id: title })}
        onClose={onCloseDrawer}
        onBack={handleBack}
      />
      <DrawerTabs
        sx={{ mt: 4 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />

      {activeDrawerTab === DRAWER_TABS.details ? (
        <Box mt={20} gap={20} display="flex" flexDirection="column">
          <DetailComponent
            detail={activeDetail}
            parentDetail={detail}
            onLoading={setIsLoading}
          />

          <Divider />

          {isClient && (
            <>
              <DetailSection
                title="label.discussionMinutes"
                details={parentDetails?.minuteClient}
                isLoading={isLoading?.minuteClient}
                emptyLabel="label.addMinuteByPressing"
                onAdd={() => handleOpenDialog(DETAIL_CATEGORIES.minuteClient)}
                onViewDetails={setActiveDetail}
              />

              <Divider />

              <DetailSection
                title="label.userStoryClient"
                details={parentDetails?.userStory?.length
                  ? [{ ...parentDetails?.userStory?.[0], title: activeDetail?.title }]
                  : []
                }
                isLoading={isLoading?.userStory}
                emptyLabel="label.addUserStoryByPressing"
                onAdd={() => handleOpenDialog(DETAIL_CATEGORIES.userStory)}
                isDisabled={parentDetails?.userStory?.length > 0}
                onViewDetails={setActiveDetail}
              />

              <Divider />
            </>
          )}

          <Box display="flex" alignItems="center" gap={10}>
            <ButtonComponent
              onClick={handleEdit}
              variant="outlined"
              color="secondary"
              icon={<EditIcon fill="currentColor" size="14px" />}
              label={intl.formatMessage({ id: 'button.edit' })}
            />
            <ButtonComponent
              onClick={handleOpenDelete}
              icon={<TrashIconOutlined size="16px" />}
              color="error"
              label={intl.formatMessage({ id: 'button.delete' })}
            />
          </Box>

          <Loading isLoading={isLoading?.loading} />
        </Box>
      ) : (
        <GenericComments
          fetchComments={fetchComments}
          type={commentType}
          teamId={teamId}
          userId={currentUserId}
          comments={contextAnalysisComments}
        />
      )}

      {dialogType === DETAIL_CATEGORIES.minuteClient && (
        <AddClientMinuteModal
          isOpen
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          formValues={detailToEdit}
        />
      )}

      {dialogType === DETAIL_CATEGORIES.userStory && (
        <AddUserStoryModal
          isOpen
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          characteristics={getCharacteristicsAsOptions(characteristics)}
          formValues={detailToEdit}
        />
      )}

      {isDeleteOpen && (
        <DialogAlert
          isModalOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteDetail' })}
          hasActions
          onSubmit={handleDelete}
        />
      )}
    </>
  );
};

export default ContentDetailDrawer;
