import { Formik } from 'formik';
import { get } from 'lodash-es';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../components/ButtonComponent';
import { DialogActionWrapper } from '../../components/DialogComponent/index.styled';

import { categoryOptions, extendedCategoryOptions } from '../../../constants/options';
import { getFormikErrors } from '../../../utils/errorUtils';
import { detailClientSchema, detailGeneralSchema } from '../../../utils/validation/detailSchema';
import { getInitialValues } from './utils';

import { SaveIconOutlined } from '../../../assets/icons';

import AutocompleteField from '../../../fields/AutocompleteField';
import CounterField from '../../../fields/CounterField';
import InputField from '../../../fields/InputField';
import DetailClientForm from './DetailClientForm';
import DetailGeneralForm from './DetailGeneralForm';
// import SwitchField from '../../../fields/SwitchField';

const DetailsForm = ({
  handleSubmit,
  formValues,
  handleUpdateDetail,
  hasExtendedCategories = false,
  hypothesisId,
}) => {
  const intl = useIntl();

  const handleChangeCounter = (values, setValues, oldValue, newValue) => {
    const array = get(values, 'links') || [];

    if (oldValue > newValue) {
      array.pop();
    } else {
      array.push({ name: '' });
    }

    setValues({ ...values, links: array, linksNumber: newValue });
  };

  const handleValidation = (values) => {
    const validationSchema = values?.category !== 'client'
      ? detailGeneralSchema
      : detailClientSchema;

    try {
      validationSchema.validateSync(values, { abortEarly: false });
    } catch (e) {
      return getFormikErrors(e);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(formValues, hypothesisId)}
      onSubmit={formValues ? handleUpdateDetail : handleSubmit}
      validate={handleValidation}
    >
      {({ handleSubmit, values, setValues }) => (
        <form onSubmit={handleSubmit} noValidate>
          <AutocompleteField
            id="category"
            placeholder={intl.formatMessage({
              id: 'placeholder.chooseDetailCategory',
            })}
            label={intl.formatMessage({ id: 'label.categoryDetail' })}
            options={hasExtendedCategories ? extendedCategoryOptions : categoryOptions}
            getOptionLabel={(option) =>
              intl.formatMessage({ id: option.label })
            }
            required
          />

          {values?.category !== 'client'
            ? <DetailGeneralForm />
            : <DetailClientForm values={values} onSetValues={setValues} />
          }

          <CounterField
            id="linksNumber"
            label={intl.formatMessage({ id: 'label.numberOfLikes' })}
            minValue={0}
            onChangeValue={(oldValue, newValue) => handleChangeCounter(values, setValues, oldValue, newValue)}
          />
          {values?.links?.map((link, index) => (
            <InputField
              key={`links-${index}`}
              id={`links[${index}].name`}
              label={intl.formatMessage({ id: 'label.link' })}
              placeholder="www.link.com"
            />
          ))}

          {/*<SwitchField*/}
          {/*  id="isPublic"*/}
          {/*  labelRight={intl.formatMessage({ id: 'label.publicDetailOnProfile' })}*/}
          {/*/>*/}

          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default DetailsForm;
