import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../components/ButtonComponent';
import { DialogActionWrapper } from '../../components/DialogComponent/index.styled';

import { TASK_STATUS_OPTIONS } from '../../../constants/options';
import { DEFAULT_TOOLBAR_CONFIG } from '../../../constants/richTextEditor';

import { SaveIconOutlined } from '../../../assets/icons';

import AutocompleteField from '../../../fields/AutocompleteField';
import RadioFieldGroup from '../../../fields/RadioFieldGroup';
import RenderRichText from '../../../fields/RichTextEditor';

const ActivitiesForm = ({
  onSubmit,
  activity = null,
  teamMembers,
}) => {
  const intl = useIntl();

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values.successCriteria) {
      errors.successCriteria = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values.userResponsibleId) {
      errors.userResponsibleId = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!values.state) {
      errors.state = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    return errors;
  };

  const initialValues = activity || {
    title: '',
    successCriteria: '',
    userResponsibleId: '',
    state: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <RenderRichText
            name="title"
            label={intl.formatMessage({ id: 'label.toValidateHypothesis' })}
            height="160px"
            config={DEFAULT_TOOLBAR_CONFIG}
          />
          <RenderRichText
            name="successCriteria"
            label={intl.formatMessage({ id: 'label.toMeasureHypothesis' })}
            height="160px"
            config={DEFAULT_TOOLBAR_CONFIG}
          />
          <AutocompleteField
            id="userResponsibleId"
            placeholder={intl.formatMessage({ id: 'placeholder.pickPersonInCharge' })}
            label={intl.formatMessage({ id: 'label.allocatedTo' })}
            options={teamMembers}
          />
          <RadioFieldGroup
            id="state"
            fullWidth
            options={TASK_STATUS_OPTIONS}
            label={intl.formatMessage({ id: 'label.study' })}
          />

          <DialogActionWrapper>
            <ButtonComponent
              variant="contained"
              color="secondary"
              type="submit"
              icon={<SaveIconOutlined fill="currentColor" size="18px" />}
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};

export default ActivitiesForm;
