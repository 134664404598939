import { Collapse, IconButton, Popper, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../common/components/ButtonComponent';
import RichTextContainer from '../../../common/components/RichTextContainer';

import { notifySuccess } from '../../../core/store';
import { markCommentsAsRead } from '../../../requests/notificationsAndComments';

import {
  ArrowDown,
  CommentIcon,
  ReplyIcon,
  ThreeDotsIcon,
} from '../../../assets/icons';

import { ArrowButton, CommentIconWrapper, RepliesWrapper, UnreadIndicator } from '../index.styled';
import { CommentHeader, PopperContainer } from './OneCommentPieces';

const CommentAndRepliesList = ({
  commentsArray,
  handleEditComment,
  handleDeleteComment,
  handleReply,
  handleDeleteReply,
  handleEditReply,
  queryCommentId,
  fetchComments,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const [anchorEl] = React.useState(null);
  const popperOpen = Boolean(anchorEl);
  const popperId = popperOpen ? 'simple-popper' : undefined;
  const [expandedCommentIds, setExpandedCommentIds] = React.useState([
    queryCommentId,
  ]);
  const currentUserId = useSelector(
    (state) => state.userProfile.userProfile._id
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const hasUnreadComments = commentsArray.some((comment) => comment.unread);
    if (hasUnreadComments) {
      const payload = {
        comments: commentsArray.map((comment) => ({
          commentId: comment._id,
          markRepliesAsRead: false,
        })),
      };

      setTimeout(async () => {
        await markCommentsAsRead({ payload });
        fetchComments();
      }, 2000);
    }
  }, []);

  const [openedMenu, setOpenedMenu] = React.useState({
    type: null, // "comment" or "reply"
    id: null,
    anchorEl: null,
  });

  const handleCopyLink = (commentId) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('comment', commentId);
    url.search = params.toString();
    navigator.clipboard.writeText(url.toString());
    dispatch(notifySuccess(intl.formatMessage({ id: 'success.linkCopiedToClipboard' })));
  };

  const showOptionsMenu = (event, id, type) => {
    event.stopPropagation();

    if (openedMenu.id === id && openedMenu.type === type) {
      setOpenedMenu({ type: null, id: null, anchorEl: null });
    } else {
      setOpenedMenu({ type, id, anchorEl: event.currentTarget });
    }
  };

  const hideOptionsMenu = () => {
    setOpenedMenu({ id: null, anchorEl: null });
  };

  const toggleCommentDropdown = async (comment) => {
    if (expandedCommentIds.includes(comment._id)) {
      const newExpandedCommentIds = expandedCommentIds.filter(
        (id) => id !== comment._id
      );
      setExpandedCommentIds(newExpandedCommentIds);
    } else {
      if (comment?.unreadReplies) {
        const payload = {
          comments: [{ commentId: comment._id, markRepliesAsRead: true }],
        };
        await markCommentsAsRead({ payload });
        fetchComments();
      }

      setExpandedCommentIds([...expandedCommentIds, comment._id]);
    }
  };

  const openReplies = (comment) => {
    if (!expandedCommentIds.includes(comment._id)) {
      setExpandedCommentIds([...expandedCommentIds, comment._id]);
    }

    if (comment?.unreadReplies) {
      const payload = {
        comments: [{ commentId: comment._id, markRepliesAsRead: true }],
      };
      markCommentsAsRead({ payload });
      fetchComments();
    }
  };

  const checkIfItsMine = (userId) => currentUserId === userId;

  return (
    commentsArray.map((entry) => (
      <Box mb={25} key={entry._id}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={10}>
          <CommentHeader
            unread={entry?.unread}
            user={entry.userProfileId}
            currentUserId={currentUserId}
          />
          <Box display="flex" alignItems="center">
            {entry.replies.length > 0 && (
              <ArrowButton
                expanded={expandedCommentIds.includes(entry._id)}
                onClick={() => toggleCommentDropdown(entry)}
              >
                <ArrowDown size="14" />
              </ArrowButton>
            )}

            <IconButton onClick={(e) => showOptionsMenu(e, entry._id, 'comment')}>
              <ThreeDotsIcon size="14" />
            </IconButton>
          </Box>
          <Popper
            id={openedMenu.type === 'comment' ? popperId : undefined}
            open={
              openedMenu.id === entry._id && openedMenu.type === 'comment'
            }
            anchorEl={openedMenu.anchorEl}
            style={{ zIndex: 2000 }}
            placement="bottom-end"
          >
            <PopperContainer
              onCopyLink={handleCopyLink}
              onEdit={handleEditComment}
              onDelete={handleDeleteComment}
              hideOptionsMenu={hideOptionsMenu}
              checkIfItsMine={checkIfItsMine}
              setOpenedMenu={setOpenedMenu}
              item={entry}
            />
          </Popper>
        </Box>

        <RichTextContainer text={entry.title} fontSize="14px" color={theme.palette.primary.main}>
          {`${intl.formatMessage({ id: 'label.loadingText' })}...`}
        </RichTextContainer>

        <Box display="flex" alignItems="center" gap={8}>
          <ButtonComponent
            variant="text"
            size="small"
            color="secondary"
            className="link"
            icon={<ReplyIcon />}
            onClick={(e) => {
              openReplies(entry);
              handleReply(e, entry);
            }}
            label={intl.formatMessage({ id: 'button.answer' })}
          />

          <CommentIconWrapper>
            <CommentIcon />
            <Typography variant="body2">
              {entry.replies.length}
            </Typography>
            {entry?.unreadReplies && <UnreadIndicator />}
          </CommentIconWrapper>
        </Box>
        <Collapse in={expandedCommentIds.includes(entry._id)}>
          <RepliesWrapper>
            {!!entry.replies.length &&
              entry.replies.map((reply) => (
                <Box
                  key={reply._id}
                  sx={{ marginBottom: '5px', marginTop: '10px' }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <CommentHeader
                      user={reply.userProfileId}
                      currentUserId={currentUserId}
                    />

                    <IconButton
                      onClick={(e) => showOptionsMenu(e, reply._id, 'reply')}
                    >
                      <ThreeDotsIcon size="14" />
                    </IconButton>

                    <Popper
                      id={openedMenu.type === 'reply' ? popperId : undefined}
                      open={
                        openedMenu.id === reply._id &&
                        openedMenu.type === 'reply'
                      }
                      anchorEl={openedMenu.anchorEl}
                      style={{ zIndex: 2000 }}
                      placement="bottom-end"
                    >
                      <PopperContainer
                        onCopyLink={handleCopyLink}
                        onEdit={handleEditReply}
                        onDelete={handleDeleteReply}
                        hideOptionsMenu={hideOptionsMenu}
                        checkIfItsMine={checkIfItsMine}
                        setOpenedMenu={setOpenedMenu}
                        item={reply}
                        entry={entry}
                        isReply
                      />
                    </Popper>
                  </Box>

                  <RichTextContainer text={reply.title} fontSize="14px" color={theme.palette.primary.main}>
                    {`${intl.formatMessage({ id: 'label.loading' })}...`}
                  </RichTextContainer>
                </Box>
              ))}
          </RepliesWrapper>
        </Collapse>
      </Box>
    ))
  );
};

export default CommentAndRepliesList;
