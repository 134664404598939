import { Box, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../../common/components/ButtonComponent';
import TourContext from '../../../context/tourContext';
import {
  OnboardingDashboardContainer,
  OnboardingLoaderContainer,
  OnboardingOrderContainer,
  OnboardingProgress,
  OnboardingSegmentContainer,
} from '../styled/onboardingDashboardBricks';

import { useApi } from '../../../hooks/useApi';
import { resetOnboardingProgress } from '../../../requests/onboarding';
import { loadProfile } from '../../../store/userProfile/userProfileSlice';

import { ONBOARDING_STEPS_BY_ROLE } from '../constants';
import { getPercentage } from '../utils';

const OnboardingDashboard = ({ onStartTour }) => {
  const { startTour } = useContext(TourContext);
  const [, runResetProgress] = useApi(resetOnboardingProgress);
  const dispatch = useDispatch();
  const intl = useIntl();
  const userProfile = useSelector(state => state.userProfile.userProfile);
  const onboardingProgress = useSelector((state) => state?.userProfile?.userProfile?.onboardings);
  const steps = ONBOARDING_STEPS_BY_ROLE?.[userProfile?.userId?.role] || [];

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  const handleStartTour = (tourName, steps) => {
    const currStep = onboardingProgress.find(
      (entry) => entry?.code === tourName
    )?.step || 0;

    if (steps?.length > currStep) {
      startTour(tourName, currStep);
    }

    onStartTour();
  };

  const handleResetProgress = async () => {
    await runResetProgress();
    dispatch(loadProfile());
  };

  return (
    <OnboardingDashboardContainer>
      <Typography variant="h3" color="primary" fontWeight={600} mb={10}>
        {intl.formatMessage({ id: 'label.launchPadGuide' })}
      </Typography>

      {steps?.map(({ pathName, title, steps }, index) => (
        <OnboardingSegmentContainer
          key={pathName}
          onClick={() => handleStartTour(pathName, steps)}
        >
          <Box display="flex" alignItems="center">
            <OnboardingOrderContainer>{index + 1}</OnboardingOrderContainer>
            <Typography variant="body1" color="primary" fontWeight={600}>
              {intl.formatMessage({ id: title })}
            </Typography>
          </Box>

          <OnboardingLoaderContainer>
            <OnboardingProgress progress={getPercentage(pathName, steps.length, onboardingProgress)} />
          </OnboardingLoaderContainer>
        </OnboardingSegmentContainer>
      ))}

      <ButtonComponent
        variant="outlined"
        color="secondary"
        onClick={handleResetProgress}
        label={intl.formatMessage({ id: 'button.reset' })}
        fullWidth
      />
    </OnboardingDashboardContainer>
  );
};

export default OnboardingDashboard;
