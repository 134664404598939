import { alpha, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.palette.general.borderAccent};
  border-radius: 4px;
  padding: ${theme.spacing(9, 9, 9, 15)};
  gap: ${theme.spacing(8)};
`);

export const CustomButton = styled(
  Button,
  { shouldForwardProp: (prop) => prop !== 'isActive' }
)(({ theme, isActive }) => `
  border-radius: 3px;
  background: ${isActive 
    ? alpha(theme.palette.general.accentLight, 0.2)
    : 'transparent'
  };
  min-width: initial;
  width: 42px;
  height: 42px;
  border: 1px solid ${theme.palette.general.borderAccent};
  padding: 0;
`);
