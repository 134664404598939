import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { putFinishQuestionnaire } from '../../../../requests/chestionar';

import { handleApiError } from '../../../../utils/errorUtils';

import { Content } from '../../index.styled';

const ConfirmationStep = ({ userProfile }) => {
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => handleFinishQuestionnaire(), 5000);
  }, []);

  const handleFinishQuestionnaire = async () => {
    try {
      await putFinishQuestionnaire();
      history.push('/');
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <Content>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <Typography variant="h1" color="primary" gutterBottom>
          {intl.formatMessage(
            { id: 'label.congratsYourAccountIsReady' },
            { name: userProfile.firstName },
          )}
        </Typography>
      </Box>
    </Content>
  );
};

export default ConfirmationStep;
