import {
  GET_COMMENTS_CSV,
  PUT_MARK_COMMENT_AS_READ,
} from '../constants/apiRoutes';
import { runGetApiRequest, runUpdateApiRequest } from '../utils/api/Api';

export function getCommentsCsv({ payload }) {
  return runGetApiRequest({
    endpoint: GET_COMMENTS_CSV,
    params: payload.params,
  });
}

export function markCommentsAsRead({ payload }) {
  return runUpdateApiRequest({
    endpoint: PUT_MARK_COMMENT_AS_READ,
    payload,
  });
}
