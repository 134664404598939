import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Content = styled(
  Box,
  { shouldForwardProp: (prop) => !['isContained', 'backgroundColor'].includes(prop) }
)(({ theme, isContained, backgroundColor }) => isContained
  ? `
    border-radius: 4px;
    background-color: ${backgroundColor || theme.palette.general.lightBlue};
    padding: ${theme.spacing(4, 10)};
    width: fit-content;
  ` : ''
);
