import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { UpwardsTrend } from '../../../../assets/icons';

export const TrendFilters = ({ onCheck, selectedFilters }) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap={15}>
      <Box display="flex" alignItems="center">
        <Checkbox
          name="isTrendFavorable"
          onChange={onCheck}
          checked={selectedFilters.isTrendFavorable}
          sx={{ mr: 1 }}
          size="medium"
        />
        <UpwardsTrend fill={theme.palette.success.main} />
        <Typography variant="body2" ml={6} color="textSecondary">
          {intl.formatMessage({ id: 'label.favorable' })}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Checkbox
          name="isTrendUnfavorable"
          onChange={onCheck}
          checked={selectedFilters.isTrendUnfavorable}
          sx={{ mr: 1 }}
        />
        <Box sx={{ transform: 'scaleY(-1)' }}>
          <UpwardsTrend fill={theme.palette.error.secondary} />
        </Box>
        <Typography variant="body2" ml={6} color="textSecondary">
          {intl.formatMessage({ id: 'label.unfavorable' })}
        </Typography>
      </Box>
    </Box>
  );
};
