import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import InformationRow from '../../../components/InformationRow';
import LinkComponent from '../../../components/LinkComponent';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { CLIENT_STAGE_LABELS } from '../../../../constants/labels';

const ClientMinuteDetail = ({ detail }) => {
  const intl = useIntl();
  const clientStage = CLIENT_STAGE_LABELS?.[detail?.clientStage]
    ? intl.formatMessage({ id: CLIENT_STAGE_LABELS?.[detail?.clientStage] })
    : '';

  return (
    <>
      <InformationRow
        label={intl.formatMessage({ id: 'label.detailName' })}
        value={detail?.title}
      />

      <InformationRow
        richText
        label={intl.formatMessage({ id: 'label.discussionSummary' })}
        value={detail?.description}
      />

      <Grid container spacing={16}>
        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.clientStage' })}
            value={clientStage}
            isContained
            color="primary"
          />
        </Grid>

        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.contactAvailability' })}
            value={intl.formatMessage({ id: detail?.contactDisponibility ? 'label.yes': 'label.no' })}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
            {intl.formatMessage({ id: 'label.nextSteps' })}
          </Typography>
          {detail?.nextSteps?.length > 0
            ? detail?.nextSteps.map((link, index) => (
              <LinkComponent key={index} domain={link.name} />
            ))
            : NOT_APPLICABLE}
        </Grid>

        <Grid item xs={12}>
          <InformationRow
            label={intl.formatMessage({ id: 'label.estimatedCost' })}
            value={detail?.cost}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" className="italic" color="textSecondary" mb={2}>
            {intl.formatMessage({ id: 'label.links' })}
          </Typography>
          {detail?.links?.length > 0
            ? detail?.links.map((link, index) => (
              <LinkComponent key={index} domain={link.name} />
            ))
            : NOT_APPLICABLE}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientMinuteDetail;
