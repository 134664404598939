import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { BUSINESS_OBJECTIVES_LABELS } from '../../../../constants/labels';

import {
  FunnelBottomArrow,
  FunnelBottomCircle1,
  FunnelBottomCircle2,
  FunnelBottomCircle3,
  FunnelPart1,
  FunnelPart2,
  FunnelPart3,
  FunnelPart4,
} from '../../chart';
import SectionDetails from '../SectionDetails';

const FunnelChart = ({ model, hasData }) => {
  const intl = useIntl();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" position="relative">
      <Box position="absolute" display="flex" justifyContent="center">
        <FunnelPart1 />
        <SectionDetails
          text={intl.formatMessage({ id: 'label.possibleClients' })}
          value={intl.formatNumber(model?.totalPotentialClients || 0)}
          top="170px"
          sx={{ minWidth: '225px' }}
        />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" mt={100}>
        <FunnelPart2 />
        <SectionDetails
          text={intl.formatMessage({ id: 'label.qualifiedClients' })}
          value={intl.formatNumber(model?.totalQualifiedClients || 0)}
          top="295px"
          sx={{ minWidth: '175px' }}
        />
        <FunnelPart3 />
        <SectionDetails
          text={intl.formatMessage({ id: 'label.payingClients' })}
          value={intl.formatNumber(model?.totalPayingClients || 0)}
          top="420px"
          sx={{ minWidth: '125px' }}
        />
        <Box mt={10} mb={20}>
          <FunnelPart4 />
        </Box>
        <FunnelBottomArrow/>

        <Box position="relative" display="flex" justifyContent="center" alignItems="center" mt={20}>
          <Box position="absolute" zIndex={1}>
            <FunnelBottomCircle1 />
          </Box>

          <Box position="absolute">
            <FunnelBottomCircle2 />
          </Box>

          <FunnelBottomCircle3 />

          <SectionDetails
            value={
              hasData
                ? intl.formatMessage({ id: 'label.valueInMonth' },
              {
                value: intl.formatNumber(BUSINESS_OBJECTIVES_LABELS[model?.viabilityModel?.businessObjective]),
                month: model?.viabilityModel?.objectiveCompletionTime * 12
              }): intl.formatMessage({ id: 'label.missingValueInMonth' })
            }
            top="15px"
            sx={{ minWidth: '250px' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FunnelChart;
