import { useTheme } from '@mui/material';
import React from 'react';

import {
  AvatarImageContainer,
  AvatarImagePlaceholder,
} from '../styled';

import { UploadIcon } from '../../assets/icons';

const AvatarImage = ({
  src,
  large = false,
  medium = false,
  // small = false,
  upload,
  width,
  height,
  marginRight = '25px',
  isRounded = false,
}) => {
  const theme = useTheme();

  return (
    <>
      {src ? (
        <AvatarImageContainer
          width={width}
          height={height}
          large={large}
          medium={medium}
          src={src}
          isRounded={isRounded}
        />
      ) : (
        <AvatarImagePlaceholder height={height} width={width} marginRight={marginRight}>
          {upload ? <UploadIcon fill={theme.palette.general.accentLight} /> : 'N/A'}
        </AvatarImagePlaceholder>
      )}
    </>
  );
};

export default AvatarImage;
