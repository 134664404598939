/* eslint-disable no-unused-vars */
import { Formik, useField, useFormikContext } from 'formik';
import { find, get, size } from 'lodash-es';
import React, { useEffect, useRef } from 'react';
import { Col, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Input,
  Heading2,
  SubmitButton,
  ButtonDiv,
  SaveIcon,
  PositionParent,
  LinkIcon,
} from '../../common/styled';
import { Dropdown } from '../../core/components';

import { RESOURCE_SECTION_OPTIONS } from '../constants/ResourceSectionOptions';

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};

const RenderLinkField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <PositionParent>
        <LinkIcon />
        <Input
          formlink
          type={type}
          name={name}
          placeholder={placeholder}
          value={fieldValue}
          onBlur={() => {
            helpers.setTouched();
          }}
          onChange={(e) => {
            helpers.setTouched();
            handleChange(e);
          }}
          isInvalid={meta.touched && !!meta.error}
        />
        <Form.Control.Feedback type="invalid">
          {meta.touched && meta.error}
        </Form.Control.Feedback>
      </PositionParent>
    </Form.Group>
  );
};

const RenderTextField = (props) => {
  const {
    values,
    handleChange,
    name,
    placeholder,
    controlId,
    label,
    type = 'text',
  } = props;

  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  return (
    <Form.Group as={Col} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        type={type}
        name={name}
        placeholder={placeholder}
        value={fieldValue}
        onChange={(e) => {
          helpers.setTouched();
          handleChange(e);
        }}
        onBlur={() => {
          helpers.setTouched();
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderSectionField = (props) => {
  const { values, setFieldValue, controlId, name, label, placeholder } = props;
  const [field, meta, helpers] = useField(props);
  const fieldValue = get(values, name);
  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name });

  return (
    <Form.Group as={Col} controlId={controlId}>
      <Heading2 marginBottom="10px">{label}</Heading2>
      <Input
        fieldRef={fieldRef}
        as={Dropdown}
        placeholder={placeholder}
        name={name}
        options={RESOURCE_SECTION_OPTIONS}
        value={fieldValue}
        onBlur={helpers.setTouched}
        onChange={(v) => {
          helpers.setTouched();
          setFieldValue(name, v);
        }}
        isInvalid={meta.touched && !!meta.error}
      />
      <Form.Control.Feedback type="invalid" style={{ display: 'inherit' }}>
        {meta.touched && meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const RenderForm = ({ handleSubmit, handleChange, values, setFieldValue }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Form.Row>
      <RenderTextField
        name="title"
        values={values}
        handleChange={handleChange}
        controlId="validationFormik01"
        label="Titlul resursei*"
        placeholder="Titlul resursei"
      />
    </Form.Row>
    <Form.Row>
      <RenderLinkField
        values={values}
        md="6"
        name="url"
        label="URL"
        placeholder="https://"
        handleChange={handleChange}
        controlId="validationFormik08"
      />
    </Form.Row>
    <Form.Row>
      <RenderSectionField
        values={values}
        controlId="validationFormik01"
        setFieldValue={setFieldValue}
        label="Alege secțiunea"
        name="section"
      />
    </Form.Row>
    <ButtonDiv>
      <SubmitButton type="submit">
        <SaveIcon /> Salvează
      </SubmitButton>
    </ButtonDiv>
  </Form>
);

const AddResourceForm = (props) => {
  const { onSubmit, resource, resources, isEditMode } = props;

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      section: get(values, 'section.value'),
      order: isEditMode ? values.order : size(resources) + 1,
    });
  };

  const schema = Yup.object({
    title: Yup.string().required('Câmp obligatoriu'),
    url: Yup.string()
      .required('Câmp obligatoriu')
      .url('URL Invalid (linkul trebuie sa contina http:// sau https://)'),
    section: Yup.string().required('Câmp obligatoriu'),
  });

  const defaultValues = {
    title: '',
    url: '',
    section: '',
    order: 0,
  };

  const initialValues = isEditMode
    ? {
        ...resource,
        section: find(RESOURCE_SECTION_OPTIONS, { value: resource.section }),
      }
    : defaultValues;

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(props) => <RenderForm {...props} isEditMode={isEditMode} />}
    </Formik>
  );
};

export default AddResourceForm;
