import { Typography } from '@mui/material';
import { get } from 'lodash-es';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import DialogComponent from '../components/DialogComponent';
import CreateTeamForm from '../forms/CreateTeamForm';

import { uploadTeamImage } from '../../requests/idea';
import { createTeam, editTeam } from '../../store/idea/teamSlice';
import { loadUserTeams, currentTeamIdSelector } from '../store';

const CreateTeamModal = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    createTeam,
    loadUserTeams,
    userId,
    isEditMode,
    team,
    teamId,
    editTeam,
    setEditedTeam,
  } = props;
  const intl = useIntl();

  const handleEdit = async (values) => {
    const { logo, ...remainingValues } = values;
    const payload = {
      ...team,
      logo: !logo?.url ? '' : team?.logo,
      ...remainingValues,
    };

    await editTeam(teamId, payload);

    if (logo?.file) {
      await uploadTeamImage(teamId, logo?.file);
    }

    await loadUserTeams(userId);
    setIsModalOpen(false);
  };

  const handleCreate = async (values) => {
    const { logo, ...remainingValues } = values;
    const response = await createTeam(remainingValues);

    if (logo?.file) {
      await uploadTeamImage(response?.teamId, logo?.file);
    }

    await loadUserTeams(userId);
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    if (setEditedTeam) {
      setEditedTeam(null);
    }
  };

  return (
    <DialogComponent
      open={isModalOpen}
      maxWidth="xs"
      handleClose={handleCloseModal}
      title={intl.formatMessage({ id: 'label.myIdea' })}
    >
      <Typography variant="h3" color="primary" mb={15} fontWeight={600}>
        {intl.formatMessage({ id: 'label.generalDetails' })}
      </Typography>

      <CreateTeamForm onSubmit={isEditMode ? handleEdit : handleCreate} team={team} />
    </DialogComponent>
  );
};

const mapStateToProps = (state) => ({
  userId: get(state.account, 'user._id'),
  teamId: currentTeamIdSelector(state.common.userTeams),
});

const mapDispatchToProps = { createTeam, loadUserTeams, editTeam };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateTeamModal)
);
