import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OrangeCircle = styled('div')(({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${theme.palette.general.orangeLight1};
  cursor: pointer;
`);

export const CommentsPopperContainer = styled('div')(({ theme }) => `
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.general.lightBlue1};
  padding: ${theme.spacing(8)};
  border-radius: 4px;
  min-width: 130px;
`);

export const MenuContent = styled('div')(() => `
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  & .MuiButton-root {
    justify-content: flex-start;
  }
`);

export const NotificationsFilterContainer = styled('div')(({ theme }) => `
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${theme.palette.primary.main};
  width: 220px;
  padding: ${theme.spacing(5)};
  justify-content: space-between;
  margin-top: ${theme.spacing(10)};
`);

export const NotificationsFilterOption = styled('div')(({ theme, active }) => `
  padding: ${theme.spacing(5)};
  cursor: pointer;
  border-radius: 5px;
  background-color: ${active ? theme.palette.general.accentLight : theme.palette.common.white};
  color: ${active ? theme.palette.common.white : theme.palette.general.accentLight};
  width: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
`);

export const Content = styled('div')(({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(8)};
  height: calc(100vh - 85px);
  padding-top: ${theme.spacing(20)};
`);

export const ArrowButton = styled(
  IconButton,
  { shouldForwardProp: (props) => props !== 'expanded' },
)(({ theme, expanded }) => `
  svg > path {
    fill: ${theme.palette.general.accentLight};
  };
  transform: ${expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`);

export const CommentIconWrapper = styled('div')(({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing(4)};

  svg > path {
    fill: ${theme.palette.general.accentLight};
  };
  
  p {
    color: ${theme.palette.general.accentLight};
  };
`);

export const UnreadIndicator = styled(Box)(({ theme }) => `
   width: 5px;
   height: 5px;
   background-color: ${theme.palette.error.main};
   border-radius: 50%;
`);

export const RepliesWrapper = styled('div')(({ theme }) => `
  padding-left: ${theme.spacing(15)};
  border-left: 1px solid ${theme.palette.general.accentLight2};
`);

export const BoxShadow = styled('div')(({ theme }) => `
  height: 30px;
  background: linear-gradient(0deg, ${theme.palette.general.accentLight3} 5.41%, rgba(255, 255, 255, 0) 100%);
  margin: 0px -20px;
`);
