import { Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Loading } from '../../../../common/styled';
import AddCharacteristicsModal from '../../components/AddCharacteristicsModal';
import AddPersonInfoModal from '../../components/AddPersonInfoModal';
import CharacteristicsSection from '../../components/CharacteristicsSection';
import PersonDetails from '../../components/PersonDetails';

import {
  addClientProfileCharacteristics,
  getCharacteristicsAverages,
} from '../../../../requests/hypothesis';

import { handleApiError } from '../../../../utils/errorUtils';
import { countUserStory, getGroupedCharacteristics } from '../../../../utils/hypothesisUtils';
import { canManageHypothesis } from '../../../../utils/permissions';
import { compareUserStories } from '../../utils';

const HypothesisProfile = ({ hypothesis }) => {
  const { hypothesisId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCharacteristicsModalOpen, setIsCharacteristicsModalOpen] = useState(false);
  const [isPersonInfoModalOpen, setIsPersonInfoModalOpen] = useState(false);
  const [characteristicType, setCharacteristicType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([
    { who: [], task: [] },
    { pain: [], win: [] },
  ]);

  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const isAllowedToManageHypothesis = canManageHypothesis(loggedUser);

  useEffect(() => {
    if (hypothesisId) {
      fetchCharacteristicsAverages();
    }
  }, [hypothesisId]);

  const userStoriesCount = useMemo(() => countUserStory(rows), [rows]);

  const fetchCharacteristicsAverages = async ({ isLoadingDisabled} = { isLoadingDisabled: false }) => {
    if (!isLoadingDisabled) {
      setIsLoading(true);
    }

    try {
      const response = await getCharacteristicsAverages(hypothesisId);
      const { who, task, pain, win } = getGroupedCharacteristics(response?.data);
      setRows([{ who, task }, { pain, win }]);
    } catch (e) {
      handleApiError(e);
    } finally {
      if (!isLoadingDisabled) {
        setIsLoading(false);
      }
    }
  };

  const handleHookSet = (type) => {
    setIsCharacteristicsModalOpen(true);
    setCharacteristicType(type);
  };

  const handleSortCharacteristics = (row, type, list, direction) => {
    const sortedList = list.sort((a, b) => compareUserStories(a, b, direction));
    setRows((prevState) => {
      const newState = [...prevState];

      prevState?.forEach((row, index) => {
        if (row?.[type]) {
          newState[index][type] = sortedList;
        }
      });

      return newState;
    });
  };

  const handleCloseModal = () => setIsCharacteristicsModalOpen(false);

  const handleAddCharacteristic = async (values) => {
    setIsSubmitting(true);

    try {
      const payload = {
        ...values,
        type: characteristicType,
      };

      await addClientProfileCharacteristics(hypothesisId, payload);
      fetchCharacteristicsAverages();
      handleCloseModal();
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PersonDetails
        isAllowedToManageHypothesis={isAllowedToManageHypothesis}
        hypothesis={hypothesis}
        setIsPersonInfoModalOpen={setIsPersonInfoModalOpen}
      />

      {rows?.length > 0 && (
        <Grid container spacing={20} height="fit-content">
          {rows?.map((row, index) => (
            <Grid item container xs={12} key={index} spacing={20} height="fit-content">
              {Object.entries(row)?.map(([type, characteristics]) => (
                <Grid item xs={6} key={type}>
                  <CharacteristicsSection
                    isAllowedToManageHypothesis={isAllowedToManageHypothesis}
                    id={`parent-container-${type}`}
                    characteristics={characteristics}
                    type={type}
                    onAddClick={() => handleHookSet(type)}
                    onSortClick={(direction) => handleSortCharacteristics(row, type, characteristics, direction)}
                    onRefresh={fetchCharacteristicsAverages}
                    userStoriesCount={userStoriesCount}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      )}

      <AddCharacteristicsModal
        isModalOpen={isCharacteristicsModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleAddCharacteristic}
        isSubmitting={isSubmitting}
      />

      <AddPersonInfoModal
        isModalOpen={isPersonInfoModalOpen}
        setIsModalOpen={setIsPersonInfoModalOpen}
        isEditMode
        entityId={hypothesisId}
        hypothesis={hypothesis}
      />

      {isLoading && <Loading isLoading={isLoading} />}
    </>
  );
};

export default HypothesisProfile;
