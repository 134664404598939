import { Box } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import Navigation from '../../components/Navigation';
import StepTitle from '../../components/StepTitle';

import InputField from '../../../../fields/InputField';
import { Content } from '../../index.styled';

const DescribeYourselfStep = ({
  onSelect,
  stepInfo,
  userProfile,
  onSubmit,
  onBack,
  isDisabled,
  hasBackButton,
}) => {
  const intl = useIntl();

  const initialValues = {
    about: userProfile?.about || '',
    appointmentsCalendar: userProfile?.appointmentsCalendar || '',
  };

  useEffect(() => {
    if (userProfile?.about && userProfile?.appointmentsCalendar) {
      onSelect({
        describeYourself: {
          about: userProfile.about,
          appointmentsCalendar: userProfile?.appointmentsCalendar,
        },
        questionAsAnswer: true,
      });
    }
  }, [userProfile?._id]);

  const validateForm = (values) => {
    const errors = {};

    if (!values.about) {
      errors.about = intl.formatMessage({ id: 'validate.mandatoryField' });
    }

    if (!Object.keys(errors).length) {
      onSelect({
        describeYourself: values,
        questionAsAnswer: true,
      });
    }

    return errors;
  };

  return (
    <>
      <Content>
        <Box mt={50} mb={30}>
          <StepTitle title={stepInfo.question} />

          <Box mt={60} width="60%">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validate={validateForm}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <InputField
                    id="about"
                    multiline
                    rows={4}
                    label={intl.formatMessage({ id: 'label.someWordsAboutYou' })}
                  />
                  <InputField
                    id="appointmentsCalendar"
                    label={intl.formatMessage({ id: 'label.appointmentsCalendar' })}
                  />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Content>

      <Navigation
        onBack={onBack}
        onSubmit={onSubmit}
        disabled={isDisabled}
        hasBackButton={hasBackButton}
      />
    </>
  );
};

export default DescribeYourselfStep;
