import styled, { css } from 'styled-components';

export const DivBorderBottom = styled.div`
  border-bottom: 1px solid #cccccc;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '20px'};
  margin-bottom: ${(props) =>
    props.paddingBottom ? props.marginBottom : '20px'};
`;

export const AcceptButton = styled.div`
  margin-left: ${(props) => props.marginLeft};
  height: 32px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100px')};
  border-radius: 4px;
  padding: ${(props) => (props.small ? '5px' : '15px')};
  background: ${(props) => props.theme.blueAccent};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;

  ${(props) =>
    props.myTeamsButton &&
    css`
      padding: 8px;
    `}

  ${(props) =>
    props.invitationButton &&
    css`
      padding: 5px 5px 5px 2px;
    `}
  > svg > path {
    fill: ${(props) => props.theme.white};
  }
  > svg > g > path {
    fill: ${(props) => props.theme.white};
  }

  :hover {
    background: ${(props) => props.theme.accentHover};
  }

  ${(props) =>
    props.dashed &&
    css`
      background: ${(props) => props.theme.white};
      border: 1px dashed #8e8eb5;
      border-radius: 3px;

      :hover {
        background: ${(props) => props.theme.white};
      }
    `}

  ${(props) =>
    props.backgroundNone &&
    css`
      background: none;
      border: 1px solid ${(props) => props.theme.blueAccent};
      :hover {
        background: none;
        border: 1px solid ${(props) => props.theme.accentHover};
      }
      :hover > span {
        color: ${(props) => props.theme.accentHover};
      }
      > svg > path {
        fill: ${(props) => props.theme.blueAccent};
      }

      :hover > svg > path {
        fill: ${(props) => props.theme.accentHover};
      }
    `}
`;

export const TitleButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;

  ${(props) =>
    props.alignItems &&
    css`
      align-items: flex-start;
    `}

  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.borderGray};
      padding-bottom: 5px;
    `}

  ${(props) =>
    props.paddingRight &&
    css`
      padding-right: 40px;
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      width: 100%;
    `}
`;

export const ButtonText = styled.span`
  font-style: normal;
  font-weight: ${(props) => (props.isInvited ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.color};
  text-align: center;
  margin-left: 5px;

  ${(props) =>
    props.noMargin &&
    css`
      margin-left: 0;
    `}

  ${(props) =>
    props.backgroundNone &&
    css`
      color: ${(props) => props.theme.blueAccent};
    `}

    ${(props) =>
    props.white &&
    css`
      color: ${(props) => props.theme.white};
    `}


      ${(props) =>
    props.ellipsis &&
    css`
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    `}
`;

export const ImageIconRoundDiv = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
