import { Box } from '@mui/material';
import React from 'react';

import ColumnPillAnswer from '../../components/ColumnPillAnswer';
import Navigation from '../../components/Navigation';
import StepTitle from '../../components/StepTitle';

import { Content } from '../../index.styled';

const ColumnOptionsStep = ({
  stepInfo,
  selectedAnswers,
  onSelect,
  onSubmit,
  onBack,
  isDisabled,
  hasBackButton,
  disableOptions,
}) => {
  return (
    <>
      <Content>
        <Box mt={50} mb={30}>
          <StepTitle title={stepInfo.question} />

          <Box mt={60}>
            <ColumnPillAnswer
              width="150px"
              selectedAnswers={selectedAnswers}
              onSelect={onSelect}
              options={stepInfo.answerOptions}
              disabled={disableOptions}
            />
          </Box>
        </Box>
      </Content>

      <Navigation
        onBack={onBack}
        onSubmit={onSubmit}
        disabled={isDisabled}
        hasBackButton={hasBackButton}
      />
    </>
  );
};

export default ColumnOptionsStep;
