import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Content = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'hasError' }
)(({ theme, hasError }) =>  `
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${
    hasError 
      ? theme.palette.error.main 
      : theme.palette.general.borderAccent
  };
  color: ${theme.palette.general.accentLight};
  border-radius: 5px;
  padding: ${theme.spacing(14.5, 10)};
  gap: 16px;
    
  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
    }
  }
  
  & .MuiInputBase-input {
    padding: ${theme.spacing(0, 2)};
    width: 30px;
    text-align: center;
    height: 22px;
  }
  
  & .MuiFormHelperText-root {
    display: none;
  }
  
  & input[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  & input[type=number]::-webkit-outer-spin-button,
  & input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`);
