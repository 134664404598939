import { Box, Typography } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import { ACTIVITY_CONCLUSION_SEGMENT_OPTIONS } from '../../constants';

import { ThumbsDownIcon, ThumbsUpIcon } from '../../../../assets/icons';

import AutocompleteField from '../../../../fields/AutocompleteField';
import { ActionsWrapper, Button, Root } from './index.styled';

const SegmentConclusion = ({
  id,
  title,
}) => {
  const intl = useIntl();
  const [field, , helpers] = useField(id);
  const [, meta] = useField('selectedSegment.value');
  const { value } = field;

  const handleChange = (status) => {
    helpers.setValue({ ...value, status });
  };

  return (
    <Root>
      <Typography variant="body1" color="general.accentLight" mb={10}>
        {title}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap={10}
      >
        <ActionsWrapper>
          <AutocompleteField
            sx={{ minWidth: '250px' }}
            fullWidth
            id="selectedSegment.value"
            options={ACTIVITY_CONCLUSION_SEGMENT_OPTIONS}
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
          />
          <Box display="flex" alignItems="center" gap={4} mb={meta.touched && meta.error ? '18px' : 0}>
            <Button
              id="button-unconfirm"
              variant="outlined"
              icon={<ThumbsDownIcon fill='currentColor' size="16px" />}
              size="small"
              isError
              onClick={() => handleChange(false)}
              isSelected={!value?.status}
              label={intl.formatMessage({ id: 'button.unconfirmed' })}
            />
            <Button
              id="button-confirmed"
              variant="outlined"
              icon={<ThumbsUpIcon fill='currentColor' size="16px" />}
              size="small"
              onClick={() => handleChange(true)}
              isSelected={value?.status}
              label={intl.formatMessage({ id: 'button.confirmed' })}
            />
          </Box>
        </ActionsWrapper>
      </Box>
    </Root>
  );
};

export default SegmentConclusion;
