export const EXPERIMENT_TYPE = {
  businessModel: 'businessModel',
  segmentSize: 'segmentSize',
  clientProblem: 'clientProblem',
  clientProfile: 'clientProfile',
  minValueSentence: 'minValueSentence',
  significantProblems: 'significantProblems',
  valueProposition: 'valueProposition',
  minValuableSolution: 'minValuableSolution',
  sourceOfIncome: 'sourceOfIncome',
  mvp10: 'mvp10',
  mvp20: 'mvp20',
  finalSolution: 'finalSolution',
  customerAcquisitionChannels: 'customerAcquisitionChannels',
};

export const TRUST_DATA = {
  small: 'small',
  medium: 'medium',
  high: 'high',
};

export const TASK_STATUSES = {
  done: 'done',
  inProgress: 'inProgress',
  inPreparation: 'inPreparation',
};

export const statusNegative = 'negative';
export const statusPositive = 'positive';

export const ACTIVITY_CONCLUSION_TRUST_OPTIONS = [
  {
    value: TRUST_DATA.small,
    label: 'Mica',
  },
  {
    value: TRUST_DATA.medium,
    label: 'Medie',
  },
  {
    value: TRUST_DATA.high,
    label: 'Mare',
  },
];

export const ACTIVITY_CONCLUSION_SEGMENT_OPTIONS = [
  {
    value: EXPERIMENT_TYPE.businessModel,
    label: 'label.businessModel',
  },
  {
    value: EXPERIMENT_TYPE.segmentSize,
    label: 'label.segmentSize',
  },
  {
    value: EXPERIMENT_TYPE.clientProblem,
    label: 'label.clientProblem',
  },
  {
    value: EXPERIMENT_TYPE.clientProfile,
    label: 'label.clientProfile',
  },
  {
    value: EXPERIMENT_TYPE.minValueSentence,
    label: 'label.minValueSentence',
  },
  {
    value: EXPERIMENT_TYPE.mvp10,
    label: 'label.mvp10',
  },
  {
    value: EXPERIMENT_TYPE.mvp20,
    label: 'label.mvp20',
  },
  {
    value: EXPERIMENT_TYPE.finalSolution,
    label: 'label.finalSolution',
  },
  {
    value: EXPERIMENT_TYPE.sourceOfIncome,
    label: 'label.sourceOfIncome',
  },
  {
    value: EXPERIMENT_TYPE.customerAcquisitionChannels,
    label: 'label.customerAcquisitionChannels',
  },
  {
    value: EXPERIMENT_TYPE.minValuableSolution,
    label: 'label.minValuableSolution',
  },
  {
    value: EXPERIMENT_TYPE.significantProblems,
    label: 'label.significantProblem',
  }
];

export const TASK_STATUS_COLOR = {
  inPreparation: 'red',
  inProgress: 'orange',
  done: 'green',
};
