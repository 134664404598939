export const USER_ROLES = {
  Founder: 'FOUNDER',
  Mentor: 'MENTOR',
  Admin: 'ADMIN',
  Accelerator: 'ACCELERATOR',
};

export const USER_ROLE_NAMES = {
  [USER_ROLES.Founder]: 'label.founder',
  [USER_ROLES.Mentor]: 'label.expert',
  [USER_ROLES.Admin]: 'label.admin',
  [USER_ROLES.Accelerator]: 'label.accelerator',
};

export const TEAM_ROLES = {
  Hustler: 'hustler',
  Hacker: 'hacker',
  Hipster: 'hipster',
};
