import { debounce } from 'lodash-es';
import { STATUS } from 'react-joyride';

import { setIsOpen } from '../mrl/store/mrl';

import { ONBOARDING_PATH_NAMES } from './constants';

export const platformFundamentalsCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  stopTour,
  runUpdateTourProgress,
                                               dispatch,
}) => {
  const { action, index, status, lifecycle } = data;

  if (action === 'close') {
    setIsTourActive(false);
    stopTour();
    return;
  }

  if (action === 'next') {
    if (index === 0) {
      setIsTourActive(false);
      push('/profile?tab=profile');
      setCurrentStep(1);
      debounce(
        () =>
          runUpdateTourProgress({
            stepData: {
              step: 1,
              code: ONBOARDING_PATH_NAMES.platformFundamentals,
            },
          }),
        100
      );

      runUpdateTourProgress({
        stepData: {
          step: 1,
          code: ONBOARDING_PATH_NAMES.platformFundamentals,
        },
      }),
        setTimeout(() => {
          setIsTourActive(true);
        }, 1000);
      return;
    }

    if (index === 3) {
      dispatch(setIsOpen(true));
      push('/canvas');
    }

    if (index === 4) {
      dispatch(setIsOpen(false));
    }

    if (lifecycle === 'complete') {
      setCurrentStep(index + 1);

      runUpdateTourProgress({
        stepData: {
          step: index + 1,
          code: ONBOARDING_PATH_NAMES.platformFundamentals,
        },
      });
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setIsTourActive(false);
    stopTour();
  }
};

const tabsMapping = {
  0: 'trends',
  5: 'industry',
  7: 'competition',
  9: 'swot',
  11: 'details',
  13: 'conclusions',
};

export const contextAnalysisCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  stopTour,
  runUpdateTourProgress,
}) => {
  const { action, index, status, lifecycle } = data;

  const handleNavigation = (index, tab) => {
    setIsTourActive(false);
    push(`/opportunity-analysis?tab=${tab}`);
    setTimeout(
      () => {
        setIsTourActive(true);
      },
      index === 0 ? 1500 : 500
    );
  };

  if (action === 'close') {
    setIsTourActive(false);
    stopTour();
    return;
  }

  if (action === 'next') {
    if (lifecycle === 'complete') {
      setCurrentStep(index + 1);

      runUpdateTourProgress({
        stepData: {
          step: index + 1,
          code: ONBOARDING_PATH_NAMES.contextAnalysis,
        },
      });
    }

    if (tabsMapping[index]) {
      handleNavigation(index, tabsMapping[index]);
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setIsTourActive(false);
    stopTour();
  }
};

export const mrlCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  stopTour,
  dispatch,
  runUpdateTourProgress,
}) => {
  const { action, index, status, lifecycle } = data;

  if (action === 'close') {
    setIsTourActive(false);
    stopTour();
    return;
  }

  if (action === 'next') {
    if (lifecycle === 'complete') {
      setCurrentStep(index + 1);
      runUpdateTourProgress({
        stepData: {
          step: index + 1,
          code: ONBOARDING_PATH_NAMES.mrl,
        },
      });
    }

    if (index === 1) {
      dispatch(setIsOpen(true));
    }

    if (index === 3) {
      dispatch(setIsOpen(false));
      setIsTourActive(false);
      push('/profile?tab=settings');

      setTimeout(() => {
        setIsTourActive(true);
      }, 1000);
      return;
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setIsTourActive(false);
    stopTour();
  }
};

export const bmcCallback = ({
  data,
  push,
  setIsTourActive,
  setCurrentStep = () => {},
  runUpdateTourProgress,
  stopTour,
}) => {
  const { action, index, status, lifecycle } = data;
  if (action === 'close') {
    setIsTourActive(false);
    stopTour();
    return;
  }

  if (action === 'next') {
    if (lifecycle === 'complete') {
      setCurrentStep(index + 1);
      runUpdateTourProgress({
        stepData: {
          step: index + 1,
          code: ONBOARDING_PATH_NAMES.bmc,
        },
      });
    }

    if (index === 1) {
      setIsTourActive(false);

      setTimeout(() => {
        setIsTourActive(true);
      }, 1000);
    }

    if (index === 2) {
      push('/canvas');
    }

    if (index === 3) {
      setIsTourActive(false);

      setTimeout(() => {
        setCurrentStep(4);
        setIsTourActive(true);
      }, 1000);
    }
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    setIsTourActive(false);
    stopTour();
  }
};

export const getPercentage = (teamName, totalSteps, onboardingProgress) => {
  const savedEntry = onboardingProgress.find(
    (entry) => entry?.code === teamName
  );

  if (savedEntry) {
    return Math.floor((savedEntry?.step * 100) / totalSteps);
  }

  return 0;
};
