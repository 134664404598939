import { isNil } from 'lodash-es';

import { USER_ROLES } from '../../constants/roles';

import {
  BOOLEAN_ANSWER_TYPE,
  MULTIPLE_ANSWER_TYPE,
  ONBOARDING_FLOW_BY_ROLE, QuestionCodes,
  SINGLE_ANSWER_TYPE
} from './steps';

export const generateQuestionData = ({
  stepInfo,
  answers,
  step,
}) => {
  let predefinedResponse;

  if (stepInfo?.answerWithQuestion) {
    predefinedResponse = stepInfo?.question;
  }

  return {
    questionId: stepInfo.questionCode,
    questionTitle: stepInfo.question,
    step: step + 1,
    questionType: stepInfo.questionAnswerType,
    ...(stepInfo.questionAnswerType === SINGLE_ANSWER_TYPE && {
      singleAnswer: predefinedResponse || answers[0],
    }),
    ...(stepInfo.questionAnswerType === MULTIPLE_ANSWER_TYPE && { multipleAnswers: answers }),
    ...(stepInfo.questionAnswerType === BOOLEAN_ANSWER_TYPE && { booleanAnswer: answers[0] }),
  };
};

export const determinateSteps = (role, invited) => {
  if (invited && role === USER_ROLES.Founder) {
    return ONBOARDING_FLOW_BY_ROLE.invitedFounder;
  }

  return ONBOARDING_FLOW_BY_ROLE[role];
};

export const getInitialAnswer = (step, onboardingProgress = [], onboardingSteps, userProfile) => {
  const { isInvited, role } = userProfile?.userId || {};
  const currentStepInfo = onboardingSteps[step || 0];
  const existingAnswers = onboardingProgress?.find(({ questionId }) => questionId === currentStepInfo.questionCode);

  if (currentStepInfo.questionCode === QuestionCodes.accountType) {
    return role ? [role] : null;
  }

  if (currentStepInfo.questionCode === QuestionCodes.language) {
    return userProfile.language ? [userProfile.language] : null;
  }

  if (currentStepInfo.questionCode === QuestionCodes.experience) {
    return userProfile.expertise?.length ? [userProfile.expertise] : null;
  }

  if (isInvited && currentStepInfo?.preselected?.[role]?.values) {
    return currentStepInfo.preselected[role].values;
  }

  if (existingAnswers) {
    if (!isNil(existingAnswers.booleanAnswer)) {
      return [existingAnswers.booleanAnswer];
    } else {
      return existingAnswers.singleAnswer ? [existingAnswers.singleAnswer] : existingAnswers.multipleAnswers;
    }
  }
};
