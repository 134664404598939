import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import React from 'react';

const MultiSelectField = ({
  options,
  placeholder = '',
  emptyMessage,
  label,
  size,
  fullWidth,
  sx,
  ...remainingProps
}) => {
  return (
  <FormControl sx={{ minWidth: '150px', ...sx }} size={size} fullWidth={fullWidth}>
    <InputLabel id="multi-select-label">{label}</InputLabel>
    <Select
      {...remainingProps}
      labelId="multi-select-label"
      label={label}
      multiple
      className="scrollbar"
      MenuProps={{ PaperProps: { sx: { maxHeight: 200, mt: 1 } } }}
    >
      {placeholder && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {options.map(({ label: optionLabel, value: val, ...remainingOptionProps }, index) => (
        <MenuItem value={val} key={`${index}-${val}`} {...remainingOptionProps}>
          {optionLabel}
        </MenuItem>
      ))}
      {!options?.length && (
        <MenuItem disabled value="">
          {emptyMessage}
        </MenuItem>
      )}
    </Select>
  </FormControl>
  );
};

export default MultiSelectField;
