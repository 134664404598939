import { http } from '../core/services/http';

import {
  EDIT_VIABILITY_MODEL, GET_VIABILITY_ACCOMPLISHED_DATA,
  GET_VIABILITY_MODEL,
  GET_VIABILITY_MODEL_STATUS,
  REMOVE_VIABILITY_MODEL_DATA,
} from '../constants/apiRoutes';
import { formatRoute } from '../utils/formatters';

export const getViabilityModel = (teamId) => {
  const endpoint = formatRoute(GET_VIABILITY_MODEL, { teamId });
  return http.get(endpoint);
};

export const editViabilityModel = (viabilityModelId, payload) => {
  const endpoint = formatRoute(EDIT_VIABILITY_MODEL, { viabilityModelId });
  return http.put(endpoint, payload);
};

export const removeViabilityModelData = (viabilityModelId) => {
  const endpoint = formatRoute(REMOVE_VIABILITY_MODEL_DATA, { viabilityModelId });
  return http.put(endpoint, {});
};

export const getViabilityModelStatus = (teamId) => {
  const endpoint = formatRoute(GET_VIABILITY_MODEL_STATUS, { teamId });
  return http.get(endpoint);
};

export const getViabilityAccomplishedData = (teamId) => {
  const endpoint = formatRoute(GET_VIABILITY_ACCOMPLISHED_DATA, { teamId });
  return http.get(endpoint);
};
