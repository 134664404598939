import { Grid, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { LinkText } from '../../../../layouts/AuthLayout/auth.styled';

import CheckboxField from '../../../../fields/CheckboxField';
import InputField from '../../../../fields/InputField';

const RegisterForm = ({
  onSubmit,
  invitedUserData = null,
}) => {
  const intl = useIntl();

  const schema = Yup.object({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField'}))
      .email(intl.formatMessage({ id: 'validate.invalidEmail' })),
    password: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    confirmPassword: Yup.string()
      .required(intl.formatMessage({ id: 'validate.mandatoryField'}))
      .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'validate.passwordsNotMatching' })),
    firstName: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    lastName: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField'})),
    gdpr: Yup.bool().oneOf([true], intl.formatMessage({ id: 'validate.mandatoryField'})),
    // recaptcha: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField),
  });

  const initialValues = {
    firstName: invitedUserData?.firstName || '',
    lastName: invitedUserData?.lastName || '',
    email: invitedUserData?.email || '',
    role: invitedUserData?.userRole || '',
    password: '',
    confirmPassword: '',
    gdpr: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={8}>
            <InputField
              id="firstName"
              label={intl.formatMessage({ id: 'label.firstName' })}
              md={6}
            />
            <InputField
              id="lastName"
              label={intl.formatMessage({ id: 'label.name' })}
              md={6}
            />
            <InputField
              disabled={invitedUserData || false}
              id="email"
              label={intl.formatMessage({ id: 'label.email' })}
              md={12}
            />
            <InputField
              type="password"
              id="password"
              label={intl.formatMessage({ id: 'label.password' })}
              md={12}
            />
            <InputField
              type="password"
              id="confirmPassword"
              label={intl.formatMessage({ id: 'label.confirmPassword' })}
              md={12}
            />

            <CheckboxField
              sx={{ mb: 0 }}
              color="secondary"
              name="gdpr"
              md={12}
              label={
                <a
                  href="https://ptic.ro/confidentialitate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography color="textSecondary">
                    {intl.formatMessage({ id: 'label.acceptTermsAndConditions' })}
                  </Typography>
                </a>
              }
            />
          </Grid>

          <LinkText style={{ float: 'right' }} to="/account/login">
            {intl.formatMessage({ id: 'label.haveAccount' })}
          </LinkText>
          <ButtonComponent
            size="large"
            sx={{ mt: 30 }}
            fullWidth
            type="submit"
            label={intl.formatMessage({ id: 'button.createAccount' })}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
