import { HighSignalIcon, LowSignalIcon, MediumSignalIcon } from '../../assets/icons';

export const HYPOTHESIS_IMPORTANCE_DETAILS = {
  high: {
    label: 'label.big',
    icon: <HighSignalIcon />,
  },
  medium: {
    label: 'label.medium',
    icon: <MediumSignalIcon />,
  },
  low: {
    label: 'label.small',
    icon: <LowSignalIcon />,
  },
};

export const IMPORTANCE_OPTIONS =   [
  {
    value: 'low',
    label: 'label.small',
  },
  {
    value: 'medium',
    label: 'label.medium',
  },
  {
    value: 'high',
    label: 'label.big',
  },
];

export const HYPOTHESIS_STAGE_OPTIONS = [
  {
    value: 'validated',
    label: 'label.validated',
  },
  {
    value: 'invalidated',
    label: 'label.invalidated',
  },
];

export const CATEGORY_OPTIONS = [
  {
    value: 'dezirabila',
    label: 'dezirabilă',
  },
  {
    value: 'viabila',
    label: 'viabilă',
  },
  {
    value: 'fezabila',
    label: 'fezabilă',
  },
];
