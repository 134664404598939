import { Grid } from '@mui/material';
import React from 'react';

import MyIdeas from '../MyIdeas';
import UserDetails from '../UserDetails';
import { ProfileContainer } from './index.styled';

const UserInformation = (props) => {
  const { userProfile, userTeams = [] } = props;

  return (
    <ProfileContainer className="scrollbar">
      <Grid container spacing={20}>
        <Grid item xs={12} lg={5}>
          <UserDetails userProfile={userProfile} />
        </Grid>
        <Grid item xs={12} lg={7}>
          <MyIdeas userTeams={userTeams} />
        </Grid>
      </Grid>
    </ProfileContainer>
  );
};

export default UserInformation;
