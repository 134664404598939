const palette = {
  primary: {
    main: '#2A5AB7',
    secondary: '#063795',
    dark: '#131336',
  },
  secondary: {
    main: '#2A5AB7',
    secondary: '#063795',
    dark: '#131336',
  },
  error: {
    main: '#BA3232',
    secondary: '#FF3D00',
    dark: '#890B0B',
  },
  success: {
    main: '#15BB89',
    secondary: '#058E65',
  },
  warning: {
    main: '#ff7600',
    secondary: '#FFB257',
  },
  background: {
    main: '#EFEFF4',
    secondary: '#F7F7F8',
  },
  text: {
    primary: '#131336',
    secondary: '#74A3FE',
    disabled: '#8E8EB5',
  },
  general: {
    accentHover: '#063795',
    accentLight: '#74A3FE',
    accentLight2: '#B1CCFE',
    accentLight3: '#E6EBFF',
    accentLightHover: 'rgba(116, 163, 254, 0.15)',
    borderGray: '#CCCCCC',
    grayLight: '#efeff4',
    grayLight1: '#7E7E7E',
    grayLight2: '#98a6ad',
    borderAccent: '#74A3FE',
    borderAccent2: '#B1CCFE',
    lightBlue: '#EFF3FE',
    lightBlue1: '#dee7fc',
    lightBlue2: '#3498db',
    lightBlue3: '#2d9bf0',
    purple: '#9441FE',
    placeholder: '#B1CCFE',
    orangeLight: '#fbe5d6',
    orangeLight1: '#ffd3c6',
    darkBlue: '#131336',
    orange: '#FB791C',
    yellow: '#FFB800',
    // text: '#131336', //text-primary
    // blueAccent: '#2A5AB7', // primary-main
    // success: '#15BB89', // success-main
    // successHover: '#058E65', // success-secondary
    // textInactive: '#8E8EB5', // text-disabled
    // danger: '#BA3232', // error-main
    // dangerHover: '#890B0B', // error=dark
    // errorLight: '#FF3D00', - error-secondary
  },
  common: {
    black: '#000',
    white: '#fff',
  },
};

export default palette;
