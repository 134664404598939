import React from 'react';
import { useIntl } from 'react-intl';

import DialogComponent from '../../components/DialogComponent';
import DetailsForm from '../../forms/DetailsForm';

const AddDetailsModal = ({
  handleClose,
  isModalOpen,
  handleSubmitDetail,
  selectedDetailData,
  formValues,
  handleUpdateDetail,
  hasExtendedCategories = false,
  hypothesisId,
}) => {
  const intl = useIntl();

  return (
    <DialogComponent
      open={isModalOpen}
      handleClose={handleClose}
      title={intl.formatMessage({ id: formValues ? 'modal.title.editDetail' : 'modal.title.addDetail' })}
    >
      <DetailsForm
        handleUpdateDetail={handleUpdateDetail}
        formValues={formValues}
        data={selectedDetailData}
        handleSubmit={handleSubmitDetail}
        hasExtendedCategories={hasExtendedCategories}
        hypothesisId={hypothesisId}
      />
    </DialogComponent>
  );
};

export default AddDetailsModal;
