import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Title = styled(Typography)(
  () => `
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
  }
  
  max-width: 175px;
`
);
