import { DownloadIcon } from '../../assets/icons';
import palette from '../../assets/theme/palette';

export const PAYMENTS_TABLE_HEADERS = [
  { name: 'Data', key: 'date' },
  { name: 'Plan', key: 'plan' },
  { name: 'Suma', key: 'amount' },
  { name: 'Status', key: 'status' },
  { name: '', key: 'actions', cellIcon: <DownloadIcon color={palette.primary.main} size={20} /> },
];
