import { Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AddButton from '../../../../common/components/AddButton';
import { ClampedRichText } from '../../../../common/components/ClampedRichText';
import DialogAlert from '../../../../common/components/DialogAlert';
import GenericEmptyTable from '../../../../common/components/GenericEmptyTable';
import GenericTableComponent from '../../../../common/components/GenericTableComponent';
import AddActivityModal from '../../../../common/modals/AddActivityModal';
import { Loading } from '../../../../common/styled';

import { currentTeamIdSelector } from '../../../../common/store';
import { useApi } from '../../../../hooks/useApi';
import {
  createActivity,
  deleteActivity,
  getAllActivitiesByTeam,
  updateActivity,
} from '../../../../requests/activities';
import { getAllConclusionsByTeam } from '../../../../requests/conclusions';
import { getMyIdeeaMembers } from '../../../../requests/myIdeea';
import { getAllTasksByActivity } from '../../../../requests/tasks';
import { getTeam } from '../../../../store/idea/teamSlice';

import { TASK_STATUS_OPTIONS } from '../../../../constants/options';
import { formatDate } from '../../../../utils/dateHelpers';
import { formatMembersToOptions } from '../../../../utils/formatters';
import { canManageHypothesis } from '../../../../utils/permissions';

import { EditIcon, TrashIconOutlined } from '../../../../assets/icons';

import ActivitiesDrawer from '../ActivitiesDrawer';

const HypothesisActivities = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const hypothesisId = useSelector((state) => state.hypotheses.hypothesis?._id);
  const teamId = useSelector((state) =>
    currentTeamIdSelector(state.common.userTeams)
  );

  const [{ result: teamMembers }, runGetTeamMembers] = useApi(
    getMyIdeeaMembers,
    []
  );

  const teamMembersOptions = useMemo(() => (
    formatMembersToOptions(teamMembers)
  ), [teamMembers]);

  const [
    { result: activities, isLoading: activitiesLoading },
    fetchAllActivitiesByTeam,
  ] = useApi(getAllActivitiesByTeam, []);
  const [, addActivity] = useApi(createActivity);
  const [, doUpdateActivity] = useApi(updateActivity);
  const [, removeActivity] = useApi(deleteActivity);
  // const [, fetchActivityById] = useApi(getActivityById);
  const [
    { result: activityTasks, isLoading: isTaskLoading },
    runGetTasksByActivity,
  ] = useApi(getAllTasksByActivity, []);
  const [
    { result: activityConclusions, isLoading: isConclusionsLoading },
    runGetConclusionsByActivity,
  ] = useApi(getAllConclusionsByTeam, []);

  const [isAddActivitiesModalOpen, setIsAddActivitiesModalOpen] =
    useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editFormValues, setEditFormValues] = useState(null);
  const [drawerActivity, setDrawerActivity] = useState(null);
  const [idToDelete, setIdToDelete] = useState('');
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const isAllowedToManageHypothesis = canManageHypothesis(loggedUser);

  const handleFetchTasks = () => runGetTasksByActivity({ params: { activityId: drawerActivity._id, } });

  const handleFetchConclusions = () => (
    runGetConclusionsByActivity({
      params: { teamId, },
      queryParams: { filters: { activityId: drawerActivity._id, } },
    })
  );

  useEffect(() => {
    if (drawerActivity?._id) {
      handleFetchTasks();
      handleFetchConclusions();
    }
  }, [drawerActivity]);

  const handleToggleAddActivitiesModal = () => {
    setIsAddActivitiesModalOpen(!isAddActivitiesModalOpen);
  };

  const handleCloseModal = () => {
    handleToggleAddActivitiesModal();
    setSelectedActivityId(null);
    setEditFormValues(null);
  };

  const handleGetActivities = async () => {
    const payload = {
      params: { teamId },
      queryParams: { filters: { hypothesisId } },
    };

    await fetchAllActivitiesByTeam(payload);
  };

  const handleSubmitActivity = async (formValues) => {
    const payload = {
      params: { teamId },
      activityData: {
        ...formValues,
        hypothesisId,
        status: formValues.state,
      },
    };
    await addActivity(payload);
    await handleGetActivities();
    handleCloseModal();
  };

  const handleUpdateActivity = async (formValues) => {
    const payload = {
      params: { activityId: selectedActivityId },
      activityData: {
        ...formValues,
        status: formValues.state,
      },
    };

    await doUpdateActivity(payload);
    await handleGetActivities();
    handleCloseModal();
  };

  const handleEditActivity = (id) => {
    const { status, successCriteria, title, userResponsibleId } = activities.find(
      (activity) => activity._id === id
    );

    setSelectedActivityId(id);
    setEditFormValues({
      state: status,
      userResponsibleId: userResponsibleId?._id,
      successCriteria,
      title,
    });
    handleToggleAddActivitiesModal();
  };

  const handleDeleteActivity = async (id) => {
    const payload = {
      params: { activityId: id },
    };
    await removeActivity(payload);
    await handleGetActivities();
    setIdToDelete('');
  };

  // const handleGetActivityById = async (id) => {
  //   const payload = {
  //     params: { activityId: id },
  //   };
  //   await fetchActivityById(payload);
  // };

  const handleToggleDrawerStatus = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = () => {
    handleToggleDrawerStatus();
    setSelectedActivityId(null);
    setEditFormValues(null);
  };

  const updateTeamMembers = async () => {
    await runGetTeamMembers({
      params: {
        teamId: teamId,
      },
    });
  };

  const createTableHeaderCells = () => {
    const header = [
      { type: 'string', content: intl.formatMessage({ id: 'label.number' }), key: 'number' },
      { type: 'string', content: intl.formatMessage({ id: 'label.title' }), key: 'title' },
      { type: 'string', content: intl.formatMessage({ id: 'label.status' }), key: 'status' },
      { type: 'string', content: intl.formatMessage({ id: 'label.completionDate' }), key: 'createdAt' },
    ];

    if (isAllowedToManageHypothesis) {
      header.push({
        type: 'component',
        content: (
          <Box sx={{ display: 'flex' }}>
            <AddButton
              id="add-activity"
              p={5}
              onClick={handleToggleAddActivitiesModal}
            />
          </Box>
        ),
        hideSortIcon: true,
      });
    }

    return header;
  };

  const createRows = () =>
    activities.map(
      (
        {
          _id,
          title,
          // userResponsibleId,
          status,
          createdAt,
        },
        index
      ) => ({
        rowId: _id,
        order: index + 1,
        title : <ClampedRichText variant="body2" color="primary" text={title} lineClamp={1} />,
        // userResponsibleId,
        status: TASK_STATUS_OPTIONS.find((option) => option.value === status).label,
        createdAt: formatDate(createdAt),
        action: isAllowedToManageHypothesis ? (
          <Box sx={{ display: 'flex', gap: '18px' }}>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setIdToDelete(_id);
              }}
              color="error"
            >
              <TrashIconOutlined />
            </IconButton>
            <IconButton
                onClick={(event) => {
                event.stopPropagation();
                handleEditActivity(_id);
              }}
            >
              <EditIcon size="18px" fill={theme.palette.general.accentLight} />
            </IconButton>
          </Box>
        ) : null,
      })
    );

  useEffect(() => {
    if (teamId) {
      dispatch(getTeam(teamId));
      updateTeamMembers();
    }
  }, [teamId]);

  useEffect(() => {
    if (teamId && hypothesisId) {
      handleGetActivities();
    }
  }, [teamId, hypothesisId]);

  const handleSelectedActivity = (activityId) => {
    setIsDrawerOpen(true);
    const matchingActivity = activities.find(
      (activity) => activity._id === activityId
    );
    setDrawerActivity(matchingActivity);
  };

  return (
    <>
      {activitiesLoading && <Loading isLoading={activitiesLoading} />}

      {!activitiesLoading && activities && !!activities.length && (
        <GenericTableComponent
          rows={createRows()}
          headerCells={createTableHeaderCells()}
          handleSelectedRow={handleSelectedActivity}
        />
      )}

      {!activitiesLoading && !activities.length && (
        <GenericEmptyTable
          headers={createTableHeaderCells()}
          onAddClick={isAllowedToManageHypothesis ? handleToggleAddActivitiesModal : null}
          addButtonText={intl.formatMessage({ id: 'button.addAnActivity' })}
          detailMessage={intl.formatMessage({ id: 'modal.content.addFirstExperimentForValidating' })}
        />
      )}

      {isAddActivitiesModalOpen && (
        <AddActivityModal
          teamId={teamId}
          isModalOpen={isAddActivitiesModalOpen}
          onClose={handleCloseModal}
          onSubmit={editFormValues ? handleUpdateActivity : handleSubmitActivity}
          activity={editFormValues}
          teamMembers={teamMembersOptions}
          isEditMode={!!editFormValues}
        />
      )}

      <ActivitiesDrawer
        isOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        activityId={drawerActivity?._id}
        teamMembers={teamMembersOptions}
        tasks={activityTasks}
        conclusions={activityConclusions}
        isLoadingResources={isTaskLoading || isConclusionsLoading}
        onFetchTasks={handleFetchTasks}
        onFetchConclusions={handleFetchConclusions}
        handleDeleteActivity={handleDeleteActivity}
        handleEditActivity={handleEditActivity}
      />

      {idToDelete && (
        <DialogAlert
          isModalOpen={idToDelete}
          onClose={() => setIdToDelete('')}
          title={intl.formatMessage({ id: 'modal.title.confirmDeleteOp' })}
          content={intl.formatMessage({ id: 'modal.content.deleteActivity' })}
          hasActions
          onSubmit={() => handleDeleteActivity(idToDelete)}
        />
      )}
    </>
  );
};

export default HypothesisActivities;
